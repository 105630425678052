// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Global Styles */
.page-system-company {
  padding: 30px;
  min-height: 540px;
  background: linear-gradient(to right, #764BA2, #3C73D2);
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 30px;
  border-radius: 30px 0;
  background: #fff;
}
.container .header {
  color: #4E4E87;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 30px;
}
.container .centered-table {
  width: 100%;
  margin-bottom: 30px;
  border-collapse: collapse;
}
.container .centered-table th, .container .centered-table td {
  padding: 10px;
  text-align: left;
  border: 1px solid #ddd;
}
.container .pagination {
  text-align: center;
  margin-bottom: 30px;
}
.container .pagination p {
  display: inline-block;
  margin: 0;
}
.container .pagination a {
  margin: 0 5px;
  text-decoration: none;
  color: #4E4E87;
}
.container .pagination a.active {
  font-weight: bold;
  color: #764BA2;
}
.container .button-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
}
.container .button-container .trigger-button {
  width: 200px;
  height: 40px;
  background-color: #3C73D2;
  color: #fff;
  border: none;
  border-radius: 5px;
  margin-left: 10px;
}
.container .button-container .save-button {
  width: 120px;
  height: 40px;
  background-color: #764BA2;
  color: #fff;
  border: none;
  border-radius: 5px;
  display: inline-block;
  margin-left: 10px;
}
.container .modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
.container .modal .modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  position: relative;
  width: 400px;
}
.container .modal .modal-content .modal-button {
  width: 120px;
  height: 40px;
  background-color: #764BA2;
  color: #fff;
  border: none;
  border-radius: 5px;
  display: inline-block;
  margin: 10px 5px;
  cursor: pointer;
  text-align: center;
  font-size: 14px;
}
.container .modal .modal-content .modal-button:hover {
  background-color: #5c3a7a;
}
.container .modal .modal-content .close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 24px;
}
.container .modal .modal-content h2 {
  margin-top: 0;
}
.container .modal .modal-content button {
  margin: 10px 5px;
}
.container select {
  background: #f5f5f5;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  font-size: 14px;
  color: #333;
  width: 100%;
  box-sizing: border-box;
}
.container select option {
  background: #fff;
  color: #333;
}
.container select:focus {
  border-color: #3C73D2;
  outline: none;
  box-shadow: 0 0 0 2px rgba(60, 115, 210, 0.2);
}
.container .ant-select .ant-select-selector {
  background: #f5f5f5;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  font-size: 14px;
  color: #333;
}
.container .ant-select .ant-select-selector:hover, .container .ant-select .ant-select-selector:focus {
  border-color: #3C73D2;
  box-shadow: 0 0 0 2px rgba(60, 115, 210, 0.2);
}
.container .ant-select .ant-select-selection-item {
  color: #333;
}
.container .ant-select .ant-select-arrow {
  color: #4E4E87;
}
.container .ant-btn-icon-only {
  line-height: 0;
}
.container ::ng-deep .cdk-overlay-pane {
  z-index: auto !important;
}
.container ::ng-deep .tac {
  text-align: center !important;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
