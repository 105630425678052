import { Component, OnInit } from "@angular/core";
import { BaseComponent } from "src/app/dynamic-components/components/base/base.component";
import { Datasources } from "src/app/dynamic-components/datasource/Datasources";
import { AngularWaitBarrier } from "blocking-proxy/built/lib/angular_wait_barrier";
import { isArray } from "util";
import { environment } from "src/environments/environment";
import { LanguageService } from "src/app/dynamic-components/utils/language.service";

@Component({
  selector: "app-textbox-view",
  templateUrl: "./textbox-view.component.html",
  host: {
    "[style.width]": "config.width",
  },
  styleUrls: ["./textbox-view.component.sass"],
})
export class TextboxViewComponent extends BaseComponent implements OnInit {
  displayValue: any;
  showTooltip = false;
  rand =Math.round((Math.random()*10000)).toString();
  constructor(private languageService: LanguageService) {
    super();
  }

  ngOnInit() {
    //for dropdown
    if (this.config.dataSource) {
      Datasources.loadData(this.config, this.context, this.formGroup).then(
        (ds) => {
          const currentValue = this.formGroup.controls[this.config.name].value;
          if (isArray(currentValue)) {
            this.displayValue = "";
            const arr = [];
            currentValue.map((v) => {
              const obj = ds.find((response) => {
                return v === response.Code;
              });
              if (obj) {
                arr.push(
                  environment.languageSwitch
                    ? this.languageService.getValue(obj.languageKey)
                    : obj.Text
                );
              }
            });
            this.displayValue = arr.join();
          } else {
            const obj = ds.find((response) => currentValue === response.Code);
            if (obj) {
              this.displayValue = environment.languageSwitch
                ? this.languageService.getValue(obj.languageKey)
                : obj.Text;
            }
          }
        }
      );
    } else if (
      this.config.datatype === "object" &&
      this.config.fieldset[0].dataSource
    ) {
      const dropDownValue =
        this.formGroup.controls[this.config.name].value[
          this.config.fieldset[0].name
        ];
      const inputValue =
        this.formGroup.controls[this.config.name].value[
          this.config.fieldset[1].name
        ];
      Datasources.loadData(
        this.config.fieldset[0],
        this.context,
        this.formGroup
      ).then((ds) => {
        const obj = ds.find((response) => dropDownValue === response.Code);
        if (obj && inputValue) {
          this.displayValue = obj.Text + "-" + inputValue;
        } else {
          this.displayValue = "";
        }
      });
    }
    // for radio
    else if (this.config.options) {
      const obj = this.config.options.find(
        (response) =>
          this.formGroup.controls[this.config.name].value === response.Code
      );
      if (obj) {
        this.displayValue = obj.Text;
      }
    } else {
      if (
        this.config.dateformat === "MM/DD/YYYY" &&
        this.value === "Invalid date"
      ) {
        this.displayValue = "";
      } else {
        this.displayValue = this.value;
      }
    }
    this.rand =Math.round((Math.random()*10000)).toString();
  }

  estimateChooseOver(e, estimateSpendTooltip) {
    const TooltipEle = estimateSpendTooltip;
    if (estimateSpendTooltip) {
      if (
        e.clientX < TooltipEle.nativeElement.getBoundingClientRect().left ||
        e.clientX >
          TooltipEle.nativeElement.getBoundingClientRect().left +
            TooltipEle.nativeElement.offsetWidth -
            5 ||
        e.clientY < TooltipEle.nativeElement.getBoundingClientRect().top ||
        e.clientY >
          TooltipEle.nativeElement.getBoundingClientRect().top +
            TooltipEle.nativeElement.offsetHeight -
            5
      ) {
        this.showTooltip = false;
      }
    } else {
      this.showTooltip = false;
    }
  }
}
