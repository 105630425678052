import { Component, OnInit, Input } from "@angular/core";
import { OnBoardingStatus } from "src/app/interfaces/supplier";
import { environment } from "src/environments/environment";
import * as moment from "moment";

@Component({
  selector: "app-onboard-process-circle",
  templateUrl: "./onboard-process-circle.componet.html",
  styleUrls: ["./onboard-process-circle.componet.sass"],
})
export class OnboardProcessCircleComponent implements OnInit {
  @Input() circleStep: any;
  @Input() isLast = false;
  eStatus = OnBoardingStatus;
  dateIsvalid = true;
  languageSwitch = environment.languageSwitch;

  constructor() {}

  ngOnInit() {}
  getUpdateDate(date: any) {
    if (date !== undefined && date !== "") {
      if (moment(date).isValid()) {
        return moment(date).utc().toDate();
      } else {
        this.dateIsvalid = false;
        return date;
      }
    }
  }
}
