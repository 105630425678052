// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.formControlInputS {
  width: 70% !important;
}

.formControlInputL {
  width: 95% !important;
}

.formControlInputM {
  width: 85% !important;
}

.formControlInputSL {
  width: 112% !important;
}

.spanDescInline {
  display: inline-block !important;
  font-weight: 100;
}

.requierdVerify {
  border: 1px solid #F3ABD1;
}

.link-mare-panel {
  position: absolute;
  /* height: 100px */
  width: 350px;
  background: #fff;
  color: #000;
  border-radius: 5px;
  padding: 5px 0;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.12), 0 3px 6px rgba(0, 0, 0, 0.24);
  outline: 0;
  z-index: 9999;
  left: 10rem;
  top: 1.2rem;
}
.link-mare-panel ul.link-mare-item {
  width: 100%;
  list-style: none;
  padding-left: 20px;
}
.link-mare-panel ul.link-mare-item li {
  float: left;
  width: 100%;
  height: 30px;
  line-height: 30px;
  color: #666;
  cursor: pointer;
}
.link-mare-panel ul.link-mare-item li i {
  font-size: 1.2rem;
  margin-right: 7px;
}

.link-mare-panel {
  left: 25rem;
  top: 1.2rem;
}
.link-mare-panel p {
  line-height: 1.5;
  font-weight: normal;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
