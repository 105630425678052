import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { SupplierHeaderService } from "../../services/header.service";
import { LanguageService } from "../../../../dynamic-components/utils/language.service";

@Component({
  selector: "app-onboarding",
  templateUrl: "./onboarding-component.html",
  styleUrls: ["./onboarding-component.sass"],
})
export class OnboardingComponent implements OnInit {
  constructor(
    private router: Router,
    private languageService: LanguageService,
    private supplierHeaderService: SupplierHeaderService
  ) {}

  async ngOnInit() {
    this.supplierHeaderService.showProfileButtons = false;
    this.supplierHeaderService.title = this.languageService.getValue(
      "static.supplier.onboarding_process.My_Onboarding_Process"
    );
    this.supplierHeaderService.icon = "assignment";
  }

  backToMyProfile() {
    this.router.navigate(["supplier/landing"]);
  }
}
