import { FilterFactory } from "./FilterFactory";
import { ComponentConfig } from "../ComponentConfig";
import { DynamicContext } from "../interfaces/DynamicContext";
import { UntypedFormGroup } from "@angular/forms";

export type Filter = (params: any) => boolean;

export class Filters {
  private static filterMap = new Map();

  static registerFilterFactory(name: string, filter: FilterFactory) {
    this.filterMap.set(name, filter);
  }

  static getFilterFactory(name: string): FilterFactory {
    return this.filterMap.get(name);
  }

  static doFilters(
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ): boolean {
    const filterDefs = config.filters;
    if (!filterDefs || filterDefs.length === 0) {
      return true;
    }
    for (const fd of filterDefs) {
      const filterFactory = this.getFilterFactory(fd.name);
      if (filterFactory) {
        const filter = filterFactory.call(
          filterFactory.objInstance,
          config,
          context,
          formGroup
        );
        const res = filter(fd.params);
        if (!res) {
          return false;
        }
      }
    }
    return true;
  }
}
