import { Pipe, PipeTransform } from "@angular/core";
import { LanguageService } from "../../dynamic-components/utils/language.service";
import { environment } from "src/environments/environment";
@Pipe({ name: "blank" })
export class BlankValuePipe implements PipeTransform {
  constructor(private languageService: LanguageService) {}
  transform(value) {
    if (!value) {
      return environment.languageSwitch
        ? this.languageService.getValue("static.history.blank")
        : "Blank";
    } else {
      return value;
    }
  }
}
