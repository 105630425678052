import {
  Component,
  ElementRef,
  OnInit,
  AfterViewInit,
  OnDestroy,
  Input,
  AfterViewChecked,
  EventEmitter,
  Renderer2,
  NgZone,
  ViewChild,
} from "@angular/core";
import { Subscription } from "rxjs";
import { DomHandler } from "../dialog/domHandler";
import { trigger, state, style } from "@angular/animations";
import {
  Confirmation,
  DialogType,
  ButtonCommands,
  ButtonItem,
  DialogService,
  SendInvitationService,
} from "src/app/services/dialog.service";
import { CommonService } from "../../../services/common.service";
import { LoadingService } from "src/app/services/loading.service";
import { OnBoardingService } from "./../../../modules/buyer/services/onboarding.service";
import { BuyerService } from "src/app/services/buyer.service";
import { SupplierService } from "src/app/services/supplier.service";
import { environment } from "src/environments/environment";
import { MigratedInvitation } from "src/app/interfaces/MigratedInvitation";
import { resolve } from "q";
import {
  LoadingUrlMapping,
  SapResultModel,
  createAPReviewModel,
  InvitedStatusMapping,
} from "src/app/interfaces/mapping";
import { ProfileService } from "src/app/modules/supplier/services/profile.service";
import { DictionaryService } from "src/app/services/dictionary.service";
import { LanguageService } from "../../../dynamic-components/utils/language.service";
import { allowedMimeType } from "../../shared";
import { Upload } from "./upload.interface";
import { FileUploadComponent } from "../file-upload/file-upload.component";

enum CheckEmailStatus {
  EmailExist = "EmailExist",
  DomainExist = "DomainExist",
  NoProblem = "NoProblem",
  InBlacklist = "InBlacklist",
  InTSM = "InTSM",
}
enum clickType {
  replace = "replace",
  invitation = "invitation",
}

@Component({
  selector: "app-dialog-invitation",
  templateUrl: "./dialog-invitation.component.html",
  styleUrls: ["./dialog-invitation.component.sass"],
  animations: [
    trigger("dialogState", [
      state(
        "hidden",
        style({
          opacity: 0,
        })
      ),
      state(
        "visible",
        style({
          opacity: 1,
        })
      ),
    ]),
  ],
})
export class DialogInvitationComponent
  implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy
{
  confirmation: Confirmation;
  subscription: Subscription;

  @Input() header: string;
  @Input() icon: string;
  @Input() message: string;
  @Input() buttons: ButtonItem[];
  @Input() width: any;
  @Input() height: any;
  @Input() closeOnEscape = true;
  @Input() rtl: boolean;
  @Input() closable = true;
  @Input() responsive = true;
  @Input() appendTo: any;
  @Input() key: string;

  _visible: boolean;
  documentEscapeListener: any;
  documentResponsiveListener: any;
  mask: any;
  contentContainer: any;
  positionInitialized: boolean;
  executePostShowActions: boolean;
  headerIcon: string;
  headerIconClass: string;
  sapResult: SapResultModel;
  migratedInvitation: MigratedInvitation = {};
  DialogType = DialogType;
  title = "title";
  dialogType: DialogType;
  isValidated: boolean;
  dictInvitationYesOrNo: any;
  dictInvitationDifference: any;
  CheckEmailStatus = CheckEmailStatus;
  isEmailPatterned: boolean;
  isCCMailPatterned: boolean;
  isCCMailMorethan20: boolean;
  isCCMailValid = true;
  isInvoiceReferencePatterned: boolean;
  private alert: any;
  migrateCCEmail: any;
  isEmptyChoice: boolean;
  migrateCCEmailMessage: string;
  firstLoadFlag = false;
  templateOptions: any;
  showTooltip: boolean;
  @ViewChild("estimateSpendTooltip") estimateSpendTooltip: ElementRef;
  @ViewChild(FileUploadComponent) fileUploadComponent!: FileUploadComponent;

  Close: ButtonItem = {
    label: "Close",
    action: () => {
      resolve("close");
    },
  };

  emailPattern = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,})$/;
  invoiceReferencePattern = /^(?!.*\<).*$/;
  uploadEvidenceAttachments: Array<Upload>;


  constructor(
    public el: ElementRef,
    public domHandler: DomHandler,
    public zone: NgZone,
    public renderer: Renderer2,
    private dictionaryService: DictionaryService,
    private onboardingService: OnBoardingService,
    private loadingService: LoadingService,
    private dialogService: DialogService,
    private buyerService: BuyerService,
    private supplierService: SupplierService,
    private profileService: ProfileService,
    private languageService: LanguageService,
    private sendInvitationService: SendInvitationService
  ) {
    this.subscription = sendInvitationService.requireInvitation$.subscribe(
      (confirmation) => {
        if (confirmation.key === this.key) {
          this.isValidated = false;
          this.isEmailPatterned = false;
          this.isCCMailPatterned = false;
          this.isCCMailMorethan20 = false;
          this.isInvoiceReferencePatterned = false;
          this.confirmation = confirmation;
          this.dialogType = this.confirmation.dialogType;
          this.title = confirmation.options.title;
          this.sapResult = createAPReviewModel();
          this.migrateCCEmail = this.sapResult.migrateCCEmail;
          this.visible = true;
          this.isCCMailValid = true;
          this.firstLoadFlag = true;
        }
      }
    );
    this.templateOptions = {
      config: {
        label: "Please upload",
        allowedMimeType: ["pdf", "img","doc"]
      }
    };
  }

  @Input() get visible(): boolean {
    return this._visible;
  }

  set visible(val: boolean) {
    this._visible = val;

    if (this._visible) {
      if (!this.positionInitialized) {
        this.center();
        this.positionInitialized = true;
      }

      this.el.nativeElement.children[0].style.zIndex = ++DomHandler.zindex;
      this.bindGlobalListeners();
      this.executePostShowActions = true;
    }

    if (this._visible) {
      this.enableModality();
    } else {
      this.disableModality();
    }
  }

  ngAfterViewInit() {
    this.contentContainer = this.domHandler.findSingle(
      this.el.nativeElement,
      ".ui-dialog-content"
    );

    if (this.appendTo) {
      if (this.appendTo === "body") {
        document.body.appendChild(this.el.nativeElement);
      } else {
        this.domHandler.appendChild(this.el.nativeElement, this.appendTo);
      }
    }
  }
  async ngOnInit() {
    await this.dictionaryService.dictionaryObserver$.subscribe((result) => {
      this.dictInvitationYesOrNo = result.filter(
        (dict) => dict.Code === "InvitationYesOrNo"
      )[0].Items;
      this.dictInvitationDifference = result.filter(
        (dict) => dict.Code === "InvitationDifference"
      )[0].Items;
    });
  }

  ngAfterViewChecked() {
    if (this.executePostShowActions) {
      this.domHandler
        .findSingle(this.el.nativeElement.children[0], "button")
        .focus();
      this.executePostShowActions = false;
    }
  }

  center() {
    const container = this.el.nativeElement.children[0];

    let elementWidth = this.domHandler.getOuterWidth(container);
    let elementHeight = this.domHandler.getOuterHeight(container);
    if (elementWidth === 0 && elementHeight === 0) {
      container.style.visibility = "hidden";
      container.style.display = "block";
      elementWidth = this.domHandler.getOuterWidth(container);
      elementHeight = this.domHandler.getOuterHeight(container);
      container.style.display = "none";
      container.style.visibility = "visible";
    }
    const viewport = this.domHandler.getViewport();
    const x = (viewport.width - elementWidth) / 2;
    // Minus 200 px makes the popup window a little higher than current position
    const y = (viewport.height - elementHeight) / 2;

    container.style.left = x + "px";
    container.style.top = y + "px";
  }

  enableModality() {
    if (!this.mask) {
      this.mask = document.createElement("div");
      this.mask.style.zIndex =
        this.el.nativeElement.children[0].style.zIndex - 1;
      this.domHandler.addMultipleClasses(
        this.mask,
        "ui-widget-overlay ui-dialog-mask"
      );
      // Set mask height position to fix the dialog layout issue when scroll down the page
      this.mask.style.height = document.body.clientHeight + "px";
      document.body.appendChild(this.mask);
      this.domHandler.addClass(document.body, "ui-overflow-hidden");
    }
  }

  disableModality() {
    if (this.mask) {
      document.body.removeChild(this.mask);
      this.domHandler.removeClass(document.body, "ui-overflow-hidden");
      this.mask = null;
    }
  }

  close(event: Event) {
    this.hide();
    event.preventDefault();
  }

  hide() {
    this.visible = false;
    this.unbindGlobalListeners();
    this.migratedInvitation.IsChangeRequest = "";
    this.migratedInvitation.InvoiceReference = "";
    this.migratedInvitation.Difference = [];
    this.alert = "";
    this.confirmation = null;
    this.firstLoadFlag = false;
    this.fileUploadComponent.resetuploadingFiles();
  }

  moveOnTop() {
    this.el.nativeElement.children[0].style.zIndex = ++DomHandler.zindex;
  }

  bindGlobalListeners() {
    if (this.closeOnEscape && this.closable && !this.documentEscapeListener) {
      this.documentEscapeListener = this.renderer.listen(
        "document",
        "keydown",
        (event) => {
          if (event.which === 27) {
            if (
              this.el.nativeElement.children[0].style.zIndex ===
                DomHandler.zindex &&
              this.visible
            ) {
              this.close(event);
            }
          }
        }
      );
    }

    if (this.responsive) {
      this.zone.runOutsideAngular(() => {
        this.documentResponsiveListener = this.center.bind(this);
        window.addEventListener("resize", this.documentResponsiveListener);
      });
    }
  }

  unbindGlobalListeners() {
    if (this.documentEscapeListener) {
      this.documentEscapeListener();
      this.documentEscapeListener = null;
    }

    if (this.documentResponsiveListener) {
      window.removeEventListener("resize", this.documentResponsiveListener);
      this.documentResponsiveListener = null;
    }
  }

  ngOnDestroy() {
    this.disableModality();

    if (this.documentResponsiveListener) {
      this.documentResponsiveListener();
    }

    if (this.documentEscapeListener) {
      this.documentEscapeListener();
    }

    if (this.appendTo && this.appendTo === "body") {
      document.body.removeChild(this.el.nativeElement);
    }

    this.subscription.unsubscribe();
  }

  click(button: ButtonItem) {
    if (button.clickEvent) {
      button.clickEvent.emit();
    }

    this.hide();
    this.confirmation = null;
  }

  saveSectionEvent(e, type) {
    this.isEmptyChoice = false;
    this.migratedInvitation[type] = e;
  }

  eventHandler(value: string) {
    this.migrateCCEmail = value;
    this.checkCCOnblur(value);
  }

  checkValidate(value: string) {
    this.checkCCOnblur(value);
  }

  async checkCCOnblur(migrateCCEmailInput) {
    this.isCCMailPatterned = false;
    this.isCCMailMorethan20 = false;
    if (migrateCCEmailInput) {
      const migrateCCEmails = migrateCCEmailInput as string;
      const ccitem = migrateCCEmails.split(";");
      let result = true;
      if (ccitem.length > 20) {
        result = false;
        this.isCCMailMorethan20 = true;
        this.migrateCCEmailMessage = "You can add up to 20 email addresses.";
      }
      ccitem.forEach((element) => {
        const itemresult = this.emailPattern.test(element);
        if (!itemresult) {
          result = false;
          this.isCCMailPatterned = true;
          this.migrateCCEmailMessage =
            "Invalid email format, please select a valid email address.";
        }
      });
      if (this.isCCMailPatterned && this.isCCMailMorethan20) {
        this.migrateCCEmailMessage =
          "Invalid email format, please input valid email.You can add up to 20 email addresses.";
      }
      this.isCCMailValid = result;
      return result;
    } else {
      this.isCCMailValid = true;
      return true;
    }
  }

  async checkOnblur() {
    this.isEmailPatterned = false;
    this.alert = "";
    if (this.sapResult.comments === "") {
      return false;
    }

    if (!this.emailPattern.test(this.sapResult.comments)) {
      this.isEmailPatterned = true;
      return false;
    }
    this.alert = await this.onboardingService.checkEmail(
      this.sapResult.comments,
      this.onboardingService.supplierModel.Mapping.TsmId
    );
    if (
      this.alert === CheckEmailStatus.InBlacklist ||
      this.alert === CheckEmailStatus.InTSM
    ) {
      return false;
    } else {
      return true;
    }
  }

  async checkInvoiceReferenceOnblur() {
    this.isInvoiceReferencePatterned = false;
    // if (this.migratedInvitation.InvoiceReference === '') {
    //   return false
    // }
    if (
      !this.invoiceReferencePattern.test(
        this.migratedInvitation.InvoiceReference
      )
    ) {
      this.isInvoiceReferencePatterned = true;
      return false;
    } else {
      return true;
    }
  }

  async validateReplaceEmail() {
    const past = await this.checkOnblur();
    return this.sapResult.comments && past;
  }

  /*   async validateCCEmail() {
    if (this.migrateCCEmail) {
      const past = await this.checkCCOnblur(this.migrateCCEmail)
      return past
    }
  } */

  async validateInvoiceReference() {
    if (this.migratedInvitation.InvoiceReference) {
      const past = await this.checkInvoiceReferenceOnblur();
      return past;
    }
  }

  validate() {
    this.isValidated = true;
    if (
      !this.migratedInvitation.IsChangeRequest ||
      (this.migratedInvitation.IsChangeRequest === "Y" &&
        !(
          this.migratedInvitation.InvoiceReference &&
          this.migratedInvitation.Difference.length > 0
        ))
    ) {
      return false;
    } else {
      return true;
    }
  }

  cancel() {
    this.hide();
  }

  async sendInvitation() {
    let type;
    let saveUrl;
    if (!this.validate()) {
      return;
    }
    /*     if (this.migrateCCEmail) {
      const past = await this.validateCCEmail()
      if (!past) {
        return
      }
    } */
    if (!this.isCCMailValid) {
      return;
    }
    if (this.migratedInvitation.InvoiceReference) {
      const past = await this.validateInvoiceReference();
      if (!past) {
        return;
      }
    }
    if (this.dialogType === DialogType.sendInvitation) {
      type = clickType.invitation;
      saveUrl = `${environment.gateway}${LoadingUrlMapping.inviteUser}`;
      if (this.onboardingService.supplierModel.Mapping.supplierEmail === "") {
        this.hide();
        if (environment.languageSwitch && environment.role == "supplier") {
          this.dialogService.confirm(
            DialogType.warning,
            this.languageService.getValue(
              "static.shared.components.dialog-invitation.poc_mail"
            ),
            [this.Close],
            this.languageService.getValue(
              "static.shareds.component.dialog-invitation.Failed"
            )
          );
        } else {
          this.dialogService.confirm(
            DialogType.warning,
            "The POC mail of this supplier is empty, please use Replace POC Email function to send invitation.",
            [this.Close],
            "Failed"
          );
        }
        return;
      }
    }

    if (this.dialogType === DialogType.replace) {
      // replace 2 columns
      type = clickType.replace;
      saveUrl = `${environment.gateway}${LoadingUrlMapping.replaceInviteUser}`;
      const result = await this.validateReplaceEmail();
      if (!result) {
        this.isValidated = true;
        return;
      }
    }

    this.loadingService.openLoading(saveUrl);
    const supplierProfile: any =
      await this.supplierService.querySupplierProfile(
        this.onboardingService.supplierModel.Mapping.supplierCode
      );
    if (supplierProfile.isSuccess) {
      this.onboardingService.supplierModel.SupplierProfile =
        supplierProfile.data.SupplierProfile;
    }
    if (type === clickType.replace) {
      if (
        this.onboardingService.supplierModel.SupplierProfile.SupplierGeneral ===
        undefined
      ) {
        const result: any = await this.supplierService.updateProfileNode(
          this.onboardingService.supplierModel.Mapping.supplierCode
        );

        if (result.isSuccess) {
          await this.onboardingService.loadSupplierProfile();
        }
      }
      this.onboardingService.supplierModel.SupplierProfile.SupplierGeneral.PrimaryContactEmail = this.sapResult.comments
      this.onboardingService.supplierModel.RequestForm.RequestorDetailsInfo.SupplierPOCEmail = this.sapResult.comments
      this.onboardingService.supplierModel.Mapping.supplierEmail = this.sapResult.comments
      this.onboardingService.supplierModel.uploadEvidenceAttachments = this.uploadEvidenceAttachments
    }

    this.onboardingService.supplierModel.Mapping.IsInvited = true
    this.onboardingService.supplierModel.Mapping.InvitedDate = new Date().toUTCString()
    this.onboardingService.supplierModel.Mapping.InvitedStatus = InvitedStatusMapping.invited
    this.onboardingService.supplierModel.Mapping.IsChangeRequest = this.migratedInvitation.IsChangeRequest
    this.onboardingService.supplierModel.Mapping.InvoiceReference = this.migratedInvitation.InvoiceReference
    this.onboardingService.supplierModel.Mapping.Difference = this.migratedInvitation.Difference
    this.onboardingService.supplierModel.uploadEvidenceAttachments = this.uploadEvidenceAttachments

    this.migratedInvitation.InvitedEmail = this.onboardingService.supplierModel.RequestForm.RequestorDetailsInfo.SupplierPOCEmail
    this.migratedInvitation.SendMailDate = new Date()
    this.migratedInvitation.ReplacePOCEmail = this.sapResult.comments
    this.migratedInvitation.SupplierCode = this.onboardingService.supplierModel.Mapping.supplierCode
    // console.log('this.migratedInvitation=======>', this.migratedInvitation)


    await this.buyerService.saveMigratedInvitation(this.migratedInvitation)
    const res: any = await this.buyerService.sendInvitation(this.onboardingService.supplierModel, this.migrateCCEmail, type)
    if (res.isSuccess) {
      if (environment.languageSwitch && environment.role == "supplier") {
        await this.dialogService.dialog(
          this.languageService.getValue(
            "static.shared.components.dialog-invitation.invitation_successfully"
          ),
          ButtonCommands.Close,
          DialogType.success
        );
      } else {
        await this.dialogService.dialog(
          "Invitation Send Successfully",
          ButtonCommands.Close,
          DialogType.success
        );
      }
      this.hide();
    } else {
      this.loadingService.closeLoading();
      if (res.error.errorType === "wrong invitation url") {
        this.onboardingService.supplierModel.Mapping.IsInvited = false;
        if (environment.languageSwitch && environment.role == "supplier") {
          await this.dialogService.dialog(
            this.languageService.getValue(
              "static.shared.components.dialog-invitation.supplier_email"
            ),
            ButtonCommands.Close,
            DialogType.warning,
            this.languageService.getValue(
              "static.shared.components.dialog-invitation.warn"
            )
          );
        } else {
          await this.dialogService.dialog(
            `The supplier email address you have entered is a group address and therefore not supported in the Supplier Hub.
                                          Please update the Supplier POC Email field with an email address that is associated with an individual within the Suppliers company.`,
            ButtonCommands.Close,
            DialogType.warning,
            "Warning"
          );
        }
        this.hide();
      }
      else if(res.error.errorType === "Supplier missing TSM details"){
        this.onboardingService.supplierModel.Mapping.IsInvited = false;
       // "Supplier missing TSM details, please reach out to support team for resolution"
          this.hide();
      }
      else if(res.error.errorType === "POC is b2b type user"){
        // "Invalid supplier POC details, please reach out to support team for resolution"
          this.onboardingService.supplierModel.Mapping.IsInvited = false;
          this.hide();
      }
    }
  }
  addAttachmentInfo(fileList: any[]): void {
    this.uploadEvidenceAttachments = fileList.map((item: any) => {
      return {
        name: item.name,
        imgUrl: item.imgUrl,
        key: item.key
      }
    });
    // console.log(this.uploadEvidenceAttachments);
  }

  estimateChooseOver(e, estimateSpendTooltip) {
    const TooltipEle = estimateSpendTooltip;
    if (estimateSpendTooltip) {
      if (
        e.clientX < TooltipEle.nativeElement.getBoundingClientRect().left ||
        e.clientX >
        TooltipEle.nativeElement.getBoundingClientRect().left +
        TooltipEle.nativeElement.offsetWidth -
        5 ||
        e.clientY < TooltipEle.nativeElement.getBoundingClientRect().top ||
        e.clientY >
        TooltipEle.nativeElement.getBoundingClientRect().top +
        TooltipEle.nativeElement.offsetHeight -
        5
      ) {
        this.showTooltip = false;
      }
    } else {
      this.showTooltip = false;
    }
  }
}
