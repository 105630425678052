import { Component, OnInit } from "@angular/core";
import { HoldOnService } from "src/app/services/HoldOn";
import { OnBoardingService } from "../../../services/onboarding.service";
import { AuthService } from "src/app/services/auth/auth.service";
import { initHoldOn } from "src/app/interfaces/HoldOn";
import { environment } from "src/environments/environment";
import { LoadingUrlMapping } from "src/app/interfaces/mapping";
import { LoadingService } from "src/app/services/loading.service";

@Component({
  selector: "app-extend-hold",
  templateUrl: "./extend-hold.component.html",
  styleUrls: ["./extend-hold.component.sass"],
})
export class ExtendHoldComponent implements OnInit {
  reason: string;
  holdOn: any;
  isValidated = false;
  holdAttachments: any;
  showTooltip: boolean;
  constructor(
    private holdOnService: HoldOnService,
    private onBoardingService: OnBoardingService,
    private loadingService: LoadingService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.isValidated = false;
    const supplier = this.onBoardingService.supplierModel;
    this.holdOn = initHoldOn(supplier.Mapping.supplierCode);
  }

  async submit() {
    if (!this.validate()) {
      return;
    }
    try {
      const saveRequestFormUrl = `${environment.gateway}${LoadingUrlMapping.saveEditHold}`;
      this.loadingService.openLoading(saveRequestFormUrl);
      this.holdOn.IsActive = true;
      this.holdOn.CreatedBy = this.authService.passport.profile.email;
      this.holdOn.ExpirationDate = new Date(
        new Date().getTime() + 1000 * 3600 * 24 * 7
      );
      this.holdOn.CreatedDate = new Date();
      this.holdOn.Source = "Extend Hold";
      this.holdOn.Comments = this.reason;
      this.holdOn.Attachment = JSON.stringify(this.holdAttachments);
      const result = await this.holdOnService.saveHoldOn(this.holdOn);
      if (result) {
        this.loadingService.closeLoading();
      }
      return { isValidate: true, isSuccess: result };
    } catch (e) {
      return { isValidate: true, isSuccess: false };
    }
  }
  addAttachmentInfo(fileList) {
    this.holdAttachments = fileList;
  }

  validate() {
    this.isValidated = true;
    if (!this.reason) {
      return false;
    }
    return true;
  }

  estimateChooseOver(e, estimateSpendTooltip) {
    const TooltipEle = estimateSpendTooltip;
    if (estimateSpendTooltip) {
      if (
        e.clientX < TooltipEle.nativeElement.getBoundingClientRect().left ||
        e.clientX >
          TooltipEle.nativeElement.getBoundingClientRect().left +
            TooltipEle.nativeElement.offsetWidth -
            5 ||
        e.clientY < TooltipEle.nativeElement.getBoundingClientRect().top ||
        e.clientY >
          TooltipEle.nativeElement.getBoundingClientRect().top +
            TooltipEle.nativeElement.offsetHeight -
            5
      ) {
        this.showTooltip = false;
      }
    } else {
      this.showTooltip = false;
    }
  }
}
