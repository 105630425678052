// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ui-confirmdialog {
  width: 65rem;
}

.ui-dialog {
  position: fixed;
  padding: 1.4rem;
  border: 1px solid #D5D5D5;
  background: #fcfcfc;
}

.header-icon {
  border: 2px solid #fcfcfc;
  margin-top: -60px;
  display: inline-block;
  background: #ffffff;
  vertical-align: middle;
}

.header-icon-success {
  color: #00a5a6;
}

.form_sub_leftt {
  display: inline-flex;
}

.header-icon-warning {
  color: #CC1E32;
}

.header-icon-question {
  color: #AB8C30;
}

.header {
  color: #333;
  font-size: 18px;
  font-weight: medium;
}

.content {
  padding: 2rem;
  max-height: 38rem;
  overflow-y: auto;
}

.footer {
  text-align: center;
}

.ui-shadow {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3);
}

.ui-corner-all {
  border-radius: 10px;
}

.text-center {
  text-align: center !important;
}

.right-button {
  text-align: right !important;
}

.spanDescInline {
  display: inline-block !important;
  font-weight: 100;
  margin-left: 5px;
}

.flex-column {
  overflow-y: auto;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
