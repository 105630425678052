import { Validators, ValidatorFn, AsyncValidatorFn } from "@angular/forms";
import { DynamicComponentService } from "../services/dynamic-component.service";
import { ComponentConfig, ValidatorMap } from "../ComponentConfig";
import { DynamicContext } from "../interfaces/DynamicContext";

export class ValidatorFactory {
  public service: DynamicComponentService;

  validatorMap: Map<string, ValidatorFn> = new Map();
  asyncValidatorMap: Map<string, AsyncValidatorFn> = new Map();
  objInstanceMap: Map<string, Object> = new Map();

  constructor() {}

  registerValidator(name: string, validator: ValidatorFn, objInstance: Object) {
    this.objInstanceMap.set(name, objInstance);
    this.validatorMap.set(name, validator);
  }

  registerAsyncValidator(
    name: string,
    validator: AsyncValidatorFn,
    objInstance: Object
  ) {
    this.registerValidator(name, validator, objInstance);
    this.asyncValidatorMap.set(name, validator);
  }

  createValidators(
    config: ComponentConfig,
    context: DynamicContext
  ): AsyncValidatorFn | ValidatorFn {
    const validators = [];
    if (config.required) {
      validators.push(Validators.required);
    }
    if (config.email) {
      // validators.push(Validators.email)
      // patch
      if (!config.validators) {
        config.validators = [];
      }
      config.validators.push({ name: "email" });
    }
    if (config.min) {
      validators.push(Validators.min(config.min));
    }
    if (config.max) {
      validators.push(Validators.max(config.max));
    }
    if (config.minlength) {
      validators.push(Validators.minLength(config.minlength));
    }
    if (config.maxlength) {
      validators.push(Validators.maxLength(config.maxlength));
    }
    if (config.pattern) {
      validators.push(Validators.pattern(config.pattern));
    }
    if (config.validators) {
      config.validators.forEach((item: ValidatorMap) => {
        if (!this.asyncValidatorMap.get(item.name)) {
          const vfn = this.validatorMap.get(item.name);
          if (!vfn) {
            return;
          }
          const objInstance = this.objInstanceMap.get(item.name);
          validators.push(vfn.call(objInstance, config, context));
        }
      });
    }
    return Validators.compose(validators);
  }

  createAsyncValidators(config: ComponentConfig, context: DynamicContext) {
    const validators = [];
    if (config.validators) {
      config.validators.forEach((item: ValidatorMap) => {
        if (this.asyncValidatorMap.get(item.name)) {
          const vfn = this.asyncValidatorMap.get(item.name);
          if (!vfn) {
            return;
          }
          const objInstance = this.objInstanceMap.get(item.name);
          validators.push(vfn.call(objInstance, config, context));
        }
      });
    }
    return Validators.composeAsync(validators);
  }
}
