import {Injectable} from '@angular/core';
import {Subject} from "rxjs";
import * as LDClient from "launchdarkly-js-client-sdk";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class LaunchDarklyService {
  public static LD_ISNEWVERSION = "spo-new-version";
  private static TOGGLE_KEY_PREFIX = "LD_";

  public ldSubject = new Subject<string>();
  public ldChange = this.ldSubject.asObservable();


  constructor() {

  }

  private getActualKey(name: string) {
    return LaunchDarklyService.TOGGLE_KEY_PREFIX + name;
  }

  private triggerRefresh() {
    this.ldSubject.next('REFRESH');
  }

  public getToggle(name: string) {
    return localStorage.getItem(this.getActualKey(name));
  }


  public setToggle(name: string, value: string) {
    localStorage.setItem(this.getActualKey(name), value);
  }

  public initLaunchDarkly(kind: string, key: string, name: string) {
    // Set clientSideID to your LaunchDarkly client-side ID

    const clientSideID =`${environment.ldClientId}`;
    // Set up the context properties. This context should appear on your
    // LaunchDarkly contexts dashboard soon after you run the demo.
    const context = {
      kind: kind,
      key: key,
      name: name
    };
    const ldClient = LDClient.initialize(clientSideID, context);

    ldClient.on('initialized', (value) => {
      console.log("initialized---")
      // div.appendChild(document.createTextNode('SDK successfully initialized!'), div.firstChild);
    });
    ldClient.on('failed', (err) => {
      console.log("failed---",err)
      // div.appendChild(document.createTextNode('SDK failed to initialize'), div.firstChild);
    });
    ldClient.on('ready', async (value) => {
      // let allFlagsResult = ldClient.allFlags();
      // for (let k in allFlagsResult) {
      //   console.log("ready：" + k + "=" + value[k].current)
      //   this.setToggle(k, value[k].current)
      // }
      const flagValue = await ldClient.variation(LaunchDarklyService.LD_ISNEWVERSION, true);
      this.setToggle(LaunchDarklyService.LD_ISNEWVERSION,flagValue)
      this.triggerRefresh()
    });

    // ldClient.on('change', (value) => {
    //   for (let k in value) {
    //     console.log("change：" + k + "=" + value[k].current)
    //     this.setToggle(k, value[k].current)
    //   }
    //   this.triggerRefresh()
    // });

    ldClient.on(`change:${LaunchDarklyService.LD_ISNEWVERSION}`, (newVal,oldVal) => {
      this.setToggle(LaunchDarklyService.LD_ISNEWVERSION,newVal)
      this.triggerRefresh()
    });

  }

}
