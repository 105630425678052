// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-menu {
  position: relative;
}

.userMenu {
  position: relative;
}

.menuPanel {
  position: absolute;
  z-index: 1000;
  font-size: 0.7em;
  right: -40px;
  top: 40px;
  width: 180px;
  cursor: default;
}
.menuPanel:after {
  content: "";
  position: absolute;
  right: 40px;
  top: -13px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 13px 13px 13px;
  border-color: transparent transparent #fff transparent;
  z-index: 9998;
}

.menuContent {
  background: #fff;
  color: #333;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 15px;
  text-align: left;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.list-group-item {
  display: block;
  font-size: 1rem;
  padding-left: 15px;
  padding-right: 12px;
  padding-bottom: 12px;
  padding-top: 12px;
  border: none;
}
.list-group-item:hover {
  background-color: #eee;
  border-color: #eee;
  color: #fff;
  cursor: pointer;
}

.header {
  font-size: 1.2rem;
  font-weight: 700;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
