import { Component, OnInit, OnChanges } from "@angular/core";
import { BaseComponent } from "src/app/dynamic-components/components/base/base.component";
import { DialogService } from "src/app/services/dialog.service";
import { BuyerService } from "src/app/services/buyer.service";
import { SupplierService } from "src/app/services/supplier.service";
import { AuthService } from "src/app/services/auth/auth.service";
import { OnBoardingService } from "src/app/modules/buyer/services/onboarding.service";
import { environment } from "src/environments/environment";
import { State } from "src/app/shared/shared";
import { strToHexCharCode } from "src/app/utils/index";
import { HttpService } from "src/app/services/api/http.service";
import { LoadingService } from "src/app/services/loading.service";
@Component({
  selector: "app-uploader-view",
  templateUrl: "./uploader-view.component.html",
  styleUrls: ["./uploader-view.component.sass"],
  host: {
    "[style.width]": "config.width",
  },
})
export class UploaderViewComponent extends BaseComponent implements OnInit {
  uploadingFiles?: Array<any> = [];
  showTooltip = false;
  rand =Math.round((Math.random()*10000)).toString();
  constructor(
    private auth: AuthService,
    private http: HttpService,
    private loadingService: LoadingService
  ) {
    super();
  }

  baseUrl = environment.gateway;

  ngOnInit() {
    if (this.value) {
      this.uploadingFiles = this.value;
    }
    this.rand =Math.round((Math.random()*10000)).toString();
  }

  async downloadFile(fileKey, fileName) {
    let role = "";
    const access_token = `Bearer ${this.auth.passport.access_token}`;
    const state = environment.role;
    let downloadPath: string;
    if (state === State.buyer && this.auth.passport.buyer) {
      role = this.auth.passport.buyer.RoleCode;
      downloadPath = "buyer/file/download";
    } else if (state === State.supplier && this.auth.passport.supplierMember) {
      role = this.auth.passport.supplierMember.RoleCode;
      downloadPath = "supplier/file/download";
    }
    const params = strToHexCharCode(
      `key=${fileKey}&Authorization=${access_token}&state=${state}&role=${role}`
    );
    // const url = `${this.baseUrl}/${downloadPath}?fileCode=${params}`
    // window.open(url, '_blank')
    const url = `${this.baseUrl}/${downloadPath}`;
    this.loadingService.openLoading(url);
    this.http
      .PostBlobPromise(url, { fileCode: params }, "blob")
      .then((data: BlobPart) => {
        const blob = new Blob([data]);
        // for ie
        fileName  = fileName.replace (/%20/g,' ');
        if ("msSaveOrOpenBlob" in navigator) {
          window.navigator.msSaveOrOpenBlob(blob, fileName);
        } else {
          const link = document.createElement("a");
          link.setAttribute("href", window.URL.createObjectURL(blob));
          link.setAttribute("download", fileName);
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          this.loadingService.closeLoading();
        }
      });
  }

  estimateChooseOver(e, estimateSpendTooltip) {
    const TooltipEle = estimateSpendTooltip;
    if (estimateSpendTooltip) {
      if (
        e.clientX < TooltipEle.nativeElement.getBoundingClientRect().left ||
        e.clientX >
          TooltipEle.nativeElement.getBoundingClientRect().left +
            TooltipEle.nativeElement.offsetWidth -
            5 ||
        e.clientY < TooltipEle.nativeElement.getBoundingClientRect().top ||
        e.clientY >
          TooltipEle.nativeElement.getBoundingClientRect().top +
            TooltipEle.nativeElement.offsetHeight -
            5
      ) {
        this.showTooltip = false;
      }
    } else {
      this.showTooltip = false;
    }
  }
}
