import { SupplierMappingDTO } from "./mapping";

export interface SupplierProfileModel {
  SupplierProfile?: any;
  Mapping?: SupplierMappingDTO;
  ChangeHistory?: any;
}

export function initializeSupplierProfileModel(): SupplierProfileModel{
  return {
    SupplierProfile: {},
    Mapping: {},
    ChangeHistory:{},
  };
}
