// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.labelCss {
  color: #666;
  font-family: Graphik;
  font-weight: lighter;
  font-style: italic;
}

.link-mare-panel {
  position: absolute;
  height: 100px;
  width: 350px;
  background: #fff;
  color: #000;
  border-radius: 5px;
  padding: 5px 0;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.12), 0 3px 6px rgba(0, 0, 0, 0.24);
  outline: 0;
  z-index: 9999;
  right: 9rem;
  top: 1.2rem;
}
.link-mare-panel p {
  line-height: 1.5;
  font-weight: normal;
}

.link-mare-panel-supplierChoose {
  height: 120px;
}

.link-mare-panel-supplierChoose-gdprHelpText {
  height: 93px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
