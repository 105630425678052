// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `label {
  display: inline-block;
  width: 20%;
}

.form-control {
  display: inline-block;
  width: 60%;
}

.people-field {
  display: inline-block;
  width: 60%;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
