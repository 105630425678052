// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tabPageSlide {
  margin: 0px;
  padding: 0px;
  list-style: none;
}

.tabPageSlide .tabPageSlide-answer {
  display: block;
  padding: 0 2rem;
  text-align: left;
  line-height: 1.5;
  color: #000;
  font-size: 15px;
}
.tabPageSlide .tabPageSlide-answer span.strong {
  font-weight: 600;
}
.tabPageSlide .tabPageSlide-answer span.upperRed {
  color: #CE004A;
  padding-top: 1rem;
  display: inline-block;
}
.tabPageSlide .tabPageSlide-answer span.purple {
  color: #ce004a;
  font-weight: 600;
}
.tabPageSlide .tabPageSlide-answer span.upper {
  padding-top: 1rem;
  display: inline-block;
}
.tabPageSlide .tabPageSlide-answer span.l-50 {
  display: inline-block;
  width: 50%;
  line-height: 1;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.tabPageSlide .tabPageSlide-answer span.l-50 a {
  text-decoration: underline;
  color: #006CAF;
}
.tabPageSlide .tabPageSlide-answer a {
  text-decoration: underline;
  color: #006CAF;
}
.tabPageSlide .tabPageSlide-answer a .strong {
  font-weight: 600;
}

.main_container {
  top: 2rem !important;
}

.file-message-con {
  text-align: -webkit-center;
  color: #4e4e87;
}

.file-message {
  padding-top: 5px;
  padding-left: 10px;
}

.material-icons {
  color: #4e4e87;
}

.file-upload {
  border: dashed 1px rgba(31, 31, 53, 0.3);
  height: 4rem;
}

.uploader {
  border-radius: 0.3rem;
  display: -webkit-box;
  -webkit-box-orient: horizontal;
  -webkit-box-pack: center;
  -webkit-box-align: center;
  cursor: pointer;
  margin: 0 105px;
}
.uploader input {
  display: none;
}

.email-container {
  height: 370px;
}

.tbHeader {
  padding: 7px 0;
  margin: 0 90px;
  font-size: 12px;
  text-align: left;
  font-weight: 700;
  border-bottom: 1px solid;
  border-bottom-width: 1.3px;
  border-bottom-color: #4e4e87;
}

.tContent {
  font-size: 12px;
  color: #212529;
  text-align: left;
  background: white;
  padding: 7px 0;
  margin: 0 90px;
  border-bottom: 1px solid;
  border-bottom-width: 1.3px;
  border-bottom-color: #4e4e87;
}
.tContent .ntfDate {
  color: #46465B;
}
.tContent .ntfEvent {
  color: #46465B;
}
.tContent .ntfMsg {
  color: black;
  font-weight: 800;
}
.tContent .ntfAction {
  color: #006CAF;
  font-weight: 600;
}
.tContent .ntfAction .btnAction {
  cursor: pointer;
}

.termitem {
  width: 25%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 1rem;
}

.atermitem {
  width: 20%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 1rem;
}

.main_button {
  text-align: right;
  padding: 1rem 8.7rem 0 0;
}

.term_container {
  min-height: 116vh;
  display: flex;
  flex-direction: column;
}

.file-img {
  width: 20px;
  height: 25px;
  float: left;
}

.progress {
  width: 8%;
  margin: auto 0;
  overflow: hidden;
  border-radius: 4px;
  text-align: -webkit-center;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
