// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list-container {
  background: #fff;
  position: absolute;
  z-index: 1000;
  font-size: 1em;
  right: -29px;
  top: 45px;
  width: 350px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  text-align: center;
  font-weight: bold;
}

.list {
  margin-top: 20px;
  margin-bottom: 20px;
  color: #0F5284;
  vertical-align: middle;
  font-weight: 600;
}

.list:hover {
  color: #006caf;
}

.title {
  margin-top: 20px;
  margin-bottom: 20px;
}

.criteria {
  position: fixed;
  left: 200px;
  right: 200px;
  top: 105px;
  background: #fff;
  z-index: 1009;
  padding: 0px;
  background: #f0f0f0;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 30px;
  text-align: left;
}

.criteriaCSS {
  position: fixed;
  left: 200px;
  right: 200px;
  top: 105px;
  background: #fff;
  z-index: 1009;
  padding: 0px;
  background: #f0f0f0;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 30px;
  text-align: left;
  max-height: 80%;
  overflow: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19);
  width: calc(100% - 400px);
}

.leftMenu ul {
  width: 375px;
  position: absolute;
  right: 350px;
  top: 100px;
  background: #FFFFFF;
  list-style: none;
}

.leftMenu li {
  width: 375px;
  margin-left: 25px;
  text-align: left;
  position: relative;
}

.leftMenu li > ul {
  left: 150px;
  top: 0;
  display: none;
}

.leftMenu li:hover > ul {
  display: block;
}

.alert-bar-color {
  background: rgba(78, 78, 135, 0.05);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
