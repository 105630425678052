import { Component, OnInit } from "@angular/core";
import { OnBoardingService } from "../../../../modules/buyer/services/onboarding.service";
import { BuyerService } from "../../../../services/buyer.service";
import { UntypedFormArray, UntypedFormGroup, UntypedFormControl } from "@angular/forms";
import { DynamicContext } from "../../../../dynamic-components/interfaces/DynamicContext";
import { ComponentConfig } from "../../../../dynamic-components/ComponentConfig";
import { BaseComponent } from "src/app/dynamic-components/components/base/base.component";
import { ProfileService } from "src/app/modules/supplier/services/profile.service";
import { cloneDeep } from "src/app/utils";

@Component({
  selector: "app-additional-gst-registration-of-collapse",
  templateUrl: "./additional-gst-registration-of-collapse.component.html",
  styleUrls: ["./additional-gst-registration-of-collapse.component.sass"],
  host: {
    "[style.width]": "config.width",
  },
})
export class AdditionalGSTRegistrationOfCollapseComponent
  extends BaseComponent
  implements OnInit
{
  additionalGstRegistrationInformationListVO = [];
  additionalGstRegistrationInformationListFormArray: UntypedFormArray;
  additionalGstRegistrationInformationList: any[];
  constructor(
    private onBoardingService: OnBoardingService,
    private buyerService: BuyerService,
    private profileService: ProfileService
  ) {
    super();
  }

  ngOnInit() {
    this.additionalGstRegistrationInformationListFormArray =
      this.getAdditionalGstRegistrationInformationListFormArray();
    this.additionalGstRegistrationInformationList =
      this.getAdditionalGstRegistrationInformationList();
    if (
      this.additionalGstRegistrationInformationList &&
      this.additionalGstRegistrationInformationList.length > 0
    ) {
      this.initadditionalGstRegistrationInformationByadditionalGstRegistrationInformationList();
    }
  }
  getAdditionalGstRegistrationInformationList(): Array<any> {
    // console.log(this.context.getValue("supplierModel"));
    // console.log(this.context.getValue("parentModel"));
    if (this.context.getValue("supplierModel")) {
      return this.context.getValue("supplierModel").SupplierOrganization
        .AdditionalGSTRegistrationList;
    }
    return this.context.getValue("parentModel").SupplierProfile
      .SupplierOrganization.AdditionalGSTRegistrationList;
  }
  getAdditionalGstRegistrationInformationListFormArray(): UntypedFormArray {
    return this.formGroup.get(this.config.name) as UntypedFormArray;
  }

  isNotViewAndFormView(context: DynamicContext): boolean {
    if (this.context.mode !== "view" && this.context.mode !== "formView") {
      return true;
    } else {
      return false;
    }
  }
  initadditionalGstRegistrationInformationByadditionalGstRegistrationInformationList() {
    for (const additionalGstRegistrationInformation of this
      .additionalGstRegistrationInformationList) {
      const additionalGstRegistrationInformationformGroup = new UntypedFormGroup({});
      const idFormControl = new UntypedFormControl();
      idFormControl.patchValue(
        additionalGstRegistrationInformation.additionalGSTRegistrationInformationId
      );
      additionalGstRegistrationInformationformGroup.addControl(
        "additionalGSTRegistrationInformationId",
        idFormControl
      );
      this.additionalGstRegistrationInformationListFormArray.push(
        additionalGstRegistrationInformationformGroup
      );
      if (
        !additionalGstRegistrationInformation.StreetAddress &&
        !additionalGstRegistrationInformation.CountryLocation &&
        !additionalGstRegistrationInformation.AdditionalState &&
        !additionalGstRegistrationInformation.City &&
        !additionalGstRegistrationInformation.ZipCode &&
        !additionalGstRegistrationInformation.AdditionalGSTRegistrationNumber &&
        !additionalGstRegistrationInformation.AdditionalGSTVendor &&
        !additionalGstRegistrationInformation.AddGSTCertificateAttachments
      ) {
        continue;
      }
      const context = new DynamicContext();
      context.parent = this.context;
      const additionalGstRegistrationInformationDTO = {
        additionalGstRegistrationInformation:
          additionalGstRegistrationInformation,
        formGroup: additionalGstRegistrationInformationformGroup,
        context: context,
        config: cloneDeep(this.config.fieldset),
        dashboardConfig: this.initViewConfig(
          additionalGstRegistrationInformation,
          cloneDeep(this.config.fieldset)
        ),
        isAdditionalGstRegistrationInformationShow: true,
      };
      // console.log(additionalGstRegistrationInformationDTO.context.parent.mode);
      this.additionalGstRegistrationInformationListVO.push(
        additionalGstRegistrationInformationDTO
      );
    }
    if (!this.profileService.isView) {
      this.context.mode = "edit";
    }
  }
  initadditionalGstRegistrationInformationByclicking() {
    const additionalGstRegistrationInformationformGroup = new UntypedFormGroup({});
    const idFormControl = new UntypedFormControl();
    idFormControl.patchValue(Math.random(), {
      onlySelf: true,
      emitEvent: true,
    });
    additionalGstRegistrationInformationformGroup.addControl(
      "additionalGSTRegistrationInformationId",
      idFormControl
    );
    this.additionalGstRegistrationInformationListFormArray.push(
      additionalGstRegistrationInformationformGroup
    );
    const context = new DynamicContext();
    context.parent = this.context;
    const additionalGstRegistrationInformationDTO = {
      additionalGstRegistrationInformation: {},
      formGroup: additionalGstRegistrationInformationformGroup,
      context: context,
      config: cloneDeep(this.config.fieldset),
      isAdditionalGstRegistrationInformationShow: true,
    };
    this.additionalGstRegistrationInformationListVO.push(
      additionalGstRegistrationInformationDTO
    );
  }
  // eslint-disable-next-line @typescript-eslint/no-shadow
  initViewConfig(
    additionalGstRegistrationInformation: any,
    config: Array<ComponentConfig>
  ) {
    const conf = new Array<ComponentConfig>();
    config.forEach((p) => {
      if (p.fieldset) {
        p.fieldset.forEach((z) => {
          if (
            additionalGstRegistrationInformation[z.name] instanceof Array &&
            additionalGstRegistrationInformation[z.name].length > 0
          ) {
            conf.push(z);
          }
          if (
            !(additionalGstRegistrationInformation[z.name] instanceof Array) &&
            additionalGstRegistrationInformation[z.name]
          ) {
            conf.push(z);
          }
        });
      } else {
        if (
          additionalGstRegistrationInformation[p.name] instanceof Array &&
          additionalGstRegistrationInformation[p.name].length > 0
        ) {
          conf.push(p);
        }
        if (
          !(additionalGstRegistrationInformation[p.name] instanceof Array) &&
          additionalGstRegistrationInformation[p.name]
        ) {
          conf.push(p);
        }
      }
    });
    return conf;
  }
  addAdditionalGstRegistrationInformation() {
    // if (this.additionalGstRegistrationInformationListFormArray.length === 10) {
    //     return
    // }
    this.initadditionalGstRegistrationInformationByclicking();
  }
  removeadditionalGstRegistrationInformation(
    additionalGstRegistrationInformation: any
  ) {
    if (this.additionalGstRegistrationInformationListFormArray.length === 0) {
      return;
    }
    const removedId = additionalGstRegistrationInformation.formGroup.get(
      "additionalGSTRegistrationInformationId"
    ).value;
    this.additionalGstRegistrationInformationListVO =
      this.additionalGstRegistrationInformationListVO.filter(
        (p) =>
          p.formGroup.get("additionalGSTRegistrationInformationId").value !==
          removedId
      );
    const index =
      this.additionalGstRegistrationInformationListFormArray.controls.findIndex(
        (p) =>
          p.get("additionalGSTRegistrationInformationId").value === removedId
      );
    this.additionalGstRegistrationInformationListFormArray.removeAt(index);
  }
  resetComponent() {
    this.additionalGstRegistrationInformationListVO.forEach(
      (gstRegistrationInformationToRemove) => {
        this.removeadditionalGstRegistrationInformation(
          gstRegistrationInformationToRemove
        );
      }
    );
  }
}
