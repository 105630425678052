import {
  Component,
  OnInit,
  Input,
  Output,
  ViewChild,
  EventEmitter,
} from "@angular/core";
import { FileUploader, FileUploaderOptions, FileLikeObject, FileItem } from "ng2-file-upload";
import { BuyerService } from "src/app/services/buyer.service";
import {
  DialogService,
  DialogType,
  ButtonCommands,
} from "src/app/services/dialog.service";
import { Router } from "@angular/router";
import {
  FileType,
  FileUploadError,
} from "src/app/services/file-upload.service";
import { allowedMimeType } from "src/app/shared/shared";
import { DynamicContext } from "src/app/dynamic-components/interfaces/DynamicContext";
import { ComponentConfig } from "src/app/dynamic-components/ComponentConfig";
import { UntypedFormGroup } from "@angular/forms";
import { DynamicComponentService } from "src/app/dynamic-components/services/dynamic-component.service";
import { LanguageService } from "../../../../dynamic-components/utils/language.service";
import { environment } from "src/environments/environment";
@Component({
  selector: "app-dataMigration",
  templateUrl: "./dataMigration.component.html",
  styleUrls: ["./dataMigration.component.sass"],
})
export class DataMigrationComponent implements OnInit {
  uploader: FileUploader;
  uploading = false;
  uploadMessage: string;
  fileName: string;
  showProgress = false;
  retryItems = [];
  resending = false;
  showAddForm = false;
  isValidated = false;
  errorType: FileUploadError;
  failedFile: FileLikeObject;

  uploadingFiles?: Array<any> = [];

  @Input() messageLargerMaxSize: string;
  @Input() messageInvalidType: string;
  @Input() context: DynamicContext;
  @Input() config: ComponentConfig;
  @Input() formGroup: UntypedFormGroup;

  @ViewChild("valueElement") valueElement: any;
  itemsPerPage = 5;
  currentPage = 1;
  @Output() error: EventEmitter<FileUploadError>;

  constructor(
    private buyerService: BuyerService,
    private dialogService: DialogService,
    private router: Router,
    public service: DynamicComponentService,
    private languageService: LanguageService
  ) {
    this.uploader = new FileUploader({
      allowedMimeType: [
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      ],
      allowedFileType: ["xls"],
      maxFileSize: 10485760,
      queueLimit: 1,
      autoUpload: false,
    } as FileUploaderOptions);
  }

  async ngOnInit() {
    this.uploader.onAfterAddingAll = async (newFiles: FileItem[]) => {
      this.uploading = true;
      this.showProgress = true;
      this.uploadMessage = "uploading...";
      this.fileName = newFiles[0]._file.name;
      const result: any = await this.buyerService.importForDataMigration(
        newFiles
      );
      this.uploading = false;
      if (result.data.error.message) {
        this.uploadMessage = result.data.error.message;
        return;
      }
      this.uploadMessage = `${result.data.rowCount} records have been successfully imported`;
      this.currentPage = 1;
    };
    // async (failed: FileLikeObject, filter: any, options: any)

    this.uploader.onWhenAddingFileFailed = (item, filter, options) => {
      this.failedFile = item;
      this.clearValues();
      this.showError(this.failedFile);
      return null;
    }
  }

  navigateToDashboard() {
    this.router.navigate(["buyer"]);
  }

  async showError(errorFile) {
    this.messageInvalidType = "";
    this.messageLargerMaxSize = "";

    if (errorFile.size > this.uploader.options.maxFileSize) {
      this.errorType = FileUploadError.SizeError;
      if (environment.languageSwitch && environment.role == "supplier") {
        this.messageLargerMaxSize =
          this.messageLargerMaxSize ||
          this.languageService.getValue(
            "static.custom.components.uploader.messageLargerMaxSize1"
          ) +
          " " +
          this.uploader.options.maxFileSize / 1024 / 1024 +
          this.languageService.getValue(
            "static.custom.components.uploader.messageLargerMaxSize2"
          ) +
          " " +
          this.uploader.options.maxFileSize / 1024 / 1024 +
          this.languageService.getValue(
            "static.custom.components.uploader.messageLargerMaxSize3"
          ) +
          "<br/>";
      } else {
        this.messageLargerMaxSize =
          this.messageLargerMaxSize ||
          "The file you tried to upload is more than " +
          this.uploader.options.maxFileSize / 1024 / 1024 +
          "MB, please try to upload files which are less than or equal to " +
          this.uploader.options.maxFileSize / 1024 / 1024 +
          "MB.<br/>";
      }
    }

    if (
      (errorFile.type !== allowedMimeType.XLS &&
        errorFile.type !== allowedMimeType.XLSX) ||
      errorFile.type === ""
    ) {
      this.errorType = FileUploadError.TypeError;
      if (environment.languageSwitch && environment.role == "supplier") {
        this.messageInvalidType =
          this.messageInvalidType ||
          this.languageService.getValue(
            "static.system.components.dataMigration.NotSupported"
          );
      } else {
        this.messageInvalidType =
          this.messageInvalidType ||
          `The file you tried to upload is not supported,
      please attached xlsx file type.`; //   return
      }
    }

    if (this.messageLargerMaxSize) {
      if (environment.languageSwitch && environment.role == "supplier") {
        await this.dialogService.dialog(
          this.messageLargerMaxSize,
          ButtonCommands.Close,
          DialogType.alert,
          this.languageService.getValue(
            "static.custom.components.uploader.header"
          )
        );
      } else {
        await this.dialogService.dialog(
          this.messageLargerMaxSize,
          ButtonCommands.Close,
          DialogType.alert,
          "Unsupported File Size"
        );
      }
      return;
    }

    if (
      this.messageInvalidType ||
      (this.messageInvalidType && this.messageLargerMaxSize) ||
      errorFile.type === ""
    ) {
      if (environment.languageSwitch && environment.role == "supplier") {
        await this.dialogService.dialog(
          this.messageInvalidType,
          ButtonCommands.Close,
          DialogType.alert,
          this.languageService.getValue(
            "static.custom.components.uploader.type"
          )
        );
      } else {
        await this.dialogService.dialog(
          this.messageInvalidType,
          ButtonCommands.Close,
          DialogType.alert,
          "Unsupported File Types"
        );
      }
      return;
    }
  }

  clearValues() {
    if (this.valueElement) {
      this.valueElement.nativeElement.value = null;
    }
  }

  get control() {
    return this.formGroup.controls[this.config.name];
  }
}
