import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-audit-trail",
  templateUrl: "./audit-trail.component.html",
  styleUrls: ["./audit-trail.component.sass"],
})
export class AuditTrailComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
