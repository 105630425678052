import { Injectable, EventEmitter } from "@angular/core";
import { Router } from "@angular/router";
import { BuyerUser } from "src/app/interfaces/buyer";

@Injectable({
  providedIn: "root",
})
export class ManagementService {
  searchCondition = "";
  searchChanged = new EventEmitter();
  public backTo = ["buyer"];
  public title = "";
  public editMode = false;
  public users: Array<BuyerUser> = [];
  constructor(private router: Router) {}

  public changeToEditMode() {
    this.editMode = true;
  }

  public changeToViewMode() {
    this.editMode = false;
  }
}
