// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home_bg {
  background-color: #eaeaea;
  background-image: url('home_bg.3171f026a7e83e1c.jpg');
}
.home_bg .home_left {
  width: 50vw;
  background: rgba(255, 255, 255, 0.5);
  background-size: cover;
}
.home_bg .home_left img {
  margin-top: 11rem;
  width: 100%;
  margin-left: 4rem;
}
.home_bg .home_right {
  width: 50vw;
}

.logoCss {
  text-align: center;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
