import {
  NgModule,
  ModuleWithProviders,
  InjectionToken,
  Provider,
} from "@angular/core";
// tslint:disable-next-line:max-line-length
import {
  MsalBroadcastService,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalGuardConfiguration,
} from "@azure/msal-angular";
import {
  IPublicClientApplication,
  PublicClientApplication,
  InteractionType,
  BrowserCacheLocation,
  LogLevel,
  BrowserAuthOptions,
  stubbedPublicClientApplication,
} from "@azure/msal-browser";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { environment } from "../../../environments/environment";
import { AppConfigService } from "../services/app-config.service";
import { AppGuard } from "src/app/app.guard";
import { AppInterceptor } from "src/app/app.interceptor";

export const REBAR_AUTH_GUARD = new InjectionToken<string>("REBAR_AUTH_GUARD");

// tslint:disable-next-line:max-line-length
const isIE =
  window.navigator.userAgent.indexOf("MSIE ") > -1 ||
  window.navigator.userAgent.indexOf("Trident/") > -1; // Remove this line to use Angular Universal

export function loggerCallback(logLevel: LogLevel, message: string): void {
  console.log(message);
}

export function MSALInstanceFactory(
  config: AppConfigService
): IPublicClientApplication {
  const msal = config.config["msal"][environment.role];
  let msalAuth;
  // console.log(msal, "..... msal inside MSALInstanceFactory of rebar.auth.module.ts");

  if (msal) {
    msalAuth =
      environment.role === "supplier" &&

      (["dev", "test", "stage", "perf", "hotfix", "prod"].includes(environment.env))
        ? ({
          clientId: msal["auth_b2c"].clientId,
          authority: msal["auth_b2c"].authorities.signUp_signIn.authority,
          redirectUri: msal["auth_b2c"].redirectUri,
          knownAuthorities: [msal["auth_b2c"].authorityDomain],
        } as BrowserAuthOptions)
        : (msal as Record<string, BrowserAuthOptions>)["auth_b2b"];
  } else {
    console.error("Configure msal in " + AppConfigService.configPath);
  }

  // console.log(msalAuth, "... msalAuth inside MSALInstanceFactory of rebar.auth.module.ts");

  return new PublicClientApplication({
    auth: msalAuth,
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11. Remove this line to use Angular Universal
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Error,
        piiLoggingEnabled: false,
      },
    },
  });
}

export function MSALInterceptorConfigFactory(
  config: AppConfigService
): MsalInterceptorConfiguration {
  const msal = config.config["msal"][environment.role]
  const msalAuth =
    environment.role === "supplier" &&

      ["dev", "test", "stage", "perf", "hotfix", "prod"].includes(environment.env)
      ? msal["auth_b2c"]
      : msal["auth_b2b"];
  const protectedResourceMap = new Map(
    msalAuth["framework"]["protectedResourceMap"] as Iterable<
      readonly [string, string[]]
    >
  );

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(
  config: AppConfigService
): MsalGuardConfiguration {
  let scopes = ["user.read"]
  if (environment.role === "supplier" &&

    ["dev", "test", "stage", "perf", "hotfix", "prod"].includes(environment.env)) {
    scopes = ["openid", "profile", "offline_access"]
    const protectedScopes = config.config["msal"][environment.role]["auth_b2c"]["framework"]["protectedResourceMap"] as Iterable<
      readonly [string, string[]]>
    new Map(protectedScopes).forEach(p => {
      p.forEach(scope => {
        if (!scopes.includes(scope)) {
          scopes.push(scope)
        }
      })
    })
  }
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes,
      state: "",
    },
    loginFailedRoute: "/code",
  };
}

/*
  When running locally `npm run start:local` or `npm run test` or `npm run e2e`
  use mock values, to turn off security.  This is set at build time.
*/
interface IConfigMap {
  [key: string]: Provider[];
}
export const PROVIDERS: IConfigMap = {
  mock: [
    {
      provide: MSAL_GUARD_CONFIG,
      useValue: { client: "mock", authority: "mock" },
    },
    {
      provide: REBAR_AUTH_GUARD,
      useValue: emptyGuard,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useValue: {},
    },
    {
      provide: MSAL_INSTANCE,
      useValue: stubbedPublicClientApplication,
    },
    MsalService,
  ] as Provider[],
  app: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
      deps: [AppConfigService],
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
      deps: [AppConfigService],
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
      deps: [AppConfigService],
    },
    MsalService,
    AppGuard,
    MsalBroadcastService,
    {
      provide: REBAR_AUTH_GUARD,
      useClass: AppGuard,
    },
  ] as Provider[],
};

@NgModule({
  imports: [MsalModule],
})
export class RebarAuthModule {
  static forRoot(): ModuleWithProviders<RebarAuthModule> {
    return {
      ngModule: RebarAuthModule,
      providers: PROVIDERS[environment.providers],
    };
  }
}

/* eslint-disable @typescript-eslint/no-unused-vars */
export function emptyGuard(
  _route: ActivatedRouteSnapshot,
  _state: RouterStateSnapshot
): boolean {
  return true;
}
/* eslint-enable @typescript-eslint/no-unused-vars */
