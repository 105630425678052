import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  Output,
  EventEmitter,
  Input,
  OnChanges,
  SimpleChange,
} from "@angular/core";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { UntypedFormControl } from "@angular/forms";
import {
  MatAutocompleteSelectedEvent,
  MatAutocomplete,
} from "@angular/material/autocomplete";
import { Observable, of } from "rxjs";
import { startWith, debounceTime, switchMap } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { BuyerService } from "src/app/services/buyer.service";
import { LaunchDarklyService } from "src/app/services/launch-darkly.service";

@Component({
  selector: "app-people-chips",
  templateUrl: "./people-chips.component.html",
  styleUrls: ["./people-chips.component.sass"],
})
export class PeopleChipsComponent implements OnInit {
  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  peopleCtrl = new UntypedFormControl();
  filteredPeople: Observable<any>;
  isNewVersion:boolean=true;

  peoplePickerList: Array<{ [key: string]: string }> = [];
  peopleList: Array<string> = [];

  @ViewChild("peopleInput") peopleInput: ElementRef<HTMLInputElement>;
  @ViewChild("auto") matAutocomplete: MatAutocomplete;
  @Output() valueChangeEvent: EventEmitter<string>;
  @Output() blurEvent: EventEmitter<string>;
  @Input() firstLoadFlag: boolean;

  constructor(private http: HttpClient, private buyerService: BuyerService, private launchDarklyService:LaunchDarklyService) {
    this.valueChangeEvent = new EventEmitter();
    this.blurEvent = new EventEmitter();
    this.launchDarklyService.ldChange.subscribe(any => {
      console.log("Trigger refresh data")
      this.refreshToggle()
    })
  }

  refreshToggle():void{
    this.isNewVersion=this.launchDarklyService.getToggle(LaunchDarklyService.LD_ISNEWVERSION)==="true";
  }

  ngOnInit() {
    this.refreshToggle();
    this.filteredPeople = this.peopleCtrl.valueChanges.pipe(
      startWith(null),
      debounceTime(500),
      switchMap((inputtedValue) => {
        if (inputtedValue) {
          let searchText = inputtedValue;
          if (inputtedValue.indexOf("@") > -1) {
            const domain = inputtedValue.split("@")[1];
            if ("accenture.com".indexOf(domain) > -1) {
              searchText = inputtedValue.split("@")[0];
            }
          }
          if(this.isNewVersion) {
            return this.buyerService.searchPeopleUsingGraphAPI(searchText);
          }
          else {
            return this.buyerService.existingsearchPeopleUsingGraphAPI(searchText);
          } 
        } else {
          this.peoplePickerList = [];
          return of();
        }
      })
    );

    this.filteredPeople.subscribe({
      next: (result: Array<{ [key: string]: string }>) => {
        this.peoplePickerList = [];
        for (const obj of result) {
          for (const key in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, key)) {
              this.peoplePickerList.push({
                enterpriseId: key,
                displayName: obj[key],
                email: `${key}@accenture.com`,
              });
            }
          }
        }
      },
      error: (err) => {
        console.error(err);
      },
    });
  }

  ngOnChanges(changes: SimpleChange) {
    if (changes["firstLoadFlag"] && changes["firstLoadFlag"].currentValue) {
      this.peopleInput.nativeElement.value = "";
      this.peopleCtrl.setValue(null);
      this.peoplePickerList = [];
      this.peopleList = [];
    }
  }

  remove(people: string): void {
    const index = this.peopleList.indexOf(people);

    if (index >= 0) {
      this.peopleList.splice(index, 1);
      this.valueChangeEvent.emit(this.peopleList.join(";"));
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.peopleList.push(event.option.value);
    this.valueChangeEvent.emit(this.peopleList.join(";"));
    this.peopleInput.nativeElement.value = "";
    this.peopleCtrl.setValue(null);
    this.peoplePickerList = [];
  }

  controlBlur() {
    const div = document.querySelector("#chips");
    div.removeAttribute("style");

    if (this.peopleInput.nativeElement.value) {
      const semicolon = this.peopleList.length > 0 ? ";" : "";
      this.blurEvent.emit(
        this.peopleList.join(";") +
          semicolon +
          this.peopleInput.nativeElement.value.split("@")[0]
      );
    } else if (this.peopleList.length > 0) {
      this.blurEvent.emit(this.peopleList.join(";"));
    } else {
      this.blurEvent.emit("");
    }
  }

  controlFocus() {
    const div = document.querySelector("#chips");
    div.setAttribute(
      "style",
      "background: #fff;border: #b6b7ce 1px solid;box-shadow: 0 0 5px #ccc;color: #666;"
    );
  }
}
