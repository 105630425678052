import { Injectable } from "@angular/core";
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot } from "@angular/router";
import { BuyerService } from "src/app/services/buyer.service";
import { OnBoardingService } from "./onboarding.service";
import { AuthService } from "../../../services/auth/auth.service";
import {
  SupplierModel,
  createSupplierModel,
} from "../../../interfaces/supplierModel";
import { SupplierMappingDTO } from "src/app/interfaces/mapping";

@Injectable()
export class OnBoardingResolver {
  constructor(
    private buyerService: BuyerService,
    private router: Router,
    private onBoardingService: OnBoardingService,
    private authService: AuthService
  ) {}

  async resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<SupplierModel> {
    let supplierCode = route.paramMap.get("code");

    if (supplierCode === "0") {
      // new create supplier requestor form
      this.onBoardingService.mode = "create";
      supplierCode = Math.round(new Date().getTime()).toString();
      this.onBoardingService.supplierModel = createSupplierModel(supplierCode);

      this.onBoardingService.supplierCode = supplierCode;
      await this.onBoardingService.initSupplierRequestForm();
    } else {
      // retrieve this supplier requestor form
      this.onBoardingService.mode = "edit";

      if (this.onBoardingService.supplierCode !== supplierCode) {
        this.onBoardingService.supplierCode = supplierCode;
        await this.onBoardingService.loadSupplierRequestForm();
      }
    }
    return this.onBoardingService.supplierModel;
  }
}
