// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#modal-supplier-bg {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.7);
}
#modal-supplier-bg #modal-supplier-main {
  position: fixed;
  z-index: 10;
  width: 34%;
  height: calc(100% - 6rem);
  border-radius: 10px;
  left: 33%;
  top: 4rem;
  background-color: #fff;
  opacity: 1;
  padding: 10px 0;
}
#modal-supplier-bg #modal-supplier-main .modal-supplier-body {
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  top: 0;
  padding: 0 40px;
}
#modal-supplier-bg #modal-supplier-main .modal-supplier-body .modal-title {
  text-align: center;
  color: #4e4e87;
  font-weight: 600;
  font-size: 24px;
  width: 100%;
  font-family: "Graphik Bold";
  padding: 40px 0;
}
#modal-supplier-bg #modal-supplier-main .modal-supplier-body ul li {
  border-bottom: #ccc 1px solid;
}
#modal-supplier-bg #modal-supplier-main .modal-supplier-body ul li .supplier-container {
  margin-top: 1rem;
}
#modal-supplier-bg #modal-supplier-main .modal-supplier-body ul li .supplier-container .warp {
  word-break: normal;
  word-wrap: break-word;
  white-space: pre-wrap;
}
#modal-supplier-bg #modal-supplier-main .modal-supplier-body ul li p {
  color: #666;
  margin: 1rem 0;
  cursor: pointer;
  display: block;
}
#modal-supplier-bg #modal-supplier-main .modal-supplier-body p {
  margin-top: -20PX;
  margin-bottom: 20px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
