import { Component, OnInit, ViewChild } from "@angular/core";
import {
  RequestorDetailsInfo,
  RequestForm,
  RequestFormInfo,
} from "src/app/interfaces/requestForm";
import { OnBoardingService } from "../../../services/onboarding.service";
import { ActivatedRoute, Router } from "@angular/router";
import {
  initChangeRequestMapping,
  initAuditChangeRequestDetail,
  ChangeRequestType,
  AmendSpendCommodityCategoryGroup,
} from "src/app/interfaces/changeRequest";
import * as moment from "moment";
import { AuthService } from "src/app/services/auth/auth.service";
import { BuyerChangeRequestService } from "../../../services/buyerChangeRequest.service";
import { environment } from "src/environments/environment";
import { LoadingUrlMapping, SpendCategoryGroup } from "src/app/interfaces/mapping";
import { LoadingService } from "src/app/services/loading.service";
import { ComponentConfig } from "src/app/dynamic-components/ComponentConfig";
import { DynamicContext } from "src/app/dynamic-components/interfaces/DynamicContext";
import { DynamicFormComponent } from "src/app/dynamic-components/components/dynamic-form/dynamic-form.component";
import { Subject } from "rxjs";
import {
  DialogService,
  DialogType,
  ButtonCommands,
  ConfirmService,
  ButtonCommand,
} from "src/app/services/dialog.service";
@Component({
  selector: "app-amend-spend-commodity-category-group",
  templateUrl: "./amend-spend-commodity-category-group.component.html",
  styleUrls: ["./amend-spend-commodity-category-group.component.sass"],
})
export class AmendSpendCommodityCategoryGroupComponent implements OnInit {
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  amendSpendCommodityCategoryGroupConfig: ComponentConfig[] =
    amendSpendCommodityCategoryGroupConfig;
  RequestorDetails: RequestorDetailsInfo;
  comments: string;
  context: DynamicContext;
  @ViewChild("dynamicForm") dynamicForm: DynamicFormComponent;
  subscription: any;
  originalCommodityValue;
  originalSubCategoryValue;
  constructor(
    private route: ActivatedRoute,
    private onBoardingService: OnBoardingService,
    private authService: AuthService,
    private buyerChangeRequest: BuyerChangeRequestService,
    private loadingService: LoadingService,
    private dialogService: DialogService,
    private router: Router,
    private confirmService: ConfirmService,

  ) { }
  ngOnInit() {
    this.context = new DynamicContext();
    const requestorData = this.RequestorDetailsInfo();
    this.RequestorDetails = {}
    this.RequestorDetails.SpendCommodityGroup = requestorData.SpendCommodityGroup
    setTimeout(() => {
      this.dynamicForm.updateFormValues()
      this.RequestorDetails = {}
      this.RequestorDetails.SpendCategoryGroup = requestorData.SpendCategoryGroup
      setTimeout(() => {
        this.dynamicForm.updateFormValues()
      })
    });
  }
  RequestorDetailsInfo(): RequestorDetailsInfo {
    return this.onBoardingService.supplierModel.RequestForm
      .RequestorDetailsInfo;
  }
  async submit() {
    this.context.changeSubmitState();
    this.dynamicForm.removeFormTouch();
    if (!this.validate()) {
      return { isValidate: false, isSuccess: false };
    }
    if (this.onBoardingService.supplierModel.RequestForm.RequestorDetailsInfo
      .SpendCategoryGroup === '5002' || this.onBoardingService.supplierModel.RequestForm.RequestorDetailsInfo
        .SpendCategoryGroup === '5021') {
      if (
        this.onBoardingService.supplierModel.RequestForm.RequestorDetailsInfo
          .SpendCommodityGroup ===
        this.dynamicForm.values().SpendCommodityGroup &&
        this.onBoardingService.supplierModel.RequestForm.RequestorDetailsInfo
          .SpendCategoryGroup === this.dynamicForm.values().SpendCategoryGroup &&
        this.onBoardingService.supplierModel.RequestForm.RequestorDetailsInfo
          .IndependentContractor === this.dynamicForm.values().IndependentContractor
      ) {
        this.loadingService.closeLoading();
        await this.dialogService.dialog(
          `Please select a new  Category Group/Sub - Category.`,
          ButtonCommands.Close,
          DialogType.warning,
          "Please Note"
        );
        return { isValidate: false, isSuccess: false };
      }
    } else {
      if (
        this.onBoardingService.supplierModel.RequestForm.RequestorDetailsInfo
          .SpendCommodityGroup ===
        this.dynamicForm.values().SpendCommodityGroup &&
        this.onBoardingService.supplierModel.RequestForm.RequestorDetailsInfo
          .SpendCategoryGroup === this.dynamicForm.values().SpendCategoryGroup
      ) {
        this.loadingService.closeLoading();
        await this.dialogService.dialog(
          `Please select a new  Category Group/Sub - Category.`,
          ButtonCommands.Close,
          DialogType.warning,
          "Please Note"
        );
        return { isValidate: false, isSuccess: false };
      }
    }


    try {
      const supplier = this.onBoardingService.supplierModel;
      const request = initChangeRequestMapping(supplier.Mapping.supplierCode);
      request.supplierCode = supplier.Mapping.supplierCode;
      request.requestType =
        ChangeRequestType.UPDATE_AmendSpendCommodityCategoryGroup;
      request.requestValue = JSON.stringify(this.dynamicForm.values());
      request.createdOn = moment.utc().toString();
      request.createdBy = this.authService.passport.profile.name;
      request.supplierModel = supplier;
      const audit = initAuditChangeRequestDetail();
      const detail = new AmendSpendCommodityCategoryGroup();
      if (
        this.onBoardingService.supplierModel.RequestForm.RequestorDetailsInfo
          .SpendCommodityGroup !== this.dynamicForm.values().SpendCommodityGroup
      ) {
        // eslint-disable-next-line max-len
        detail.SpendCommodityGroup = {
          oldValue:
            this.onBoardingService.supplierModel.RequestForm
              .RequestorDetailsInfo.SpendCommodityGroup,
          newValue: this.dynamicForm.values().SpendCommodityGroup,
        };
      } else {
        detail.SpendCommodityGroup = {
          oldValue:
            this.onBoardingService.supplierModel.RequestForm
              .RequestorDetailsInfo.SpendCommodityGroup,
        };
      }
      if (
        this.onBoardingService.supplierModel.RequestForm.RequestorDetailsInfo
          .SpendCategoryGroup !== this.dynamicForm.values().SpendCategoryGroup
      ) {
        // eslint-disable-next-line max-len
        detail.SpendCategoryGroup = {
          oldValue:
            this.onBoardingService.supplierModel.RequestForm
              .RequestorDetailsInfo.SpendCategoryGroup,
          newValue: this.dynamicForm.values().SpendCategoryGroup,
        };
      } else {
        detail.SpendCategoryGroup = {
          oldValue:
            this.onBoardingService.supplierModel.RequestForm
              .RequestorDetailsInfo.SpendCategoryGroup,
        };
      }
      if (
        this.onBoardingService.supplierModel.RequestForm.RequestorDetailsInfo
          .IndependentContractor !== this.dynamicForm.values().IndependentContractor
      ) {
        detail.IndependentContractor = {
          oldValue:
            this.onBoardingService.supplierModel.RequestForm
              .RequestorDetailsInfo.IndependentContractor,
          newValue: this.dynamicForm.values().IndependentContractor,
        };
      } else {
        detail.IndependentContractor = {
          oldValue:
            this.onBoardingService.supplierModel.RequestForm
              .RequestorDetailsInfo.IndependentContractor,
        };
      }
      audit.addAmendSpendCommodityCategoryGroup.push(detail);
      audit.comments = this.comments;
      audit.actionTaken = "";
      audit.createdBy = this.authService.passport.profile.name;
      audit.createdOn = moment.utc().toString();
      request.audit = [audit];
      request.openFlag = true;
      // const data = {
      //   coupaFlag: true,
      //   audit
      // }
      // if (this.dynamicForm.values().SpendCategoryGroup == '5002' || this.dynamicForm.values().SpendCategoryGroup == '5021') {
      //   if (this.dynamicForm.values().IndependentContractor == 'N') {
      //     SpendCategoryGroup['5002'] = true
      //     SpendCategoryGroup['5021'] = true
      //   }else {
      //     SpendCategoryGroup['5002'] = false
      //     SpendCategoryGroup['5021'] = false
      //   }
      // }
      // const isCoupa = SpendCategoryGroup[this.dynamicForm.values().SpendCategoryGroup];
      // const isMigrate = !this.onBoardingService.supplierModel.Mapping.IsMigrated;
      // if (isCoupa && isMigrate) {
      //   await this.dialogService
      //     .dialog(
      //       `Supplier Category has changed for this supplier and you will be required to answer the Information Security and Data Privacy risk assessment questionnaires`,
      //       ButtonCommands.Close,
      //       DialogType.warning,
      //       "Please Note"
      //     )
      //   await this.router.navigate([
      //     `buyer/onboarding/${this.onBoardingService.supplierCode}/stepCRCoupa`,
      //   ])
      //     .then(() => {
      //       this.confirmService.send();
      //       this.confirmService.sendAudit(audit)
      //     });

      //   return {
      //     isValidate: true,
      //   };
      // }
      this.originalCommodityValue = this.onBoardingService.supplierModel.RequestForm
      .RequestorDetailsInfo.SpendCommodityGroup;
      this.originalSubCategoryValue = this.onBoardingService.supplierModel.RequestForm
      .RequestorDetailsInfo.SpendCategoryGroup;
      const categoryAndSubcategoryChangeConfirmation = await this.dialogService.dialog(
          `<div style="text-align: center;">
          This change in category/sub-category will result in needing a new Risk Assessment.
          Please ask the Requestor or Supplier Manager to complete the Risk Assessment in TSM.<br>
          Requestor ${this.onBoardingService.supplierModel.RequestForm.RequestorDetailsInfo.RequestorEid}
          </div>` ,
          ButtonCommands.ProceedCancel
        );
        if (categoryAndSubcategoryChangeConfirmation === ButtonCommand.no) {
         if (
            this.onBoardingService.supplierModel.RequestForm.RequestorDetailsInfo
         .SpendCommodityGroup !== this.dynamicForm.values().SpendCommodityGroup
          )
          {
            this.dynamicForm.formGroup.get("SpendCommodityGroup").setValue(this.originalCommodityValue);
            this.dynamicForm.formGroup.get("SpendCategoryGroup").setValue( this.originalSubCategoryValue);
          }
          else
          {
          this.dynamicForm.formGroup.get("SpendCategoryGroup").setValue( this.originalSubCategoryValue);
          }
          return { isValidate: false, isSuccess: true };
        }  
        else
        {
         const saveUrl = `${environment.gateway}${LoadingUrlMapping.addChangeRequest}`;
         this.loadingService.openLoading(saveUrl);
         return {
            isValidate: true,
            isSuccess: await this.buyerChangeRequest.submitRequestor(request),
         }; 
        }
    } catch (e) {
      return { isValidate: true, isSuccess: false };
    }
  }
  validate(): boolean {
    return this.dynamicForm.valide();
  }
}

const amendSpendCommodityCategoryGroupConfig: ComponentConfig[] = [
  {
    type: "layout",
    fieldset: [
      {
        name: "SpendCommodityGroup",
        label: "Category Group",
        type: "dropdown",
        required: true,
        width: "50%",
        errorMessage: {
          required: "Please select Category Group",
        },
        dataSource: {
          name: "dictionary",
          params: "requestor-details.SpendCommodityGroup",
          loadOnInit: true,
        },
      },
    ],
  },
  {
    type: "layout",
    fieldset: [
      {
        name: "SpendCategoryGroup",
        label: "Sub - Category",
        type: "dropdown",
        width: "50%",
        required: true,
        hide: true,
        // validateOn: 'change',
        errorMessage: {
          required: "Please select Sub - Category",
        },
        dataSource: {
          name: "SpendCategoryGroupDictionary",
          params: "",
          loadOnInit: true,
        },
        eventListeners: [
          {
            event: "change:model.SpendCommodityGroup",
            actions: [
              {
                name: "setVisible",
                params: "!!$value",
              },
              { name: "setValue", params: `''` },
              { name: "loadDatasource" },
            ],
          },
        ],
        // validators: [
        //   { name: 'subCategoryCRWarningMessage' }
        // ],
      },
    ],
  },
  {
    name: 'IndependentContractor',
    label: 'Is this an Independent contractor?',
    type: 'dropdown',
    templateOptions: {
      hasSpecialErrorMessage: true
    },
    hide: true,
    required: true,
    width: '50%',
    validateOn: 'change',
    errorMessage: {
      'required': 'Please select'
    },
    dataSource: {
      name: 'dictionary',
      params: 'requestor-details.YesOrNo',
      loadOnInit: true,
    },
    eventListeners: [
      {
        event: 'change:model.SpendCategoryGroup',
        actions: [
          {
            name: 'setVisible',
            params: '$value.includes("5002") || $value.includes("5021")'
          }
        ]
      }
    ],
    validators: [
      { name: 'subCategoryCRWarningMessageIndependentContractor' }
    ]
  },
];
