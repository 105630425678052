import { Component, OnInit, Input } from "@angular/core";
import { BaseComponent } from "src/app/dynamic-components/components/base/base.component";
import { DictionaryService } from "src/app/services/dictionary.service";
import { environment } from "src/environments/environment";
import { State } from "src/app/shared/shared";
import { AuthService } from "src/app/services/auth/auth.service";
import { strToHexCharCode } from "src/app/utils";
import { Datasources } from "src/app/dynamic-components/datasource/Datasources";
import { HttpService } from "src/app/services/api/http.service";
import { LoadingService } from "src/app/services/loading.service";
import { LanguageService } from "src/app/dynamic-components/utils/language.service";

@Component({
  selector: "app-item",
  templateUrl: "./item.component.html",
  host: {
    "[style.width]": "config.width",
  },
  styleUrls: ["./item.component.sass"],
})
export class ItemComponent extends BaseComponent implements OnInit {
  displayValue: any;
  cacheValue: Map<string, any>;
  item: any;
  history: Object;
  isShow: boolean;
  baseUrl = environment.gateway;
  newDisplay: string;
  oldDisplay: string;
  constructor(
    dic: DictionaryService,
    private auth: AuthService,
    private http: HttpService,
    private loadingService: LoadingService,
    private languageService: LanguageService
  ) {
    super();
    this.cacheValue = dic.getDataMap();
  }
  async ngOnInit() {
    this.item = this.config.templateOptions;
    this.history = this.context.getValue("history");
    const newValue = this.item.newValue;
    const oldValue = this.item.oldValue;
    if (!this.ifEmptyObject(this.config.dataSource)) {
      await Datasources.loadData(this.config, this.context, this.formGroup).then(
        (ds) => {
          if (ds === "") {
            return;
          }
          if (this.config.name === 'InformationSecurity_1ce_1') {
            if (!ds.find((item) => item.Code === newValue)) {
              ds.unshift({ Code: newValue, Text: newValue});
            }
            if (!ds.find((item) => item.Code === oldValue)) {
              ds.unshift({ Code: oldValue, Text: oldValue});
            }
          }
          if (this.config.name === 'PreferredOrderingMethod') {
            if (this.item.status == "Created") {
              if (!ds.find((item) => item.Code === newValue)) {
                this.config.hide = true
              }
            } else {
              if (!ds.find((item) => item.Code === newValue) && !ds.find((item) => item.Code === oldValue)) {
                this.config.hide = true
              }
            }
          }

          if (
            (newValue && newValue.split(",").length > 1) ||
            (oldValue && oldValue.split(",").length > 1)
          ) {
            this.setMultipleValue(ds, newValue, true);
            this.setMultipleValue(ds, oldValue, false);
          } else {
            if (ds.data) {
              ds = ds.data;
            }
            this.setValue(ds, newValue, true);
            this.setValue(ds, oldValue, false);
          }
        }
      );
    } else if (
      this.item.fieldType === "radio" &&
      this.config.templateOptions.options
    ) {
      this.setValue(this.config.templateOptions.options, newValue, true);
      this.setValue(this.config.templateOptions.options, oldValue, false);
    } else if (
      this.item.fieldType === "checkbox" &&
      this.config.templateOptions.options
    ) {
      this.setValue(this.config.templateOptions.options, newValue, true);
      this.setValue(this.config.templateOptions.options, oldValue, false);
    } else {
      this.newDisplay = this.item.newValue;
      this.oldDisplay = this.item.oldValue;
    }
    if (
      this.config.hide ||
      (this.config.templateOptions &&
        !this.config.templateOptions.oldValue &&
        this.config.templateOptions.isFile &&
        this.config.templateOptions.newValue &&
        !this.config.templateOptions.newValue.path)
    ) {
      this.isShow = false;
    } else {
      this.isShow = true;
    }
    if (this.config.label) {
      this.config.label = this.config.label.replace(
        "---undefined---undefined",
        ""
      );
    }
  }
  setMultipleValue(ds, itemValue, flag) {
    if (itemValue) {
      const arr = [];
      itemValue.split(",").map((v) => {
        const obj = ds.find((response) => {
          return v === response.Code;
        });
        if (obj) {
          arr.push(
            environment.languageSwitch
              ? this.languageService.getValue(obj.languageKey)
              : obj.Text
          );
        }
      });
      if (flag) {
        this.newDisplay = arr.join();
      } else {
        this.oldDisplay = arr.join();
      }
    }
  }

  setValue(arrays, itemValue, flag) {
    const obj = arrays.find((response) => itemValue === response.Code);
    if (obj) {
      if (flag) {
        this.newDisplay = environment.languageSwitch
          ? this.languageService.getValue(obj.languageKey)
          : obj.Text;
      } else {
        this.oldDisplay = environment.languageSwitch
          ? this.languageService.getValue(obj.languageKey)
          : obj.Text;
      }
    }
  }

  ifEmptyObject(obj) {
    for (const key in obj) {
      return false;
    }
    return true;
  }

  downloadFile(fileKey, fileName) {
    let role = "";
    let downloadPath = "";
    const access_token = `Bearer ${this.auth.passport.access_token}`;
    const state = environment.role;
    if (state === State.buyer && this.auth.passport.buyer) {
      role = this.auth.passport.buyer.RoleCode;
      downloadPath = "buyer/file/download";
    } else if (state === State.supplier && this.auth.passport.supplierMember) {
      role = this.auth.passport.supplierMember.RoleCode;
      downloadPath = "supplier/file/download";
    }
    const params = strToHexCharCode(
      `key=${fileKey}&Authorization=${access_token}&state=${state}&role=${role}`
    );
    // const url = `${this.baseUrl}/${downloadPath}?fileCode=${params}`
    // window.open(url, '_blank')
    const url = `${this.baseUrl}/${downloadPath}`;
    this.loadingService.openLoading(url);
    this.http
      .PostBlobPromise(url, { fileCode: params }, "blob")
      .then((data: BlobPart) => {
        const blob = new Blob([data]);
        // for ie
        if ("msSaveOrOpenBlob" in navigator) {
          window.navigator.msSaveOrOpenBlob(blob, fileName);
        } else {
          const link = document.createElement("a");
          link.setAttribute("href", window.URL.createObjectURL(blob));
          link.setAttribute("download", fileName);
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          this.loadingService.closeLoading();
        }
      });
  }
}
