import { HooksFactory } from "./hooksFactory";
import { HookConfig, ComponentConfig } from "../ComponentConfig";
import { DynamicContext } from "../interfaces/DynamicContext";
import { UntypedFormGroup } from "@angular/forms";

export class Hooks {
  public static TRIGGER_FORMINIT = "formInit";
  public static TRIGGER_BeforeFormControlInit = "beforeFormControlInit";
  public static TRIGGER_AfterFormControlInit = "afterFormControlInit";
  public static TRIGGER_ONCOMPONENTRENDER = "onComponentRender";

  private static hooksMap = new Map();

  static registerHooksFactory(name: string, hook: HooksFactory) {
    this.hooksMap.set(name, hook);
  }

  static getHookFactory(name: string): HooksFactory {
    return this.hooksMap.get(name);
  }

  static executeHook(
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup,
    trigger: string
  ) {
    const hookDefs = config.hooks;
    if (!hookDefs || hookDefs.length === 0) {
      return false;
    }
    const targetHooks = config.hooks.filter((h: HookConfig) => {
      return h.trigger === trigger;
    });
    for (const hd of targetHooks) {
      if (hd.condition) {
        const fn = `return !!(${hd.condition})`;
        const runtime = new Function("config", "context", "formGroup", fn);
        const past = runtime(config, context, formGroup);
        if (!past) {
          continue;
        }
      }
      const hookFactory = this.getHookFactory(hd.name);
      if (hookFactory) {
        const hook = hookFactory.call(
          hookFactory.objInstance,
          config,
          context,
          formGroup
        );
        hook(hd.params);
      }
    }
  }
}
