import { Component, OnInit } from "@angular/core";
import { BaseComponent } from "src/app/dynamic-components/components/base/base.component";
import { DynamicComponentService } from "src/app/dynamic-components/services/dynamic-component.service";
import { Datasources } from "../../../../dynamic-components/datasource/Datasources";

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.sass'],
  host: {
    '[style.width]': 'config.width',
  }
})
export class DropdownComponent extends BaseComponent {
  showTooltip: boolean;
  isHide = true;
  rand =Math.round((Math.random()*10000)).toString();
  constructor(public componentService: DynamicComponentService) {
    super();
  }

  estimateChooseOver(e, estimateSpendTooltip) {
    const TooltipEle = estimateSpendTooltip;
    if (estimateSpendTooltip) {
      if (
        e.clientX < TooltipEle.nativeElement.getBoundingClientRect().left ||
        e.clientX >
          TooltipEle.nativeElement.getBoundingClientRect().left +
            TooltipEle.nativeElement.offsetWidth -
            5 ||
        e.clientY < TooltipEle.nativeElement.getBoundingClientRect().top ||
        e.clientY >
          TooltipEle.nativeElement.getBoundingClientRect().top +
            TooltipEle.nativeElement.offsetHeight -
            5
      ) {
        this.showTooltip = false;
      }
    } else {
      this.showTooltip = false;
    }
  }

  ngOnInit() {
    super.ngOnInit();
    Datasources.loadData(this.config, this.context, this.formGroup).then(
      (ds) => {
        try {
          const formControl = this.formGroup.get(this.config.name);
          if (ds && ds instanceof Array) {
            this.config.options = ds.filter((item) => {
              if (item.Code == formControl.value && item.IsDeprecated) {
                formControl.patchValue("");
              }
              return !item.IsDeprecated;
            });
          }
        } catch (e) {
          console.log("---ds error---", this.config.name);
        }
      }
    );
    this.rand =Math.round((Math.random()*10000)).toString();
  }

  toggle() {
    this.isHide = !this.isHide;
  }
}
