import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { LoadingService } from "../../services/loading.service"
@Component({
  selector: "app-error",
  templateUrl: "./error.component.html",
  styleUrls: ["./error.component.sass"],
})

export class ErrorComponent implements OnInit {
  code: string;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private loadingService: LoadingService
  ) {}
  ngOnInit() {
    this.route.params.subscribe((data) => {
      this.code = data.code;
      this.loadingService.hideLoading();
    });
  }
  backToTsm() {
    if (environment.role === "buyer") {
      this.router.navigate(["/buyer"]);
    } else {
      this.router.navigate(["/supplier/landing"]);
    }
  }
}

