// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header .banner {
  padding: 2rem;
}
.header .banner .welcome_message {
  margin: 2rem 4rem;
  font-size: 2rem;
  color: rgb(255, 255, 255);
}
.header .banner .search {
  margin-left: 4rem;
}
.header .banner .search .search_bg {
  display: inline;
  background: #fff;
  border-radius: 1.5rem;
  padding: 1rem;
}
.header .banner .search .search_bg .material-icons {
  margin-right: 0.5rem;
}
.header .banner .search .search_bg input {
  border: 0;
  font-size: 1.25rem;
  height: 2rem;
  background: transparent;
  width: 11rem;
}
.header .banner .menu_items .item {
  margin-right: 5rem;
  text-align: center;
  color: #fff;
  opacity: 0.5;
}
.header .banner .menu_items .item .circle {
  margin: 0 auto;
  width: 5.5rem;
  height: 5.5rem;
  line-height: 5.5rem;
  text-align: center;
  border-radius: 50%;
  border: #FFF 1px solid;
  margin-bottom: 2rem;
}
.header .banner .menu_items .item .circle .dashboard {
  font-size: 3.5rem;
  width: 4rem;
}
.header .banner .menu_items .item .circle .my_account {
  font-size: 3.5rem;
}
.header .banner .menu_items .item .circle .my_suppliers {
  font-size: 3.5rem;
}
.header .banner .menu_items .active {
  opacity: 1;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
