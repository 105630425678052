// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rounded-circle {
  border-radius: 50% !important;
  height: 7.4rem;
  width: 7.4rem;
  font-size: 1rem;
  padding: 12px 4px;
  text-align: center;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.circle-todo {
  color: #555;
  font-weight: 500;
  box-shadow: 0px 0px 5px #000;
}

.circle-done {
  border: solid 5px #65BEE1;
  font-weight: bold;
  color: #555555;
}

.circle-terminated {
  border: solid 5px red !important;
  color: #555555;
}

.circle-rejected {
  border: solid 5px orange;
  color: #555555;
}

.line-done {
  width: calc(135px - 7.4rem);
  height: 6px;
  background-color: #4FABE3;
  z-index: 0;
}

.line-rejected {
  background-color: orange;
  width: calc(135px - 7.4rem);
  height: 6px;
  z-index: 0;
}

.line-todo {
  width: calc(135px - 7.4rem);
  height: 6px;
  background-color: #EEEEEE;
  z-index: 0;
}

.item {
  margin-top: 10px;
  line-height: 20px;
  width: 135px;
  color: #666;
  padding: 0 10px;
  font-size: 1rem;
}

.date {
  margin: 6px 0px;
  color: #737777;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
