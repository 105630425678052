import { Component, Input } from "@angular/core";

@Component({
  selector: "app-error-msg",
  templateUrl: "./error-msg.component.html",
  styleUrls: ["./error-msg.component.sass"],
})
export class ErrorMsgComponent {
  @Input() message: string;
  @Input() isWarning = false;
}
