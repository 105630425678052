export function strToHexCharCode(str) {
  if (str === "") {
    return "";
  }
  var strtoutf8= unescape(encodeURIComponent(str));
  const hexCharCode = [];
  var utf8tohex = utftohex(strtoutf8);
  hexCharCode.push(utf8tohex)
  return utf8tohex;
}
const utf8encoder = new TextEncoder();
export function utftohex(s) 
  {
    const hexa = utf8encoder.encode(s);
    let hexaData = '';
    for (const b of hexa) {
      hexaData += ('0' + b.toString(16)).slice(-2);
    }
    return hexaData;
}


export function hexCharCodeToStr(hexCharCodeStr) {
  const trimedStr = hexCharCodeStr.trim();
  const rawStr =
    trimedStr.substr(0, 2).toLowerCase() === "0x"
      ? trimedStr.substr(2)
      : trimedStr;
  const len = rawStr.length;
  if (len % 2 !== 0) {
    alert("Illegal Format ASCII Code!");
    return "";
  }
  let curCharCode;
  const resultStr = [];
  for (let i = 0; i < len; i = i + 2) {
    curCharCode = parseInt(rawStr.substr(i, 2), 16); // ASCII Code Value
    resultStr.push(String.fromCharCode(curCharCode));
  }
  return resultStr.join("");
}
