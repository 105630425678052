import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import {
  AllTaskKey,
  ProcessNames,
  ProcessStatus,
  MigrateProcess,
} from "src/app/interfaces/workflow";
import { ProfileService } from "./profile.service";
import { SupplierStatus } from "src/app/interfaces/mapping";

@Injectable({
  providedIn: "root",
})
export class SupplierHeaderService {
  public title = "";
  public icon = "account_circle";
  public showProfileButtons = true;
  public isSSTRejectOrQCApprove = false;
  constructor(private router: Router, private profileService: ProfileService) {}
  // show edit button
  getIsCompletedAndIsSSTRejectOrQCApprove() {
    // supplier edit button is enable: sst reject and supplier on boarded
    if (
      this.profileService.supplierModel.Mapping.status ===
      SupplierStatus.terminated
    ) {
      this.isSSTRejectOrQCApprove = false;
    } else if (
      this.profileService.getUserTaskByTaskKey(AllTaskKey.SST_rejectToSupplier)
    ) {
      this.isSSTRejectOrQCApprove = true;
    } else if (
      this.profileService.supplierModel.Mapping.supplierChangeRequestStatus ===
      ProcessStatus.pending
    ) {
      this.isSSTRejectOrQCApprove = false;
    } else if (
      this.profileService.getUserTaskByKey(
        ProcessNames.generic_supplierMigrateSetup,
        MigrateProcess.profile_draft
      )
    ) {
      this.isSSTRejectOrQCApprove = true;
    } else if (
      this.profileService.supplierModel.Mapping.supplierMigrateSetupStatus ===
      ProcessStatus.pending
    ) {
      this.isSSTRejectOrQCApprove = false;
    } else if (
      this.profileService.supplierModel.Mapping.status ===
      SupplierStatus.onboarded
    ) {
      this.isSSTRejectOrQCApprove = true;
    } else {
      this.isSSTRejectOrQCApprove = false;
    }
  }
}
