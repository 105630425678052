import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class BuyerACLPermissionService {
  public pagePermissions?: Array<any>;
  public buttonPermissions?: Array<any>;
  constructor() {}
}

export class BuyerItemPermission {
  RoleID?: string;
  ItemID?: string;
  ItemName?: string;
  Enabled?: string;
  ItemType?: string;
}
