// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error-page {
  display: grid;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.error-code {
  display: inline-block;
  font-size: 30px;
  height: 60px;
  margin-top: 40px;
  font-family: Graphik;
}

.error-message {
  display: inline-block;
  font-size: 30px;
  padding-top: 20px;
  line-height: 1.2;
  font-family: "Graphik Semi Bold";
  color: blue;
}

.error-status {
  display: inline-block;
  font-size: 30px;
  padding-top: 20px;
  line-height: 1.2;
  font-family: "Graphik";
}

.error-back {
  width: 30px;
  font-size: 1.8rem;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
