// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tabPageSlide {
  margin: 0px;
  padding: 0px;
  list-style: none;
}

.tabPageSlide .tabPageSlide-answer {
  display: block;
  padding: 0 2rem;
  text-align: left;
  line-height: 1.5;
  color: #000;
  font-size: 15px;
}
.tabPageSlide .tabPageSlide-answer span.strong {
  font-weight: 600;
}
.tabPageSlide .tabPageSlide-answer span.upperRed {
  color: #CE004A;
  padding-top: 1rem;
  display: inline-block;
}
.tabPageSlide .tabPageSlide-answer span.purple {
  color: #ce004a;
  font-weight: 600;
}
.tabPageSlide .tabPageSlide-answer span.upper {
  padding-top: 1rem;
  display: inline-block;
}
.tabPageSlide .tabPageSlide-answer span.l-50 {
  display: inline-block;
  width: 50%;
  line-height: 1;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.tabPageSlide .tabPageSlide-answer span.l-50 a {
  text-decoration: underline;
  color: #006CAF;
}
.tabPageSlide .tabPageSlide-answer a {
  text-decoration: underline;
  color: #006CAF;
}
.tabPageSlide .tabPageSlide-answer a .strong {
  font-weight: 600;
}

.modal-body {
  position: relative;
  width: 100%;
  height: 85%;
  overflow-x: hidden;
  overflow-y: auto;
  top: 0;
  padding: 10px 40px;
}
.modal-body::-webkit-scrollbar {
  width: 0;
}
.modal-body .modal-title.h2 {
  text-align: center;
  line-height: 2;
  color: #4e4e87;
  font-weight: 600;
  font-size: 24px;
  width: 100%;
  font-family: "Graphik Bold";
}
.modal-body .h3 {
  text-align: left;
  line-height: 2;
  color: #000;
  font-weight: 600;
  font-size: 22px;
  width: 100%;
  font-family: "Graphik Semi Bold";
}
.modal-body .h4 {
  text-align: left;
  line-height: 2;
  color: #000;
  font-weight: 600;
  font-size: 20px;
  width: 100%;
  border-bottom: none;
}
.modal-body .ullist {
  list-style-type: square;
  margin-left: 50px;
}
.modal-body .table {
  width: 90%;
  margin-left: 30px;
}
.modal-body .table td {
  padding: 1rem;
}
.modal-body p {
  display: block;
  padding: 0 2rem;
  text-align: left;
  line-height: 1.5;
  color: #000;
  font-size: 15px;
}
.modal-body p span.strong {
  font-weight: 600;
}
.modal-body p span.upperRed {
  color: #CE004A;
  padding-top: 1rem;
  display: inline-block;
}
.modal-body p span.purple {
  color: #ce004a;
  font-weight: 600;
}
.modal-body p span.upper {
  padding-top: 1rem;
  display: inline-block;
}
.modal-body p span.l-50 {
  display: inline-block;
  width: 50%;
  line-height: 1;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.modal-body p span.l-50 a {
  text-decoration: underline;
  color: #006CAF;
}
.modal-body p a {
  text-decoration: underline;
  color: #006CAF;
}
.modal-body p a .strong {
  font-weight: 600;
}
.modal-body p.last {
  display: block;
  margin-bottom: 5rem;
}
.modal-body p.mb0 {
  margin-bottom: 0;
}
.modal-body .lrMain {
  overflow: hidden;
}
.modal-body .lrMain .leftRight {
  float: left;
  width: 50%;
}

.faqcontainer {
  width: 55%;
}

.faqcontainer h4 {
  line-height: 2;
  margin-top: 10px;
}

.faqcontainer p {
  line-height: 1.5;
}

.faqcontainer .subtitle {
  line-height: 1;
}

.quicklinkcontainer {
  width: 35%;
  vertical-align: top;
  text-align: left;
  padding-top: 45px;
  padding-left: 120px;
}

.quicklinkcontainer a {
  text-decoration: underline;
  color: #006caf;
  font-weight: 600;
  line-height: 2;
}

.quicklinkcontainer .h4 {
  font-weight: 600;
  text-decoration: none;
  color: #212529;
}

.askforsupport p {
  margin-top: 20px;
  line-height: 1.5;
}

.askforsupport a {
  color: #006caf;
  font-weight: 600;
}

.quickTutorials p {
  margin-top: 30px;
  line-height: 1.5;
}
.quickTutorials p a {
  text-decoration: underline;
  color: #006caf;
  font-weight: 600;
  margin-top: 10px;
}
.quickTutorials .pageVideo {
  width: 100%;
}
.quickTutorials .pageVideo ul {
  width: 100%;
  overflow: hidden;
}
.quickTutorials .pageVideo ul li {
  width: calc(33% - 13px);
  margin-right: 23px;
  margin-bottom: 20px;
  float: left;
}
.quickTutorials .pageVideo ul li.rightSide {
  margin-right: 0;
}

.contactDetailsTable {
  border-collapse: collapse;
  text-align: left;
  width: 40vw;
}
.contactDetailsTable td {
  border: 1px solid black;
  padding-left: 5px;
}

.selectGU {
  border: none;
  border-bottom: solid 1px #666;
  background-color: #ffffff !important;
  margin-left: 10px;
  font-size: initial;
  width: 155px;
  padding: 0 0 0 10px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
