import { Component, OnInit } from "@angular/core";
import { BaseComponent } from "src/app/dynamic-components/components/base/base.component";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: 'app-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.sass'],
  host: {
    '[style.width]': 'config.width',
  }
})
export class RadioComponent extends BaseComponent implements OnInit {
  rand =Math.round((Math.random()*10000)).toString();
  showTooltip: boolean;
  label: any;
  isAgreedTSMWidth: string;
  constructor(private sanitized: DomSanitizer) {
    super();
  }
  ngOnInit() {
    this.label = this.sanitized.bypassSecurityTrustHtml(this.config.label);
    this.isAgreedTSMWidth =
      this.config.name === "IsAgreedTSM"
        ? "700"
        : this.config.name === "SupplierStandardEquivalent"
        ? "600"
        : "";
  }
  estimateChooseOver(e, estimateSpendTooltip) {
    const TooltipEle = estimateSpendTooltip;
    if (estimateSpendTooltip) {
      if (
        e.clientX < TooltipEle.nativeElement.getBoundingClientRect().left ||
        e.clientX >
          TooltipEle.nativeElement.getBoundingClientRect().left +
            TooltipEle.nativeElement.offsetWidth -
            5 ||
        e.clientY < TooltipEle.nativeElement.getBoundingClientRect().top ||
        e.clientY >
          TooltipEle.nativeElement.getBoundingClientRect().top +
            TooltipEle.nativeElement.offsetHeight -
            5
      ) {
        this.showTooltip = false;
      }
    } else {
      this.showTooltip = false;
    }
  }
}
