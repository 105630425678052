import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { ComplianceStatus } from "src/app/interfaces/supplier";
import { CommonService } from "../../../../../services/common.service";
import { SupplierService } from "../../../../../services/supplier.service";
import { ProfileService } from "../../../services/profile.service";
import { SupplierCompliance } from "src/app/interfaces/supplierProfile";
import {
  ButtonCommand,
  ButtonCommands,
  DialogType,
  DialogService,
} from "src/app/services/dialog.service";
import { cloneDeep, trim } from "src/app/utils";
import VMController from "src/app/interfaces/vm";
import { CanComponentDeactivate } from "src/app/can-deactivate.guard";
import {
  AP_ReviewStatus,
  StatusType,
  SupplierStatus,
  LoadingUrlMapping,
} from "src/app/interfaces/mapping";
import { environment } from "src/environments/environment";
import { LoadingService } from "src/app/services/loading.service";
import { ComponentConfig } from "src/app/dynamic-components/ComponentConfig";
import { DynamicContext } from "src/app/dynamic-components/interfaces/DynamicContext";
import { DynamicFormComponent } from "src/app/dynamic-components/components/dynamic-form/dynamic-form.component";
import { OnBoardingService } from "src/app/modules/buyer/services/onboarding.service";
import { MetadataService } from "src/app/services/metadata.service";
import { Validators } from "@angular/forms";
import { DictionaryService } from "src/app/services/dictionary.service";
import { supplierGeographical } from "src/app/shared/shared";
import {
  SetupProcess,
  ProcessNames,
  SupplierChangeRequestProcess,
  AllTaskKey,
} from "src/app/interfaces/workflow";
import { SupplierCommonService } from "../../../services/supplierCommon.service";
import { DatePipe } from "@angular/common";
import { LanguageService } from "../../../../../dynamic-components/utils/language.service";
import { showGlobalPrepopulateData } from "../../../../../../config/config";
import { AuthService } from "src/app/services/auth/auth.service";
import { MatDialog } from '@angular/material/dialog';
import { GlobalPopupComponent } from "src/app/global-popup/global-popup.component";

enum complianceClearType {
  CDF = "CDF",
  CLP = "CLP",
  EA = "EA",
}

enum attachType {
  certificate = "certificate",
  detailsMaterial = "detailsMaterial",
}

@Component({
  selector: "app-compliance",
  templateUrl: "./compliance.component.html",
  styleUrls: ["./compliance.component.sass"],
  providers: [DatePipe],
})
export class ComplianceComponent implements OnInit, CanComponentDeactivate {
  getConfigsInfo: ComponentConfig[] = [];
  originalConfig: ComponentConfig[] = [];
  context: DynamicContext;
  @ViewChild("dynamicForm") dynamicForm: DynamicFormComponent;

  private complianceStatus: ComplianceStatus = {};
  // private supplierStatus: any
  // AP_ReviewStatus = AP_ReviewStatus
  isValidated: boolean;
  isDisabledSaveBtn = false;
  globalAnwsers = [];
  globalNameElement:any;
  dialogRef: any;
  getglobalVal: any;
  // apReviewStatus: string
  isMET = false;
  isResubmitDisabled = true;
  get complianceInfo(): SupplierCompliance {
    return this.profileService.supplierModel.SupplierProfile.SupplierCompliance;
  }
  set complianceInfo(val) {
    this.profileService.supplierModel.SupplierProfile.SupplierCompliance = val
  }
  get isView() {
    if (!this.profileService.isView) {
      this.getConfigsInfo = this.originalConfig;
    }
    return this.profileService.isView;
  }

  vm: VMController<SupplierCompliance> = new VMController();

  constructor(
    private router: Router,
    private commonS: CommonService,
    private supplierService: SupplierService,
    private profileService: ProfileService,
    private dialogService: DialogService,
    private loadingService: LoadingService,
    private onBoardingService: OnBoardingService,
    private dictionaryService: DictionaryService,
    private metadataService: MetadataService,
    private supplierCommonService: SupplierCommonService,
    private languageService: LanguageService,
    private datePipe: DatePipe,
    private auth: AuthService,
    private dialog: MatDialog
  ) {
    this.profileService.stepChangeEvent.emit(4);
  }

  async ngOnInit() {
    this.loadingService.showLoading();
    this.profileService.current_step = 4;
    // load current login user's task
    await this.profileService.loadUserTasks();
    this.context = new DynamicContext();
    this.context.mode = this.onBoardingService.mode;
    this.profileService.current_step = 4;
    if (this.complianceInfo.DiversityType) {
      this.loadCheckQuestion();
    }

    // For display on supplier profile
    if (
      this.profileService.supplierModel.SupplierProfile.SupplierCompliance
        .SupplierBBBEECertificate
    ) {
      this.profileService.supplierModel.SupplierProfile.SupplierCompliance.SupplierBBBEECertificate =
        new Date(
          this.profileService.supplierModel.SupplierProfile.SupplierCompliance.SupplierBBBEECertificate
        );
    }

    if (this.profileService.supplierModel) {
      await this.profileService.loadRequestForm();
    }
    await this.supplierCommonService.SetContextValue(this.context);
    this.getConfigsInfo = await this.metadataService.getPageConfiguration(
      "supplier-profile-step4"
    );
    this.originalConfig = cloneDeep(this.getConfigsInfo);

    this.onUploadFile("EnterpriseScaleAttachments");

    this.context.on("UploadDetailsMaterial", (fileList: any) => {
      if (fileList !== "") {
        this.dynamicForm.formGroup
          .get("UploadDetailsMaterial")
          .patchValue(fileList);
        this.dynamicForm.formGroup
          .get("UploadDetailsMaterial")
          .clearValidators();
        this.dynamicForm.formGroup
          .get("UploadDetailsMaterial")
          .updateValueAndValidity({ onlySelf: true, emitEvent: true });
      } else {
        this.dynamicForm.formGroup
          .get("UploadDetailsMaterial")
          .setValidators(Validators.required);
        this.dynamicForm.formGroup
          .get("UploadDetailsMaterial")
          .updateValueAndValidity({ onlySelf: true, emitEvent: true });
      }
    });

    this.commonS.ExitEvent.subscribe((e) => {
      this.vm.setOriginal(this.dynamicForm.originalValues());
      this.vm.setCurrent(this.dynamicForm.values());
      if (this.vm.isDirty()) {
        e.preventDefault();
        // Chrome requires returnValue to be set
        e.returnValue = "";
      }
    });
    if (!this.isView) {
      this.dynamicForm.subscribeToFormChange(() => {
        setTimeout(() => {
          this.isResubmitDisabled =
            JSON.stringify(this.dynamicForm.originalValues()) ===
            JSON.stringify(this.dynamicForm.values());
        });
      });
    }
    this.loadingService.hideLoading();
    // this.apReviewStatus = this.profileService.supplierModel.Mapping.AP_reviewStatus
    // console.log('MODE', this.onBoardingService.mode)
    if ((this.onBoardingService.mode == 'edit' || this.onBoardingService.mode == 'create') && showGlobalPrepopulateData ){
      this.formAutofill()
    }
  }

  async formAutofill() {
    let payload = {
      supplierCode: this.profileService.supplierCode, 
      SupplierCompanyName: this.profileService.supplierModel.SupplierProfile.SupplierGeneral.CompanyName,
      supplierTsmId: this.profileService.supplierModel.Mapping.TsmId,
      countryServed: this.profileService.supplierModel.RequestForm.RequestorDetailsInfo.SupplierCountry,
      RoleCode: this.auth.passport.supplierMember.RoleCode
    }
    // console.log('formAutofill Global payload', payload);
    if(payload.supplierCode && payload.supplierTsmId){
      let getGlobalQAs;
      if (localStorage.getItem("globalqa") == null) {
        const getGlobalQAs = await this.supplierService.getGlobalFieldData(payload);
        localStorage.setItem("globalqa", JSON.stringify(getGlobalQAs))
      }
      else {
        getGlobalQAs = JSON.parse(localStorage.getItem("globalqa"));
      }
    // console.log('formAutofill getAllAnswers', getGlobalQAs);
      if (this.getConfigsInfo && this.getConfigsInfo.length && getGlobalQAs) {
      this.getConfigsInfo.forEach(item => {
        item['fieldset'].forEach(field => {
          // console.log('field', field)
          // console.log('Global', field['global'])
          if (field['global']) {
            const filterAnwsers = getGlobalQAs.filter((question) => question['QuestionName'] == field['name'])
            this.globalAnwsers.push(...filterAnwsers)
          }
        })
      })
    }
    // console.log('formAutofill globalAnwsers', this.globalAnwsers)
    let complianceInfoData = Object.assign({}, this.complianceInfo)
    // console.log('formAutofill complianceInfoData', complianceInfoData);  
    this.globalAnwsers.forEach((anwserItem) => {
      if (anwserItem != null && anwserItem != undefined) {
        // console.log('formAutofill this.generalInfo', this.complianceInfo);  
        if((this.complianceInfo[anwserItem['QuestionName']] == "") || (this.complianceInfo[anwserItem['QuestionName']] == null) || (this.complianceInfo[anwserItem['QuestionName']] == undefined)){
          // console.log('formAutofill Prepopulate Values', anwserItem['Answer']);    
          complianceInfoData[anwserItem['QuestionName']] = anwserItem['Answer']
        }
      }
    })
    this.complianceInfo = complianceInfoData
    // console.log('formAutofill this.generalInfo', this.complianceInfo);  
    setTimeout(() => {
      // console.log('formAutofill Calling updateFormValues');  
      this.dynamicForm.updateFormValues()
    });
  }
  setTimeout(() => {  
    if(!localStorage.getItem('compliancesaved') && localStorage.getItem('compliancesaved') !== 'yes') {
      this.SaveFirsttime();
    }                
    console.log("settimeout");
    const elements: HTMLInputElement[] = [];

    for (let gobalVal of this.globalAnwsers) {
      let formControlType: string;

      if (gobalVal['AnswerInputField'] === 'dropdown') {
        formControlType = 'select';
      } else if (gobalVal['AnswerInputField'] === 'input') {
        formControlType = 'input';
      } else if (gobalVal['AnswerInputField'] === 'radio') {
        formControlType = 'radio';
      }

      const actualElement = document.querySelector<HTMLInputElement>(`[id^="${gobalVal['QuestionName']}${formControlType}"]`);

      if (actualElement) {
        elements.push(actualElement);
      }
    }

    for (const element of elements) {
      element.addEventListener("change", (e) => {
        // console.log("Field change??? ===>", e);
        const targetElement = e.currentTarget as HTMLElement;
        if (targetElement) {
          targetElement.style.border = 'solid 1px #414187';
        }
      });
    }
  });
}

  highlightInput() {
    const elements: HTMLInputElement[] = [];

    for (let gobalVal of this.globalAnwsers) {
      let formControlType: string;

      if (gobalVal['AnswerInputField'] === 'dropdown') {
        formControlType = 'select';
      } else if (gobalVal['AnswerInputField'] === 'input') {
        formControlType = 'input';
      } else if (gobalVal['AnswerInputField'] === 'radio') {
        formControlType = 'radio';
      }

      const actualElement = document.querySelector<HTMLInputElement>(`[id^="${gobalVal['QuestionName']}${formControlType}"]`);

      if (actualElement) {
        elements.push(actualElement);
      }
    }''

    for (const element of elements) {
      element.style.border = '2px solid orange';
    }
  }
  async SaveFirsttime(): Promise<boolean> {
    this.profileService.supplierModel.SupplierProfile.SupplierCompliance = trim(
      this.dynamicForm.values()
    );
    const saveUrl = `${environment.gateway}${LoadingUrlMapping.saveProfileDraft}`;
    this.loadingService.openLoading(saveUrl);
    await this.profileService.saveDraft();
    localStorage.setItem('compliancesaved', 'yes');
    return true;
  }
  openGlobalPopup() {
    this.dialogRef = this.dialog.open(GlobalPopupComponent, {
      width: '450px',
    });

    this.dialogRef.afterClosed().subscribe(result => {
      for (this.getglobalVal of this.globalAnwsers) {
        const supplierControl = this.dynamicForm.formGroup.controls[this.getglobalVal.QuestionName];
        if (supplierControl) {
          if (supplierControl.dirty) {
            if (result === 'onConfirmClick') {
              this.router.navigate([
                `supplier/profile/${this.profileService.supplierCode}/step5`,
              ]);
            } else if (result === 'onCancelClick') {
              supplierControl.setValue(this.getglobalVal.Answer);
              this.highlightInput();
            }
          }
        }
      }
    });
  }

  checkAndOpenGlobalPopup() {
    const globalDirty = this.globalAnwsers.some(globalAnswerVal => {
      const control = this.dynamicForm.formGroup.controls[globalAnswerVal.QuestionName];
      return control && control.dirty;
  });
    if (globalDirty) {
      this.openGlobalPopup();
  }
  else if(!globalDirty){
    this.router.navigate([
      `supplier/profile/${this.profileService.supplierCode}/step5`,
    ]);
  }
  }

  onUploadFile(fieldName: string) {
    this.context.on(fieldName, (fileList: any) => {
      if (fileList !== "") {
        this.dynamicForm.formGroup.get(fieldName).patchValue(fileList);
        this.dynamicForm.formGroup.get(fieldName).clearValidators();
        this.dynamicForm.formGroup
          .get(fieldName)
          .updateValueAndValidity({ onlySelf: true, emitEvent: true });
      } else {
        this.dynamicForm.formGroup
          .get(fieldName)
          .setValidators(Validators.required);
        this.dynamicForm.formGroup
          .get(fieldName)
          .updateValueAndValidity({ onlySelf: true, emitEvent: true });
      }
    });
  }

  async canDeactivate() {
    if (this.isView) {
      return true;
    }
    this.vm.setOriginal(this.dynamicForm.originalValues());
    this.vm.setCurrent(this.dynamicForm.values());
    if (this.vm.isDirty()) {
      const result = await this.dialogService.saveConfirm();
      if (result === "save") {
        if (!(await this.saveDraft())) {
          return false;
        }
      }
      if (result === "cancel") {
        return false;
      }
    }

    return true;
  }

  loadCheckQuestion() {
    this.complianceStatus.StepFourComment =
      this.complianceInfo.DiversityType.substring(0, 1) === "F" ? true : false;
    this.complianceStatus.IsCompliance =
      this.complianceInfo.DiversityType.substring(0, 1) === "C" ? true : false;
  }

  validate() {
    return this.dynamicForm.valide();
  }

  async resubmit() {
    this.context.changeSubmitState();
    this.dynamicForm.removeFormTouch();
    if (!this.validate()) {
      return;
    }
    // resubmit
    if (
      this.profileService.getUserTaskByTaskKey(AllTaskKey.SST_rejectToSupplier)
    ) {
      this.resubmitForm().then((data: boolean) => {
        if (data) {
          this.vm.saved();
          this.dialogService
            .dialog(
              this.languageService.getValue(
                "static.supplier.components.compliance.change_request"
              ),
              ButtonCommands.Ok,
              DialogType.success,
              this.languageService.getValue(
                "static.supplier.components.compliance.please_note"
              )
            )
            .then((result: boolean) => {
              this.router.navigate([`supplier/landing`]);
            });
        }
      });
    } else {
      this.changeRequest().then((data: boolean) => {
        if (data) {
          this.vm.saved();
          this.dialogService
            .dialog(
              this.languageService.getValue(
                "static.supplier.components.compliance.change_request"
              ),
              ButtonCommands.Ok,
              DialogType.success,
              this.languageService.getValue(
                "static.supplier.components.compliance.please_note"
              )
            )
            .then((result: boolean) => {
              this.router.navigate([`supplier/landing`]);
            });
        }
      });
    }
  }

  async btnNext() {
    this.router.navigate([
      `supplier/profile/${this.profileService.supplierCode}/step5`,
    ]);
  }

  // async save(): Promise<boolean> {
  //   this.profileService.supplierModel.SupplierProfile.SupplierCompliance = trim(this.complianceInfo)
  //   if (!this.validate()) {
  //     return false
  //   }
  //   const saveUrl = `${environment.gateway}${LoadingUrlMapping.saveProfileForm}`
  //   this.loadingService.openLoading(saveUrl)
  //   if (this.profileService.profile_step < this.profileService.current_step) {
  //     this.profileService.profile_step = 4
  //   }
  //   await this.profileService.saveSupplierProfile()
  //   return true
  // }

  // resubmit request form
  async resubmitForm(): Promise<boolean> {
    this.context.changeSubmitState();
    this.dynamicForm.removeFormTouch();
    if (!this.validate()) {
      return false;
    }

    await this.updateSupplierMapping();

    // Modify the display style of SupplierBBBEECertificate
    this.modefyDateFormat();

    // get executable task GUID: taskInstId
    this.profileService.getResubmitTask();
    if (!this.profileService.supplierModel.Mapping.executeTask) {
      return false;
    }
    const saveUrl = `${environment.gateway}${LoadingUrlMapping.resubmitSupplierProfile}`;
    this.loadingService.openLoading(saveUrl);
    await this.profileService.resubmitForm();
    return true;
  }

  /**
   * resubmit request form
   */
  async changeRequest(): Promise<boolean> {
    this.context.changeSubmitState();
    this.dynamicForm.removeFormTouch();
    if (!this.validate()) {
      return false;
    }
    await this.updateSupplierMapping();

    // Modify the display style of SupplierBBBEECertificate
    this.modefyDateFormat();

    const saveUrl = `${environment.gateway}${LoadingUrlMapping.requestChangeSupplierProfile}`;
    this.loadingService.openLoading(saveUrl);
    await this.profileService.changeRequestForm();
    return true;
  }

  /**
   * save draft request form
   */
  async saveDraft(): Promise<boolean> {
    this.context.changeSubmitState();
    this.dynamicForm.removeFormTouch();
    if (!this.validate()) {
      return false;
    }
    if (!this.complianceInfo.UploadCertificate) {
      this.complianceInfo.UploadCertificate = [];
    }
    if (!this.complianceInfo.UploadDetailsMaterial) {
      this.complianceInfo.UploadDetailsMaterial = [];
    }

    await this.updateSupplierMapping();

    // Modify the display style of SupplierBBBEECertificate
    this.modefyDateFormat();

    const saveUrl = `${environment.gateway}${LoadingUrlMapping.saveProfileDraft}`;
    this.loadingService.openLoading(saveUrl);
    await this.profileService.saveDraft();
    return true;
  }

  /**
   * Add by Jerome
   * Modify the display style of SupplierBBBEECertificate
   */
  modefyDateFormat() {
    const formValue = trim(this.dynamicForm.values());
    this.profileService.supplierModel.SupplierProfile.SupplierCompliance.SupplierBBBEECertificate =
      this.datePipe.transform(formValue.SupplierBBBEECertificate, "MM/dd/yyyy");
  }

  /**
   * update supplier mapping info
   * @private
   */
  private async updateSupplierMapping() {
    this.profileService.supplierModel.SupplierProfile.SupplierCompliance = trim(
      this.dynamicForm.values()
    );
    if (this.profileService.profile_step < this.profileService.current_step) {
      this.profileService.profile_step = 4;
    }
  }

  get showResubmitBtn() {
    if (this.dynamicForm) {
      if (
        !this.isView &&
        !this.dynamicForm.disabled &&
        this.dynamicForm.formGroup
      ) {
        this.isResubmitDisabled =
          JSON.stringify(this.dynamicForm.originalValues()) ===
          JSON.stringify(this.dynamicForm.values());
      }
    }
    return this.profileService.checkResubmit();

    // workflow comments: remove supplier change request
    // return (this.apReviewStatus === AP_ReviewStatus.SST_reject || this.profileService.supplierModel.Mapping.status === 'Onboarded')
    //   && !this.isView && !this.profileService.checkIsMigrateSubmit()
  }

  btnSave() {
    this.saveDraft().then((data: boolean) => {
      if (data) {
        this.vm.saved();
        this.checkAndOpenGlobalPopup();
       }
    });
  }
}
