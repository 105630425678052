import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  HostListener,
  Output,
  EventEmitter,
  Input,
} from "@angular/core";
import { AuthService } from "src/app/services/auth/auth.service";
import { BuyerService } from "../../../../services/buyer.service";
import { DashBoardService } from "../../services/dashboard.service";

@Component({
  selector: "app-accept-modal",
  templateUrl: "./accept-modal.component.html",
  styleUrls: ["./accept-modal.component.sass"],
})
export class AcceptModalComponent implements OnInit {
  @Output() acceptEvent: EventEmitter<boolean>;

  // buttonDisabled: boolean
  @ViewChild("acceptScroll") acceptScroll: ElementRef;

  constructor(private dashBoardService: DashBoardService) {
    this.acceptEvent = new EventEmitter();
  }

  ngOnInit() {
    // this.buttonDisabled = false
  }
  @HostListener("scroll", ["$event"])

  // onscroll(event: any) {
  //   if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight - 100) {
  //     this.buttonDisabled = true
  //   } else {
  //     this.buttonDisabled = false
  //   }
  // }
  acceptModal(): void {
    this.acceptEvent.emit(true);
  }
}
