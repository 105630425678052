import { Component, HostListener, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-ra-trigger',
  standalone: true,
  imports: [],
  templateUrl: './ra-trigger.component.html',
  styleUrl: './ra-trigger.component.sass'
})
export class RaTriggerComponent implements OnInit {
  raData: any[] = [];
  pageSize: number = 100;
  currentPage: number = 1;
  totalItems: number = 0;
  isDialogVisible = false;
  isFormDirty = false;
  constructor(private commonService: CommonService) { }

  ngOnInit() {
    this.loadRAStatus();
  }

  async loadRAStatus() {
    try {
      const data = await this.commonService.getRAData();
      this.raData = Array.isArray(data) ? data : [];
      this.totalItems = this.raData.length;
    } catch (error) {
      console.error('Error fetching RA Status:', error);
    }
  }

  get paginatedArr() {
    const startIndex = (this.currentPage - 1) * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    return this.raData.slice(startIndex, endIndex);
  }

  get totalPages() {
    return Math.ceil(this.totalItems / this.pageSize);
  }

  changePage(page: number) {
    if (page >= 1 && page <= this.totalPages) {
      this.currentPage = page;
    }
  }

  async save() {
    const updatedRecords = this.paginatedArr
      .filter(status => status.status === 'Resubmit')
      .map(status => ({
        ra_id: status.ra_id,
        status: status.status
      }));

    if (updatedRecords.length > 0) {
      try {
        await this.commonService.updateRAStatus(updatedRecords);
        console.log('Updated Records:', updatedRecords);
        this.isFormDirty = false; // Mark as clean after saving
      } catch (error) {
        console.error('Error saving RA Status:', error);
      }
    } else {
      console.log('No records to update');
    }
  }

  @HostListener('window:beforeunload', ['$event'])
  canDeactivate($event: any): void {
    if (this.isFormDirty) {
      $event.returnValue = 'You have unsaved changes. Are you sure you want to leave?';
    }
  
  }

  openDialog() {
    this.isDialogVisible = true;
  }

  closeDialog() {
    this.isDialogVisible = false;
  }

  confirmChanges() {
    this.save();
    this.closeDialog();
    alert('Changes saved successfully!');
  }

  markAsDirty() {
    this.isFormDirty = true;
  }

  markAsClean() {
    this.isFormDirty = false;
  }

}
