import { Injectable } from "@angular/core";
import { MigrateSetupSteps, MigratedTask } from "src/app/interfaces/process";
import {
  ProcessNames,
  HistoryTask,
  HistoryProcessInstance,
  MigrateProcess,
} from "src/app/interfaces/workflow";
import {
  SupplierStatus,
  RiskAssessmentStatus,
} from "src/app/interfaces/mapping";
import * as moment from "moment";
import { WorkflowService } from "src/app/services/workflow.service";
import { ProfileService } from "./profile.service";
import { SupplierService } from "src/app/services/supplier.service";
import { LaunchDarklyService } from "src/app/services/launch-darkly.service";
@Injectable({
  providedIn: "root",
})
export class MigrateSetupProcessService {
  isNewVersion: boolean = true;

  constructor(
    private profileService: ProfileService,
    private workflowService: WorkflowService,
    private supplierService: SupplierService,
    private launchDarklyService: LaunchDarklyService
  ) {
    this.launchDarklyService.ldChange.subscribe(any => {
      console.log("Trigger refresh data")
      this.refreshToggle()
    })
  }
  refreshToggle():void{
    this.isNewVersion = this.launchDarklyService.getToggle(LaunchDarklyService.LD_ISNEWVERSION).toLowerCase() === "true";
  }
  get migrateSetupTaskHistory(): Array<any> {
    return this.profileService.supplierModel.Mapping.historyTasks.filter(
      (task) =>
        task.processDefName === ProcessNames.generic_supplierMigrateSetup
    );
  }

  circleSteps = [];
  currTitleStep: any;
  private migrateSetupSteps = MigrateSetupSteps;
  private processHistories: HistoryProcessInstance[];
  // load supplier setup process
  async loadMigrateSetupProcess() {
    // update task steps
    await this.updateMigrateSetup_MigrateSteps();
    this.initMigrateSetup_APTasks();
    await this.initMigrateSetup_EndSteps();
    this.migrateSetupTaskHistory.forEach((item: HistoryTask) => {
      if (
        item.name === MigrateProcess.supplier_accepted ||
        item.name === MigrateProcess.profile_draft
      ) {
        this.updateMigrateSetup_TaskSteps(item);
      }
    });
    this.migrateSetupSteps_Handling();
  }

  migrateSetupSteps_Handling() {
    // buyer process don't show SAP
    const showSteps = Object.values(this.migrateSetupSteps).filter(
      (item) =>
        item.status !== MigrateProcess.QC_review &&
        item.status !== MigrateProcess.SST_review
    );

    // unfinished task
    const unfinishedTasks = showSteps.filter((item) => !item.done);

    // complete task
    let completedTasks = showSteps.filter((item) => item.done);
    completedTasks = completedTasks.sort(
      (first, second) =>
        moment(first.step.UpdateAt).unix() - moment(second.step.UpdateAt).unix()
    );

    // all step circles
    this.circleSteps = [...completedTasks, ...unfinishedTasks];
    //  current step
    this.currTitleStep = Object.assign([], this.circleSteps)
      .reverse()
      .find((item) => !!item && item.done);
      this.circleSteps = this.circleSteps.filter(((item) => !!item && item.status !== MigrateProcess.profile_resubmit)); 
  }

  // update migrate steps
  private async updateMigrateSetup_MigrateSteps() {
    const migrateDate = await this.getMigrateDate(
      this.profileService.supplierModel.Mapping.supplierCode
    );
    const tmpItem: any = {
      UpdateAt: migrateDate,
      status: MigratedTask.migrate,
    };
    const step = this.migrateSetupSteps[MigratedTask.migrate];
    if (step) {
      step.done = true;
      step.step = tmpItem;
    }
  }
  // init ap subProcess task steps
  private initMigrateSetup_APTasks() {
    const qcReviewComplete: boolean = this.profileService.checkAPTaskIsComplete(
      ProcessNames.generic_supplierMigrateSetup,
      MigrateProcess.QC_review
    );
    if (qcReviewComplete) {
      const qcTasks = this.migrateSetupTaskHistory
        .filter((item: HistoryTask) => MigrateProcess.QC_review === item.name)
        .sort(
          (first, second) =>
            moment(first.endTime).unix() - moment(second.endTime).unix()
        );
      const lastQCTask = qcTasks[qcTasks.length - 1];
      this.updateMigrateSetup_APTaskSteps(lastQCTask);
    }
  }

  // update general task steps
  private updateMigrateSetup_TaskSteps(task: HistoryTask) {
    const tmpItem: any = {
      UpdateAt: task.endTime,
      status: task.name,
    };
    const step = this.migrateSetupSteps[task.name];
    if (step) {
      step.done = true;
      step.step = tmpItem;
    }
  }

  // init terminate and on boarded steps
  private async initMigrateSetup_EndSteps() {
    await this.loadProcessHistories();
    if (this.processHistories && this.processHistories.length > 0) {
      const setupProcess = this.processHistories.find(
        (process: HistoryProcessInstance) =>
          process.name === ProcessNames.generic_supplierMigrateSetup
      );
      if (!setupProcess) {
        return;
      }
      this.updateMigrateSetup_CompletedSteps(setupProcess);
    }
  }
  // update complete steps
  private updateMigrateSetup_CompletedSteps(process: HistoryProcessInstance) {
    const tmpItem: any = {
      UpdateAt: process.endTime,
      status: SupplierStatus.onboarded,
    };
    const step = this.migrateSetupSteps[SupplierStatus.onboarded];
    if (step) {
      step.done = true;
      step.step = tmpItem;
    }
  }
  // update ap task steps
  private updateMigrateSetup_APTaskSteps(task: HistoryTask) {
    const tmpItem: any = {
      UpdateAt: task.endTime,
      status: RiskAssessmentStatus.SAP,
    };
    const step = this.migrateSetupSteps[RiskAssessmentStatus.SAP];
    if (step) {
      step.done = true;
      step.step = tmpItem;
    }
  }

  // load process histories and update steps
  private async loadProcessHistories() {
    const data = await this.workflowService.getProcessHistoriesByBusinessKey(
      this.profileService.supplierModel.Mapping.supplierCode
    );
    if (data.isSuccess) {
      this.processHistories = data.data;
    }
  }
  clear() {
    Object.values(this.migrateSetupSteps).map((item) => {
      item.done = false;
      item.reject = false;
    });
  }
  private async getMigrateDate(supplierCode: string): Promise<string> {
    let migrateDate: string;
    let suppliers: any;
    if (this.isNewVersion) {
        suppliers = await this.supplierService.querySupplierHistoriesEnhanced(supplierCode);
      } else {
        suppliers = await this.supplierService.querySupplierHistories(
        supplierCode
      );
    }   
    if (suppliers.isSuccess) {
      if (suppliers.data) {
        migrateDate = suppliers.data[0].createdOn;
      }
    }
    return migrateDate;
  }
}
