// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.background {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1100;
  background-color: rgba(0, 0, 0, 0.85);
}

.criteria {
  position: fixed;
  left: 330px;
  right: 330px;
  top: 70px;
  height: 70%;
  background: #fff;
  z-index: 1009;
  padding: 0px;
  background: #f0f0f0;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 30px;
  text-align: left;
  overflow-x: hidden;
  overflow-y: auto;
}

.title {
  margin-top: 20px;
  margin-bottom: 20px;
}

.text-center {
  text-align: center !important;
}

.header {
  color: #333;
  font-size: 18px;
  font-weight: medium;
}

.float_right {
  float: right;
}

.link-mare-panel {
  position: absolute;
  height: auto;
  background: #fff;
  color: #000;
  border-radius: 5px;
  padding: 5px 0;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.12), 0 3px 6px rgba(0, 0, 0, 0.24);
  outline: 10;
  z-index: 9999;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
