import { Component, OnInit, HostListener } from "@angular/core";
import { privacyStatementState } from "src/app/interfaces/mapping";

@Component({
  selector: "app-management-layout",
  templateUrl: "./management-layout.component.html",
  styleUrls: ["./management-layout.component.sass"],
})
export class ManagementLayoutComponent implements OnInit {
  privacyStatement = privacyStatementState;

  constructor() {}

  ngOnInit() {}

  @HostListener("document: click", ["$event"])
  documentClick(evt) {
    // handle footer click
    if (this.privacyStatement.linkType === "footerLink") {
      this.privacyStatement.privacyStatement = true;
    }
  }
  async privacyaccept(flag) {
    if (flag) {
      // upload supplierAccepted status
    } else {
      this.privacyStatement.privacyStatement = false;
      this.privacyStatement.linkType = "";
    }
  }

  termclose() {
    this.privacyStatement.termOfUse = false;
  }
}
