import { ComponentConfig } from "../ComponentConfig";
import { DateFormator } from "./DateFormator";
import { NumberFormator } from "./NumberFormator";

export class FomatorFactory {
  private formators = new Map();

  createFormator(config: ComponentConfig) {
    if (config.dateformat) {
      return new DateFormator(config.dateformat);
    }
    if (config.numberformat) {
      return new NumberFormator(config.numberformat);
    }
  }

  createCustomFormator(config: ComponentConfig) {
    // TODO
  }
}

export default new FomatorFactory();
