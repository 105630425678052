import { AuthService } from "src/app/services/auth/auth.service";
import { BuyerService } from "src/app/services/buyer.service";
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
} from "@angular/core";
import {
  FileItem,
  FileUploader,
  FileLikeObject,
  FileUploaderOptions,
} from "ng2-file-upload";
import { config, met_upload_config } from "src/upload_setting/app.config";
import {
  FileType,
  FileUploadError,
} from "src/app/services/file-upload.service";
import {
  DialogService,
  ButtonCommands,
  DialogType,
} from "src/app/services/dialog.service";
import { allowedMimeType, supplierGeographical } from "../../shared";
import { environment } from "../../../../environments/environment";
import { SupplierService } from "src/app/services/supplier.service";
import { State } from "../../../shared/shared";
import { strToHexCharCode } from "../../../utils/index";
import { ProfileService } from "src/app/modules/supplier/services/profile.service";
import { HttpService } from "src/app/services/api/http.service";
import { LoadingService } from "src/app/services/loading.service";

enum Role {
  Buyer = "buyer",
  Supplier = "supplier",
}

const fileNamePattern = /^[^]*$/;

@Component({
  selector: "app-file-upload",
  templateUrl: "./file-upload.component.html",
  styleUrls: ["./file-upload.component.sass"],
})
export class FileUploadComponent implements OnInit {
  @Input() disabled = true;
  @Input() maxFileSize;
  @Input() fileType;
  @Input() supplierCode;
  @Input() uploadFileList?: any;
  @Input() imageData: any;
  @Input() showProgress = false;
  @Input() description = "Click to add or drag your file here";
  @Input() height = "100%";
  @Input() autoUpload = true;
  @Input() multiple = false;
  @Input() isView = false;
  @Input() templateOptions: any;
  @Input() messageLessMinSize: string;
  @Input() messageLargerMaxSize: string;
  @Input() messageInvalidType: string;
  @Output() error: EventEmitter<FileUploadError>;
  @Output() addedFiles = new EventEmitter<any>();
  @Output() isDisabledSaveBtn = new EventEmitter<any>();
  @Output() completed: EventEmitter<any>;

  @ViewChild("multiValueElement") multiValueElement: any;
  @ViewChild("valueElement") valueElement: any;

  uploader: FileUploader;
  hasBaseDropZoneOver = false;
  fType: any = FileType;
  isAdded = false;
  failedFile: FileLikeObject;
  errorType: FileUploadError;
  uploadingFiles?: Array<any> = [];
  maxFileNumber = config.uploadOptions.maxFileSize;
  baseUrl = environment.gateway;

  constructor(
    private dialogService: DialogService,
    private buyerService: BuyerService,
    private supplierService: SupplierService,
    private auth: AuthService,
    private profileService: ProfileService,
    private http: HttpService,
    private loadingService: LoadingService
  ) { }

  initUploader() {
    this.maxFileSize = this.maxFileSize || config.uploadOptions.maxFileSize;
    config.uploadOptions.autoUpload = this.autoUpload;
    this.uploader = new FileUploader(config.uploadOptions as FileUploaderOptions);
  }

  initUploadForMet() {
    this.maxFileSize =
      this.maxFileSize || met_upload_config.uploadOptions.maxFileSize;
    config.uploadOptions.autoUpload = this.autoUpload;
    this.uploader = new FileUploader(met_upload_config.uploadOptions as FileUploaderOptions);
  }
  async ngOnInit() {
    if (this.profileService.supplierModel) {
      await this.profileService.loadRequestForm();
    }
    if (this.uploadFileList) {
      this.uploadingFiles = this.uploadFileList;
    }
    if (
      this.profileService.supplierModel &&
      this.profileService.supplierModel.RequestForm.RequestorDetailsInfo
        .SupplierGeographical === supplierGeographical.MET
    ) {
      this.initUploadForMet();
    } else {
      this.initUploader();
    }

    this.uploader.onWhenAddingFileFailed = async (
      failed: FileLikeObject,
      filter: any,
      options: any
    ) => {
      this.failedFile = failed;
      if (this.error) {
        this.error.emit(this.errorType);
      } else {
        this.clearValues();
        this.showError(this.failedFile);
      }
    };

    this.uploader.onAfterAddingAll = async (newFiles: FileItem[]) => {
      const existingFiles = this.uploadingFiles.filter((uf) =>
        newFiles.find((nf) => nf.file.name === uf.name)
      );
      if (existingFiles && existingFiles.length > 0) {
        return;
      }
      const fileNum = this.maxFileNumber - this.uploadingFiles.length;
      newFiles = newFiles.slice(0, fileNum);
      // fileNamePattern.test(this.requestorDetailsInfo.SupplierPOCEmail)
      const allowedMimeType: Array<string> = this.findAllowedMimeType();
      newFiles.forEach(async (file) => {
        const fileType: string = this.initFileType(file);
        if (
          allowedMimeType.length &&
          allowedMimeType.indexOf(fileType) === -1
        ) {
          this.addingFileFailed(file.file);
          if (newFiles.length === this.uploader.queue.length) {
            newFiles = [];
            this.uploader.queue = [];
          } else {
            newFiles = [];
            this.uploader.queue = this.uploader.queue.slice(1, 1);
          }
          return;
        }
        if (!fileNamePattern.test(file.file.name)) {
          if (newFiles.length === this.uploader.queue.length) {
            newFiles = [];
            this.uploader.queue = [];
          } else {
            newFiles = [];
            this.uploader.queue = this.uploader.queue.slice(1, 1);
          }
          this.clearValues();
          await this.dialogService.dialog(
            `The attachment could not be uploaded. Please use a file name containing only the following characters: 'a-z' 'A-Z' '0-9'  '-' or '_' `,
            ButtonCommands.Close,
            DialogType.alert,
            "Please Note"
          );
          return;
        }
      });
      const newUploaderQueue = this.uploader.queue.filter(
        (fileItem: FileItem, index) => {
          if (index < newFiles.length) {
            return true;
          }
        }
      );
      this.uploader.queue = [];
      this.uploader.queue = newUploaderQueue;

      // const state = environment.role
      // let uploadPath: string

      // if (state === State.buyer && this.auth.passport.buyer) {
      //   uploadPath = 'buyer/file/upload'
      // } else if (state === State.supplier && this.auth.passport.supplierMember) {
      //   uploadPath = 'supplier/file/upload'
      // }
      newFiles.forEach((file) => {
        const fileImg = this.getImageUrl(file);
        // const fileImg = this.initFileType(file);
        // file.url = `${this.baseUrl}/${uploadPath}/${this.supplierCode}`
        // console.log('file.url======>',file.url);
        this.uploadingFiles.push({
          name: file.file.name,
          imgUrl: fileImg,
          isUploading: true,
        });
      });
      this.isDisabledSaveBtn.emit();

      const resFileList =
        environment.role === Role.Buyer
          ? await this.buyerService.buyerFileUpload(
              this.supplierCode,
              "files",
              newFiles
            )
          : await this.supplierService.supplierFileUpload(
              this.supplierCode,
              "files",
              newFiles
            );
      resFileList.data.map((fileRes) => {
        this.uploadingFiles.map((uploadedFile) => {
          if (uploadedFile.name === fileRes.OriginalName) {
            uploadedFile.filePath = fileRes.Location;
            uploadedFile.key = fileRes.BucketKey;
            uploadedFile.isUploading = false;
          }
        });
      });
      this.addedFiles.emit(this.uploadingFiles);
    };

    this.uploader.onCompleteAll = () => {};
  }

  initFileType(file) {
    let type;
    const chunkFile = file.file.name.split(".");
    if (file.file.type === allowedMimeType.PDF) {
      type = "pdf";
    }
    if (
      file.file.type === allowedMimeType.XLS ||
      file.file.type === allowedMimeType.CSV ||
      file.file.type === allowedMimeType.XLSX
    ) {
      type = "xls";
    }
    if (
      file.file.type === allowedMimeType.DOC ||
      file.file.type === allowedMimeType.DOCX
    ) {
      type = "doc";
    }
    if (
      file.file.type === allowedMimeType.PPT ||
      file.file.type === allowedMimeType.PPTX
    ) {
      type = "ppt";
    }
    if (file.file.type === allowedMimeType.RTF) {
      type = "rtf";
    }
    if (file.file.type === allowedMimeType.IMG ||  file.file.type === allowedMimeType.PNG) {
      type = "img";
    }
    if (file.file.type === allowedMimeType.ODT) {
      type = "odt";
    }
    if (file.file.type === "" && chunkFile[chunkFile.length - 1] === "msg") {
      type = "msg";
    }
    if (file.file.type === allowedMimeType.TXT) {
      type = "txt";
    }
    if (file.file.type === "" && chunkFile[chunkFile.length - 1] ==="java") {
      type = "java";
    }
    return type;
  }

  getImageUrl(file) {
    const type: string = this.initFileType(file);
    return `/assets/images/files/${type}_file.svg`;
  }

  resetuploadingFiles() {
    this.uploadingFiles = [];
  }

  // get requestorDetailsInfo(): RequestorDetailsInfo {
  //   return this.onBoardingService.supplierModel.RequestForm.RequestorDetailsInfo
  // }

  async showError(errorFile) {
    this.messageInvalidType = "";
    this.messageLargerMaxSize = "";
    if (errorFile.size > this.maxFileSize) {
      this.errorType = FileUploadError.SizeError;
      this.messageLargerMaxSize =
        this.messageLargerMaxSize ||
        "The file you tried to upload is more than " +
          this.maxFileSize / 1024 / 1024 +
          "MB, please try to upload files which are less than or equal to " +
          this.maxFileSize / 1024 / 1024 +
          "MB.<br/>";
    }

    const allowedTypes = [
      ...met_upload_config.uploadOptions.allowedMimeType,
      ...config.uploadOptions.allowedMimeType,
    ];
    if (allowedTypes || errorFile.type === "") {
      this.errorType = FileUploadError.TypeError;
      this.messageInvalidType =
        this.messageInvalidType ||
        `The file you tried to upload is not supported,
                                    please see the help icon besides upload field to know what are supported file types.`;
    }

    if (this.messageLargerMaxSize) {
      await this.dialogService.dialog(
        this.messageLargerMaxSize,
        ButtonCommands.Close,
        DialogType.alert,
        "Unsupported File Size"
      );
      return;
    }
    if (
      this.messageInvalidType ||
      (this.messageInvalidType && this.messageLargerMaxSize) ||
      errorFile.type === ""
    ) {
      await this.dialogService.dialog(
        this.messageInvalidType,
        ButtonCommands.Close,
        DialogType.alert,
        "Unsupported File Types"
      );
      return;
    }
  }

  fileOverBase(event) {
    // 拖拽状态改变的回调函数
    this.hasBaseDropZoneOver = event;
  }

  fileDropOver(event) {
    // 文件拖拽完成的回调函数
  }

  async downloadFile(fileKey, fileName) {
    let role = "";
    const access_token = `Bearer ${this.auth.passport.access_token}`;
    const state = environment.role;
    let downloadPath: string;
    if (state === State.buyer && this.auth.passport.buyer) {
      role = this.auth.passport.buyer.RoleCode;
      downloadPath = "buyer/file/download";
    } else if (state === State.supplier && this.auth.passport.supplierMember) {
      role = this.auth.passport.supplierMember.RoleCode;
      downloadPath = "supplier/file/download";
    }
    const params = strToHexCharCode(
      `key=${fileKey}&Authorization=${access_token}&state=${state}&role=${role}`
    );
    // const url = `${this.baseUrl}/${downloadPath}?fileCode=${params}`
    // window.open(url, '_blank')
    const url = `${this.baseUrl}/${downloadPath}`;
    this.loadingService.openLoading(url);
    this.http
      .PostBlobPromise(url, { fileCode: params }, "blob")
      .then((data: BlobPart) => {
        const blob = new Blob([data]);
        // for ie
        if ("msSaveOrOpenBlob" in navigator) {
          window.navigator.msSaveOrOpenBlob(blob, fileName);
        } else {
          const link = document.createElement("a");
          link.setAttribute("href", window.URL.createObjectURL(blob));
          link.setAttribute("download", fileName);
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          this.loadingService.closeLoading();
        }
      });
  }

  deleteFile(index) {
    this.uploadingFiles.splice(index, 1);
    this.uploader.queue.splice(index, 1);
    this.addedFiles.emit(this.uploadingFiles);
    this.clearValues();
  }

  clearValues() {
    if (this.valueElement) {
      this.valueElement.nativeElement.value = null;
    }
    if (this.multiValueElement) {
      this.multiValueElement.nativeElement.value = null;
    }
  }
  addingFileFailed(failed: FileLikeObject) {
    this.failedFile = failed;
    this.clearValues();
    this.showError(this.failedFile);
  }
  findAllowedMimeType(): Array<string> {
    const mimeType: Array<string> =
      this.templateOptions &&
      this.templateOptions.config &&
      this.templateOptions.config.allowedMimeType
        ? this.templateOptions.config.allowedMimeType
        : [];
    return mimeType;
  }
}
