import { Injectable } from "@angular/core";
import { HttpService } from "./api/http.service";
import { environment } from "src/environments/environment";
import { HoldOn } from "../interfaces/HoldOn";
@Injectable()
export class HoldOnService {
  baseUrl = environment.gateway;
  constructor(private http: HttpService) {}

  async saveHoldOn(holdOn: HoldOn) {
    const url = `${this.baseUrl}/holdOn/saveHoldOn`;
    const result: any = await this.http.PostPromise(url, holdOn);
    return result.isSuccess;
  }

  public getLatestHoldonBysupplierCode(supplierCode: string) {
    const url =
      `${this.baseUrl}/holdOn/getLatestHoldonBySupplierCode/${supplierCode}` +
      "?num=" +
      Math.random();
    return this.http.GetPromise(url);
  }

  public getLatestActiveHoldonBysupplierCode(supplierCode: string) {
    const url =
      `${this.baseUrl}/holdOn/getLatestActiveHoldonBysupplierCode/${supplierCode}` +
      "?num=" +
      Math.random();
    return this.http.GetPromise(url);
  }

  public getActiveHold(supplierCode: string) {
    const url = `${this.baseUrl}/holdOn/getLatestActiveHoldonBysupplierCode/${supplierCode}`;
    return this.http.GetPromise(url);
  }
  public getAllHoldOnBySupplierCode(supplierCode: string) {
    const url = `${this.baseUrl}/holdOn/getAllHoldOnBySupplierCode/${supplierCode}`;
    return this.http.GetPromise(url);
  }
}
