// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header .banner {
  padding: 2rem;
}
.header .banner .back_to {
  color: #fff;
  font-weight: 600;
  font-size: 1.25rem;
}
.header .banner .back_to .material-icons {
  font-size: 1.25rem;
  margin-right: 0.75rem;
}
.header .banner .header_text {
  padding: 2.1rem 0 0;
  font-size: 1.75rem;
  color: #fff;
}
.header .banner .header_text .circle {
  width: 5.5rem;
  height: 5.5rem;
  line-height: 5.5rem;
  text-align: center;
  border-radius: 50%;
  border: #FFF 1px solid;
  margin-right: 2rem;
}
.header .banner .header_text .circle .material-icons {
  font-size: 3.5rem;
}
.header .banner .progress .step {
  width: 6rem;
  margin: 0 1rem;
  opacity: 0.6;
  color: #fff;
}
.header .banner .progress .step .percent {
  font-size: 1.25rem;
  text-align: center;
  margin-bottom: 1rem;
}
.header .banner .progress .step .indicator {
  margin: 0 -1rem;
  margin-bottom: 1rem;
  position: relative;
}
.header .banner .progress .step .indicator .line {
  position: absolute;
  top: 0.4rem;
  width: 3.5rem;
  height: 2px;
  background: rgba(255, 255, 255, 0.6);
}
.header .banner .progress .step .indicator .left-line {
  left: 0;
}
.header .banner .progress .step .indicator .right-line {
  right: 0;
}
.header .banner .progress .step .indicator .ring {
  margin: 0 auto;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background: #fff;
  border: #ccc 2px solid;
}
.header .banner .progress .step .circle {
  width: 6.5rem;
  height: 6.5rem;
  text-align: center;
  border-radius: 50%;
  font-weight: bold;
  font-size: 0.8rem;
}
.header .banner .progress .step .circle .material-icons {
  display: block;
  margin: 0.8rem 0 0.2rem;
  font-size: 2.5rem;
}
.header .banner .progress .active {
  opacity: 1;
}
.header .banner .progress .active .indicator .line {
  background: rgba(255, 255, 255, 0.4);
}
.header .banner .progress .active .indicator .ring {
  background: #04a9eb;
  border: #fff 2px solid;
}
.header .banner .progress .active .circle {
  background: #fff;
  color: #4e4e87;
}
.header .banner .menu_items .item {
  margin-right: 5rem;
  text-align: center;
  color: #fff;
  opacity: 0.5;
}
.header .banner .menu_items .item .circle {
  margin: 0 auto;
  width: 5.5rem;
  height: 5.5rem;
  line-height: 5.5rem;
  text-align: center;
  border-radius: 50%;
  border: #FFF 1px solid;
  margin-bottom: 2rem;
}
.header .banner .menu_items .item .circle .dashboard {
  font-size: 3.5rem;
  width: 4rem;
}
.header .banner .menu_items .item .circle .my_account {
  font-size: 3.5rem;
}
.header .banner .menu_items .item .circle .my_suppliers {
  font-size: 3.5rem;
}
.header .banner .menu_items .active {
  opacity: 1;
}

.btnCss {
  cursor: pointer;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
