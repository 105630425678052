import {
  Component,
  OnInit,
  AfterViewInit,
  ElementRef,
  ViewChild,
  HostListener,
} from "@angular/core";
import { BaseComponent } from "src/app/dynamic-components/components/base/base.component";
import { OnBoardingService } from "src/app/modules/buyer/services/onboarding.service";
import { Datasources } from "src/app/dynamic-components/datasource/Datasources";
import { LanguageService } from "src/app/dynamic-components/utils/language.service";

@Component({
  selector: "app-searchable-dropdown",
  templateUrl: "./searchable-dropdown.component.html",
  styleUrls: ["./searchable-dropdown.component.sass"],
  host: {
    "[style.width]": "config.width",
  },
})
export class SearchableDropdownComponent
  extends BaseComponent
  implements OnInit, AfterViewInit
{
  showTooltip: boolean;
  searchText: string;
  selectedItems: any[] = [];
  selectedText = "";
  datalist: any[] = [];
  showPanel = false;
  valueKey = "Code";
  textKey = "Text";
  languageKey = "languageKey";
  rand =Math.round((Math.random()*10000)).toString();
  @ViewChild("inputPanel") inputPanel: ElementRef;
  @ViewChild("dropPpanel") dropPpanel: ElementRef;
  constructor(
    private onBoardingService: OnBoardingService,
    private languageService: LanguageService
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.refresh();
    this.context.on(this.config.name, (str: any) => {
      this.resetAll();
    });
    this.rand =Math.round((Math.random()*10000)).toString();
  }

  ngAfterViewInit() {
    this.subscribeToValueChange(this.config.name, (v) => {
      this.onValueChange(v);
    });
  }

  loadDatasource() {
    Datasources.loadData(this.config, this.context, this.formGroup).then((ds) =>
      this.onDatasourceLoaded(ds)
    );
  }

  onDatasourceLoaded(ds: any) {
    let filteredDataSource = ds.filter(item => {
      const formControl = this.formGroup.get(this.config.name);
      if (!!item.IsDeprecated) {
        if( !!item.Code && formControl.value.includes(item.Code)) {
          formControl.patchValue([]); 
        }
      }
      return !item.IsDeprecated;
    })
    this.datalist = filteredDataSource;
    this.config.options = filteredDataSource;
    this.refresh();
  }

  checkedChanged(event, item: any) {
    this.config.options = this.datalist;
    const target = this.config.options.find((d) => d.Code === item.Code);
    target.checked = !target.checked;
    this.selectedItems = this.config.options.filter((d) => d.checked);
    this.value = this.selectedItems.map((i) => i[this.valueKey]);
    this.refresh();
  }

  chooseItem(event, item: any) {
    this.formGroup.get(this.config.name).markAsDirty();
    this.value = item.Code;
    this.showPanel = false;
    this.refresh();
  }

  refresh() {
    if (!this.config.options || this.config.options.length === 0) {
      return;
    }
    this.resetAll();
    this.setDisableByExclusiveKey();
    this.selectedItems = this.config.options.filter(
      (d) => d.Code === this.value
    );
    if (this.selectedItems[0] && this.selectedItems[0].languageKey) {
      this.selectedText = this.languageService.getValue(
        this.selectedItems[0].languageKey
      );
    } else {
      this.selectedText = this.selectedItems[0].Text;
    }
  }

  resetAll() {
    this.selectedItems = [];
    this.selectedText = "";
    this.config.options.forEach((d) => {
      d.checked = false;
      d.disabled = false;
    });
  }

  setDisableByExclusiveKey() {
    if (
      this.config.templateOptions &&
      this.config.templateOptions.exclusiveKey
    ) {
      const exclusiveKey = this.config.templateOptions.exclusiveKey;
      if (!this.value || this.value.length === 0) {
        this.config.options.forEach((item) => (item.disabled = false));
        return;
      }
      if (this.value.includes(exclusiveKey)) {
        const itemsToDisable = this.config.options.filter(
          (o) => o[this.valueKey] !== exclusiveKey
        );
        itemsToDisable.forEach((itd) => (itd.disabled = true));
      } else {
        const itemsToDisable = this.config.options.filter(
          (o) => o[this.valueKey] === exclusiveKey
        );
        itemsToDisable.forEach((itd) => (itd.disabled = true));
      }
    }
  }

  onValueChange(v) {
    this.refresh();
  }

  togglePanel() {
    this.showPanel = !this.showPanel;
    this.searchText = "";
    this.filter("null");
  }

  filter(keyword: any) {
    if ("null" === keyword) {
      this.searchText = "";
      this.config.options = this.datalist;
      return;
    }
    if (keyword.currentTarget.value) {
      this.searchText = keyword.currentTarget.value;
    }
    if (!this.searchText) {
      this.config.options = this.datalist;
      return;
    }

    // if open multiple language
    if (this.languageService.switcher) {
      this.config.options = this.datalist.filter((kv) => {
        // if the option has language key
        if (kv[this.languageKey]) {
          const translated = this.languageService.getValue(
            kv[this.languageKey]
          );
          // if the option has its translation
          if (translated) {
            return (
              translated
                .toLowerCase()
                .indexOf(keyword.currentTarget.value.toLowerCase()) >= 0
            );
          } else {
            return (
              kv[this.textKey]
                .toLowerCase()
                .indexOf(keyword.currentTarget.value.toLowerCase()) >= 0
            );
          }
        } else {
          return (
            kv[this.textKey]
              .toLowerCase()
              .indexOf(keyword.currentTarget.value.toLowerCase()) >= 0
          );
        }
      });
    } else {
      this.config.options = this.datalist.filter(
        (kv) =>
          kv[this.textKey]
            .toLowerCase()
            .indexOf(keyword.currentTarget.value.toLowerCase()) >= 0
      );
    }
  }

  estimateChooseOver(e, estimateSpendTooltip) {
    const TooltipEle = estimateSpendTooltip;
    if (estimateSpendTooltip) {
      if (
        e.clientX < TooltipEle.nativeElement.getBoundingClientRect().left ||
        e.clientX >
          TooltipEle.nativeElement.getBoundingClientRect().left +
            TooltipEle.nativeElement.offsetWidth -
            5 ||
        e.clientY < TooltipEle.nativeElement.getBoundingClientRect().top ||
        e.clientY >
          TooltipEle.nativeElement.getBoundingClientRect().top +
            TooltipEle.nativeElement.offsetHeight -
            5
      ) {
        this.showTooltip = false;
      }
    } else {
      this.showTooltip = false;
    }
  }

  @HostListener("document:click", ["$event"])
  documentClick(event) {
    if (this.inputPanel && this.dropPpanel) {
      if (
        this.inputPanel.nativeElement.contains(event.target) ||
        this.dropPpanel.nativeElement.contains(event.target)
      ) {
        return;
      }
    }
    this.showPanel = false;
  }
}
