import { Component, OnInit } from "@angular/core";
import { SupplierService } from "src/app/services/supplier.service";
import { RebarAuthService } from "src/app/core/rebarauth/rebar.auth.service";

@Component({
  selector: "app-alert-bar",
  templateUrl: "./alert-bar.component.html",
  styleUrls: ["./alert-bar.component.sass"],
})
export class AlertBarComponent implements OnInit {
  message: string;
  isShownAlert = false;

  constructor(
    private supplierService: SupplierService,
    private rebarService: RebarAuthService
  ) {}

  get isLogin() {
    return this.rebarService.isUserAuthenticated();
  }
  ngOnInit() {
    if (this.isLogin) {
      this.getAlertMessage();
    }
  }

  async getAlertMessage() {
    const data: any = await this.supplierService.queryLastAlertBar();
    if (data && data.data && data.data.AlertMessage && data.data.AlertMessage.trim()) {
      this.isShownAlert = true;
      this.message = data.data.AlertMessage;
    }
  }
}
