import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { DashBoardService } from "../../../services/dashboard.service";
import { ManagementService } from "../../../services/management.service";
import { OnBoardingService } from "../../../services/onboarding.service";
@Component({
  selector: "app-admin-layout",
  templateUrl: "./admin-layout.component.html",
  styleUrls: ["./admin-layout.component.sass"],
})
export class AdminLayoutComponent implements OnInit {
  isFromTSM = false;
  TsmId = "";
  isDisable = false;
  isHideDshbdHelp = false;
  get supplierCode(): string {
    return this.dashBoardService.supplierCode;
  }

  get title(): string {
    return this.managementService.title;
  }

  managementService: ManagementService;

  constructor(
    private dashBoardService: DashBoardService,
    private _managementService: ManagementService,
    private onBoardingService: OnBoardingService
  ) {
    this.managementService = _managementService;
    this.onBoardingService.profileStage = "management";
  }

  ngOnInit() {
    // if enter management page from TSM
    if (this.dashBoardService.isSearchInTsm) {
      this.isFromTSM = true;
      this.TsmId = this.dashBoardService.tsmKey1;
      // hide the help and dashboard icon at nav-side
      this.isHideDshbdHelp = true;
    }
  }

  backTo() {
    this.isDisable = true;
  }

  cancelEvent(event) {
    this.isDisable = false;
  }

  hideCreateBtn() {
    return this.managementService.editMode === true;
  }

  search(evt) {
    // this.managementService.searchCondition = this.searchCondition
  }
}
