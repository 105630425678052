import { Component, OnInit, HostListener } from "@angular/core";
import { privacyStatementState } from "src/app/interfaces/mapping";
import { BuyerService } from "src/app/services/buyer.service";
import { AuthService } from "src/app/services/auth/auth.service";
import { RoleName } from "src/app/interfaces/buyer";
import { Router } from "@angular/router";

@Component({
  selector: "app-layout",
  templateUrl: "./layout.component.html",
  styleUrls: ["./layout.component.sass"],
})
export class LayoutComponent implements OnInit {
  privacyStatement = privacyStatementState;
  constructor(
    private buyerService: BuyerService,
    private auth: AuthService,
    private router: Router
  ) {}

  ngOnInit() {
    this.router.events.subscribe((event) => {
      window.scrollTo(0, 0);
    });
  }

  @HostListener("document: click", ["$event"])
  documentClick(evt) {
    // handle footer click
    if (this.privacyStatement.linkType === "footerLink") {
      this.privacyStatement.privacyStatement = true;
    }
  }

  privacyaccept(flag) {
    if (flag) {
      this.auth.passport.buyer.Status = "accept";
      this.buyerService
        .updateUserAcceptStatus(this.auth.passport.buyer.Email)
        .then((data: any) => {
          if (data.isSuccess) {
            this.privacyStatement.privacyStatement = false;
            if (
              this.auth.passport.buyer.RoleCode === RoleName.Requestor ||
              this.auth.passport.buyer.RoleCode ===
                RoleName.SourcingCategoryManagement ||
              this.auth.passport.buyer.RoleCode === RoleName.BuyerAdmin ||
              this.auth.passport.buyer.RoleCode === RoleName.SystemAdmin
            ) {
              this.privacyStatement.isDisplayAccept = true;
            }
          }
        });
    } else {
      this.privacyStatement.privacyStatement = false;
      this.privacyStatement.linkType = "";
    }
  }

  termclose() {
    this.privacyStatement.termOfUse = false;
  }
}
