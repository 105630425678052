// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.back_position {
  padding-left: 20px;
  padding-bottom: 5px;
}
.back_position a {
  cursor: pointer;
  text-decoration: underline;
}
.back_position a:focus {
  outline: 2px solid black;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
