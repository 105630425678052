import { TranslateService } from "@ngx-translate/core";
import { EventEmitter, Injectable, Output, Directive } from "@angular/core";
import { languageList } from "../../shared/components/layout/language/language-list";
import { Language } from "../../shared/components/layout/language/language.interface";

@Directive()
@Injectable({
  providedIn: "root",
})
export class LanguageService {
  @Output() initEmit: EventEmitter<any> = new EventEmitter();

  public switcher = true;

  constructor(public translateService: TranslateService) { }

  /**
   * Get multiple language with language key
   * @param languageKey
   */
  getValue(languageKey: string) {
    try {
      return this.translateService.instant(languageKey);
    } catch (e) {
      console.log("LanguageKey could not be found. ", languageKey);
    }
  }

  /**
   * Get multiple language with suffix
   * @param item
   */
  getCurrentLanguageValue(item: any) {
    const itemLanguageKey = [
      "label",
      "subLabel",
      "sublabel",
      "tooltip",
      "subLabelA",
      "placeholder"
    ];
    const errorMessageLanguageKey = [
      "required",
      "email",
      "BusinessRegistrationNb",
    ];
    const templateOptionsLanguageKey = [
      "message",
      "errorMessage",
      "label",
      "subLabelName",
    ];
    if (item.languageKey) {
      for (const keyItem in item) {
        if (itemLanguageKey.indexOf(keyItem) > -1 && item[keyItem]) {
          item[keyItem] = this.translateService.instant(
            item.languageKey + `.${keyItem}`
          );
        }
      }
      if (item.errorMessage) {
        for (const keyItem in item.errorMessage) {
          if (
            errorMessageLanguageKey.indexOf(keyItem) > -1 &&
            item.errorMessage[keyItem]
          ) {
            item.errorMessage[keyItem] = this.translateService.instant(
              item.languageKey + `.errorMessage.${keyItem}`
            );
          }
        }
      }
      if (item.templateOptions) {
        for (const keyItem in item.templateOptions) {
          if (
            templateOptionsLanguageKey.indexOf(keyItem) > -1 &&
            item.templateOptions[keyItem]
          ) {
            item.templateOptions[keyItem] = this.translateService.instant(
              item.languageKey + `.templateOptions.${keyItem}`
            );
          }
        }
      }
      // for phone type component
      if (item["fieldset"]) {
        item["fieldset"].forEach((field) =>
          this.getCurrentLanguageValue(field)
        );
      }
    }
    return item;
  }

  /**
   * Get multiple language message with params
   * @param value
   * @param params
   */
  getValueWithParams(value: string, params: any[]) {
    while (value.indexOf("${value}") > 0) {
      value = value.replace("${value}", params[0]);
      params.splice(0, 1);
    }

    return value;
  }

  /**
   * Change language
   * @param language
   */
  setCurrentLanguage(language: string) {
    this.translateService.use(language);
  }

  /**
   * Component value transfer
   * @param value
   */
  setValue(value: any) {
    this.initEmit.emit(value);
  }

  /**
   * based on the search language it will return the searched language object
   * if searched language is not present, then it will return the default language
   * @param lang
   * @returns
   */
  findCurrentLanguage(lang: string): Language {
    let langauge: Language;
    if (!lang) {
      langauge = this.getDefaultLang();
    } else {
      const needle: string = lang.toLowerCase();
      langauge = languageList.find((item) => item.code === needle);
      if (!langauge) {
        langauge = this.getDefaultLang();
      }
    }
    return langauge;
  }

  /**
   * this function returns the default language from languageList
   * @returns
   */
  getDefaultLang(): Language {
    const langauge = languageList.find((item) => item.default === true);
    return langauge;
  }

  /**
   * sets the language in localstorage
   * @param langObj
   */
  setDefaultLang(langObj: Language): void {
    localStorage.setItem("language", langObj.key);
    this.translateService.setDefaultLang(langObj.key);
    this.translateService.use(langObj.key);
  }

}
