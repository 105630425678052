import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-supplier-history-layout",
  templateUrl: "./supplier-history-layout.component.html",
  styleUrls: ["./supplier-history-layout.component.sass"],
})
export class SupplierHistoryLayoutComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
