import { Component, OnInit, Input } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { LanguageService } from "src/app/dynamic-components/utils/language.service";
@Component({
  selector: "app-text-href",
  templateUrl: "./text-href.component.html",
  styleUrls: ["./text-href.component.sass"],
})
export class TextHrefComponent implements OnInit {
  @Input() hrefURL: string;
  @Input() isTargetBlank: boolean;
  @Input() otherWording: string;
  @Input() colorStyle: string;
  @Input() decorationStyle: string;
  @Input() hasSpanLabel = false;
  trustedHtmlSnippet;
  blankValue;
  constructor(
    private sanitizer: DomSanitizer,
    private languageService: LanguageService
  ) {}

  ngOnInit() {
    this.trustedHtmlSnippet = `${this.hrefURL}`;
    this.blankValue = this.isTargetBlank;
    this.otherWording = this.getMultipleLanguageByKey(this.otherWording);
  }
  getMultipleLanguageByKey(languageKey: string) {
    return this.languageService.getValue(languageKey);
  }
}
