// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menuPanel {
  background: #fff;
  position: absolute;
  z-index: 1000;
  font-size: 0.7em;
  right: -29px;
  top: 45px;
  width: 250px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.menuContent {
  color: #333;
  padding-top: 10px;
  text-align: left;
}

.list-group-item {
  display: block;
  font-size: 1rem;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 5px;
  padding-top: 5px;
  border-bottom: 3px #f0f0f0 solid;
  text-align: center;
}

.anchor {
  position: relative;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
