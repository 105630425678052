// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tooltip_img_msg {
  display: inline-block;
}

.tooltip_img {
  width: 15px;
  height: 15px;
  margin-left: 7px;
}

.tooltip_msg {
  margin-top: -18px;
  margin-bottom: 0;
  line-height: 1.25;
}

.tooltip_text {
  margin: 0px 30px;
  font-weight: normal;
}

.uploader-label {
  display: inline-flex;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
