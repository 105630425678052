// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.link-mare-panel {
  position: absolute;
  height: auto;
  width: 350px;
  background: #fff;
  color: #000;
  border-radius: 5px;
  padding: 5px 0;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.12), 0 3px 6px rgba(0, 0, 0, 0.24);
  outline: 10;
  z-index: 9999;
}
.link-mare-panel p {
  line-height: 1.5;
  font-weight: normal;
}

.col-auto {
  margin-left: 5px;
  margin-right: 5px;
  line-height: 2rem;
}

.drop-input {
  width: calc(100% - 24px);
  height: 100%;
  background: #f6f5fa;
  border: 0;
  color: #999;
}

.drop-panel {
  height: 250px;
  border-left: solid 0.9px #ddd;
  border-bottom: solid 0.9px #ddd;
  border-right: solid 0.9px #ddd;
  padding: 0px;
  overflow-y: auto;
  position: absolute;
  background-color: #fff;
  z-index: 9999;
  width: 100px;
}

.search_bg {
  display: inline-flex;
  background: #fff;
  width: 100%;
  border: solid 1px #efefef;
  border-radius: 1.5rem;
  padding-left: 5px;
  padding-top: 3px;
}
.search_bg .material-icons {
  margin-right: 0.5rem;
}

.search-box {
  border: none;
  background: #fff;
  font-size: 14px;
  width: 80%;
}

.search-box .search-container {
  position: absolute;
  top: 100px;
  right: 100px;
}

.icon-drop {
  color: #ddd;
}

.icon-drop-arrow {
  color: #000;
}

label-drop {
  padding-top: 2px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
