// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-btn-icon-only {
  line-height: 0;
}

.page-system-standard-payment-term {
  padding: 30px;
  min-height: 540px;
  background: linear-gradient(to right, #764BA2, #3C73D2);
}
.page-system-standard-payment-term .container {
  padding: 30px 0;
  border-radius: 30px 0;
  background: #fff;
}
.page-system-standard-payment-term .container .header {
  background: #fff;
  color: #4E4E87;
  font-size: 24px;
  font-weight: bold;
}
.page-system-standard-payment-term .container .search-container {
  padding: 0 48px 48px 48px;
}
.page-system-standard-payment-term .container .search-container .action-container {
  text-align: right;
}
.page-system-standard-payment-term .container .search-container .action-container button {
  width: 70px;
  height: 32px;
  margin-left: 10px;
}
.page-system-standard-payment-term .container .table-container {
  padding: 0 48px;
}
.page-system-standard-payment-term .container .table-container .action-container {
  text-align: center;
}
.page-system-standard-payment-term .container .table-container .action-container button {
  margin: 0 5px;
}
.page-system-standard-payment-term .container .padding30 {
  padding: 0 30px !important;
}
.page-system-standard-payment-term .container .dropdownPadding30 {
  padding: 0 30px 48px 30px !important;
}

::ng-deep .cdk-overlay-pane {
  z-index: auto !important;
}

::ng-deep .tac {
  text-align: center !important;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
