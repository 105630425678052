export function isOrganizationEmpty(supOrg: any): boolean {
    const { BusinessRegistrationType, BusinessRegistrationNb, CompanyVATNumber, TypeOfCompany, GSTRegistrationNb } = supOrg;

    if (BusinessRegistrationType || BusinessRegistrationNb || CompanyVATNumber || TypeOfCompany || GSTRegistrationNb) {
        return false;
    } else {
        return true;
    }
}

