import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
@Component({
  selector: 'app-global-popup',
  templateUrl: './global-popup.component.html',
  styleUrls: ['./global-popup.component.sass']
})
export class GlobalPopupComponent {
  constructor(public dialogRef: MatDialogRef<GlobalPopupComponent>,private router: Router) {}
  
  onCancelClick(): void {
    this.dialogRef.close('onCancelClick');
  }
  onConfirmClick(): void {
    this.dialogRef.close('onConfirmClick');
  }
}
