import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class DataService {
    private messageSource = new BehaviorSubject('default message');
    private messageSourceArray = new BehaviorSubject('Array message');

    currentMessage = this.messageSource.asObservable();
    currentMessageArray = this.messageSourceArray.asObservable();
  
  
    constructor() { }
  
    changeMessage(message: string) {
      this.messageSource.next(message);
    }
    changeMessageArray(message: string[]) {
      this.messageSourceArray.next(message.toString());
    }
  
}