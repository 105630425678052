import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { CommonService } from "src/app/services/common.service";
import { EditHold } from "src/app/interfaces/buyer";
import { RemoveHoldComponent } from "./remove-hold/remove-hold.component";
import { ExtendHoldComponent } from "./extend-hold/extend-hold.component";
import {
  DialogService,
  DialogType,
  ButtonItem,
} from "src/app/services/dialog.service";
import { resolve } from "q";
import { LoadingService } from "src/app/services/loading.service";
import { DictionaryService } from "src/app/services/dictionary.service";

@Component({
  selector: "app-edit-hold",
  templateUrl: "./edit-hold.component.html",
  styleUrls: ["./edit-hold.component.sass"],
})
export class EditHoldComponent implements OnInit {
  @Input() isShowDialog: boolean;
  @Output() hiddenDialog: EventEmitter<boolean>;
  @Output() checkResult: EventEmitter<boolean>;
  @ViewChild("childComponentRemoveHold")
  childComponentRemoveHold: RemoveHoldComponent;
  @ViewChild("childComponentExtendHold")
  childComponentExtendHold: ExtendHoldComponent;
  @ViewChild("acceptScroll") acceptScroll: ElementRef;
  editHoldItems: any = [];
  isValidated: boolean;
  currentSelect = "";
  editHoldItemsKey: any;
  result: any;
  OK: ButtonItem = {
    label: "OK",
    action: () => {
      resolve("ok");
    },
  };
  Close: ButtonItem = {
    label: "Close",
    action: () => {
      resolve("close");
    },
  };
  constructor(
    private dictionaryService: DictionaryService,
    private dialogService: DialogService
  ) {
    this.hiddenDialog = new EventEmitter();
    this.checkResult = new EventEmitter();
  }

  async ngOnInit() {
    const dicts = await this.dictionaryService.getDictionary("Edit Hold");
    if (dicts) {
      this.editHoldItems = dicts;
    }
    this.editHoldItemsKey = EditHold;
  }

  validate(): boolean {
    this.isValidated = true;
    if (this.currentSelect === "") {
      return false;
    }
    return true;
  }

  closeDialog() {
    this.isValidated = false;
    this.isShowDialog = false;
    this.hiddenDialog.emit(this.isShowDialog);
    this.currentSelect = "";
  }

  async submit() {
    let isSuccess = false;
    let removeIsSuccess = false;
    let isValidate = true;
    if (!this.validate()) {
      return;
    }
    switch (this.currentSelect) {
      case EditHold.Remove:
        ({ isValidate, removeIsSuccess } =
          await this.childComponentRemoveHold.submit());
        break;
      case EditHold.Extend:
        ({ isValidate, isSuccess } =
          await this.childComponentExtendHold.submit());
        break;
    }
    if (!isValidate) {
      return;
    }
    this.checkResult.emit(removeIsSuccess);
    if (isSuccess || removeIsSuccess) {
      this.closeDialog();
      this.dialogService.confirm(
        DialogType.success,
        "The hold request has been submitted successfully.",
        [this.OK],
        "Confirmed"
      );
    } else {
      this.closeDialog();
      this.dialogService.confirm(
        DialogType.warning,
        "Submit failed,please try again later.",
        [this.Close],
        "Failed"
      );
    }
    // location.reload()
  }
}
