import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-dashboard-search-bar",
  templateUrl: "./dashboard-search-bar.component.html",
  styleUrls: ["./dashboard-search-bar.component.sass"],
})
export class DashboardSearchBarComponent implements OnInit {
  isDisable = false;
  constructor(private router: Router) {}

  ngOnInit() {}

  nav() {
    this.router.navigate(["system/users"], {replaceUrl: true});
  }
  backTo() {
    window.location.href = "https://cn.bing.com/";
  }
  // cancelEvent(event) {
  //   this.isDisable = false
  // }
}
