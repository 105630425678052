import { SupplierModel } from "./supplierModel";

export interface BuyerChangeRequestDTO {
  supplierCode?: string;
  supplierModel?: SupplierModel;
  crId?: string; // id for the change request
  status?: string;
  requestType?: string; // which column will be modified, used as type
  requestValue?: string; // modify column value, json format
  comments?: string;
  createdOn?: string;
  createdBy?: string;
  audit?: Array<AuditChangeRequestDetail>;
  openFlag?: boolean;
}

export interface AuditChangeRequestDetail {
  addCompanyCodeList?: Array<AddCompanyCodeDetailsList>;
  addPaymentTermsList?: Array<PaymentTermsItem>;
  addAmendSpendCommodityCategoryGroup?: Array<AmendSpendCommodityCategoryGroup>;
  RiskAssessmentInfoCRData?: Array<RiskAssessmentInfoCRData>;
  status?: string;
  comments?: string;
  actionTaken?: string;
  createdOn?: string;
  createdBy?: string;
  questionObject?: any;
  isChangeRACR?: any;
  uploadFile?: any;
}

export class PaymentTermsItem {
  PaymentTermId?: string;
  TimeLimit?: string;
  TermPayment?: any;
  Country?: string;
  CompanyCode?: string;
  HasNonStandardPaymentTerms?: any;
  AgreementAttachments?: any;
  ApproveAttachments?: any;
  ProcurementApproval?: any;
}

export interface AddCompanyCodeDetailsList {
  geographicalUnit?: string;
  country?: string;
  companyCode?: string;
  sapID?: string;
}

export enum ChangeRequestStatus {
  // new cr/ qc reject
  PENDING_APSST_REVIEW = "Pending AP SST Review",
  // sst approved
  PENDING_APQC_REVIEW = "Pending AP QC Review",
  // qc approve,trigger GDPR
  PENDING_GDPR = "Pending GDPR Risk Assessment",
  // sst/qc closed, qc approved
  COMPLETED = "Completed",
}

export enum ChangeRequestDetailStatus {
  REQUESTOR_CHANGE_REQUEST = "Requestor Change Request",
  APPROVED_BY_AP_SST = "Approved By AP SST",
  APPROVED_BY_AP_QC = "Approved By AP QC",
  CLOSED_BY_AP_SST = "Closed By AP SST",
  CLOSED_BY_AP_QC = "Closed By AP QC",
  REJECT_BY_AP_QC = "Rejected By AP QC",
}

export enum ChangeRequestType {
  ADD_COMPANY_CODE = "Add Company Code",
  UPDATE_PAYMENT_TERMS = "Update Payment Terms",
  UPDATE_AmendSpendCommodityCategoryGroup = "Update Spend Category Group",
}

export function initChangeRequestMapping(supplierCode) {
  const requestMapping: BuyerChangeRequestDTO = {
    crId: Math.round(new Date().getTime()).toString(),
    supplierCode: supplierCode,
    status: ChangeRequestStatus.PENDING_APSST_REVIEW,
    requestType: "", // which column will be modified, used as type
    requestValue: "", // modify column value, json format
    comments: "",
    createdOn: "",
    createdBy: "",
    audit: [],
    openFlag: true,
  };
  return requestMapping;
}

export function initAuditChangeRequestDetail() {
  const auditChangeDetail: AuditChangeRequestDetail = {
    addCompanyCodeList: [],
    addPaymentTermsList: [],
    addAmendSpendCommodityCategoryGroup: [],
    RiskAssessmentInfoCRData: [],
    status: ChangeRequestDetailStatus.REQUESTOR_CHANGE_REQUEST,
    comments: "",
    actionTaken: "",
    createdOn: "",
    createdBy: "",
    questionObject: {},
    isChangeRACR: "",
    uploadFile: [],
  };
  return auditChangeDetail;
}

export function initMultiplePaymentTermsCRItem() {
  const item: PaymentTermsItem = {
    TimeLimit: "",
    CompanyCode: "",
    Country: "",
    TermPayment: {
      oldValue: null,
      newValue: null,
    },
    HasNonStandardPaymentTerms: {
      oldValue: null,
      newValue: null,
    },
    AgreementAttachments: {
      oldValue: [],
      newValue: [],
    },
    ApproveAttachments: {
      oldValue: [],
      newValue: [],
    },
    ProcurementApproval: {
      oldValue: [],
      newValue: [],
    },
  };
  return item;
}

export function initDetailForAddCompanyCode() {
  const auditChangeDetail: AuditChangeRequestDetail = {
    addCompanyCodeList: [],
    status: "",
    comments: "",
    actionTaken: "",
    createdOn: "",
    createdBy: "",
  };
  return auditChangeDetail;
}

export function convertToChangeRequest(data: BuyerChangeRequestDTO) {
  const requestMapping: BuyerChangeRequestDTO = {
    crId: data.crId,
    supplierCode: data.supplierCode,
    status: data.status,
    requestType: data.requestType,
    requestValue: data.requestValue,
    comments: data.comments,
    createdOn: data.createdOn,
    createdBy: data.createdBy,
    audit: data.audit,
    openFlag: data.openFlag,
  };
  return requestMapping;
}

export class AmendSpendCommodityCategoryGroup {
  SpendCommodityGroup?: any;
  SpendCategoryGroup?: any;
  IndependentContractor?: any;
}

export class RiskAssessmentInfoCRData {
  BI_BDA_1?: any;
  BI_BDA_1a?: any;
  BI_BDA_1bEndDate?: any;
  BI_BDA_1bStartDate?: any;
  BI_BDA_1c?: any;
  BI_BDA_2?: any;
  BI_BDA_1b?: any;
  InformationSecurity?: any;
  InformationSecurity_1?: any;
  InformationSecurity_1Yes?: any;
  InformationSecurity_1a_new?: any;
  InformationSecurity_1a_no?: any;
  InformationSecurity_1b_new?: any;
  InformationSecurity_1c_1?: any;
  InformationSecurity_1c_2?: any;
  InformationSecurity_1c_2_other?: any;
  InformationSecurity_1c_new?: any;
  InformationSecurity_1ca_2?: any;
  InformationSecurity_1ca_2_other?: any;
  InformationSecurity_1cb_new?: any;
  InformationSecurity_1cb_other?: any;
  InformationSecurity_1cd_2?: any;
  InformationSecurity_1cd_3?: any;
  InformationSecurity_1cd_3_1?: any;
  InformationSecurity_1cd_4?: any;
  InformationSecurity_1cd_4_NO?: any;
  InformationSecurity_1cd_4_YES?: any;
  InformationSecurity_1cd_5?: any;
  InformationSecurity_1cd_5_other?: any;
  InformationSecurity_1cd_new?: any;
  InformationSecurity_1ce_1?: any;
  InformationSecurity_1cf_new?: any;
  InformationSecurity_1cg_new?: any;
  InformationSecurity_1d_1?: any;
  InformationSecurity_1eYes?: any;
  InformationSecurity_1e_new?: any;
  InformationSecurity_1f_1?: any;
  InformationSecurity_1g_1?: any;
  InformationSecurity_1h_1?: any;
  InformationSecurity_1i?: any;
  InformationSecurity_1j?: any;
  InformationSecurity_2?: any;
  InformationSecurity_2a?: any;
  InformationSecurity_3?: any;
  InformationSecurity_3a?: any;
  InformationSecurity_4?: any;
  InformationSecurity_4a?: any;
  InformationSecurity_5?: any;
  InformationSecurity_6?: any;
  InformationSecurity_12?: any;
  InformationSecurity_1a?: any;
  InformationSecurity_1b?: any;
  InformationSecurity_1c?: any;
  InformationSecurity_1ca_Others?: any;
  InformationSecurity_1ca?: any;
  InformationSecurity_1cb?: any;
  InformationSecurity_1cc?: any;
  InformationSecurity_1cd?: any;
  InformationSecurity_1cd_1?: any;
  InformationSecurity_1ce?: any;
  InformationSecurity_1cf?: any;
  InformationSecurity_1cg?: any;
  InformationSecurity_1d?: any;
  InformationSecurity_1e?: any;
  InformationSecurity_1f?: any;
  InformationSecurity_1g?: any;
  InformationSecurity_1ga?: any;
  InformationSecurity_1gb?: any;
  InformationSecurity_1h?: any;
}
