import { Component, OnInit } from "@angular/core";
import { BaseComponent } from "src/app/dynamic-components/components/base/base.component";

@Component({
  selector: "app-textbox",
  templateUrl: "./textbox.component.html",
  host: {
    "[style.width]": "config.width",
  },
  styleUrls: ["./textbox.component.sass"],
})
export class TextboxComponent extends BaseComponent implements OnInit {
  showTooltip: boolean;
  rand =Math.round((Math.random()*10000)).toString();

  constructor() {
    super();
  }

  estimateChooseOver(e, estimateSpendTooltip) {
    const TooltipEle = estimateSpendTooltip;
    if (estimateSpendTooltip) {
      if (
        e.clientX < TooltipEle.nativeElement.getBoundingClientRect().left ||
        e.clientX >
          TooltipEle.nativeElement.getBoundingClientRect().left +
            TooltipEle.nativeElement.offsetWidth -
            5 ||
        e.clientY < TooltipEle.nativeElement.getBoundingClientRect().top ||
        e.clientY >
          TooltipEle.nativeElement.getBoundingClientRect().top +
            TooltipEle.nativeElement.offsetHeight -
            5
      ) {
        this.showTooltip = false;
      }
    } else {
      this.showTooltip = false;
    }
  }
}
