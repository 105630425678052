import { Pipe, PipeTransform } from "@angular/core";
import * as moment from "moment";

@Pipe({
  name: "sapResult",
})
export class SapResult implements PipeTransform {
  constructor() {}
  transform(createdOn: string): string {
    if (createdOn) {
      if (moment(new Date(createdOn.substring(3))).isValid()) {
        const utcDate = new Date(createdOn.substring(3)).toUTCString();
        return utcDate;
      } else {
        // IE change
        return this.newDate(createdOn.substring(4)).toUTCString();
      }
    }
    return "";
  }

  newDate(str: any) {
    const day = str.split(" ");
    const days = day[0].split("-");
    const mi = day[day.length - 1].split(":");
    const date = new Date();
    date.setUTCFullYear(days[2], this.currentMonth(days[1]), days[0]);
    const utcString = -(new Date().getTimezoneOffset() / 60);
    date.setUTCHours(mi[0] - utcString, mi[1], mi[2]);
    return date;
  }

  currentMonth(month: any) {
    let realMonth: number;
    switch (month) {
      case "Jan":
        realMonth = 0;
        break;
      case "Feb":
        realMonth = 1;
        break;
      case "Mar":
        realMonth = 2;
        break;
      case "Ari":
        realMonth = 3;
        break;
      case "May":
        realMonth = 4;
        break;
      case "Jun":
        realMonth = 5;
        break;
      case "Jul":
        realMonth = 6;
        break;
      case "Aug":
        realMonth = 7;
        break;
      case "Sep":
        realMonth = 8;
        break;
      case "Oct":
        realMonth = 9;
        break;
      case "Nov":
        realMonth = 10;
        break;
      case "Dec":
        realMonth = 11;
        break;
    }
    return realMonth;
  }
}
