import { Pipe, PipeTransform } from "@angular/core";
import { LanguageService } from "../../dynamic-components/utils/language.service";

@Pipe({ name: "translateWithStyle" })
export class TranslateWithStylePipe implements PipeTransform {
  constructor(private languageService: LanguageService) {}

  /**
   * Bold the part of the parameter in the obtained language value
   * @param languageKey To get language value
   * @param param Parameters to be bold
   */
  transform(languageKey) {
    const value = this.languageService.getValue(languageKey);
    const p = document.getElementById(languageKey);
    p.innerHTML = value;
  }
}
