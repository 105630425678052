import { Injectable } from "@angular/core";
import { SupplierSetupSteps } from "src/app/interfaces/process";
import {
  ProcessNames,
  HistoryTask,
  HistoryProcessInstance,
  WorkflowEndType,
  SetupProcess,
} from "src/app/interfaces/workflow";
import {
  RiskAssessmentStatus,
  SupplierStatus,
} from "src/app/interfaces/mapping";
import * as moment from "moment";
import { WorkflowService } from "src/app/services/workflow.service";
import { ProfileService } from "./profile.service";
@Injectable({
  providedIn: "root",
})
export class SupplierSetupProcessService {
  constructor(
    private profileService: ProfileService,
    private workflowService: WorkflowService
  ) {}
  get supplierSetupTaskHistory(): Array<any> {
    return this.profileService.supplierModel.Mapping.historyTasks.filter(
      (task) =>
        task.processDefName === ProcessNames.generic_supplierSetup ||
        task.processDefName === ProcessNames.generic_supplierSetupSubProcess
    );
  }
  circleSteps = [];
  currTitleStep: any;
  private supplierSetupSteps = SupplierSetupSteps;
  private processHistories: HistoryProcessInstance[];
  // load supplier setup process
  async loadSupplierSetupProcess() {
    // update task steps
    let apSubProcessId: string;
    this.supplierSetupTaskHistory.map((item: HistoryTask) => {
      if (item.name === SetupProcess.SST_review) {
        apSubProcessId = item.processInstId;
      }
      return item;
    });
    this.initSupplierSetup_APTasks();
    await this.initSupplierSetup_EndSteps();
    this.supplierSetupTaskHistory.forEach((item: HistoryTask) => {
      // ap task handled separately
      if (apSubProcessId && item.processInstId === apSubProcessId) {
        return;
      }
      this.updateSupplierSetup_TaskSteps(item);
    });
    this.supplierSetupSteps_Handling();
  }

  supplierSetupSteps_Handling() {
    const tasks = Object.values(this.supplierSetupSteps).filter(
      (item) =>
        item.status !== RiskAssessmentStatus.IS &&
        item.status !== SetupProcess.GDPR &&
        item.status !== SetupProcess.BI &&
        item.status !== SetupProcess.QC_review &&
        item.status !== SetupProcess.SST_review
    );

    // unfinished task
    const unfinishedTasks = tasks.filter((item) => !item.done);

    // complete task
    let completedTasks = tasks.filter((item) => item.done);
    completedTasks = completedTasks.sort(
      (first, second) =>
        moment(first.step.UpdateAt).unix() - moment(second.step.UpdateAt).unix()
    );

    // all pending step array
    let allSteps;
    if (
      this.profileService.supplierModel.Mapping.status ===
      SupplierStatus.terminated
    ) {
      allSteps = completedTasks;
    } else {
      allSteps = [...completedTasks, ...unfinishedTasks];
    }
    // all step circles
    this.circleSteps = allSteps;
    //  current step
    this.currTitleStep = Object.assign([], this.circleSteps)
      .reverse()
      .find((item) => !!item && item.done);
    this.circleSteps = this.circleSteps.filter(((item) => !!item && item.status !== SetupProcess.profile_resubmit));
}

  // update general task steps
  private updateSupplierSetup_TaskSteps(task: HistoryTask) {
    const tmpItem: any = {
      UpdateAt: task.endTime,
      status: task.name,
    };
    const step = this.supplierSetupSteps[task.name];
    if (step) {
      step.done = true;
      step.step = tmpItem;
    }
  }

  // init terminate and on boarded steps
  private async initSupplierSetup_EndSteps() {
    await this.loadProcessHistories();
    if (this.processHistories && this.processHistories.length > 0) {
      const setupProcess = this.processHistories.find(
        (process: HistoryProcessInstance) =>
          process.name === ProcessNames.generic_supplierSetup
      );
      if (!setupProcess) {
        return;
      }
      if (
        setupProcess.endType &&
        setupProcess.endType === WorkflowEndType.terminateEnd
      ) {
        this.updateSupplierSetup_TerminatedSteps(setupProcess);
      } else {
        this.updateSupplierSetup_CompletedSteps(setupProcess);
      }
    }
  }

  // init ap subProcess task steps
  private initSupplierSetup_APTasks() {
    const qcReviewComplete: boolean = this.profileService.checkAPTaskIsComplete(
      ProcessNames.generic_supplierSetupSubProcess,
      SetupProcess.QC_review
    );
    if (qcReviewComplete) {
      const qcTasks = this.supplierSetupTaskHistory
        .filter((item: HistoryTask) => SetupProcess.QC_review === item.name)
        .sort(
          (first, second) =>
            moment(first.endTime).unix() - moment(second.endTime).unix()
        );
      const lastQCTask = qcTasks[qcTasks.length - 1];
      this.updateSupplierSetup_SAPTaskSteps(lastQCTask);
    }
  }

  // update complete steps
  private updateSupplierSetup_CompletedSteps(process: HistoryProcessInstance) {
    const tmpItem: any = {
      UpdateAt: process.endTime,
      status: SupplierStatus.onboarded,
    };
    const step = this.supplierSetupSteps[SupplierStatus.onboarded];
    if (step) {
      step.done = true;
      step.step = tmpItem;
    }
  }

  // update complete steps
  private updateSupplierSetup_TerminatedSteps(process: HistoryProcessInstance) {
    const tmpItem: any = {
      UpdateAt: process.endTime,
      status: SupplierStatus.terminated,
    };
    const step = this.supplierSetupSteps[SupplierStatus.terminated];
    if (step) {
      step.done = true;
      step.show = true;
      step.step = tmpItem;
    }
    // requestor form terminate: not show requestor draft step
    if (this.supplierSetupTaskHistory.length === 1) {
      const requestorDraftStep =
        this.supplierSetupSteps[SetupProcess.requestor_draft];
      requestorDraftStep.show = false;
    }
  }

  // update ap task steps
  private updateSupplierSetup_SAPTaskSteps(task: HistoryTask) {
    const tmpItem: any = {
      UpdateAt: task.endTime,
      status: RiskAssessmentStatus.SAP,
    };
    const step = this.supplierSetupSteps[RiskAssessmentStatus.SAP];
    if (step) {
      step.done = true;
      step.step = tmpItem;
    }
  }

  // load process histories and update steps
  private async loadProcessHistories() {
    const data = await this.workflowService.getProcessHistoriesByBusinessKey(
      this.profileService.supplierModel.Mapping.supplierCode
    );
    if (data.isSuccess) {
      this.processHistories = data.data;
    }
  }

  clear() {
    Object.values(this.supplierSetupSteps).map((item) => {
      item.done = false;
      item.reject = false;
      item.terminate = false;
      if (item.status === SupplierStatus.terminated) {
        item.terminate = true;
      } else if (item.status === SupplierStatus.onboarded) {
        item.title = "Completed";
        item.body = "Supplier has been onboarded";
        item.intro = "Supplier has been onboarded";
        item.titleLanguageKey =
          "static.process.SupplierSetupSteps.SupplierStatus.onboarded.title";
        item.introLanguageKey =
          "static.process.SupplierSetupSteps.SupplierStatus.onboarded.intro";
        item.bodyLanguageKey =
          "static.process.SupplierSetupSteps.SupplierStatus.onboarded.body";
      }
    });
  }
}
