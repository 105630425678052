import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { ManagementService } from "../../../services/management.service";
import { DashBoardService } from "../../../../buyer/services/dashboard.service";
import { AuthService } from "src/app/services/auth/auth.service";
import {
  ButtonCommands,
  DialogType,
  DialogService,
} from "src/app/services/dialog.service";
import { OnBoardingService } from "../../../services/onboarding.service";

@Component({
  selector: "app-admin-layout",
  templateUrl: "./admin-layout.component.html",
  styleUrls: ["./admin-layout.component.sass"],
})
export class SupplierAdminLayoutComponent implements OnInit {
  isDisable = false;
  isFromTSM = false;
  TsmId = "";
  supplierName: string;
  managementService: ManagementService;

  get supplierCode(): string {
    return this.route.firstChild.snapshot.paramMap.get("supplierCode");
  }

  get title(): string {
    return this.managementService.title;
  }

  get isMigrated(): boolean {
    return false;
  }
  constructor(
    private router: Router,
    private auth: AuthService,
    private _managementService: ManagementService,
    private dialogService: DialogService,
    private route: ActivatedRoute,
    private dashBoardService: DashBoardService,
    private onBoardingService: OnBoardingService
  ) {
    this.managementService = _managementService;
    this.onBoardingService.profileStage = "supplierManagement";
  }

  ngOnInit() {
    this.isFromTSM = this.onBoardingService.isFromTsm();
    this.TsmId = this.onBoardingService.TsmId();
  }
  isSupplierNameDuplicate(): boolean {
    this.supplierName =
      this.onBoardingService.supplierModel.Mapping.searchTerm.supplierName;
    return this.supplierName.startsWith("DUPLICATE-");
  }

  backTo() {
    // this.router.navigate(['supplier/landing'])
    this.isDisable = true;
  }

  cancelEvent(event) {
    this.isDisable = false;
  }

  hideCreateBtn() {
    return this.managementService.editMode === true;
  }
  isTwentyActiveUser() {
    if (
      this.managementService.users.filter((item) => item.Enabled === true)
        .length >= 20
    ) {
      this.dialogService.dialog(
        `You have reached the limit of 20 active users. To create a new user, please deactivate an active user first.<br>
      <br>How do I deactivate a user?  <br> <br>
      1) Click '<span style="font-weight: 600;">...</span>' against the user you want to deactivate;<br>
      2) Click '<span style="font-weight: 600;">Edit</span>';<br>
      3) Untick '<span style="font-weight: 600;">Active</span>';<br>
      4) Save`,
        ButtonCommands.Close,
        DialogType.alert,
        "Please Note"
      );
    } else {
      this.router.navigate([
        `/buyer/supplierManagement/user-create/${this.route.firstChild.snapshot.paramMap.get(
          "supplierCode"
        )}`,
      ]);
    }
  }
}
