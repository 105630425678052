import { Component, Input } from "@angular/core";
@Component({
  selector: "app-special-error-msg",
  templateUrl: "./special-error-msg.component.html",
  styleUrls: ["./special-error-msg.component.sass"],
})
export class SpecialErrorMsgComponent {
  @Input() message: string;
  @Input() isWarning = false;
}
