import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";
import { AppConfigService } from "./app/core/services/app-config.service";

if (environment.production) {
  enableProdMode();
}
console.log(".... inside main.ts 1");

/**
 * Bypassing console message except Dev and Test environment
 */
const enableconsole: string[] = ["dev", "test", "stage", "local"];
if (enableconsole.indexOf(environment.env) === -1) {
  window.console.log = () => {};
}

function bootstrapApp(): void {
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.log(err));
}

document.addEventListener("DOMContentLoaded", () => {
  // We bootstraping app from browser, we need fetch config.json.
  fetch(AppConfigService.configPath)
    .then((response) => {
      return response.json();
    })
    .then((jsonData) => {
      sessionStorage[AppConfigService.configPath] = JSON.stringify(jsonData);
      bootstrapApp();
    })
    .catch((err) => {
      console.warn("caught when bootstrapping app");
      console.error(err);
    });
});
