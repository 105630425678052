import { Component, OnInit } from "@angular/core";
import { BaseComponent } from "../base/base.component";

@Component({
  selector: "app-blank",
  template: "",
})
export class BlankComponent extends BaseComponent implements OnInit {
  constructor() {
    super();
  }
}
