import { SupplierUser } from "./../../../../../interfaces/supplier";
import { SupplierService } from "src/app/services/supplier.service";
import { BuyerService } from "src/app/services/buyer.service";
import { CommonService } from "src/app/services/common.service";
import {
  OnBoardingRequestInfoType,
  DeclarationDS,
  OnBoardMessage,
} from "./../../../../../interfaces/buyer";
import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import {
  DialogService,
  ButtonCommands,
  DialogType,
  ButtonCommand,
} from "src/app/services/dialog.service";
import { OnBoardingService } from "../../../services/onboarding.service";
import { DeclarationInfo } from "src/app/interfaces/requestForm";
import { RiskAssessmentInfo } from "src/app/interfaces/requestForm";
import { trim } from "src/app/utils";
import {
  SupplierPendingStatus,
  StatusType,
  AP_ReviewStatus,
  SupplierStatus,
  LoadingUrlMapping,
} from "src/app/interfaces/mapping";
import VMController from "src/app/interfaces/vm";
import { CanComponentDeactivate } from "src/app/can-deactivate.guard";
import { LoadingService } from "src/app/services/loading.service";
import { environment } from "src/environments/environment";
import {
  SetupProcess,
  ProcessNames,
  Task,
  AllTaskKey,
} from "src/app/interfaces/workflow";
import { showGlobalPrepopulateData } from "../../../../../../config/config";
import { SupplierModel } from "src/app/interfaces/supplierModel";
import { MetadataService } from "src/app/services/metadata.service";
import { DynamicContext } from "src/app/dynamic-components/interfaces/DynamicContext";
import { UntypedFormGroup } from "@angular/forms";
import { ComponentConfig } from "src/app/dynamic-components/ComponentConfig";
import { DynamicFormComponent } from "src/app/dynamic-components/components/dynamic-form/dynamic-form.component";
import { DictionaryService } from "src/app/services/dictionary.service";
import { AuthService } from "src/app/services/auth/auth.service";
import { BuyerCommonService } from "src/app/modules/buyer/services/buyerCommonService";
import { MatDialog } from '@angular/material/dialog';
import { GlobalPopupComponent } from "src/app/global-popup/global-popup.component";
import { SupplierModelForSaveDraft, createSupplierModelForSaveDraft } from "src/app/interfaces/supplierModelForSaveDraft";
import { LaunchDarklyService } from "src/app/services/launch-darkly.service";

@Component({
  selector: "app-onboarding-declaration",
  templateUrl: "./declaration.component.html",
  styleUrls: ["./declaration.component.sass"],
})
export class OnBoardingDeclarationComponent
  implements OnInit, CanComponentDeactivate
{
  getConfigsInfo: ComponentConfig[] = [];
  @ViewChild("dynamicForm") dynamicForm: DynamicFormComponent;
  // declarationForm: DeclarationInfo = {}
  declarationDS: DeclarationDS = {};
  onBoardingInfoType = OnBoardingRequestInfoType;
  AP_ReviewStatus = AP_ReviewStatus;
  SupplierPendingStatus = SupplierPendingStatus;
  supplierAdmin: SupplierUser = {};
  isValidated: boolean;
  supplierName : string;  
  isNew = true;
  isResubmitDisabled = true;
  isShownAllQuestion = false;
  globalAnwsers = [];
  globalNameElement:any;
  dialogRef: any;
  getglobalVal: any;
  isNewVersion:boolean=true;
  supplierModelForSaveDraft:SupplierModelForSaveDraft=createSupplierModelForSaveDraft();
  // detailStatus: string   // old status verify
  // apReviewStatus: string   // old status verify
  env = environment.env;
  get declarationForm(): DeclarationInfo {
    return this.onBoardingService.supplierModel.RequestForm.DeclarationInfo;
  }
  get riskAssessmentInfo(): RiskAssessmentInfo {
    return this.onBoardingService.supplierModel.RequestForm.RiskAssessmentInfo;
  }
  get supplierCode(): string {
    return this.onBoardingService.supplierCode;
  }

  get isTerminate(): boolean {
    return this.onBoardingService.checkIsTerminate();
  }

  set declarationForm(val) {
    this.onBoardingService.supplierModel.RequestForm.DeclarationInfo = val
  }

  vm: VMController<DeclarationInfo> = new VMController();
  formGroup: UntypedFormGroup;
  context: DynamicContext;
  constructor(
    private router: Router,
    private onBoardingService: OnBoardingService,
    private commonService: CommonService,
    private dialogService: DialogService,
    private supplierService: SupplierService,
    private buyerService: BuyerService,
    private loadingService: LoadingService,
    private dictionaryService: DictionaryService,
    private authService: AuthService,
    private metadataService: MetadataService,
    private buyerCommonService: BuyerCommonService,
    private dialog: MatDialog,
     private launchDarklyService:LaunchDarklyService
  ) {
    this.onBoardingService.stepChangeEvent.emit(4);

     this.launchDarklyService.ldChange.subscribe(any => {
          console.log("Trigger refresh data")
          this.refreshToggle()
      })
  }

  refreshToggle():void{
    this.isNewVersion=this.launchDarklyService.getToggle(LaunchDarklyService.LD_ISNEWVERSION)==="true";
  }

  async ngOnInit() {
    this.refreshToggle();
    this.loadingService.showLoading();
    this.onBoardingService.current_step = 3;
    // workflow load current login user's tasks
    await this.onBoardingService.loadUserTasks();
    this.getConfigsInfo = await this.metadataService.getPageConfiguration(
      "buyer-requestform-step4"
    );
    this.context = new DynamicContext();
    await this.buyerCommonService.SetContextValue(this.context);
    this.context.set(
      "BI_BDA_1",
      this.onBoardingService.supplierModel.RequestForm.RiskAssessmentInfo
        .BI_BDA_1
    );
    this.dynamicForm.subscribeToModelChange("Declaration_5", async (x) => {
      await this.checkAnswerValue("Declaration_5");
    });

    this.dynamicForm.subscribeToModelChange("Declaration_6", async (x) => {
      await this.checkAnswerValue("Declaration_6");
    });
    this.isValidated = false;
    // this.commonService.getDictionary('Declaration').then(data => {
    //   if (data) {
    //     data.data.map(t => {
    //       this.declarationDS[t.Code] = t.Items
    //     })
    //   }
    // })

    this.commonService.ExitEvent.subscribe((e) => {
      this.vm.setOriginal(this.dynamicForm.originalValues());
      this.vm.setCurrent(this.dynamicForm.values());
      if (this.vm.isDirty()) {
        e.preventDefault();
        // Chrome requires returnValue to be set
        e.returnValue = "";
      }
    });
    this.dynamicForm.subscribeToFormChange(() => {
      setTimeout(() => {
        this.isResubmitDisabled =
          JSON.stringify(this.dynamicForm.originalValues()) ===
          JSON.stringify(this.dynamicForm.values());
      });
    });
    this.loadingService.hideLoading();
    // this.detailStatus = this.onBoardingService.supplierModel.Mapping.detailStatus
    // this.apReviewStatus = this.onBoardingService.supplierModel.Mapping.AP_reviewStatus
    // console.log('MODE', this.onBoardingService.mode)
    if ((this.onBoardingService.mode == 'edit' || this.onBoardingService.mode == 'create') && showGlobalPrepopulateData ){
        this.formAutofill()
    }
  }

  async formAutofill(){
    // console.log("formAutofill - supplierCode", this.supplierCode);
    // console.log("formAutofill - supplierTsmId",this.onBoardingService.supplierModel.Mapping.TsmId);
    let payload = {
      supplierCode: this.supplierCode,
            SupplierCompanyName: this.onBoardingService.supplierModel.RequestForm.RequestorDetailsInfo.SupplierCompanyName,
      supplierTsmId: this.onBoardingService.supplierModel.Mapping.TsmId,
      countryServed: this.onBoardingService.supplierModel.RequestForm.RequestorDetailsInfo.SupplierCountry,
      RoleCode: this.authService.passport.buyer.RoleCode
    }
    // console.log('formAutofill Global payload', payload);
    if(payload.supplierCode && payload.supplierTsmId){
      
      let getGlobalQAs;
      if (localStorage.getItem("globalqa") == null) {
        const getGlobalQAs = await this.buyerService.getGlobalFieldData(payload);
        localStorage.setItem("globalqa", JSON.stringify(getGlobalQAs))
      }
      else {
        getGlobalQAs = JSON.parse(localStorage.getItem("globalqa"));
      }
      // console.log('formAutofill getAllAnswers', getGlobalQAs)
      if (this.getConfigsInfo && this.getConfigsInfo.length && getGlobalQAs) {
        this.getConfigsInfo.forEach(item => {
          if (item['type'] == 'layout'){
            item['fieldset'].forEach(field => {
              // console.log('field', field)
              // console.log('Global', field['global'])
              if (field['global']){
                const filterAnwsers = getGlobalQAs.filter((question) => question['QuestionName'] == field['name'])
                this.globalAnwsers.push(...filterAnwsers)
              }
            })
          }
        })
      }

      // console.log('formAutofill globalAnwsers', this.globalAnwsers)
      let declarationFormData = Object.assign({}, this.declarationForm)
      this.globalAnwsers.forEach((anwserItem) => {
        // console.log('formAutofill anwserItem', anwserItem);
        if(anwserItem != null && anwserItem != undefined ){
          // console.log('this.declarationForm', this.declarationForm);  
          if((this.declarationForm[anwserItem['QuestionName']] == "") || (this.declarationForm[anwserItem['QuestionName']] == null) || (this.declarationForm[anwserItem['QuestionName']] == undefined)){
            // console.log('formAutofill Prepopulate Values', anwserItem['Answer']);    
            declarationFormData[anwserItem['QuestionName']] = anwserItem['Answer']
          }
        }  
      })
      this.declarationForm = declarationFormData
      // console.log('formAutofill this.requestorDetailsInfo', this.declarationForm);  
      setTimeout(() => {
        console.log('formAutofill Calling updateFormValues');  
        this.dynamicForm.updateFormValues()
      });  
   }
    setTimeout(() => {              
      console.log("settimeout");
      const elements: HTMLInputElement[] = [];
    
      for (let gobalVal of this.globalAnwsers) {
        let formControlType: string;
    
        if (gobalVal['AnswerInputField'] === 'dropdown') {
          formControlType = 'select';
        } else if (gobalVal['AnswerInputField'] === 'input') {
          formControlType = 'input';
        } else if (gobalVal['AnswerInputField'] === 'radio') {
          formControlType = 'radio';
        }
    
        const actualElement = document.querySelector<HTMLInputElement>(`[id^="${gobalVal['QuestionName']}${formControlType}"]`);
    
        if (actualElement) {
          elements.push(actualElement);
        }
      }
    
      for (const element of elements) {
        element.addEventListener("change", (e) => {
          // console.log("Field change??? ===>", e);
          const targetElement = e.currentTarget as HTMLElement;
          if (targetElement) {
            targetElement.style.border = 'solid 1px #414187';
          }
        });
      }
    });
  }
 
  highlightInput() {
    let selectedElement = [];
    if((this.globalAnwsers.filter(globalval => this.dynamicForm.formGroup.controls[globalval.QuestionName].value !== globalval.Answer)).length > 0) {
      selectedElement = this.globalAnwsers.filter(globalval => this.dynamicForm.formGroup.controls[globalval.QuestionName].value !== globalval.Answer)
     this.globalAnwsers.forEach (eachItem => {
      const actualElement = document.querySelector<HTMLInputElement>(`[id^="${eachItem['QuestionName']}${this.formControlType(eachItem)}"]`);
      actualElement.style.border = 'solid 1px #414187';
      const supplierControl = this.dynamicForm.formGroup.controls[eachItem.QuestionName]
      supplierControl.setValue(eachItem.Answer)
    })
    selectedElement.forEach(element => {
       const actualElement = document.querySelector<HTMLInputElement>(`[id^="${element['QuestionName']}${this.formControlType(element)}"]`);
       actualElement.style.setProperty('border', '2px solid orange', 'important');
       return
    })
   }
  }
  
  formControlType(globalValue) {
    let formControlType: string;
       if (globalValue['AnswerInputField'] === 'dropdown') {
         formControlType = 'select';
       } else if (globalValue['AnswerInputField'] === 'input') {
         formControlType = 'input';
       } else if (globalValue['AnswerInputField'] === 'radio') {
         formControlType = 'radio';
       }
       return formControlType;
  }

  openGlobalPopup() {
    this.dialogRef = this.dialog.open(GlobalPopupComponent, {
      width: '450px',
    });
  
    this.dialogRef.afterClosed().subscribe(result => {
      for (this.getglobalVal of this.globalAnwsers) {
        const supplierControl = this.dynamicForm.formGroup.controls[this.getglobalVal.QuestionName];
        if (supplierControl) {
          if (supplierControl.dirty) {
            // console.log("openGlobalPopup called : supplierControl", supplierControl);
            // console.log("openGlobalPopup called : result", result);
            if (result === 'onConfirmClick') {
              this.submitDraft().then((data: boolean) => {
                if (data) {
                  this.vm.saved();
                  this.router.navigate([
                    `buyer/onboarding/${this.onBoardingService.supplierCode}/step4`,
                  ]);
                }
              });
      } else if (result === 'onCancelClick') {
        this.highlightInput();
        supplierControl.setValue(this.getglobalVal.Answer);
        // this.globalAnwsers.some(globalAnswerVal => {
        //   if(this.dynamicForm.formGroup.controls[globalAnswerVal.QuestionName].status == 'INVALID' && this.dynamicForm.formGroup.controls[globalAnswerVal.QuestionName].touched == true) {
        //       const actualElement = document.querySelector<HTMLInputElement>(`[id^="${globalAnswerVal['QuestionName']}${this.formControlType(globalAnswerVal)}"]`);
        //       actualElement.style.border = '2px solid orange';
        //       return
        //   };
        // });
      }
    }
  }
  }
  });
}
  
  checkAndOpenGlobalPopup() {
    const globalDirty = this.globalAnwsers.some(globalAnswerVal => {
      const control = this.dynamicForm.formGroup.controls[globalAnswerVal.QuestionName];
      return control && control.dirty;
    });
    return globalDirty;
  }

  async canDeactivate() {
    this.vm.setOriginal(this.dynamicForm.originalValues());
    this.vm.setCurrent(this.dynamicForm.values());
    // Disabling this code for bug 319981.
    // if (this.vm.isDirty()) {
    //   const result = await this.dialogService.saveConfirmLastPage();
    //   if (result === "save") {
    //     // current task is request form draft complete
    //     if (
    //       this.onBoardingService.getUserTaskByKey(
    //         ProcessNames.generic_supplierSetup,
    //         SetupProcess.requestor_draft
    //       )
    //     ) {
    //       if (!(await this.submitDraft())) {
    //         return false;
    //       }
    //     } else if (
    //       this.onBoardingService.getUserTaskByTaskKey(
    //         AllTaskKey.SST_rejectToBuyer
    //       )
    //     ) {
    //       if (!(await this.resubmitForm())) {
    //         return false;
    //       }
    //     }
    //   }
    //   if (result === "cancel") {
    //     return false;
    //   }
    // }

    return true;
  }

  validate() {
    return this.dynamicForm.valide();
  }

  async checkAnswerValue(type) {
    let tmpMessage;
    if (type === "Declaration_5") {
      const tmpValue = this.dynamicForm.formGroup.get("Declaration_5").value;
      if (tmpValue === "11" || tmpValue === "12") {
        tmpMessage = `Ex-employees must wait 6 months before working for Accenture/Avanade as Contractors.
                      Former Accenture/Avanade Leaders as contractors require prior Legal approval`;
        await this.dialogService.dialog(
          tmpMessage,
          ButtonCommands.Close,
          DialogType.warning,
          "Please Note"
        );
      }
    } else if (type === "Declaration_6") {
      const tmpValue = this.dynamicForm.formGroup.get("Declaration_6").value;
      if (tmpValue === "Y") {
        tmpMessage = `Former/Current Government employees or Government Lobbyists as Contractors require prior Legal approval`;
        await this.dialogService.dialog(
          tmpMessage,
          ButtonCommands.Close,
          DialogType.warning,
          "Please Note"
        );
      }
    }
  }
  isSupplierNameDuplicate(): boolean {
    this.supplierName =
      this.onBoardingService.supplierModel.Mapping.searchTerm.supplierName;
    return this.supplierName.startsWith("DUPLICATE-");
  }
  // update supplier mapping info
  private updateSupplierMapping() {
    // this.onBoardingService.supplierModel.RequestForm.DeclarationInfo = trim(this.declarationForm)
    this.onBoardingService.supplierModel.RequestForm.DeclarationInfo = trim(
      this.dynamicForm.values()
    );
    // const saveUrl = `${environment.gateway}${LoadingUrlMapping.submitRequestForm}`;
    // this.loadingService.openLoading(saveUrl);
    if (
      this.onBoardingService.requestor_step <
      this.onBoardingService.current_step
    ) {
      this.onBoardingService.requestor_step = 4;
    }
    this.supplierAdmin.Email =
      this.onBoardingService.supplierModel.Mapping.supplierEmail;
    this.supplierAdmin.RoleCode = "supplierAdmin";
    this.supplierAdmin.FirstName =
      this.onBoardingService.supplierModel.RequestForm.RequestorDetailsInfo.SupplierContactFirstName;
    this.supplierAdmin.LastName =
      this.onBoardingService.supplierModel.RequestForm.RequestorDetailsInfo.SupplierContactLastName;
    this.supplierAdmin.Name =
      this.onBoardingService.supplierModel.RequestForm.RequestorDetailsInfo.SupplierContactPoint;
    this.supplierAdmin.Enabled = true;
    this.supplierAdmin.Status = "Active";
    this.supplierAdmin.SupplierCode = this.supplierCode;
    this.supplierAdmin.PrivacyStatus = "new";
  }

  // submit draft request form
  async submitDraft(): Promise<boolean> {
    this.context.changeSubmitState();
    this.dynamicForm.removeFormTouch();
    if (!this.validate()) {
      return false;
    }
    this.updateSupplierMapping();
    // exec task
    const variables = {
      isTerminate: false,
      isGDPR: true,
      isBI: this.onBoardingService.isBIApplicable,
    };
    this.onBoardingService.getExecuteTask(
      ProcessNames.generic_supplierSetup,
      AllTaskKey.requestor_draft,
      variables
    );
    if (!this.onBoardingService.supplierModel.Mapping.executeTask) {
      return false;
    }
    let submitDraftRes:any
    if (this.isNewVersion) {
      this.setSupplieModelForSaveDraft()
      submitDraftRes= await this.onsubmitDraft()
    }else{
        submitDraftRes= await this.onExistingSubmitDraft()
    }
   
    // console.log("submit result ---- ", submitDraftRes);
    if (
      submitDraftRes.isSuccess === false &&
      submitDraftRes.error.errorType === "wrong invitation url"
    ) {
      this.loadingService.closeLoading();
      // eslint-disable-next-line max-len
      await this.dialogService.dialog(
        `The supplier email address you have entered is a group address and therefore not supported in the Supplier Hub.Please navigate back to the Requester Details page and update the Supplier POC Email field with an email address that is associated with an individual within the Suppliers company.
                                        If you require further support please contact <a href='mailto:procurement.support@accenture.com'>procurement.support@accenture.com</a>`,
        ButtonCommands.Close,
        DialogType.warning,
        "Warning"
      );
      return false;
    }
    // submitDraft function has been created supplier user
    // if (!this.onBoardingService.supplierModel.Mapping.IsMigrated) {
    //   this.addSupplierAdmin()
    // }
    return true;
  }

  async onExistingSubmitDraft(){
     const saveUrl = `${environment.gateway}${LoadingUrlMapping.submitRequestDraft}`;
    this.loadingService.openLoading(saveUrl);
    return await this.onBoardingService.submitDraft();
  }

  async onsubmitDraft(){
     const saveUrl = `${environment.buyerGateway}${LoadingUrlMapping.submitRequestDraft}`;
    this.loadingService.openLoading(saveUrl);
    return await this.onBoardingService.submitDraftEnhanced(this.supplierModelForSaveDraft);
  }

  setSupplieModelForSaveDraft(){
    this.supplierModelForSaveDraft.RequestForm.DeclarationInfo=this.onBoardingService.supplierModel.RequestForm.DeclarationInfo;
      this.supplierModelForSaveDraft.Mapping=this.onBoardingService.supplierModel.Mapping
      let initalSupplierModel:DeclarationInfo=trim(this.dynamicForm.originalValues());
      this.supplierModelForSaveDraft.ChangeHistory =this.commonService.getChangeHistory(initalSupplierModel,this.supplierModelForSaveDraft.RequestForm.DeclarationInfo,"DeclarationInfo")
  }

  // resubmit request form
  async resubmitForm(): Promise<boolean> {
    this.context.changeSubmitState();
    // this.dynamicForm.removeFormTouch()
    if (!this.validate()) {
      return false;
    }
    await this.updateSupplierMapping();
    // get executable task GUID: taskInstId
    if (
      this.onBoardingService.checkTaskIsComplete(
        ProcessNames.generic_supplierSetup,
        SetupProcess.supplier_accepted
      )
    ) {
      this.onBoardingService.getExecuteTask(
        ProcessNames.generic_supplierSetupSubProcess,
        AllTaskKey.SST_rejectToBuyer
      );
      if (!this.onBoardingService.supplierModel.Mapping.executeTask) {
        return false;
      }
    }

    if(this.isNewVersion){
      this.setSupplieModelForSaveDraft()
      return await this.onResubmitForm()
    }else{
      return await this.existingResubmitform()
    }
  }

  async onResubmitForm(){
    const saveUrl = `${environment.buyerGateway}${LoadingUrlMapping.resubmitRequestForm}`;
    this.loadingService.openLoading(saveUrl);
    await this.onBoardingService.resubmitFormEnhanced(this.supplierModelForSaveDraft),
    this.loadingService.closeLoading()
    return true;
  }

  async existingResubmitform(){
    const saveUrl = `${environment.gateway}${LoadingUrlMapping.resubmitRequestForm}`;
    this.loadingService.openLoading(saveUrl);
    await this.onBoardingService.resubmitForm();
    return true;
  }

  async SaveAndNavigate() {
    console.log("SaveAndNavigate method called")
    const globalDirty = this.globalAnwsers.some(globalAnswerVal => {
      const control = this.dynamicForm.formGroup.controls[globalAnswerVal.QuestionName];
      if(control && this.dynamicForm.formGroup.controls[globalAnswerVal.QuestionName].value !== globalAnswerVal.Answer) {
        return true
      } else {
        return false
      }
    });
    if(globalDirty) {
      this.openGlobalPopup();
    } else {
      this.submitDraft().then((data: boolean) => {
        if (data) {
          this.vm.saved();
          this.router.navigate([
            `buyer/onboarding/${this.onBoardingService.supplierCode}/step4`,
          ]);
        }
      });
    }
  }


  async resubmit() {
    // this.context.changeSubmitState()
    // // this.dynamicForm.removeFormTouch()
    // // this.onBoardingService.supplierModel.RequestForm.DeclarationInfo = trim(this.dynamicForm.values())
    // if (!this.validate()) {
    //   return false
    // }

    this.resubmitForm().then((data: boolean) => {
      if (data) {
        this.vm.saved();
        this.dialogService
          .dialog(
            `Your change request has been successfully submitted.`,
            ButtonCommands.Ok,
            DialogType.success,
            "Please Note"
          )
          .then((result: boolean) => {
            this.router.navigate([`buyer`]);
          });
      }
    });
  }

  get ShowResubmitButton() {
    return this.onBoardingService.checkResubmit();
  }

  async addSupplierAdmin() {
    const isSendEmail = false; // not send invitation email
    const data = await this.supplierService.createUser(
      this.supplierAdmin,
      isSendEmail,
      false
    );
  }

  async terminate() {
    const terminateResult = await this.dialogService.dialog(
      `Please note that if you terminate this request, the current process will be stopped with no option to reactivate.
                                          The process will need to be started again from the beginning.`,
      ButtonCommands.ProceedCancel
    );
    if (terminateResult === ButtonCommand.yes) {
      const variables = {
        isTerminate: true,
      };
      this.onBoardingService.getExecuteTask(
        ProcessNames.generic_supplierSetup,
        AllTaskKey.requestor_draft,
        variables
      );
      if (!this.onBoardingService.supplierModel.Mapping.executeTask) {
        return false;
      }
      this.onBoardingService.requestFormTerminate().then((d: any) => {
        if (d.isSuccess) {
          this.vm.saved();
          this.dialogService
            .dialog(
              "You have terminated successfully.",
              ButtonCommands.Close,
              DialogType.success,
              OnBoardMessage.APResultSendSuccess
            )
            .then((res: any) => {
              if (res === ButtonCommand.close) {
                const TsmId: string =
                  this.onBoardingService.supplierModel.Mapping.TsmId;
                const apFlag: boolean =
                  this.authService.passport.buyer.RoleCode === "AP_SST" ||
                  this.authService.passport.buyer.RoleCode === "AP_QC";
                if (!!TsmId && !apFlag) {
                  const env = sessionStorage.getItem("tsmEnv");
                  const url =
                    env === "dev"
                      ? environment.landingdev_url
                      : env === "perf"
                      ? environment.landingperf_url
                      : env === "hotfix"
                      ? environment.landinghotfix_url
                      : env === "stage"
                      ? environment.landingstage_url
                      : env === "test"
                      ? environment.landingtest_url
                      : environment.landing_url;
                  // let TsmLink:string = environment.landing_url
                  window.location.href =
                    url +
                    "?login_hint=" +
                    this.authService.passport.buyer.Email;
                } else {
                  this.router.navigate([`buyer`]);
                }
              }
            });
        }
      });
    }
  }
}
