// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.collapse_Country {
  margin-top: 1rem;
  font-weight: bold;
  border-bottom: #ccc 1px solid;
  color: #666;
}

.sub_title {
  position: relative;
}

.collapse_left_icon {
  position: absolute;
  left: 8px;
}

.link-mare-panel {
  position: absolute;
  height: auto;
  background: #fff;
  color: #000;
  border-radius: 5px;
  padding: 5px 0;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.12), 0 3px 6px rgba(0, 0, 0, 0.24);
  outline: 10;
  z-index: 9999;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
