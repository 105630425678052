export function CustomValidator(params: { name: string; async: boolean }) {
  return function (
    target: any,
    propertyKey: string,
    descriptor: PropertyDescriptor
  ) {
    if (!target.metadata) {
      target.metadata = {};
      target.metadata.validators = [];
    }
    target.metadata.validators.push({
      name: params.name || propertyKey,
      fn: descriptor.value,
      async: params.async || false,
    });
  };
}

export function CustomFilter(name: string) {
  return function (
    target: any,
    propertyKey: string,
    descriptor: PropertyDescriptor
  ) {
    if (!target.metadata) {
      target.metadata = {};
      target.metadata.filters = [];
    }
    target.metadata.filters.push({
      name: name || propertyKey,
      fn: descriptor.value,
    });
  };
}

export function CustomHook(name: string) {
  return function (
    target: any,
    propertyKey: string,
    descriptor: PropertyDescriptor
  ) {
    if (!target.metadata) {
      target.metadata = {};
      target.metadata.hooks = [];
    }
    target.metadata.hooks.push({
      name: name || propertyKey,
      fn: descriptor.value,
    });
  };
}

export function CustomDatasource(name: string) {
  return function (
    target: any,
    propertyKey: string,
    descriptor: PropertyDescriptor
  ) {
    if (!target.metadata) {
      target.metadata = {};
      target.metadata.datasources = [];
    }
    target.metadata.datasources.push({
      name: name || propertyKey,
      fn: descriptor.value,
    });
  };
}

export function CustomExpression(name: string) {
  return function (
    target: any,
    propertyKey: string,
    descriptor: PropertyDescriptor
  ) {
    if (!target.metadata) {
      target.metadata = {};
      target.metadata.expressions = [];
    }
    target.metadata.expressions.push({
      name: name || propertyKey,
      fn: descriptor.value,
    });
  };
}
