// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header .banner {
  padding: 2rem;
}
.header .banner .supplier {
  padding-left: 1rem;
  padding-top: 1rem;
  color: #fff;
  font-size: 2.25rem;
}
.header .banner .supplier .material-icons {
  font-size: 2.25rem;
  margin-right: 0.75rem;
}
.header .banner .header_text {
  padding: 2.1rem 0 0;
  font-size: 1.75rem;
  color: #fff;
}
.header .banner .header_text .medium {
  font-size: 1.2rem;
  padding-top: 0.9rem;
}
.header .banner .header_text .circle {
  width: 5.5rem;
  height: 5.5rem;
  line-height: 5.5rem;
  text-align: center;
  border-radius: 50%;
  border: #FFF 1px solid;
  margin-right: 2rem;
}
.header .banner .header_text .circle .material-icons {
  font-size: 3.5rem;
}

.btnCss {
  cursor: pointer;
}

.CreateCard {
  margin-left: 3rem;
  margin-right: 3rem;
}

.lineheightForTsm {
  line-height: 2rem;
}

.rightCard {
  margin-right: 2rem;
  height: 30rem;
}

.congrats {
  margin: 2rem 0;
  font-size: 1.25rem;
  color: #4E4E87;
  font-weight: bold;
}

.congrats_card {
  margin: 2rem 0;
  width: 33.2vw;
  height: 18vw;
  margin-left: -7.2rem;
}
.congrats_card .left {
  float: left;
  width: 54%;
}
.congrats_card .left .title {
  font-size: 1.5rem;
  padding: 0 0;
  color: #47246a;
}
.congrats_card .left .sub_title {
  font-size: 1rem;
  font-weight: normal;
  margin-top: 1.8rem;
  word-break: normal;
  word-wrap: break-word;
  white-space: pre-wrap;
}
.congrats_card .left .reg_date {
  vertical-align: middle;
  margin-top: 1.5rem;
}
.congrats_card .left .reg_date_icon {
  margin-top: -0.3rem;
  color: #47246a;
  margin-left: -0.3rem;
  font-size: 1.3rem;
}
.congrats_card .left .reg_date_span {
  margin-left: 0.5rem;
}
.congrats_card .left .view_details_btn {
  background-color: #ecf7f3;
  border-radius: 2.5rem;
  line-height: 3rem;
  font-size: 1.1rem;
  text-align: center;
  margin-top: 1.5rem;
  color: #47246a;
  font-weight: 600;
  padding: 0 1rem;
  display: inline-block;
}

.right_dev {
  text-align: center;
}

.fingerprint_image {
  width: 9rem;
  margin-top: 3.3rem;
}

.right_dev_all {
  height: 18vw;
  background-color: #ecf7f3;
  border-bottom-right-radius: 2rem;
  float: left;
  width: 46%;
}

.right_subtitle {
  margin-top: 3.5rem;
  font-weight: bolder;
}
.right_subtitle i {
  font-size: 1.2rem;
  margin-top: -0.2rem;
}
.right_subtitle span {
  margin-left: 0.3rem;
}

.right_sub_content {
  margin-left: 1.6rem;
  margin-top: 0.5rem;
}

.completed_status-value {
  background: #55bded;
  padding: 0 1.5rem;
  color: #fff;
  background-color: #763ab2;
}

.status-border {
  border-top: 2.25rem solid #47246a !important;
}

.subtitle_color {
  color: #4E4E87;
}

.labelCss {
  padding-bottom: 2rem;
  font-size: 1rem;
}

.labelContentRight {
  margin: 2rem 0;
  font-size: 1rem;
  font-weight: 200;
  line-height: 1.5;
}

.labelContentLeft {
  font-size: 1rem;
  font-weight: 200;
  line-height: 1.5;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
