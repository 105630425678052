export const config = {
  uploadOptions: {
    allowedMimeType: [
      "image/jpeg",
      "application/pdf",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.ms-excel",
      "application/vnd.oasis.opendocument.text",
      "application/vnd.ms-powerpoint",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      "text/plain",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/rtf",
      "application/vnd.ms-outlook",
      "text/comma-separated-values",
      "text/csv",
      "text/x-java-source",
      "image/png",
      "",
    ],
    allowedFileType: [
      "image",
      "video",
      "audio",
      "pdf",
      "doc",
      "xls",
      "ppt",
      "msg",
      "csv",
      "java",
    ],
    maxFileSize: 10485760,
    queueLimit: 10,
    autoUpload: false,
  },
};

export const met_upload_config = {
  uploadOptions: {
    allowedMimeType: ["image/jpeg", "application/pdf", "image/png"],
    allowedFileType: ["image", "pdf"],
    maxFileSize: 10485760,
    queueLimit: 2,
    autoUpload: false,
  },
};
