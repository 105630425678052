import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef, MatDialogState } from '@angular/material/dialog';
import { RebarAuthService } from "src/app/core/rebarauth/rebar.auth.service";
import { timeoutPopup } from "src/app/interfaces/timeoutPopup";
import { timeoutPopupService } from "src/app/services/timeout-popup.service";

@Component({
  selector: 'app-timeout-popup',
  templateUrl: './timeout-popup.component.html',
  styleUrls: ['./timeout-popup.component.sass']
})
export class TimeoutPopupComponent implements OnInit {
  public static interval = 600;
  public static buttonParam = "init";
  static param: any;
  public static flag = false;
  dialogRef: any;
  constructor(
    public dialog: MatDialog,
    private rebarService: RebarAuthService,
    private modalService: timeoutPopupService,
  ) {
  }

  ngOnInit() {
    this.openDialog();
  }

  public openDialog() {
    let objTime = {
      init: 0,
      time: () => {
        objTime.init += 1;
        const state = this.dialogRef && this.dialogRef.getState()
        // console.log('Timeout Popup objTime.init :', objTime.init);
        // console.log('Timeout Popup interval :', TimeoutPopupComponent.interval);
        if (objTime.init === TimeoutPopupComponent.interval && state !== MatDialogState.OPEN) {
          this.dialogRef = this.dialog.open(TimeoutPopupContentComponent, {
            width: '510px',
            disableClose: true,
            autoFocus: false,
          });
          clearInterval(testUser);
          objTime.init = 0;
          this.dialogRef.afterClosed().subscribe(() => {
            if (TimeoutPopupComponent.buttonParam == 'Extend') {
              this.openDialog();
            }
          });
        }
      },
      eventFun: function () {
        clearInterval(testUser);
        objTime.init = 0;
        testUser = setInterval(objTime.time, 1000);
      }
    };

    let testUser = setInterval(objTime.time, 1000);
    let body = document.querySelector("html");
    body.addEventListener("click", objTime.eventFun);
    body.addEventListener("keydown", objTime.eventFun);
    body.addEventListener("mousemove", objTime.eventFun);
    body.addEventListener("mousewheel", objTime.eventFun);
  }
}


@Component({
  selector: 'app-timeout-content-popup',
  templateUrl: './timeout-popup-content.html',
})
export class TimeoutPopupContentComponent implements OnInit {
  msg: string = "";
  param: any;
  constructor(@Inject(MAT_DIALOG_DATA) public data,
    private matDialogRef: MatDialogRef<TimeoutPopupContentComponent>,
    private modalService: timeoutPopupService,
    private rebarService: RebarAuthService,) {
  }

  ngOnInit() {
    this.resetTime(300);
  }

  timeExtend(value) {
    TimeoutPopupComponent.buttonParam = value;
    clearTimeout(this.param);
    this.matDialogRef.close();
    TimeoutPopupComponent.flag = true;
    TimeoutPopupComponent.interval = 1800;
  }

  logout() {
        this.rebarService.logout();
  }

  resetTime(time: number) {
    var countdown = (() => {
      let s = time % 60;
      let m = Math.floor(time / 60) % 60;
      this.msg = (m < 10 ? "0" : "") + m + ":" + (s < 10 ? "0" : "") + s;
      if (time == 0) {
        TimeoutPopupComponent.flag = true;
        setTimeout(() => {
          this.rebarService.logout();
        }, 600000);
      }
      if (--time >= 0) {
        this.param = setTimeout(countdown, 1000);
      }
    })
    countdown();
  }

  get flag() {
    return TimeoutPopupComponent.flag;
  }

  get popData() {
    return this.modalService.getPopData();
  }

  get popNewData() {
    return this.modalService.getNewPopData();
  }
}