/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable prettier/prettier */
import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { environment } from "../../environments/environment";
import { cloneDeep } from "../utils";
import { HttpService } from "./api/http.service";

@Injectable({
  providedIn: "root",
})
export class DictionaryService {
  private resultCache = [];
  private dataMap = new Map<string, any>();
  private separator = ".";
  private loading = false;
  private dictionaryEvent = new Subject<any>();
  public dictionaryObserver$ = this.dictionaryEvent.asObservable();

  constructor(private http: HttpService) {}

  async loadAllDictionary(): Promise<any> {
    while (this.loading) {
      await this.sleep(50);
    }
    if (this.resultCache && this.resultCache.length > 0) {
      return this.resultCache;
    }
    const url = `${environment.gateway}/dropdown/dictionary`;
    this.loading = true;
    try {
      const response = (await this.http.GetPromise(url)) as any;
      this.resultCache = response.data;
      this.dictionaryEvent.next(response.data);
    } finally {
      this.loading = false;
    }
    return this.resultCache;
  }

  /**
   * use this when you cannot make sure the dicts were cached or not , such as in a dialog component
   * @param code can be nested , ex: requestor-form.SpendCommodityGroup
   */
  async getDictionary(code: string = null): Promise<Array<any>> {
    if (!this.resultCache || this.resultCache.length === 0) {
      await this.loadAllDictionary();
    }
    return this.getDictionaryFromCache(code);
  }

  /**
   * use this when you can make sure the dicts were cached , such as in a custom datasource or hook
   * @param code can be nested , ex: requestor-form.SpendCommodityGroup
   */
  getDictionaryFromCache(code: string = null): Array<any> {
    const cacheInstance = cloneDeep(this.resultCache);
    if (!code) {
      return cacheInstance;
    }
    if (code.indexOf(this.separator) > 0) {
      const parts = code.split(this.separator);
      let source = cacheInstance;
      parts.forEach((part: string) => {
        //source = source.find((s) => s.Code === part).Items;
        const foundItem = source.find((s) => s.Code === part);
        if (foundItem) {
          source = foundItem.Items;
        }
      });
      return source as Array<any>;
    }

    return cacheInstance.find((res) => res.Code === code).Items as Array<any>;
  }

  public setDataMap(key, value) {
    this.dataMap.set(key, value);
  }

  public getDataMap() {
    return this.dataMap;
  }

  public sleep(time) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }
}
