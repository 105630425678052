import { Component, OnInit } from "@angular/core";
import { ProfileService } from "../../services/profile.service";
import { SupplierHeaderService } from "../../services/header.service";
import { LanguageService } from "src/app/dynamic-components/utils/language.service";

@Component({
  selector: "app-helppage",
  templateUrl: "./helppage.component.html",
  styleUrls: ["./helppage.component.sass"],
})
export class HelppageComponent implements OnInit {
  currentTitle = "FAQ";
  selectGUvalue = "UKIA";
  currentFAQPage: boolean;
  currentVideoPage: boolean;
  currentAskForSupportPage: boolean;
  faqQuestionShow1 = false;
  faqQuestionShow2 = false;
  faqQuestionShow3 = false;
  faqQuestionShow4 = false;
  faqQuestionShow5 = false;
  faqQuestionShow6 = false;
  faqQuestionShow7 = false;
  faqQuestionShow8 = false;
  faqQuestionShow9 = false;
  faqQuestionShow10 = false;
  faqQuestionShow11 = false;
  faqQuestionShow12 = false;
  faqQuestionShow13 = false;
  faqQuestionShow14 = false;
  faqQuestionShow15 = false;
  faqQuestionShow16 = false;
  faqQuestionShow17 = false;
  faqQuestionShow18 = false;

  FAQ: string;
  Quick_Tutorials: string;
  Ask_for: string;
  Download: string;
  Guide: string;

  constructor(
    private profileService: ProfileService,
    private languageService: LanguageService,
    private supplierHeaderService: SupplierHeaderService
  ) {
    this.supplierHeaderService.title = this.languageService.getValue(
      "static.supplier.landing-header.helpSupport"
    );
    this.supplierHeaderService.icon = "live_help";
    this.supplierHeaderService.showProfileButtons = false;
  }

  ngOnInit() {
    this.currentFAQPage = true;
    this.currentVideoPage = false;
    this.currentAskForSupportPage = false;
    this.currentTitle = this.languageService.getValue(
      "static.supplier.helppage.FAQ"
    );
    this.FAQ = this.languageService.getValue("static.supplier.helppage.FAQ");
    this.Quick_Tutorials = this.languageService.getValue(
      "static.supplier.helppage.Quick_Tutorials"
    );
    this.Ask_for = this.languageService.getValue(
      "static.supplier.helppage.Ask_for_Support"
    );
    this.Download = this.languageService.getValue(
      "static.supplier.helppage.Download"
    );
    this.Guide = this.languageService.getValue(
      "static.supplier.helppage.Guide"
    );
  }

  tabPageChange(currentTitle: string) {
    this.currentTitle = currentTitle;

    switch (currentTitle) {
      case this.FAQ:
        this.currentFAQPage = true;
        this.currentVideoPage = false;
        this.currentAskForSupportPage = false;
        break;
      case this.Quick_Tutorials:
        this.currentFAQPage = false;
        this.currentVideoPage = true;
        this.currentAskForSupportPage = false;
        break;
      case this.Ask_for:
        this.currentFAQPage = false;
        this.currentVideoPage = false;
        this.currentAskForSupportPage = true;
        break;
    }
  }

  displayValue(country) {
    switch (country) {
      case "Philippines":
        return (
          this.languageService.getValue("static.supplier.helppage.Download") +
          this.languageService.getValue(
            "static.supplier.helppage.Philippines"
          ) +
          "(PDF)"
        );
      case "NA":
        return (
          this.languageService.getValue("static.supplier.helppage.Download") +
          this.languageService.getValue(
            "static.supplier.helppage.North_America"
          ) +
          "(PDF)"
        );
      case "Nordics":
        return (
          this.languageService.getValue("static.supplier.helppage.Download") +
          this.languageService.getValue("static.supplier.helppage.Nordics") +
          "(PDF)"
        );
      case "Italy_EN":
        return (
          this.languageService.getValue("static.supplier.helppage.Download") +
          this.languageService.getValue("static.supplier.helppage.Italy_EN") +
          "(PDF)"
        );
      case "Italy_IT":
        return (
          this.languageService.getValue("static.supplier.helppage.Download") +
          this.languageService.getValue("static.supplier.helppage.Italy_IT") +
          "(PDF)"
        );
      case "Iberia_EN":
        return (
          this.languageService.getValue("static.supplier.helppage.Download") +
          this.languageService.getValue("static.supplier.helppage.Iberia_EN") +
          "(PDF)"
        );
      case "Iberia_Spanish":
        return (
          this.languageService.getValue("static.supplier.helppage.Download") +
          this.languageService.getValue(
            "static.supplier.helppage.Iberia_Spanish"
          ) +
          "(PDF)"
        );
      case "Iberia_Portuguese":
        return (
          this.languageService.getValue("static.supplier.helppage.Download") +
          this.languageService.getValue(
            "static.supplier.helppage.Iberia_Portuguese"
          ) +
          "(PDF)"
        );
        case "Gallia":
          return (
            this.languageService.getValue("static.supplier.helppage.Download") +
            this.languageService.getValue(
              "static.supplier.helppage.Gallia"
            ) +
            "(PDF)"
          );
      default:
        break;
    }
  }
}
