import {
  Component,
  OnInit,
  HostListener,
  ElementRef,
  ViewChild,
  Input,
} from "@angular/core";
import { NotificationService } from "src/app/services/notification.service";
import { AuthService } from "src/app/services/auth/auth.service";
import { DashBoardService } from "src/app/modules/buyer/services/dashboard.service";
import { Router } from "@angular/router";
import {
  Notification,
  NotificationType,
} from "../../../../interfaces/notification";
import { LanguageService } from "src/app/dynamic-components/utils/language.service";

@Component({
  selector: "app-notification-header",
  templateUrl: "./notification-header.component.html",
  styleUrls: ["./notification-header.component.sass"],
})
export class NotificationHeaderComponent implements OnInit {
  @Input() enableClick = true;
  isShowNotification = false;

  isShowCenter = false;

  @ViewChild("abc")
  abc: ElementRef;

  @ViewChild("def")
  def: ElementRef;

  constructor(
    private notificationService: NotificationService,
    private authService: AuthService,
    private dashBoardService: DashBoardService,
    private languageService: LanguageService,
    private router: Router,
    private ele: ElementRef
  ) {}

  ngOnInit() {
    this.authService.initCompleted.subscribe((value) => {
      this.notificationService.loadData();
    });
  }

  get latestNotification() {
    return this.notificationService.latesNotification;
  }

  get newExists() {
    return this.notificationService.latesNotification !== undefined;
  }

  navigateTo(item: Notification) {
    this.notificationService.markAsRead(item.ID + "");
    if (this.notificationService.isBuyer()) {
      if (
        item.EventType === NotificationType.AP_QC_ReviewReject ||
        item.EventType === NotificationType.AP_SST_ReviewApproved
      ) {
        this.router.navigate([
          `buyer/onboarding/process/${item.Params.SupplierCode}`,
        ]);
      } else if (
        item.EventType === NotificationType.RequestorChangeAfterReject ||
        item.EventType === NotificationType.RequestorChangeAfterOnboarded
      ) {
        this.router.navigate([`buyer/history/${item.Params.SupplierCode}/0`]);
      } else if (
        item.EventType === NotificationType.SupplierChangeAfterReject ||
        item.EventType === NotificationType.SupplierChangeAfterOnboarded
      ) {
        this.router.navigate([`buyer/history/${item.Params.SupplierCode}/1`]);
      } else if (item.EventType === NotificationType.SupplierDraftComplete) {
        this.router.navigate([`buyer/supplier/${item.Params.SupplierCode}`]);
      }
    } else {
      if (item.EventType === NotificationType.AP_SST_Reject) {
        this.router.navigate([
          `supplier/onboarding/process/${item.Params.SupplierCode}`,
        ]);
      } else {
        // this.router.navigate([`supplier/history/${item.Params.SupplierCode}`])
      }
    }
  }

  @HostListener("document:keydown", ["$event"])
  documentClick(evt) {
    if (this.abc && evt.keyCode !== 13) {
      if (this.def.nativeElement.contains(evt.target)) {
        return;
      }
      if (this.abc.nativeElement.contains(evt.target)) {
        return;
      }
      this.isShowNotification = false;
    }
    if(evt.keyCode == 13 && evt.srcElement.nodeName === 'APP-NOTIFICATION-HEADER') {
      this.isShowNotification = !this.isShowNotification;
    }
  }

  get notifications() {
    this.notificationService.notifications.slice(0, 5).forEach((v, i, a) => {
      const cha = Math.floor(
        (new Date().getTime() - new Date(v.CreatedDate).getTime()) /
          24 /
          60 /
          60 /
          1000
      );
      if (cha === 0) {
        v.daysBefore = this.languageService.getValue(
          "static.supplier.notification-header.today"
        );
      } else if (cha === 1) {
        v.daysBefore =
          "1" +
          " " +
          this.languageService.getValue(
            "static.supplier.notification-header.daysAgo"
          );
      } else {
        v.daysBefore =
          cha +
          " " +
          this.languageService.getValue(
            "static.supplier.notification-header.daysAgo"
          );
      }
    });
    return this.notificationService.notifications.slice(0, 5);
  }

  archived(e: MouseEvent) {
    e.stopPropagation();
    this.isShowNotification = false;
    this.notificationService.isShowCenter = true;
    this.notificationService.loadDataCenter();
  }
}
