import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "src/app/services/auth/auth.service";

@Injectable({
  providedIn: "root",
})
export class SupplierManagementGuardService {
  constructor(private authService: AuthService) {}

  async canActivate() {
    if (this.authService.passport.supplierMember.RoleCode === "supplierAdmin") {
      return true;
    }
    return false;
  }
}
