import { Injectable, EventEmitter } from "@angular/core";
import Passport from "./passport";

export enum LoginStatus {
  TokenExpired,
  TokenNone,
  Success,
}
const CONSTANTS = {
  EXPIRES_IN: "exp",
  ID_TOKEN: "id_token",
  ACCESS_TOKEN: "access_token",
  USER_PROFILE: "user_profile",
  USER_PROFILE_ID: "userProfileId",
  ROLES: "user_roles",
  CODE: "code",
  STATE: "state",
};

@Injectable({
  providedIn: "root",
})
export class AuthService {
  passport: Passport; // current user info
  loginStatus: LoginStatus = LoginStatus.TokenNone;
  initCompleted: EventEmitter<any> = new EventEmitter();
  ACL_Completed: EventEmitter<any> = new EventEmitter();
  isOtherUser = false;
  constructor() {}
}
