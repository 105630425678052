import _ from "lodash";
export const MY_FORMATS = {
  parse: {
    dateInput: "DD-MMM-YYYY",
  },
  display: {
    dateInput: "DD-MMM-YYYY",
  },
};
export enum SideNavPath {
  supplier_1 = "supplier/onboarding/process/",
  supplier_2 = "supplier/landing",
  supplier_3 = "supplier/helpPage",
  supplier_4 = "supplier/history/",
  buyer_1 = "buyer",
  buyer_2 = "",
  buyer_3 = "buyer/helpPage",
  buyer_4 = "buyer/history/",
}
export enum SideNavType {
  Dashboard = 1,
  Profile = 2,
  Help = 3,
  History = 4,
}
export enum CheckNoValue {
  NotApplicable = "Not Applicable",
  DontKnow = "I DON'T KNOW",
  NO = "NO",
  No = "No",
  NotSureNote = "Not Sure (Please note this may delay the process)",
}

export enum allowedMimeType {
  IMG = "image/jpeg",
  PDF = "application/pdf",
  XLSX = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  XLS = "application/vnd.ms-excel",
  ODT = "application/vnd.oasis.opendocument.text",
  PPT = "application/vnd.ms-powerpoint",
  PPTX = "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  TXT = "text/plain",
  DOC = "application/msword",
  DOCX = "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  RTF = "application/rtf",
  MSG = "application/vnd.ms-outlook",
  CSV = "text/comma-separated-values",
  PNG = "image/png",
  JAVA = "text/x-java-soure",
}

export enum State {
  supplier = "supplier",
  buyer = "buyer",
}

export const metricsReportList = [
  "Supplier E2E Process Turnaround Time Report",
  "AP SST Review Time Taken Report",
  "AP QC Review Time Taken Report",
  "Requestor Form Completion Time Taken Report",
  "Supplier Changes Request Completion Report",
  "AP Rejection and Termination Report",
  "Requestor Form and Supplier Forms Draft and Submission Report",
  "Supplier Invite Acceptance TAT Report",
];
export enum supplierGeographical {
  ANZ = '11',
  ASEAN = '12',
  SEA = '12',
  ASG = '13',
  Gallia = '16',
  GreaterChina = '18',
  Iberia = '19',
  ICEG = '20',
  IN = '21',
  Nordics = '25',
  MET = '24',
  UKIA = '26',
  NA = '27',
  PH = '28',
  Japan = '22'
}
export enum supplierCountry {
  SG = "SG",
  RS = "RS",
  TR = "TR",
}
export enum termPaymenCodeOfCountry {
  ANZ = "SpendCommodityGroupANZ",
  GreaterChina = "SpendCommodityGroupGreaterChina",
  ASEAN = "SpendCommodityGroupAsen",
  NA = "SpendCommodityGroupNA",
  TRorRS = "SpendCommodityGroupTRorRS",
  DEFAULT = "SpendCommodityGroup",
}

export const getTSMCountryServedQuery = (
  setUpCountry: string[],
  firstable = true
) => {
  if (firstable) {
    return _.includes(setUpCountry, "GB")
      ? "UK"
      : _.toString(_.head(setUpCountry))
  } else {
    return _.chain(setUpCountry)
      .map((countryCode) => (countryCode === "GB" ? "UK" : countryCode))
      .value();
  }
};

export enum spendCommodityGroup {
  CAM = "19"
};