import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  HostListener,
} from "@angular/core";

@Component({
  selector: "app-term-of-use",
  templateUrl: "./term-of-use.component.html",
  styleUrls: ["./term-of-use.component.sass"],
})
export class TermOfUseComponent implements OnInit {
  @Output() closeEvent: EventEmitter<boolean>;
  @ViewChild("acceptScroll") acceptScroll: ElementRef;

  constructor() {
    this.closeEvent = new EventEmitter();
  }

  ngOnInit() {}
  acceptModal(flag): void {
    if (flag === "close") {
      this.closeEvent.emit(false);
    }
  }
}
