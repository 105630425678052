import * as decode from "jwt-decode";
import * as moment from "moment";
import { environment } from "../../../environments/environment";
import { Profile } from "src/app/interfaces/profile";
import { SupplierProfile } from "src/app/interfaces/supplierProfile";
import { BuyerUser } from "src/app/interfaces/buyer";
import { SupplierModel } from "src/app/interfaces/supplierModel";

const CONSTANTS = {
  EXPIRES_IN: "exp",
  ID_TOKEN: "id_token",
  ACCESS_TOKEN: "access_token",
  USER_PROFILE: "user_profile",
  USER_PROFILE_ID: "userProfileId",
  ROLES: "user_roles",
  CODE: "code",
  STATE: "state",
  ENV: "env",
};

export default class Passport {
  id_token?: string;
  access_token?: string;
  expires_in: number;
  profile?: Profile;

  supplierMember?: any;
  supplierList?: any;
  supplier?: SupplierModel;
  buyer?: BuyerUser;
  buyerRoles: [];

  constructor(profile: Profile) {
    this.profile = profile;
  }

  public decodeToken(access_token) {
    const { email, upn, oid, name, sub, nonce, tid, unique_name, exp } =
      decode(access_token);
    return { email, upn, oid, name, sub, nonce, tid, unique_name, exp };
  }

  public getEmail(upn: string, unique_name: string, email: string) {
    if (unique_name) {
      if (unique_name.includes("#")) {
        return unique_name.substring(
          unique_name.indexOf("#") + 1,
          unique_name.length
        );
      } else {
        return unique_name;
      }
    } else if (upn) {
      if (upn.includes("#")) {
        return upn.substring(upn.indexOf("#") + 1, upn.length);
      } else {
        return upn;
      }
    } else if (email) {
      if (email.includes("#")) {
        return email.substring(email.indexOf("#") + 1, email.length);
      } else {
        return email;
      }
    } else {
      return "";
    }
  }
  public saveLocal(id_token, access_token, state) {
    this.id_token = id_token;
    this.access_token = access_token;
    
    let { upn, oid, name, email, sub, nonce, tid, unique_name, exp } =
      this.decodeToken(this.access_token);
    this.expires_in = exp;
    email = this.getEmail(upn, unique_name, email);
    const profile: Profile = { email, oid, name, sub, unique_name, nonce };
    this.profile = profile;
    // buyer unique_name is
    // console.log("this.profile.email ====>", this.profile);
    localStorage.setItem(CONSTANTS.ENV, environment.env);
    localStorage.setItem(CONSTANTS.ID_TOKEN, id_token);
    localStorage.setItem(CONSTANTS.ACCESS_TOKEN, access_token);
    localStorage.setItem(CONSTANTS.STATE, state);
    localStorage.setItem(CONSTANTS.USER_PROFILE, JSON.stringify(this.profile));
    localStorage.setItem(
      CONSTANTS.EXPIRES_IN,
      (this.expires_in + 7 * 60 * 60).toString()
    );
  }

  public removeLocal() {
    this.id_token = null;
    this.profile = null;

    localStorage.removeItem(CONSTANTS.ID_TOKEN);
    localStorage.removeItem(CONSTANTS.ACCESS_TOKEN);
    localStorage.removeItem(CONSTANTS.STATE);
    localStorage.removeItem(CONSTANTS.USER_PROFILE);
    localStorage.removeItem(CONSTANTS.EXPIRES_IN);
    localStorage.removeItem(CONSTANTS.ENV);
  }

  public refreshToken(id_token: string, access_token: string) {
    localStorage.setItem(CONSTANTS.ID_TOKEN, id_token);
    localStorage.setItem(CONSTANTS.ACCESS_TOKEN, access_token);
  }

  public tokenExpired(): boolean {
    const now = moment().utc().unix(); // current timestamp

    if (!environment.production) {
      this.expires_in = this.expires_in + 12 * 30 * 24 * 60 * 60;
    }

    if (this.expires_in > now) {
      return false;
    } else {
      this.removeLocal();
      return true;
    }
  }
}
