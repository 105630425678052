// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rounded-container {
  display: -webkit-inline-box;
}

.navTo {
  margin-bottom: 3rem;
}
.navTo img {
  width: 20px;
  margin-bottom: 0.5rem;
}
.navTo label {
  margin-left: 1rem;
  color: #AAAAAA;
}

.riskTitle {
  font-weight: bolder;
  font-size: 1.2rem;
  color: #666;
  padding-bottom: 12px;
}

.imgCss {
  text-align: right;
  padding-right: 3rem;
}

.riskContent {
  color: #666;
  font-size: 13px;
  padding-bottom: 12px;
}

.rounded-circle {
  border-radius: 50% !important;
  height: 10px;
  width: 10px;
  margin-top: 1px;
}

.normal {
  border: solid 3px #0ad89c;
}

.aboveNormal {
  border: solid 3px orange;
}

.high {
  border: solid 3px red;
}

.back_dashboard {
  padding-left: 20px;
  padding-bottom: 5px;
}
.back_dashboard a {
  cursor: pointer;
  text-decoration: underline;
}
.back_dashboard a:focus {
  outline: 2px solid black;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
