import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  HostListener,
  ElementRef,
  ViewChild,
  SimpleChanges,
  AfterViewInit,
} from "@angular/core";
import { OnBoardingService } from "src/app/modules/buyer/services/onboarding.service";
import { BaseComponent } from "src/app/dynamic-components/components/base/base.component";
import { LanguageService } from "src/app/dynamic-components/utils/language.service";
import { Console } from "console";

@Component({
  selector: "app-multiple-dropdown-custom",
  templateUrl: "./multiple-dropdown.component.html",
  styleUrls: ["./multiple-dropdown.component.sass"],
  host: {
    "[style.width]": "config.width",
  },
})
export class MultipleDropdownComponent
  extends BaseComponent
  implements OnInit, AfterViewInit
{
  selectAllChecked: boolean;
  selectAllDisabled: boolean;
  showTooltip: boolean;
  searchText: string;
  selectedItems: any[] = [];
  selectedText = "";
  datalist: any[] = [];
  showPanel = false;
  valueKey = "Code";
  textKey = "Text";
  languageKey = "languageKey";
  rand =Math.round((Math.random()*10000)).toString();
  @ViewChild("inputPanel") inputPanel: ElementRef;
  @ViewChild("dropPanel") dropPanel: ElementRef;
  constructor(
    private onBoardingService: OnBoardingService,
    private languageService: LanguageService
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.refresh();
    this.rand =Math.round((Math.random()*10000)).toString();
  }

  ngAfterViewInit() {
    this.subscribeToValueChange(this.config.name, (v) => {
      if (v) {
        this.onValueChange(v);
      }
    });
  }

  onDatasourceLoaded(ds: any) {
    let filteredData = ds.filter(d => {
      const formControl = this.formGroup.get(this.config.name);
      if (!!d.IsDeprecated) {
        if (!!d.Code && formControl.value.includes(d.Code)) {
          formControl.patchValue([]); 
        }
      }
      return !d.IsDeprecated;
    })
    this.datalist = filteredData;
    this.config.options = filteredData;
    if (ds.length > 0) {
      this.setDisableSelectAll();
    }
    this.refresh();
  }

  checkedChanged(event, item: any) {
    const target = this.config.options.find((d) => d.Code === item.Code);
    target.checked = !target.checked;
    this.selectedItems = this.config.options.filter((d) => d.checked);
    this.selectedText = this.selectedItems
      .map((kv) => {
        if (this.languageService.switcher && kv[this.languageKey]) {
          return this.languageService.getValue(kv[this.languageKey]);
        } else {
          return kv[this.textKey];
        }
      })
      .join(", ");
    this.value = this.selectedItems.map((i) => i[this.valueKey]);
    this.setDisableByExclusiveKey();
    this.formGroup.updateValueAndValidity();
    this.selectAllChecked =
      !!this.value && this.value instanceof Array
        ? this.value.length === this.config.options.length
        : false;
  }

  setValue() {
    if (this.value) {
      this.config.options.map((d) => {
        this.value.includes(d[this.valueKey])
          ? (d.checked = true)
          : (d.checked = false);
      });
      this.selectedItems = this.config.options.filter(
        (d) => d.checked && d.Code !== ""
      );
      this.selectedText = this.selectedItems
        .map((kv) => {
          if (this.languageService.switcher && kv[this.languageKey]) {
            return this.languageService.getValue(kv[this.languageKey]);
          } else {
            return kv[this.textKey];
          }
        })
        .join(", ");
      // this.datalist = cloneDeep(this.config.options.filter((d) => d.Code !== '')))
      this.datalist = this.config.options.filter((d) => d.Code !== "");

      this.formGroup.updateValueAndValidity();
    }
  }

  refresh() {
    if (!this.config.options) {
      return;
    }
    this.resetAll();
    this.setDisableByExclusiveKey();
    this.setValue();
  }

  resetAll() {
    this.selectedItems = [];
    this.selectedText = "";
    this.selectAllChecked = false;
    this.config.options.forEach((d) => {
      d.checked = false;
      d.disabled = false;
    });
  }

  setDisableSelectAll() {
    if (this.config.type === "multiple" && this.config.selectAll) {
      this.selectAllDisabled = true;
    }
  }

  setDisableByExclusiveKey() {
    if (
      this.config.templateOptions &&
      this.config.templateOptions.exclusiveKey
    ) {
      const exclusiveKey = this.config.templateOptions.exclusiveKey;
      if (!this.value || this.value.length === 0) {
        this.config.options.forEach((item) => (item.disabled = false));
        return;
      }
      if (this.value.includes(exclusiveKey)) {
        const itemsToDisable = this.config.options.filter(
          (o) => o[this.valueKey] !== exclusiveKey
        );
        itemsToDisable.forEach((itd) => (itd.disabled = true));
      } else {
        const itemsToDisable = this.config.options.filter(
          (o) => o[this.valueKey] === exclusiveKey
        );
        itemsToDisable.forEach((itd) => (itd.disabled = true));
      }
    }
  }

  setDisableBySelectAll(target: any) {
    if (this.config.templateOptions && this.config.templateOptions.selectAll) {
      const selectAllkey = this.config.templateOptions.selectAll;

      if (target.Code === selectAllkey) {
        if (target.checked) {
          this.value = this.config.options.map((i) => i[this.valueKey]);
        } else {
          this.value = [];
        }
      } else {
        if (this.value.includes(selectAllkey)) {
          const index = this.value.indexOf(selectAllkey);
          this.value.splice(index, 1);
        } else {
          if (this.value.length === this.config.options.length - 1) {
            this.value.push(selectAllkey);
          }
        }
      }
    }
  }

  onValueChange(v) {
    if (!v || (v && v.length === 0)) {
      this.resetAll();
    }
  }

  checkedSelectAll(event) {
    if (this.selectAllChecked) {
      this.value = this.config.options.map((i) => i[this.valueKey]);
    } else {
      this.value = [];
    }
    this.setValue();
    this.searchText = "";
  }

  togglePanel() {
    this.showPanel = !this.showPanel;
    this.setValue();
    this.searchText = "";
  }

  filter(keyword: any) {
    if ("null" === keyword) {
      this.searchText = "";
      this.datalist = this.config.options;
      return;
    }
    if (keyword.currentTarget.value) {
      this.searchText = keyword.currentTarget.value;
      this.datalist = this.config.options;
    }
    if (!this.searchText) {
      this.datalist = this.config.options;
      return;
    }
    // if open multiple language
    if (this.languageService.switcher) {
      this.datalist = this.config.options.filter((kv) => {
        // if the option has language key
        if (kv[this.languageKey]) {
          const translated = this.languageService.getValue(
            kv[this.languageKey]
          );
          // if the option has its translation
          if (translated) {
            return (
              translated
                .toLowerCase()
                .indexOf(keyword.currentTarget.value.toLowerCase()) >= 0
            );
          } else {
            return (
              kv[this.textKey]
                .toLowerCase()
                .indexOf(keyword.currentTarget.value.toLowerCase()) >= 0
            );
          }
        } else {
          return (
            kv[this.textKey]
              .toLowerCase()
              .indexOf(keyword.currentTarget.value.toLowerCase()) >= 0
          );
        }
      });
    } else {
      this.datalist = this.config.options.filter(
        (kv) =>
          kv[this.textKey]
            .toLowerCase()
            .indexOf(keyword.currentTarget.value.toLowerCase()) >= 0
      );
    }
  }

  estimateChooseOver(e, estimateSpendTooltip) {
    const TooltipEle = estimateSpendTooltip;
    if (estimateSpendTooltip) {
      if (
        e.clientX < TooltipEle.nativeElement.getBoundingClientRect().left ||
        e.clientX >
          TooltipEle.nativeElement.getBoundingClientRect().left +
            TooltipEle.nativeElement.offsetWidth -
            5 ||
        e.clientY < TooltipEle.nativeElement.getBoundingClientRect().top ||
        e.clientY >
          TooltipEle.nativeElement.getBoundingClientRect().top +
            TooltipEle.nativeElement.offsetHeight -
            5
      ) {
        this.showTooltip = false;
      }
    } else {
      this.showTooltip = false;
    }
  }

  @HostListener("document:click", ["$event"])
  documentClick(event) {
    if (this.inputPanel && this.dropPanel) {
      if (
        this.inputPanel.nativeElement.contains(event.target) ||
        this.dropPanel.nativeElement.contains(event.target)
      ) {
        return;
      }
    }
    this.showPanel = false;
  }
}
