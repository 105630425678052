import {
  Component,
  ElementRef,
  OnInit,
  QueryList,
  Type,
  ViewChildren,
} from "@angular/core";
import { Router } from "@angular/router";
import {
  FileItem,
  FileLikeObject,
  FileUploader,
  FileUploaderOptions,
} from "ng2-file-upload";
import {
  ButtonCommands,
  DialogService,
  DialogType,
} from "src/app/services/dialog.service";
import { allowedMimeType } from "src/app/shared/shared";
import { environment } from "src/environments/environment";
import { AuthService } from "src/app/services/auth/auth.service";
import { strToHexCharCode } from "src/app/utils/strToHex";
import { HttpService } from "src/app/services/api/http.service";
import { LoadingService } from "src/app/services/loading.service";
import * as moment from "moment";

const enum messageEnum {
  success,
  exceedSize,
  unsupportType,
  unavailableName,
  others,
}

const messages: { [key: number]: string } = {
  [messageEnum.success]: "The file is replaced successfully.",
  [messageEnum.exceedSize]:
    "The file you tried to upload is more than 10 MB, please try to upload files which are less than or equal to 10 MB.",
  [messageEnum.unsupportType]:
    "The file you tried to upload is not supported, please see the help icon besides upload field to know what are supported file types.",
  [messageEnum.unavailableName]:
    "Please use the exactly same names as below for the upload files <br/> - ",
  [messageEnum.others]: "Upload or Replace failed, please re-upload.",
};

const preBucketKey = "buyer/accenture/requestFormFiles/tax";

const enum fileEnum {
  fw8ben = "fw8ben.pdf",
  fw8bene = "fw8bene.pdf",
  w8imy = "w8imy.pdf",
  W9 = "W9 form.pdf",
}

@Component({
  selector: "app-upload-file",
  templateUrl: "./upload-file.component.html",
  styleUrls: ["./upload-file.component.sass"],
})
export class UploadFileComponent implements OnInit {
  @ViewChildren("uploadInputs") uploadInputs: QueryList<ElementRef>;

  toolTipName = "";
  fileList: Array<{ [key: string]: string }> = [];
  private uploader: FileUploader;

  constructor(
    private router: Router,
    private httpService: HttpService,
    private authService: AuthService,
    private dialogService: DialogService,
    private loadingService: LoadingService
  ) {
    this.uploader = new FileUploader({
      url: `${environment.gateway}/version`,
      method: 'Get',
      autoUpload: false,
      allowedMimeType: [allowedMimeType.PDF],
      allowedFileType: ["pdf"],
      maxFileSize: 10 * 1024 * 1024,
      queueLimit: 2,
      additionalParameter: { isValid: true },
      filters: [
        {
          name: "validFileName",
          fn: (file: FileLikeObject, options: FileUploaderOptions) => {
            options.additionalParameter["isValid"] =
              !!options.additionalParameter["triggerName"] &&
              options.additionalParameter["triggerName"] === file.name;
            return options.additionalParameter["isValid"];
          }
        }
      ]
    });
  }

  async ngOnInit() {
    await this.initFileList();

    this.uploader.onWhenAddingFileFailed = async (
      uploadingFile: FileLikeObject
    ) => {
      this.uploadInputs.map((p) => (p.nativeElement.value = ""));
      let messageKey = messageEnum.others;
      if (uploadingFile.size > this.uploader.options.maxFileSize) {
        messageKey = messageEnum.exceedSize;
      } else if (uploadingFile.type !== allowedMimeType.PDF) {
        messageKey = messageEnum.unsupportType;
      } else if (!this.uploader.options.additionalParameter["isValid"]) {
        messageKey = messageEnum.unavailableName;
      }

      await this.dialogService.dialog(
        messageKey === messageEnum.unavailableName
          ? messages[messageKey] +
          this.uploader.options.additionalParameter["triggerName"]
          : messages[messageKey],
        ButtonCommands.Ok,
        DialogType.alert,
        "Fail"
      );
    };

    this.uploader.onAfterAddingAll = async (uploadedFiles: FileItem[]) => {
      this.uploadInputs.map((p) => (p.nativeElement.value = ""));
      this.uploader.queue = this.uploader.queue.slice(
        1,
        this.uploader.queue.length
      );

      const currentFile = this.fileList.find(
        (p) => p.uploadName === uploadedFiles[0]._file.name
      );
      const uploadResult = await this.uploadTaxFile(uploadedFiles[0]);
      uploadResult.data.map((p) => {
        if (p.OriginalName === currentFile.uploadName) {
          currentFile.uploadTime = moment(moment.now()).format(
            "MM-DD-YYYY HH:mm:ss"
          );
          currentFile.bucketKey = p.BucketKey;
        }
      });

      this.dialogService.dialog(
        messages[messageEnum.success],
        ButtonCommands.Ok,
        DialogType.success,
        "Success"
      );
    };

    this.loadingService.closeLoading();
  }

  private async initFileList() {
    const url = `${environment.gateway}/buyer/tax/getuploadtime`;
    this.loadingService.openLoading(url);

    const initalFileList = [
      {
        fileName: "W-8BEN.PDF",
        uploadName: fileEnum.fw8ben,
        uploadTime: "",
        bucketKey: `${preBucketKey}/${fileEnum.fw8ben}`,
      },
      {
        fileName: "W-8BEN-E.PDF",
        uploadName: fileEnum.fw8bene,
        uploadTime: "",
        bucketKey: `${preBucketKey}/${fileEnum.fw8bene}`,
      },
      {
        fileName: "W-8IMY.PDF",
        uploadName: fileEnum.w8imy,
        uploadTime: "",
        bucketKey: `${preBucketKey}/${fileEnum.w8imy}`,
      },
      {
        fileName: "W9.PDF",
        uploadName: fileEnum.W9,
        uploadTime: "",
        bucketKey: `${preBucketKey}/${fileEnum.W9}`,
      },
    ];

    const s3Result = await this.getTaxUploadTime(url);
    if (s3Result.isSuccess && !!s3Result.data) {
      const s3Metadata = s3Result.data;
      for (const key in s3Metadata) {
        if (Object.prototype.hasOwnProperty.call(s3Metadata, key)) {
          const tempInfo = initalFileList.find((p) => p.uploadName === key);
          if (s3Metadata[key]) {
            tempInfo.uploadTime = moment(s3Metadata[key]).format(
              "MM-DD-YYYY HH:mm:ss"
            );
          } else {
            tempInfo.bucketKey = "";
          }
        }
      }
    }

    Object.assign(this.fileList, initalFileList);
  }

  private async uploadTaxFile(file: FileItem): Promise<any> {
    const url = `${environment.gateway}/buyer/tax/uploadtax/tax`;
    this.loadingService.openLoading(url);
    const formData: FormData = new FormData();
    formData.append("files", file._file, file._file.name);
    const result = await this.httpService.PostPromise<any>(url, formData);
    this.loadingService.closeLoading();

    return result;
  }

  private getTaxUploadTime(url: string): Promise<any> {
    return this.httpService.PostPromise(url, {
      tax: {
        [fileEnum.fw8ben]: `${preBucketKey}/${fileEnum.fw8ben}`,
        [fileEnum.fw8bene]: `${preBucketKey}/${fileEnum.fw8bene}`,
        [fileEnum.w8imy]: `${preBucketKey}/${fileEnum.w8imy}`,
        [fileEnum.W9]: `${preBucketKey}/${fileEnum.W9}`,
      },
    });
  }

  uploadFile(fileName: string) {
    this.uploader.options.additionalParameter["triggerName"] = fileName;
  }

  downloadFile(fileName: string) {
    const bucketKey: string = this.fileList.find(
      (p) => p.uploadName === fileName
    ).bucketKey;
    if (!bucketKey) {
      this.dialogService.dialog(
        "There has no form template to be downloaded, please upload a pdf template firstly.",
        ButtonCommands.Ok,
        DialogType.alert,
        "Fail"
      );
      return void 0;
    }

    const access_token = `Bearer ${this.authService.passport.access_token}`;
    const roleCode: string = this.authService.passport.buyer.RoleCode;
    const data: string = strToHexCharCode(
      `key=${bucketKey}&Authorization=${access_token}&state=${environment.role}&role=${roleCode}`
    );
    const url = `${environment.gateway}/buyer/file/download`;
    this.loadingService.openLoading(url);

    this.httpService
      .PostBlobPromise(url, { fileCode: data }, "blob")
      .then((blobData: BlobPart) => {
        const blob = new Blob([blobData]);
        if ("msSaveOrOpenBlob" in navigator) {
          window.navigator.msSaveOrOpenBlob(blob, fileName);
        } else {
          const link = document.createElement("a");
          link.setAttribute("href", window.URL.createObjectURL(blob));
          link.setAttribute("download", fileName);
          link.style.visibility = "hidden";

          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }

        this.loadingService.closeLoading();
      })
      .catch((err) => {
        // console.log(err);
        this.loadingService.closeLoading();
      });
  }

  navigateToDashboard() {
    this.router.navigate(["buyer"]);
  }

  showTooltip(name) {
    this.toolTipName = name;
  }
}
