import { Subject } from "rxjs";
import { filter } from "rxjs/operators";

class Event {
  constructor(public name: string, public params: any) {}
}

export class EventBus {
  private subject: Subject<any>;

  constructor() {
    this.subject = new Subject();
  }

  emit(eventName: string, params: any) {
    this.subject.next(new Event(eventName, params));
  }

  on(eventName: string, callback: (params: any) => void) {
    const sub = this.subject
      .pipe(
        filter((event: Event): boolean => {
          return event.name === eventName;
        })
      )
      .subscribe((event: Event) => {
        callback(event.params);
      });
    return sub;
  }
}
