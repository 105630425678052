import {
  AP_ReviewStatus,
  SupplierStatus,
  LoadingUrlMapping,
} from "./../../../../../interfaces/mapping";
import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { SupplierInfoType } from "../../../../../interfaces/supplier";
import { ProfileService } from "src/app/modules/supplier/services/profile.service";
import { SupplierService } from "src/app/services/supplier.service";
import {
  ButtonCommands,
  DialogType,
  DialogService,
} from "src/app/services/dialog.service";
import { AuthService } from "src/app/services/auth/auth.service";
import { SupplierDeclaration } from "src/app/interfaces/supplierProfile";
import { cloneDeep, trim } from "src/app/utils";
import VMController from "src/app/interfaces/vm";
import { CanComponentDeactivate } from "src/app/can-deactivate.guard";
import { CommonService } from "src/app/services/common.service";
import { environment } from "src/environments/environment";
import { LoadingService } from "src/app/services/loading.service";
import { supplierGeographical } from "src/app/shared/shared";
import {
  ProcessNames,
  SetupProcess,
  AllTaskKey,
  MigrateProcess,
} from "src/app/interfaces/workflow";
import { MetadataService } from "src/app/services/metadata.service";
import { DynamicContext } from "src/app/dynamic-components/interfaces/DynamicContext";
import { DynamicFormComponent } from "src/app/dynamic-components/components/dynamic-form/dynamic-form.component";
import { ComponentConfig } from "src/app/dynamic-components/ComponentConfig";
import { DynamicViewComponent } from "src/app/dynamic-components/components/dynamic-view/dynamic-view.component";
import { SupplierCommonService } from "../../../services/supplierCommon.service";
import { LanguageService } from "../../../../../dynamic-components/utils/language.service";
import { LaunchDarklyService } from "src/app/services/launch-darkly.service";
import { SupplierProfile } from "../../../../../interfaces/supplierProfile";
@Component({
  selector: "app-profile-declaration",
  templateUrl: "./declaration.component.html",
  styleUrls: ["./declaration.component.sass"],
})
export class DeclarationComponent implements OnInit, CanComponentDeactivate {
  // private supplierStatus: any
  AP_ReviewStatus = AP_ReviewStatus;
  supplierInfoType = SupplierInfoType;
  isDisabledSaveBtn = false;
  isNew = true;
  uploadingFiles?: Array<any> = [];
  isNewVersion:boolean=true;
  // isResubmitDisabled = true
  // apReviewStatus: string
  context: DynamicContext;
  @ViewChild("dynamicForm") dynamicForm: DynamicFormComponent;
  @ViewChild("dynamicView") dynamicView: DynamicViewComponent;
  get declaration(): SupplierDeclaration {
    return this.profileService.supplierModel.SupplierProfile
      .SupplierDeclaration;
  }
  get isView() {
    if (!this.profileService.isView) {
      this.getConfigsInfo = this.originalConfig;
    }
    return this.profileService.isView;
  }
  getConfigsInfo: ComponentConfig[] = [];
  originalConfig: ComponentConfig[] = [];
  vm: VMController<SupplierDeclaration> = new VMController();

  constructor(
    private router: Router,
    private profileService: ProfileService,
    private supplierService: SupplierService,
    private commonService: CommonService,
    private dialogService: DialogService,
    private authService: AuthService,
    private loadingService: LoadingService,
    private metadataService: MetadataService,
    private languageService: LanguageService,
    private supplierCommonService: SupplierCommonService,
    private launchDarklyService: LaunchDarklyService
  ) {
    this.profileService.stepChangeEvent.emit(5);
    this.launchDarklyService.ldChange.subscribe(any => {
      console.log("Trigger refresh data")
      this.refreshToggle()
  })
  }
  refreshToggle():void{
    this.isNewVersion=this.launchDarklyService.getToggle(LaunchDarklyService.LD_ISNEWVERSION)==="true";
  }

  async ngOnInit() {
    this.loadingService.showLoading();
    this.context = new DynamicContext();
    // load current login user's task
    await this.profileService.loadUserTasks();
    this.profileService.current_step = 5;
    await this.supplierCommonService.SetContextValue(this.context);
    this.getConfigsInfo = await this.metadataService.getPageConfiguration(
      "supplier-profile-step5"
    );
    this.originalConfig = cloneDeep(this.getConfigsInfo);
    this.commonService.ExitEvent.subscribe((e) => {
      this.vm.setOriginal(this.dynamicForm.originalValues());
      this.vm.setCurrent(this.dynamicForm.values());
      if (this.vm.isDirty()) {
        e.preventDefault();
        // Chrome requires returnValue to be set
        e.returnValue = "";
      }
    });
    this.loadingService.hideLoading();
    // if (!this.isView) {
    //   this.dynamicForm.subscribeToFormChange(() => {
    //     this.isResubmitDisabled = (JSON.stringify(this.dynamicForm.originalValues()) === JSON.stringify(this.dynamicForm.values()))
    //   })
    // }
    // this.apReviewStatus = this.profileService.supplierModel.Mapping.AP_reviewStatus
  }

  async canDeactivate() {
    if (this.isView) {
      return true;
    }
    this.vm.setOriginal(this.dynamicForm.originalValues());
    this.vm.setCurrent(this.dynamicForm.values());
    if (this.vm.isDirty()) {
      const result = await this.dialogService.saveConfirmLastPage();
      if (result === "save") {
        if (
          this.profileService.getUserTaskByKey(
            ProcessNames.generic_supplierSetup,
            SetupProcess.profile_draft
          )
        ) {
          if (!(await this.submitDraft())) {
            return false;
          }
        } else if (
          this.profileService.getUserTaskByTaskKey(AllTaskKey.SST_rejectToBuyer)
        ) {
          if (!(await this.resubmitForm())) {
            return false;
          }
        } else {
          if (!(await this.changeRequest())) {
            return false;
          }
        }
      }
      if (result === "cancel") {
        return false;
      }
    }

    return true;
  }

  // addAttachmentInfo(fileList) {
  //   this.declaration.AddDeclarationAttachments = fileList
  //   this.isDisabledSaveBtn = false
  // }

  // get isAllFileUploaded() {
  //   const addDeclarationAttachmentsList = []
  //   if (this.declaration.AddDeclarationAttachments && this.declaration.AddDeclarationAttachments.length > 0) {
  //     this.declaration.AddDeclarationAttachments.forEach(item => {
  //       if (item.isUploading) {
  //         addDeclarationAttachmentsList.push(item)
  //       }
  //     })
  //   }
  //   return addDeclarationAttachmentsList.length !== 0
  // }

  // validate() {
  //   this.isValidated = true
  //   if (!this.declaration.SubmittedBy
  //     || !this.declaration.IsConflictOfInterest
  //     || !this.declaration.IsConflictOfInterestC
  //     || !this.declaration.IsInformationCorrect
  //     || !this.declaration.IsAgreed
  //     || this.isAllFileUploaded
  //     || (this.declaration.IsAgreed === 'N' && !this.declaration.AddDeclarationAttachments.length)
  //   ) {
  //     return false
  //   }
  //   return true
  // }

  async btnNext() {
    this.router.navigate([
      `supplier/profile/${this.profileService.supplierCode}/step6`,
    ]);
  }

  async SaveAndNavigate() {
    localStorage.removeItem('generalsaved');
    localStorage.removeItem('organizationsaved');
    localStorage.removeItem('financialsaved');
    localStorage.removeItem('compliancesaved');
    this.submitDraft().then((data: boolean) => {
      if (data) {
        this.vm.saved();
        this.profileService.supplierModel.Mapping.profile.isUpdated = true;
        this.loadingService.closeLoading();
        this.loadingService.hideLoading();
        this.router.navigate([
          `supplier/profile/${this.profileService.supplierCode}/step6`,
        ]);
      }
    });
  }

  get showResubmitBtn() {
    // read only
    if (this.isView) {
      return false;
      // reject to supplier
    } else if (
      this.profileService.getUserTaskByTaskKey(AllTaskKey.SST_rejectToSupplier)
    ) {
      return true;
      // migrate profile draft
    } else if (
      this.profileService.getUserTaskByKey(
        ProcessNames.generic_supplierMigrateSetup,
        MigrateProcess.profile_draft
      )
    ) {
      return true;
      // supplier change request
    } else if (
      this.profileService.supplierModel.Mapping.status ===
      SupplierStatus.onboarded
    ) {
      return true;
    } else {
      return false;
    }
    // workflow comments: remove old verify
  }

  get showSaveBtn() {
    // read only
    if (this.isView) {
      return false;
      // supplier setup process: profile draft
    } else if (
      this.profileService.getUserTaskByKey(
        ProcessNames.generic_supplierSetup,
        SetupProcess.profile_draft
      )
    ) {
      return true;
    } else {
      return false;
    }

    // workflow comments: remove old verify
    // return !this.isView && !(this.apReviewStatus === AP_ReviewStatus.SST_reject) && !(this.profileService.supplierModel.Mapping.status === 'Onboarded')
  }
  validate() {
    return this.dynamicForm.valide();
  }
  async resubmit() {
    this.context.changeSubmitState();
    this.dynamicForm.removeFormTouch();
    if (!this.validate()) {
      return;
    }
    // resubmit
    if (
      this.profileService.getUserTaskByTaskKey(AllTaskKey.SST_rejectToSupplier)
    ) {
      this.resubmitForm().then((data: boolean) => {
        if (data) {
          this.vm.saved();
          this.dialogService
            .dialog(
              this.languageService.getValue(
                "static.supplier.components.declaration.change_request"
              ),
              ButtonCommands.Ok,
              DialogType.success,
              this.languageService.getValue(
                "static.supplier.components.declaration.please_note"
              )
            )
            .then((result: boolean) => {
              this.router.navigate([`supplier/landing`]);
            });
        }
      });
    } else if (
      this.profileService.getUserTaskByKey(
        ProcessNames.generic_supplierMigrateSetup,
        MigrateProcess.profile_draft
      )
    ) {
      this.submitMigrateDraft().then((data: boolean) => {
        if (data) {
          this.vm.saved();
          this.dialogService
            .dialog(
              this.languageService.getValue(
                "static.supplier.components.declaration.change_request"
              ),
              ButtonCommands.Ok,
              DialogType.success,
              this.languageService.getValue(
                "static.supplier.components.declaration.please_note"
              )
            )
            .then((result: boolean) => {
              this.router.navigate([`supplier/landing`]);
            });
        }
      });
      // supplier change request
    } else {
      this.changeRequest().then((data: boolean) => {
        if (data) {
          this.vm.saved();
          this.dialogService
            .dialog(
              this.languageService.getValue(
                "static.supplier.components.declaration.change_request"
              ),
              ButtonCommands.Ok,
              DialogType.success,
              this.languageService.getValue(
                "static.supplier.components.declaration.please_note"
              )
            )
            .then((result: boolean) => {
              this.router.navigate([`supplier/landing`]);
            });
        }
      });
    }
  }

  // resubmit request form
  async resubmitForm(): Promise<boolean> {
    this.context.changeSubmitState();
    this.dynamicForm.removeFormTouch();
    if (!this.validate()) {
      return false;
    }
    await this.updateSupplierMapping();
    // get executable task GUID: taskInstId
    this.profileService.getResubmitTask();
    if (!this.profileService.supplierModel.Mapping.executeTask) {
      return false;
    }
    if (this.isNewVersion) {
      this.bindDataToSupplierProfileModel();
      await this.saveNewresubmit();
    } else {
      await this.saveExistingresubmit();
    }
    return true;
  }

  async saveExistingresubmit() {
    const saveUrl = `${environment.gateway}${LoadingUrlMapping.resubmitSupplierProfile}`;
    this.loadingService.openLoading(saveUrl);
    await this.profileService.resubmitForm();
  }
  async saveNewresubmit() {
    const saveUrl = `${environment.supplierGateway}${LoadingUrlMapping.supplierProfileResubmit}`;
    this.loadingService.openLoading(saveUrl);
    await this.profileService.newresubmitForm();
    this.loadingService.closeLoading()
  }

  bindDataToSupplierProfileModel() {
    this.profileService.createSupplierProfileModel();
    this.profileService.supplierProfileModel.SupplierProfile.SupplierDeclaration = this.profileService.supplierModel.SupplierProfile.SupplierDeclaration;
    this.profileService.supplierProfileModel.Mapping = this.profileService.supplierModel.Mapping;
    let initalSupplierModel: SupplierDeclaration = trim(this.dynamicForm.originalValues());
    this.profileService.supplierProfileModel.ChangeHistory = this.commonService.getChangeHistory(initalSupplierModel, this.profileService.supplierProfileModel.SupplierProfile.SupplierDeclaration, "SupplierDeclaration")
  }

  // migrate submit
  async submitMigrateDraft(): Promise<boolean> {
    this.context.changeSubmitState();
    this.dynamicForm.removeFormTouch();
    if (!this.validate()) {
      return false;
    }
    await this.updateSupplierMapping();
    // get executable task GUID: taskInstId
    this.profileService.getExecuteTask(
      ProcessNames.generic_supplierMigrateSetup,
      MigrateProcess.profile_draft
    );
    if (!this.profileService.supplierModel.Mapping.executeTask) {
      return false;
    }
    const saveUrl = `${environment.gateway}${LoadingUrlMapping.completeMigrateProfileDraft}`;
    this.loadingService.openLoading(saveUrl);
    await this.profileService.submitMigrateDraft();
    return true;
  }

  // resubmit request form
  async changeRequest(): Promise<boolean> {
    this.context.changeSubmitState();
    this.dynamicForm.removeFormTouch();
    if (!this.validate()) {
      return false;
    }
    await this.updateSupplierMapping();
    if (this.isNewVersion) {
      this.bindDataToSupplierProfileModel();
      await this.newChangeRequestform();
    } else {
      await this.existingChangeRequestform();
    }
    return true;
  }

  async newChangeRequestform(){
    const saveUrl = `${environment.supplierGateway}${LoadingUrlMapping.newRequestChangeSupplierProfile}`;
    this.loadingService.openLoading(saveUrl);
    await this.profileService.newchangeRequestForm(),
    this.loadingService.closeLoading()
  }

  async existingChangeRequestform(){
    const saveUrl = `${environment.gateway}${LoadingUrlMapping.requestChangeSupplierProfile}`;
    this.loadingService.openLoading(saveUrl);
    await this.profileService.changeRequestForm();
  }

  // save draft request form
  async submitDraft(): Promise<boolean> {
    this.context.changeSubmitState();
    this.dynamicForm.removeFormTouch();
    if (!this.validate()) {
      return false;
    }
    await this.updateSupplierMapping();
    // get executable task GUID: taskInstId
    this.profileService.getExecuteTask(
      ProcessNames.generic_supplierSetup,
      AllTaskKey.profile_draft
    );
    if (!this.profileService.supplierModel.Mapping.executeTask) {
      return false;
    }
    if (this.isNewVersion) {
      this.bindDataToSupplierProfileModel();
      const saveUrl = `${environment.supplierGateway}${LoadingUrlMapping.completeProfileDraft}`;
      this.loadingService.openLoading(saveUrl);
      await this.profileService.submitDraft();
      return true;
    } else {
      const saveUrl = `${environment.gateway}${LoadingUrlMapping.completeProfileDraft}`;
      this.loadingService.openLoading(saveUrl);
      await this.profileService.submitDraftForExisting();
      return true;
    }
  }

  // update supplier mapping info
  private async updateSupplierMapping() {
    this.profileService.supplierModel.SupplierProfile.SupplierDeclaration =
      trim(this.dynamicForm.values());
    if (this.profileService.profile_step < this.profileService.current_step) {
      const now = new Date();
      this.profileService.profile_step = 5;
      this.profileService.supplierModel.Mapping.profile.completedTime =
        now.toUTCString();
      this.authService.passport.supplier.Mapping =
        this.profileService.supplierModel.Mapping;
    }
  }
}
