import { OnBoardingService } from "src/app/modules/buyer/services/onboarding.service";
import { Pipe, PipeTransform } from "@angular/core";
import { CommonService } from "src/app/services/common.service";
import { isNgTemplate } from "@angular/compiler";
import { DictionaryService } from "src/app/services/dictionary.service";

@Pipe({
  name: "dropDownValue",
})
export class DropDownValuePipe implements PipeTransform {
  resultDictionary: any;
  constructor(private dictionaryService: DictionaryService) {}
  /// targetDictionary: special parameter for the form in view mode
  /// targetForm and targetField: special parameters for the Onboarding Form and History
  transform(
    value: any,
    targetDictionary?: any,
    targetForm?: string,
    targetField?: string,
    fieldType?: string
  ): any {
    const result: any = this.dictionaryService.getDictionaryFromCache();
    let formFields, fieldItems, fieldValue: any;
    if (fieldType === "Drop Down" || fieldType === "dropdown") {
      switch (targetForm) {
        case "Requestor Details":
          formFields = result.filter(
            (form) => form.Code === "requestor-details"
          )[0].Items;
          if (targetField === "SpendCategoryGroup") {
            fieldItems = result.filter(
              (items) => items.Code === "SpendCategoryGroup"
            )[0].Items;
          } else {
            fieldItems = formFields.filter(
              (field) => field.Code === targetField
            )[0].Items;
          }
          if (fieldItems.filter((fieldItem) => fieldItem.Code === value)[0]) {
            fieldValue = fieldItems.filter(
              (fieldItem) => fieldItem.Code === value
            )[0].Text;
          } else {
            fieldValue = "";
          }

          return fieldValue;
        case "Supplier & Spend Details":
        case "Spend & Commodity Related":
          formFields = result.filter(
            (form) => form.Code === "requestor-form"
          )[0].Items;
          switch (targetField) {
            case "SpendCommodityGroup":
              fieldItems = formFields.filter(
                (field) => field.Code === "SpendCommodityGroup"
              )[0].Items;
              break;
            case "SpendCommodityGroupNA":
              fieldItems = formFields.filter(
                (field) => field.Code === "SpendCommodityGroupNA"
              )[0].Items;
              break;
            case "SpendCommodityGroupAsen":
              fieldItems = formFields.filter(
                (field) => field.Code === "SpendCommodityGroupAsen"
              )[0].Items;
              break;
            case "SpendCommodityGroupANZ":
              fieldItems = formFields.filter(
                (field) => field.Code === "SpendCommodityGroupANZ"
              )[0].Items;
              break;
              case "SpendCommodityGroupGreaterChina":
              fieldItems = formFields.filter(
                (field) => field.Code === "SpendCommodityGroupGreaterChina"
              )[0].Items;
              break;
              case "SpendCommodityGroupTRorRS":
                fieldItems = formFields.filter(
                  (field) => field.Code === "SpendCommodityGroupTRorRS"
                )[0].Items;
                break;
            case "HaveSupplier":
              fieldItems = formFields.filter(
                (field) => field.Code === "SpendCategoryGroup"
              )[0].Items;
              break;
            case "TotalSpendOfSupplier":
              fieldItems = formFields.filter(
                (field) => field.Code === "EstimatedSpend"
              )[0].Items;
              break;
            case "SupplierIsAccentureClient":
              fieldItems = formFields.filter(
                (field) => field.Code === "SpendSubCategory"
              )[0].Items;
              break;
            case "OnetimeOrRecurringPurchases":
              fieldItems = formFields.filter(
                (field) => field.Code === "OnetimeOrRecurringpurchases"
              )[0].Items;
              break;
            default:
              fieldItems = formFields.filter(
                (field) => field.Code === targetField
              )[0].Items;
              break;
          }
          fieldValue = fieldItems.filter(
            (fieldItem) => fieldItem.Code === value
          )[0].Text;
          return fieldValue;
        case "Risk Assessment":
        case "BI/BDA":
        case "Information Security & Data Protection":
          switch (targetField) {
            case "InformationSecurity_1j":
              fieldItems = result.filter(
                (form) => form.Code === "BankCountry"
              )[0].Items;
              fieldValue = fieldItems.filter(
                (fieldItem) => fieldItem.Code === value
              )[0].Text;
              break;
            default:
              targetField = "dp_" + targetField;
              formFields = result.filter(
                (form) => form.Code === "RiskAssessment"
              )[0].Items;
              fieldItems = formFields.filter(
                (field) => field.Code === targetField
              )[0].Items;
              fieldValue = fieldItems.filter(
                (fieldItem) => fieldItem.Code === value
              )[0].Text;
              break;
          }
          return fieldValue;

        case "Declaration":
          targetField = "dp_" + targetField.toLowerCase();
          formFields = result.filter((form) => form.Code === "Declaration")[0]
            .Items;
          fieldItems = formFields.filter(
            (field) => field.Code === targetField
          )[0].Items;
          fieldValue = fieldItems.filter(
            (fieldItem) => fieldItem.Code === value
          )[0].Text;
          return fieldValue;

        case "General Information":
        case "General":
          switch (targetField) {
            case "Country":
              formFields = result.filter(
                (form) => form.Code === "supplier-step2"
              )[0].Items;
              fieldItems = formFields.filter(
                (form) => form.Code === "Country"
              )[0].Items;
              fieldValue = fieldItems.filter(
                (fieldItem) => fieldItem.Code === value
              )[0].Text;
              break;
            default:
              fieldItems = result.filter(
                (field) => field.Code === targetField
              )[0].Items;
              fieldValue = fieldItems.filter(
                (fieldItem) => fieldItem.Code === value
              )[0].Text;
              break;
          }
          // fieldItems = result.filter(field => field.Code === targetField)[0].Items
          // fieldValue = fieldItems.filter(fieldItem => fieldItem.Code === value)[0].Text
          return fieldValue;
        case "Registration Details":
        case "Organization Size & Type":
        case "Organization":
          formFields = result.filter(
            (form) => form.Code === "supplier-step2"
          )[0].Items;
          switch (targetField) {
            case "SupplierRegisteredCountry":
              fieldItems = formFields.filter(
                (field) => field.Code === "Country"
              )[0].Items;
              break;
            case "BusinessRegistrationType":
              const subFieldItems = formFields.filter(
                (field) => field.Code === "RegistrationType"
              )[0].Items;
              fieldItems = subFieldItems.filter(
                (field) =>
                  field.Items.filter((subfield) => subfield.Code === value)[0]
              )[0].Items;
              break;
            case "IsSupplierAnIndividualOrCompany":
              fieldItems = formFields.filter(
                (field) => field.Code === "IndividualorCompany"
              )[0].Items;
              break;
            case "TypeOfCompany":
              fieldItems = formFields.filter(
                (field) => field.Code === "CompanyType"
              )[0].Items;
              break;
            case "SupplierAnnualRevenue":
              fieldItems = formFields.filter(
                (field) => field.Code === "AnnualRevenue"
              )[0].Items;
              break;
            case "BusinessSize":
              fieldItems = formFields.filter(
                (field) => field.Code === "PeopleNo"
              )[0].Items;
              break;
            case "LongOfSupplier":
              fieldItems = formFields.filter(
                (field) => field.Code === "GeographiesServed"
              )[0].Items;
              break;
          }
          fieldValue = fieldItems.filter(
            (fieldItem) => fieldItem.Code === value
          )[0].Text;
          return fieldValue;
        case "Ordering Method":
        case "Payment & Banking Information":
        case "Financial":
          fieldItems = result.filter((field) => field.Code === targetField)[0]
            .Items;
          fieldValue = fieldItems.filter(
            (fieldItem) => fieldItem.Code === value
          )[0].Text;
          return fieldValue;
        case "Diversity & Inclusion":
        case "Human Rights and Employment Standards":
        case "Environmental Awareness":
        case "Compliance":
          formFields = result.filter(
            (form) => form.Code === "supplier-step4"
          )[0].Items;
          switch (targetField) {
            case "SignatureInternationalStandards":
              fieldItems = formFields.filter(
                (field) => field.Code === "standardsList"
              )[0].Items;
              break;
            case "ContractTermsProvideToSuppliers":
              fieldItems = formFields.filter(
                (field) => field.Code === "provideToSuppliersList"
              )[0].Items;
              break;
            case "UnitedNationsGlobalCompact":
              fieldItems = formFields.filter(
                (field) => field.Code === "unitCompactList"
              )[0].Items;
              break;
            case "PreferredOrderingMethod":
              fieldItems = result.filter(
                (field) => field.Code === "PreferredOrderingMethod"
              )[0].Items;
              break;
            default:
              fieldItems = formFields.filter(
                (field) => field.Code === targetField
              )[0].Items;
              break;
          }
          fieldValue = fieldItems.filter(
            (fieldItem) => fieldItem.Code === value
          )[0].Text;
          return fieldValue;
      }
    } else if (fieldType === "Multiple Choice" || fieldType === "multiple") {
      // let formFields, fieldItems, fieldValue: any
      switch (targetForm) {
        case "Requestor Details":
          formFields = result.filter(
            (form) => form.Code === "requestor-details"
          )[0].Items;
          if (targetField === "CompanyCode") {
            fieldItems = result.filter(
              (items) => items.Code === "CompanyCode"
            )[0].Items;
            fieldValue = "";
            value =
              value.changingThisBreaksApplicationSecurity === undefined
                ? value
                : value.changingThisBreaksApplicationSecurity;
            if (Array.isArray(value) || value.indexOf(",") > 0) {
              const companyValue = Array.isArray(value)
                ? value
                : value.split(",");
              companyValue.forEach((v, i) => {
                fieldValue += fieldItems.filter(
                  (fieldItem) => fieldItem.Code === v
                )[0].Text;
                if (i < value.length - 1) {
                  fieldValue += ", ";
                }
              });
            } else {
              fieldValue = fieldItems.filter(
                (fieldItem) => fieldItem.Code === value
              )[0].Text;
            }
          } else if (
            targetField === "SupplierCountry" ||
            targetField === "Country"
          ) {
            fieldValue = "";
            fieldItems = result.filter((items) => items.Code === "Country")[0]
              .Items;
            if (fieldItems !== undefined && fieldItems.length > 0) {
              value =
                value.changingThisBreaksApplicationSecurity === undefined
                  ? value
                  : value.changingThisBreaksApplicationSecurity;
              if (Array.isArray(value) || value.indexOf(",") > 0) {
                const v_Array = Array.isArray(value) ? value : value.split(",");
                v_Array.forEach((v, i) => {
                  fieldValue += fieldItems.filter(
                    (fieldItem) => fieldItem.Code === v
                  )[0].Text;
                  if (i < value.length - 1) {
                    fieldValue += ", ";
                  }
                });
              } else {
                fieldValue = fieldItems.filter(
                  (fieldItem) => fieldItem.Code === value
                )[0].Text;
              }
            }
          }
          break;
        default:
          fieldValue = value;
      }
      return fieldValue;
    } else if (fieldType === "") {
      return targetDictionary.filter((item) => item.Code === value)[0].Text;
    } else {
      return value;
    }
  }
}
