import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { SharedModule } from "src/app/shared/shared.module";
import { Routes, RouterModule } from "@angular/router";

import { HeaderComponent } from "./views/header/header.component";
import { LayoutComponent } from "./views/layout/layout.component";
import { ProfileOrganizationComponent } from "./views/profile/organization/organization.component";
import { ProfileGeneralComponent } from "./views/profile/general/general.component";
import { FinancialComponent } from "./views/profile/financial/financial.component";
import { DeclarationComponent } from "./views/profile/declaration/declaration.component";
import { ProfileComponent } from "./views/profile/profile.component";
import { SupplierService } from "../../services/supplier.service";
import { ProfileService } from "./services/profile.service";
import { ProfileResolver } from "./services/profile.resolver.service";
import { ComplianceComponent } from "./views/profile/compliance/compliance.component";
import { EncryptedComponent } from "./views/profile/encrypted/encrypted.component";
import { SupplierGuardService } from "./supplier.guard";
import { OnboardingProcessComponent } from "./views/onboarding/process/onboarding-process-component";
import { OnboardingComponent } from "./views/onboarding/onboarding-component";
import { LandingComponent } from "./views/landing/landing.component";
import { LandingHeaderComponent } from "./views/landing-header/landing-header.component";
import { HistorySupplierComponent } from "./views/history/history-supplier/history-supplier.component";
import { AcceptModalSupplierComponent } from "./views/accept-modal-supplier/accept-modal-supplier.component";
import { AdminLayoutComponent } from "./views/management/admin-layout/admin-layout.component";
import { UsersComponent } from "./views/management/users/users.component";
import { SupplierHistoryLayoutComponent } from "./views/history/supplier-history-layout/supplier-history-layout.component";
import { UserEditComponent } from "./views/management/user-edit/user-edit.component";
import { SupplierManagementGuardService } from "./management.guard";
import { NgxPaginationModule } from "ngx-pagination";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { CanDeactivateGuard } from "src/app/can-deactivate.guard";
import { HelppageComponent } from "./views/helppage/helppage.component";
import { SupplierSetupProcessService } from "./services/supplierSetupProcess.service";
import { MigrateSetupProcessService } from "./services/migrateSetupProcess.service";
import { TranslateModule } from "@ngx-translate/core";

const routes: Routes = [
  {
    path: "supplier",
    canActivate: [SupplierGuardService],
    component: LayoutComponent,
    children: [
      {
        path: "landing",
        component: LandingComponent,
        children: [
          {
            path: ":key1/:key2/:mail/:supplierCode",
            component: LayoutComponent,
            canActivate: [SupplierGuardService],
            data: { title: 'landing'}
          },
        ],
      },
      {
        path: "helpPage",
        component: HelppageComponent,
        canActivate: [SupplierGuardService],
        data: { title: 'helpPage'}
      },
      {
        path: "history/:code",
        component: SupplierHistoryLayoutComponent,
        canActivate: [SupplierGuardService],
        children: [{ path: "", component: HistorySupplierComponent }],
        data: { title: 'helHistorySupplierpPage'}
      },
      {
        path: "profile/:code",
        component: ProfileComponent,
        canActivate: [SupplierGuardService],
        resolve: {
          SupplierStatus: ProfileResolver,
        },
        children: [
          {
            path: "step1",
            component: ProfileGeneralComponent,
            canDeactivate: [CanDeactivateGuard],
            data: { title: 'step1'}
          },
          {
            path: "step2",
            component: ProfileOrganizationComponent,
            canDeactivate: [CanDeactivateGuard],
            data: { title: 'step2'}
          },
          {
            path: "step3",
            component: FinancialComponent,
            canDeactivate: [CanDeactivateGuard],
            data: { title: 'step3'}
          },
          {
            path: "step4",
            component: ComplianceComponent,
            canDeactivate: [CanDeactivateGuard],
            data: { title: 'step4'}
          },
          {
            path: "step5",
            component: DeclarationComponent,
            canDeactivate: [CanDeactivateGuard],
            data: { title: 'step5'}
          },
          { path: "step6", component: EncryptedComponent, data: { title: 'step6'} },
        ],
      },
      {
        path: "onboarding",
        component: OnboardingComponent,
        data: { title: 'onboarding'},
        canActivate: [SupplierGuardService],
        children: [
          {
            path: "process/:supplierCode",
            component: OnboardingProcessComponent,
            data: { title: 'supplierCode'}
          },
        ],
      },
      {
        path: "management",
        canActivate: [SupplierManagementGuardService],
        component: AdminLayoutComponent,
        data: { title: 'management'},
        children: [
          { path: "users", component: UsersComponent },
          { path: "user-create", component: UserEditComponent },
          { path: "user-edit/:mode/:mail", component: UserEditComponent },
        ],
      },
    ],
  },
];

@NgModule({
  declarations: [
    LayoutComponent,
    ProfileComponent,
    ProfileOrganizationComponent,
    ProfileGeneralComponent,
    FinancialComponent,
    DeclarationComponent,
    HeaderComponent,
    ComplianceComponent,
    EncryptedComponent,
    OnboardingProcessComponent,
    OnboardingComponent,
    LandingComponent,
    LandingHeaderComponent,
    HistorySupplierComponent,
    AcceptModalSupplierComponent,
    AdminLayoutComponent,
    UsersComponent,
    SupplierHistoryLayoutComponent,
    UserEditComponent,
    HelppageComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    NgxPaginationModule,
    MatSlideToggleModule,
    RouterModule.forRoot(routes, { useHash: true }),
    TranslateModule,
  ],
  providers: [
    SupplierService,
    ProfileService,
    ProfileResolver,
    SupplierSetupProcessService,
    MigrateSetupProcessService,
  ],
  exports: [RouterModule],
})
export class SupplierModule {}
