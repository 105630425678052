import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { SupplierStatus } from "src/app/interfaces/mapping";
import { AuthService } from "src/app/services/auth/auth.service";
import { SupplierHeaderService } from "src/app/modules/supplier/services/header.service";
import { ProfileService } from "../../../services/profile.service";
import { SupplierSetupProcessService } from "src/app/modules/supplier/services/supplierSetupProcess.service";
import { MigrateSetupProcessService } from "src/app/modules/supplier/services/migrateSetupProcess.service";
import { LoadingService } from "src/app/services/loading.service";

@Component({
  selector: "app-onboarding-process",
  templateUrl: "./onboarding-process-component.html",
  styleUrls: ["./onboarding-process-component.sass"],
})
export class OnboardingProcessComponent implements OnInit {
  section1_show = true;
  section2_show = true;

  supplierCode: string;
  circleSteps = [];
  currTitleStep: any;

  eStatus = SupplierStatus;
  onboardingContentTemplate = "";
  supplierInfo: any = {};

  constructor(
    private route: ActivatedRoute,
    private profileService: ProfileService,
    private supplierSetupProcessService: SupplierSetupProcessService,
    private migrateSetupProcessService: MigrateSetupProcessService,
    public loadingService: LoadingService
  ) {}

  async ngOnInit() {
    this.loadingService.showLoading();
    this.clearStepsInfo();
    this.supplierCode = this.route.snapshot.paramMap.get("supplierCode");
    await this.profileService.loadSupplierProfile();
    if (this.profileService.supplierModel.Mapping.IsMigrated) {
      await this.loadMigrateSetupProcess();
    } else {
      await this.loadSupplierSetupProcess();
    }
    this.loadingService.hideLoading();
  }

  async loadSupplierSetupProcess() {
    await this.supplierSetupProcessService.loadSupplierSetupProcess();
    this.circleSteps = this.supplierSetupProcessService.circleSteps;
    this.currTitleStep = this.supplierSetupProcessService.currTitleStep;
  }

  async loadMigrateSetupProcess() {
    await this.migrateSetupProcessService.loadMigrateSetupProcess();
    this.circleSteps = this.migrateSetupProcessService.circleSteps;
    this.currTitleStep = this.migrateSetupProcessService.currTitleStep;
  }

  clearStepsInfo() {
    this.supplierSetupProcessService.clear();
    this.migrateSetupProcessService.clear();
  }
}
