import {
  Component,
  OnInit,
  AfterViewInit,
  HostListener,
  ElementRef,
  ViewChild,
} from "@angular/core";
import { BaseComponent } from "src/app/dynamic-components/components/base/base.component";
import { CommonService } from "src/app/services/common.service";
import { UntypedFormGroup, UntypedFormBuilder } from "@angular/forms";
import { Datasources } from "src/app/dynamic-components/datasource/Datasources";

@Component({
  selector: "app-phone",
  templateUrl: "./phone.component.html",
  styleUrls: ["./phone.component.sass"],
  host: {
    "[style.width]": "config.width",
  },
})
export class PhoneComponent extends BaseComponent implements OnInit,AfterViewInit {
  dictPhone: any;
  showTooltip: boolean;
  form: UntypedFormGroup;
  searchText: string;
  selectedText = "";
  datalist: any[] = [];
  showPanel = false;
  valueKey = "Code";
  textKey = "Text";
  @ViewChild("inputPanel") inputPanel: ElementRef;
  @ViewChild("dropPpanel") dropPpanel: ElementRef;
  constructor(private commonS: CommonService, private fb: UntypedFormBuilder) {
    super();
  }

  ngOnInit() {
    super.initErrorMessage();
    Datasources.loadData(
      this.config.fieldset[0],
      this.context,
      this.formGroup
    ).then((ds) => {
      this.dictPhone = ds;
      this.datalist = ds;
      this.refresh();
    });
  }
  estimateChooseOver(e, estimateSpendTooltip) {
    const TooltipEle = estimateSpendTooltip;
    if (estimateSpendTooltip) {
      if (
        e.clientX < TooltipEle.nativeElement.getBoundingClientRect().left ||
        e.clientX >
          TooltipEle.nativeElement.getBoundingClientRect().left +
            TooltipEle.nativeElement.offsetWidth -
            5 ||
        e.clientY < TooltipEle.nativeElement.getBoundingClientRect().top ||
        e.clientY >
          TooltipEle.nativeElement.getBoundingClientRect().top +
            TooltipEle.nativeElement.offsetHeight -
            5
      ) {
        this.showTooltip = false;
      }
    } else {
      this.showTooltip = false;
    }
  }
  ngAfterViewInit() {
    this.subscribeToValueChange(this.config.name, (v) => {
      this.onValueChange(v);
    });
  }
  onValueChange(v) {
    this.refresh();
  }
  onDatasourceLoaded(ds: any) {
    this.datalist = ds;
    this.dictPhone = ds;
    this.refresh();
  }
  togglePanel() {
    this.showPanel = !this.showPanel;
    this.searchText = "";
    this.filter("null");
  }
  
  chooseItem(event, item: any) {
    this.formGroup
      .get(this.config.name)
      .get(this.config.fieldset[0].name)
      .patchValue(item.Code, { onlySelf: true, emitEvent: true });
    this.showPanel = false;
    this.refresh();
  }
  filter(keyword: any) {
    if ("null" === keyword || !keyword.currentTarget.value) {
      this.searchText = "";
      this.dictPhone = this.datalist;
      //this.config.options = this.datalist
      return;
    }
    if (keyword.currentTarget.value) {
      this.searchText = keyword.currentTarget.value;
    }
    this.dictPhone = this.datalist.filter(
      (kv) => kv[this.textKey].indexOf(this.searchText) >= 0
    );
  }

  refresh() {
    this.selectedText = "";
    this.setDisableByExclusiveKey();
    this.selectedText = this.formGroup
      .get(this.config.name)
      .get(this.config.fieldset[0].name).value;
  }

  setDisableByExclusiveKey() {
    if (
      this.config.templateOptions &&
      this.config.templateOptions.exclusiveKey
    ) {
      const exclusiveKey = this.config.templateOptions.exclusiveKey;
      if (!this.value || this.value.length === 0) {
        this.config.options.forEach((item) => (item.disabled = false));
        return;
      }
      if (this.value.includes(exclusiveKey)) {
        const itemsToDisable = this.config.options.filter(
          (o) => o[this.valueKey] !== exclusiveKey
        );
        itemsToDisable.forEach((itd) => (itd.disabled = true));
      } else {
        const itemsToDisable = this.config.options.filter(
          (o) => o[this.valueKey] === exclusiveKey
        );
        itemsToDisable.forEach((itd) => (itd.disabled = true));
      }
    }
  }

  @HostListener("document:click", ["$event"])
  documentClick(event) {
    if (this.inputPanel && this.dropPpanel) {
      if (
        this.inputPanel.nativeElement.contains(event.target) ||
        this.dropPpanel.nativeElement.contains(event.target)
      ) {
        return;
      }
    }
    this.showPanel = false;
  }
}
