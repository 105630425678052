import { Component, OnInit, Input } from "@angular/core";
import { BaseComponent } from "src/app/dynamic-components/components/base/base.component";

@Component({
  selector: "app-collapse",
  templateUrl: "./collapse.component.html",
  host: {
    "[style.width]": "config.width",
  },
  styleUrls: ["./collapse.component.sass"],
})
export class CollapseComponent extends BaseComponent implements OnInit {
  isHide = false;
  arr = Array;

  ngOnInit() {
    this.isHide = this.config.templateOptions.isHide;
    this.context.on(this.config.id, (param) => {
      this[param.method]();
    });
  }

  open() {
    this.isHide = false;
  }

  toggle() {
    this.isHide = !this.isHide;
  }
}
