import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { json } from 'stream/consumers';

@Injectable({
  providedIn: 'root'
})
export class SupplierGeneralService {

  constructor() { }

  /**
   * 
   * @param classification 
   */
  isSupplierIndividual(classification: "individual" | "company"):boolean{
     const userRole: "buyer" | "supplier" = this.getUserRole();
     if(userRole == "buyer")
       return false
     else 
       return classification==="individual"
  }

  /**
   * 
   * @returns 
   */
  getUserRole(): "buyer" | "supplier" {
    return environment.role === "buyer" ? "buyer" : "supplier";
  }

  /**
   * 
   * @param v 
   */
  public setIsIndividual(v : boolean):void{
    localStorage.setItem("isIndividual", JSON.stringify(v));
  }

  /**
   * 
   * @returns 
   */
  public getIsIndividual():boolean{
    return JSON.parse(localStorage.getItem("isIndividual"))

}
  
}
