import { Component, OnInit } from "@angular/core";
import { BuyerService } from "src/app/services/buyer.service";
import {
  DialogService,
  ButtonCommands,
  DialogType,
} from "src/app/services/dialog.service";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { LanguageService } from "../../../../dynamic-components/utils/language.service";

@Component({
  selector: "app-users",
  templateUrl: "./users.component.html",
  styleUrls: ["./users.component.sass"],
})
export class UsersComponent implements OnInit {
  errorMessage = "Error Notification";
  itemsPerPage = 6;
  currentPage = 1;
  retryItems = [];

  constructor(
    private buyerService: BuyerService,
    private dialogService: DialogService,
    private languageService: LanguageService,
    private router: Router
  ) {}

  async ngOnInit() {
    this.searchResendEmails();
  }

  pageChange(event) {
    this.currentPage = event;
  }

  async searchResendEmails() {
    const retryItemsResp: any = await this.buyerService.listRetryEmail();
    retryItemsResp.data.map((item: any) => {
      item.LastRetryTimeMessage = this.formatDate(new Date(item.LastRetryTime));
    });
    this.retryItems = retryItemsResp.data.filter((item: any) => {
      setTimeout(() => {
        const usersdiv = document.getElementsByClassName("my-pagination")[0];
        if (usersdiv) {
          const userscontrol = usersdiv.childNodes[0];
          if (userscontrol) {
            const userstemplate = userscontrol.childNodes[0];
            if (userstemplate) {
              const usersul = userstemplate.childNodes[0];
              if (usersul) {
                for (let i = 0; i < usersul.childNodes.length; i++) {
                  let usersli = usersul.childNodes[i];
                  if (usersli.nodeName == 'LI') {
                    (usersli as any).role = "group";
                  }
                }
              }
            }
          }
        }
      });
      
      return item.ResendEmailInfo.EmailType !== this.errorMessage;
    });
  }

  formatDate(date: Date) {
    const cd = new Date(date);
    const day = cd.getDate() > 9 ? cd.getDate() : "0" + cd.getDate();
    const year = cd.getFullYear();
    const month =
      cd.getMonth() > 9 ? cd.getMonth() + 1 : "0" + (cd.getMonth() + 1);
    const hour = cd.getHours() > 9 ? cd.getHours() : "0" + cd.getHours();
    const minute =
      cd.getMinutes() > 9 ? cd.getMinutes() : "0" + cd.getMinutes();
    const seconds =
      cd.getSeconds() > 9 ? cd.getSeconds() : "0" + cd.getSeconds();
    return (
      day + "-" + month + "-" + year + " " + hour + ":" + minute + ":" + seconds
    );
  }

  async resendEmail(resendItem) {
    const result: any = await this.buyerService.resendEmail(resendItem);
    if (result.data.IsSuccess) {
      if (environment.languageSwitch && environment.role == "supplier") {
        await this.dialogService.dialog(
          this.languageService.getValue(
            "static.system.components.users.Successfully"
          ),
          ButtonCommands.Ok,
          DialogType.success
        );
      } else {
        await this.dialogService.dialog(
          "Resend Successfully",
          ButtonCommands.Ok,
          DialogType.success
        );
      }
    } else {
      if (environment.languageSwitch && environment.role == "supplier") {
        await this.dialogService.dialog(
          this.languageService.getValue(
            "static.system.components.users.Failed"
          ),
          ButtonCommands.Ok
        );
      } else {
        await this.dialogService.dialog("Resend Failed", ButtonCommands.Ok);
      }
    }
    if (environment.role === "buyer") {
      this.router.navigate(["/buyer"]);
    } else {
      this.router.navigate(["/supplier"]);
    }
  }
}
