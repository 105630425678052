import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-header-bar",
  templateUrl: "./header-bar.component.html",
  styleUrls: ["./header-bar.component.sass"],
})
export class HeaderBarComponent implements OnInit {
  @Input() enableClick = true;
  constructor(private router: Router) {}
  @ViewChild('targetElement') targetElement: ElementRef;
  ngOnInit() {}

  nav() {
    this.router.navigate(["system/users"]);
  }
  scrollToElement(event) {
    if(event.keyCode == 13){
      this.scrollToElementOnClick()
    }
  }
  scrollToElementOnClick() {
    const element = document.getElementById("targetElement");
    element.scrollIntoView({ behavior: "smooth" });
    element.setAttribute('tabindex','-1') // You can set tabindex in HTML too than in JS
    element.focus()
  }
}
