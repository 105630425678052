import { Component, OnInit } from "@angular/core";
import { SupplierService } from "../../../../../services/supplier.service";
import { OnBoardingService } from "../../../services/onboarding.service";
import { OnBoardingStatus } from "../../../../../interfaces/supplier";
import { Router } from "@angular/router";
import { DashBoardService } from "../../../services/dashboard.service";
import { SupplierCardStatus } from "src/app/interfaces/buyer";
import { environment } from "../../../../../../environments/environment";
import { AuthService } from "src/app/services/auth/auth.service";
import { getTSMCountryServedQuery } from "src/app/shared/shared";
@Component({
  selector: "app-completed",
  templateUrl: "./completed.component.html",
  styleUrls: ["./completed.component.sass"],
})
export class CompletedComponent implements OnInit {
  supplier: any = {};
  isFromTSM: boolean;
  isFromTsmStep5: boolean;
  TsmId: any = "";
  onBoardingStatus = OnBoardingStatus;
  onBoardingService: OnBoardingService;

  constructor(
    private _onBoardingService: OnBoardingService,
    private dashBoardService: DashBoardService,
    private router: Router,
    private authService: AuthService
  ) {
    this.onBoardingService = _onBoardingService;
    this.isFromTSM = this.onBoardingService.isFromTsm();
    this.isFromTsmStep5 = this.onBoardingService.isFromTsmStep5();
    this.dashBoardService.title = "Supplier Details";
    this.onBoardingService.stepChangeEvent.emit(5);
    this.onBoardingService.profileStage = "completed";
  }

  async ngOnInit() {
    // load supplierRequestForm
    await this.onBoardingService.loadSupplierRequestForm();
    this.isFromTSM = this.onBoardingService.isFromTsm();
    this.isFromTsmStep5 = this.onBoardingService.isFromTsmStep5();
    this.TsmId = this.onBoardingService.TsmId();
    this.onBoardingService.current_step = 4;
    if (
      this.onBoardingService.requestor_step <
      this.onBoardingService.current_step
    ) {
      this.onBoardingService.requestor_step = 4;
    }
    this.supplier = this.onBoardingService.supplierModel;
  }
  navigateTo(type) {
    if (type === "Suppliers") {
    } else if (type === "Dashboard") {
      this.router.navigate(["buyer"]);
    }
  }
  turnto() {
    const buyerTsmId = this.TsmId;
    
    // const url='http://tsm-frontend-dev.s3-website.us-east-2.amazonaws.com/#/supplier-detail/'+buyerTsmId+'?edit=true'
    const env = sessionStorage.getItem("tsmEnv");
    const urlparam =
      env === "dev"
        ? environment.relationshipdev_url
        : env === "perf"
        ? environment.relationshipperf_url
        : env === "hotfix"
        ? environment.relationshiphotfix_url
        : env === "stage"
        ? environment.relationshipstage_url
        : environment.relationship_url;
        const countryServed = getTSMCountryServedQuery(
          this.onBoardingService.supplierModel.Mapping.setUpCountry,
        );
        const redirectUrl =
        urlparam +
        this.onBoardingService.supplierModel.Mapping.TsmId+
        "?countryServed=" +
        countryServed +
        "&login_hint=" +
        this.authService.passport.profile.email
        console.debug(`Jump to TSM url: ${redirectUrl}`);
        window.location.href = redirectUrl;
  }
}

