// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.noti-body {
  background-color: #9FE7ED;
  height: 35px;
  color: #000;
  margin-top: 2rem;
  width: 540px;
  border-radius: 0.3rem;
  padding-left: 10px;
  position: relative;
  cursor: pointer;
}

.noti-content {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 500px;
  font-size: 1rem;
}

.noti-icon {
  color: orange;
  position: absolute;
  border-radius: 20px;
  background-color: white;
  top: -11px;
  left: -22px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
