// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ui-loading {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: rgba(242, 242, 242, 0.7);
}
.ui-loading .images {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100px;
}

.loading {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: rgba(242, 242, 242, 0.7);
}
.loading .images {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100px;
}

.colorfulPulse {
  width: 125px;
  height: 80px;
  margin: 23% auto;
}
.colorfulPulse span {
  display: inline-block;
  width: 10px;
  height: 40px;
  margin-left: 0.5rem;
  animation-name: scale;
  -webkit-animation-name: scale;
  -moz-animation-name: scale;
  -ms-animation-name: scale;
  -o-animation-name: scale;
  animation-duration: 1.2s;
  -webkit-animation-duration: 1.2s;
  -moz-animation-duration: 1.2s;
  -ms-animation-duration: 1.2s;
  -o-animation-duration: 1.2s;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  -ms-animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
}

span.item-1 {
  background: #764BA2;
}
span.item-2 {
  background: #6C4A9F;
}
span.item-3 {
  background: #6F4AA0;
}
span.item-4 {
  background: #56479A;
}
span.item-5 {
  background: #474597;
}
span.item-6 {
  background: #354393;
}
span.item-7 {
  background: #294191;
}

.item-1 {
  animation-delay: -1s;
  -webkit-animation-delay: -1s;
  -moz-animation-delay: -1s;
  -ms-animation-delay: -1s;
  -o-animation-delay: -1s;
}

.item-2 {
  animation-delay: -0.9s;
  -webkit-animation-delay: -0.9s;
  -moz-animation-delay: -0.9s;
  -ms-animation-delay: -0.9s;
  -o-animation-delay: -0.9s;
}

.item-3 {
  animation-delay: -0.8s;
  -webkit-animation-delay: -0.8s;
  -moz-animation-delay: -0.8s;
  -ms-animation-delay: -0.8s;
  -o-animation-delay: -0.8s;
}

.item-4 {
  animation-delay: -0.7s;
  -webkit-animation-delay: -0.7s;
  -moz-animation-delay: -0.7s;
  -ms-animation-delay: -0.7s;
  -o-animation-delay: -0.7s;
}

.item-5 {
  animation-delay: -0.6s;
  -webkit-animation-delay: -0.6s;
  -moz-animation-delay: -0.6s;
  -ms-animation-delay: -0.6s;
  -o-animation-delay: -0.6s;
}

.item-6 {
  animation-delay: -0.5s;
  -webkit-animation-delay: -0.5s;
  -moz-animation-delay: -0.5s;
  -ms-animation-delay: -0.5s;
  -o-animation-delay: -0.5s;
}

.item-7 {
  animation-delay: -0.4s;
  -webkit-animation-delay: -0.4s;
  -moz-animation-delay: -0.4s;
  -ms-animation-delay: -0.4s;
  -o-animation-delay: -0.4s;
}
@keyframes scale {
  0%, 40%, 100% {
    transform: scaleY(0.2);
  }
  20%, 60% {
    transform: scaleY(1);
  }
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
