import { Component, OnInit } from "@angular/core";
import { ProfileService } from "src/app/modules/supplier/services/profile.service";
import { SupplierProfile } from "src/app/interfaces/supplierProfile";
import { ActivatedRoute, Params } from "@angular/router";
import { SupplierHeaderService } from "../../services/header.service";
import { AuthService } from "src/app/services/auth/auth.service";
import { SupplierCommonService } from "../../services/supplierCommon.service";

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.sass"],
})
export class ProfileComponent implements OnInit {
  TsmId: string = this.profileService.supplierModel.Mapping.TsmId;
  isShowBack = true;
  get supplierStatus(): SupplierProfile {
    return this.profileService.supplierModel.SupplierProfile;
  }

  profileId: string;

  constructor(
    private profileService: ProfileService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private supplierHeaderService: SupplierHeaderService,
    private supplierCommon: SupplierCommonService
  ) {
    this.profileService.stepChangeEvent.subscribe((step) => {
      const flag: boolean = step !== 6 && !(step === 1 && this.isTsmId());
      this.isShowBack = flag;
    });
  }

  ngOnInit() {
    // this.TsmId = this.profileService.supplierModel.Mapping.TsmId
    const title = this.profileService.headerTitle;
    this.supplierHeaderService.title = title;
    this.supplierHeaderService.showProfileButtons = true;
  }

  backToMyProfile() {
    this.profileService.backTo();
  }
  isTsmId(): boolean {
    this.TsmId = this.authService.passport.supplier
      ? this.authService.passport.supplier.Mapping.TsmId
      : "";
    return !!this.authService.passport.supplier.Mapping.TsmId;
  }
}
