import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, ActivatedRouteSnapshot } from "@angular/router";
import { OnBoardingService } from "../../../services/onboarding.service";
import { DashBoardService } from "../../../services/dashboard.service";
import { ConfirmService } from "src/app/services/dialog.service";
import { Subscription } from "rxjs";
@Component({
  selector: "app-onboarding-layout",
  templateUrl: "./layout.component.html",
  styleUrls: ["./layout.component.sass"],
})
export class OnBoardingLayoutComponent implements OnInit {
  isShowBack = true;
  subscription: Subscription;
  constructor(
    private route: ActivatedRoute,
    private onBoardingService: OnBoardingService,
    private dashboardService: DashBoardService,
    private confirmService: ConfirmService,

  ) {
    if (this.dashboardService.isSearchInTsm) {
      this.dashboardService.isTsmEnterOnboarding = true;
    }
    this.onBoardingService.profileStage = "onboarding";
  }

  ngOnInit() {
    this.onBoardingService.stepChangeEvent.subscribe((step) => {
      this.isShowBack = step !== 5;
    });
  }

  backTo() {
    this.onBoardingService.backTo();
  }
  gotobackonkeydown(event) {
    if(event.keyCode == 13){
      this.backTo()
    }
  }
  // ngOnDestroy() {
  //   this.subscription.unsubscribe();
  // }
}
