// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.side-nav {
  width: 400px;
  float: left;
  background: #3D3D3D;
  position: absolute;
  z-index: 9999;
}
.side-nav .header_text {
  padding: 4rem 3rem 0;
  font-size: 1.14rem;
  color: #666;
}
.side-nav .header_text .circle {
  width: 5.5rem;
  height: 5.5rem;
  line-height: 5.5rem;
  text-align: center;
  border-radius: 50%;
  border: #666 1px solid;
  margin-right: 2rem;
}
.side-nav .header_text .circle .material-icons {
  font-size: 3.5rem;
}

.active:hover {
  color: #fff !important;
}
.active:hover .circle {
  border: #fff 1px solid !important;
}

.btnCss {
  cursor: pointer;
}

.cancel {
  border-radius: 50%;
  background: #FFF;
  margin-left: 14rem;
  color: #3D3D3D;
  cursor: pointer;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
