// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.collapse {
  padding: 2rem 1rem 1rem 1rem;
}
.collapse label {
  font-weight: lighter;
  padding-bottom: 1rem;
  font-size: 1rem;
}
.collapse .labelCss {
  margin-left: -2px;
}

.subCollapse {
  border-bottom: #ccc 1px solid;
  border-top: #ccc 1px solid;
  border-left: #026EA4 5px solid;
  background: #F3F3F6;
}
.subCollapse .labelLine {
  border-top: #ccc 1px solid;
  padding-top: 1rem;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
