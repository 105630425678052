// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filter .option {
  margin-right: 2rem;
  padding-bottom: 5px;
  color: #46465B;
}
.filter .option.active {
  border-bottom: #46465B 3px solid;
}
.filter .sort_label {
  margin-right: 0.5rem;
  padding-bottom: 5px;
}
.filter select {
  margin-bottom: 5px;
  width: 250px;
  border: none;
  border-bottom: solid 1px #666;
  background-color: #f1f1f1 !important;
}

.supplier-loop {
  position: static;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
