import { Component, OnInit, Input } from "@angular/core";
import {
  BuyerChangeRequestDTO,
  ChangeRequestType,
  ChangeRequestStatus,
  ChangeRequestDetailStatus,
  AuditChangeRequestDetail,
} from "src/app/interfaces/changeRequest";
import { BuyerService } from "src/app/services/buyer.service";
import { ActivatedRoute } from "@angular/router";
import {
  DialogService,
  DialogType,
  ButtonCommands,
  CRDialogType,
} from "src/app/services/dialog.service";
import { OnBoardingService } from "../../../services/onboarding.service";
import { AuthService } from "src/app/services/auth/auth.service";
import { environment } from "src/environments/environment";
import { State } from "../../../../../shared/shared";
import { strToHexCharCode } from "../../../../../utils/index";
import * as moment from "moment";
import {
  AllTaskKey,
  ProcessNames,
  BuyerChangeRequestProcess,
  SetupProcess,
} from "src/app/interfaces/workflow";
import { BuyerCommonService } from "../../../services/buyerCommonService";
import { HttpService } from "src/app/services/api/http.service";
import { LoadingService } from "src/app/services/loading.service";
import { DictionaryService } from "src/app/services/dictionary.service";
import { SpendCategoryGroup } from "src/app/interfaces/mapping";

enum ButtonType {
  sstApprove = "sstApprove",
  sstClose = "sstClose",
  qcApprove = "qcApprove",
  qcClose = "qcClose",
  qcReject = "qcReject",
}
enum RoleCode {
  APSST = "AP_SST",
  APQC = "AP_QC",
}

@Component({
  selector: "app-audit-trail-change-request",
  templateUrl: "./audit-trail-change-request.component.html",
  styleUrls: ["./audit-trail-change-request.component.sass"],
})
export class AuditTrailChangeRequestComponent implements OnInit {
  @Input() changeRequestList: any;
  // @Input() mockChangRequest: Array<BuyerChangeRequest>
  supplierCode: any;
  supplierName: string;
  buttonType = ButtonType;
  baseUrl = environment.gateway;
  crType = ChangeRequestType;
  userRole = RoleCode;
  userRoleDesc = this.authService.passport.buyer.RoleCode;
  crPendingStatus = ChangeRequestStatus;
  crDetailStatus = ChangeRequestDetailStatus;
  isSST = false;
  isQC = false;
  termPaymentCode: string;
  hasNonStandardPaymentTermsLabel =
    "Has Accenture/Avanade agreed to non-standard Payment Terms for this Supplier?";
  termPaymentLabel =
    "Select different payment terms if Accenture/Avanade has agreed to non-standard payment terms per executed agreement";
  guFlag = "SupplierGeographical";
  constructor(
    private buyerService: BuyerService,
    private route: ActivatedRoute,
    private dialogService: DialogService,
    private authService: AuthService,
    private onboardingService: OnBoardingService,
    private buyerCommonService: BuyerCommonService,
    private http: HttpService,
    private loadingService: LoadingService,
    private dictionaryService: DictionaryService
  ) {}

  async ngOnInit() {
    this.supplierCode = this.route.snapshot.paramMap.get("code");
    this.onboardingService.loadChangeRequestList().then((data) => {
      this.changeRequestList = data;
      data.map((item) => {
        if (item.openFlag === true) {
          this.onboardingService.openChangeRequestItem = item;
        }
      });
      this.changeRequestList.forEach(
        (changeRequestItem: BuyerChangeRequestDTO) => {
          if (
            changeRequestItem.requestType ===
              this.crType.UPDATE_PAYMENT_TERMS ||
            changeRequestItem.requestType ===
              this.crType.UPDATE_AmendSpendCommodityCategoryGroup
          ) {
            changeRequestItem.requestValue = changeRequestItem.requestValue;
          }
        }
      );
    });
    if (
      this.onboardingService.supplierModel.Mapping.geographicalUnit === "25"
    ) {
      this.hasNonStandardPaymentTermsLabel =
        "Has Accenture/Avanade agreed to an alternative Payment Term for this Supplier?";
    }
    // workflow load current login user's tasks
    await this.onboardingService.loadUserTasks();
    // console.log("changeRequestList", this.changeRequestList);
    if (
      this.onboardingService.getUserTaskByKey(
        ProcessNames.generic_buyerChangeRequest,
        BuyerChangeRequestProcess.SST_review
      )
    ) {
      this.isSST = true;
    }
    if (
      this.onboardingService.getUserTaskByKey(
        ProcessNames.generic_buyerChangeRequest,
        BuyerChangeRequestProcess.QC_review
      )
    ) {
      this.isQC = true;
    }
    this.termPaymentCode = await this.buyerCommonService.getTermPaymentCode();
    this.geographicalUnitHandle();
  }

  async downloadFile(fileKey: string, fileName: string): Promise<void> {
    const state = environment.role;
    const access_token = `Bearer ${this.authService.passport.access_token}`;
    const role =
      (state === State.buyer && this.authService.passport.buyer?.RoleCode) ||
      (state === State.supplier &&
        this.authService.passport.supplierMember?.RoleCode);
    const downloadPath =
      state === State.buyer ? "buyer/file/download" : "supplier/file/download";
    const params = strToHexCharCode(
      `key=${fileKey}&Authorization=${access_token}&state=${state}&role=${role}`
    );
    const url = `${this.baseUrl}/${downloadPath}`;
    try {
      this.loadingService.openLoading(url);
      const data: BlobPart = await this.http.PostBlobPromise(
        url,
        { fileCode: params },
        "blob"
      );
      const blob = new Blob([data]);
      // for ie
      if ("msSaveOrOpenBlob" in navigator) {
        window.navigator.msSaveOrOpenBlob(blob, fileName);
      } else {
        const link = document.createElement("a");
        link.setAttribute("href", URL.createObjectURL(blob));
        link.setAttribute("download", fileName);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.loadingService.closeLoading();
    }
  }

  sortCR() {
    this.changeRequestList.forEach(
      (changeRequestItem: BuyerChangeRequestDTO) => {
        changeRequestItem.audit.sort(
          (a: any, b: any) =>
            moment(b.createdOn).toDate().getTime() -
            moment(a.createdOn).toDate().getTime()
        );
      }
    );
  }
  
  isSupplierNameDuplicate(): boolean {
    this.supplierName =
      this.onboardingService.supplierModel.Mapping.searchTerm.supplierName;
    return this.supplierName.startsWith("DUPLICATE-");
  }
  async approve(type: ButtonType, changeRequest = null) {
    if (type === ButtonType.sstApprove) {
      const taskInst = this.onboardingService.getUserTaskByKey(
        ProcessNames.generic_buyerChangeRequest,
        BuyerChangeRequestProcess.SST_review
      );
      taskInst.variables = {
        SST_Approve: true,
      };
      await this.dialogService.apSSTApproveChangeRequest(
        taskInst,
        CRDialogType.SSTApproveChangeRequest
      );
    }
    if (type === ButtonType.qcApprove) {
      const taskInst = this.onboardingService.getUserTaskByKey(
        ProcessNames.generic_buyerChangeRequest,
        BuyerChangeRequestProcess.QC_review
      );
      // TODO: CR trigger coupa need to enhance, Currently, it cannot trigger coupa in CR process
      // let isGDPRTrigger = false;
      // if(changeRequest && changeRequest.requestType === ChangeRequestType.UPDATE_AmendSpendCommodityCategoryGroup) {
      //   const GDPRTask = this.onboardingService.supplierModel.Mapping.historyTasks.filter(
      //     (task) => task.name === SetupProcess.GDPR
      //   )
      //   const spendCategoryGroupValue = JSON.parse(changeRequest.requestValue)?.SpendCategoryGroup;
      //   const IndependentContractor = JSON.parse(changeRequest.requestValue)?.IndependentContractor;
      //   if (spendCategoryGroupValue && (spendCategoryGroupValue == '5002' || spendCategoryGroupValue == '5021')) {
      //     if (IndependentContractor && IndependentContractor == 'N') {
      //       SpendCategoryGroup['5002'] = true
      //       SpendCategoryGroup['5021'] = true
      //     }else {
      //       SpendCategoryGroup['5002'] = false
      //       SpendCategoryGroup['5021'] = false
      //     }
      //   }
      //   const isRiskInfoExist = JSON.parse(changeRequest.requestValue)?.BI_BDA_1;
      //   const isMigrated = this.onboardingService.supplierModel.Mapping.IsMigrated;
      //   isGDPRTrigger = !!SpendCategoryGroup[spendCategoryGroupValue] && !GDPRTask.length && !!isRiskInfoExist && !isMigrated;
      // }
      // taskInst.variables = {
      //   QC_Approve: true,
      //   isGDPR: isGDPRTrigger
      // };
      taskInst.variables = {
        QC_Approve: true
      };
      await this.dialogService.apQCApproveChangeRequest(
        taskInst,
        this.crDetailStatus.APPROVED_BY_AP_QC
      );
    }
  }

  async close(type: ButtonType) {
    if (type === ButtonType.sstClose) {
      const taskInst = this.onboardingService.getUserTaskByKey(
        ProcessNames.generic_buyerChangeRequest,
        BuyerChangeRequestProcess.SST_review
      );
      taskInst.variables = {
        isTerminate: true,
      };
      await this.dialogService.apSSTCloseChangeRequest(
        taskInst,
        CRDialogType.SSTCloseChangeRequest
      );
    } else if (type === ButtonType.qcClose) {
      const taskInst = this.onboardingService.getUserTaskByKey(
        ProcessNames.generic_buyerChangeRequest,
        BuyerChangeRequestProcess.QC_review
      );
      taskInst.variables = {
        isTerminate: true,
      };
      await this.dialogService.apQCCloseChangeRequest(
        taskInst,
        this.crDetailStatus.CLOSED_BY_AP_QC
      );
    }
  }

  async reject(type: ButtonType) {
    const taskInst = this.onboardingService.getUserTaskByTaskKey(
      AllTaskKey.QC_review
    );
    if (type === ButtonType.qcReject) {
      taskInst.variables = {
        QC_Reject: true,
      };
      await this.dialogService.apQCRejectChangeRequest(
        taskInst,
        this.crDetailStatus.REJECT_BY_AP_QC
      );
    }
  }
  transformdata(code: any, value: any) {
    const dicts =
      this.dictionaryService.getDictionaryFromCache("requestor-details");
    const SpendCommodityGroup = dicts.filter(
      (dict) => dict.Code === "SpendCommodityGroup"
    )[0].Items;
    const data = SpendCommodityGroup.filter((item) => item.Code === code)[0]
      .Items;
    return data.filter((item) => item.Code === value)[0].Text;
  }

  geographicalUnitHandle() {
    const gu = this.onboardingService.supplierModel.Mapping.geographicalUnit
    const dictsGu = this.dictionaryService.getDictionaryFromCache("requestor-details").filter((item: any) => item.Code === "SupplierGeographicalANZ" || item.Code === "SupplierGeographicalUKI" || item.Code === "SupplierGeographical");
    dictsGu.forEach((guItem: any) => {
      const targetObject = guItem.Items.filter((item: any) => item.Code === gu)
      if (targetObject && targetObject.length) {
        this.guFlag = guItem.Code
      }
    })
  }
}
