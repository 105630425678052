import { Component, OnInit } from "@angular/core";
import { BaseComponent } from "src/app/dynamic-components/components/base/base.component";
@Component({
  selector: "app-checkbox",
  templateUrl: "./checkbox.component.html",
  host: {
    "[style.width]": "config.width",
  },
  styleUrls: ["./checkbox.component.sass"],
})
export class CheckBoxComponent extends BaseComponent implements OnInit {
  constructor() {
    super();
  }
}
