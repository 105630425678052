// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.congrats {
  margin: 2rem 0;
  font-size: 1.25rem;
  color: #333;
}

.moreactions {
  margin-top: 7.2rem;
  margin-bottom: 1.4rem;
  font-size: 1.25rem;
  color: #666;
  font-weight: bold;
}

.congrats_card {
  margin: 2rem 0;
  width: 33.2vw;
  height: 18vw;
  margin-left: -7.2rem;
}
.congrats_card .left {
  float: left;
  width: 54%;
}
.congrats_card .left .title {
  font-size: 1.5rem;
  padding: 0 0;
  color: #47246a;
}
.congrats_card .left .sub_title {
  font-size: 1rem;
  font-weight: normal;
  margin-top: 1.8rem;
  word-break: normal;
  word-wrap: break-word;
}
.congrats_card .left .reg_date {
  vertical-align: middle;
  margin-top: 1.5rem;
}
.congrats_card .left .reg_date_icon {
  margin-top: -0.3rem;
  color: #47246a;
  margin-left: -0.3rem;
  font-size: 1.3rem;
}
.congrats_card .left .reg_date_span {
  margin-left: 0.5rem;
}
.congrats_card .left .view_details_btn {
  background-color: #ecf7f3;
  border-radius: 2.5rem;
  line-height: 3rem;
  font-size: 1.1rem;
  text-align: center;
  margin-top: 1.5rem;
  color: #47246a;
  font-weight: 600;
  display: inline-block;
  padding: 0 1rem;
}
.congrats_card .left .white_space {
  white-space: pre-wrap;
}

.right_dev {
  text-align: center;
}

.fingerprint_image {
  width: 9rem;
  margin-top: 3.3rem;
}

.right_dev_all {
  height: 18vw;
  background-color: #ecf7f3;
  border-bottom-right-radius: 2rem;
  float: left;
  width: 46%;
}

.right_subtitle {
  margin-top: 4rem;
  font-weight: bold;
}
.right_subtitle i {
  font-size: 1.2rem;
  margin-top: -0.2rem;
}

.right_sub_content {
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 1rem;
  color: #3a3a3a;
  width: 89%;
  line-height: 1.4;
  font-weight: normal;
}

.completed_status-value {
  background: #55bded;
  padding: 0 1.5rem;
  color: #fff;
  background-color: #763ab2;
}

.status-border {
  border-top: 2.25rem solid #47246a !important;
}

.subtitle_color {
  color: #4E4E87;
}

.btn_copmlete {
  font-weight: bold;
  border: 0;
  opacity: 0.9;
  padding: 1rem 1.5rem;
  border-radius: 2rem;
  cursor: pointer;
  color: #fff;
  background: #3C73D2;
}

.btn_view {
  border: 0;
  opacity: 0.9;
  padding: 1rem 1.5rem;
  border-radius: 2rem;
  cursor: pointer;
  font-weight: bold;
  color: #4e4e87;
  background: rgba(78, 78, 135, 0.1);
}

.back_to_tsm {
  color: #5c5ca3;
  font-size: 1.2rem;
  cursor: pointer;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  font-size: 1rem;
  font-family: Graphik, Arial;
  font-weight: 400;
  visibility: hidden;
  width: 2200%;
  background-color: #ffffff;
  color: #484848;
  text-align: left;
  padding: 5px 0;
  border-radius: 9px;
  position: absolute;
  z-index: 1;
  top: 180%;
  left: 50%;
  margin-left: -220px;
  box-shadow: 0px 0px 5px #b0c0dd;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.colorcontrast {
  color: #FFFFFF;
  background: #0033AA;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
