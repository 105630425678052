// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.material-icons {
  vertical-align: middle;
}

.btn-small {
  padding: 0.5rem 1.2rem;
  margin-left: 1.5rem;
}

.btn-white {
  background-color: #fff;
  border: solid 1px #ccc;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
