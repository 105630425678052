// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.collapse_more_icon {
  position: absolute;
  top: -0.5rem;
  left: 0;
}

.sub_title {
  position: relative;
}

.collapse_left_icon {
  position: absolute;
  left: 8px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
