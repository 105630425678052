import {
  Directive,
  DoCheck,
  ComponentFactoryResolver,
  ViewContainerRef,
  Input,
  OnInit,
  HostListener,
  OnDestroy,
  OnChanges,
  AfterViewInit,
  SimpleChanges,
} from "@angular/core";
import { DynamicComponentService } from "src/app/dynamic-components/services/dynamic-component.service";
import { UntypedFormGroup, ValidatorFn, AsyncValidatorFn } from "@angular/forms";
import { BaseComponent } from "../../components/base/base.component";
import { Subscription } from "rxjs";
import { ComponentConfig, HookConfig } from "../../ComponentConfig";
import { DynamicContext } from "../../interfaces/DynamicContext";
import formatorFactory from "../../formators/FormatorFactory";
import { Filters } from "../../filters/Filters";
import { Hooks } from "../../hooks/hooks";

@Directive({
  selector: "[appDynamicComponent]",
})
export class DynamicComponentDirective
  implements OnInit, DoCheck, OnDestroy, OnChanges, AfterViewInit
{
  @Input() viewOnly: boolean;
  @Input() config: ComponentConfig;
  @Input() formGroup: UntypedFormGroup;
  @Input() context: DynamicContext;
  @Input() model: any;
  component: BaseComponent;
  private subscription: Subscription;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private viewContainer: ViewContainerRef,
    private dynamicComponentService: DynamicComponentService
  ) {}

  ngOnInit() {
    this.beforeRender();
    this.render();
  }

  ngAfterViewInit() {}

  ngOnChanges(changes: SimpleChanges) {}

  beforeRender() {
    if (this.config.hooks) {
      Hooks.executeHook(
        this.config,
        this.context,
        this.formGroup,
        Hooks.TRIGGER_ONCOMPONENTRENDER
      );
    }
  }

  render() {
    if (!this.config) {
      console.error(`Cannot find a field named ${this.config.name}`);
      return;
    }
    const component = this.dynamicComponentService.getComponent(
      this.config.type
    );
    if (!component) {
      console.error(`No component found for field ${this.config.name}`);
      return;
    }
    //   this.clear()
    // filter check
    // if (!Filters.doFilters(this.config, this.context, this.formGroup)) {
    //   return
    // }
    const componentFactory =
      this.componentFactoryResolver.resolveComponentFactory<BaseComponent>(
        component
      );
    const componentRef =
      this.viewContainer.createComponent<BaseComponent>(componentFactory);
    this.component = componentRef.instance;
    this.component.config = this.config;
    if (this.context) {
      this.component.context = this.context;
    }

    this.component.formGroup = this.formGroup;
    this.component.formator = formatorFactory.createFormator(this.config);
    if (this.config.name) {
      this.context.setComponent(this.config.name, this.component);
    }
  }

  @HostListener("click")
  handleEvent(event: any) {}

  clear() {
    this.viewContainer.clear();
  }

  ngOnDestroy(): void {
    this.debindingListeners();
  }

  debindingListeners() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  ngDoCheck() {}
}
