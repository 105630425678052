export interface HoldOn {
  SupplierCode?: string;
  IsActive?: boolean;
  CreatedBy?: string;
  CreatedDate?: Date;
  ExpirationDate?: Date;
  Source?: string;
  Comments?: string;
  Attachment?: string;
}

export function initHoldOn(supplierCode) {
  const holdOn: HoldOn = {
    SupplierCode: supplierCode,
    IsActive: false,
    CreatedBy: "",
    CreatedDate: new Date(),
    ExpirationDate: new Date(),
    Source: "",
    Comments: "",
    Attachment: "",
  };
  return holdOn;
}
