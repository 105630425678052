import { ComponentConfig } from "../ComponentConfig";
import { DynamicContext } from "../interfaces/DynamicContext";
import { UntypedFormGroup } from "@angular/forms";
import { ExpressionFactory } from "./ExpressionFactory";

export class Expressions {
  private static expressionsMap = new Map();

  static registerExpressionFactory(name: string, filter: ExpressionFactory) {
    this.expressionsMap.set(name, filter);
  }

  static getExpressionFactory(name: string): ExpressionFactory {
    return this.expressionsMap.get(name);
  }

  static executeExpression(
    value: any,
    name: string,
    params: any,
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    const expressionFactory = this.getExpressionFactory(name);
    if (expressionFactory) {
      const hook = expressionFactory.call(
        expressionFactory.objInstance,
        value,
        config,
        context,
        formGroup
      );
      hook(params);
    }
  }
}
