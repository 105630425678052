import {
  Component,
  OnInit,
  Input,
  Output,
  ViewChild,
  EventEmitter,
} from "@angular/core";
import { Router } from "@angular/router";
import { FileUploader, FileUploaderOptions, FileLikeObject, FileItem } from "ng2-file-upload";
import {
  FileType,
  FileUploadError,
} from "src/app/services/file-upload.service";
import { allowedMimeType } from "src/app/shared/shared";
import { BuyerService } from "src/app/services/buyer.service";
import {
  DialogService,
  DialogType,
  ButtonCommands,
} from "src/app/services/dialog.service";
import { OnBoardingService } from "src/app/modules/buyer/services/onboarding.service";
import { DynamicComponentService } from "src/app/dynamic-components/services/dynamic-component.service";
import { DynamicContext } from "src/app/dynamic-components/interfaces/DynamicContext";
import { ComponentConfig } from "src/app/dynamic-components/ComponentConfig";
import { UntypedFormGroup } from "@angular/forms";
import { LanguageService } from "../../../../dynamic-components/utils/language.service";
import { environment } from "src/environments/environment";
import * as XLSX from "xlsx";
import { LoadingService } from "src/app/services/loading.service";
import { LoadingUrlMapping } from "src/app/interfaces/mapping";
import { WsTypeEnum } from "src/app/interfaces/notification";

@Component({
  selector: "app-termination",
  templateUrl: "./termination.component.html",
  styleUrls: ["./termination.component.sass"],
})
export class TerminationComponent implements OnInit {
  itemsPerPage = 10;
  currentPage = 1;
  activitycurrentPage = 1;
  isShow: boolean;
  currentTitle = "Termination Script";

  uploader: FileUploader;
  uploading = false;
  uploadMessage: string;
  fileName: string;
  showProgress = false;
  retryItems = [];
  resending = false;
  showAddForm = false;
  isValidated = false;
  termactivityItems = TERMACTIVITIES;
  TermLists: any;
  uploadFileSize: any;
  requiredFileSize: any;
  uploadFileType: any;
  requiredFileType: any;
  uploadValue: any;
  supplierName: string;
  errorType: FileUploadError;
  failedFile: FileLikeObject;
  uploadingFiles?: Array<any> = [];

  @Input() messageLargerMaxSize: string;
  @Input() messageInvalidType: string;
  @Input() messageEmptyFile: string;
  @Input() messageWrongColumns: string;
  @Input() messageMoreColumns: string;
  @Input() messageEmptyValue: string;
  @Input() messageLessColumns: string;
  @Input() messageValidating: string;
  @Input() messageValidateSuccess: string;
  @Input() context: DynamicContext;
  @Input() config: ComponentConfig;
  @Input() formGroup: UntypedFormGroup;

  @ViewChild("valueElement") valueElement: any;

  @Output() error: EventEmitter<FileUploadError>;
  target: any;
  // isDisable: boolean
  // url: string

  constructor(
    private router: Router,
    private buyerService: BuyerService,
    private dialogService: DialogService,
    public service: DynamicComponentService,
    private loadingService: LoadingService,
    private languageService: LanguageService,
    private onBoardingService: OnBoardingService
  ) {
    this.uploader = new FileUploader({
      allowedMimeType: [
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      ],
      allowedFileType: ["xls"],
      maxFileSize: 10485760,
      queueLimit: 2,
      autoUpload: false,
    } as FileUploaderOptions);
  }

  ngOnInit() {
    // console.log("aaaa", this.uploader);
    // this.isDisable = false
    this.uploader.onAfterAddingAll = async (newFiles: FileItem[]) => {
      // console.log("fileName", newFiles);
      this.fileName = newFiles[0]._file.name;
      this.currentPage = 1;
      setTimeout(() => {
        const terminationdiv = document.getElementsByClassName("my-pagination")[0];
        if (terminationdiv) {
          const terminationcontrol = terminationdiv.childNodes[0];
          if (terminationcontrol) {
            const terminationtemplate = terminationcontrol.childNodes[0];
            if (terminationtemplate) {
              const terminationnul = terminationtemplate.childNodes[0];
              if (terminationnul) {
                for (let i = 0; i < terminationnul.childNodes.length; i++) {
                  let terminationli = terminationnul.childNodes[i];
                  if (terminationli.nodeName == 'LI') {
                    (terminationli as any).role = "group";
                  }
                }
              }
            }
          }
        }
      });
    };
    this.uploader.onWhenAddingFileFailed = async (
      failed: FileLikeObject,
      filter: any,
      options: any
    ) => {
      this.failedFile = failed;
      // console.log("this.failedFile", this.failedFile);
      this.showError(this.failedFile);
    };

  }

  uploadFile(e) {
    this.uploadFileType = this.uploader.queue[0].file.size.type;
    this.requiredFileType = this.uploader.options.maxFileSize;
    if (
      this.uploadFileType === this.requiredFileType[0] ||
      this.uploadFileType === this.requiredFileType[1]
    ) {
      if (this.uploader.queue && this.uploader.queue.length == 2) {
        this.uploader.removeFromQueue(this.uploader.queue[0]);
      }
      this.uploadFileSize = this.uploader.queue[0].file.size;
      this.requiredFileSize = this.uploader.options.maxFileSize;
      // console.log("this.uploadFileSize", this.uploadFileSize);
      // console.log("this.uploadFileSize", this.uploader.queue[0].file);
      if (
        this.uploadFileSize < this.requiredFileSize &&
        this.uploadFileSize >= 9408
      ) {
        const target: DataTransfer = e.target as DataTransfer;
        const reader: FileReader = new FileReader();
        reader.onload = async (e: any) => {
          /* read workbook */
          const bstr: string = e.target.result;
          const wb: XLSX.WorkBook = XLSX.read(bstr, { type: "binary" });
          const termvalues = [];
          /* grab first sheet */
          const wsname: string = wb.SheetNames[0];
          const ws = XLSX.utils.sheet_to_json(wb.Sheets[wsname]);
          // this.TermLists = ws
          // console.log("ws", ws);
          if (this.uploadFileSize === 9408 || ws.length === 0) {
            this.showProgress = false;
            this.isShow = false;
            this.messageEmptyFile =
              "The file you tried to upload is empty, please try to upload file which with at least one piece of data";
            this.clearValues();
            this.emptyFileError(this.messageEmptyFile);
            this.target.files = "";
          } else {
            let fromTo = "";
            for (const sheet in wb.Sheets) {
              if (wb.Sheets.hasOwnProperty(sheet)) {
                fromTo = wb.Sheets[sheet]["!ref"];
                // console.log("uploader.file", this.uploader.queue[0].file);
                // console.log("formTo", fromTo);
                this.uploadValue = wb.Sheets[sheet];
                if (
                  this.uploadValue.A1 &&
                  this.uploadValue.B1 &&
                  this.uploadValue.C1
                ) {
                  if (
                    this.uploadValue.A1.v == "SAP ID" &&
                    this.uploadValue.B1.v == "Company Code" &&
                    this.uploadValue.C1.v == "Supplier Company Name"
                  ) {
                    if (fromTo[0] === "A" && fromTo[3] === "C") {
                      for (let i = 0; i < ws.length; i++) {
                        if (
                          ws[i]["SAP ID"] === undefined ||
                          ws[i]["Company Code"] === undefined ||
                          ws[i]["Supplier Company Name"] === undefined
                        ) {
                          this.showProgress = false;
                          this.isShow = false;
                          this.messageEmptyValue =
                            "“SAP ID”, “Company Code” and “Supplier Company Name” all need values";
                          this.clearValues();
                          this.emptyValueError(this.messageEmptyValue);
                          this.target.files = "";
                        } else {
                          // this.messageValidating = 'Please wait for the validate.'
                          // this.showValidating(this.messageValidating)
                          this.loadingService.openLoading("");
                          this.uploading = true;
                          this.showProgress = true;
                          this.uploadMessage = "uploading...";
                          this.TermLists = ws;
                          const result: any =
                            await this.buyerService.terminateValueList(
                              this.TermLists,
                              this.uploader
                            );
                          // const saveUrl = `${environment.gateway}/buyer/management/terminateValueList`
                          // this.loadingService.openLoading(saveUrl)
                          // console.log('saveUrl',saveUrl)
                          if (result && result.isSuccess === true) {
                            this.loadingService.closeLoading();
                            this.uploading = false;
                            this.uploadMessage = `${result.data.length} records have been successfully validated.`;
                            // console.log("result", result.data);
                            // console.log("result", result.isSuccess);
                            this.isShow = true;
                            this.TermLists = result.data;
                            // this.messageValidateSuccess = 'Congratulation! The validation for the profile has ran successfully.Please continue the termination process by click OK button.'
                            // this.ValidateSuccess(this.messageValidateSuccess)
                            this.clearValues();
                          }
                        }
                      }
                    } else {
                      this.showProgress = false;
                      this.isShow = false;
                      this.messageMoreColumns =
                        "Only 3 columns with names “SAP ID”, “Company Code” and “Supplier Company Name” allowed";
                      this.clearValues();
                      this.moreColumnsError(this.messageMoreColumns);
                      this.target.files = "";
                    }
                  } else {
                    this.showProgress = false;
                    this.isShow = false;
                    this.messageWrongColumns =
                      "Only 3 columns with names “SAP ID”, “Company Code” and “Supplier Company Name” allowed";
                    this.clearValues();
                    this.wrongColumnError(this.messageWrongColumns);
                    this.target.files = "";
                  }
                } else {
                  this.showProgress = false;
                  this.isShow = false;
                  this.messageLessColumns =
                    "Need 3 columns with names “SAP ID”, “Company Code” and “Supplier Company Name”";
                  this.clearValues();
                  this.lessColumnsError(this.messageLessColumns);
                  this.target.files = "";
                }
              }
            }
          }
        };
        // console.log("target.files", target.files);
        reader.readAsBinaryString(target.files[0]);
        this.target.files = "";
      } else if (this.uploadFileSize < 9408) {
        this.showProgress = false;
        this.isShow = false;
        this.messageLessColumns =
          "Need 3 columns with names “SAP ID”, “Company Code” and “Supplier Company Name”";
        this.clearValues();
        this.lessColumnsError(this.messageLessColumns);
        this.target.files = "";
      }
    }
  }
  isSupplierNameDuplicate(): boolean {
    this.supplierName =
      this.onBoardingService.supplierModel.Mapping.searchTerm.supplierName;
    return this.supplierName.startsWith("DUPLICATE-");
  }

  async showValidating(messageValidating) {
    if (messageValidating) {
      await this.dialogService.dialogNoButton(
        this.messageValidating,
        DialogType.alert,
        "Validating..."
      );
      // this.clearValues()
    }
    return;
  }

  async ValidateSuccess(messageValidateSuccess) {
    if (messageValidateSuccess) {
      await this.dialogService.dialog(
        this.messageValidateSuccess,
        ButtonCommands.Ok,
        DialogType.success,
        "Validating Success"
      );
      // this.clearValues()
    }
    return;
  }

  async emptyValueError(messageEmptyValue) {
    if (messageEmptyValue) {
      await this.dialogService.dialog(
        this.messageEmptyValue,
        ButtonCommands.Close,
        DialogType.alert,
        "Missing Value(s)"
      );
      this.clearValues();
    }
    return;
  }

  async wrongColumnError(messageWrongColumns) {
    if (messageWrongColumns) {
      await this.dialogService.dialog(
        this.messageWrongColumns,
        ButtonCommands.Close,
        DialogType.alert,
        "Wrong Column(s)"
      );
      this.clearValues();
    }
    return;
  }

  async moreColumnsError(messageMoreColumns) {
    if (messageMoreColumns) {
      await this.dialogService.dialog(
        this.messageMoreColumns,
        ButtonCommands.Close,
        DialogType.alert,
        "More Than Three Columns"
      );
      this.clearValues();
    }
    return;
  }

  async lessColumnsError(messageLessColumns) {
    if (messageLessColumns) {
      await this.dialogService.dialog(
        this.messageLessColumns,
        ButtonCommands.Close,
        DialogType.alert,
        "Less Than Three Columns"
      );
      this.clearValues();
    }
    return;
  }

  async emptyFileError(messageEmptyFile) {
    if (messageEmptyFile) {
      await this.dialogService.dialog(
        this.messageEmptyFile,
        ButtonCommands.Close,
        DialogType.alert,
        "Empty File"
      );
      this.clearValues();
    }
    return;
  }

  // async maxFileError(messageLargerMaxSize){
  //   if (messageLargerMaxSize) {
  //     await this.dialogService.dialog(this.messageLargerMaxSize, ButtonCommands.Close, DialogType.alert, 'Unsupported File Size')
  //     this.clearValues()
  //   }
  //   return
  // }

  // async InvalidTypeError(messageInvalidType){
  //   if (messageInvalidType) {
  //     await this.dialogService.dialog(this.messageInvalidType, ButtonCommands.Close, DialogType.alert, 'Unsupported File Types')
  //     this.clearValues()
  //   }
  //   return
  // }

  pageChange(event) {
    this.currentPage = event;
  }
  activitypageChange(event) {
    this.activitycurrentPage = event;
  }

  tabPageChange(currentTitle: string) {
    this.currentTitle = currentTitle;
    this.currentPage = 1;
    this.activitycurrentPage = 1;
  }

  async showError(errorFile) {
    this.messageInvalidType = "";
    this.messageLargerMaxSize = "";
    this.messageEmptyFile = "";

    if (errorFile.size > this.uploader.options.maxFileSize) {
      this.clearValues();
      this.showProgress = false;
      this.isShow = false;
      this.errorType = FileUploadError.SizeError;
      if (environment.languageSwitch && environment.role == "supplier") {
        this.messageLargerMaxSize =
          this.messageLargerMaxSize ||
          this.languageService.getValue(
            "static.custom.components.uploader.messageLargerMaxSize1"
          ) +
          " " +
          this.uploader.options.maxFileSize / 1024 / 1024 +
          this.languageService.getValue(
            "static.custom.components.uploader.messageLargerMaxSize2"
          ) +
          " " +
          this.uploader.options.maxFileSize / 1024 / 1024 +
          this.languageService.getValue(
            "static.custom.components.uploader.messageLargerMaxSize3"
          ) +
          "<br/>";
      } else {
        this.messageLargerMaxSize =
          this.messageLargerMaxSize ||
          "The file you tried to upload is more than " +
          this.uploader.options.maxFileSize / 1024 / 1024 +
          "MB, please try to upload file which is less than or equal to " +
          this.uploader.options.maxFileSize / 1024 / 1024 +
          "MB.<br/>";
      }
    }

    if (
      (errorFile.type !== allowedMimeType.XLS &&
        errorFile.type !== allowedMimeType.XLSX) ||
      errorFile.type === ""
    ) {
      this.clearValues();
      this.showProgress = false;
      this.isShow = false;
      this.errorType = FileUploadError.TypeError;
      if (environment.languageSwitch && environment.role == "supplier") {
        this.messageInvalidType =
          this.messageInvalidType ||
          this.languageService.getValue(
            "static.system.components.dataMigration.NotSupported"
          );
      } else {
        this.messageInvalidType =
          this.messageInvalidType ||
          `The file you tried to upload is not supported,
      please attached xlsx file type.`; //   return
      }
    }

    if (this.messageLargerMaxSize) {
      this.isShow = false;
      this.showProgress = false;
      this.clearValues();
      if (environment.languageSwitch && environment.role == "supplier") {
        await this.dialogService.dialog(
          this.messageLargerMaxSize,
          ButtonCommands.Close,
          DialogType.alert,
          this.languageService.getValue(
            "static.custom.components.uploader.header"
          )
        );
      } else {
        await this.dialogService.dialog(
          this.messageLargerMaxSize,
          ButtonCommands.Close,
          DialogType.alert,
          "Unsupported File Size"
        );
      }
      return;
    }

    if (
      this.messageInvalidType ||
      (this.messageInvalidType && this.messageLargerMaxSize) ||
      errorFile.type === ""
    ) {
      // this.uploading = true
      this.isShow = false;
      this.showProgress = false;
      this.clearValues();
      if (environment.languageSwitch && environment.role == "supplier") {
        await this.dialogService.dialog(
          this.messageInvalidType,
          ButtonCommands.Close,
          DialogType.alert,
          this.languageService.getValue(
            "static.custom.components.uploader.type"
          )
        );
      } else {
        await this.dialogService.dialog(
          this.messageInvalidType,
          ButtonCommands.Close,
          DialogType.alert,
          "Unsupported File Types"
        );
      }
      return;
    }
  }

  navigateToDashboard() {
    this.router.navigate(["buyer"]);
  }

  // openLoading(url: any) {
  //   this.url = url
  //   this.isDisable = true
  // }

  // closeLoading() {
  //   this.isDisable = false
  // }

  clearValues() {
    if (this.valueElement) {
      this.valueElement.nativeElement.value = null;
    }
  }

  get control() {
    return this.formGroup.controls[this.config.name];
  }
}

// mock termination script data
export interface termactivityItem {
  number: number;
  status: string;
  termuserid: string;
  termtimesnap: string;
}

// mock termination activity
const TERMACTIVITIES: termactivityItem[] = [
  {
    number: 1,
    status: "Termination Success",
    termuserid: "baijingting@163.com",
    termtimesnap: "2021-9-2 8:30:00",
  },
  {
    number: 2,
    status: "Termination Success",
    termuserid: "renjialun@163.com",
    termtimesnap: "2021-9-3 9:30:00",
  },
  {
    number: 3,
    status: "Validation Failed",
    termuserid: "wangjiaer@163.com",
    termtimesnap: "2021-9-4 10:30:00",
  },
  {
    number: 4,
    status: "Termination Failed",
    termuserid: "dilireba@163.com",
    termtimesnap: "2021-9-5 11:30:00",
  },
  {
    number: 5,
    status: "Termination Success",
    termuserid: "gulinazha@163.com",
    termtimesnap: "2021-9-6 12:30:00",
  },
  {
    number: 6,
    status: "Termination Success",
    termuserid: "lijiaqi@163.com",
    termtimesnap: "2021-9-7 13:30:00",
  },
  {
    number: 7,
    status: "Termination Success",
    termuserid: "fanbingbing@163.com",
    termtimesnap: "2021-9-8 14:30:00",
  },
  {
    number: 8,
    status: "Termination Failed",
    termuserid: "zhoushen@163.com",
    termtimesnap: "2021-9-9 15:30:00",
  },
  {
    number: 9,
    status: "Validation Failed",
    termuserid: "jialing@163.com",
    termtimesnap: "2021-9-10 16:30:00",
  },
  {
    number: 10,
    status: "Termination Success",
    termuserid: "regou@163.com",
    termtimesnap: "2021-9-11 17:30:00",
  },
  {
    number: 11,
    status: "Termination Failed",
    termuserid: "baijingting@qq.com",
    termtimesnap: "2021-9-12 18:30:00",
  },
  {
    number: 12,
    status: "Validation Failed",
    termuserid: "zhangxueyou@163.com",
    termtimesnap: "2021-9-13 19:30:00",
  },
  {
    number: 13,
    status: "Termination Success",
    termuserid: "liudehua@qq.com",
    termtimesnap: "2021-9-14 20:30:00",
  },
  {
    number: 14,
    status: "Termination Failed",
    termuserid: "weidaxun@qq.com",
    termtimesnap: "2021-9-15 21:30:00",
  },
  {
    number: 15,
    status: "Validation Failed",
    termuserid: "fanchengcheng@qq.com",
    termtimesnap: "2021-9-16 22:30:00",
  },
];
