// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.labelCss {
  color: #666;
  height: 30px;
  font-family: Graphik;
  font-weight: lighter;
  font-style: italic;
}

.file-img {
  width: 20px;
  height: 25px;
  float: left;
}

div label.labelCss {
  color: #666;
  height: 30px;
  font-family: Graphik;
  font-weight: lighter;
  font-style: italic;
}

.sub_title {
  margin-left: 1rem;
  position: relative;
}

.collapse_more_icon {
  position: absolute;
  top: -0.5rem;
}

.collapse_left_icon {
  position: absolute;
  left: -0.5rem;
  top: -0.25rem;
}

.collapse_left_icon1 {
  margin-top: -0.25rem;
}

.profile_content {
  margin-left: 1.3rem;
}

.form-group {
  margin-bottom: 0.9rem;
}

.formControlInputS {
  width: 70% !important;
}

.formControlInputL {
  width: 95% !important;
}

.formControlInputM {
  width: 85% !important;
}

.formControlInputSL {
  width: 112% !important;
}

.spanDescInline {
  display: inline-block !important;
  font-weight: 100;
}

.requierdVerify {
  border: 1px solid #F3ABD1;
}

.spanTitle {
  margin-bottom: 1rem;
  font-weight: bold;
  color: #333;
  line-height: 1.25rem;
}

.MainTitle {
  display: block;
  margin-bottom: 1rem;
  font-weight: bold;
  color: #555555;
  line-height: 1.25rem;
}

.bottomButton {
  margin-top: 2rem;
}

.buyer_label {
  color: #3F72D2;
}

.buyer_label_active {
  color: #3F72D2;
}

.buyer_risk_assessment_result {
  border-radius: 5px;
  border: #ccc 1px solid;
  padding: 15px 10px;
  position: relative;
  margin-top: 10px;
}

.buyer_risk_assessment_label {
  display: inline-flex;
  position: absolute;
  top: -0.6rem;
  background-color: #fff;
  z-index: 10;
  margin-left: 10px;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
}

.buyer_risk_assessment_input {
  z-index: 15;
}

.float_right {
  float: right;
}

.btn_padding {
  padding-bottom: 2rem;
}

.link-mare-panel {
  position: absolute;
  height: auto;
  color: #000;
  border-radius: 5px;
  padding: 5px 0;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.12), 0 3px 6px rgba(0, 0, 0, 0.24);
  outline: 10px;
  z-index: 9999;
  margin-left: 905px;
  color: #fff;
  text-align: center;
  background-color: #000;
}

.back_onboard {
  padding-left: 20px;
  padding-bottom: 5px;
}
.back_onboard a {
  cursor: pointer;
  text-decoration: underline;
}

.resolve-cover {
  padding-top: 20px;
}

.cursor {
  cursor: pointer;
}

.white-space {
  white-space: pre-wrap;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
