// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ui-confirmdialog {
  width: 33rem;
}

.ui-dialog {
  position: fixed;
  padding: 1.4rem;
  border: 1px solid #D5D5D5;
  background: #fcfcfc;
}

.header-icon {
  border: 2px solid #fcfcfc;
  margin-top: -60px;
  display: inline-block;
  background: #ffffff;
  vertical-align: middle;
}

.header-icon-success {
  color: #00a5a6;
}

.header-icon-warning {
  color: #CC1E32;
}

.header-icon-question {
  color: #AB8C30;
}

.header {
  position: relative;
  color: #333;
  font-size: 18px;
  font-weight: medium;
}
.header .language {
  position: absolute;
  top: -20px;
  right: -10px;
}

.content {
  margin-top: 2.5rem;
  margin-bottom: 2.1rem;
  max-height: 19rem;
  overflow-y: auto;
}

.footer {
  text-align: center;
}

.ui-shadow {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3);
}

.ui-corner-all {
  border-radius: 10px;
}

.text-center {
  text-align: center !important;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
