// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.multi-drop-input {
  width: calc(100% - 24px);
  height: 100%;
  background: #f6f5fa;
  border: 0;
  color: #999;
}

.multi-drop-panel {
  height: 140px;
  border-left: solid 0.9px #ddd;
  border-bottom: solid 0.9px #ddd;
  border-right: solid 0.9px #ddd;
  padding: 6px;
  overflow-y: auto;
  position: absolute;
  background-color: #fff;
  z-index: 9999;
  width: 100%;
}

.search_bg {
  display: inline-flex;
  background: #fff;
  width: 100%;
  border: solid 1px #efefef;
  border-radius: 1.5rem;
  padding-left: 5px;
  padding-top: 3px;
}
.search_bg .material-icons {
  margin-right: 0.5rem;
}

.icon-drop {
  color: #ddd;
}

.icon-drop-arrow {
  color: #000;
}

.search-box {
  border: none;
  background: #fff;
  font-size: 14px;
  width: 80%;
}

.search-box .search-container {
  position: absolute;
  top: 100px;
  right: 100px;
}

.multi-drop-panel-cont {
  padding-left: 10px;
}

label-drop {
  padding-top: 2px;
}

.line-drop {
  margin: 13px 0px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
