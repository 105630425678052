import { Component, OnInit, Input, ViewChild, ElementRef } from "@angular/core";

@Component({
  selector: "app-info-tooltip",
  templateUrl: "./info-tooltip.component.html",
  styleUrls: ["./info-tooltip.component.sass"],
})
export class InfoTooltipComponent implements OnInit {
  @Input() text: string;
  showTooltip: boolean;
  @ViewChild("estimateSpendTooltip") estimateSpendTooltip: ElementRef;

  constructor() {}

  ngOnInit() {
  }

   estimateChooseOver(e, estimateSpendTooltip) {
    const TooltipEle = estimateSpendTooltip;
    if (estimateSpendTooltip) {
      if (
        e.clientX < TooltipEle.nativeElement.getBoundingClientRect().left ||
        e.clientX >
        TooltipEle.nativeElement.getBoundingClientRect().left +
        TooltipEle.nativeElement.offsetWidth -
        5 ||
        e.clientY < TooltipEle.nativeElement.getBoundingClientRect().top ||
        e.clientY >
        TooltipEle.nativeElement.getBoundingClientRect().top +
        TooltipEle.nativeElement.offsetHeight -
        5
      ) {
        this.showTooltip = false;
      }
    } else {
      this.showTooltip = false;
    }
  }
}
