import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { BuyerChangeRequestService } from "../../../services/buyerChangeRequest.service";
import {
  initChangeRequestMapping,
  initAuditChangeRequestDetail,
  ChangeRequestStatus,
  ChangeRequestType,
} from "src/app/interfaces/changeRequest";
import { OnBoardingService } from "../../../services/onboarding.service";
import {
  BuyerChangeRequestItem,
  OnBoardMessage,
} from "src/app/interfaces/buyer";
import * as moment from "moment";
import { AuthService } from "src/app/services/auth/auth.service";
import { CommonService } from "src/app/services/common.service";
import {
  ButtonCommands,
  DialogType,
  DialogService,
} from "src/app/services/dialog.service";
import { environment } from "src/environments/environment";
import { LoadingUrlMapping } from "src/app/interfaces/mapping";
import { LoadingService } from "src/app/services/loading.service";
import { DictionaryService } from "src/app/services/dictionary.service";
import { DynamicFormComponent } from "src/app/dynamic-components/components/dynamic-form/dynamic-form.component";
import { ComponentConfig } from "src/app/dynamic-components/ComponentConfig";
import { RequestorDetailsInfo } from "src/app/interfaces/requestForm";
import { DynamicContext } from "src/app/dynamic-components/interfaces/DynamicContext";
import { supplierGeographical } from "src/app/shared/shared";

@Component({
  selector: "app-company",
  templateUrl: "./company.component.html",
  styleUrls: ["./company.component.sass"],
})
export class CompanyComponent implements OnInit {
  showTooltip: boolean;
  context: DynamicContext;
  RequestorDetails: RequestorDetailsInfo;
  @ViewChild("estimateSpendTooltip") estimateSpendTooltip: ElementRef;
  @ViewChild("dynamicForm") dynamicForm: DynamicFormComponent;

  constructor(
    private buyerChangeRequest: BuyerChangeRequestService,
    private dialogService: DialogService,
    private onBoardingService: OnBoardingService,
    private authService: AuthService,
    private dictionaryService: DictionaryService,
    private loadingService: LoadingService
  ) { }

  comments: string;
  companyCodes: string[] = [];
  currentCompanyDropDownList: any = [];
  CompanyCode: any = [];
  isEmptyChoice = false;
  isValidated = false;
  uploadConfig: ComponentConfig[] = [
    {
      name: "UploadForPH",
      label: "Please upload evidence you have approval to add this:",
      subLabel: "<b> Procurement Approval from Category lead</b>",
      tooltip:
        '<ul class="link-mare-item"><li>Only allow email attachments and PDF, JPEG, PNG</li></ul>',
      width: "100%",
      templateOptions: {
        subLabelName: "What evidence do I need to provide?",
        boxWidth: { width: "75%" },
        allowedMimeType: [
          "application/pdf",
          "image/jpeg",
          "image/png",
          "",
          "application/vnd.ms-outlook",
        ],
      },
      required: true,
      errorMessage: {
        required: "Please upload attachment",
      },
      type: "uploader",
    },
  ];
  ngOnInit() {
    this.context = new DynamicContext();
    this.RequestorDetails = this.RequestorDetailsInfo();
    this.loadingService.openLoading("");
    this.dictionaryService.getDictionary("requestor-details").then((dicts) => {
      this.CompanyCode = dicts.filter(
        (dict) => dict.Code === "CompanyCode"
      )[0].Items;
      this.initCompanyCodeDropDown();
    });
  }

  RequestorDetailsInfo(): RequestorDetailsInfo {
    return this.onBoardingService.supplierModel?.RequestForm
      ?.RequestorDetailsInfo;
  }

  isPH() {
    return (
      this.onBoardingService.supplierModel?.RequestForm?.RequestorDetailsInfo
        ?.SupplierGeographical === supplierGeographical.PH
    );
  }

  async submit() {
    if (!this.validate()) {
      return { isValidate: false, isSuccess: false };
    }
    try {
      const supplier = this.onBoardingService.supplierModel;
      const request = initChangeRequestMapping(supplier.Mapping.supplierCode);
      request.supplierCode = supplier.Mapping.supplierCode;
      request.requestType = ChangeRequestType.ADD_COMPANY_CODE;
      request.requestValue = this.companyCodes.join(",");
      request.createdOn = moment.utc().toString();
      request.createdBy = this.authService.passport.profile.name;
      request.supplierModel = supplier;
      const audit = initAuditChangeRequestDetail();
      const detail = this.companyCodes.map((c) => {
        let countryCode: string = null;
        this.CompanyCode.forEach((companyList: any) => {
          companyList.Items.forEach((companyItem: any) => {
            if (companyItem.Code === c) {
              countryCode = companyList.Code;
            }
          });
        });
        return {
          geographicalUnit:
            supplier.RequestForm.RequestorDetailsInfo.SupplierGeographical,
          country: countryCode,
          companyCode: c,
          sapID: "",
        };
      });
      audit.addCompanyCodeList = detail;
      audit.comments = this.comments;
      audit.uploadFile = this.dynamicForm?.formGroup.get("UploadForPH")?.value;
      audit.actionTaken = "";
      audit.createdBy = this.authService.passport.profile.name;
      audit.createdOn = moment.utc().toString();
      request.audit = [audit];
      request.openFlag = true;
      const saveUrl = `${environment.gateway}${LoadingUrlMapping.addChangeRequest}`;
      this.loadingService.openLoading(saveUrl);
      return {
        isValidate: true,
        isSuccess: await this.buyerChangeRequest.submitRequestor(request),
      };
    } catch (e) {
      return { isValidate: true, isSuccess: false };
    }
  }

  validate() {
    this.isValidated = true;
    if (this.companyCodes.length < 1) {
      return false;
    }
    if (this.isPH() && !this.dynamicForm?.formGroup.get("UploadForPH")?.value) {
      return false;
    }
    return true;
  }

  initCompanyCodeDropDown() {
    const supplier = this.onBoardingService.supplierModel;
    this.CompanyCode.forEach((company: any) => {
      if (
        supplier.RequestForm.RequestorDetailsInfo.SupplierCountry.includes(
          company.Code
        )
      ) {
        company.Items.forEach((item: any) => {
          if (!item.IsDeprecated) {
            this.currentCompanyDropDownList.push(item);
          }
        });
      }
    });
    this.currentCompanyDropDownList = this.currentCompanyDropDownList.filter(
      (d) => {
        return !supplier.RequestForm.RequestorDetailsInfo.CompanyCode.includes(
          d.Code
        );
      }
    );
    this.loadingService.closeLoading();
  }

  saveSectionEvent(e) {
    this.companyCodes = e;
  }

  triggerCompany() {
    if (this.currentCompanyDropDownList.length === 0) {
      this.dialogService.dialog(
        `No additional company code can be added.`,
        ButtonCommands.Close,
        DialogType.alert,
        OnBoardMessage.APResultSendSuccess
      );
    }
  }

  estimateChooseOver(e, estimateSpendTooltip) {
    const TooltipEle = estimateSpendTooltip;
    if (estimateSpendTooltip) {
      if (
        e.clientX < TooltipEle.nativeElement.getBoundingClientRect().left ||
        e.clientX >
        TooltipEle.nativeElement.getBoundingClientRect().left +
        TooltipEle.nativeElement.offsetWidth -
        5 ||
        e.clientY < TooltipEle.nativeElement.getBoundingClientRect().top ||
        e.clientY >
        TooltipEle.nativeElement.getBoundingClientRect().top +
        TooltipEle.nativeElement.offsetHeight -
        5
      ) {
        this.showTooltip = false;
      }
    } else {
      this.showTooltip = false;
    }
  }
}
