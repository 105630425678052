import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { DashBoardService } from "../../services/dashboard.service";
import { OnBoardingService } from "../../services/onboarding.service";

@Component({
  selector: "app-helppage",
  templateUrl: "./helppage.component.html",
  styleUrls: ["./helppage.component.sass"],
})
export class HelppageComponent implements OnInit, AfterViewInit {
  @ViewChild("QuestionShow17") QuestionShow17: ElementRef;
  
  currentTitle = "FAQ";
  selectGUvalue = "UKIA";

  currentFAQPage = true;
  currentVideoPage = false;
  currentAskForSupportPage = false;

  faqQuestionShow1 = false;
  faqQuestionShow2 = false;
  faqQuestionShow3 = false;
  faqQuestionShow4 = false;
  faqQuestionShow5 = false;
  faqQuestionShow6 = false;
  faqQuestionShow7 = false;
  faqQuestionShow8 = false;
  faqQuestionShow9 = false;
  faqQuestionShow10 = false;
  faqQuestionShow11 = false;
  faqQuestionShow17 = false;

  faqQuestionShowSupplier1 = false;
  faqQuestionShowSupplier2 = false;
  faqQuestionShowSupplier3 = false;
  faqQuestionShowSupplier4 = false;
  faqQuestionShowSupplier5 = false;
  faqQuestionShowSupplier6 = false;
  faqQuestionShowSupplier7 = false;
  faqQuestionShowSupplier8 = false;
  faqQuestionShowSupplier9 = false;
  faqQuestionShowSupplier10 = false;
  faqQuestionShowSupplier11 = false;
  faqQuestionShowSupplier12 = false;
  faqQuestionShowSupplier13 = false;
  faqQuestionShowSupplier14 = false;
  faqQuestionShowSupplier15 = false;
  faqQuestionShowSupplier16 = false;
  faqQuestionShowSupplier17 = false;
  faqQuestionShowSupplier18 = false;
  faqQuestionShowSupplier19 = false;
  constructor(
    private dashboardService: DashBoardService,
    private onboardingService: OnBoardingService,
    private route: ActivatedRoute
  ) {
    this.dashboardService.title = "Help & Support";
    this.dashboardService.isDashBoard = false;
    this.dashboardService.isMigrated = true;
    this.onboardingService.profileStage = "helppage";
  }
  ngAfterViewInit(): void {
    if (this.faqQuestionShow17) {
      this.QuestionShow17.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
    }
  }

  ngOnInit() {
    this.route.queryParams.subscribe(queryParam =>{ 
      if (!!queryParam && queryParam.QuestionShow17) {
        this.faqQuestionShow17 = true
      }
    })
  }

  tabPageChange(currentTitle: string) {
    this.currentTitle = currentTitle;
  }
}
