// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dataMigration-container {
  background: linear-gradient(to right, #764BA2, #3C73D2);
  height: 621px;
}

.file-upload {
  border: dashed 1px rgba(31, 31, 53, 0.3);
  height: 4rem;
}

.uploader {
  border-radius: 0.3rem;
  display: -webkit-box;
  -webkit-box-orient: horizontal;
  -webkit-box-pack: center;
  -webkit-box-align: center;
  cursor: pointer;
}
.uploader input {
  display: none;
}

.file-img {
  width: 20px;
  height: 25px;
  float: left;
}

.progress {
  width: 8%;
  margin: auto 0;
  overflow: hidden;
  border-radius: 4px;
  text-align: -webkit-center;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}

.file-message-con {
  text-align: -webkit-center;
  color: #4e4e87;
}

.file-message {
  padding-top: 5px;
  padding-left: 10px;
}

.material-icons {
  color: #4e4e87;
}

.back {
  margin-top: 35px;
  margin-left: 35px;
  font-size: 16px;
  text-align: left;
  font-weight: 700;
}

.page-navigation {
  font-size: 12px;
  font-weight: initial;
  margin-left: 35px;
  margin-top: 10px;
  cursor: pointer;
  border-bottom: solid 1px #666;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
