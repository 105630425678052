import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import * as moment from "moment";
import { BaseComponent } from "src/app/dynamic-components/components/base/base.component";

@Component({
  selector: "app-custom-datepicker",
  templateUrl: "./datepicker.component.html",
  host: {
    "[style.width]": "config.width",
  },
  styleUrls: ["./datepicker.component.sass"],
})
export class DatepickerComponent extends BaseComponent implements OnInit {
  showTooltip: boolean;

  constructor() {
    super();
  }

  estimateChooseOver(e, estimateSpendTooltip) {
    const TooltipEle = estimateSpendTooltip;
    if (estimateSpendTooltip) {
      if (
        e.clientX < TooltipEle.nativeElement.getBoundingClientRect().left ||
        e.clientX >
          TooltipEle.nativeElement.getBoundingClientRect().left +
            TooltipEle.nativeElement.offsetWidth -
            5 ||
        e.clientY < TooltipEle.nativeElement.getBoundingClientRect().top ||
        e.clientY >
          TooltipEle.nativeElement.getBoundingClientRect().top +
            TooltipEle.nativeElement.offsetHeight -
            5
      ) {
        this.showTooltip = false;
      }
    } else {
      this.showTooltip = false;
    }
  }
}
