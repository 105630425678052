import { SafeHtmlPipe } from "./piples/safeHtml.pipe";
import { CheckBoxFormatPipe } from "./piples/checkBoxValue.pipe";
import { BlankValuePipe } from "./piples/blanckValue.pipe";
import { FieldEmptyPipe } from "./piples/fieldEmpty.pipe";
import { SapResult } from "./piples/sapResult.pipe";

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HTTP_INTERCEPTORS } from "@angular/common/http";

import { ButtonComponent } from "./components/button/button.component";
import { FooterComponent } from "./components/layout/footer/footer.component";
import { HeaderBarComponent } from "./components/layout/header-bar/header-bar.component";
import { SupplierCardComponent } from "./components/supplier-card/supplier-card.component";
import { DatepickerComponent } from "./components/datepicker/datepicker.component";
import { SupplierCardContentFormatPipe } from "./piples/supplier.card.content.format";
import { InfoTooltipComponent } from "./components/info-tooltip/info-tooltip.component";
import { ResInterceptor } from "../services/auth/res.interceptor";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import {
  MatNativeDateModule,
  MAT_DATE_LOCALE,
  MAT_DATE_FORMATS,
  DateAdapter,
} from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatInputModule } from "@angular/material/input";
import { MatIconModule } from "@angular/material/icon";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatChipsModule } from "@angular/material/chips";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { TooltipComponent } from "./components/tooltip/tooltip.component";
import { HeaderComponent } from "./components/layout/header/header.component";
import { DomHandler } from "./components/dialog/domHandler";
import { FileUploadComponent } from "../shared/components/file-upload/file-upload.component";
import { MultipleDropdownComponent } from "../shared/components/multiple-dropdown/multiple-dropdown.component";
import { FileUploadModule } from 'ng2-file-upload';
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { MY_FORMATS } from "./shared";
import { OnboardProcessCircleComponent } from "./components/onboard-process-circle/onboard-process-circle.componet";
import { UserMenuComponent } from "./components/layout/user-menu/user-menu.component";
import { SideNavComponent } from "./components/layout/side-nav/side-nav.component";
import { NotificationComponent } from "./components/notification/notification.component";

import { WebSocketService } from "../services/websocket.service";
import { HistoryComponent } from "./components/history/history.component";
import { ToggleButtonComponent } from "./components/toggle-button/toggle-button.component";
import { PrivacyStatementPoppageComponent } from "./components/privacy-statement-poppage/privacy-statement-poppage.component";
import { NotificationHeaderComponent } from "./components/layout/notification-header/notification-header.component";
import { NotificationCenterComponent } from "./components/notification-center/notification-center.component";
import { ReportSRCMComponent } from "./components/report/report-srcm/report-srcm.component";
import { DropDownValuePipe } from "./piples/dropDownValue.pipe";
import { NgxPaginationModule } from "ngx-pagination";
import { AlertBarComponent } from "./components/layout/alert-bar/alert-bar.component";
import { SystemAdminComponent } from "./components/system-admin/system-admin.component";
import { TermOfUseComponent } from "./components/term-of-use/term-of-use.component";
import { LoadingComponent } from "./components/loading/loading.component";
import { LoadingService } from "../services/loading.service";
import { WorkflowService } from "../services/workflow.service";
import { DynamicComponentService } from "../dynamic-components/services/dynamic-component.service";
import { CustomHooks } from "./custom/CustomHook";
import { CustomValidators } from "./custom/CustomValidators";
import { CustomFilters } from "./custom/CustomFilters";
import { InputComponent } from "./custom/components/input/input.component";
import { RadioComponent } from "./custom/components/radio/radio.component";
import { CheckBoxComponent } from "./custom/components/check-box/checkbox.component";
import { CollapseComponent } from "./custom/components/collapse/collapse.component";
import { UploaderComponent } from "./custom/components/uploader/uploader.component";
import { LayoutComponent } from "../dynamic-components/components/layout/layout.component";
import { HiddenComponent } from "../dynamic-components/components/hidden/hidden.component";
import { DynamicComponentDirective } from "../dynamic-components/directives/components/dynamic-component.directive";
import { DynamicFormComponent } from "../dynamic-components/components/dynamic-form/dynamic-form.component";
import { DynamicViewComponent } from "../dynamic-components/components/dynamic-view/dynamic-view.component";
import { DatepickerComponent as CustomizedDatepicker } from "./custom/components/datepicker/datepicker.component";
import { MultipleDropdownComponent as CustomizedMultiple } from "./custom/components/multiple-dropdown/multiple-dropdown.component";
import { TextboxComponent } from "./custom/components/textbox/textbox.component";
import { DropdownComponent } from "./custom/components/dropdown/dropdown.component";
import { CustomDatasources } from "./custom/CustomDataSource";
import { ErrorMsgComponent } from "./custom/components/error-msg/error-msg.component";
import { PhoneComponent } from "./custom/components/phone/phone.component";
import { NumberComponent } from "./custom/components/number/number.component";
import { UploaderViewComponent } from "./custom/components/uploader-view/uploader-view.component";
import { TextboxViewComponent } from "./custom/components/textbox-view/textbox-view.component";
import { ItemComponent } from "./custom/components/item/item.component";
import { CustomExpressions } from "./custom/CustomExpressions";
import { TextHrefComponent } from "./components/text-href/text-href.component";
import { SpecialErrorMsgComponent } from "./custom/components/special-error-msg/special-error-msg.component";
import { CollapseOfCountryComponent } from "./custom/components/collapse-of-country/collapse-of-country.component";
import { SearchableDropdownComponent } from "./custom/components/searchable-dropdown/searchable-dropdown.component";
import { HoldOnService } from "../services/HoldOn";
import { PaymentBankingInformationOfCollapseComponent } from "./custom/components/payment-banking-information-of-collapse/payment-banking-information-of-collapse.component";
import { ThirdPartyPaymentOptionOfCollapseComponent } from "./custom/components/third-party-payment-option-of-collapse/third-party-payment-option-of-collapse.component";
import { PeoplepickerComponent } from "./components/peoplepicker/peoplepicker.component";
import { PeoplepickerComponent as CustomizedPeoplepicker } from "./custom/components/peoplepicker/peoplepicker.component";
import { PeopleChipsComponent } from "./custom/components/people-chips/people-chips.component";
import { AdditionalGSTRegistrationOfCollapseComponent } from "./custom/components/additional-gst-registration-of-collapse/additional-gst-registration-of-collapse.component";
import { TranslateModule } from "@ngx-translate/core";

import { LangItemPipe } from "./piples/langItem.pipe";
import { LangStrPipe } from "./piples/langStr.pipe";
import { TranslateWithStylePipe } from "./piples/translateWithStyle.pipe";
import { SecurityTrustHtmlPipe } from "./piples/securityTrustHtml.pipe";
import { LanguageComponent } from "./components/layout/language/language.component";
import { CustomDatePipe } from "./piples/customDate.pipe";
import { TimeoutPopupComponent, TimeoutPopupContentComponent } from './components/timeout-popup/timeout-popup.component';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
    declarations: [
        SupplierCardComponent,
        ButtonComponent,
        FooterComponent,
        HeaderBarComponent,
        HeaderComponent,
        SupplierCardContentFormatPipe,
        SafeHtmlPipe,
        CheckBoxFormatPipe,
        BlankValuePipe,
        FieldEmptyPipe,
        SapResult,
        DatepickerComponent,
        TooltipComponent,
        FileUploadComponent,
        MultipleDropdownComponent,
        OnboardProcessCircleComponent,
        InfoTooltipComponent,
        UserMenuComponent,
        SideNavComponent,
        NotificationComponent,
        HistoryComponent,
        ToggleButtonComponent,
        PrivacyStatementPoppageComponent,
        NotificationHeaderComponent,
        NotificationCenterComponent,
        ReportSRCMComponent,
        DropDownValuePipe,
        AlertBarComponent,
        SystemAdminComponent,
        TermOfUseComponent,
        LoadingComponent,
        DynamicComponentDirective,
        DynamicFormComponent,
        DynamicViewComponent,
        TextboxComponent,
        DropdownComponent,
        InputComponent,
        RadioComponent,
        CheckBoxComponent,
        CollapseComponent,
        UploaderComponent,
        CustomizedDatepicker,
        CustomizedMultiple,
        LayoutComponent,
        HiddenComponent,
        ErrorMsgComponent,
        PhoneComponent,
        NumberComponent,
        UploaderViewComponent,
        TextboxViewComponent,
        ItemComponent,
        TextHrefComponent,
        SpecialErrorMsgComponent,
        CollapseOfCountryComponent,
        SearchableDropdownComponent,
        PaymentBankingInformationOfCollapseComponent,
        ThirdPartyPaymentOptionOfCollapseComponent,
        PeoplepickerComponent,
        CustomizedPeoplepicker,
        PeopleChipsComponent,
        AdditionalGSTRegistrationOfCollapseComponent,
        LangStrPipe,
        LangItemPipe,
        TranslateWithStylePipe,
        SecurityTrustHtmlPipe,
        LanguageComponent,
        CustomDatePipe,
        TimeoutPopupComponent,
        TimeoutPopupContentComponent
    ],
    imports: [
        CommonModule,
        MatDatepickerModule,
        MatFormFieldModule,
        BrowserAnimationsModule,
        MatInputModule,
        MatIconModule,
        MatNativeDateModule,
        FormsModule,
        FileUploadModule,
        ReactiveFormsModule,
        NgxPaginationModule,
        MatChipsModule,
        MatAutocompleteModule,
        TranslateModule,
        MatDialogModule,
        MatButtonModule
    ],
    exports: [
        TextboxComponent,
        DropdownComponent,
        ButtonComponent,
        CheckBoxComponent,
        SupplierCardComponent,
        FooterComponent,
        HeaderBarComponent,
        SafeHtmlPipe,
        DropDownValuePipe,
        DatepickerComponent,
        TooltipComponent,
        FileUploadComponent,
        MultipleDropdownComponent,
        OnboardProcessCircleComponent,
        CheckBoxFormatPipe,
        InfoTooltipComponent,
        SideNavComponent,
        MatDatepickerModule,
        MatFormFieldModule,
        BrowserAnimationsModule,
        MatInputModule,
        MatIconModule,
        FileUploadModule,
        MatChipsModule,
        MatDialogModule,
        MatAutocompleteModule,
        NotificationComponent,
        HistoryComponent,
        BlankValuePipe,
        FieldEmptyPipe,
        SapResult,
        ToggleButtonComponent,
        PrivacyStatementPoppageComponent,
        NotificationHeaderComponent,
        NotificationCenterComponent,
        SystemAdminComponent,
        TermOfUseComponent,
        LoadingComponent,
        DynamicComponentDirective,
        DynamicFormComponent,
        DynamicViewComponent,
        CheckBoxComponent,
        CollapseComponent,
        UploaderComponent,
        ReactiveFormsModule,
        CustomizedMultiple,
        UploaderViewComponent,
        TextboxViewComponent,
        ItemComponent,
        TextHrefComponent,
        CollapseOfCountryComponent,
        SearchableDropdownComponent,
        PaymentBankingInformationOfCollapseComponent,
        ThirdPartyPaymentOptionOfCollapseComponent,
        PeoplepickerComponent,
        CustomizedPeoplepicker,
        PeopleChipsComponent,
        AdditionalGSTRegistrationOfCollapseComponent,
        LangStrPipe,
        LangItemPipe,
        TranslateWithStylePipe,
        SecurityTrustHtmlPipe,
        CustomDatePipe,
        LanguageComponent,
        TimeoutPopupComponent,
        TimeoutPopupContentComponent,
        MatButtonModule
    ],
    providers: [
        DomHandler,
        WebSocketService,
        LoadingService,
        WorkflowService,
        DynamicComponentService,
        CustomHooks,
        CustomValidators,
        CustomFilters,
        CustomDatasources,
        CustomExpressions,
        HoldOnService,
        { provide: HTTP_INTERCEPTORS, useClass: ResInterceptor, multi: true },
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE],
        },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    ]
})
export class SharedModule {
  constructor(
    private componentService: DynamicComponentService,
    private customHooks: CustomHooks,
    private validator: CustomValidators,
    private customFilters: CustomFilters,
    private customDatasources: CustomDatasources,
    private customExpressions: CustomExpressions
  ) {
    this.componentService.globalViewOnlyComponent = TextboxComponent;
    this.componentService.registerComponent("textbox", TextboxComponent);
    this.componentService.registerComponent("input", InputComponent);
    this.componentService.registerComponent("dropdown", DropdownComponent);
    this.componentService.registerComponent("radio", RadioComponent);
    this.componentService.registerComponent("checkbox", CheckBoxComponent);
    this.componentService.registerComponent(
      "collapse",
      CollapseComponent,
      true
    );
    this.componentService.registerComponent("uploader", UploaderComponent);
    this.componentService.registerComponent("datepicker", CustomizedDatepicker);
    this.componentService.registerComponent("multiple", CustomizedMultiple);
    this.componentService.registerComponent(
      "uploaderview",
      UploaderViewComponent
    );
    this.componentService.registerComponent(
      "textboxview",
      TextboxViewComponent
    );
    this.componentService.registerComponent("item", ItemComponent);
    this.componentService.registerComponent(
      "peoplepicker",
      PeoplepickerComponent
    );
    this.componentService.registerViewOnlyComponent("input", "textbox");
    this.componentService.registerViewOnlyComponent("number", "textbox");
    this.componentService.registerViewOnlyComponent(
      "datepicker",
      "textboxview"
    );
    this.componentService.registerViewOnlyComponent("uploader", "uploaderview");
    this.componentService.registerViewOnlyComponent("dropdown", "textboxview");
    this.componentService.registerViewOnlyComponent("phone", "textboxview");
    this.componentService.registerViewOnlyComponent("phone", "textboxview");
    this.componentService.registerViewOnlyComponent("multiple", "textboxview");
    this.componentService.registerViewOnlyComponent(
      "PaymentBankingInformationOfCollapse",
      "PaymentBankingInformationOfCollapse"
    );
    this.componentService.registerViewOnlyComponent(
      "ThirdPartyPaymentOptionOfCollapse",
      "ThirdPartyPaymentOptionOfCollapse"
    );
    this.componentService.registerViewOnlyComponent(
      "AdditionalGSTRegistrationOfCollapse",
      "AdditionalGSTRegistrationOfCollapse"
    );
    this.componentService.registerViewOnlyComponent(
      "searchableDropdown",
      "textboxview"
    );
    this.componentService.registerComponent("phone", PhoneComponent);
    this.componentService.registerComponent("number", NumberComponent);
    this.componentService.registerDatasourceClass(customDatasources);
    this.componentService.registerCustomValidatorClass(validator);
    this.componentService.registerCustomFilterClass(customFilters);
    this.componentService.registerCustomHookClass(customHooks);
    this.componentService.registerCustomExpressionClass(customExpressions);
    this.componentService.registerComponent(
      "countryOfCollapse",
      CollapseOfCountryComponent
    );
    this.componentService.registerComponent(
      "searchableDropdown",
      SearchableDropdownComponent
    );
    this.componentService.registerComponent(
      "PaymentBankingInformationOfCollapse",
      PaymentBankingInformationOfCollapseComponent
    );
    this.componentService.registerComponent(
      "ThirdPartyPaymentOptionOfCollapse",
      ThirdPartyPaymentOptionOfCollapseComponent
    );
    this.componentService.registerComponent(
      "AdditionalGSTRegistrationOfCollapse",
      AdditionalGSTRegistrationOfCollapseComponent
    );
  }
}
