export function cloneDeep(obj: any) {
  const objClone: any = Array.isArray(obj) ? [] : {};
  if (obj && typeof obj === "object") {
    for (const key in obj) {
      if (
        obj[key] &&
        typeof obj[key] === "object" &&
        !(obj[key] instanceof Date)
      ) {
        objClone[key] = cloneDeep(obj[key]);
      } else if (typeof obj[key] != "undefined") {
        objClone[key] = obj[key];
      }
    }
  }
  return objClone;
}
