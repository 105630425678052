import {
  Component,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ChangeDetectorRef,
} from "@angular/core";
import { DynamicComponentService } from "../../services/dynamic-component.service";
import { UntypedFormBuilder } from "@angular/forms";
import { ComponentConfig } from "../../ComponentConfig";
import { DynamicFormComponent } from "../dynamic-form/dynamic-form.component";
import { LanguageService } from "../../utils/language.service";

@Component({
  selector: "app-dynamic-view",
  template: `
    <div class="dynamic-row">
      <ng-container
        *ngFor="let config of configs"
        appDynamicComponent
        [viewOnly]="true"
        [formGroup]="formGroup"
        [context]="context"
        [config]="config"
      >
      </ng-container>
    </div>
  `,
  styleUrls: ["./dynamic-view.component.sass"],
})
export class DynamicViewComponent
  extends DynamicFormComponent
  implements OnChanges, OnDestroy
{
  constructor(
    formBuilder: UntypedFormBuilder,
    service: DynamicComponentService,
    changeDetector: ChangeDetectorRef,
    languageService: LanguageService
  ) {
    super(formBuilder, service, changeDetector, languageService);
    this.disabled = true;
  }

  ngOnChanges(changes: SimpleChanges): any {
    if (
      !changes.configs ||
      !changes.configs.currentValue ||
      changes.configs.currentValue.length === 0
    ) {
      return;
    }
    this.changeViewOnlyComponents();
    super.ngOnChanges(changes);
  }

  changeViewOnlyComponents() {
    this.configs.forEach((c) => this.changeViewOnlyComponent(c));
  }

  changeViewOnlyComponent(config: ComponentConfig) {
    if (this.service.isContainerComponent(config.type)) {
      config.fieldset.forEach((def) => this.changeViewOnlyComponent(def));
      return;
    }
    const viewOnlyType = this.service.getViewOnlyComponent(config.type);
    if (viewOnlyType) {
      config.type = viewOnlyType;
    }
  }

  createValidators() {
    return null;
  }

  createAsyncValidators() {
    return null;
  }
}
