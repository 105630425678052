// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.example-chip-list {
  width: 100%;
}

.peoplepicker-drop-panel {
  height: 250px;
  border-left: solid 0.9px #ddd;
  border-bottom: solid 0.9px #ddd;
  border-right: solid 0.9px #ddd;
  padding: 6px;
  overflow-y: auto;
  position: absolute;
  background-color: #fff;
  z-index: 9999;
  width: 100%;
}

.peoplepicker-drop-option {
  cursor: pointer;
  margin-top: 1.5rem;
}
.peoplepicker-drop-option:first-child {
  margin-top: 0;
}

.peoplepicker-drop-option-span {
  padding: 0.5rem 0px 0px 0.5rem;
}

::ng-deep .cdk-overlay-container {
  z-index: 9999 !important;
}

::ng-deep .cdk-overlay-pane {
  z-index: 9999 !important;
}

::ng-deep .mat-form-field-appearance-legacy .mat-form-field-underline {
  height: 0px !important;
}

::ng-deep.mat-form-field-ripple {
  height: 0px !important;
}

.chips-border {
  background: #f6f5fa;
  border-radius: 0.25rem;
  border: solid 1px #414187;
}

::ng-deep .mat-mdc-form-field-bottom-align::before {
  display: none !important;
}

::ng-deep .mat-mdc-form-field-bottom-align::before {
  display: none !important;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
