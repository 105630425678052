import { FormGroup } from "@angular/forms";
export interface RequestForm {
  RequestorDetailsInfo?: RequestorDetailsInfo;
  RequestFormInfo?: RequestFormInfo;
  DeclarationInfo?: DeclarationInfo;
  RiskAssessmentInfo?: RiskAssessmentInfo;
}

export function createRequestForm(): RequestForm {
  return {
    RequestorDetailsInfo: {},
    RequestFormInfo: {},
    DeclarationInfo: {},
    RiskAssessmentInfo: {},
  };
}

/// ############ buyer model###############
export interface RequestorDetailsInfo {
  CompanyCode?: Array<string>;
  RequestedDate?: Date;
  RequestorName?: string;
  RequestorEid?: string;
  IsEmployee?: string;
  SupplierCompanyName?: string;
  // BehalfName?: string
  BehalfEid?: string;
  BehalfAnother?: string;
  RequestorEmail?: string;
  BehalfEmail?: string;
  SupplierGeographical?: string;
  BusinessOrganization?: string;
  ExecutiveSponsor?: string;
  SupplierCountry?: Array<string>;
  SupplierContactPoint?: string;
  SupplierContactFirstName?: string;
  SupplierContactLastName?: string;
  SupplierPOCEmail?: string;
  SpendCommodityGroup?: string;
  SpendCategoryGroup?: string;
  SpendSubCategory?: string;
  TypeOfServiceRequired?: string;
  PaymentPurposeCode?: string;
  ServicesDescription?: string;
  SignContract?: string;
  ContractStatus?: string;
  ContractStatusForPH?: string;
  CategoryManagerName?: string;
  CategoryManagerNameForPH?: string;
  OnetimeOrRecurringPurchases?: string;
  AlreadyStartedProvidingRequestorDetails?: string;
  PO?: string;
  HoldStatus?: string;
  HoldExpiryDate?: string;
  SignedQuotationAttachments?: any;
  TmsId?: string;
  ContractStatusForGallia?: any
  ContractAttachmentsForGallia?: any
  ContractNegotiationsForGallia?: any
  ProcurementTeam?: any
  IndependentContractor?:string
}

export interface RequestFormInfo {
  PaymentTermList?: Array<PaymentTerm>;
  HaveSupplier?: string;
  RequestJustification?: string;
  SupplierIsAccentureClient?: string;
  BuyingRelationship?: string;
  EnterClientInfo?: string;
  OnetimeOrRecurringPurchases?: string;
  TotalSpendOfSupplier?: string;
  AlreadyStartedProviding?: string;
  AlreadySendInvoice?: string;
  WhetherChooseSuppliers?: string;
  EnterRegistrationNum?: string;
  AddRequestFormAttachments?: any;
  DecisionOfSupplier?: any;
  DecisionOfSupplierDetails?: string;
  AlreadySendInvoiceForPH?: any;
  plannedDate?: string;
}

export interface DeclarationInfo {
  Declaration_1?: string;
  Declaration_2?: string;
  Declaration_3?: string;
  Declaration_4?: string;
  Declaration_5?: string;
  Declaration_6?: string;
}

export interface RiskAssessmentInfo {
  BI_BDA_1?: string;
  BI_BDA_1a?: string;
  BI_BDA_1b?: string;
  BI_BDA_1bStartDate?: string;
  BI_BDA_1bEndDate?: string;
  BI_BDA_1c?: string;
  InformationSecurity_1?: string;
  InformationSecurity_1Yes?: string;
  InformationSecurity_1a?: string;
  InformationSecurity_1b?: string;
  InformationSecurity_1c?: string;
  InformationSecurity_1ca_Others?: string;
  InformationSecurity_1ca?: any[];
  InformationSecurity_1cb?: any[];
  InformationSecurity_1cc?: any[];
  InformationSecurity_1cd?: string;
  InformationSecurity_1cd_1?: string;
  InformationSecurity_1ce?: string;
  InformationSecurity_1cf?: string;
  InformationSecurity_1cg?: string;
  InformationSecurity_1d?: any[];
  InformationSecurity_1e?: string;
  InformationSecurity_1f?: string;
  InformationSecurity_1g?: string;
  InformationSecurity_1ga?: string;
  InformationSecurity_1gb?: string;
  InformationSecurity_1h?: string;
  InformationSecurity_1i?: string;
  InformationSecurity_1j?: string;
  InformationSecurity_2?: string;
  InformationSecurity_2a?: string;
  InformationSecurity_3?: string;
  InformationSecurity_3a?: string;
  BI_BDA_2?: string;
  InformationSecurity?: any;
  InformationSecurity_1a_new?: any;
  InformationSecurity_1a_no?: any;
  InformationSecurity_1b_new?: any;
  InformationSecurity_1c_1?: any;
  InformationSecurity_1c_2?: any;
  InformationSecurity_1c_2_other?: any;
  InformationSecurity_1c_new?: any;
  InformationSecurity_1ca_2?: any;
  InformationSecurity_1ca_2_other?: any;
  InformationSecurity_1cb_new?: any;
  InformationSecurity_1cb_other?: any;
  InformationSecurity_1cd_2?: any;
  InformationSecurity_1cd_3?: any;
  InformationSecurity_1cd_3_1?: any;
  InformationSecurity_1cd_4?: any;
  InformationSecurity_1cd_4_NO?: any;
  InformationSecurity_1cd_4_YES?: any;
  InformationSecurity_1cd_5?: any;
  InformationSecurity_1cd_5_other?: any;
  InformationSecurity_1cd_new?: any;
  InformationSecurity_1ce_1?: any;
  InformationSecurity_1cf_new?: any;
  InformationSecurity_1cg_new?: any;
  InformationSecurity_1d_1?: any;
  InformationSecurity_1eYes?: any;
  InformationSecurity_1e_new?: any;
  InformationSecurity_1f_1?: any;
  InformationSecurity_1g_1?: any;
  InformationSecurity_1h_1?: any;
  InformationSecurity_4?: any;
  InformationSecurity_4a?: any;
  InformationSecurity_5?: any;
  InformationSecurity_6?: any;
  InformationSecurity_12?: any;
}
export class PaymentTerm {
  PaymentTermId?: number;
  TimeLimit?: string;
  TermPayment?: string;
  Country?: string;
  CompanyCode?: string;
  HasNonStandardPaymentTerms?: string;
  AgreementAttachments?: any;
  ApproveAttachments?: any;
  ProcurementApproval?: any;
  LanguageKey?: string;
}

export class CompanyCode {
  companyCode: [];
}
export class PaymentTermDTO {
  ID?: number;

  Country?: string;

  CompanyCode?: string;

  PaymentTerm?: string;

  formGroup?: any;

  PaymentTermCountryKey?: string;
}

export class PaymentTermIterm {
  TimeLimit?: string;
  TermPayment?: string;
  HasNonStandardPaymentTerms?: string;
  AgreementAttachments?: any;
  ApproveAttachments?: any;
  ProcurementApproval?: any;
}
