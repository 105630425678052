import { Component, OnInit } from "@angular/core";
import { HoldOnService } from "src/app/services/HoldOn";
import { HoldOn, initHoldOn } from "src/app/interfaces/HoldOn";
import { OnBoardingService } from "../../../services/onboarding.service";
import { AuthService } from "src/app/services/auth/auth.service";
import { environment } from "src/environments/environment";
import { LoadingUrlMapping } from "src/app/interfaces/mapping";
import { LoadingService } from "src/app/services/loading.service";

@Component({
  selector: "app-remove-hold",
  templateUrl: "./remove-hold.component.html",
  styleUrls: ["./remove-hold.component.sass"],
})
export class RemoveHoldComponent implements OnInit {
  reason: string;
  isValidated = false;
  constructor(
    private holdOnService: HoldOnService,
    private onBoardingService: OnBoardingService,
    private loadingService: LoadingService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.isValidated = false;
  }

  async submit() {
    if (!this.validate()) {
      return { isValidate: false, isSuccess: false };
    }
    try {
      const saveRequestFormUrl = `${environment.gateway}${LoadingUrlMapping.saveEditHold}`;
      this.loadingService.openLoading(saveRequestFormUrl);
      const supplier = this.onBoardingService.supplierModel;
      const holdOn = initHoldOn(supplier.Mapping.supplierCode);
      holdOn.IsActive = false;
      holdOn.CreatedBy = this.authService.passport.profile.email;
      holdOn.CreatedDate = new Date();
      holdOn.ExpirationDate = new Date(
        new Date().getTime() + 1000 * 3600 * 24 * 7
      );
      holdOn.Source = "Remove Hold";
      holdOn.Comments = this.reason;
      const result = await this.holdOnService.saveHoldOn(holdOn);
      if (result) {
        this.loadingService.closeLoading();
      }
      return { isValidate: true, removeIsSuccess: result };
    } catch (e) {
      return { isValidate: true, removeIsSuccess: false };
    }
  }
  validate(): boolean {
    this.isValidated = true;
    if (!this.reason) {
      return false;
    }
    return true;
  }

  closeDialog() {
    this.isValidated = false;
  }
}
