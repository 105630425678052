import { Injectable } from "@angular/core";
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot } from "@angular/router";
import { ProfileService } from "./profile.service";
import { SupplierService } from "src/app/services/supplier.service";
import { AuthService } from "src/app/services/auth/auth.service";
import {
  SupplierModel,
  createSupplierModel,
} from "src/app/interfaces/supplierModel";
import {
  SupplierPendingStatus,
  LoadingUrlMapping,
} from "src/app/interfaces/mapping";
import { ProcessNames, SetupProcess } from "src/app/interfaces/workflow";
import { LoadingService } from "src/app/services/loading.service";
import { environment } from "src/environments/environment";

@Injectable()
export class ProfileResolver {
  constructor(
    private supplierService: SupplierService,
    private router: Router,
    private auth: AuthService,
    private loadingService: LoadingService,
    private profileService: ProfileService
  ) {}

  async resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<SupplierModel> {
    const supplierCode = route.paramMap.get("code");
    if (supplierCode) {
      const loadingUrl = `${environment.gateway}${LoadingUrlMapping.saveProfileForm}`;
      try {
        this.loadingService.openLoading(loadingUrl);
        await this.profileService.loadSupplierProfile();
        // workflow
        await this.profileService.loadUserTasks();
        if (
          this.profileService.getUserTaskByKey(
            ProcessNames.generic_supplierSetup,
            SetupProcess.profile_draft
          )
        ) {
          this.profileService.mode = "create";
          console.log("create supplier profile");
          this.profileService.supplierModel.SupplierProfile =
            this.auth.passport.supplier.SupplierProfile;
        } else {
          this.auth.passport.supplier = this.profileService.supplierModel;
        }
      } finally {
        this.loadingService.closeLoading();
      }
    }
    return;
  }
}
