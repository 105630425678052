import { Injectable } from "@angular/core";
import { CustomHook, CustomValidator } from "../interfaces/Decorators";
import { ComponentConfig } from "../ComponentConfig";
import { DynamicContext } from "../interfaces/DynamicContext";
import {
  FormGroup,
  ValidatorFn,
  AbstractControl,
  ValidationErrors,
} from "@angular/forms";

@Injectable()
export class ValidatorDecoratorComposition {
  private emailPattern =
    /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,})$/;

  @CustomValidator({ name: "email", async: false })
  public email(config: ComponentConfig, context: DynamicContext): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }
      const result = this.emailPattern.test(control.value);
      return !result ? { email: true } : null;
    };
  }
}
