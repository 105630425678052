import { Component, OnInit } from "@angular/core";
import { OnBoardingService } from "../../../services/onboarding.service";
@Component({
  selector: "app-history-layout",
  templateUrl: "./history-layout.component.html",
  styleUrls: ["./history-layout.component.sass"],
})
export class HistoryLayoutComponent implements OnInit {
  constructor(private onBoardingService: OnBoardingService) {
    this.onBoardingService.profileStage = "history";
  }

  ngOnInit() {}
}
