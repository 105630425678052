// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header .banner {
  padding: 2rem;
  height: 15rem;
}
.header .banner .back_to {
  color: #fff;
  font-weight: 600;
  font-size: 1.25rem;
}
.header .banner .back_to .material-icons {
  font-size: 1.25rem;
  margin-right: 0.75rem;
}
.header .banner .header_text {
  padding: 2.1rem 0 0;
  font-size: 1.75rem;
  color: #fff;
}
.header .banner .header_text .circle {
  width: 5.5rem;
  height: 5.5rem;
  line-height: 5.5rem;
  text-align: center;
  border-radius: 50%;
  border: #FFF 1px solid;
  margin-right: 2rem;
}
.header .banner .header_text .circle .material-icons {
  font-size: 3.5rem;
}
.header .banner .header-search {
  margin-left: 110px;
}
.header .banner .header-search .search_bg {
  display: inline;
  background: #fff;
  border-radius: 1.5rem;
  padding: 1rem;
}
.header .banner .header-search .search_bg .material-icons {
  margin-right: 0.5rem;
}
.header .banner .header-search .btn-user-create {
  height: 40px;
  margin-left: 1.5rem;
  font-size: auto;
  color: #0F5284;
  background-color: white;
}
.header .banner .menu_items .item {
  margin-right: 5rem;
  text-align: center;
  color: #fff;
  opacity: 0.5;
}
.header .banner .menu_items .item .circle {
  margin: 0 auto;
  width: 5.5rem;
  height: 5.5rem;
  line-height: 5.5rem;
  text-align: center;
  border-radius: 50%;
  border: #FFF 1px solid;
  margin-bottom: 2rem;
}
.header .banner .menu_items .item .circle .dashboard {
  font-size: 3.5rem;
  width: 4rem;
}
.header .banner .menu_items .item .circle .my_account {
  font-size: 3.5rem;
}
.header .banner .menu_items .item .circle .my_suppliers {
  font-size: 3.5rem;
}
.header .banner .menu_items .active {
  opacity: 1;
}

.btnCss {
  cursor: pointer;
}

.search-box {
  transition: width 0.6s, border-radius 0.6s, background 0.6s, box-shadow 0.6s;
  width: 180px;
  border-radius: 20px;
  border: none;
  cursor: pointer;
  background: #fff;
  color: #0E5284;
  font-size: 18px;
}

.search_bg:hover {
  color: #0E5284;
  font-size: 18px;
  color: #0E5284;
  background: white;
  box-shadow: 0 0 0 3px #ccc;
}

.search-box .search-container {
  position: absolute;
  top: 100px;
  right: 100px;
}

.search-box:focus {
  transition: width 0.6s cubic-bezier(0, 1.22, 0.66, 1.39), border-radius 0.6s, background 0.6s;
  border: none;
  outline: none;
  box-shadow: none;
  cursor: text;
  width: 300px;
  border-radius: auto;
  background: #fff;
  color: #0E5284;
  font: 18px 500;
  font-weight: 500;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
