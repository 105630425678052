import { Component, OnInit } from "@angular/core";
import { BaseComponent } from "../base/base.component";
import { Container } from "../../interfaces/Container";

@Component({
  selector: "app-layout",
  host: {
    "[style.width]": "'100%'",
  },
  template: ` <div #layout class="dynamic-row" *ngIf="!config.hide">
    <ng-container
      *ngFor="let fieldConfig of config.fieldset"
      appDynamicComponent
      [formGroup]="formGroup"
      [config]="fieldConfig"
      [context]="context"
    >
    </ng-container>
  </div>`,
  styleUrls: ["./layout.component.sass"],
})
export class LayoutComponent extends BaseComponent implements Container {
  constructor() {
    super();
  }
}
