import { Directive, EventEmitter, Injectable, Output } from '@angular/core'

@Directive()
@Injectable({
  providedIn: "root",
})
export class EmitService {
  @Output() transferHide: EventEmitter<any> = new EventEmitter();
  @Output() transferRemove: EventEmitter<any> = new EventEmitter();

  /**
   * Component value transfer
   * @param value
   */
  setHideValue(value: any) {
    this.transferHide.emit(value);
  }

  /**
   * Component value transfer
   * @param value
   */
  setRemoveValue(value: any) {
    this.transferRemove.emit(value);
  }
}
