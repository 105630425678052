import { Component, OnInit } from "@angular/core";
import { OnBoardingService } from "../../../../modules/buyer/services/onboarding.service";
import { BuyerService } from "../../../../services/buyer.service";
import {
  UntypedFormArray,
  UntypedFormGroup,
  UntypedFormControl,
} from "@angular/forms";
import { DynamicContext } from "../../../../dynamic-components/interfaces/DynamicContext";
import { ComponentConfig } from "../../../../dynamic-components/ComponentConfig";
import { BaseComponent } from "src/app/dynamic-components/components/base/base.component";
import { ProfileService } from "src/app/modules/supplier/services/profile.service";
import { cloneDeep } from "src/app/utils";
import { EmitService } from "./emit.service";

@Component({
  selector: "app-payment-banking-information-of-collapse",
  templateUrl: "./payment-banking-information-of-collapse.component.html",
  styleUrls: ["./payment-banking-information-of-collapse.component.sass"],
  host: {
    "[style.width]": "config.width",
  },
})
export class PaymentBankingInformationOfCollapseComponent
  extends BaseComponent
  implements OnInit
{
  paymentBankingInformationListVO = [];
  paymentBankingInformationListFormArray: UntypedFormArray;
  paymentBankingInformationList: any[];
  hidePluBbutton = false;
  constructor(
    private onBoardingService: OnBoardingService,
    private buyerService: BuyerService,
    private profileService: ProfileService,
    private emitService: EmitService
  ) {
    super();
  }

  ngOnInit() {
    this.paymentBankingInformationListFormArray =
      this.getPaymentBankingInformationListFormArray();
    this.paymentBankingInformationList =
      this.getPaymentBankingInformationList();
    //  let paymentBankingInformationList = this.getPaymentBankingInformationList()
    if (
      this.paymentBankingInformationList &&
      this.paymentBankingInformationList.length > 0
    ) {
      this.initPaymentBankingInformationByPaymentBankingInformationList();
    } else {
      this.initPaymentBankingInformationByclicking();
    }
    if(Array.isArray(this.context.get("parentModel").value.SupplierProfile.SupplierFinancial.PaymentBankingInformationList)) {
      const isOtherPESONetregistered =
      this.context.get("parentModel").value.SupplierProfile.SupplierFinancial
      .PaymentBankingInformationList[0].isOtherPESONetregistered;
      if (!!isOtherPESONetregistered && isOtherPESONetregistered == "N") {
        this.hidePluBbutton = true;
      }
    }
  }

  ngAfterViewInit() {
    const isOtherPESONetregisteredControl = this.formGroup.get(
      "PaymentBankingInformationList.0.isOtherPESONetregistered"
    ) as UntypedFormControl;
    if (isOtherPESONetregisteredControl) {
      isOtherPESONetregisteredControl.valueChanges.subscribe((value) => {
        this.checkPESONetregistered(value);
      });
    }
  }

  getPaymentBankingInformationList(): Array<any> {
    if (this.context.getValue("supplierModel")) {
      return this.context.getValue("supplierModel").SupplierFinancial
        .PaymentBankingInformationList;
    }
    return this.context.getValue("parentModel").SupplierProfile
      .SupplierFinancial.PaymentBankingInformationList;
  }
  getPaymentBankingInformationListFormArray(): UntypedFormArray {
    return this.formGroup.get(this.config.name) as UntypedFormArray;
  }

  /**
   * 
   * @returns boolean
   */
  isChinaCountry(): boolean {
    const chinaCountry: string[] = ["CN", "TW", "MO", "HK"];
    const supplierCountry: string =
      (this.context.getValue("parentModel").RequestForm.RequestorDetailsInfo
        .SupplierCountry).toString();
    return chinaCountry.indexOf(supplierCountry) !== -1 ? true : false;
  }

  isNotViewAndformView(): boolean {
    if (this.context.mode !== "view" && this.context.mode !== "formView") {
      const flag: boolean = !this.isChinaCountry();
      this.emitService.setHideValue(flag)
      return flag;
    }
  }

  checkPESONetregistered(v: any) {
    if (v == "N") {
      while (this.paymentBankingInformationListVO.length > 1) {
        this.removePaymentBankingInformation(
          this.paymentBankingInformationListVO[1]
        );
      }
      this.hidePluBbutton = true;
    } else {
      this.hidePluBbutton = false;
    }
  }
  initPaymentBankingInformationByPaymentBankingInformationList() {
    for (const PaymentBankingInformation of this
      .paymentBankingInformationList) {
      const paymentBankingInformationformGroup = new UntypedFormGroup({});
      const idFormControl = new UntypedFormControl();
      // idFormControl.patchValue(PaymentBankingInformation.paymentBankingInformationId, { onlySelf: true, emitEvent: true })
      idFormControl.patchValue(
        PaymentBankingInformation.paymentBankingInformationId
          ? PaymentBankingInformation.paymentBankingInformationId
          : Math.random(),
        { onlySelf: true, emitEvent: true }
      );
      paymentBankingInformationformGroup.addControl(
        "paymentBankingInformationId",
        idFormControl
      );
      if (
        !PaymentBankingInformation.BankCountry &&
        !PaymentBankingInformation.PaymentMethod &&
        !PaymentBankingInformation.BankNm &&
        !PaymentBankingInformation.BankAds &&
        !PaymentBankingInformation.BankBranchNm &&
        !PaymentBankingInformation.AccountHolderNm &&
        !PaymentBankingInformation.Account &&
        !PaymentBankingInformation.BankKey &&
        !PaymentBankingInformation.IBAN &&
        !PaymentBankingInformation.BIC_SwiftCd &&
        !PaymentBankingInformation.ABARouting &&
        !PaymentBankingInformation.CurrencyUsedForPayments &&
        !PaymentBankingInformation.AddFinancialAttachments &&
        !PaymentBankingInformation.AddAgreementAttachments
      ) {
        continue;
      }
      this.paymentBankingInformationListFormArray.push(
        paymentBankingInformationformGroup
      );
      const context = new DynamicContext();
      context.parent = this.context;
      const paymentBankingInformationDTO = {
        paymentBankingInformation: PaymentBankingInformation,
        formGroup: paymentBankingInformationformGroup,
        context: context,
        config: cloneDeep(this.config.fieldset),
        dashboradConfig: this.initViewConfig(
          PaymentBankingInformation,
          cloneDeep(this.config.fieldset)
        ),
        ispaymentBankingInformationShow: true,
      };
      this.paymentBankingInformationListVO.push(paymentBankingInformationDTO);
    }
    if (!this.profileService.isView) {
      this.context.mode = "edit";
    }
  }
  initPaymentBankingInformationByclicking() {
    const paymentBankingInformationformGroup = new UntypedFormGroup({});
    const idFormControl = new UntypedFormControl();
    idFormControl.patchValue(Math.random(), {
      onlySelf: true,
      emitEvent: true,
    });
    paymentBankingInformationformGroup.addControl(
      "paymentBankingInformationId",
      idFormControl
    );
    this.paymentBankingInformationListFormArray.push(
      paymentBankingInformationformGroup
    );
    const context = new DynamicContext();
    context.parent = this.context;
    const bankInformationConfigs = cloneDeep(this.config.fieldset);
    if (this.paymentBankingInformationListVO.length != 0) {
      for (let i = 0; i < bankInformationConfigs.length; i++) {
        if (bankInformationConfigs[i].type == "layout") {
          if (
            !!bankInformationConfigs[i].fieldset[0] &&
            bankInformationConfigs[i].fieldset[0].name == "isPESONetregistered"
          ) {
            bankInformationConfigs.splice(i, 1);
          }
        }
      }
    }
    const paymentBankingInformationDTO = {
      paymentBankingInformation: {},
      formGroup: paymentBankingInformationformGroup,
      context: context,
      config: bankInformationConfigs,
      ispaymentBankingInformationShow: true,
    };
    this.paymentBankingInformationListVO.push(paymentBankingInformationDTO);
  }
  // eslint-disable-next-line @typescript-eslint/no-shadow
  initViewConfig(
    paymentBankingInformation: any,
    config: Array<ComponentConfig>
  ) {
    const conf = new Array<ComponentConfig>();
    config.forEach((p) => {
      if (p.fieldset) {
        p.fieldset.forEach((z) => {
          if (
            paymentBankingInformation[z.name] instanceof Array &&
            paymentBankingInformation[z.name].length > 0
          ) {
            conf.push(z);
          }
          if (
            !(paymentBankingInformation[z.name] instanceof Array) &&
            paymentBankingInformation[z.name]
          ) {
            conf.push(z);
          }
        });
      } else {
        if (
          paymentBankingInformation[p.name] instanceof Array &&
          paymentBankingInformation[p.name].length > 0
        ) {
          conf.push(p);
        }
        if (
          !(paymentBankingInformation[p.name] instanceof Array) &&
          paymentBankingInformation[p.name]
        ) {
          conf.push(p);
        }
      }
    });
    return conf;
  }
  addPaymentBankingInformation() {
    // if (this.paymentBankingInformationListFormArray.length === 10) {
    //   return
    // }

    this.initPaymentBankingInformationByclicking();
  }
  removePaymentBankingInformation(paymentBankingInformation: any) {
    if (this.paymentBankingInformationListFormArray.length === 1) {
      return;
    }
    const removedId = paymentBankingInformation.formGroup.get(
      "paymentBankingInformationId"
    ).value;
    this.paymentBankingInformationListVO =
      this.paymentBankingInformationListVO.filter(
        (p) =>
          p.formGroup.get("paymentBankingInformationId").value !== removedId
      );
    const index =
      this.paymentBankingInformationListFormArray.controls.findIndex(
        (p) => p.get("paymentBankingInformationId").value === removedId
      );
    this.paymentBankingInformationListFormArray.removeAt(index);
  }
}
