import {
  Component,
  Input,
  ElementRef,
  NgZone,
  Renderer2,
  AfterViewInit,
  AfterViewChecked,
  OnDestroy,
  ViewChild,
} from "@angular/core";
import {
  CRConfirmation,
  DialogType,
  ChangeRequestAPReviewService,
  ButtonItem,
  DialogService,
  ButtonCommands,
} from "src/app/services/dialog.service";
import { Subscription } from "rxjs";
import { trigger, state, style } from "@angular/animations";
import { DomHandler } from "../dialog/domHandler";
import {
  LoadingUrlMapping,
  SapResultModel,
  createAPReviewModel,
  createAPApproveItem,
  SpendCategoryGroup,
} from "src/app/interfaces/mapping";
import { OnBoardingService } from "./../../../modules/buyer/services/onboarding.service";
import { environment } from "src/environments/environment";
import { LoadingService } from "src/app/services/loading.service";
import { BuyerService } from "src/app/services/buyer.service";
import { AuthService } from "src/app/services/auth/auth.service";
import * as moment from "moment";
import {
  ChangeRequestType,
  AuditChangeRequestDetail,
  initAuditChangeRequestDetail,
  AddCompanyCodeDetailsList,
  ChangeRequestDetailStatus,
  initDetailForAddCompanyCode,
  BuyerChangeRequestDTO,
  ChangeRequestStatus,
} from "src/app/interfaces/changeRequest";
import { BuyerChangeRequestService } from "src/app/modules/buyer/services/buyerChangeRequest.service";
import { Router } from "@angular/router";
import { RoleName } from "src/app/interfaces/buyer";
import { SetupProcess, Task } from "src/app/interfaces/workflow";
import { PaymentTermDTO } from "src/app/interfaces/requestForm";
import { DictionaryService } from "src/app/services/dictionary.service";
import { LanguageService } from "../../../dynamic-components/utils/language.service";
import { cloneDeep } from "src/app/utils";
import { split } from "lodash";
import { LaunchDarklyService } from "src/app/services/launch-darkly.service";

@Component({
  selector: "app-change-request-dialog-form",
  templateUrl: "./change-request-dialog-form.component.html",
  styleUrls: ["./change-request-dialog-form.component.sass"],
  animations: [
    trigger("dialogState", [
      state(
        "hidden",
        style({
          opacity: 0,
        })
      ),
      state(
        "visible",
        style({
          opacity: 1,
        })
      ),
    ]),
  ],
})
export class ChangeRequestDialogFormComponent
  implements AfterViewInit, AfterViewChecked, OnDestroy
{
  confirmation: CRConfirmation;
  subscription: Subscription;
  @Input() key: string;
  @Input() dialogType: string;
  @Input() header: string;
  @Input() icon: string;
  @Input() message: string;
  @Input() buttons: ButtonItem[];
  @Input() width: any;
  @Input() height: any;
  @Input() closeOnEscape = true;
  @Input() rtl: boolean;
  @Input() closable = true;
  @Input() responsive = true;
  @Input() appendTo: any;
  @ViewChild("dialogForm") dialogForm: ElementRef;
  _visible: boolean;
  documentEscapeListener: any;
  documentResponsiveListener: any;
  mask: any;
  contentContainer: any;
  positionInitialized: boolean;
  executePostShowActions: boolean;
  title = "title";
  isValidated: boolean;
  auditChangeRequestDetail: AuditChangeRequestDetail;
  openChangeRequest: BuyerChangeRequestDTO[];
  auditDetail: AuditChangeRequestDetail;
  actionTaken: string;
  isSubmitDisabled = false;
  comments = "";
  apApproveList: Array<any> = [];
  sapIdValue: Array<string> = [];
  private dictTaken: any;
  changeRequestDetailStatus: any;
  isNewVersion: boolean = true;
  crType = ChangeRequestType;
  changRequestType: string;
  sapResultList: SapResultModel;
  currentChangeRequest: BuyerChangeRequestDTO;
  lastAudit: AuditChangeRequestDetail;
  supplierCode: any;
  creationAPValidated = true;
  // workflow task
  taskInst: Task;
  coupaFlag = false;
  constructor(
    public el: ElementRef,
    public domHandler: DomHandler,
    public zone: NgZone,
    public renderer: Renderer2,
    private changeRequestAPReviewService: ChangeRequestAPReviewService,
    private dialogService: DialogService,
    private onboardingService: OnBoardingService,
    private loadingService: LoadingService,
    private buyerService: BuyerService,
    private auth: AuthService,
    private buyerChangeRequest: BuyerChangeRequestService,
    private router: Router,
    private dictionaryService: DictionaryService,
    private languageService: LanguageService,
    private launchDarklyService: LaunchDarklyService
  ) {
    this.launchDarklyService.ldChange.subscribe((any) => {
      this.refreshToggle();
    });
    this.changeRequestDetailStatus = ChangeRequestDetailStatus;
    this.subscription =
      changeRequestAPReviewService.changeRequestRequireReview$.subscribe(
        async (confirmation) => {
          // workflow load current login user's tasks
          // await this.onboardingService.loadUserTasks()
          this.isValidated = false;
          this.dialogType = confirmation.dialogType;
          this.message = confirmation.message || this.message;
          this.title = confirmation.options.title || this.title;
          this.auditChangeRequestDetail = initAuditChangeRequestDetail();
          this.lastAudit = initAuditChangeRequestDetail();
          this.supplierCode =
            this.onboardingService.openChangeRequestItem.supplierCode;
          this.visible = true;
          this.taskInst = confirmation.task;
          if (
            this.dialogType === ChangeRequestDetailStatus.APPROVED_BY_AP_QC ||
            this.dialogType === ChangeRequestDetailStatus.CLOSED_BY_AP_QC ||
            this.dialogType === ChangeRequestDetailStatus.REJECT_BY_AP_QC
          ) {
            // console.log(
            //   "AP QC review dialog ============ audit",
            //   this.onboardingService.openChangeRequestItem.audit
            // );
            // console.log(
            //   "AP QC review dialog ============ audit clone",
            //   cloneDeep(this.onboardingService.openChangeRequestItem.audit)
            // );
            this.onboardingService.openChangeRequestItem.audit.sort(
              (a: any, b: any) =>
                moment(b.createdOn).toDate().getTime() -
                moment(a.createdOn).toDate().getTime()
            );
            this.auditChangeRequestDetail.actionTaken =
              this.onboardingService.openChangeRequestItem.audit[0].actionTaken;
            this.auditChangeRequestDetail.addCompanyCodeList =
              this.onboardingService.openChangeRequestItem.audit[0].addCompanyCodeList;
            this.auditChangeRequestDetail.addPaymentTermsList =
              this.onboardingService.openChangeRequestItem.audit[0].addPaymentTermsList;
            this.auditChangeRequestDetail.addAmendSpendCommodityCategoryGroup =
              this.onboardingService.openChangeRequestItem.audit[0].addAmendSpendCommodityCategoryGroup;
          }
          // TODO
          this.auditDetail = initDetailForAddCompanyCode();
          this.confirmation = confirmation;
          if (
            this.dialogType === ChangeRequestDetailStatus.APPROVED_BY_AP_SST
          ) {
            this.dialogForm.nativeElement.style.height = "32rem";
          } else {
            this.dialogForm.nativeElement.style.height = "32rem";
          }
          if (
            this.dialogType === ChangeRequestDetailStatus.APPROVED_BY_AP_SST &&
            this.dictTaken === undefined
          ) {
            this.dictTaken = await this.dictionaryService.getDictionary(
              "Action Taken"
            );
          }
          if (
            this.dialogType === ChangeRequestDetailStatus.APPROVED_BY_AP_SST
          ) {
            this.changRequestType =
              this.onboardingService.openChangeRequestItem.requestType;
            if (this.changRequestType === this.crType.ADD_COMPANY_CODE) {
              this.onboardingService.openChangeRequestItem.audit[0].addCompanyCodeList.forEach(
                (item: AddCompanyCodeDetailsList) => {
                  this.apApproveList.push({
                    geographicalUnit: item.geographicalUnit,
                    country: item.country,
                    companyCode: item.companyCode,
                    sapID: item.sapID,
                  });
                }
              );
            }
          }

          this.visible = true;
        }
      );
  }
  refreshToggle(): void {
    this.isNewVersion =
      this.launchDarklyService.getToggle(
        LaunchDarklyService.LD_ISNEWVERSION
      ) === "true";
  }

  @Input() get visible(): boolean {
    return this._visible;
  }

  set visible(val: boolean) {
    this._visible = val;

    if (this._visible) {
      if (!this.positionInitialized) {
        this.center();
        this.positionInitialized = true;
      }
      this.el.nativeElement.children[0].style.zIndex = ++DomHandler.zindex;
      this.bindGlobalListeners();
      this.executePostShowActions = true;
    }

    if (this._visible) {
      this.enableModality();
    } else {
      this.disableModality();
    }
  }

  ngAfterViewInit() {
    this.contentContainer = this.domHandler.findSingle(
      this.el.nativeElement,
      ".ui-dialog-content"
    );

    if (this.appendTo) {
      if (this.appendTo === "body") {
        document.body.appendChild(this.el.nativeElement);
      } else {
        this.domHandler.appendChild(this.el.nativeElement, this.appendTo);
      }
    }
  }

  ngAfterViewChecked() {
    if (this.executePostShowActions) {
      // this.domHandler.findSingle(this.el.nativeElement.children[0], 'button').focus()
      this.executePostShowActions = false;
    }
  }

  center() {
    const container = this.el.nativeElement.children[0];

    let elementWidth = this.domHandler.getOuterWidth(container);
    let elementHeight = this.domHandler.getOuterHeight(container);
    if (elementWidth === 0 && elementHeight === 0) {
      container.style.visibility = "hidden";
      container.style.display = "block";
      elementWidth = this.domHandler.getOuterWidth(container);
      elementHeight = this.domHandler.getOuterHeight(container);
      container.style.display = "none";
      container.style.visibility = "visible";
    }
    const viewport = this.domHandler.getViewport();
    const x = (viewport.width - elementWidth) / 2;
    // Minus 200 px makes the popup window a little higher than current position
    const y = (viewport.height - elementHeight - 200) / 2;

    container.style.left = x + "px";
    container.style.top = y + "px";
  }

  enableModality() {
    if (!this.mask) {
      this.mask = document.createElement("div");
      this.mask.style.zIndex =
        this.el.nativeElement.children[0].style.zIndex - 1;
      this.domHandler.addMultipleClasses(
        this.mask,
        "ui-widget-overlay ui-dialog-mask"
      );
      // Set mask height position to fix the dialog layout issue when scroll down the page
      this.mask.style.height = document.body.clientHeight + "px";
      document.body.appendChild(this.mask);
      this.domHandler.addClass(document.body, "ui-overflow-hidden");
    }
  }

  disableModality() {
    if (this.mask) {
      document.body.removeChild(this.mask);
      this.domHandler.removeClass(document.body, "ui-overflow-hidden");
      this.mask = null;
    }
  }

  close(event: Event) {
    this.clear();
    event.preventDefault();
  }

  moveOnTop() {
    this.el.nativeElement.children[0].style.zIndex = ++DomHandler.zindex;
  }

  bindGlobalListeners() {
    if (this.closeOnEscape && this.closable && !this.documentEscapeListener) {
      this.documentEscapeListener = this.renderer.listen(
        "document",
        "keydown",
        (event) => {
          if (event.which === 27) {
            if (
              this.el.nativeElement.children[0].style.zIndex ===
              DomHandler.zindex &&
              this.visible
            ) {
              this.close(event);
            }
          }
        }
      );
    }

    if (this.responsive) {
      this.zone.runOutsideAngular(() => {
        this.documentResponsiveListener = this.center.bind(this);
        window.addEventListener("resize", this.documentResponsiveListener);
      });
    }
  }

  unbindGlobalListeners() {
    if (this.documentEscapeListener) {
      this.documentEscapeListener();
      this.documentEscapeListener = null;
    }

    if (this.documentResponsiveListener) {
      window.removeEventListener("resize", this.documentResponsiveListener);
      this.documentResponsiveListener = null;
    }
  }

  ngOnDestroy() {
    this.disableModality();

    if (this.documentResponsiveListener) {
      this.documentResponsiveListener();
    }

    if (this.documentEscapeListener) {
      this.documentEscapeListener();
    }

    if (this.appendTo && this.appendTo === "body") {
      document.body.removeChild(this.el.nativeElement);
    }

    this.subscription.unsubscribe();
  }

  click(button: ButtonItem) {
    if (button.clickEvent) {
      button.clickEvent.emit();
    }

    this.clear();
  }

  cancel() {
    this.creationAPValidated = true;
    this.clear();
  }

  /**
   * clear method is used for reset all fields when leave this component
   */
  clear() {
    this.visible = false;
    this.unbindGlobalListeners();
    this.apApproveList = [];
    this.comments = "";
    this.confirmation = null;
  }

  async submit() {
    const date: any = new Date().toUTCString();
    this.auditChangeRequestDetail.createdOn = moment.utc().toString();
    this.auditChangeRequestDetail.createdBy = this.auth.passport.profile.name;
    this.auditChangeRequestDetail.comments = this.comments;

    // get current user excute task
    this.onboardingService.supplierModel.Mapping.executeTask = this.taskInst;
    // this.onboardingService.getExecuteTask(ProcessNames.generic_buyerChangeRequest, AllTaskKey.SST_review)
    // if (!this.onboardingService.supplierModel.Mapping.executeTask) {
    //   return false
    // }
    this.isSubmitDisabled = true;
    // QC Validation
    if (this.auth.passport.buyer.RoleCode === RoleName.APQualityControl) {
      if (await this.qcValidate()) {
        if (this.dialogType === ChangeRequestDetailStatus.CLOSED_BY_AP_QC) {
          this.auditChangeRequestDetail.status =
            ChangeRequestDetailStatus.CLOSED_BY_AP_QC;
          this.onboardingService.openChangeRequestItem.status =
            ChangeRequestStatus.COMPLETED;
          this.onboardingService.openChangeRequestItem.audit.push(
            this.auditChangeRequestDetail
          );
          this.onboardingService.openChangeRequestItem.openFlag = false;
        }
        if (this.dialogType === ChangeRequestDetailStatus.REJECT_BY_AP_QC) {
          this.auditChangeRequestDetail.status =
            ChangeRequestDetailStatus.REJECT_BY_AP_QC;
          this.onboardingService.openChangeRequestItem.status =
            ChangeRequestStatus.PENDING_APSST_REVIEW;
          this.onboardingService.openChangeRequestItem.audit.push(
            this.auditChangeRequestDetail
          );
        }
        if (this.dialogType === ChangeRequestDetailStatus.APPROVED_BY_AP_QC) {
          if (this.isNewVersion) {
            const saveUrl = `${environment.stakeholderGateway}${LoadingUrlMapping.buyerCR_QCApprove}`;
            this.loadingService.openLoading(saveUrl);
          } else {
            const saveUrl = `${environment.gateway}${LoadingUrlMapping.buyerCR_QCApprove}`;
            this.loadingService.openLoading(saveUrl);
          }
          this.auditChangeRequestDetail.status =
            ChangeRequestDetailStatus.APPROVED_BY_AP_QC;
          let isGDPRTrigger = false;
          // TODO: CR trigger coupa need to enhance, Currently, it cannot trigger coupa in CR process
          // if(this.onboardingService.openChangeRequestItem && this.onboardingService.openChangeRequestItem.requestType === ChangeRequestType.UPDATE_AmendSpendCommodityCategoryGroup) {
          //   const GDPRTask = this.onboardingService.supplierModel.Mapping.historyTasks.filter(
          //     (task) => task.name === SetupProcess.GDPR
          //   )
          //   const spendCategoryGroupValue = JSON.parse(this.onboardingService.openChangeRequestItem.requestValue)?.SpendCategoryGroup;
          //   const IndependentContractor = JSON.parse(this.onboardingService.openChangeRequestItem.requestValue)?.IndependentContractor;
          //   if (spendCategoryGroupValue && (spendCategoryGroupValue == '5002' || spendCategoryGroupValue == '5021')) {
          //     if (IndependentContractor && IndependentContractor == 'N') {
          //       SpendCategoryGroup['5002'] = true
          //       SpendCategoryGroup['5021'] = true
          //     }else {
          //       SpendCategoryGroup['5002'] = false
          //       SpendCategoryGroup['5021'] = false
          //     }
          //   }
          //   const isRiskInfoExist = JSON.parse(this.onboardingService.openChangeRequestItem.requestValue)?.BI_BDA_1;
          //   const isMigrated = this.onboardingService.supplierModel.Mapping.IsMigrated;
          //   isGDPRTrigger = !!SpendCategoryGroup[spendCategoryGroupValue] && !GDPRTask.length && !!isRiskInfoExist && !isMigrated;
          // }
          this.onboardingService.openChangeRequestItem.status = isGDPRTrigger ? ChangeRequestStatus.PENDING_GDPR : ChangeRequestStatus.COMPLETED;
          this.onboardingService.openChangeRequestItem.audit.push(
            this.auditChangeRequestDetail
          );
          this.onboardingService.openChangeRequestItem.openFlag = isGDPRTrigger;
          const tempCompanyCodeList: string[] = [];
          const tempSAPIDList: string[] = [];
          if (
            this.onboardingService.openChangeRequestItem.requestType ===
            this.crType.ADD_COMPANY_CODE
          ) {
             // }
            // when add companyCode and QC approve update mapping AssessmentRate
            // console.log(newPaymentTermList)
            // console.log(this.onboardingService.supplierModel.RequestForm.RequestFormInfo.PaymentTermList)
            this.addResultToMappingAssessmentRate(date);
          } 
             // This is CR trigger coupa code
            // let auditLength = this.onboardingService.openChangeRequestItem.audit.length;
            // let ACGLength = this.onboardingService.openChangeRequestItem.audit[auditLength - 1].addAmendSpendCommodityCategoryGroup.length;
            // if (this.onboardingService.openChangeRequestItem.audit[auditLength - 1].addAmendSpendCommodityCategoryGroup[ACGLength - 1].SpendCategoryGroup.newValue) {
            //   if (this.onboardingService.openChangeRequestItem.audit[auditLength - 1].addAmendSpendCommodityCategoryGroup[ACGLength - 1].SpendCategoryGroup.newValue === '5021'
            //     || this.onboardingService.openChangeRequestItem.audit[auditLength - 1].addAmendSpendCommodityCategoryGroup[ACGLength - 1].SpendCategoryGroup.newValue === '5002') {
            //     if (this.onboardingService.openChangeRequestItem.audit[auditLength - 1].addAmendSpendCommodityCategoryGroup[ACGLength - 1].IndependentContractor.newValue) {
            //       if (this.onboardingService.openChangeRequestItem.audit[auditLength - 1].addAmendSpendCommodityCategoryGroup[ACGLength - 1].IndependentContractor.newValue == 'Y') {
            //         SpendCategoryGroup['5002'] = false
            //         SpendCategoryGroup['5021'] = false
            //       } else {
            //         SpendCategoryGroup['5002'] = true
            //         SpendCategoryGroup['5021'] = true
            //       }
            //     } else {
            //       if (this.onboardingService.openChangeRequestItem.audit[auditLength - 1].addAmendSpendCommodityCategoryGroup[ACGLength - 1].IndependentContractor.oldValue == 'Y') {
            //         SpendCategoryGroup['5002'] = false
            //         SpendCategoryGroup['5021'] = false
            //       } else {
            //         SpendCategoryGroup['5002'] = true
            //         SpendCategoryGroup['5021'] = true
            //       }
            //     }
            //   }
            // } else {
            //   if (this.onboardingService.openChangeRequestItem.audit[auditLength - 1].addAmendSpendCommodityCategoryGroup[ACGLength - 1].SpendCategoryGroup.oldValue === '5021'
            //     || this.onboardingService.openChangeRequestItem.audit[auditLength - 1].addAmendSpendCommodityCategoryGroup[ACGLength - 1].SpendCategoryGroup.oldValue === '5002') {
            //     if (this.onboardingService.openChangeRequestItem.audit[auditLength - 1].addAmendSpendCommodityCategoryGroup[ACGLength - 1].IndependentContractor.newValue == 'Y') {
            //       SpendCategoryGroup['5002'] = false
            //       SpendCategoryGroup['5021'] = false
            //     } else {
            //       SpendCategoryGroup['5002'] = true
            //       SpendCategoryGroup['5021'] = true
            //     }
            //   }
            // }
            // let isCoupaRA = this.onboardingService.openChangeRequestItem.audit[auditLength - 1].addAmendSpendCommodityCategoryGroup[ACGLength - 1].SpendCategoryGroup.newValue 
            //   ? SpendCategoryGroup[this.onboardingService.openChangeRequestItem.audit[auditLength - 1].addAmendSpendCommodityCategoryGroup[ACGLength - 1].SpendCategoryGroup.newValue] 
            //   : SpendCategoryGroup[this.onboardingService.openChangeRequestItem.audit[auditLength - 1].addAmendSpendCommodityCategoryGroup[ACGLength - 1].SpendCategoryGroup.oldValue];
            // let ACGOld = this.onboardingService.supplierModel.RequestForm.RequestorDetailsInfo;
            // let ACGNew = this.onboardingService.openChangeRequestItem.audit[auditLength - 1].addAmendSpendCommodityCategoryGroup[ACGLength - 1];

            // const cRId = this.onboardingService.openChangeRequestItem.crId;
            // console.log('==========cRIdcRIdcRIdcRId' + cRId)


            // const timeCompareNeed = parseInt(cRId);
            // console.log('==========timeCompareNeed' + timeCompareNeed)
            // // const timeCompareCode = 1680751800000;
            // let timeCompare: boolean = timeCompareNeed > 1682393400000

            // let isMigrate = !this.onboardingService.supplierModel.Mapping.IsMigrated;
            // if (isCoupaRA && timeCompare && isMigrate) {

            //   if (ACGNew.SpendCategoryGroup.newValue) {
            //     ACGOld.SpendCategoryGroup =
            //       ACGNew.SpendCategoryGroup.newValue;
            //   }
            //   if (ACGNew.SpendCommodityGroup.newValue) {
            //     ACGOld.SpendCommodityGroup =
            //       ACGNew.SpendCommodityGroup.newValue;
            //   }
            //   if (ACGOld.SpendCategoryGroup === '5002' || ACGOld.SpendCategoryGroup === '5021') {
            //     ACGOld.IndependentContractor =
            //       ACGNew.IndependentContractor.newValue;
            //   } else {
            //     ACGOld.IndependentContractor = ''
            //   }

            //   const tempTermsValueRACR: any = JSON.parse(
            //     this.onboardingService.openChangeRequestItem.requestValue
            //   );
            //   delete tempTermsValueRACR.SpendCommodityGroup;
            //   delete tempTermsValueRACR.SpendCategoryGroup;
            //   delete tempTermsValueRACR.IndependentContractor;

            //   this.onboardingService.supplierModel.RequestForm.RiskAssessmentInfo = tempTermsValueRACR;

            // } else {
          if (this.isNewVersion) {
           var listAjax = [
              await this.buyerChangeRequest.newBuyerCR_qcApprove(
                this.onboardingService.supplierModel.Mapping,
                this.onboardingService.openChangeRequestItem
              ),
            ];
          } else {
           var listAjax = [
              await this.buyerChangeRequest.buyerCR_qcApprove(
                this.onboardingService.supplierModel,
                this.onboardingService.openChangeRequestItem
              ),
            ];
          }
          const result = await Promise.all(listAjax);
          this.loadingService.closeLoading();
          if (result) {
            this.clear();
            let dialogResult: any = "";
            if (environment.languageSwitch && environment.role == "supplier") {
              dialogResult = await this.dialogService.dialog(
                this.languageService.getValue(
                  "static.shared.components.change-request-dialog-form.submit_success"
                ),
                ButtonCommands.Close,
                DialogType.success
              );
            } else {
              dialogResult = await this.dialogService.dialog(
                "You have submitted successfully.",
                ButtonCommands.Close,
                DialogType.success
              );
            }
            if (dialogResult) {
              this.router.navigate([
                `buyer/onboarding/process/${this.supplierCode}`,
              ]);
            }
          }
        } else if (
          this.dialogType === ChangeRequestDetailStatus.REJECT_BY_AP_QC
        ) {
          if (this.isNewVersion) {
            var saveUrl = `${environment.stakeholderGateway}${LoadingUrlMapping.buyerCR_QCReject}`;
            this.loadingService.openLoading(saveUrl);
            var data: any = await this.buyerChangeRequest.newBuyerCR_qcReject(
              this.onboardingService.supplierModel.Mapping,
              this.onboardingService.openChangeRequestItem
            );
          } else {
            var saveUrl = `${environment.gateway}${LoadingUrlMapping.buyerCR_QCReject}`;
            this.loadingService.openLoading(saveUrl);
            var data: any = await this.buyerChangeRequest.buyerCR_qcReject(
              this.onboardingService.supplierModel,
              this.onboardingService.openChangeRequestItem
            );
          }
          this.loadingService.closeLoading();
          if (data && data.isSuccess) {
            this.clear();
            if (environment.languageSwitch && environment.role == "supplier") {
              await this.dialogService.dialog(
                this.languageService.getValue(
                  "static.shared.components.change-request-dialog-form.submit_success"
                ),
                ButtonCommands.Close,
                DialogType.success
              );
            } else {
              await this.dialogService.dialog(
                "You have submitted successfully.",
                ButtonCommands.Close,
                DialogType.success
              );
            }
            this.router.navigate([
              `buyer/onboarding/process/${this.supplierCode}`,
            ]);
          }
        } else if (
          this.dialogType === ChangeRequestDetailStatus.CLOSED_BY_AP_QC
        ) {
          if (this.isNewVersion) {
            const saveUrl = `${environment.stakeholderGateway}${LoadingUrlMapping.buyerCR_QCclose}`;
            this.loadingService.openLoading(saveUrl);
            var data: any = await this.buyerChangeRequest.newBuyerCR_qcClose(
              this.onboardingService.supplierModel.Mapping,
              this.onboardingService.openChangeRequestItem
            );
          }
          else {
            const saveUrl = `${environment.gateway}${LoadingUrlMapping.buyerCR_QCclose}`;
            this.loadingService.openLoading(saveUrl);
            var data: any = await this.buyerChangeRequest.buyerCR_qcClose(
              this.onboardingService.supplierModel,
              this.onboardingService.openChangeRequestItem
            );
          }
          this.loadingService.closeLoading();
          if (data && data.isSuccess) {
            this.clear();
            if (environment.languageSwitch && environment.role == "supplier") {
              this.dialogService.dialog(
                this.languageService.getValue(
                  "static.shared.components.change-request-dialog-form.submit_success"
                ),
                ButtonCommands.Close,
                DialogType.success
              );
            } else {
              this.dialogService.dialog(
                "You have submitted successfully.",
                ButtonCommands.Close,
                DialogType.success
              );
            }
            this.router.navigate([
              `buyer/onboarding/process/${this.supplierCode}`,
            ]);
          }
        }
      }
    }
    // SST Validation
    if (this.auth.passport.buyer.RoleCode === RoleName.APSupplierSetupTeam) {
      if (await this.sstValidate()) {
        if (this.dialogType === ChangeRequestDetailStatus.APPROVED_BY_AP_SST) {
          if (this.isNewVersion) {
            const saveUrl = `${environment.stakeholderGateway}${LoadingUrlMapping.buyerCR_SSTApprove}`;
            this.loadingService.openLoading(saveUrl);
          } else {
            const saveUrl = `${environment.gateway}${LoadingUrlMapping.buyerCR_SSTApprove}`;
            this.loadingService.openLoading(saveUrl);
          }
          this.loadingService.closeLoading();
          if (
            this.onboardingService.openChangeRequestItem.requestType ===
            ChangeRequestType.ADD_COMPANY_CODE
          ) {
            this.auditChangeRequestDetail.addCompanyCodeList =
              this.apApproveList;
          }
          this.auditChangeRequestDetail.addPaymentTermsList =
            this.onboardingService.openChangeRequestItem.audit[0].addPaymentTermsList;
          this.auditChangeRequestDetail.addAmendSpendCommodityCategoryGroup =
            this.onboardingService.openChangeRequestItem.audit[0].addAmendSpendCommodityCategoryGroup;
          this.auditChangeRequestDetail.comments = this.comments;
          this.auditChangeRequestDetail.actionTaken = this.actionTaken;
          this.auditChangeRequestDetail.createdBy =
            this.auth.passport.profile.name;
          this.auditChangeRequestDetail.createdOn = moment.utc().toString();
          this.auditChangeRequestDetail.status =
            this.changeRequestDetailStatus.APPROVED_BY_AP_SST;
          this.onboardingService.openChangeRequestItem.status =
            ChangeRequestStatus.PENDING_APQC_REVIEW;
        }
        if (this.dialogType === ChangeRequestDetailStatus.CLOSED_BY_AP_SST) {
          if (this.isNewVersion) {
            const saveUrl = `${environment.stakeholderGateway}${LoadingUrlMapping.buyerCR_SSTClose}`;
            this.loadingService.openLoading(saveUrl);
          } else {
            const saveUrl = `${environment.gateway}${LoadingUrlMapping.buyerCR_SSTClose}`;
            this.loadingService.openLoading(saveUrl);
          }
          this.loadingService.closeLoading();
          this.auditChangeRequestDetail.addPaymentTermsList =
            this.onboardingService.openChangeRequestItem.audit[0].addPaymentTermsList;
          this.auditChangeRequestDetail.addCompanyCodeList =
            this.onboardingService.openChangeRequestItem.audit[0].addCompanyCodeList;
          this.auditChangeRequestDetail.addAmendSpendCommodityCategoryGroup =
            this.onboardingService.openChangeRequestItem.audit[0].addAmendSpendCommodityCategoryGroup;
          this.auditChangeRequestDetail.comments = this.comments;
          this.auditChangeRequestDetail.createdBy =
            this.auth.passport.profile.name;
          this.auditChangeRequestDetail.createdOn = moment.utc().toString();
          this.auditChangeRequestDetail.status =
            this.changeRequestDetailStatus.CLOSED_BY_AP_SST;
          this.onboardingService.openChangeRequestItem.status =
            ChangeRequestStatus.COMPLETED;
          this.onboardingService.openChangeRequestItem.openFlag = false;
        }
        this.onboardingService.openChangeRequestItem.audit.push(
          this.auditChangeRequestDetail
        );
        let saveResult: any;
        // const saveResult: any = await this.buyerService.updateChangeRequestforSST(this.onboardingService.openChangeRequestItem)
        if (this.isNewVersion) {
          saveResult =
            this.dialogType === ChangeRequestDetailStatus.APPROVED_BY_AP_SST
              ? await this.buyerChangeRequest.newBuyerCR_sstApprove(
                this.onboardingService.supplierModel.Mapping,
                this.onboardingService.openChangeRequestItem
              )
              : await this.buyerChangeRequest.newBuyerCR_sstClose(
                this.onboardingService.supplierModel.Mapping,
                this.onboardingService.openChangeRequestItem
              );
        } else {
          saveResult =
            this.dialogType === ChangeRequestDetailStatus.APPROVED_BY_AP_SST
              ? await this.buyerChangeRequest.buyerCR_sstApprove(
                this.onboardingService.supplierModel,
                this.onboardingService.openChangeRequestItem
              )
              : await this.buyerChangeRequest.buyerCR_sstClose(
                this.onboardingService.supplierModel,
                this.onboardingService.openChangeRequestItem
              );
        }
        this.loadingService.closeLoading()
        if (saveResult && saveResult.isSuccess) {
          this.clear();
          if (environment.languageSwitch && environment.role == "supplier") {
            await this.dialogService.dialog(
              this.languageService.getValue(
                "static.shared.components.change-request-dialog-form.submit_success"
              ),
              ButtonCommands.Close,
              DialogType.success
            );
          } else {
            await this.dialogService.dialog(
              "You have submitted successfully.",
              ButtonCommands.Close,
              DialogType.success
            );
          }
          this.router.navigate([
            `buyer/onboarding/process/${this.supplierCode}`,
          ]);
        }
      }
    }
    this.isSubmitDisabled = false;
  }

  async qcValidate() {
    this.isValidated = true;
    this.comments = this.comments.trim();
    return this.comments;
  }

  async sstValidate() {
    this.isValidated = true;
    if (this.comments) {
      this.comments = this.comments.trim();
    }
    if (
      this.dialogType === ChangeRequestDetailStatus.APPROVED_BY_AP_SST ||
      this.dialogType === ChangeRequestDetailStatus.CLOSED_BY_AP_SST
    ) {
      this.creationAPValidated =
        this.isSAPIDValid && this.comments ? true : false;
      return this.creationAPValidated;
    } else {
      // TODO: Kira add SST Validate

      return true;
    }
  }

  get isSAPIDValid() {
    // return ((/^\d{9}$/.test(this.sapResult.sapId)) && !this.isMigrated) || ((/^\d{9}$/.test(this.sapIdValue)))
    const creationAPIdValid: any = [];
    this.apApproveList.forEach((item: any) => {
      creationAPIdValid.push(
        this.checkSingleSAPID(item.sapID) && item.sapID.length > 0
      );
    });
    return !creationAPIdValid.includes(false);
  }

  checkSingleSAPID(sapID: string): boolean {
    return /^1\d{8}$/.test(sapID);
  }

  addResultToMappingAssessmentRate(date) {
    this.sapResultList = createAPReviewModel();
    const apResultLength =
      this.onboardingService.supplierModel.Mapping.assessmentRate.sapResult
        .length;
    if (apResultLength && apResultLength > 0) {
      const lastSapResult =
        this.onboardingService.supplierModel.Mapping.assessmentRate.sapResult[
          apResultLength - 1
        ].sapResultList;
      lastSapResult.forEach((item) => {
        const sapItem = createAPApproveItem();
        sapItem.companyCode = item.companyCode;
        sapItem.countryCode = item.countryCode;
        sapItem.sapId = item.sapId;
        this.sapResultList.sapResultList.push(sapItem);
      });
    }
    this.auditChangeRequestDetail.addCompanyCodeList.forEach((item) => {
      const sapItem = createAPApproveItem();
      sapItem.companyCode = item.companyCode;
      sapItem.countryCode = item.country;
      sapItem.sapId = item.sapID;
      this.sapResultList.sapResultList.push(sapItem);
    });
    this.sapResultList.actionTaken = this.auditChangeRequestDetail.actionTaken
      ? this.auditChangeRequestDetail.actionTaken
      : "";
    this.sapResultList.comments = this.auditChangeRequestDetail.comments;
    this.sapResultList.createdBy =
      "By " + this.auditChangeRequestDetail.createdBy;
    this.sapResultList.createdOn =
      " on " + moment(date).format("DD-MMM-YYYY HH:mm:ss");
    this.sapResultList.type = this.auditChangeRequestDetail.status;
    this.onboardingService.supplierModel.Mapping.assessmentRate.sapResult.push(
      this.sapResultList
    );
  }
}
