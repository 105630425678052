import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { RebarAuthService } from "src/app/core/rebarauth/rebar.auth.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.sass"],
})
export class LoginComponent implements OnInit {
  constructor(private router: Router, private activeRoute: ActivatedRoute, private rebarService: RebarAuthService) { }

  ngOnInit() {
    if (this.activeRoute.snapshot.routeConfig.path === "code") {
      this.router.navigate(["home"]);
      return
    }

    if (this.rebarService.isB2C()) {
      const tsmState = this.activeRoute.snapshot.paramMap.get("tsm");
      // console.log("tsm state is %s, lack tsm navigation logic", tsmState);
      // split tsm state and then navigate to specified route path

      return
    }
  }
}