// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.invited-container {
  background: linear-gradient(to right, #764BA2, #3C73D2);
  height: 621px;
}

.file-upload {
  border: dashed 1px rgba(31, 31, 53, 0.3);
  height: 4rem;
}

.nv-file-over {
  border: dashed 1px red;
}

.uploader {
  border-radius: 0.3rem;
  display: -webkit-box;
  -webkit-box-orient: horizontal;
  -webkit-box-pack: center;
  -webkit-box-align: center;
  cursor: pointer;
}
.uploader input {
  display: none;
}

.file-img {
  width: 20px;
  height: 25px;
  float: left;
}

.progress {
  width: 8%;
  margin: auto 0;
  overflow: hidden;
  border-radius: 4px;
  text-align: -webkit-center;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}

.progress-bar {
  float: left;
  width: 0;
  height: 100%;
  font-size: 12px;
  color: #fff;
  text-align: center;
  background-color: #337ab7;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  transition: width 10s ease;
}

.file-message-con {
  text-align: -webkit-center;
  color: #4e4e87;
}

.file-message {
  padding-top: 5px;
  padding-left: 10px;
}

.material-icons {
  color: #4e4e87;
}

.ntfTitle {
  height: 50px;
  background: white;
  padding-top: 20px;
  font-weight: 600;
}
.ntfTitle .hover_pointer {
  float: right;
  font-size: 16px;
  font-weight: 900;
}

.tbHeader {
  margin-top: 40px;
  font-size: 16px;
  text-align: left;
  font-weight: 700;
}

.tContent {
  font-size: 14px;
  color: #707070;
  text-align: left;
  background: white;
  padding-top: 8px;
  margin-left: 35px;
  border-bottom: #dddddd;
  border-bottom-width: 5px;
  border-bottom-color: #707070;
}
.tContent .ntfDate {
  color: #46465B;
}
.tContent .ntfEvent {
  color: #46465B;
}
.tContent .ntfMsg {
  color: black;
  font-weight: 800;
}
.tContent .ntfAction {
  color: #006CAF;
  font-weight: 600;
}
.tContent .ntfAction .btnAction {
  cursor: pointer;
}

.emptyContents {
  height: 200px;
  background: white;
  text-align: center;
  padding-top: 70px;
  font-size: 15px;
}

.close-table {
  position: relative;
  left: 970px;
  bottom: 22px;
}

.my-pagination {
  margin-top: 30px;
}

.alert-bar-color {
  background: rgba(78, 78, 135, 0.05);
}

.check-container {
  position: relative;
}

.checkpoint {
  position: absolute;
  top: 10px;
  left: 16px;
  height: 18px;
  width: 18px;
  background-color: #fff;
  border: #4e4e87 2px solid;
}

.isInvalid {
  border: solid 1.5px #F3BCDA !important;
  border-background: gray;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
