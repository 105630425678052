import * as _ from "lodash";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { environment } from "../../../../../environments/environment";
// import { NzMessageService } from 'ng-zorro-antd/message'
import { DictionaryService } from "../../services";

@Component({
  templateUrl: "./standard-payment-term.template.html",
  styleUrls: ["./standard-payment-term.style.sass"],
})
export class StandardPaymentTermComponent implements OnInit {
  public geographicUnitDictionary: any[] = [];
  public companyCountryMappingDictionary: any[] = [];
  public paymentTermDictionary: any[] = [];
  public searchCountryOptions: any[] = [];
  public searchCompanyOptions: any[] = [];
  public searchStandardPaymentTermOptions: any[] = [];
  public searchStandardPaymentTermForm: UntypedFormGroup;
  public standardPaymentTerms: any[] = [];
  public total = 0;
  public pageNumber = 1;
  public pageSize = 10;
  public loading = true;
  public deletedStandardPaymentTermId = 0;
  public standardPaymentTermModalVisible = false;
  public standardPaymentTermForm: UntypedFormGroup;
  public modalCountryOptions: any[] = [];
  public modalCompanyOptions: any[] = [];
  public modalPaymentTermsOptions: any[] = [];
  public standPaymentTerm: any | undefined;
  public saveStandardPaymentTermLoading = false;
  public saveStandardPaymentTermDisabled = false;

  constructor(
    private readonly httpClient: HttpClient,
    // private readonly message: NzMessageService,
    private readonly dictionaryService: DictionaryService
  ) {}

  private getSearchStandardPaymentTermFormParams() {
    const { countryCode, companyCodes, standardPaymentTermCode } =
      this.searchStandardPaymentTermForm.value;
    let params = new HttpParams();
    if (countryCode) {
      const country = _.find(this.searchCountryOptions, { Code: countryCode });
      if (country) {
        params = params.set("countryName", country.Text);
      }
    }
    if (companyCodes && companyCodes.length !== 0) {
      params = params.set("companyCodes", companyCodes.join("||"));
    }
    if (standardPaymentTermCode) {
      const standardPaymentTerm = _.find(
        this.searchStandardPaymentTermOptions,
        { Code: standardPaymentTermCode }
      );
      if (standardPaymentTerm) {
        params = params.set(
          "standardPaymentTermName",
          standardPaymentTerm.Text
        );
      }
    }
    params = params.set("pageNumber", _.toString(this.pageNumber));
    params = params.set("pageSize", _.toString(this.pageSize));
    // console.log("get standard payment terms and count params", params);
    return params;
  }

  private getStandardPaymentTermFormParams() {
    const { countryName, standardPaymentTermName, companyCodes } =
      this.standardPaymentTermForm.value;
    const params = {
      Country: countryName,
      PaymentTerm: standardPaymentTermName,
      CompanyCode: JSON.stringify(companyCodes),
    };
    return params;
  }

  private searchStandardPaymentTermFormBuilder() {
    this.searchStandardPaymentTermForm = new UntypedFormGroup({
      countryCode: new UntypedFormControl(""),
      companyCodes: new UntypedFormControl(null),
      standardPaymentTermCode: new UntypedFormControl(""),
    });
  }

  private standardPaymentTermFormBuilder() {
    this.standardPaymentTermForm = new UntypedFormGroup({
      countryName: new UntypedFormControl(null, [Validators.required]),
      standardPaymentTermName: new UntypedFormControl(null, [Validators.required]),
      companyCodes: new UntypedFormControl(null, [Validators.required]),
    });
    const countryControl = this.standardPaymentTermForm.get("countryName");
    const companyControl = this.standardPaymentTermForm.get("companyCodes");
    countryControl.valueChanges.subscribe((countryName) => {
      // console.log("country name is ", countryName);
      const countryOption = _.find(this.modalCountryOptions, {
        Text: countryName,
      });
      this.handleModalCompanies(countryOption ? [countryOption.Code] : []);
      companyControl.patchValue(null);
    });
    this.standardPaymentTermForm.statusChanges.subscribe((status) => {
      this.saveStandardPaymentTermDisabled = status !== "VALID";
    });
  }

  private handleCountries(geographicUnitCodes: string[] = []) {
    const geographicUnitDictionary = this.geographicUnitDictionary;
    const countryOptions = _.chain(geographicUnitDictionary)
      .filter((geographicUnit) =>
        geographicUnitCodes.length
          ? _.includes(geographicUnitCodes, geographicUnit.Code)
          : true
      )
      .map("Items")
      .compact()
      .flatMap()
      .value();
    this.searchCountryOptions = countryOptions;
    // console.log("search country options", countryOptions);
  }

  private handleSearchCompanies(countryCodes: string[] = []) {
    const companyDictionary = this.companyCountryMappingDictionary;
    const companyOptions = _.chain(companyDictionary)
      .filter((company) =>
        countryCodes.length ? _.includes(countryCodes, company.Code) : true
      )
      .map("Items")
      .compact()
      .flatMap()
      .sortBy("Code")
      .value();
    this.searchCompanyOptions = companyOptions;
    // console.log("search company options", companyOptions);
  }

  private handleSearchPaymentTerms() {
    const standardPaymentTerms = this.paymentTermDictionary;
    this.searchStandardPaymentTermOptions = standardPaymentTerms;
    // console.log("search standard payment term options", standardPaymentTerms);
  }

  private handleModalCountries() {
    const geographicUnitDictionary = this.geographicUnitDictionary;
    const countryOptions = _.chain(geographicUnitDictionary)
      .map("Items")
      .compact()
      .flatMap()
      .value();
    this.modalCountryOptions = countryOptions;
    // console.log("modal country options", countryOptions);
  }

  private handleModalCompanies(countryCodes: string[] = []) {
    const companyDictionary = this.companyCountryMappingDictionary;
    const companyOptions = _.chain(companyDictionary)
      .filter((company) =>
        countryCodes.length ? _.includes(countryCodes, company.Code) : true
      )
      .map("Items")
      .compact()
      .flatMap()
      .sortBy("Code")
      .value();
    this.modalCompanyOptions = companyOptions;
    // console.log("modal company options", companyOptions);
  }

  private handleModalPaymentTerms() {
    const paymentTermOptions = this.paymentTermDictionary;
    this.modalPaymentTermsOptions = paymentTermOptions;
    // console.log("modal payment term options", paymentTermOptions);
  }

  private handleOptions() {
    this.handleCountries();
    this.handleSearchCompanies();
    this.handleSearchPaymentTerms();
    this.handleModalCountries();
    this.handleModalCompanies();
    this.handleModalPaymentTerms();
  }

  private async getDictionary() {
    const result = await this.httpClient
      .get<any>(`${environment.gateway}/dropdown/dictionary`)
      .toPromise();
    // console.log("get dictionary result", result);
    if (result.isSuccess) {
      const dictionary = result.data;
      this.geographicUnitDictionary =
        this.dictionaryService.getGeographicUnits(dictionary);
      // console.log("geographic unit dictionary", this.geographicUnitDictionary);
      this.companyCountryMappingDictionary =
        this.dictionaryService.getCompanyCountryMappings(dictionary);
      // console.log(
      //   "company country mapping dictionary",
      //   this.companyCountryMappingDictionary
      // );
      this.paymentTermDictionary =
        this.dictionaryService.getPaymentTerms(dictionary);
      // console.log("payment term dictionary", this.paymentTermDictionary);
      this.handleOptions();
    }
  }

  private async createStandardPaymentTerm() {
    const params = this.getStandardPaymentTermFormParams();
    const result = await this.httpClient
      .post<any>(`${environment.gateway}/system/standardPaymentTerms`, params)
      .toPromise();
    return result;
  }

  private async updateStandardPaymentTerm() {
    const params = this.getStandardPaymentTermFormParams();
    const result = await this.httpClient
      .put<any>(
        `${environment.gateway}/system/standardPaymentTerms/${this.standPaymentTerm.ID}`,
        params
      )
      .toPromise();
    return result;
  }

  public async ngOnInit() {
    this.searchStandardPaymentTermFormBuilder();
    this.standardPaymentTermFormBuilder();
    await this.getDictionary();
    await this.getStandardPaymentTermsAndCount();
  }

  public reset() {
    this.searchStandardPaymentTermForm.reset({
      geographicUnitCode: "",
      countryCode: "",
      standardPaymentTermCode: "",
    });
    this.getStandardPaymentTermsAndCount(1);
  }

  public search() {
    // console.log("search form", this.searchStandardPaymentTermForm);
    this.getStandardPaymentTermsAndCount(1);
  }

  public async getStandardPaymentTermsAndCount(
    pageNumber = this.pageNumber,
    pageSize = this.pageSize
  ) {
    this.loading = true;
    this.pageNumber = pageNumber;
    this.pageSize = pageSize;
    const params = this.getSearchStandardPaymentTermFormParams();
    const result = await this.httpClient
      .get<any>(`${environment.gateway}/system/standardPaymentTerms/andCount`, {
        params,
      })
      .toPromise();
    this.loading = false;
    // console.log("get standard payment terms and count result", result);
    if (result.isSuccess) {
      this.standardPaymentTerms = result.data.standardPaymentTerms;
      this.total = result.data.count;
    }
  }

  public getCompanyNames(companyCode: string) {
    const companyCodes = companyCode ? JSON.parse(companyCode) : [];
    const companyNames = _.chain(this.companyCountryMappingDictionary)
      .map("Items")
      .compact()
      .flatMap()
      .filter(({ Code }) => _.includes(companyCodes, Code))
      .map(({ Text }) => `${Text}<br>`)
      .join("")
      .value();
    return companyNames;
  }

  public openStandardPaymentTermModal(data?: any) {
    this.standPaymentTerm = data;
    let form: any = {
      countryName: "",
      standardPaymentTermName: "",
      companyCodes: [],
    };
    if (data) {
      form = {
        countryName: data.Country,
        standardPaymentTermName: data.PaymentTerm,
        companyCodes: JSON.parse(data.CompanyCode),
      };
      // console.log("data", data);
    }
    this.standardPaymentTermForm.reset(form);
    this.standardPaymentTermModalVisible = true;
  }

  public closeStandardPaymentTermModal() {
    this.standardPaymentTermModalVisible = false;
    this.standPaymentTerm = undefined;
  }

  public async saveStandardPaymentTerm() {
    try {
      this.saveStandardPaymentTermLoading = true;
      const result = this.standPaymentTerm
        ? await this.updateStandardPaymentTerm()
        : await this.createStandardPaymentTerm();
      if (result.isSuccess && result.data.succeed) {
        //this.message.success('save company succeed')
        this.saveStandardPaymentTermLoading = false;
        this.standardPaymentTermModalVisible = false;
        this.standPaymentTerm = undefined;
        this.loading = true;
        await this.getStandardPaymentTermsAndCount(1);
      } else {
        //this.message.error(result.data.message || 'save standard payment term failed')
        this.saveStandardPaymentTermLoading = false;
      }
    } catch (error) {
      //this.message.error('save standard payment term failed')
      this.saveStandardPaymentTermLoading = false;
    }
  }

  public async deleteStandardPaymentTerm(id: number) {
    try {
      this.deletedStandardPaymentTermId = id;
      const result = await this.httpClient
        .delete<any>(`${environment.gateway}/system/standardPaymentTerms/${id}`)
        .toPromise();
      if (result.isSuccess && result.data.succeed) {
        //this.message.success('delete standard payment term succeed')
        await this.getStandardPaymentTermsAndCount(1);
        this.deletedStandardPaymentTermId = 0;
      } else {
        //this.message.error(result.data.message || 'delete standard payment term failed')
        this.deletedStandardPaymentTermId = 0;
      }
    } catch (error) {
      //this.message.error('delete standard payment term failed')
      this.deletedStandardPaymentTermId = 0;
    }
  }
}
