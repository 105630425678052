import { Subject, Subscription } from "rxjs";
import { DynamicComponentService } from "../services/dynamic-component.service";
import { EventBus } from "../event/EventBus";
import { BaseComponent } from "../components/base/base.component";

class ContextField {
  constructor(public name: string, public value: any) {}
  private valueChangeSubject = new Subject();
  private sub: Subscription;

  subscribeToValueChange(callback: (value: any) => void): void {
    this.sub = this.valueChangeSubject.subscribe((v) => {
      callback(v);
    });
  }

  unsubscribe() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  patchValue(value) {
    this.value = value;
    this.valueChangeSubject.next(value);
  }
}

export class DynamicContext {
  static MODE_VIEW = "view";
  static MODE_EDIT = "edit";
  public SubmitState = false;
  mode: string;
  private fieldMap = new Map<string, ContextField>();
  public service: DynamicComponentService;
  private model: any;
  private eventBus: EventBus;
  private eventBusSubscription: Subscription[];
  public parent: DynamicContext;
  private componentMap = new Map<string, BaseComponent>();

  constructor() {
    this.eventBus = new EventBus();
    this.eventBusSubscription = [];
  }

  changeSubmitState() {
    this.SubmitState = true;
  }

  emit(eventName: string, params: any) {
    this.eventBus.emit(eventName, params);
  }

  on(eventName: string, callback: (params: any) => void) {
    const sub = this.eventBus.on(eventName, callback);
    this.eventBusSubscription.push(sub);
  }

  subscribeToFieldChange(
    fieldName: string,
    callback: (value: any) => void
  ): void {
    this.fieldMap.get(fieldName).subscribeToValueChange((v) => {
      callback(v);
    });
  }

  cleanUp() {
    this.fieldMap.forEach((v, k) => v.unsubscribe());
    this.eventBusSubscription.forEach((s) => s.unsubscribe());
  }

  setComponent(cName: string, component: BaseComponent) {
    this.componentMap.set(cName, component);
  }

  getComponent(cName: string) {
    return this.componentMap.get(cName);
  }

  set(fieldName, value) {
    const existField = this.get(fieldName);
    if (existField) {
      existField.patchValue(value);
      return;
    }
    const field = new ContextField(fieldName, value);
    this.fieldMap.set(fieldName, field);
  }

  get(fieldName): ContextField {
    return this.fieldMap.get(fieldName);
  }

  getValue(fieldName: string): any {
    if (this.get(fieldName)) {
      return this.get(fieldName).value;
    }
    return "";
  }

  viewMode() {
    return this.mode === DynamicContext.MODE_VIEW;
  }

  isEditMode() {
    return this.mode === DynamicContext.MODE_EDIT;
  }
  setModel(model: any) {
    this.model = model;
  }
  getModel() {
    return this.model;
  }
}

// export class DynamicContextProxy {
//   context: DynamicContext
//   constructor() {
//     this.context = new DynamicContext()
//   }
//   // const proxy = new Proxy(this.context, {
//   //   set: function (obj, prop, value) {
//   //     obj.addField(prop, value)
//   //     return true
//   //   },
//   //   get: function (obj, prop) {
//   //     return obj.getField(prop)
//   //   }
//   // })
//   // this.context = proxy
// }
