import { Injectable, Injector } from "@angular/core";
import { HttpInterceptor, HttpErrorResponse, HttpEvent, HttpHandler, HttpRequest, HttpResponse } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { HttpHeaderResponse } from "@angular/common/http";
import { catchError, mergeMap } from "rxjs/operators";
import { Router } from "@angular/router";
import { AuthService } from "./auth.service";
import { DialogService, DialogType, ButtonItem } from "../dialog.service";
import { showPageError } from "../../../config/config"
import { resolve } from "q";
import { LoadingService } from "../loading.service";
@Injectable()
export class ResInterceptor implements HttpInterceptor {
  constructor(
    private injector: Injector,
    private auth: AuthService,
    private dialogService: DialogService,
    private loadingService: LoadingService
  ) {}

  private goTo(url: string) {
    setTimeout(() => this.injector.get(Router).navigateByUrl(url));
  }

  private loadErrorPage(event: HttpErrorResponse): void {
    if (showPageError) {
      const status = event.status;
      this.goTo(`error/${status}`);
    }
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpHeaderResponse | HttpResponse<any>> {
    return next.handle(req).pipe(
      mergeMap((event: any) => {
        if (event instanceof HttpResponse && event.status === 200) {
          return this.handleData(event);
        }
        return of(event);
      }),
      catchError((err: HttpErrorResponse) => this.handleData(err))
    );
  }

  private handleData(
    event: HttpResponse<any> | HttpErrorResponse
  ): Observable<any> {
    if (event && event.url === this.loadingService.url) {
      this.loadingService.closeLoading();
    }
    switch (event.status) {
      case 200:
        if (event instanceof HttpResponse) {
          const body: any = event.body;
          if (body && body.isSuccess === false) {
            console.log(body.error.errorMessage);
          }
        }
        break;
      case 401:
        if (event instanceof HttpErrorResponse) {
          console.log(
            "token authentication fail",
            event.error || event.message
          ); // should be a general message
        }
        break;
      case 404:
        this.goTo(`/error/${event.status}`);
        break;
      case 405:
        // const ok: ButtonItem = {
        // label: 'OK',
        // action: () => {

        /**
         * comment with msal
         * 
         if (environment.role === "supplier") {
           this.auth.refreshB2CUrl("supplier");
          } else {
            this.auth.refreshUrl();
          }
        */

        resolve("refresh");
        // }
        // }
        // const message = 'Your token has been expired, please try to re-login the system.'
        // const header = 'Please Notice'
        // this.dialogService.confirm(DialogType.warning, message, [ok], header)
        break;
      case 403:
      case 500:
        if (event instanceof HttpErrorResponse) {
          console.log(event.error || event.message); // should be a general message
          this.loadErrorPage(event);
        }
        break;
      default:
        if (event instanceof HttpErrorResponse) {
          console.warn(
            event.message, // should be a general message
            event
          );
          throw new Error(event.message);
        }

        break;
    }
    return of(event);
  }
}
