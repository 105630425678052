import { Injectable, OnDestroy } from "@angular/core";
import { LayoutComponent } from "../components/layout/layout.component";
import { BlankComponent } from "../components/blank/blank.component";
import { DatasourceFactory } from "../datasource/DatasourceFactory";
import { Datasources } from "../datasource/Datasources";
import { ValidatorFactory } from "../validators/ValidatorFactory";
import { HttpClient } from "@angular/common/http";
import { ValidatorFn, AsyncValidatorFn } from "@angular/forms";
import { FilterFactory } from "../filters/FilterFactory";
import { Filters } from "../filters/Filters";
import { Expressions } from "../expressions/Expressions";
import { ExpressionDecoratorComposition } from "../expressions/ExpressionDecoratorComposition";
import { EventBus } from "../event/EventBus";
import { Subscription } from "rxjs";
import { HiddenComponent } from "../components/hidden/hidden.component";
import { Hooks } from "../hooks/hooks";
import { HooksFactory } from "../hooks/hooksFactory";
import { FilterDecoratorComposition } from "../filters/FilterDecoratorComposition";
import { HookDecoratorComposition } from "../hooks/HookDecoratorComposition";
import { ValidatorDecoratorComposition } from "../validators/ValidatorDecoratorComposition";
import { LanguageService } from "src/app/dynamic-components/utils/language.service";
@Injectable()
export class DynamicComponentService implements OnDestroy {
  public validatorFactory: ValidatorFactory;
  private eventBus: EventBus;
  private eventBusSubscription: Subscription[];
  private viewOnlyComponents = new Map<string, string>();
  public globalViewOnlyComponent: any;

  constructor(
    public httpClient: HttpClient,
    private languageService: LanguageService
  ) {
    this.validatorFactory = new ValidatorFactory();
    this.validatorFactory.service = this;
    this.eventBus = new EventBus();
    this.eventBusSubscription = [];
    this.registerCustomExpressionClass(
      new ExpressionDecoratorComposition(this)
    );
    this.registerCustomHookClass(
      new HookDecoratorComposition(this, this.languageService)
    );
    this.registerCustomFilterClass(new FilterDecoratorComposition());
    this.registerCustomValidatorClass(new ValidatorDecoratorComposition());
    this.registerComponent("layout", LayoutComponent, true);
    this.registerComponent("blank", BlankComponent);
    this.registerComponent("hidden", HiddenComponent);
  }

  private containerComponentMap: { [key: string]: any } = {};

  private componentMap: { [key: string]: any } = {};

  registerCustomValidator(name: string, fn: ValidatorFn, objInstance: Object) {
    this.validatorFactory.registerValidator(name, fn, objInstance);
  }

  registerAsyncCustomValidator(
    name: string,
    fn: AsyncValidatorFn,
    objInstance: Object
  ) {
    this.validatorFactory.registerAsyncValidator(name, fn, objInstance);
  }

  registerCustomValidatorClass(obj: Object) {
    const validatoMetadata = obj["metadata"];
    if (!validatoMetadata) {
      return;
    }
    const validators = validatoMetadata.validators as Array<any>;
    if (!validators) {
      return;
    }
    validators.forEach((v) => {
      if (v.async) {
        this.registerAsyncCustomValidator(v.name, v.fn, obj);
      } else {
        this.registerCustomValidator(v.name, v.fn, obj);
      }
    });
  }

  registerDatasourceClass(obj: Object) {
    const datasourceMetadata = obj["metadata"];
    if (!datasourceMetadata) {
      return;
    }
    const datasources = datasourceMetadata.datasources as Array<any>;
    if (!datasources) {
      return;
    }
    datasources.forEach((v) => {
      this.registerDatasource(v.name, v.fn, obj);
    });
  }

  registerDatasource(
    name: string,
    dsFactory: DatasourceFactory,
    objInstance: Object
  ) {
    dsFactory.objInstance = objInstance;
    Datasources.registerDatasource(name, dsFactory);
  }

  getDatasource(name: string): DatasourceFactory {
    if (!Datasources.getDatasource(name)) {
      console.log("no datasource found for name : " + name);
    }
    return Datasources.getDatasource(name);
  }
  registerCustomExpressionClass(obj: Object) {
    const expressionMetadata = obj["metadata"];
    if (!expressionMetadata) {
      return;
    }
    const expressions = expressionMetadata.expressions as Array<any>;
    if (!expressions) {
      return;
    }
    expressions.forEach((f) => {
      this.registerExpression(f.name, f.fn, obj);
    });
  }
  registerCustomFilterClass(obj: Object) {
    const filterMetadata = obj["metadata"];
    if (!filterMetadata) {
      return;
    }
    const filters = filterMetadata.filters as Array<any>;
    if (!filters) {
      return;
    }
    filters.forEach((f) => {
      this.registerFilter(f.name, f.fn, obj);
    });
  }
  registerCustomHookClass(obj: Object) {
    const filterMetadata = obj["metadata"];
    if (!filterMetadata) {
      return;
    }
    const hooks = filterMetadata.hooks as Array<any>;
    if (!hooks) {
      return;
    }
    hooks.forEach((f) => {
      this.registerHook(f.name, f.fn, obj);
    });
  }

  registerExpression(name: string, fn: FilterFactory, objInstance: Object) {
    fn.objInstance = objInstance;
    Expressions.registerExpressionFactory(name, fn);
  }
  registerFilter(name: string, fn: FilterFactory, objInstance: Object) {
    fn.objInstance = objInstance;
    Filters.registerFilterFactory(name, fn);
  }

  registerHook(name: string, fn: HooksFactory, objInstance: Object) {
    fn.objInstance = objInstance;
    Hooks.registerHooksFactory(name, fn);
  }

  getFilter(name: string) {
    return Filters.getFilterFactory(name);
  }

  registerContainer(type: string, component: any) {
    this.registerComponent(type, component, true);
  }

  registerComponent(type: string, component: any, container = false) {
    if (container) {
      this.containerComponentMap[type] = component;
    }
    this.componentMap[type] = component;
  }

  getComponent(type: string): any {
    return this.componentMap[type];
  }

  registerViewOnlyComponent(type: string, componentName: string) {
    this.viewOnlyComponents.set(type, componentName);
  }

  getViewOnlyComponent(type: string): string {
    return this.viewOnlyComponents.get(type);
  }

  isContainerComponent(type: string): boolean {
    return !!this.containerComponentMap[type];
  }

  emit(eventName: string, params: any) {
    this.eventBus.emit(eventName, params);
  }

  on(eventName: string, callback: (params: any) => void) {
    const sub = this.eventBus.on(eventName, callback);
    this.eventBusSubscription.push(sub);
  }

  ngOnDestroy() {
    this.eventBusSubscription.forEach((s) => s.unsubscribe());
  }
}
