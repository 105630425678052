import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  HostListener,
  ElementRef,
  ViewChild,
} from "@angular/core";
import { NotificationService } from "src/app/services/notification.service";
import { Router } from "@angular/router";
import { DashBoardService } from "src/app/modules/buyer/services/dashboard.service";
import { AuthService } from "src/app/services/auth/auth.service";
import {
  Notification,
  NotificationType,
} from "../../../interfaces/notification";

@Component({
  selector: "app-notification",
  templateUrl: "./notification.component.html",
  styleUrls: ["./notification.component.sass"],
})
export class NotificationComponent implements OnInit {
  constructor(
    private notificationService: NotificationService,
    private authService: AuthService,
    private dashBoardService: DashBoardService,
    private router: Router
  ) {}

  ngOnInit() {}

  get latestNotification() {
    return this.notificationService.latesNotification;
  }

  navigateTo(item: Notification) {
    this.notificationService.markAsRead(item.ID + "");
    if (this.notificationService.isBuyer()) {
      if (
        item.EventType === NotificationType.AP_QC_ReviewReject ||
        item.EventType === NotificationType.AP_SST_ReviewApproved
      ) {
        this.router.navigate([
          `buyer/onboarding/process/${item.Params.SupplierCode}`,
        ]);
      } else if (
        item.EventType === NotificationType.RequestorChangeAfterReject ||
        item.EventType === NotificationType.RequestorChangeAfterOnboarded
      ) {
        this.router.navigate([`buyer/history/${item.Params.SupplierCode}/0`]);
      } else if (
        item.EventType === NotificationType.SupplierChangeAfterReject ||
        item.EventType === NotificationType.SupplierChangeAfterOnboarded
      ) {
        this.router.navigate([`buyer/history/${item.Params.SupplierCode}/1`]);
      } else if (item.EventType === NotificationType.SupplierDraftComplete) {
        this.router.navigate([`buyer/supplier/${item.Params.SupplierCode}`]);
      }
    } else {
      if (item.EventType === NotificationType.AP_SST_Reject) {
        this.router.navigate([
          `supplier/onboarding/process/${item.Params.SupplierCode}`,
        ]);
      } else {
        // this.router.navigate([`supplier/history/${item.Params.SupplierCode}`])
      }
    }
  }
}
