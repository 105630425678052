import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class DashBoardService {
  public supplierName = "";
  public isDashBoard = true;
  public backTo = ["buyer"];
  public title = "";
  public supplierCode?: string;
  public isMigrated = false;
  public isSearchInTsm = false;
  public isTsmEnterOnboarding = false;
  public tsmKey1 = "";
  public tsmKey2 = "";
  constructor(private router: Router) {}
}
