import { Component, OnInit, HostListener, ViewChild, ElementRef } from "@angular/core";
import {
  SupplierPendingStatus,
  privacyStatementState,
} from "src/app/interfaces/mapping";
import { AuthService } from "src/app/services/auth/auth.service";
import { RoleName } from "src/app/interfaces/buyer";
// import { checkDetailStatus } from 'src/app/interfaces/supplierModel'

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.sass"],
})
export class FooterComponent implements OnInit {
  showTooltip: boolean;
  @ViewChild("estimateSpendTooltip") estimateSpendTooltip: ElementRef;

  constructor(
    private auth: AuthService
  ) {}

  ngOnInit() {}

  privacyStatement() {
    if (
      privacyStatementState.privacyStatement ||
      privacyStatementState.isDisplayAccept
    ) {
      return;
    }
    privacyStatementState.linkType = "footerLink";
  }
  termOfUse() {
    privacyStatementState.termOfUse = true;
  }

  estimateChooseOver(e, estimateSpendTooltip) {
    const TooltipEle = estimateSpendTooltip;
    if (estimateSpendTooltip) {
      if (
        e.clientX < TooltipEle.nativeElement.getBoundingClientRect().left ||
        e.clientX >
          TooltipEle.nativeElement.getBoundingClientRect().left +
            TooltipEle.nativeElement.offsetWidth -
            5 ||
        e.clientY < TooltipEle.nativeElement.getBoundingClientRect().top ||
        e.clientY >
          TooltipEle.nativeElement.getBoundingClientRect().top +
            TooltipEle.nativeElement.offsetHeight -
            5
      ) {
        this.showTooltip = false;
      }
    } else {
      this.showTooltip = false;
    }
  }
}
