import { Injectable } from "@angular/core";
import { BuyerService } from "./buyer.service";
import { SupplierService } from "./supplier.service";
import { environment } from "src/environments/environment";
import { Pager, Notification, createPager } from "../interfaces/notification";
import { WebSocketService } from "./websocket.service";
import { AuthService } from "./auth/auth.service";

@Injectable()
export class NotificationService {
  page: Pager<Notification>;
  pageCenter: Pager<Notification>;
  latesNotification: Notification;
  userID: number;
  notifications: Notification[] = [];
  isShowCenter = false;
  monthDef = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  constructor(
    private buyerService: BuyerService,
    private supplierService: SupplierService,
    private ws: WebSocketService,
    private authService: AuthService
  ) {
    // let notifications = setInterval(() => {
    //   this.loadData()
    //   this.loadDataCenter()
    // }, 180000)
    this.pageCenter = createPager();
    // this.loadData()
  }

  isBuyer() {
    if (environment.role === "buyer") {
      return true;
    }
    return false;
  }

  public loadData() {
    if (this.isBuyer()) {
      this.userID = this.authService.passport.buyer.ID;
      this.buyerService
        .notificationList(this.userID, 1, 100)
        .then((data: any) => {
          this.page = data.data;
          this.notifications = data.data.data;
          let first;
          this.notifications.map((data1) => {
            data1.CreatedDateStr = this.formatEventDate(data1.CreatedDate);
            if (!first && data1.Status === 0) {
              first = data1;
            }
          });
          this.latesNotification = first;
        });
    } else {
      this.userID = this.authService.passport.supplierMember.ID;
      this.supplierService
        .notificationList(this.userID, 1, 100)
        .then((data: any) => {
          this.page = data.data;
          this.notifications = data.data.data;
          let first;
          this.notifications.map((data1) => {
            data1.CreatedDateStr = this.formatEventDate(data1.CreatedDate);
            if (!first && data1.Status === 0) {
              first = data1;
            }
          });
          this.latesNotification = first;
        });
    }
  }

  public loadDataCenter() {
    if (this.isBuyer()) {
      this.userID = this.authService.passport.buyer.ID;
      this.buyerService
        .notificationList(this.userID, this.pageCenter.cp, this.pageCenter.ps)
        .then((data: any) => {
          this.pageCenter = data.data;
          this.pageCenter.data.map((data1) => {
            data1.CreatedDateStr = this.formatEventDate(data1.CreatedDate);
          });
        });
    } else {
      this.userID = this.authService.passport.supplierMember.ID;
      this.supplierService
        .notificationList(this.userID, this.pageCenter.cp, this.pageCenter.ps)
        .then((data: any) => {
          this.pageCenter = data.data;
          this.pageCenter.data.map((data1) => {
            data1.CreatedDateStr = this.formatEventDate(data1.CreatedDate);
          });
        });
    }
  }

  private formatEventDate(date: Date): string {
    const cd = new Date(date);
    const day = cd.getDate() > 9 ? cd.getDate() : "0" + cd.getDate();
    const year = cd.getFullYear();
    const month = cd.getMonth();
    const hour = cd.getHours() > 9 ? cd.getHours() : "0" + cd.getHours();
    const minute =
      cd.getMinutes() > 9 ? cd.getMinutes() : "0" + cd.getMinutes();
    const seconds =
      cd.getSeconds() > 9 ? cd.getSeconds() : "0" + cd.getSeconds();
    return (
      day +
      "-" +
      this.monthDef[month] +
      "-" +
      year +
      " " +
      hour +
      ":" +
      minute +
      ":" +
      seconds
    );
  }

  public markAsRead(notificationID: string) {
    if (this.isBuyer()) {
      this.buyerService.markAsRead(this.userID, notificationID);
    } else {
      this.supplierService.markAsRead(this.userID, notificationID);
    }
  }
}
