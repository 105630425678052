import { Injectable } from "@angular/core";
import { RouterStateSnapshot, ActivatedRouteSnapshot } from "@angular/router";
import { AuthService } from "src/app/services/auth/auth.service";
import { environment } from "src/environments/environment";
import { RoleName } from "src/app/interfaces/buyer";

@Injectable({
  providedIn: "root",
})
export class SystemGuard {
  constructor(private auth: AuthService) {}

  async canActivate() {
    if (environment.role === "buyer") {
      return this.auth.passport.buyer.RoleCode === RoleName.SystemAdmin;
    }
    return false;
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    childState: RouterStateSnapshot
  ) {
    if (environment.role === "buyer") {
      return (
        this.auth.passport.buyer.RoleCode === RoleName.SystemAdmin ||
        this.auth.passport.buyer.RoleCode === RoleName.APQualityControl
      );
    }
    return false;
  }
}
