// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.merge-profile-container {
  background: linear-gradient(to right, #764BA2, #3C73D2);
}

.tbheader {
  margin-top: 40px;
  font-size: 16px;
  text-align: left;
  font-weight: 700;
}

.arrow {
  display: inline-block;
  position: relative;
  width: 100%;
  height: 2px;
  background-color: black;
}
.arrow::before, .arrow::after {
  content: "";
  position: absolute;
  top: 50%;
  width: 0;
  height: 0;
  border: solid black;
}
.arrow::before {
  left: 100%;
  border-width: 5px 0 5px 5px;
  border-color: transparent transparent transparent black;
  transform: translateY(-50%);
}
.arrow::after {
  display: none;
}

.supplier-name-container {
  text-align: center;
}
.supplier-name-container .supplier-name {
  padding: 4%;
}

.error-merge-profile-space {
  margin-top: 2%;
}
.error-merge-profile-space .error-merge-profile {
  color: red;
}

.confirmation-dialog-box {
  background-color: whitesmoke;
  width: 28%;
  text-align: center;
  align-content: center;
  margin: 2% 34%;
  border: 2px solid black;
  position: relative;
  z-index: 99;
}
.confirmation-dialog-box .dialog-box-header {
  margin-top: 4%;
  margin-bottom: -2%;
}
.confirmation-dialog-box .confirmation-dialog-box-label-container {
  padding: 10% 6% 0 6%;
}
.confirmation-dialog-box .confirmation-dialog-box-button-container {
  padding: 4%;
}
.confirmation-dialog-box .confirmation-dialog-box-button-container .confirmation-dialog-box-button {
  margin: 0 8%;
  padding: 1% 4%;
}

.close-button-container {
  padding: 5%;
}
.close-button-container .close-button {
  background-color: white;
  border: 1px solid black;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
