import { DashBoardService } from "./../../../services/dashboard.service";
import { Component, OnInit ,OnDestroy} from "@angular/core";
import { OnBoardingService } from "../../../services/onboarding.service";
import { Router, ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { ConfirmService } from "src/app/services/dialog.service";

@Component({
  selector: "app-onboarding-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.sass"],
})
export class OnBoardingHeaderComponent implements OnInit , OnDestroy{
  isDisable = false;
  supplierComNb = "";
  TsmId = "";
  isFromTSM = false;
  isHideDshbdHelp = false;
  coupaFlag = false;
  get supplierCode() {
    return this.onBoardingService.supplierCode;
  }

  constructor(
    private router: Router,
    private onBoardingService: OnBoardingService,
    private dashBoardService: DashBoardService,
    private activatedRoute: ActivatedRoute,
    protected translateService: TranslateService,
    private confirmService: ConfirmService
  ) {
    // this.supplierComNb = this.activatedRoute.snapshot.paramMap.get('code')
    this.TsmId = onBoardingService.supplierModel.Mapping.TsmId;
    this.confirmService.sendFlag$.subscribe((coupaFlag) => {
      this.coupaFlag = coupaFlag;
    });
  }

  get current_step() {
    return this.onBoardingService.current_step;
  }

  ngOnInit() {
    this.isFromTSM = !!this.TsmId;
    if (this.dashBoardService.isSearchInTsm) {
      this.isFromTSM = true;
      // this.TsmId = this.dashBoardService.tsmKey1
      // hide the help and dashboard icon at nav-side
      this.isHideDshbdHelp = true;
    }
  }

  navigateTo(step) {
    if (!this.coupaFlag) {
      this.onBoardingService.navigateTo(step, this.supplierCode);
    }
  }

  backTo() {
    if (this.coupaFlag) {
      this.router.navigate([
        `buyer/onboarding/process/${this.onBoardingService.supplierCode}`,
      ])
    } else {
      this.isDisable = true;
      // this.onBoardingService.backTo()
    }
  }
  cancelEvent(event) {
    this.isDisable = false;
  }

  ngOnDestroy(){
    this.coupaFlag = false;
  }
}
