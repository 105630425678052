import { Component, OnInit, HostListener, DoCheck } from "@angular/core";
import { SupplierHeaderService } from "../../services/header.service";
import { AuthService } from "src/app/services/auth/auth.service";
import { Router } from "@angular/router";
import { CommonService } from "src/app/services/common.service";
import { ProfileService } from "../../services/profile.service";
import {
  SupplierPendingStatus,
  privacyStatementState,
  SupplierStatus,
  StatusType,
  AP_ReviewStatus,
} from "src/app/interfaces/mapping";
import { SupplierService } from "src/app/services/supplier.service";
// import { checkDetailStatus, checkAPStatus } from 'src/app/interfaces/supplierModel'
import {
  ProcessNames,
  MigrateProcess,
  SetupProcess,
  ProcessInstance,
} from "src/app/interfaces/workflow";
import { environment } from "../../../../../environments/environment";
import { LoadingService } from "src/app/services/loading.service";
import { getTSMCountryServedQuery } from "../../../../shared/shared";

@Component({
  selector: "app-landing",
  templateUrl: "./landing.component.html",
  styleUrls: ["./landing.component.sass"],
})
export class LandingComponent implements OnInit, DoCheck {
  isProfileCompleted = false;
  privacyStatement = privacyStatementState;
  oldMultipleSupplier = false;
  isOnboarded = false;
  loading = false;
  loadingSupplierStatus = true;
  constructor(
    private supplierHeaderService: SupplierHeaderService,
    private router: Router,
    private supplierService: SupplierService,
    private authService: AuthService,
    private profileService: ProfileService,
    private loadingService: LoadingService
  ) {}

  TsmId = "";
  TsmLink = "";
  get isTerminate(): boolean {
    return this.profileService.supplierModel.Mapping.status ===
      SupplierStatus.terminated
      ? true
      : false;
  }
  get isMigrated(): boolean {
    return this.profileService.supplierModel.Mapping.IsMigrated;
  }
  get isTsmId(): boolean {
    this.TsmId = this.authService.passport.supplier
      ? this.authService.passport.supplier.Mapping.TsmId
      : "";
    return !!this.authService.passport.supplier.Mapping.TsmId;
  }
  get isTsmIdForDynamicLink(): boolean {
    this.TsmId = this.authService.passport.supplier
      ? this.authService.passport.supplier.Mapping.TsmId
      : "";
    const env = sessionStorage.getItem("tsmEnv");
    const url =
      env === "dev"
        ? environment.relationshipdev_url
        : env === "perf"
        ? environment.relationshipperf_url
        : env === "hotfix"
        ? environment.relationshiphotfix_url
        : env === "stage"
        ? environment.relationshipstage_url
        : env === "test"
        ? environment.relationshiptest_url
        : environment.relationship_url;
    const countryServed = getTSMCountryServedQuery(
      this.profileService.supplierModel.Mapping.setUpCountry,
    );
    this.TsmLink =
      url +
      this.TsmId +
      "?countryServed=" +
      countryServed +
      "&login_hint=" +
      this.authService.passport.supplier.Mapping.supplierEmail;
    console.debug(`Jump to TSM url: ${this.TsmLink}`);
    return !!this.authService.passport.supplier.Mapping.TsmId;
  }
  async ngOnInit() {
    const saveUrl = `${environment.gateway}/supplier/landing`
    this.loadingService.openLoading(saveUrl)
    this.oldMultipleSupplier = this.privacyStatement.multipleSupplier;
    if (
      this.authService.passport.supplier &&
      !this.profileService.supplierModel
    ) {
      this.profileService.initSupplierProfile();
    }
    if (
      this.profileService.supplierModel &&
      this.profileService.supplierModel.Mapping
    ) {
      // await this.profileService.loadUserTasks();
      await this.landingPageCheckSupplierStatus();
    }
    this.loadingService.closeLoading();
  }
  async ngDoCheck() {
    if (this.privacyStatement.multipleSupplier !== this.oldMultipleSupplier) {
      if (this.authService.passport.supplier) {
        this.profileService.initSupplierProfile();
        if (this.loading) {
          return;
        }
        this.loading = true;
        try {
          await this.profileService.loadUserTasks();
          await this.landingPageCheckSupplierStatus();
          this.loading = false;
          this.oldMultipleSupplier = this.privacyStatement.multipleSupplier;
        } catch (error) {
          this.loading = false;
        }
      }
    }
  }

  privacyaccept(flag) {
    this.privacyStatement.privacyStatement = false;
    this.privacyStatement.linkType = "";
  }

  accept() {
    if (
      this.authService.passport.supplier.Mapping.profile.supplierAccepted ===
      "accept"
    ) {
      const supplierCode =
        this.authService.passport.supplier.Mapping.supplierCode;
      this.supplierService.supplierUnderstand(supplierCode).then((d) => {
        this.authService.passport.supplier.Mapping.profile.supplierAccepted =
          "understand";
        this.privacyStatement.isDisplayAccept = false;
        if (this.authService.passport.supplier.Mapping.TsmId) {
          this.createProfile();
        }
      });
    }

    if (this.authService.passport.supplierMember.PrivacyStatus === "new") {
      this.authService.passport.supplierMember.PrivacyStatus = "accept";
    }

    if (this.authService.passport.supplierMember.PrivacyStatus === "accept") {
      const supplierCode =
        this.authService.passport.supplier.Mapping.supplierCode;
      const supplierEmail = this.authService.passport.supplierMember.Email;
      this.supplierService
        .supplierNormalUserAccept(supplierEmail, supplierCode)
        .then((d) => {
          this.authService.passport.supplierMember.PrivacyStatus = "understand";
          this.privacyStatement.isDisplayAccept = false;
          if (this.authService.passport.supplier.Mapping.TsmId) {
            this.createProfile();
          }
        });
    }
  }

  createProfile() {
    const supplierCode =
      this.authService.passport.supplier.Mapping.supplierCode;
    this.profileService.mode = "create";
    this.router.navigate([`/supplier/profile/${supplierCode}/step1`]);
  }

  navigateTo(type) {
    const supplierCode =
      this.authService.passport.supplier.Mapping.supplierCode;
    if (type === "view") {
      this.profileService.mode = "view";
      this.router.navigate([`supplier/profile/${supplierCode}/step1`]);
      this.profileService.isViewSupplier = true;
    }
  }
  async landingPageCheckSupplierStatus() {
    // const currentProcess: ProcessInstance[] = await this.profileService.getProcessInstanceByBusinessKey(this.profileService.supplierModel.Mapping.supplierCode)
    // currentProcess.forEach(item => {
    //   if (item.name === ProcessNames.generic_supplierMigrateSetup) {
    //     this.isMigrated = true
    //   }
    // })

    if (
      this.profileService.checkTaskIsComplete(
        ProcessNames.generic_supplierSetup,
        SetupProcess.profile_draft
      ) ||
      this.profileService.checkTaskIsComplete(
        ProcessNames.generic_supplierMigrateSetup,
        MigrateProcess.profile_draft
      ) ||
      this.isMigrated
    ) {
      this.isProfileCompleted = true;
      this.loadingSupplierStatus = false;
    } else {
      this.loadingSupplierStatus = false;
    }
    if (
      this.authService.passport.supplier.Mapping.profile.supplierAccepted ===
        "new" ||
      this.authService.passport.supplierMember.PrivacyStatus === "new"
    ) {
      this.privacyStatement.privacyStatement = true;
      this.privacyStatement.isDisplayAccept = false;
    }

    if (
      this.authService.passport.supplier.Mapping.status ===
      SupplierStatus.onboarded
    ) {
      this.isOnboarded = true;
    }
  }
}
