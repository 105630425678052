import { Component, OnInit } from "@angular/core";
import { BaseComponent } from "src/app/dynamic-components/components/base/base.component";

@Component({
  selector: "app-number",
  templateUrl: "./number.component.html",
  styleUrls: ["./number.component.sass"],
  host: {
    '[style.width]': 'config.width',
  }
})
export class NumberComponent extends BaseComponent implements OnInit {
  showTooltip: boolean;

  constructor() {
    super();
  }

  ngOnInit() {}

  percentOne() {
    let theVal = "";
    const Arr = [];
    theVal = this.value;
    // if (val === 'Enterprises') {
    //   theVal = this.complianceInfo.SmallMediumSizedEnterprisesPercentage
    // } else if (val === 'SuppliersPer') {
    //   theVal = this.complianceInfo.DifferentSuppliersPercentage
    // }
    const repVal = theVal.replace(/[^\d\.]/g, "");
    Arr.push(...repVal.split("."));
    const emptyFalg: boolean = Arr.every((i) => i === "");
    let lastVal = "";
    if (!emptyFalg) {
      if (Arr.length >= 2) {
        if (Arr[0] === "" && Arr[1] !== "") {
          lastVal = `0.${Arr[1].substr(0, 2)}%`;
        } else if (Arr[0] !== "" && Arr[1] === "") {
          lastVal = `${Arr[0]}%`;
        } else if (Arr[0] !== "" && Arr[1] !== "") {
          lastVal;
        }
      } else if (Arr.length === 1) {
        lastVal = `${Arr[0]}%`;
      } else {
        lastVal = "0%";
      }
    }
    this.value = lastVal;
    // if (val === 'Enterprises') {
    //   this.complianceInfo.SmallMediumSizedEnterprisesPercentage = lastVal
    // } else if (val === 'SuppliersPer') {
    //   this.complianceInfo.DifferentSuppliersPercentage = lastVal
    // }
  }

  checkNumber() {
    setTimeout(() => {
      const theVal = this.value;
      const reg = /^(\-)?\d+(\.\d+)?$/;

      if (reg.test(theVal)) {
        const parsed = parseInt(theVal);
        if (parsed == -0) {
          this.value = "0";
        } else {
          this.value = parsed + "";
        }
      } else {
        this.value = "";
      }
    }, 0);
  }

  estimateChooseOver(e, estimateSpendTooltip) {
    const TooltipEle = estimateSpendTooltip;
    if (estimateSpendTooltip) {
      if (
        e.clientX < TooltipEle.nativeElement.getBoundingClientRect().left ||
        e.clientX >
          TooltipEle.nativeElement.getBoundingClientRect().left +
            TooltipEle.nativeElement.offsetWidth -
            5 ||
        e.clientY < TooltipEle.nativeElement.getBoundingClientRect().top ||
        e.clientY >
          TooltipEle.nativeElement.getBoundingClientRect().top +
            TooltipEle.nativeElement.offsetHeight -
            5
      ) {
        this.showTooltip = false;
      }
    } else {
      this.showTooltip = false;
    }
  }
}
