import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { RebarAuthService } from "./core/rebarauth/rebar.auth.service";
import {
  AuthenticationResult,
  EventMessage,
  EventType,
  InteractionStatus,
} from "@azure/msal-browser";
import { environment } from "src/environments/environment";
import { datadogRum } from "@datadog/browser-rum";
import { AppConfigService } from "./core/services/app-config.service";
import { DatadogSessionService } from "./core/services/datadog-session.service";
import { ButtonCommands, DialogService, DialogType } from "./services/dialog.service";
import { AuthService } from "./services/auth/auth.service";
import { MatDialog } from '@angular/material/dialog';
import { languageList } from '../app/shared/components/layout/language/language-list';
import { LanguageService } from '../app/dynamic-components/utils/language.service'
import { SupplierGeneralService } from "./services/supplier-general.service";
import {LaunchDarklyService} from "./services/launch-darkly.service";

const redirectSupplierProfileContent = {
  en: {
    title: 'Warning message',
    message: 'User redirect to the TSM Application. Click ‘Ok’ to proceed.'
  },
}
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.sass"],
})
export class AppComponent implements OnInit {
  title = "procurement";
  browserValidation = true;
  isLogin = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private rebarAuthService: RebarAuthService,
    protected translateService: TranslateService,
    private appConfigService: AppConfigService,
    private datadogSessionService: DatadogSessionService,
    private dialogService: DialogService,
    private authService: AuthService,
    private languageService: LanguageService,
    public dialog: MatDialog,
    private supplierGeneralService: SupplierGeneralService,
     private launchDarklyService :LaunchDarklyService
  ) {
    console.log("... inside app.component.ts constructor");
    this.isLogin = this.rebarAuthService.isUserAuthenticated();
    // If not set the language in localStorage
    if (environment.role === "buyer" || !localStorage.getItem("language")) {
      const defaultLang = this.languageService.getDefaultLang();
      this.languageService.setDefaultLang(defaultLang);
    }

  }
  async ngOnInit() {
    this.rebarAuthService.authObserver$.subscribe(
      (eventMessage: EventMessage) => {
        // console.log(eventMessage, "... eventMessage inside ngOnInit of app.component.ts");
        if (eventMessage && !this.isLogin) {
          // console.log(`-------- user is logged in ${eventMessage.eventType} --------`);
          this.isLogin = !this.isLogin;

          const accountPayload = eventMessage.payload as AuthenticationResult;
          // console.log(accountPayload, ".... accountPayload inside ngOnInit of app.component.ts ");
          this.rebarAuthService.setActiveAccount(accountPayload);

          const states = accountPayload.state.split("__");
          // console.log("-------- tsm state is %s --------", accountPayload.state);
          if (states && states.length > 1) {
            // console.log("***environment role", environment.role);
            // console.log("***environment env", environment.env);
            // tsm navigation for the first login
            let target: string =
              environment.role === "buyer" ? "buyer" : "supplier/landing";

            const stateArr = states[1].split("_");
            target =
              stateArr.length === 2 && stateArr[0] === "tsmstp1"
                ? "buyer/onboarding/" + stateArr[1] + "/step1"
                : stateArr.length === 3 && stateArr[0] === "tsmsrch"
                  ? "buyer/search/" + stateArr[1] + "/" + stateArr[2]
                  : stateArr.length === 4 && stateArr[0] === "tsmland"
                    ? "supplier/landing/" +
                    stateArr[1] +
                    "/" +
                    stateArr[2] +
                    "/" +
                    stateArr[3]
                    : target;

            // console.log("-------- tsm jump target --------", target);
            this.router.navigate([target], { relativeTo: this.route , replaceUrl: true});
          }
        }
      }
    );
    this.launchDarklyService.initLaunchDarkly("any","any","any")

    if (this.isLogin && this.rebarAuthService.isUserAuthenticated()) {
      // tsm navigation for the case which is already have token
      if (window.location.hash.includes("tsmcross") || sessionStorage.getItem('tsmcross')) {
        sessionStorage.setItem('tsmcross', '' + window.location.hash.includes("tsmcross"))
        const tsm = this.rebarAuthService.buildTsmObj();
        const language = this.languageService.findCurrentLanguage(tsm?.language);
        this.languageService.setDefaultLang(language);
        if(tsm){
        const isIndividual:boolean = this.supplierGeneralService.isSupplierIndividual(tsm?.classification)
        this.supplierGeneralService.setIsIndividual(isIndividual)
        }
        const hash = window.location.hash;
        const hashArray = hash.substring(11).split("?");
        const tsmPath = hashArray[0];
        let target: string =
          environment.role === "buyer" ? "buyer" : "supplier/landing";
        if (tsmPath === "step1") {
          target = "buyer/onboarding/" + tsm.supplierCode + "/step1";
        } else if (
          tsmPath === "tsmsearch" &&
          tsm.key1 &&
          tsm.key2 &&
          !tsm.email
        ) {
          target = "buyer/search/" + tsm.key1 + "/" + tsm.key2;
        } else if (
          (tsmPath === "landing/tsmsupplier" ||
            tsmPath === "landing/tsmsupplier/tsmsearch") &&
          tsm.key1 &&
          tsm.key2 &&
          tsm.supplierCode &&
          tsm.email
        ) {
          localStorage.setItem("currentSupplierCode", tsm.supplierCode);
          target =
            "supplier/landing/" + tsm.key1 + "/" + tsm.key2 + "/" + tsm.email + "/" + tsm.supplierCode;
        }
        // console.log("-------- tsm jump target --------", target);
        this.router.navigate([target], {replaceUrl: true});
      }
    }

    if (environment.env === "prod" || environment.env === "stage") {
      datadogRum.init({
        applicationId: (
          this.appConfigService.config["datadog"] as Record<string, string>
        )["appId"],
        clientToken: (
          this.appConfigService.config["datadog"] as Record<string, string>
        )["clientToken"],
        site: "datadoghq.com",
        service: (
          this.appConfigService.config["datadog"] as Record<string, string>
        )["service"],
        env: (
          this.appConfigService.config["datadog"] as Record<string, string>
        )["environment"],
        sampleRate: 100,
        trackInteractions: true,
        allowedTracingOrigins: [
          (this.appConfigService.config["datadog"] as Record<string, string>)[
          "allowedTracingOriginsDNS"
          ],
        ],
      });
      datadogRum.setUser({
        id: this.datadogSessionService.setDataDogSessionId(),
      });
    }
  }
}
