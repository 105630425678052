import { Component, OnInit } from "@angular/core";
import { SupplierHeaderService } from "../../../services/header.service";
import { Route, ActivatedRoute, Router } from "@angular/router";
import { SupplierService } from "src/app/services/supplier.service";
import { IHistoryItem, IListHistoryItems } from "src/app/interfaces/history";
import { SupplierProfile } from "src/app/interfaces/supplierProfile";
import { BuyerService } from "src/app/services/buyer.service";
import { RequestForm } from "src/app/interfaces/requestForm";
import { OnBoardingService } from "src/app/modules/buyer/services/onboarding.service";
import { supplierGeographical } from "src/app/shared/shared";
import { MetadataService } from "src/app/services/metadata.service";
import { LanguageService } from "src/app/dynamic-components/utils/language.service";
import { LoadingService } from "src/app/services/loading.service";
import { LaunchDarklyService } from "src/app/services/launch-darkly.service";

@Component({
  selector: "app-history-supplier",
  templateUrl: "./history-supplier.component.html",
  styleUrls: ["./history-supplier.component.sass"],
})
export class HistorySupplierComponent implements OnInit {
  supplierProfiles: any[] = [];
  buyerHistoryList: any = [];
  supplierHistoryList: any = [];
  supplierCode: any;
  isSupplierDraft: boolean;
  isNewVersion: boolean = true;
  constructor(
    private supplierHeaderService: SupplierHeaderService,
    private route: ActivatedRoute,
    private supplierService: SupplierService,
    private buyerService: BuyerService,
    private onboardingService: OnBoardingService,
    private metadataService: MetadataService,
    private router: Router,
    private languageService: LanguageService,
    public loadingService: LoadingService,
    private launchDarklyService: LaunchDarklyService
  ) {
    this.launchDarklyService.ldChange.subscribe(any => {
      console.log("Trigger refresh data")
      this.refreshToggle()
    })
  }
  refreshToggle():void{
    this.isNewVersion = this.launchDarklyService.getToggle(LaunchDarklyService.LD_ISNEWVERSION).toLowerCase() === "true";
  }
  async ngOnInit() {
    this.loadingService.showLoading();
    this.supplierHeaderService.icon = "history";
    this.supplierHeaderService.title = this.languageService.getValue(
      "static.supplier.history_supplier.My_Transactions"
    );
    this.supplierHeaderService.showProfileButtons = false;
    this.supplierCode = this.route.snapshot.paramMap.get("code");
    this.onboardingService.supplierCode = this.supplierCode;
    await this.onboardingService.loadSupplierRequestForm();

    //const allField: any = await this.supplierService.getProfilesForm(this.supplierCode)

    const allField: any = await this.metadataService.getPageConfiguration(
      "transactions-history"
    );
    let supplierHistories: any;
    if (this.isNewVersion) {
      supplierHistories = await this.supplierService.querySupplierHistoriesEnhanced(this.supplierCode);
    } else {
      supplierHistories = await this.supplierService.querySupplierHistories(this.supplierCode);
    }
    if (supplierHistories.isSuccess) {
      if(this.isNewVersion){
        const newSupplierHistoryItemList: IListHistoryItems<SupplierProfile> =
        new IListHistoryItems(
          supplierHistories.data,
          allField,
          this.languageService
        );
      this.supplierHistoryList = newSupplierHistoryItemList.onLoad();
      this.supplierProfiles = supplierHistories.data;
      } 
      else{
      const historyItemList: IListHistoryItems<SupplierProfile> =
        new IListHistoryItems(
          supplierHistories.data,
          allField,
          this.languageService
        );
      this.supplierHistoryList = historyItemList.load();
      this.supplierProfiles = supplierHistories.data;
      }
    }
    if (this.supplierHistoryList.length > 0) {
      this.isSupplierDraft = true;
    } else {
      this.isSupplierDraft = false;
      this.loadingService.hideLoading();
    }
    if (
      this.onboardingService.supplierModel.RequestForm.RequestorDetailsInfo
        .SupplierGeographical === supplierGeographical.MET
    ) {
      this.supplierHistoryList.forEach((t) => {
        t.value.forEach((s) => {
          if (s.columName === "DiversifiedEnterprises") {
            s.title = this.languageService.getValue(
              "static.supplier.history_supplier.DiversifiedEnterprises"
            );
          }
          if (s.columName === "ComplianceLocalPolicies") {
            s.title = this.languageService.getValue(
              "static.supplier.history_supplier.ComplianceLocalPolicies"
            );
          }
          if (s.columName === "UploadDetailsMaterial") {
            s.title = this.languageService.getValue(
              "static.supplier.history_supplier.UploadDetailsMaterial"
            );
          }
          if (s.columName === "BusinessRegistrationNb") {
            s.title = this.languageService.getValue(
              "static.supplier.history_supplier.BusinessRegistrationNb"
            );
          }
        });
      });
    }
  }

  backToMyProfile() {
    this.router.navigate(["supplier/landing"]);
  }
}
