import { Component, OnInit, Input } from "@angular/core";
import { BuyerService } from "src/app/services/buyer.service";
import { AuthService } from "src/app/services/auth/auth.service";
import { environment } from "src/environments/environment";
import { State } from "../../../../../shared/shared";
import { strToHexCharCode } from "../../../../../utils/index";
import { HttpService } from "src/app/services/api/http.service";
import { LoadingService } from "src/app/services/loading.service";
import { HoldOnService } from "src/app/services/HoldOn";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-on-hold-history",
  templateUrl: "./on-hold-history.component.html",
  styleUrls: ["./on-hold-history.component.sass"],
})
export class OnHoldHistoryComponent implements OnInit {
  isShow: boolean;
  isAuthorised: boolean;
  baseUrl = environment.gateway;
  allHoldOn: any;
  attachmentArray: any = [];
  constructor(
    private buyerService: BuyerService,
    private authService: AuthService,
    private http: HttpService,
    private loadingService: LoadingService,
    private holdOnService: HoldOnService,
    private route: ActivatedRoute
  ) {}

  async ngOnInit() {
    const supplierCode = this.route.snapshot.paramMap.get("code");
    const result: any = await this.holdOnService.getAllHoldOnBySupplierCode(
      supplierCode
    );
    if (result.isSuccess && !!result.data) {
      this.allHoldOn = result.data;
    }
    const roles = ["S_CM", "IS_Legal_GDPR", "AP_SST", "AP_QC", "buyerAdmin", "systemAdmin"];
    const delegateRole = this.authService.passport.buyer.RoleCode;
    this.isAuthorised = !!roles.find((p) => p === delegateRole);
  }
  
  turnIntoArray(data: any, id: any) {
    if (data) {
      this.attachmentArray[id] = JSON.parse(data);
    }
  }

  getName(email: string) {
    if (email.substring(0, email.indexOf("@"))) {
      return email.substring(0, email.indexOf("@"));
    } else {
      return email;
    }
  }  
  
  downloadFile(fileKey, fileName) {
    let role = "";
    let downloadPath = "";
    const access_token = `Bearer ${this.authService.passport.access_token}`;
    const state = environment.role;
    if (state === State.buyer && this.authService.passport.buyer) {
      role = this.authService.passport.buyer.RoleCode;
      downloadPath = "buyer/file/download";
    } else if (
      state === State.supplier &&
      this.authService.passport.supplierMember
    ) {
      role = this.authService.passport.supplierMember.RoleCode;
      downloadPath = "supplier/file/download";
    }
    const params = strToHexCharCode(
      `key=${fileKey}&Authorization=${access_token}&state=${state}&role=${role}`
    );
    // const url = `${this.baseUrl}/${downloadPath}?fileCode=${params}`
    // window.open(url, '_blank')
    const url = `${this.baseUrl}/${downloadPath}`;
    this.loadingService.openLoading(url);
    this.http
      .PostBlobPromise(url, { fileCode: params }, "blob")
      .then((data: BlobPart) => {
        const blob = new Blob([data]);
        // for ie
        if ("msSaveOrOpenBlob" in navigator) {
          window.navigator.msSaveOrOpenBlob(blob, fileName);
        } else {
          const link = document.createElement("a");
          link.setAttribute("href", window.URL.createObjectURL(blob));
          link.setAttribute("download", fileName);
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          this.loadingService.closeLoading();
        }
      });
  }
}
