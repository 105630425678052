import {
  Component,
  OnInit,
  HostListener,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "src/app/services/auth/auth.service";
import { NotificationService } from "src/app/services/notification.service";
import {
  Notification,
  NotificationType,
} from "../../../interfaces/notification";
import { LanguageService } from "src/app/dynamic-components/utils/language.service";

@Component({
  selector: "app-notification-center",
  templateUrl: "./notification-center.component.html",
  styleUrls: ["./notification-center.component.sass"],
})
export class NotificationCenterComponent implements OnInit {
  @ViewChild("notificationCenter")
  notificationCenter: ElementRef;

  sortBy = false;

  previous: string;
  next: string;
  notificationService: NotificationService;

  constructor(
    public _notificationService: NotificationService,
    private authService: AuthService,
    private languageService: LanguageService,
    private router: Router
  ) {
    this.notificationService = _notificationService;
  }

  ngOnInit() {
    this.previous = this.languageService.getValue(
      "static.supplier.notification-center.previous"
    );
    this.next = this.languageService.getValue(
      "static.supplier.notification-center.next"
    );
    setTimeout(() => {
      const notificationdiv = document.getElementsByClassName("my-pagination")[0];
      if (notificationdiv) {
        const notificationcontrol = notificationdiv.childNodes[0];
        if (notificationcontrol) {
          const notificationtemplate = notificationcontrol.childNodes[0];
          if (notificationtemplate) {
            const notificationul = notificationtemplate.childNodes[0];
            if (notificationul) {
              for (let i = 0; i < notificationul.childNodes.length; i++) {
                let notificationli = notificationul.childNodes[i];
                if (notificationli.nodeName == 'LI') {
                  (notificationli as any).role = "group";
                }
              }
            }
          }
        }
      }
    });
  }

  get notifications() {
    return this.notificationService.pageCenter.data.sort((a, b) => {
      const jian: number =
        new Date(a.CreatedDate).getTime() - new Date(b.CreatedDate).getTime();
      if (this.sortBy) {
        return jian;
      } else {
        return 0 - jian;
      }
    });
  }

  navigateTo(item: Notification) {
    this.notificationService.markAsRead(item.ID + "");
    this.closetable();
    if (this.notificationService.isBuyer()) {
      if (
        item.EventType === NotificationType.AP_QC_ReviewReject ||
        item.EventType === NotificationType.AP_SST_ReviewApproved
      ) {
        this.router.navigate([
          `buyer/onboarding/process/${item.Params.SupplierCode}`,
        ]);
      } else if (
        item.EventType === NotificationType.RequestorChangeAfterReject ||
        item.EventType === NotificationType.RequestorChangeAfterOnboarded
      ) {
        this.router.navigate([`buyer/history/${item.Params.SupplierCode}/0`]);
      } else if (
        item.EventType === NotificationType.SupplierChangeAfterReject ||
        item.EventType === NotificationType.SupplierChangeAfterOnboarded
      ) {
        this.router.navigate([`buyer/history/${item.Params.SupplierCode}/1`]);
      } else if (item.EventType === NotificationType.SupplierDraftComplete) {
        this.router.navigate([`buyer/supplier/${item.Params.SupplierCode}`]);
      }
    } else {
      if (item.EventType === NotificationType.AP_SST_Reject) {
        this.router.navigate([
          `supplier/onboarding/process/${item.Params.SupplierCode}`,
        ]);
      } else {
        // this.router.navigate([`supplier/history/${item.Params.SupplierCode}`])
      }
    }
  }

  @HostListener("document:click", ["$event"])
  documentClick(evt) {
    if (this.notificationCenter) {
      if (this.notificationCenter.nativeElement.contains(evt.target)) {
        return;
      }
      this.notificationService.isShowCenter = true;
      return;
    }
    this.notificationService.isShowCenter = false;
  }

  closetable() {
    this.notificationService.isShowCenter = false;
  }

  sortByDate() {
    this.sortBy = !this.sortBy;
  }

  pageChange(event) {
    this.notificationService.pageCenter.cp = event;
    this.notificationService.loadDataCenter();
  }
}
