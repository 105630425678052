import { Component, OnInit } from "@angular/core";
import { SupplierService } from "src/app/services/supplier.service";
import { BuyerService } from "src/app/services/buyer.service";
import {
  ButtonCommands,
  DialogType,
  DialogService,
} from "src/app/services/dialog.service";
import { SupplierDeletedInfo } from "src/app/interfaces/SupplierDeletedInfo";
import { LanguageService } from "../../../../dynamic-components/utils/language.service";
import { environment } from "src/environments/environment";
@Component({
  selector: "app-delete-user-info",
  templateUrl: "./delete-user-info.component.html",
  styleUrls: ["./delete-user-info.component.sass"],
})
export class DeleteUserInfoComponent implements OnInit {
  isValidated = false;
  isDeleted = false;
  supplierDeletedInfo: SupplierDeletedInfo = {};
  supplierDeletedInfoList = [];
  constructor(
    private supplierService: SupplierService,
    private dialogService: DialogService,
    private languageService: LanguageService,
    private buyerService: BuyerService
  ) {}

  ngOnInit() {}
  validate() {
    if (!this.supplierDeletedInfo.SupplierCode) {
      this.isValidated = true;
      return false;
    }
    return true;
  }
  async search() {
    if (!this.validate()) {
      return;
    }
    const result: any = await this.supplierService.getSupplierInfo(
      this.supplierDeletedInfo.SupplierCode
    );
    this.supplierDeletedInfoList = [];
    if (result && result.data.length !== 0) {
      this.isDeleted = true;
      result.data.forEach((p) => {
        const supplierInfo = {
          SupplierCodeShow: p.supplierCode,
          POCEmail: p.supplierEmail,
          SupplierName: p.searchTerm.supplierName,
        };
        this.supplierDeletedInfoList.push(supplierInfo);
      });
    } else {
      this.isDeleted = false;
      if (environment.languageSwitch && environment.role == "supplier") {
        await this.dialogService.dialog(
          this.languageService.getValue(
            "static.system.components.delete-user-info.no_result"
          ),
          ButtonCommands.Close,
          DialogType.warning,
          this.languageService.getValue(
            "static.system.components.delete-user-info.fail"
          )
        );
      } else {
        await this.dialogService.dialog(
          "There is no result.",
          ButtonCommands.Close,
          DialogType.warning,
          "Fail"
        );
      }
    }
  }
  async delete() {
    const resultAction = await this.dialogService.deleteSupplierWarning();
    if (resultAction === "Yes") {
      this.isDeleted = false;
      if (!this.supplierDeletedInfoList.find((p) => !!p.POCEmail)) {
        if (environment.languageSwitch && environment.role == "supplier") {
          await this.dialogService.dialog(
            this.languageService.getValue(
              "static.system.components.delete-user-info.has_been_deleted"
            ),
            ButtonCommands.Close,
            DialogType.warning,
            this.languageService.getValue(
              "static.system.components.delete-user-info.success"
            )
          );
        } else {
          await this.dialogService.dialog(
            "UserInfo has been deleted.",
            ButtonCommands.Close,
            DialogType.warning,
            "Success"
          );
        }
        return;
      }
      const result: any = await this.buyerService.deleteUserInfo(
        this.supplierDeletedInfo.SupplierCode,
        this.supplierDeletedInfoList.find((p) => p.POCEmail).POCEmail
      );
      if (!result || !result.isSuccess) {
        this.isDeleted = true;
        if (result && result.error) {
          if (environment.languageSwitch && environment.role == "supplier") {
            switch (result.error.errorMessage) {
              case "Delete AAD failed":
                await this.dialogService.dialog(
                  this.languageService.getValue(
                    "static.system.components.delete-user-info.delete_add_failed"
                  ),
                  ButtonCommands.Close,
                  DialogType.warning,
                  this.languageService.getValue(
                    "static.system.components.delete-user-info.fail"
                  )
                );
                break;
              case "Delete S3Info failed":
                await this.dialogService.dialog(
                  this.languageService.getValue(
                    "static.system.components.delete-user-info.delete_s3_failed"
                  ),
                  ButtonCommands.Close,
                  DialogType.warning,
                  this.languageService.getValue(
                    "static.system.components.delete-user-info.fail"
                  )
                );
                break;
              case "Delete databaseInfo failed":
                await this.dialogService.dialog(
                  this.languageService.getValue(
                    "static.system.components.delete-user-info.delete_failed"
                  ),
                  ButtonCommands.Close,
                  DialogType.warning,
                  this.languageService.getValue(
                    "static.system.components.delete-user-info.fail"
                  )
                );
                break;
            }
          } else {
            switch (result.error.errorMessage) {
              case "Delete AAD failed":
                await this.dialogService.dialog(
                  "Delete AAD failed.",
                  ButtonCommands.Close,
                  DialogType.warning,
                  "Fail"
                );
                break;
              case "Delete S3Info failed":
                await this.dialogService.dialog(
                  "Delete S3Info failed.",
                  ButtonCommands.Close,
                  DialogType.warning,
                  "Fail"
                );
                break;
              case "Delete databaseInfo failed":
                await this.dialogService.dialog(
                  "Delete databaseInfo failed.",
                  ButtonCommands.Close,
                  DialogType.warning,
                  "Fail"
                );
                break;
            }
          }
        }
      } else {
        if (environment.languageSwitch && environment.role == "supplier") {
          await this.dialogService.dialog(
            this.languageService.getValue(
              "static.system.components.delete-user-info.delete_success"
            ),
            ButtonCommands.Close,
            DialogType.warning,
            this.languageService.getValue(
              "static.system.components.delete-user-info.success"
            )
          );
        } else {
          await this.dialogService.dialog(
            "delete UserInfo Success.",
            ButtonCommands.Close,
            DialogType.warning,
            "Success"
          );
        }
      }
    }
  }
}
