import { Injectable } from "@angular/core";
import { CustomExpression } from "../interfaces/Decorators";
import { ComponentConfig } from "../ComponentConfig";
import { DynamicContext } from "../interfaces/DynamicContext";
import { UntypedFormGroup, Validators } from "@angular/forms";
import { DynamicComponentService } from "../services/dynamic-component.service";
import { Datasources } from "../datasource/Datasources";
import { getDefaultValue } from "../utils/index";

export class ExpressionDecoratorComposition {
  constructor(private service: DynamicComponentService) { }

  @CustomExpression("setVisible")
  setVisibility(
    value: any,
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): void => {
      const fn = `return !(${params})`;
      const runtime = new Function(
        "config",
        "context",
        "$value",
        "$formGroup",
        fn
      );
      const result = runtime(config, context, value, formGroup);
      config.hide = result;
      const control = formGroup.get(config.name);
      if (!control) {
        return;
      }
      if (result) {
        const defaultValue = getDefaultValue(control.value, config.datatype);
        control.patchValue(defaultValue, { onlySelf: true, emitEvent: true });
        control.clearValidators();
        control.clearAsyncValidators();
        control.setErrors(null);
        control.updateValueAndValidity({ onlySelf: true, emitEvent: true });
        control.markAsPristine();
        control.markAsUntouched();
      } else {
        if (!control.value) {
          if (config.defaultValue && !control.value) {
            control.patchValue(config.defaultValue, {
              onlySelf: true,
              emitEvent: true,
            });
          } else if (control.dirty) {
            control.patchValue("", { onlySelf: true, emitEvent: true });
          }
        }
        control.setValidators(
          this.service.validatorFactory.createValidators(config, context)
        );
        control.setAsyncValidators(
          this.service.validatorFactory.createAsyncValidators(config, context)
        );
        control.setErrors(null);
        control.updateValueAndValidity({ onlySelf: true, emitEvent: true });
      }
    };
  }

  @CustomExpression("setBalanceSheetVisible")
  setBalanceSheetVisibility(
    value: any,
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): void => {
      const fn = `return !(${params})`;
      const runtime = new Function("config", "context", "$value", fn);
      const result = runtime(config, context, value);
      config.hide = result;
      const control = formGroup.get(config.name);
      if (!control) {
        return;
      }

      const LatestFirstYearNetIncome = formGroup.get(
        "LatestFirstYearNetIncome"
      ).value;
      const LatestSecondYearNetIncome = formGroup.get(
        "LatestSecondYearNetIncome"
      ).value;
      let firstCondition = false;
      let secondCondition = false;

      if (LatestFirstYearNetIncome) {
        firstCondition = Number(LatestFirstYearNetIncome) <= 0;
      }

      if (LatestSecondYearNetIncome) {
        secondCondition = Number(LatestSecondYearNetIncome) <= 0;
      }

      if (firstCondition || secondCondition) {
        config.hide = false;
      }

      if (result) {
        const defaultValue = getDefaultValue(control.value, config.datatype);
        control.patchValue(defaultValue, { onlySelf: true, emitEvent: true });
        control.clearValidators();
        control.clearAsyncValidators();
        control.setErrors(null);
        control.updateValueAndValidity({ onlySelf: true, emitEvent: true });
        control.markAsPristine();
        control.markAsUntouched();
      } else {
        if (!control.value) {
          if (config.defaultValue && !control.value) {
            control.patchValue(config.defaultValue, {
              onlySelf: true,
              emitEvent: true,
            });
          } else if (control.dirty) {
            control.patchValue("", { onlySelf: true, emitEvent: true });
          }
        }
        control.setValidators(
          this.service.validatorFactory.createValidators(config, context)
        );
        control.setAsyncValidators(
          this.service.validatorFactory.createAsyncValidators(config, context)
        );
        control.setErrors(null);
        control.updateValueAndValidity({ onlySelf: true, emitEvent: true });
      }
    };
  }

  @CustomExpression("setSMEVisible")
  setSMEVisible(
    value: any,
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): void => {
      const fn = `return !(${params})`
      let runtime = new Function('config', 'context', '$value', fn)
      const result = runtime(config, context, value)
      config.hide = result
      config.required = true
      const countryServed: string = context.getValue('parentModel').RequestForm.RequestorDetailsInfo.SupplierCountry
      if (countryServed.includes('IE')) {
        config.staticMessage =

          '<i class="material-icons waringMsg">warning</i>' + ' <br>' +
          'Micro company - The following requirements are fulfilled:<br>' +
          '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Average number of employees is less than 10<br>' +
          '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Annual turnover is not more than € 2 million OR Balance sheet total is not more than € 2 million<br>' +
          'Small company - The following requirements are fulfilled:<br>' +
          '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Average number of employees is less than 50<br>' +
          '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Annual turnover is not more than € 10 million OR Balance sheet total is not more than € 10 million<br>' +
          'Medium-sized company - The following requirements are fulfilled:<br>' +
          '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Average number of employees is less than 250<br>' +
          '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Annual turnover is not more than € 50 million OR Balance sheet total is not more than € 43 million<br>' +
          'Source: EU “SME Definition”: https://ec.europa.eu/growth/smes/sme-definition_en.'
      }
      // else if (countryServed.includes('GB')){
      //   config.staticMessage =
      //     '<i class="material-icons waringMsg">warning</i>' +
      //     " <br>" +
      //     "Micro company - The following requirements are fulfilled:<br>" +
      //     "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Average number of employees is less than 10<br>" +
      //     "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Annual turnover is not more than € 2 million OR Balance sheet total is not more than € 2 million<br>" +
      //     "Small company - The following requirements are fulfilled:<br>" +
      //     "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Average number of employees is less than 50<br>" +
      //     "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Annual turnover is not more than € 10 million OR Balance sheet total is not more than € 10 million<br>" +
      //     "Medium-sized company - The following requirements are fulfilled:<br>" +
      //     "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Average number of employees is less than 250<br>" +
      //     "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Annual turnover is not more than € 50 million OR Balance sheet total is not more than € 43 million<br>" +
      //     "Source: EU “SME Definition”: https://ec.europa.eu/growth/smes/sme-definition_en.";
      // } 
      else if (countryServed.includes("GB")) {
        config.staticMessage =
          '<i class="material-icons waringMsg">warning</i>' +
          " <br>" +
          '<div class="warningMsg">' +
          "Micro company - At least two out of the following three requirements need to be fulfilled:<br>" +
          "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Average number of employees is not more than 10<br>" +
          "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Annual turnover is not more than £632,000<br>" +
          "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Balance sheet total is not more than £316,000<br>" +
          "Small company - At least two out of the following three requirements need to be fulfilled:<br>" +
          "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Average number of employees is not more than 50<br>" +
          "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Annual turnover is not more than £10.2 million<br>" +
          "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Balance sheet total is not more than £5.1 million<br>" +
          "Medium-sized company - At least two out of the following three requirements need to be fulfilled: <br>" +
          "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Average number of employees is not more than 250<br>" +
          "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Annual turnover is not more than £36 million<br>" +
          "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Balance sheet total is not more than £18 million<br>" +
          "Source: UK Companies Act 2006." +
          "</div>";
      } else if (countryServed.includes("MU") || countryServed.includes("MA") || countryServed.includes("VN")) {
        config.staticMessage =
          '<i class="material-icons waringMsg">warning</i>' +
          " <br>" +
          '<div class="warningMsg">' +
          "Micro enterprise - Not more than 2 million<br>" +
          "Small enterprise - More than 2 million but not more than 10 million<br>" +
          "Medium enterprise - More than 10 million but not more than 50 million <br>"
        "</div>";
      }
      /*       else if (countryServed.includes('AU')){
        config.staticMessage =
                
        '<i class="material-icons waringMsg">warning</i>'+' <br>' +
        'Sole Proprietorship - <br>' +
        '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Individual/Sole proprietorship owned and run by one person<br>' +
        'Small Organisation - <br>' +
        '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Company with less than 50 employees, and with an annual turnover not exceeding 10 million USD<br>' +
        'Medium Organisation - <br>' +        
        '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Company with less than 250 employees, and with an annual turnover not exceeding 50 million USD<br>' +
        'Defined by the Business Council of Australia.'
      }else if (countryServed.includes('NZ')){
        config.staticMessage =
                
        '<i class="material-icons waringMsg">warning</i>'+' <br>' +
        'Sole Proprietorship - <br>' +
        '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Individual/Sole proprietorship owned and run by one person<br>' +
        'Small Organisation - <br>' +
        '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Company with less than 50 employees, and with an annual turnover not exceeding 10 million USD<br>' +
        'Medium Organisation - <br>' +        
        '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Company with less than 250 employees, and with an annual turnover not exceeding 50 million USD<br>' +
        'Defined by the Business Council of Australia.'
      } */
      const control = formGroup.get(config.name);
      if (!control) {
        return;
      }
      if (result) {
        const defaultValue = getDefaultValue(control.value, config.datatype);
        control.patchValue(defaultValue, { onlySelf: true, emitEvent: true });
        control.clearValidators();
        control.clearAsyncValidators();
        control.setErrors(null);
        control.updateValueAndValidity({ onlySelf: true, emitEvent: true });
        control.markAsPristine();
        control.markAsUntouched();
      } else {
        if (!control.value) {
          if (config.defaultValue && !control.value) {
            control.patchValue(config.defaultValue, {
              onlySelf: true,
              emitEvent: true,
            });
          } else if (control.dirty) {
            control.patchValue("", { onlySelf: true, emitEvent: true });
          }
        }
        control.setValidators(
          this.service.validatorFactory.createValidators(config, context)
        );
        control.setAsyncValidators(
          this.service.validatorFactory.createAsyncValidators(config, context)
        );
        control.setErrors(null);
        control.updateValueAndValidity({ onlySelf: true, emitEvent: true });
      }
    };
  }

  @CustomExpression("setValue")
  setValue(
    value: any,
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): void => {
      const runtime = new Function(
        "config",
        "context",
        "$value",
        `return (${params})`
      );
      const result = runtime(config, context, value);
      formGroup
        .get(config.name)
        .patchValue(result, { onlySelf: true, emitEvent: true });
    };
  }

  @CustomExpression("resetValue")
  resetValue(
    value: any,
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): void => {
      formGroup.get(config.name).reset();
    };
  }

  @CustomExpression("setRequired")
  setRequired(
    value: any,
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): void => {
      let runtime = new Function('config', 'context', '$value', '$formGroup', `return (${params})`)
      const result = runtime(config, context, value, formGroup)
      const control = formGroup.get(config.name)
      if (config.hide) {
        return;
      }
      if (result) {
        config.required = true;
        control.setValidators(
          this.service.validatorFactory.createValidators(config, context)
        );
        control.setErrors(null);
        control.updateValueAndValidity({ onlySelf: true, emitEvent: true });
      } else {
        // const val = getDefaultValue(control.value, config.datatype)
        // control.patchValue(val)
        config.required = false;
        control.setValidators(
          this.service.validatorFactory.createValidators(config, context)
        );
        control.setErrors(null);
        control.updateValueAndValidity({ onlySelf: true, emitEvent: true });
      }
    };
  }

  @CustomExpression("setConfig")
  setConfig(
    value: any,
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): void => {
      const fn = `return (${params})`;
      const runtime = new Function("config", "context", "$value", fn);
      runtime(config, context, value);
    };
  }

  @CustomExpression("loadDatasource")
  loadDatasource(
    value: any,
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): void => {
      Datasources.loadData(config, context, formGroup).then((ds) => {
        config.options = ds;
        context.emit(`DataSourceLoaded:${config.name}`, ds);
      });
    };
  }

  @CustomExpression("setDisable")
  setDisable(
    value: any,
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): void => {
      const fn = `return !(${params})`;
      const runtime = new Function("config", "context", "$value", fn);
      const result = runtime(config, context, value);
      if (result) {
        if (formGroup.get(config.name)) {
          formGroup
            .get(config.name)
            .disable({ onlySelf: true, emitEvent: false });
        }
      } else {
        if (formGroup.get(config.name)) {
          formGroup
            .get(config.name)
            .enable({ onlySelf: true, emitEvent: false });
        }
      }
    };
  }

  @CustomExpression("updateValueAndValidity")
  updateValueAndValidity(
    value: any,
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): void => {
      const control = formGroup.get(config.name);
      if (control) {
        control.updateValueAndValidity({ onlySelf: true, emitEvent: true });
      }
    };
  }
}
