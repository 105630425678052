import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  HostListener,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { Router } from "@angular/router";
import { getTSMCountryServedQuery, SideNavPath, SideNavType } from "../../../shared";
import { CommonService } from "src/app/services/common.service";
import { OnBoardingService } from "src/app/modules/buyer/services/onboarding.service";
import { environment } from "../../../../../environments/environment";
import { RebarAuthService } from "src/app/core/rebarauth/rebar.auth.service";
import {
  ProcessNames,
  SetupProcess,
  MigrateProcess,
  SupplierChangeRequestProcess,
  Task,
  AllTaskKey,
} from "src/app/interfaces/workflow";
import { ProfileService } from "src/app/modules/supplier/services/profile.service";
import { AuthService } from "src/app/services/auth/auth.service";
import { languageList } from "src/app/shared/components/layout/language/language-list"
import { LanguageService } from "src/app/dynamic-components/utils/language.service";
@Component({
  selector: "app-side-nav",
  templateUrl: "./side-nav.component.html",
  styleUrls: ["./side-nav.component.sass"],
})
export class SideNavComponent implements OnInit {
  @Input() isDisable = false;
  @Input() isMigrated = false;
  @Input() isHideDshbdHelp = false;
  @Input() userType: any = "";
  @Input() companyNb: any = "";
  @Input() onBoardingStatus = false;
  @Input() isFromTSM = false;
  @Input() TsmId: any = "";
  @Output() cancelEvent = new EventEmitter();
  @ViewChild("side_Nav") side_Nav: ElementRef;
  sideNavPath = SideNavPath;
  sideNavType = SideNavType;
  OnBoardingService: OnBoardingService;
  get side_navHeight() {
    return (
      document.getElementById("footerHeight").offsetTop == 0 ?
        window.innerHeight - document.getElementById("headerBar").offsetHeight + "px" :
        document.getElementById("footerHeight").offsetTop -
        document.getElementById("headerBar").offsetHeight + "px"
    );
  }
  constructor(
    private router: Router,
    private onBoardingService: OnBoardingService,
    private profileService: ProfileService,
    private authService: AuthService,
    private rebarAuthService: RebarAuthService,
    private languageService: LanguageService
  ) { }
  ngOnInit() { }

  backTo(value) {
    const type = this.sideNavType[value];
    const param = this.sideNavPath[this.userType + "_" + type];
    const action = Array.from(this.actions()).filter((item) =>
      new RegExp(item[0]).test(this.userType + "_" + type)
    );
    action[0][1].call(this, param);
    // this.actions().forEach((v, k) => {
    //   if (new RegExp(k).test(this.userType + '_' + type)) {
    //     if (param) { v.call(this, param) }
    //   }
    // }, this.actions())
  }
  actions() {
    const NavigateTo = (path) => {
      this.cancelEvent.emit();
      if (path) {
        this.router.navigate([path]);
      }
    };
    const NavigateTo_1 = (path) => {
      this.cancelEvent.emit();
      if (path && this.companyNb) {
        this.router.navigate([path + this.companyNb]);
      }
    };
    return new Map([
      [/^supplier_[14]$/, NavigateTo_1],
      [/^supplier_[2-3]$/, NavigateTo],
      [/^buyer_[1-3]$/, NavigateTo],
      [/^buyer_[4]$/, NavigateTo_1],
    ]);
  }
  cancel() {
    this.cancelEvent.emit();
  }
  turnto() {
    const buyerTsmId = this.TsmId;
    const url =
      " https://www.tsm-playground.com/#/supplier-profile/" + buyerTsmId;
    window.location.href = url;
  }

  turntoForTsmBuyer() {
    const buyerTsmId = this.TsmId;
    // const url = environment.relationship_url
    // if (this.onBoardingService.checkRequesterIsCompleteForTsm()) {
    //   window.location.href= url
    // } else {
    //   window.location.href= url + buyerTsmId
    // }
    if (this.onBoardingService.profileStage === "onboarding") {
      const env = sessionStorage.getItem("tsmEnv");
      const tsmUrl =
        env === "dev"
          ? environment.relationshipdev_url
          : env === "perf"
            ? environment.relationshipperf_url
            : env === "hotfix"
              ? environment.relationshiphotfix_url
              : env === "stage"
                ? environment.relationshipstage_url
                : env === "test"
                  ? environment.relationshiptest_url
                  : environment.relationship_url;
      const countryServed = getTSMCountryServedQuery(
        this.onBoardingService.supplierModel.Mapping.setUpCountry,
      );
      const redirectUrl =
        tsmUrl +
        buyerTsmId +
        "?countryServed=" +
        countryServed +
        "&login_hint=" +
        this.authService.passport.buyer.Email;
      console.debug(`Jump to TSM url: ${redirectUrl}`);
      window.location.href = redirectUrl;
    } else {
      // window.location.href= environment.landing_url
      const env = sessionStorage.getItem("tsmEnv");
      const url =
        env === "dev"
          ? environment.landingdev_url
          : env === "perf"
            ? environment.landingperf_url
            : env === "hotfix"
              ? environment.landinghotfix_url
              : env === "stage"
                ? environment.landingstage_url
                : env === "test"
                  ? environment.landingtest_url
                  : environment.landing_url;
      window.location.href =
        url + "?login_hint=" + this.authService.passport.buyer.Email;
    }
  }

  turntoForTsmSupplier() {
    const localstorageLang = localStorage.getItem("language")
    const language = languageList.find((item) => item.key === localstorageLang).code;
    const buyerTsmId = this.TsmId;
    // const url = environment.relationship_url
    // window.location.href= url + buyerTsmId
    if (this.profileService.isViewSupplier) {
      // window.location.href= environment.landing_url
      const env = sessionStorage.getItem("tsmEnv");
      const url =
        env === "dev"
          ? environment.landingdev_url
          : env === "perf"
            ? environment.landingperf_url
            : env === "hotfix"
              ? environment.landinghotfix_url
              : env === "stage"
                ? environment.landingstage_url
                : env === "test"
                  ? environment.landingtest_url
                  : environment.landing_url;
      window.location.href =
        url +
        "?login_hint=" +
        this.authService.passport.supplier.Mapping.supplierEmail;
    } else {
      const env = sessionStorage.getItem("tsmEnv");
      const tsmUrl =
        env === "dev"
          ? environment.relationshipdev_url
          : env === "perf"
            ? environment.relationshipperf_url
            : env === "hotfix"
              ? environment.relationshiphotfix_url
              : env === "stage"
                ? environment.relationshipstage_url
                : env === "test"
                  ? environment.relationshiptest_url
                  : environment.relationship_url;
      const countryServed = getTSMCountryServedQuery(
        this.profileService.supplierModel.Mapping.setUpCountry,
      );
      const redirectUrl =
        tsmUrl +
        buyerTsmId +
        "?countryServed=" +
        countryServed +
        "&login_hint=" +
        this.authService.passport.supplier.Mapping.supplierEmail +
        "&language=" +
        language;
      console.debug(`Jump to TSM url: ${redirectUrl}`);
      window.location.href = redirectUrl;
    }
  }
  @HostListener("document: click", ["$event"])
  documentClick(evt) {
    if (this.side_Nav && this.side_Nav.nativeElement.contains(event.target)) {
      return;
    }
    const backTo = this.languageService.getValue(
      "static.supplier.header.back_to"
    );
    if (
      evt.target.lastChild &&
      evt.target.lastChild.data &&
      evt.target.lastChild.data.trim().includes(backTo)
    ) {
      return;
    }
    this.cancel();
  }

  translateLabel(label) {
    if (this.userType === "supplier") {
      switch (label) {
        case "Back to ...":
          return this.languageService.getValue(
            "static.supplier.side-nav.backTo"
          );
        case "Onboarding Process":
          return this.languageService.getValue(
            "static.supplier.side-nav.onboardingProcess"
          );
        case "My Profile":
          return this.languageService.getValue(
            "static.supplier.side-nav.myProfile"
          );
        case "Transactions":
          return this.languageService.getValue(
            "static.supplier.side-nav.transactions"
          );
        case "Help & Support":
          return this.languageService.getValue(
            "static.supplier.side-nav.helpSupport"
          );
        case "TSM Relationship Profile":
          return this.languageService.getValue(
            "static.supplier.side-nav.TSMRelationshipProfile"
          );
        default:
          break;
      }
    } else {
      return label;
    }
  }
}
