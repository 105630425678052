import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ProfileService } from "src/app/modules/supplier/services/profile.service";
import { SupplierService } from "src/app/services/supplier.service";
import { AuthService } from "src/app/services/auth/auth.service";
import { SupplierHeaderService } from "../../../services/header.service";
import { environment } from "../../../../../../environments/environment";
import { getTSMCountryServedQuery } from "../../../../../shared/shared";
import { LoadingService } from "../../../../../services/loading.service";

@Component({
  selector: "app-encrypted",
  templateUrl: "./encrypted.component.html",
  styleUrls: ["./encrypted.component.sass"],
})
export class EncryptedComponent implements OnInit {
  showTooltip: boolean;
  publicInfo: any = {};
  completedTime: any;
  tsmId: any;
  profileService: ProfileService;
  SAVisible = true;

  constructor(
    private router: Router,
    private authService: AuthService,
    private _profileService: ProfileService,
    private supplierHeaderService: SupplierHeaderService,
    private supplierService: SupplierService,
    private loadingService: LoadingService
  ) {
    this.profileService = _profileService;
    this.profileService.stepChangeEvent.emit(6);
  }

  async ngOnInit() {
    this.tsmId = this.profileService.supplierModel.Mapping.TsmId;
    this.profileService.current_step = 6;
    // load current login user's task
    await this.profileService.loadUserTasks();
    this.publicInfo =
      this.profileService.supplierModel.SupplierProfile.SupplierGeneral;
    this.completedTime =
      this.profileService.supplierModel.Mapping.profile.completedTime;
    // add SA status judgement for display SA button and introduction or not
    this.loadingService.showLoading();
    const result: any = await this.supplierService.verifyTSMSAStatus(
      this.profileService.supplierCode,
      this.profileService.supplierModel.Mapping.TsmId,
      this.profileService.supplierModel.Mapping.setUpCountry
    );
    this.SAVisible = !result.data;
    this.loadingService.hideLoading();
    // SA status judgement end
  }

  navigate1() {
    this.profileService.mode = "view";
    this.profileService.isViewSupplier = true;
    this.router.navigate([
      `supplier/onboarding/process/${this.profileService.supplierModel.Mapping.supplierCode}`,
    ]);
  }

  navigate() {
    this.profileService.mode = "view";
    this.supplierHeaderService.title = this.profileService.headerTitle;
    this.profileService.isViewSupplier = true;
    this.router.navigate([
      `supplier/profile/${this.profileService.supplierModel.Mapping.supplierCode}/step1`,
    ]);
  }

  navigateComplete() {
    const supplierTsmId = this.profileService.supplierModel.Mapping.TsmId;
    const countryServed = getTSMCountryServedQuery(
      this.profileService.supplierModel.Mapping.setUpCountry,
      false
    );
    const supplierName =
      this.profileService.supplierModel.RequestForm.RequestorDetailsInfo
        .SupplierCompanyName;
    const env = sessionStorage.getItem("tsmEnv");
    const url =
      env === "dev"
        ? environment.landingdev_url
        : env === "perf"
        ? environment.landingperf_url
        : env === "hotfix"
        ? environment.landinghotfix_url
        : env === "stage"
        ? environment.landingstage_url
        : env === "test"
        ? environment.landingtest_url
        : environment.landing_url;
    const redirectUrl =
      url +
      "supplierhub2esg" +
      "?supplierTsmId=" +
      supplierTsmId +
      "&countryServed=" +
      countryServed +
      "&supplierName=" +
      supplierName +
      "&login_hint=" +
      this.authService.passport.supplier.Mapping.supplierEmail;
    console.debug(`Jump to TSM url: ${redirectUrl}`);
    window.location.href = redirectUrl;
    // window.location.href= url + '?login_hint=' + this.authService.passport.supplier.Mapping.supplierEmail
    // this.router.navigate([`window.location.href`])
  }

  navigateOnboarding() {
    this.profileService.mode = "view";
    this.profileService.isViewSupplier = true;
    this.router.navigate([
      `supplier/onboarding/process/${this.profileService.supplierModel.Mapping.supplierCode}`,
    ]);
  }

  navigateTSM() {
    this.profileService.mode = "view";
    this.profileService.isViewSupplier = true;
    const env = sessionStorage.getItem("tsmEnv");
    const url =
      env === "dev"
        ? environment.landingdev_url
        : env === "perf"
        ? environment.landingperf_url
        : env === "hotfix"
        ? environment.landinghotfix_url
        : env === "stage"
        ? environment.landingstage_url
        : env === "test"
        ? environment.landingtest_url
        : environment.landing_url;
    window.location.href =
      url +
      "?login_hint=" +
      this.authService.passport.supplier.Mapping.supplierEmail;
    // this.router.navigate([`window.location.href`])
  }

  estimateChooseOver(e, estimateSpendTooltip) {
    const TooltipEle = estimateSpendTooltip;
    if (estimateSpendTooltip) {
      if (
        e.clientX < TooltipEle.nativeElement.getBoundingClientRect().left ||
        e.clientX >
          TooltipEle.nativeElement.getBoundingClientRect().left +
            TooltipEle.nativeElement.offsetWidth -
            5 ||
        e.clientY < TooltipEle.nativeElement.getBoundingClientRect().top ||
        e.clientY >
          TooltipEle.nativeElement.getBoundingClientRect().top +
            TooltipEle.nativeElement.offsetHeight -
            5
      ) {
        this.showTooltip = false;
      }
    } else {
      this.showTooltip = false;
    }
  }
}
