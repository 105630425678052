import { Component, OnInit } from "@angular/core";
import { BaseComponent } from "../base/base.component";

@Component({
  selector: "app-hidden",
  template: `
    <div [formGroup]="formGroup">
      <input type="hidden" [formControlName]="config.name" />
    </div>
  `,
})
export class HiddenComponent extends BaseComponent implements OnInit {
  constructor() {
    super();
  }
}
