export enum WsTypeEnum {
  NOTIFICATION_UPDATE = "NotificationEvent",
}

export interface Pager<T> {
  cp: number;
  ps: number;
  totalCount: number;
  totalPage: number;
  data: Array<T>;
  hasNextPage: boolean;
  hasPrevPage: boolean;
}

export interface Notification {
  ID?: number;
  EventType?: string;
  CreatedBy?: string;
  CreatedDate?: Date;
  CreatedDateStr?: string;
  daysBefore?: string;
  Params?: any;
  Status?: number;
  UserType?: string;
  UserID?: number;
  Message?: string;
}

export function createNotification(status: any) {
  const model: Notification = {
    ID: 0,
    EventType: "",
    CreatedBy: "",
    CreatedDate: null,
    Params: {},
    Status: 0,
    UserType: "",
    UserID: 0,
    Message: "",
  };
  return model;
}

export function createPager(): Pager<Notification> {
  const model: Pager<Notification> = {
    cp: 1,
    ps: 10,
    totalCount: 0,
    totalPage: 0,
    data: [],
    hasNextPage: false,
    hasPrevPage: false,
  };
  return model;
}

export enum NotificationType {
  RequestorFormChange = "RequestorFormChange",
  SupplierFormChange = "SupplierFormChange",
  SupplierDraftComplete = "SupplierDraftComplete",
  AP_SST_ReviewApproved = "AP_SST_ReviewApproved",
  AP_QC_ReviewReject = "AP_QC_ReviewReject",
  RequestorChangeAfterReject = "RequestorChangeAfterReject",
  SupplierChangeAfterReject = "SupplierChangeAfterReject",
  RequestorChangeAfterOnboarded = "RequestorChangeAfterOnboarded",
  SupplierChangeAfterOnboarded = "SupplierChangeAfterOnboarded",
  AP_SST_Reject = "AP_SST_Reject",
}
