import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { LanguageService } from "../../dynamic-components/utils/language.service";
import { environment } from "src/environments/environment";

@Pipe({ name: "langItem" })
export class LangItemPipe implements PipeTransform {
  constructor(private languageService: LanguageService) {}

  transform(value) {
    if (value.languageKey && value.Text) {
      return environment.languageSwitch
        ? this.languageService.getValue(value.languageKey)
        : value.Text;
    } else {
      return value.Text;
    }
  }
}
