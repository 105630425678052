import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from "@angular/router";
import { Injectable, Inject, VERSION } from "@angular/core";
import { Location } from "@angular/common";
import {
  InteractionType,
  BrowserConfigurationAuthError,
  BrowserUtils,
  UrlString,
  PopupRequest,
  RedirectRequest,
  AuthenticationResult,
} from "@azure/msal-browser";
import {
  concatMap,
  catchError,
  map,
  switchMap,
  mergeMap,
} from "rxjs/operators";
import { Observable, of } from "rxjs";
import {
  MsalBroadcastService,
  MsalService,
  MSAL_GUARD_CONFIG,
  MsalGuardConfiguration,
} from "@azure/msal-angular";
import { HttpClient } from "@angular/common/http";
import { DictionaryService } from "./services/dictionary.service";
import { AuthService, LoginStatus } from "./services/auth/auth.service";
import Passport from "./services/auth/passport";
import { Profile } from "./interfaces/profile";

@Injectable()
export class AppGuard {
  private loginFailedRoute?: UrlTree;

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalBroadcastService: MsalBroadcastService,
    private msalService: MsalService,
    private location: Location,
    private authService: AuthService,
    private dictionaryService: DictionaryService,
    private router: Router
  ) {
    // Subscribing so events in MsalGuard will set inProgress$ observable
    // this.msalBroadcastService.inProgress$.subscribe();
  }

  /**
   * Parses url string to UrlTree
   * @param url
   */
  parseUrl(url: string): UrlTree {
    return this.router.parseUrl(url);
  }

  /**
   * Builds the absolute url for the destination page
   * @param path Relative path of requested page
   * @returns Full destination url
   */
  getDestinationUrl(path: string): string {
    this.msalService.getLogger().verbose("Guard - getting destination url");
    // Absolute base url for the application (default to origin if base element not present)
    const baseElements = document.getElementsByTagName("base");
    const baseUrl = this.location.normalize(
      baseElements.length ? baseElements[0].href : window.location.origin
    );

    // Path of page (including hash, if using hash routing)
    const pathUrl = this.location.prepareExternalUrl(path);

    // Hash location strategy
    if (pathUrl.startsWith("#")) {
      this.msalService
        .getLogger()
        .verbose("Guard - destination by hash routing");
      return `${baseUrl}/${pathUrl}`;
    }

    /*
     * If using path location strategy, pathUrl will include the relative portion of the base path (e.g. /base/page).
     * Since baseUrl also includes /base, can just concatentate baseUrl + path
     */
    return `${baseUrl}${path}`;
  }

  /**
   * Interactively prompt the user to login
   * @param url Path of the requested page
   */
  private loginInteractively(state: RouterStateSnapshot): Observable<boolean> {
    const authRequest =
      typeof this.msalGuardConfig.authRequest === "function"
        ? this.msalGuardConfig.authRequest(this.msalService, state)
        : { ...this.msalGuardConfig.authRequest };
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      this.msalService.getLogger().verbose("Guard - logging in by popup");
      return this.msalService.loginPopup(authRequest as PopupRequest).pipe(
        map((response: AuthenticationResult) => {
          this.msalService
            .getLogger()
            .verbose(
              "Guard - login by popup successful, can activate, setting active account"
            );
          this.msalService.instance.setActiveAccount(response.account);
          return true;
        })
      );
    }

    this.msalService.getLogger().verbose("Guard - logging in by redirect");
    const redirectStartPage = this.getDestinationUrl(state.url);
    return this.msalService
      .loginRedirect({
        redirectStartPage,
        ...authRequest,
      } as RedirectRequest)
      .pipe(map(() => false));
  }

  /**
   * Helper which checks for the correct interaction type, prevents page with Guard to be set as reidrect, and calls handleRedirectObservable
   * @param state
   */
  private activateHelper(
    state?: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    if (
      this.msalGuardConfig.interactionType !== InteractionType.Popup &&
      this.msalGuardConfig.interactionType !== InteractionType.Redirect
    ) {
      throw new BrowserConfigurationAuthError(
        "invalid_interaction_type",
        "Invalid interaction type provided to MSAL Guard. InteractionType.Popup or InteractionType.Redirect must be provided in the MsalGuardConfiguration"
      );
    }
    this.msalService.getLogger().verbose("MSAL Guard activated");

    /*
     * If a page with MSAL Guard is set as the redirect for acquireTokenSilent,
     * short-circuit to prevent redirecting or popups.
     */
    if (typeof window !== "undefined") {
      if (
        UrlString.hashContainsKnownProperties(window.location.hash) &&
        BrowserUtils.isInIframe() &&
        !this.msalService.instance.getConfiguration().system
          .allowRedirectInIframe
      ) {
        this.msalService
          .getLogger()
          .warning(
            "Guard - redirectUri set to page with MSAL Guard. It is recommended to not set redirectUri to a page that requires authentication."
          );
        return of(false);
      }
    } else {
      this.msalService
        .getLogger()
        .info(
          "Guard - window is undefined, MSAL does not support server-side token acquisition"
        );
      return of(true);
    }

    /**
     * If a loginFailedRoute is set in the config, set this as the loginFailedRoute
     */
    if (this.msalGuardConfig.loginFailedRoute) {
      this.loginFailedRoute = this.parseUrl(
        this.msalGuardConfig.loginFailedRoute
      );
    }

    // Capture current path before it gets changed by handleRedirectObservable
    const currentPath = this.location.path(true);

    return this.msalService.handleRedirectObservable().pipe(
      concatMap(() => {
        if (!this.msalService.instance.getAllAccounts().length) {
          if (state) {
            this.msalService
              .getLogger()
              .verbose(
                "Guard - no accounts retrieved, log in required to activate"
              );
            return this.loginInteractively(state);
          }
          this.msalService
            .getLogger()
            .verbose("Guard - no accounts retrieved, no state, cannot load");
          return of(false);
        }

        this.msalService
          .getLogger()
          .verbose("Guard - at least 1 account exists, can activate or load");

        // Prevent navigating the app to /#code= or /code=
        if (state) {
          /*
           * Path routing:
           * state.url: /#code=...
           * state.root.fragment: code=...
           */

          /*
           * Hash routing:
           * state.url: /code
           * state.root.fragment: null
           */
          const urlContainsCode: boolean = this.includesCode(state.url);
          const fragmentContainsCode: boolean =
            !!state.root &&
            !!state.root.fragment &&
            this.includesCode(`#${state.root.fragment}`);
          const hashRouting: boolean =
            this.location.prepareExternalUrl(state.url).indexOf("#") === 0;

          // Ensure code parameter is in fragment (and not in query parameter), or that hash hash routing is used
          if (urlContainsCode && (fragmentContainsCode || hashRouting)) {
            this.msalService
              .getLogger()
              .info(
                "Guard - Hash contains known code response, stopping navigation."
              );

            // Path routing (navigate to current path without hash)
            if (currentPath.indexOf("#") > -1) {
              return of(this.parseUrl(this.location.path()));
            }

            // Hash routing (navigate to root path)
            return of(this.parseUrl(""));
          }
        }

        return of(true);
      }),
      catchError((error: Error) => {
        this.msalService
          .getLogger()
          .error("Guard - error while logging in, unable to activate");
        this.msalService
          .getLogger()
          .errorPii(`Guard - error: ${error.message}`);
        /**
         * If a loginFailedRoute is set, checks to see if Angular 10+ is used and state is passed in before returning route
         * Apps using Angular 9 will receive of(false) in canLoad interface, as it does not support UrlTree return types
         */
        if (this.loginFailedRoute && parseInt(VERSION.major, 10) > 9 && state) {
          this.msalService
            .getLogger()
            .verbose("Guard - loginFailedRoute set, redirecting");
          return of(this.loginFailedRoute);
        }
        return of(false);
      })
    );
  }

  includesCode(path: string): boolean {
    return (
      (path.lastIndexOf("/code") > -1 &&
        path.lastIndexOf("/code") === path.length - "/code".length) || // path.endsWith("/code")
      path.indexOf("#code=") > -1 ||
      path.indexOf("&code=") > -1
    );
  }

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean | UrlTree> {
    console.log("-------- entry app guard --------");
    const result = await this.activateHelper(state).toPromise();
    if (result) {
      const currentUser = this.msalService.instance.getActiveAccount();
      
      if (currentUser) {
        // initialize current user passport
        const roles: Array<string> = (currentUser.idTokenClaims as any).roles

        let userRole = "Default_Access"
        if(roles && roles.length > 0) {
          let specialRole = roles.filter(
            (role) => role !== "Default_Access" && role !== "User"
          )[0]
  
          userRole = specialRole ? specialRole : "Default_Access"
        }

        // console.log("-----app guard-----userRole: ", userRole)

        if (!this.authService.passport) {
          const profile: Profile = {
            email: currentUser.username || (currentUser.idTokenClaims as any).email,
            oid: (currentUser.idTokenClaims as any).oid,
            name: currentUser.name,
            sub: (currentUser.idTokenClaims as any).sub,
            unique_name: (currentUser.idTokenClaims as any).preferred_username,
            nonce: (currentUser.idTokenClaims as any).once,
            role: userRole
          };

          this.authService.passport = new Passport(profile);
          // this.authService.loginStatus = LoginStatus.Success
        }

        // load dictionary
        await this.dictionaryService.loadAllDictionary();
        console.log("-------- exit app guard --------");
      }
    }

    return result;
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    this.msalService.getLogger().verbose("Guard - canActivateChild");
    return this.activateHelper(state);
  }

  canLoad(): Observable<boolean> {
    this.msalService.getLogger().verbose("Guard - canLoad");
    // @ts-ignore
    return this.activateHelper();
  }
}
