import { Formator } from "./Formator";
import * as moment from "moment";

export class DateFormator implements Formator {
  // TODO add formgroup?
  constructor(private pattern: string) {}

  format(v) {
    return moment(new Date(v)).format(this.pattern);
  }
}
