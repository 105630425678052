import {
  Component,
  OnInit,
  HostListener,
  ElementRef,
  ViewChild,
} from "@angular/core";
import { Router } from "@angular/router";
import { BuyerACLPermissionService } from "src/app/modules/buyer/services/buyerACLPermission.service";
import { environment } from "src/environments/environment";
import { BuyerRoleACLButton } from "src/app/interfaces/buyer";
import { SupplierService } from "src/app/services/supplier.service";
import { RebarAuthService } from "src/app/core/rebarauth/rebar.auth.service";
import { AuthService } from "src/app/services/auth/auth.service";
import { AppConfigService } from "src/app/core/services/app-config.service";

enum POLICY_TYPE {
  CHANGE_PASSORD = "change_passord",
  UPDATE_MFA = "edit_phone_mfa",
}

@Component({
  selector: "app-user-menu",
  templateUrl: "./user-menu.component.html",
  styleUrls: ["./user-menu.component.sass"],
})
export class UserMenuComponent implements OnInit {
  @ViewChild("menuDrop") menuPanel: ElementRef;
  @ViewChild("menuButton") menuButton: ElementRef;
  showPanel = false;
  showB2CLink = false;

  get role() {
    if (this.auth.passport.buyer) {
      const role: any = this.auth.passport.buyerRoles.find(
        (r: any) => r.RoleCode === this.auth.passport.buyer.RoleCode
      );
      return role.RoleName;
    }
  }

  get name() {
    return this.auth.passport.profile.name;
  }

  get isSupplier() {
    if (environment.role === "supplier") {
      return true;
    }
    return false;
  }

  get isBuyer() {
    if (environment.role === "buyer") {
      return true;
    }
    return false;
  }

  get isLogin() {
    return this.rebarService.isUserAuthenticated();
  }

  constructor(
    private router: Router,
    private supplierService: SupplierService,
    private buyerACLPermissionService: BuyerACLPermissionService,
    private rebarService: RebarAuthService,
    private auth: AuthService,
    private configService: AppConfigService
  ) { }

  ngOnInit() {
    this.showB2CExtraLink();
  }

  @HostListener("document:keydown", ["$event"])
  documentClick(evt) {
    if (this.menuPanel && evt.keyCode !== 13) {
      if (this.menuButton.nativeElement.contains(event.target)) {
        return;
      }
      this.showPanel = false;
    } 
    if(evt.keyCode == 13 && evt.srcElement.nodeName === 'APP-USER-MENU') {
      this.showPanel = !this.showPanel;
    }
  }

  showDropdown() {
    if (this.isLogin) {
      this.showPanel = !this.showPanel;
    }
  }

  get showManagementButton() {
    if (this.isBuyer) {
      // if (
      //   this.buyerACLPermissionService.buttonPermissions !== undefined &&
      //   this.buyerACLPermissionService.buttonPermissions.length > 0
      // ) {
      //   const migrateInvitationIndex =
      //     this.buyerACLPermissionService.buttonPermissions.indexOf(
      //       BuyerRoleACLButton.UserManagementPageButton
      //     );
      //   return migrateInvitationIndex === -1 ? false : true;
      // }
      return false
    }

    if (this.isSupplier) {
      if (this.auth.passport.supplierMember !== undefined) {
        if (this.auth.passport.supplierMember.RoleCode === "supplierAdmin") {
          return true;
        }
      }
      return false;
    }
  }

  manageUsers() {
    if (this.isBuyer) {
      this.router.navigate(["/buyer/management/users"]);
    }
    if (this.isSupplier) {
      this.router.navigate(["/supplier/management/users"]);
    }
  }

  updateSupplierPhoneMFA() {
    this.rebarService.loginWithCustomizeFlow({
      authority: this.configService.config["msal"]["supplier"]["auth_b2c"].authorities.edit_phone_mfa.authority
    })
  }

  changeSupplierPassword() {
    this.rebarService.loginWithCustomizeFlow({
      authority: this.configService.config["msal"]["supplier"]["auth_b2c"].authorities.change_password.authority
    })
  }

  async showB2CExtraLink() {
    if (this.isSupplier) {
      if(environment.env !== "dev" && environment.env !== "perf") {
      const result: any = await this.supplierService.getSupplierB2CFlag(
        this.auth.passport.profile.email
      );
      this.showB2CLink = result.data;
    }
  }
    this.showB2CLink =
      this.isSupplier && environment.env !== "dev" && environment.env !== "perf"
        ? this.showB2CLink
        : false;
  }


  editProfile() {
    this.router.navigate(["/buyer/management/edit-profile"]);
  }

  async logout() { 
    this.rebarService.logout()
  }
}
