import { Component, OnInit } from "@angular/core";
import { SupplierService } from "src/app/services/supplier.service";
import { Router } from "@angular/router";
import {
  ButtonCommands,
  DialogType,
  DialogService,
} from "src/app/services/dialog.service";
import { SupplierInvitationStatusModel } from "src/app/interfaces/SupplierInvitationStatusModel";
import {
  InvitedStatusMapping,
  SupplierMappingDTO,
  SupplierStatus,
} from "src/app/interfaces/mapping";
import { LanguageService } from "../../../../dynamic-components/utils/language.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-invitation-status",
  templateUrl: "./invitationStatus.component.html",
  styleUrls: ["./invitationStatus.component.sass"],
})
export class InvitationStatusComponent implements OnInit {
  isValidated = false;
  isDisabled = true;
  invitedIsChange = false;
  mapping: SupplierMappingDTO = {};
  isInvitedStatus: SupplierInvitationStatusModel = {};
  tooltipText = 'Change status functionality is only for the migrated supplier here you can change the migration & Inactive supplier into a migrated & active supplier and Migrated & active supplier into and Migrated and Inactive supplier';
  isTooltipVisible = false;
  showTooltip() {
    this.isTooltipVisible = true;
  }
  hideTooltip() {
    this.isTooltipVisible = false;
  }

  constructor(
    private supplierService: SupplierService,
    private dialogService: DialogService,
    private languageService: LanguageService,
    private router: Router
  ) {}

  ngOnInit() {}

  validate() {
    this.isValidated = true;
    if (!this.isInvitedStatus.SupplierCode) {
      return false;
    }
    return true;
  }

  async search() {
    if (!this.validate()) {
      return;
    }
    const result: any = await this.supplierService.queryIsInvited(
      this.isInvitedStatus.SupplierCode
    );
    this.isInvitedStatus.SupplierName = "";
    this.isInvitedStatus.IsInvited = false;
    this.isInvitedStatus.SupplierCodeShow = "";
    if (result && result.data) {
      this.mapping = result.data;
      this.isInvitedStatus.SupplierName = this.mapping.searchTerm.supplierName;
      this.isInvitedStatus.IsInvited = this.mapping.IsInvited;
      this.isInvitedStatus.SupplierCodeShow = this.mapping.supplierCode;
      this.isDisabled = false;
      this.invitedIsChange = true;
    } else {
      this.invitedIsChange = false;
      if (environment.languageSwitch && environment.role == "supplier") {
        await this.dialogService.dialog(
          this.languageService.getValue(
            "static.system.components.invitationStatus.no_result"
          ),
          ButtonCommands.Close,
          DialogType.warning,
          this.languageService.getValue(
            "static.system.components.invitationStatus.fail"
          )
        );
      } else {
        await this.dialogService.dialog(
          "There is no result.",
          ButtonCommands.Close,
          DialogType.warning,
          "Fail"
        );
      }
    }
  }

  async change() {
    if (!this.invitedIsChange) {
      return;
    }
    if (this.mapping.status === SupplierStatus.terminated) {
      const res = await this.dialogService.InvitationStatusChange();
      if (res === "cancel") {
        return;
      }
    }
    if (this.mapping.InvitedStatus === InvitedStatusMapping.accepted) {
      const message = this.languageService.getValue(
        "static.system.components.invitationStatus.validation_fail"
      );
      await this.dialogService.dialog(
        message,
        ButtonCommands.Close,
        DialogType.warning,
        "Fail"
      );
      return;
    }
    this.mapping.IsInvited = false;
    this.mapping.InvitedDate = "";
    this.mapping.InvitedStatus = "New";
    const result: any = await this.supplierService.changeIsInvited(
      this.mapping
    );
    if (result.isSuccess) {
      if (environment.languageSwitch && environment.role == "supplier") {
        await this.dialogService.dialog(
          this.languageService.getValue(
            "static.system.components.invitationStatus.edit_success"
          ),
          ButtonCommands.Close,
          DialogType.success,
          this.languageService.getValue(
            "static.system.components.delete-user-info.success"
          )
        );
      } else {
        await this.dialogService.dialog(
          "You have edited successfully.",
          ButtonCommands.Close,
          DialogType.success,
          "Success"
        );
      }
      this.isInvitedStatus = {};
      this.router.navigate(["buyer"]);
    } else {
      if (environment.languageSwitch && environment.role == "supplier") {
        await this.dialogService.dialog(
          this.languageService.getValue(
            "static.system.components.invitationStatus.modify_fail"
          ),
          ButtonCommands.Close,
          DialogType.warning,
          this.languageService.getValue(
            "static.system.components.invitationStatus.fail"
          )
        );
      } else {
        await this.dialogService.dialog(
          "Field modification failed, please try again.",
          ButtonCommands.Close,
          DialogType.warning,
          "Fail"
        );
      }
    }
  }
}
