// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.file-upload {
  border: dashed 1px rgba(31, 31, 53, 0.3);
  height: 4rem;
}

.nv-file-over {
  border: dashed 1px red;
}

.uploader {
  border-radius: 0.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  display: -webkit-box;
  -webkit-box-orient: horizontal;
  -webkit-box-pack: center;
  -webkit-box-align: center;
  cursor: pointer;
}

.file-img {
  width: 20px;
  height: 25px;
  float: left;
}

.progress {
  width: 8%;
  margin: auto 0;
  overflow: hidden;
  border-radius: 4px;
  text-align: -webkit-center;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}

.progress-bar {
  float: left;
  width: 0;
  height: 100%;
  font-size: 12px;
  color: #fff;
  text-align: center;
  background-color: #337ab7;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  transition: width 10s ease;
}

.file-message-con {
  display: flex;
  align-items: center;
  text-align: -webkit-center;
  color: #4e4e87;
}

.file-message {
  padding-left: 10px;
}

.material-icons {
  color: #4e4e87;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
