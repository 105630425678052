// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  position: fixed;
  left: 200px;
  right: 200px;
  top: 135px;
  background: #fff;
  z-index: 999;
  padding: 0px;
  background: #f0f0f0;
  font-size: 13px;
}
.container .ntfTitle {
  height: 50px;
  background: white;
  padding-top: 20px;
  font-weight: 600;
}
.container .ntfTitle .hover_pointer {
  float: right;
  font-size: 16px;
  font-weight: 900;
}
.container .tbHeader {
  height: 40px;
  line-height: 50px;
  text-align: left;
  font-weight: 700;
}
.container .tContent {
  margin-top: 4px;
  height: 60px;
  text-align: left;
  background: white;
  padding-top: 20px;
}
.container .tContent .ntfDate {
  color: #46465B;
}
.container .tContent .ntfEvent {
  color: #46465B;
}
.container .tContent .ntfMsg {
  color: black;
  font-weight: 800;
}
.container .tContent .ntfAction {
  color: #0F5284;
  font-weight: 600;
}
.container .tContent .ntfAction .btnAction {
  cursor: pointer;
}
.container .emptyContents {
  height: 200px;
  background: white;
  text-align: center;
  padding-top: 70px;
  font-size: 15px;
}

.close-table {
  position: relative;
  left: 970px;
  bottom: 22px;
}

.my-pagination {
  margin-top: 30px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
