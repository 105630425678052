import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

@Pipe({ name: "securityTrustHtml" })
export class SecurityTrustHtmlPipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) {}

  transform(value) {
    if (value) {
      return this.sanitized.bypassSecurityTrustHtml(value);
    }
    return value;
  }
}
