export const popData = {
    title: "Inactivity Detected!",
    iconUrl: "assets/images/icon-conectivity.svg",
    content: `<p>Looks like you're not using this application anymore and your session is about to expire.</p>
    <p>Click the "End" button below to terminate the session and automatically log-out or click "Extend" to continue your session.</p>`,
    headerCloseButton: true,
    button1: "End", 
    button2: "Extend",
    key: "FirstPopup"

};

export const popNewData = {
    title: "Inactivity Detected!",
    iconUrl: "assets/images/icon-conectivity.svg",
    content: `<p>Looks like your session has expired. You will be redirected to the Authentication page.</p>`,
    headerCloseButton: true,
    button1: "",
    button2: "Continue",
    key: "FinalPopup"
};