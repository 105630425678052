import { AbstractControl, FormControl } from "@angular/forms";
import { ComponentConfig } from "../ComponentConfig";
import { DynamicContext } from "../interfaces/DynamicContext";
import { DynamicComponentService } from "../services/dynamic-component.service";

export function getDefaultValue(value: any, dataType: string) {
  if (dataType) {
    return getValue(dataType);
  }
  if (typeof value === "string") {
    return "";
  } else if (value instanceof Array) {
    return [];
  } else {
    return undefined;
  }
}
function getValue(dataType: any) {
  if (dataType) {
    if (dataType === "string") {
      return "";
    }
    if (dataType === "Array") {
      return [];
    } else {
      return undefined;
    }
  }
}

export function clearValidators(control: AbstractControl) {
  control.clearValidators();
  control.clearAsyncValidators();
  control.setErrors(null);
  control.updateValueAndValidity({ onlySelf: true, emitEvent: true });
}

export function attachValidators(
  service: DynamicComponentService,
  control: AbstractControl,
  config: ComponentConfig,
  context: DynamicContext
) {
  control.setValidators(
    service.validatorFactory.createValidators(config, context)
  );
  control.setAsyncValidators(
    service.validatorFactory.createAsyncValidators(config, context)
  );
  control.setErrors(null);
  control.updateValueAndValidity({ onlySelf: true, emitEvent: true });
}
