import { CustomFilter } from "../interfaces/Decorators";
import { ComponentConfig } from "../ComponentConfig";
import { DynamicContext } from "../interfaces/DynamicContext";
import { UntypedFormGroup } from "@angular/forms";
import { isArray } from "util";

export class FilterDecoratorComposition {
  @CustomFilter("expression")
  expressionFilter(
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): boolean => {
      const fn = `return (${params})`;
      const runtime = new Function("config", "context", "formGroup", fn);
      const result = runtime(config, context, formGroup);
      return !!(isArray(result) ? result.length > 0 : result);
    };
  }

  @CustomFilter("expressionACL")
  expressionACLFilter(
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ) {
    return (params: any): boolean => {
      const fn = `return (${params})`;
      const runtime = new Function("config", "context", "formGroup", fn);
      const result = runtime(config, context, formGroup);
      if (result == 'US1' && config.filters[1].name == 'acl') {
        config.filters[1].params = 'AP_SST,AP_QC,systemAdmin';
      }
      return !!(isArray(result) ? result.length > 0 : result);
    };
  }
}
