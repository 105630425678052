import { Injectable } from "@angular/core";
import { HttpService } from "./api/http.service";
import { environment } from "../../environments/environment";
import { cloneDeep } from "../utils";

@Injectable({
  providedIn: "root",
})
export class MetadataService {
  baseUrl = environment.gateway;

  private pageCache = {};

  constructor(private http: HttpService) {}

  public async getPageConfiguration(key: string): Promise<any> {
    if (this.pageCache[key]) {
      return cloneDeep(this.pageCache[key]);
    }
    const url = `${this.baseUrl}/metadata/pageConfigs/${key}`;
    const resp = await this.http.GetPromise<any>(url);
    const configs = resp.data;
    if (!this.pageCache[key]) {
      this.pageCache[key] = configs;
    }
    const configCopy = cloneDeep(configs);
    return configCopy;
  }
}
