// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-mdc-dialog-content.mdc-dialog__content {
  color: #000 !important;
  font-weight: 500 !important;
  font-size: 13px !important;
  text-align: justify !important;
  margin-top: 10px !important;
  letter-spacing: 0 !important;
}

.mat-mdc-dialog-container .mdc-dialog__surface {
  border-radius: 0 !important;
}

.container {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.global-poup-box {
  width: 450px;
  align-items: center;
  justify-content: center;
}

img {
  max-width: 60px;
  margin-top: 10px;
}

.button-container {
  display: flex;
  justify-content: space-around;
  margin-bottom: 15px;
  gap: 140px;
  color: unset !important;
  outline-color: unset !important;
}

.mat-mdc-dialog-actions.mdc-dialog__actions {
  justify-content: center;
  color: unset !important;
}

mat-mdc-button-persistent-ripple mdc-button__ripple::before {
  background-color: unset !important;
}

button.mdc-button.mat-mdc-button {
  background-color: rgb(0, 122, 204);
  color: #fff !important;
  padding: 10px 25px !important;
  border: unset !important;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
