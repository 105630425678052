import { DashBoardService } from "./../../../services/dashboard.service";
import {
  IHistoryItem,
  IListHistoryItems,
  IAuditTrailHistory,
} from "./../../../../../interfaces/history";
import { Router, ActivatedRoute } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { BuyerService } from "src/app/services/buyer.service";
import { SupplierProfile } from "src/app/interfaces/supplierProfile";
import { RequestForm } from "src/app/interfaces/requestForm";
import { OnBoardingService } from "../../../services/onboarding.service";
import { AuthService } from "src/app/services/auth/auth.service";
import { RoleName, RoleCode } from "src/app/interfaces/buyer";
import { SupplierService } from "src/app/services/supplier.service";
import * as moment from "moment";
import {
  BuyerChangeRequestDTO,
  AuditChangeRequestDetail,
  ChangeRequestStatus,
  ChangeRequestType,
  ChangeRequestDetailStatus,
} from "src/app/interfaces/changeRequest";
import { supplierGeographical } from "src/app/shared/shared";
import {
  StatusModel,
  SupplierPendingStatus,
  SupplierStatus,
  StatusType,
  SapResultModel,
  InvitedStatusMapping,
  PocEmailEvidence,
} from "src/app/interfaces/mapping";
import {
  HistoryTask,
  AllTaskKey,
  StatusHistoriesTitle,
  HistoryProcessInstance,
  ProcessNames,
  getTaskCompletedName,
  CurrentProcess,
  ProcessInstance,
  getProcessTitle,
  Task,
  WorkflowEndType,
} from "src/app/interfaces/workflow";
import { WorkflowService } from "src/app/services/workflow.service";
import { MetadataService } from "src/app/services/metadata.service";
import { DialogType } from "src/app/services/dialog.service";
import { LanguageService } from "../../../../../dynamic-components/utils/language.service";
import { LoadingService } from "src/app/services/loading.service";
import { isArray } from "lodash";
import { LaunchDarklyService } from "src/app/services/launch-darkly.service";
import { LexerTokenType } from "@angular/compiler";

@Component({
  selector: "app-history-buyer",
  templateUrl: "./history-buyer.component.html",
  styleUrls: ["./history-buyer.component.sass"],
})
export class HistoryBuyerComponent implements OnInit {
  supplierProfiles: any[] = [];
  buyerHistoryList: any = [];
  supplierHistoryList: any = [];
  auditTrailHistoryList: any = [];
  changeRequestList: Array<BuyerChangeRequestDTO> = [];
  supplierCode: any;
  isNewVersion:boolean=true;
  page = 0;
  userRoleDesc = this.authService.passport.buyer.RoleCode;
  roleCode = RoleName;
  isSupplierDraft: boolean;
  auditTrailHistories = [];
  auditTrailHistoriesResults1 = [];
  auditTrailHistoriesResults2 = [];
  Navigation = Navigation;

  constructor(
    private buyerService: BuyerService,
    private supplierService: SupplierService,
    private route: ActivatedRoute,
    private dashBoardService: DashBoardService,
    private onboardingService: OnBoardingService,
    private authService: AuthService,
    private router: Router,
    private metadataService: MetadataService,
    private workflowService: WorkflowService,
    private languageService: LanguageService,
    public loadingService: LoadingService,
    private launchDarklyService:LaunchDarklyService
  ) {
    this.launchDarklyService.ldChange.subscribe(any => {
      console.log("Trigger refresh data")
      this.refreshToggle()
  })
  }
  refreshToggle():void{
    this.isNewVersion=this.launchDarklyService.getToggle(LaunchDarklyService.LD_ISNEWVERSION)==="true"
  }

  async ngOnInit() {
    this.loadingService.showLoading();
    this.refreshToggle();
    this.supplierCode = this.route.snapshot.paramMap.get("code");
    this.page = parseInt(this.route.snapshot.paramMap.get("step"), 10);
    this.onboardingService.supplierCode = this.supplierCode;
    await this.onboardingService.loadSupplierRequestForm();
    // let allField: any
    const createUser =
      this.onboardingService.supplierModel.Mapping.searchTerm.createBuyer;
    const currentUser = this.authService.passport.buyer.Email;
    // if (this.userRoleDesc === this.roleCode.Requestor) {
    //   // allField = createUser === currentUser
    //   //   ? profileJsonData
    //   //   : profileJsonData
    //   allField = profileJsonData
    // } else {
    //   allField = createUser === currentUser
    //     ? profileJsonData
    //     : profileJsonData
    //    allField = profileJsonData
    // }

    // const historyAjax = [
    //   await this.buyerService.getChangeRequestList(this.supplierCode),
    //   await this.supplierService.querySupplierProfile(this.supplierCode),
    //   await this.buyerService.querySupplierHistories(this.supplierCode),
    //   await this.buyerService.queryBuyerHistories(this.supplierCode),
    //   await this.metadataService.getPageConfiguration('transactions-history')
    // ]
    // await Promise.all(historyAjax)
    // const [crHistory, data, supplierHistories, buyerHistories, allField]: any = await historyAjax
 
    const [crHistory, data, supplierHistories, buyerHistories, allField]: any = this.isNewVersion?
      await Promise.all([
        this.buyerService.getChangeRequestList(this.supplierCode),
        this.supplierService.queryNewSupplierProfile(this.supplierCode),
        this.supplierService.querySupplierHistoriesEnhanced(this.supplierCode),
        this.buyerService.queryNewBuyerHistories(this.supplierCode),
        this.metadataService.getPageConfiguration("transactions-history"),
      ]) :
      await Promise.all([
        this.buyerService.getChangeRequestList(this.supplierCode),
        this.supplierService.querySupplierProfile(this.supplierCode),
        this.buyerService.querySupplierHistories(this.supplierCode),
        this.buyerService.queryBuyerHistories(this.supplierCode),
        this.metadataService.getPageConfiguration("transactions-history"),
      ]); 
    
    if (data.isSuccess) {
      // const statusHistories = [...data.data.Mapping.statusHistories, ...data.data.Mapping.AP_statusHistories]
      const historyTasks = [...data.data.Mapping.historyTasks];
      await this.updateStatusModel(historyTasks);
      this.transitionAuditTrailHistory();
      this.changeRequestList = this.transitionChangeRequestHistory(
        crHistory.data
      ) as Array<BuyerChangeRequestDTO>;
    }
    if (buyerHistories.isSuccess) {  
      if(this.isNewVersion){
        const newBuyerHistoryItemList: IListHistoryItems<RequestForm> =
        new IListHistoryItems(
          buyerHistories.data,
          allField,
          this.languageService
        );
      this.buyerHistoryList = newBuyerHistoryItemList.onLoad();
      } 
      else { 
      const buyerHistoryItemList: IListHistoryItems<RequestForm> =
        new IListHistoryItems(
          buyerHistories.data,
          allField,
          this.languageService
        );
      this.buyerHistoryList = buyerHistoryItemList.load();
      }
    }
    if (supplierHistories.isSuccess) {
      if(this.isNewVersion) {
        const newSupplierHistoryItemList: IListHistoryItems<SupplierProfile> =
        new IListHistoryItems(
          supplierHistories.data,
          allField,
          this.languageService
        );
        this.supplierHistoryList = newSupplierHistoryItemList.onLoad();
      } else {
        const supplierHistoryItemList: IListHistoryItems<SupplierProfile> =
          new IListHistoryItems(
            supplierHistories.data,
            allField,
            this.languageService
          );
        this.supplierHistoryList = supplierHistoryItemList.load();
      }
      this.supplierProfiles = supplierHistories.data;
    }
    if (this.supplierHistoryList.length > 0) {
      this.isSupplierDraft = true;
    } else {
      this.isSupplierDraft = false;
      this.loadingService.hideLoading();
    }

    if (
      this.onboardingService.supplierModel.RequestForm.RequestorDetailsInfo
        .SupplierGeographical === supplierGeographical.MET
    ) {
      this.supplierHistoryList.forEach((t) => {
        t.value.forEach((s) => {
          if (s.columName === "DiversifiedEnterprises") {
            s.title =
              "Does your company qualify as being owned and/or operated/Lead by Women or a diverse group?";
          }
          if (s.columName === "ComplianceLocalPolicies") {
            s.title =
              "Does your company have sufficient policies / regulations to manage and safeguard itself on aspects such as Modern Slavery and / or human trafficking?";
          }
          if (s.columName === "UploadDetailsMaterial") {
            s.title = "If Yes, please attach the policy";
          }
          if (s.columName === "BusinessRegistrationNb") {
            s.title = "Business Registration Number";
          }
        });
      });
    }
  }
  navTo(n: number) {
    this.page = n;
  }
 
  transitionAuditTrailHistory() {
    for (const auditTrailHistory of this.auditTrailHistoriesResults2) {
      auditTrailHistory.statusHistories.map((i, index) => {
        const currentObj = i;
        currentObj.show = false;
        currentObj.type = "Updated By";
        const currentObjKey = Object.keys(currentObj)[0];
        const currentTitle: string = Object.keys(i)[0];
        if (index === 0) {
          currentObj.previousStatus = "Blank";
        } else if (index > 0) {
          const previousObj = auditTrailHistory.statusHistories[index - 1];
          const previousObjKey = Object.keys(previousObj)[0];
          currentObj[currentObjKey].previousStatus =
            previousObj[previousObjKey].status;
        }
        return i;
      });
      auditTrailHistory.statusHistories.sort((first: any, second: any) => {
        const firstKey = Object.keys(first)[0];
        const secondKey = Object.keys(second)[0];
        return (
          moment(first[firstKey].createdOn).unix() -
          moment(second[secondKey].createdOn).unix()
        );
      });
      auditTrailHistory.statusHistories.reverse();
    }
  }

  transitionChangeRequestHistory(changeRequestHistory) {
    changeRequestHistory.forEach((i, index) => {
      const currentObj = i;
      currentObj.show = false;
    });
    return changeRequestHistory;
  }
  viewProfileForm() {
    this.router.navigate([`buyer/supplier/${this.supplierCode}`], {replaceUrl: true});
  }
  backToOnboardingProcess() {
    this.router.navigate([`buyer/onboarding/process/${this.supplierCode}`], {replaceUrl: true});
  }
  async updateStatusModel(historyTasks: Array<HistoryTask>, rejectTo = "") {
    let historyTasksTerminted = historyTasks.filter(item => item.processDefName === 'Generic Supplier Setup' && JSON.parse(item.variablesStr).isSupplierTerminated)
    historyTasks = historyTasks.filter(item => !(item.processDefName === 'Generic Supplier Setup' && JSON.parse(item.variablesStr).isSupplierTerminated))
    this.auditTrailHistories = [];
    const processHistories: HistoryProcessInstance[] =
      await this.loadProcessHistories();
    const activeProcess: ProcessInstance[] = await this.loadActiveProcess();
    if (activeProcess && activeProcess.length > 0) {
      activeProcess.forEach((process: ProcessInstance) => {
        const iProcess = {
          process,
          show: false,
        };
        const auditTrailHistory: IAuditTrailHistory<ProcessInstance> = {
          iProcess,
          status: "pending",
          statusHistories: [],
        };
        this.auditTrailHistories.push(auditTrailHistory);
      });
    }
    if (processHistories && processHistories.length > 0) {
      processHistories.forEach((process: HistoryProcessInstance) => {
        const iProcess = {
          process,
          show: false,
        };
        const auditTrailHistory: IAuditTrailHistory<HistoryProcessInstance> = {
          iProcess,
          status: "completed",
          statusHistories: [],
        };
        this.auditTrailHistories.push(auditTrailHistory);
      });
    }
    historyTasks.forEach((singleTask) => {
      const keyStatus: string = Object.keys(AllTaskKey).filter(
        (item) => AllTaskKey[item] === singleTask.name
      )[0];
      const isAPReview = false;
      let taskStatus: string = getTaskCompletedName(singleTask.name);
      let historiesTitle = StatusHistoriesTitle[keyStatus];
      const createdBy = singleTask.startUser;
      let roleName = RoleCode[singleTask.candidateGroup];
      if (singleTask.name === AllTaskKey.SST_review) {
        if (singleTask.variablesObj.SST_Approve) {
          historiesTitle = StatusHistoriesTitle.SST_review;
          taskStatus = "AP SST Review Approved";
        } else if (
          singleTask.variablesObj.SST_RejectTo &&
          singleTask.variablesObj.SST_RejectTo === "buyer"
        ) {
          historiesTitle = StatusHistoriesTitle.SST_rejectToBuyer;
          taskStatus = "AP SST Reject";
        } else if (
          singleTask.variablesObj.SST_RejectTo &&
          singleTask.variablesObj.SST_RejectTo === "supplier"
        ) {
          historiesTitle = StatusHistoriesTitle.SST_rejectToSupplier;
          taskStatus = "AP SST Reject";
        } else if (
          singleTask.variablesObj &&
          singleTask.variablesObj.isSupplierTerminated
        ) {
          historiesTitle = StatusHistoriesTitle.SST_terminate;
          //taskStatus = "AP SST Terminated";
          taskStatus = "Terminated";
        }
      } else if (singleTask.name === AllTaskKey.QC_review) {
        if (singleTask.variablesObj.QC_Approve) {
          historiesTitle = StatusHistoriesTitle.QC_review;
          taskStatus = "AP QC Review Approved";
        } else if (singleTask.variablesObj.QC_Reject) {
          historiesTitle = StatusHistoriesTitle.QC_reject;
          taskStatus = "AP QC Reject";
        } else if (
          singleTask.variablesObj &&
          singleTask.variablesObj.isSupplierTerminated
        ) {
          historiesTitle = StatusHistoriesTitle.QC_terminate;
          //taskStatus = "AP QC Terminated";
          taskStatus = "Terminated";
        }
      } else if (singleTask.name === AllTaskKey.requestor_draft) {
        roleName = "Requestor";
        if (singleTask.variablesObj && singleTask.variablesObj.isTerminate) {
          taskStatus = SupplierPendingStatus.terminated;
          historiesTitle = StatusHistoriesTitle.terminated;
          // console.log("historiesTitle", historiesTitle);
        }
      } else if (singleTask.name === AllTaskKey.GDPR) {
        roleName = "Buyer";
      } else if (singleTask.name === AllTaskKey.BI) {
        roleName = RoleCode.BI;
      } else if (singleTask.name === AllTaskKey.Onboarding_supplier_PocEmailUpdate) {
        historiesTitle = StatusHistoriesTitle.poc_update;
      }
      if (rejectTo) {
        rejectTo = rejectTo.substring(
          rejectTo.lastIndexOf(" ") + 1,
          rejectTo.length
        );
      }
      if (singleTask.name === AllTaskKey.SST_rejectToBuyer) {
        roleName = "Requestor";
      }
      const model: StatusModel = {};
      model[keyStatus] = {
        status: taskStatus,
        createdBy: createdBy,
        createdOn: singleTask.endTime,
        isStarted: true,
        isDisplay: true,
        isAPReview: isAPReview,
        role: roleName,
        historiesTitle: historiesTitle,
        rejectTo: rejectTo,
        pocEmailEvidence: this.preparePOCEmailEvidence(singleTask)
      };
      for (const auditTrailHistory of this.auditTrailHistories) {
        if (
          auditTrailHistory.iProcess.process.processInstId ===
          singleTask.processInstId
        ) {
          auditTrailHistory.statusHistories.push(model);
        }
      }
    });
    let terminateComments: string;
    let createdBy: string;
    let createdOn: string;
    const sapResult: SapResultModel[] =
      this.onboardingService.supplierModel.Mapping.assessmentRate.sapResult;
    if (sapResult && sapResult.length > 0) {
      sapResult.forEach((item: SapResultModel) => {
        if (item.type === DialogType.terminateSupplier) {
          terminateComments = item.comments;
          createdBy = item.createdBy.split("By ")[1];
          createdOn = item.createdOn;
        }
      });
    }
    const invitedStatus: string =
      this.onboardingService.supplierModel.Mapping.InvitedStatus;
    if (
      this.onboardingService.supplierModel.Mapping.status ===
        SupplierStatus.terminated &&
      this.onboardingService.supplierModel.Mapping.IsMigrated &&
      (invitedStatus === InvitedStatusMapping.new ||
        invitedStatus === InvitedStatusMapping.invited)
    ) {
      const process: HistoryProcessInstance = {};
      process.name = ProcessNames.generic_supplierMigrateSetup;
      const iProcess = {
        process,
        show: true,
      };
      const auditTrailHistory: IAuditTrailHistory<HistoryProcessInstance> = {
        iProcess,
        status: "completed",
        statusHistories: [],
      };
      const terminatedModel: StatusModel = {};
      terminatedModel["Terminated"] = {
        status: "Terminated",
        createdBy: createdBy,
        createdOn: createdOn,
        isStarted: true,
        isDisplay: true,
        historiesTitle: StatusHistoriesTitle.terminated,
        comments: terminateComments,
      };
      auditTrailHistory.statusHistories.push(terminatedModel);
      this.auditTrailHistoriesResults2.push(auditTrailHistory);
    }
    if (historyTasks && historyTasks.length > 0) {
      let his = historyTasksTerminted&& historyTasksTerminted.length ? historyTasksTerminted : historyTasks
      const variable: any = his[his.length - 1].variablesObj;
      const role =his[his.length - 1].candidateGroup
      const isTerminatedInProcessingPage: boolean =
        variable.isSupplierTerminated === true;
      if (
        this.onboardingService.supplierModel.Mapping.status ===
          SupplierStatus.terminated &&
        isTerminatedInProcessingPage
      ) {
        for (const auditTrailHistory of this.auditTrailHistories) {
          const terminatedModel: StatusModel = {};
          terminatedModel["Terminated"] = {
            status: "Terminated",
            createdBy: createdBy,
            createdOn: createdOn,
            isStarted: true,
            isDisplay: true,
            role: role,
            historiesTitle: StatusHistoriesTitle.terminated,
            comments: terminateComments,
          };
          auditTrailHistory.statusHistories.push(terminatedModel);
        }
      }
    }
    this.auditTrailHistoriesResults1 = this.auditTrailHistories.filter(
      (item) => {
        return (
          item.iProcess.process.name ===
          ProcessNames.generic_supplierSetupSubProcess
        );
      }
    );
    this.auditTrailHistoriesResults2 = this.auditTrailHistories.filter(
      (item) => {
        return (
          item.iProcess.process.name !==
          ProcessNames.generic_supplierSetupSubProcess
        );
      }
    );
    for (const history of this.auditTrailHistoriesResults2) {
      if (this.auditTrailHistoriesResults1.length > 0) {
        if (
          history.iProcess.process.processInstId ===
          this.auditTrailHistoriesResults1[0].iProcess.process
            .parentProcessInstId
        ) {
          history.statusHistories = history.statusHistories.concat(
            this.auditTrailHistoriesResults1[0].statusHistories.filter(
              (item) => {
                const itemKey = Object.keys(item)[0];
                //return Object.keys(item)[0].status !== SupplierStatus.terminated;
                return item[itemKey].status !== SupplierStatus.terminated;
              }
            )
          );
          history.statusHistories.sort((first: any, second: any) => {
            const firstKey = Object.keys(first)[0];
            const secondKey = Object.keys(second)[0];
            return (
              moment(first[firstKey].createdOn).unix() -
              moment(second[secondKey].createdOn).unix()
            );
          });
          history.statusHistories.map((item, i) =>{
            const itemKey = Object.keys(item)[0];
            if(itemKey == 'Terminated'){
              history.statusHistories.splice(i+1, history.statusHistories.length-i)
            }
          });
        }
      }
      if (
        history.iProcess.process.name === ProcessNames.generic_supplierSetup
      ) {
        if (
          history.iProcess.process.endType &&
          history.iProcess.process.endType === WorkflowEndType.noneEnd
        ) {
          const lastMode =
            history.statusHistories[history.statusHistories.length - 1];
          const lastKey = Object.keys(lastMode)[0];
          // console.log(
          //   "lastMode",
          //   lastMode,
          //   "lastKey",
          //   lastKey,
          //   "lastMode[key]",
          //   lastMode[lastKey].role
          // );
          const model: StatusModel = {};
          model[StatusType.onboarded] = {
            status: SupplierStatus.onboarded,
            createdBy: lastMode[lastKey].createdBy,
            createdOn: history.iProcess.process.endTime,
            isStarted: true,
            isDisplay: true,
            isAPReview: false,
            role: lastMode[lastKey].role,
            historiesTitle: StatusHistoriesTitle.onboarded,
            rejectTo: "",
          };
          history.statusHistories.push(model);
          history.statusHistories.map((item, i) =>{
            const itemKey = Object.keys(item)[0];
            if(itemKey == 'Terminated'){
              history.statusHistories.splice(i, 1)
            }
          });
        }
      }
      if (history.iProcess.process.name === ProcessNames.generic_buyerChangeRequest || history.iProcess.process.name === ProcessNames.generic_supplierChangeRequest) {
        this.changeRequestTerminate(history);
      }
      if (history.iProcess.process.name === ProcessNames.generic_supplierMigrateSetup) {
        history.statusHistories.sort((first: any, second: any) => {
          const firstKey = Object.keys(first)[0];
          const secondKey = Object.keys(second)[0];
          return (
            moment(first[firstKey].createdOn).unix() -
            moment(second[secondKey].createdOn).unix()
          );
        });
        this.migratedTerminate(history);
      }
      history.iProcess.process.name = getProcessTitle(
        history.iProcess.process.name
      );
    }
    this.auditTrailHistoriesResults2.sort(
      (a: any, b: any) =>
        moment(b.iProcess.process.startTime).toDate().getTime() -
        moment(a.iProcess.process.startTime).toDate().getTime()
    );
    return this.auditTrailHistoriesResults2;
  }
  // load process histories and update steps
  private async loadProcessHistories() {
    let data: any;
    if (this.isNewVersion) {
      data = await this.workflowService.getNewProcessHistoriesByBusinessKey(
        this.supplierCode
      );
    } else {
      data = await this.workflowService.getProcessHistoriesByBusinessKey(
        this.supplierCode
      );
    }
    if (data.isSuccess) {
      return data.data;
    }
  }

  preparePOCEmailEvidence(singleTask): Array<PocEmailEvidence> | undefined{
    let data = undefined;
    if(singleTask && singleTask.variablesObj && isArray(singleTask.variablesObj))
    {
      data = singleTask.variablesObj.filter((item: any) => {
        item.filePath ? delete(item.filePath) : false;
        item.isUploading ? delete(item.isUploading) : false;
       return true;
    });
    }
    return data;
  }

  async changeRequestTerminate(history: any) {
    history.statusHistories.map((item, i) =>{
      const itemKey = Object.keys(item)[0];
      if(itemKey != 'Terminated' && item[itemKey].status == 'Terminated'){
        history.statusHistories.splice(i, 1)
      } else if (itemKey == 'Terminated' 
        || (itemKey == 'QC_review' && item[itemKey].status == 'AP QC Review Approved')
        || (itemKey == 'QC_review' && item[itemKey].status == 'AP QC Review Approved')
      ) {
        history.statusHistories.splice(i+1, history.statusHistories.length-i)
      } else if (itemKey == 'QC_review' && (item[itemKey].status != 'AP QC Review Approved' || item[itemKey].status != "AP QC Reject") && Object.keys(history.statusHistories[i + 1])[0] == 'Terminated') {
        history.statusHistories.splice(i+1, 1)
      }
    });
  }

  async migratedTerminate(history: any){
    history.statusHistories.map((item, i) =>{
      const itemKey = Object.keys(item)[0];
      if (itemKey == 'SST_rejectToSupplier') {
        if (Object.keys(history.statusHistories[i + 1])[0] != 'SST_review') {
          history.statusHistories.splice(i, 1)
        }
      } else if(itemKey != 'Terminated' && item[itemKey].status == 'Terminated'){
        history.statusHistories.splice(i, 1)
      } else if (itemKey == 'Terminated' 
        || (itemKey == 'QC_review' && item[itemKey].status == 'AP QC Review Approved') 
        || (itemKey == 'QC_review' && item[itemKey].status != 'AP QC Review Approved' && item[itemKey].status != 'AP QC Reject')
      ) {
        history.statusHistories.splice(i+1, history.statusHistories.length-i)
      }
    });
  }

  private async loadActiveProcess() {
    let data: any;
    if (this.isNewVersion) {
      data = await this.workflowService.getNewProcessInstanceByBusinessKey(
        this.supplierCode
      );
    } else {
      data = await this.workflowService.getProcessInstanceByBusinessKey(
        this.supplierCode
      );
    }
    if (data.isSuccess) {
      return data.data;
    }
  }
}
enum Navigation {
  RequestForms = 0,
  SupplierForms = 1,
  SupplierStatusAuditTrail = 2,
  ChangeRequestAuditTrail = 3,
  OnHoldHistory = 4,
}
