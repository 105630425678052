import { DatasourceFactory } from "./DatasourceFactory";
import { ComponentConfig } from "../ComponentConfig";
import { DynamicContext } from "../interfaces/DynamicContext";
import { UntypedFormGroup } from "@angular/forms";

export class Datasources {
  private static datasources = new Map();

  constructor() {}

  static registerDatasource(name: string, datasource: DatasourceFactory) {
    this.datasources.set(name, datasource);
  }

  static getDatasource(name: string): DatasourceFactory {
    if (!this.datasources.get(name)) {
      console.log("no datasource found for name : " + name);
    }
    return this.datasources.get(name);
  }

  static loadData(
    config: ComponentConfig,
    context: DynamicContext,
    formGroup: UntypedFormGroup
  ): Promise<any> {
    if (!config.dataSource) {
      return;
    }
    const datasourceFactory = this.getDatasource(config.dataSource.name);
    if (datasourceFactory) {
      const ds = datasourceFactory.call(
        datasourceFactory.objInstance,
        config,
        context,
        formGroup
      );
      return ds(config.dataSource.params);
    }
  }
}
