import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { SupplierService } from "src/app/services/supplier.service";
import { ManagementService } from "../../../services/management.service";
import { DashBoardService } from "../../../../buyer/services/dashboard.service";
import { AuthService } from "src/app/services/auth/auth.service";
import {
  ButtonCommands,
  DialogType,
  DialogService,
} from "src/app/services/dialog.service";
import { LanguageService } from "../../../../../dynamic-components/utils/language.service";

@Component({
  selector: "app-admin-layout",
  templateUrl: "./admin-layout.component.html",
  styleUrls: ["./admin-layout.component.sass"],
})
export class AdminLayoutComponent implements OnInit {
  isDisable = false;
  isFromTSM = false;
  TsmId: any = "";
  Search_Users: string;
  managementService: ManagementService;

  constructor(
    private router: Router,
    private auth: AuthService,
    private _managementService: ManagementService,
    private dialogService: DialogService,
    private languageService: LanguageService
  ) {
    this.managementService = _managementService;
  }

  get supplierCode(): string {
    return this.auth.passport.supplier.Mapping.supplierCode;
  }

  get title(): string {
    return this.managementService.title;
  }

  get isMigrated(): boolean {
    return this.auth.passport.supplier.Mapping.IsMigrated;
  }

  ngOnInit() {
    this.isFromTSM = !!this.auth.passport.supplier.Mapping.TsmId;
    this.TsmId = this.auth.passport.supplier.Mapping.TsmId;
    this.Search_Users = this.languageService.getValue(
      "static.supplier.user.Search_Users"
    );
  }

  backTo() {
    // this.router.navigate(['supplier/landing'])
    this.isDisable = true;
  }

  cancelEvent(event) {
    this.isDisable = false;
  }

  hideCreateBtn() {
    return this.managementService.editMode === true;
  }

  isTwentyActiveUser() {
    if (
      this.managementService.users.filter((item) => item.Enabled === true)
        .length >= 20
    ) {
      this.dialogService.dialog(
        this.languageService.getValue(
          "static.supplier.components.admin-layout.have_reached"
        ),
        ButtonCommands.Close,
        DialogType.alert,
        this.languageService.getValue(
          "static.supplier.components.supplier-detail.please_note"
        )
      );
    } else {
      this.router.navigate(["/supplier/management/user-create"]);
    }
  }
}
