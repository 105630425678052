// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.checkBoxCss {
  margin-top: 1rem;
}

.spanDescInline {
  display: inline-block !important;
  font-weight: 100;
}

.link-mare-panel {
  position: absolute;
  height: auto;
  width: 350px;
  background: #fff;
  color: #000;
  border-radius: 5px;
  padding: 5px 0;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.12), 0 3px 6px rgba(0, 0, 0, 0.24);
  outline: 0;
  z-index: 9999;
  top: 1.2rem;
}
.link-mare-panel p {
  line-height: 1.5;
  font-weight: normal;
}

.link-mare-panel-supplierChoose {
  height: auto;
}
.link-mare-panel-supplierChoose a {
  color: #006caf;
  font-weight: 600;
  cursor: pointer;
}

.link-mare-panel01 {
  position: absolute;
  height: auto;
  width: 350px;
  background: #fff;
  color: #000;
  border-radius: 5px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.12), 0 3px 6px rgba(0, 0, 0, 0.24);
  outline: 0;
  z-index: 9999;
  top: 5rem;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
