import { ActivatedRoute, Router } from "@angular/router";
import { Component, OnInit, Input } from "@angular/core";
import { ProfileService } from "src/app/modules/supplier/services/profile.service";
import { SupplierHeaderService } from "../../services/header.service";
import { CommonService } from "src/app/services/common.service";
import { AuthService } from "src/app/services/auth/auth.service";
import {
  SupplierPendingStatus,
  SupplierStatus,
} from "src/app/interfaces/mapping";
import { ProcessNames, SetupProcess } from "src/app/interfaces/workflow";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.sass"],
})
export class HeaderComponent implements OnInit {
  @Input() isTsmId = false;
  TsmId: any;
  isDisable = false;
  onBoardingStatus = false;
  isFromTSM = false;
  get supplierCode(): string {
    return this.auth.passport.supplier.Mapping.supplierCode;
  }
  get current_step() {
    return this.profileService.current_step;
  }
  get isView() {
    console.log("debugging 394551 isView", this.profileService.isView)
    return this.profileService.isView;
  }

  get isShowEditBtn() {
    console.log("debugging 394551 current_step", this.profileService.current_step);
    console.log("debugging 394551 isSSTRejectOrQCApprove ", this.supplierHeaderService.isSSTRejectOrQCApprove);
    console.log("debugging 394551  isShowEditBtn", this.supplierHeaderService.isSSTRejectOrQCApprove &&
    this.profileService.current_step !== 6);
    return (
      this.supplierHeaderService.isSSTRejectOrQCApprove &&
      this.profileService.current_step !== 6
    );
  }

  get showProfileButtons() {
    console.log("debugging 394551 showProfileButtons", this.supplierHeaderService.showProfileButtons);
    return this.supplierHeaderService.showProfileButtons;
  }

  get isMigrated(): boolean {
    return this.auth.passport.supplier.Mapping.IsMigrated;
  }
  get getTsmId(): any {
    this.TsmId = this.profileService.supplierModel
      ? this.profileService.supplierModel.Mapping.TsmId
      : "";
    return this.TsmId;
  }

  supplierHeaderService: SupplierHeaderService;
  profileService: ProfileService;

  constructor(
    private _profileService: ProfileService,
    private _supplierHeaderService: SupplierHeaderService,
    private router: Router,
    private commonService: CommonService,
    private auth: AuthService
  ) {
    this.supplierHeaderService = _supplierHeaderService;
    this.profileService = _profileService;
  }

  ngOnInit() {
    this.TsmId = this.profileService.supplierModel.Mapping.TsmId;
    this.isFromTSM = !!this.profileService.supplierModel.Mapping.TsmId;
  }

  navigateTo(step) {
    if (
      step <= this.profileService.supplierModel.Mapping.profile.step ||
      step === 6
    ) {
      this.router.navigate([
        `supplier/profile/${this.supplierCode}/step${step}`,
      ]);
    }
  }

  async backTo() {
    this.onBoardingStatus = await this.checkOnboardingStatus();
    this.isDisable = true;
  }

  async checkOnboardingStatus(): Promise<boolean> {
    if (
      this.profileService.getUserTaskByKey(
        ProcessNames.generic_supplierSetup,
        SetupProcess.profile_draft
      ) ||
      this.auth.passport.supplier.Mapping.status === SupplierStatus.onboarded
    ) {
      return true;
    } else {
      return false;
    }
  }

  cancelEvent(event) {
    this.isDisable = false;
  }

  edit_profile() {
    this.profileService.mode = "edit";

    const title = this.profileService.headerTitle;
    this.supplierHeaderService.title = title;
  }
}
