// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#modal-bg {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 501;
  background-color: rgba(0, 0, 0, 0.7);
}
#modal-bg #modal-main {
  position: fixed;
  z-index: 10;
  width: 56%;
  height: calc(100% - 6rem);
  border-radius: 10px;
  left: 22%;
  top: 4rem;
  background-color: #fff;
  opacity: 1;
  padding: 10px 0;
}
#modal-bg #modal-main .modal-body {
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  top: 0;
  padding: 0 40px;
}
#modal-bg #modal-main .modal-body .modal-title.h2 {
  text-align: center;
  line-height: 4;
  color: #4e4e87;
  font-weight: 600;
  font-size: 24px;
  width: 100%;
  font-family: "Graphik Bold";
}
#modal-bg #modal-main .modal-body .h3 {
  text-align: left;
  line-height: 2.5;
  color: #000;
  font-weight: 600;
  font-size: 22px;
  width: 100%;
  font-family: "Graphik Semi Bold";
}
#modal-bg #modal-main .modal-body .h4 {
  text-align: left;
  line-height: 2.5;
  color: #000;
  font-weight: 600;
  font-size: 16px;
  width: 100%;
}
#modal-bg #modal-main .modal-body .ullist {
  list-style-type: square;
  margin-left: 1rem;
}
#modal-bg #modal-main .modal-body .table {
  width: 100%;
}
#modal-bg #modal-main .modal-body .table td {
  padding: 1rem;
}
#modal-bg #modal-main .modal-body p {
  display: block;
  padding: 0;
  text-align: left;
  line-height: 1.5;
  color: #000;
  font-size: 15px;
}
#modal-bg #modal-main .modal-body p span.strong {
  font-weight: 600;
}
#modal-bg #modal-main .modal-body p span.upperRed {
  color: #CE004A;
  padding-top: 1rem;
  display: inline-block;
}
#modal-bg #modal-main .modal-body p span.purple {
  color: #ce004a;
  font-weight: 600;
}
#modal-bg #modal-main .modal-body p span.upper {
  padding-top: 1rem;
  display: inline-block;
}
#modal-bg #modal-main .modal-body p span.l-50 {
  display: inline-block;
  width: 50%;
  line-height: 1;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
#modal-bg #modal-main .modal-body p span.l-50 a {
  text-decoration: underline;
  color: #006CAF;
}
#modal-bg #modal-main .modal-body p a {
  text-decoration: underline;
  color: #006CAF;
}
#modal-bg #modal-main .modal-body p a .strong {
  font-weight: 600;
}
#modal-bg #modal-main .modal-body p.last {
  display: block;
  margin-bottom: 5rem;
}
#modal-bg #modal-main .modal-body p.mb0 {
  margin-bottom: 0;
}
#modal-bg #modal-main .modal-body .lrMain {
  overflow: hidden;
}
#modal-bg #modal-main .modal-body .lrMain .leftRight {
  float: left;
  width: 50%;
}
#modal-bg #modal-main .modal-body .modal-footer {
  width: 100%;
  height: 20%;
  padding: 2rem 0;
  background-color: #fff;
  border-radius: 10px;
}
#modal-bg #modal-main .modal-body .modal-footer button.btn-modal {
  width: 220px;
  height: 50px;
  background: linear-gradient(to right, #764ba2 0%, #3C73D2 100%);
  border: none;
  border-radius: 25px;
  position: relative;
  left: calc(50% - 110px);
  top: calc(50% - 30px);
  color: #fff;
  font-weight: 600;
  font-size: 1.5rem;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
