/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable prettier/prettier */
import { Injectable } from "@angular/core";
import { ProfileService } from "src/app/modules/supplier/services/profile.service";
import { supplierGeographical, supplierCountry, getTSMCountryServedQuery, spendCommodityGroup } from "../../../shared/shared";
import { DynamicContext } from "../../../dynamic-components/interfaces/DynamicContext";
import { environment } from "../../../../environments/environment";
import { AuthService } from "src/app/services/auth/auth.service";
import { DictionaryService } from "../../../services/dictionary.service";
import { SupplierGeneralService } from "src/app/services/supplier-general.service";
import { LaunchDarklyService } from "src/app/services/launch-darkly.service";

@Injectable({
  providedIn: "root",
})
export class SupplierCommonService {
  isNewVersion: boolean = true;
  constructor(
    private profileService: ProfileService,
    private authService: AuthService,
    private dictionaryService: DictionaryService,
    private supplierGeneralService: SupplierGeneralService,
    private launchDarklyService: LaunchDarklyService
  ) { 
    this.launchDarklyService.ldChange.subscribe((any) => {
      this.refreshToggle();
    });
  }

  refreshToggle(): void {
    this.isNewVersion =
      this.launchDarklyService.getToggle(
        LaunchDarklyService.LD_ISNEWVERSION
      ) === "true";
  }

  private isMet() {
    return (
      this.profileService.supplierModel.RequestForm.RequestorDetailsInfo
        .SupplierGeographical === supplierGeographical.MET
    );
  }
  private isASEAN() {
    return (
      this.profileService.supplierModel.RequestForm.RequestorDetailsInfo
        .SupplierGeographical === supplierGeographical.ASEAN
    );
  }
  private isANZ() {
    return (
      this.profileService.supplierModel.RequestForm.RequestorDetailsInfo
        .SupplierGeographical === supplierGeographical.ANZ
    );
  }
  private isGreaterChina() {
    return (
      this.profileService.supplierModel.RequestForm.RequestorDetailsInfo
        .SupplierGeographical === supplierGeographical.GreaterChina
    );
  }
  private isPH() {
    return (
      this.profileService.supplierModel.RequestForm.RequestorDetailsInfo
        .SupplierGeographical === supplierGeographical.PH
    );
  }
  private isSG() {
    return (
      this.profileService.supplierModel.RequestForm.RequestorDetailsInfo?.SupplierCountry?.find(
        (x) => x === "SG"
      ) === supplierCountry.SG
    );
  }
  private isCEG() {
    return (
      this.isICEG() &&
      !this.profileService.supplierModel.RequestForm.RequestorDetailsInfo?.SupplierCountry.includes("IT")
    );
  }
  private isICEG() {
    return (
      this.profileService.supplierModel.RequestForm.RequestorDetailsInfo
        .SupplierGeographical === supplierGeographical.ICEG
    );
  }
  private isSEA() {
    return (
      this.profileService.supplierModel.RequestForm.RequestorDetailsInfo
        .SupplierGeographical === supplierGeographical.SEA
    );
  }
  private isASG() {
    return (
      this.profileService.supplierModel.RequestForm.RequestorDetailsInfo
        .SupplierGeographical === supplierGeographical.ASG
    );
  }
  private isUKIA() {
    return (
      this.profileService.supplierModel.RequestForm.RequestorDetailsInfo
        .SupplierGeographical === supplierGeographical.UKIA
    );
  }
  private isNordics() {
    return (
      this.profileService.supplierModel.RequestForm.RequestorDetailsInfo
        .SupplierGeographical === supplierGeographical.Nordics
    );
  }
  private isNA() {
    return (
      this.profileService.supplierModel.RequestForm.RequestorDetailsInfo
        .SupplierGeographical === supplierGeographical.NA
    );
  }
  private isIN() {
    return (
      this.profileService.supplierModel.RequestForm.RequestorDetailsInfo
        .SupplierGeographical === supplierGeographical.IN
    );
  }
  // private isAfrica() {
  //   return (
  //     this.profileService.supplierModel.RequestForm.RequestorDetailsInfo
  //       .SupplierGeographical === supplierGeographical.Africa
  //   );
  // }
  private isIberia() {
    return (
      this.profileService.supplierModel.RequestForm.RequestorDetailsInfo
        .SupplierGeographical === supplierGeographical.Iberia
    );
  }
  private isTsmId() {
    return !!this.profileService.supplierModel.Mapping.TsmId;
  }
  private TsmId() {
    return this.profileService.supplierModel.Mapping.TsmId;
  }
  private isGallia() {
    return (
      this.profileService.supplierModel.RequestForm.RequestorDetailsInfo
        .SupplierGeographical === supplierGeographical.Gallia
    );
  }
  private isJapan() {
    return (
      this.profileService.supplierModel.RequestForm.RequestorDetailsInfo
        .SupplierGeographical === supplierGeographical.Japan
    )
  }
   isNewSupplier() {
    localStorage.setItem("isNewSupplier", "" + this.profileService.supplierModel.isNewSupplier);
    return this.profileService.supplierModel.isNewSupplier;
  }

  private isIndividual(){
    return this.supplierGeneralService.getIsIndividual()
  }

  private isCam() {
    return this.profileService.supplierModel.RequestForm.RequestorDetailsInfo.SpendCommodityGroup==spendCommodityGroup.CAM;
  }

  private supplierCountryList() {
    return this.profileService.supplierModel.RequestForm.RequestorDetailsInfo.SupplierCountry;
  }

  private isRollout() {
    const rolloutGUs = [
      ...this.dictionaryService.getDictionaryFromCache(
        "requestor-details.SupplierGeographicalUKI"
      ),
      ...this.dictionaryService.getDictionaryFromCache(
        "requestor-details.SupplierGeographicalANZ"
      ),
    ];
    const guCode =
      this.profileService.supplierModel.RequestForm.RequestorDetailsInfo
        .SupplierGeographical;
    return rolloutGUs.find(({ Code }) => guCode === Code) ? true : false;
  }
  private isPaymentModeCreditCard() {
    return (
      this.profileService.supplierModel.RequestForm.RequestorDetailsInfo
        .SpendCommodityGroup=="17"
    );
  }
  private async SetBuyerModel() {
    if (!this.isNewVersion) {
      if (
        !this.profileService.supplierModel.RequestForm
     ) {
       await this.profileService.loadRequestForm();
     }
    } else {
      // This needs to be removed as part of SPO.
      await this.profileService.loadRequestForm();
    }
  }

  private TsmLink() {
    const env = sessionStorage.getItem("tsmEnv");
    const url =
      env === "dev"
        ? environment.relationshipdev_url
        : env === "perf"
          ? environment.relationshipperf_url
          : env === "hotfix"
            ? environment.relationshiphotfix_url
            : env === "stage"
              ? environment.relationshipstage_url
              : env === "test"
                ? environment.relationshiptest_url
                : environment.relationship_url;
    const countryServed = getTSMCountryServedQuery(
      this.profileService.supplierModel.Mapping.setUpCountry,
    );
    const redirectUrl =
      url +
      this.TsmId() +
      "?countryServed=" +
      countryServed +
      "&login_hint=" +
      this.authService.passport.supplier.Mapping.supplierEmail
    console.debug(`Jump to TSM url: ${redirectUrl}`);
    return redirectUrl;
  }
  private isComplete() {
    return this.profileService.checkSupplierIsCompleteForTsm();
  }

  public async SetContextValue(Context: DynamicContext) {
    await this.SetBuyerModel();
    Context.set("isMET", this.isMet());
    Context.set("isAsen", this.isASEAN());
    Context.set("isANZ", this.isANZ());
    Context.set("isGreaterChina", this.isGreaterChina());
    Context.set("isPH", this.isPH());
    Context.set("isSG", this.isSG());
    Context.set("isCEG", this.isCEG());
    Context.set("isICEG", this.isICEG());
    Context.set("isSEA", this.isSEA());
    Context.set("isASG", this.isASG());
    Context.set("isUKIA", this.isUKIA());
    Context.set("isTsmId", this.isTsmId());
    Context.set("TsmId", this.TsmId());
    Context.set("isNordics", this.isNordics());
    Context.set("isNA", this.isNA());
    Context.set("parentModel", this.profileService.supplierModel);
    Context.set("isIN", this.isIN());
    // Context.set("isAfrica", this.isAfrica());
    Context.set("TsmLink", this.TsmLink());
    Context.set("isComplete", this.isComplete());
    Context.set("isIberia", this.isIberia());
    Context.set("isGallia", this.isGallia());
    Context.set("isRollout", this.isRollout());
    Context.set("isJapan", this.isJapan());
    Context.set("isPaymentModeCreditCard", this.isPaymentModeCreditCard());
    // setting isNewSupplier in localStorage as isNewSupplier is coming as undefined on Refresh of page
    // This can be used as temporary fix, but actual root cause is still needs to be find
    let isNewSupplierLocal;
    if(this.isNewSupplier !== undefined){
    isNewSupplierLocal = localStorage.getItem("isNewSupplier") === "true" ? true : false
    } else{
      localStorage.setItem("isNewSupplier", "undefined")
    }
    Context.set("isNewSupplier", isNewSupplierLocal || this.isNewSupplier());
    Context.set("isIndividual", this.isIndividual());
    Context.set("isCam", this.isCam());
    Context.set("supplierCountryList", this.supplierCountryList())

    console.log(`debugging 402654`, Context);
    console.log(`debugging 402654`, Context.getValue("parentModel").RequestForm?.RiskAssessmentInfo?.BI_BDA_1);
  }
  }
