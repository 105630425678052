import { SupplierMappingDTO } from "./mapping";
import { RequestForm, createRequestForm } from "./requestForm";

export interface SupplierModelForSaveDraft {
  RequestForm?: any;
  Mapping?: SupplierMappingDTO;
  ChangeHistory?:any
 
}

 export function createSupplierModelForSaveDraft(): SupplierModelForSaveDraft {
  return {
    RequestForm:{},
    Mapping: {},
    ChangeHistory:{}
  };
}

