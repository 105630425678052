import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  HostListener,
} from "@angular/core";
import { AuthService } from "src/app/services/auth/auth.service";
import { BuyerService } from "src/app/services/buyer.service";
import { BuyerUser } from "src/app/interfaces/buyer";
import {
  DialogService,
  ButtonCommands,
  DialogType,
} from "src/app/services/dialog.service";
import { ThemePalette } from "@angular/material/core";
import { Router } from "@angular/router";
import { ManagementService } from "../../../services/management.service";
import { LoadingService } from "src/app/services/loading.service";

@Component({
  selector: "app-users",
  templateUrl: "./users.component.html",
  styleUrls: ["./users.component.sass"],
})
export class UsersComponent implements OnInit {
  users: Array<BuyerUser> = [];
  roles = [];
  color: ThemePalette = "primary";

  currentUser;
  itemsPerPage = 12;
  currentPage = 1;

  get searchCondition() {
    return this.managementService.searchCondition;
  }

  currentStatus = "all";

  @ViewChild("menuDrop") menuPanel: ElementRef;
  @ViewChild("menuButton") menuButton: ElementRef;

  roleName(roleCode): string {
    const role: any = this.roles.find((r: any) => r.RoleCode === roleCode);
    return role.RoleName;
  }

  get userList() {
    let users = this.users;
    if (this.searchCondition && this.searchCondition !== "") {
      users = users.filter((u) => u.Name.includes(this.searchCondition));
    }

    if (this.currentStatus === "Active") {
      users = users.filter((u) => u.Enabled);
    }
    if (this.currentStatus === "Inactive") {
      users = users.filter((u) => !u.Enabled);
    }
    return users;
  }

  constructor(
    private router: Router,
    private authService: AuthService,
    private buyerService: BuyerService,
    private managementService: ManagementService,
    private dialogService: DialogService,
    public loadingService: LoadingService
  ) {}

  ngOnInit() {
    this.loadingService.showLoading();
    this.managementService.title = "User Management";
    this.managementService.searchCondition = "";
    this.loadData();
  }

  show(user) {
    this.currentUser = user;
    this.currentUser.showMenu = true;
  }

  @HostListener("document:click", ["$event"])
  documentClick(evt) {
    if (this.menuPanel) {
      if (
        this.menuButton &&
        this.menuButton.nativeElement.contains(event.target)
      ) {
        return;
      }
      this.currentUser.showMenu = false;
    }
  }

  loadData() {
    this.buyerService.buyerUsers().then((data: any) => {
      this.users = data.data.buyerUsers;
      this.roles = data.data.buyerRoles;

      setTimeout(() => {
        const usersdiv = document.getElementsByClassName("my-pagination")[0];
        if (usersdiv) {
          const userscontrol = usersdiv.childNodes[0];
          if (userscontrol) {
            const userstemplate = userscontrol.childNodes[0];
            if (userstemplate) {
              const usersul = userstemplate.childNodes[0];
              if (usersul) {
                for (let i = 0; i < usersul.childNodes.length; i++) {
                  let usersli = usersul.childNodes[i];
                  if (usersli.nodeName == 'LI') {
                    (usersli as any).role = "group";
                  }
                }
              }
            }
          }
        }
      });
    });
    this.loadingService.hideLoading();
  }

  view(user: BuyerUser) {
    this.router.navigate([`/buyer/management/user-edit/view/${user.Email}`], {replaceUrl: true});
  }

  edit(user: BuyerUser) {
    this.router.navigate([`/buyer/management/user-edit/edit/${user.Email}`], {replaceUrl: true});
  }

  pageChange(event) {
    this.currentPage = event;
  }
}
