import { HttpService } from "./api/http.service";
import { Injectable } from "@angular/core";
import { timeoutPopup } from "src/app/interfaces/timeoutPopup";
import { popData, popNewData } from "src/app/shared/components/timeout-popup/timeout-popData";

@Injectable()
export class timeoutPopupService {

    getPopData(): timeoutPopup {   
        return popData;
    }

    getNewPopData(): timeoutPopup { 
        return popNewData;
    }
}