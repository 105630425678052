export class CountrySpecificResponse {
  sizeInBytes: number;
  tooltipMsg: string;
  configFound: boolean;
}

export class MaxFileSizeResponse {
  sizeInMB: number;
  sizeInBytes: number;
  configFound: boolean;
}

import { Injectable } from "@angular/core";
import * as util from "util";
import { config } from "src/upload_setting/app.config";
import { CountrySpecificUploaderConfig } from "../dynamic-components/CountrySpecificUploaderConfig";

@Injectable({
  providedIn: "root",
})
export class CountrySpecificUploaderConfigService {
  initConfig(config: any, countryId: number): CountrySpecificResponse {
    const countryConfig: CountrySpecificUploaderConfig =
      config.countrySpecificConfig;
    const maxFileSize: MaxFileSizeResponse = this.findMaxUploadSize(
      countryConfig,
      countryId
    );

    const { sizeInMB, sizeInBytes, configFound } = maxFileSize;
    const tooltipMsg: string = util.format(config.tooltip, sizeInMB);
    const response: CountrySpecificResponse = {
      sizeInBytes,
      tooltipMsg,
      configFound,
    };
    return response;
  }

  /**
   * Find Max Upload Size
   * @param countryConfig
   * @param countryId
   * @returns
   */
  findMaxUploadSize(
    countryConfig: CountrySpecificUploaderConfig,
    countryId: number
  ): MaxFileSizeResponse {
    let sizeInBytes: number;
    let configFound: boolean = false;
    if (countryConfig && countryConfig.country.includes(countryId)) {
      sizeInBytes = countryConfig.maxFileSize;
      configFound = true;
    } else {
      sizeInBytes = config.uploadOptions.maxFileSize;
      configFound = false;
    }
    const sizeInMB: number = this.convertBytesToMB(sizeInBytes);
    return {
      sizeInMB,
      sizeInBytes,
      configFound,
    };
  }

  /**
   * Convert Bytes To MB
   * @param bytes
   * @returns
   */
  convertBytesToMB(bytes): number {
    const sizeInMB: number = bytes / (1024 * 1024);
    return sizeInMB;
  }
}
