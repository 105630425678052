import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { BaseComponent } from "src/app/dynamic-components/components/base/base.component";
import { UntypedFormArray, UntypedFormGroup, UntypedFormControl } from "@angular/forms";
import { OnBoardingService } from "../../../../modules/buyer/services/onboarding.service";
import { BuyerService } from "../../../../services/buyer.service";
import {
  PaymentTerm,
  CompanyCode,
  PaymentTermDTO,
  PaymentTermIterm,
} from "src/app/interfaces/requestForm";
import { DynamicContext } from "../../../../dynamic-components/interfaces/DynamicContext";
import { ComponentConfig } from "../../../../dynamic-components/ComponentConfig";
import { cloneDeep } from "src/app/utils";
import { environment } from "src/environments/environment";
import { LanguageService } from "src/app/dynamic-components/utils/language.service";

@Component({
  selector: "app-collapse-of-country",
  templateUrl: "./collapse-of-country.component.html",
  styleUrls: ["./collapse-of-country.component.sass"],
  host: {
    "[style.width]": "config.width",
  },
})
export class CollapseOfCountryComponent
  extends BaseComponent
  implements OnInit
{
  showTooltip: boolean;
  companycode: CompanyCode;
  paymentTermListDTO: Array<PaymentTermDTO>;
  paymentTermListVO = [];
  @ViewChild("estimateSpendTooltip") estimateSpendTooltip: ElementRef;
  constructor(
    private onBoardingService: OnBoardingService,
    private languageService: LanguageService
  ) {
    super();
  }
  async ngOnInit() {
    this.companycode = new CompanyCode();
    this.companycode.companyCode =
      this.context.getValue(
        "parentModel"
      ).RequestForm.RequestorDetailsInfo.CompanyCode;
    this.paymentTermListDTO =
      this.context.getValue("standardPaymentTerms") || [];
    this.paymentTermListDTO.forEach((paymentTermDTO) => {
      const formGroup = new UntypedFormGroup({});
      const countryControl = new UntypedFormControl();
      countryControl.patchValue(paymentTermDTO.Country, {
        onlySelf: true,
        emitEvent: true,
      });
      formGroup.addControl("Country", countryControl);
      const companyCodeControl = new UntypedFormControl();
      companyCodeControl.patchValue(paymentTermDTO.CompanyCode, {
        onlySelf: true,
        emitEvent: true,
      });
      formGroup.addControl("CompanyCode", companyCodeControl);
      const id = new UntypedFormControl();
      id.patchValue(paymentTermDTO.ID, { onlySelf: true, emitEvent: true });
      formGroup.addControl("PaymentTermId", id);
      this.paymentTermListFormArray.push(formGroup);
    });
    const countryPaymenTermList = [];
    this.paymentTermListDTO.forEach((paymentTermDTO, i) => {
      paymentTermDTO.formGroup = this.paymentTermListFormArray.controls[i];
      const countryPaymenTerm = {
        country: paymentTermDTO.Country,
        countryDisplayName: this.getPaymenttermCountryName(
          paymentTermDTO.PaymentTermCountryKey,
          paymentTermDTO.Country
        ),
        companyCodepaymentTermList: [],
      };
      const context = new DynamicContext();
      context.parent = this.context;
      const paymentTermVO = {
        paymentTerm: {
          TimeLimit: this.paymentTermList
            ? this.paymentTermList.filter(
                (d) => d.CompanyCode == paymentTermDTO.CompanyCode
              ).length === 0
              ? paymentTermDTO.PaymentTerm
              : this.paymentTermList.filter(
                  (d) => d.CompanyCode === paymentTermDTO.CompanyCode
                )[0].TimeLimit
            : paymentTermDTO.PaymentTerm,
          TermPayment: this.paymentTermList
            ? this.paymentTermList.filter(
                (d) => d.CompanyCode == paymentTermDTO.CompanyCode
              ).length == 0
              ? undefined
              : this.paymentTermList.filter(
                  (d) => d.CompanyCode == paymentTermDTO.CompanyCode
                )[0].TermPayment
            : undefined,
          HasNonStandardPaymentTerms: this.paymentTermList
            ? this.paymentTermList.filter(
                (d) => d.CompanyCode == paymentTermDTO.CompanyCode
              ).length == 0
              ? undefined
              : this.paymentTermList.filter(
                  (d) => d.CompanyCode == paymentTermDTO.CompanyCode
                )[0].HasNonStandardPaymentTerms
            : undefined,
          AgreementAttachments: this.paymentTermList
            ? this.paymentTermList.filter(
                (d) => d.CompanyCode == paymentTermDTO.CompanyCode
              ).length == 0
              ? undefined
              : this.paymentTermList.filter(
                  (d) => d.CompanyCode == paymentTermDTO.CompanyCode
                )[0].AgreementAttachments
            : undefined,
          ApproveAttachments: this.paymentTermList
            ? this.paymentTermList.filter(
                (d) => d.CompanyCode == paymentTermDTO.CompanyCode
              ).length == 0
              ? undefined
              : this.paymentTermList.filter(
                  (d) => d.CompanyCode == paymentTermDTO.CompanyCode
                )[0].ApproveAttachments
            : undefined,
          ProcurementApproval: this.paymentTermList
            ? this.paymentTermList.filter(
                (d) => d.CompanyCode == paymentTermDTO.CompanyCode
              ).length == 0
              ? undefined
              : this.paymentTermList.filter(
                  (d) => d.CompanyCode == paymentTermDTO.CompanyCode
                )[0].ProcurementApproval
            : undefined,
          LanguageKey: this.paymentTermList
            ? this.paymentTermList.filter(
                (d) => d.CompanyCode == paymentTermDTO.CompanyCode
              ).length == 0
              ? undefined
              : this.paymentTermList.filter(
                  (d) => d.CompanyCode == paymentTermDTO.CompanyCode
                )[0].LanguageKey
            : undefined,
        },
        CompanyCode: paymentTermDTO.CompanyCode,
        formGroup: this.paymentTermListFormArray.controls[i],
        context: context,
      };
      countryPaymenTerm.companyCodepaymentTermList.push(paymentTermVO);
      countryPaymenTermList.push(countryPaymenTerm);
    });
    const record = {};
    countryPaymenTermList.forEach((countryPaymenTerm) => {
      if (!record[countryPaymenTerm.country]) {
        this.paymentTermListVO.push(countryPaymenTerm);
        record[countryPaymenTerm.country] = true;
      } else {
        this.paymentTermListVO.forEach((paymentTermVO) => {
          if (paymentTermVO.country === countryPaymenTerm.country) {
            paymentTermVO.companyCodepaymentTermList =
              paymentTermVO.companyCodepaymentTermList.concat(
                countryPaymenTerm.companyCodepaymentTermList
              );
          }
        });
      }
    });
    this.paymentTermListVO.forEach((p) => {
      p["isCountryShow"] = true;
      p.companyCodepaymentTermList.forEach((paymentTermVO) => {
        paymentTermVO["isCompanyCodeShow"] = true;
        paymentTermVO["config"] = cloneDeep(this.config.fieldset);
      });
    });
  }
  get paymentTermList(): Array<PaymentTerm> {
    if (this.onBoardingService.supplierModel) {
      return this.onBoardingService.supplierModel.RequestForm.RequestFormInfo
        .PaymentTermList;
    }
    return this.context.getValue("parentModel").RequestForm.RequestFormInfo
      .PaymentTermList;
  }
  get paymentTermListFormArray(): UntypedFormArray {
    return this.formGroup.get(this.config.name) as UntypedFormArray;
  }

  isView(context: DynamicContext): boolean {
    if (context.parent && context.parent.mode == "view") {
      return true;
    } else {
      return false;
    }
  }

  initViewConfig(
    paymentTerm: PaymentTermIterm,
    config: Array<ComponentConfig>
  ) {
    const conf = new Array<ComponentConfig>();
    config.forEach((p) => {
      if (
        paymentTerm[p.name] instanceof Array &&
        paymentTerm[p.name].length > 0
      ) {
        conf.push(p);
      }
      if (!(paymentTerm[p.name] instanceof Array) && paymentTerm[p.name]) {
        conf.push(p);
      }
    });
    return conf;
  }

  estimateChooseOver(e, estimateSpendTooltip) {
    const TooltipEle = estimateSpendTooltip;
    if (estimateSpendTooltip) {
      if (
        e.clientX < TooltipEle.nativeElement.getBoundingClientRect().left ||
        e.clientX >
          TooltipEle.nativeElement.getBoundingClientRect().left +
            TooltipEle.nativeElement.offsetWidth -
            5 ||
        e.clientY < TooltipEle.nativeElement.getBoundingClientRect().top ||
        e.clientY >
          TooltipEle.nativeElement.getBoundingClientRect().top +
            TooltipEle.nativeElement.offsetHeight -
            5
      ) {
        this.showTooltip = false;
      }
    } else {
      this.showTooltip = false;
    }
  }

  getPaymenttermCountryName(countryKey: string, countryName: string) {
    if (environment.languageSwitch && !!countryKey) {
      return this.languageService.getValue(
        `dropdown.supplier-step2.Country.${countryKey.toUpperCase()}.Text`
      );
    }
    return countryName;
  }
}
