// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.congrats {
  margin: 2rem 0;
  font-size: 1.25rem;
  color: #333;
  font-weight: bolder;
}

.congrats_card {
  margin: 2rem 0;
  width: 33.2vw;
  height: 18vw;
  margin-left: -7.2rem;
}
.congrats_card .left {
  float: left;
  width: 54%;
}
.congrats_card .left .title {
  font-size: 1.5rem;
  padding: 0 0;
  color: #54846f;
}
.congrats_card .left .sub_title {
  font-size: 1rem;
  font-weight: normal;
  margin-top: 1.8rem;
}
.congrats_card .left .reg_date {
  vertical-align: middle;
  margin-top: 1.5rem;
}
.congrats_card .left .reg_date_icon {
  margin-top: -0.3rem;
  color: #54846f;
  margin-left: -0.3rem;
  font-size: 1.3rem;
}
.congrats_card .left .reg_date_span {
  margin-left: 0.5rem;
}
.congrats_card .left .view_details_btn {
  background-color: #ecf7f3;
  border-radius: 2.5rem;
  line-height: 3rem;
  font-size: 1.1rem;
  width: 11rem;
  text-align: center;
  margin-top: 1.5rem;
  color: #5a9d82;
  font-weight: 600;
}

.right_dev {
  text-align: center;
}

.fingerprint_image {
  width: 9rem;
  margin-top: 3.3rem;
}

.right_dev_all {
  height: 18vw;
  background-color: #ecf7f3;
  border-bottom-right-radius: 2rem;
  float: left;
  width: 46%;
}

.right_subtitle {
  font-weight: bolder;
}
.right_subtitle i {
  font-size: 1.2rem;
  margin-top: -0.2rem;
}
.right_subtitle span {
  margin-left: 0.3rem;
}

.right_sub_content {
  margin-left: 1.6rem;
  margin-top: 0.5rem;
}

.completed_status-value {
  background: #55bded;
  padding: 0 1.5rem;
  color: #fff;
  background-color: #6bc8a5;
}

.status-border {
  border-top: 2.25rem solid #5a9d82 !important;
}

.subtitle_color {
  color: #4E4E87;
}

.notice-card {
  background: #fcfcfc;
  opacity: 0.95;
  height: 240px;
  margin: 1.6rem;
  border-radius: 2rem;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
