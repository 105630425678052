import {
  AfterViewInit,
  Component,
  OnInit,
  Input,
  HostListener,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
} from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, of, Subject } from "rxjs";
import { debounceTime, switchMap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { PeoplePickerConfig } from "../../../../interfaces/peoplePickerConfig";
import { BuyerService } from "src/app/services/buyer.service";
import { LaunchDarklyService } from "src/app/services/launch-darkly.service";

@Component({
  selector: "app-custom-peoplepicker",
  templateUrl: "./peoplepicker.component.html",
  styleUrls: ["./peoplepicker.component.sass"],
  host: {
    "[style.width]": "config.width",
  },
})
export class PeoplepickerComponent implements OnInit {
  showTooltip = false;
  selectedText = "";
  selectedValue = "";
  peoplePickerList: Array<{ [key: string]: string }> = [];
  isShowPopup = false;
  isFocus = false;
  isLoading = false;
  isNewVersion:boolean=true;
  private subject = new Subject();
  private $observable: Observable<any>;
  @ViewChild("inputPanel") inputPanel: ElementRef;
  @ViewChild("dropPanel") dropPanel: ElementRef;
  @Input() config: PeoplePickerConfig;
  @Input() userEmail: string;
  @Input() invalid: boolean;
  @Output() modelValueChangeEvent: EventEmitter<string>;
  @Output() blurEvent: EventEmitter<boolean>;

  constructor(private http: HttpClient, private buyerService: BuyerService, private launchDarklyService:LaunchDarklyService) {
    this.modelValueChangeEvent = new EventEmitter();
    this.blurEvent = new EventEmitter();
    this.launchDarklyService.ldChange.subscribe(any => {
      console.log("Trigger refresh data")
      this.refreshToggle()
    })
  }

  refreshToggle():void{
    this.isNewVersion=this.launchDarklyService.getToggle(LaunchDarklyService.LD_ISNEWVERSION)==="true";
  }

  ngOnInit() {
    this.refreshToggle();
    this.selectedText = this.userEmail;

    this.$observable = this.subject.asObservable().pipe(
      debounceTime(500),
      switchMap((inputedValue) => {
        if(this.isNewVersion) {
          return this.buyerService.searchPeopleUsingGraphAPI(inputedValue);
        }
        else {
          return this.buyerService.existingsearchPeopleUsingGraphAPI(inputedValue);
        } 
      })
    );

    const peopeickerSubsribe = this.$observable.subscribe({
      next: (result: Array<{ [key: string]: string }>) => {
        this.peoplePickerList = [];
        this.isLoading = false;
        for (const obj of result) {
          for (const key in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, key)) {
              this.peoplePickerList.push({
                enterpriseId: key,
                displayName: obj[key],
                email: `${key}@accenture.com`,
              });
            }
          }
        }
      },
      error: (err) => {
        console.error(err);
        this.isLoading = false;
      },
    });
  }

  getPeoplePicker(inputedValue) {
    this.modelValueChangeEvent.emit("");
    if (
      this.config["showType"] === "email" &&
      inputedValue &&
      inputedValue.indexOf("@") > -1
    ) {
      const domain = inputedValue.split("@")[1];
      if ("accenture.com".indexOf(domain) > -1) {
        inputedValue = inputedValue.split("@")[0];
      }
    }
    if (!inputedValue) {
      this.peoplePickerList = [];
      this.isLoading = false;
      return;
    }
    this.peoplePickerList = [];
    this.isLoading = true;
    this.subject.next(inputedValue);
  }

  selectOne(eid) {
    this.setDisplayValueOnType(eid);
    this.peoplePickerList = [];
    this.selectedValue = eid;
    this.isShowPopup = false;
    this.blurEvent.emit(true);
  }

  setDisplayValueOnType(eid) {
    if (eid) {
      this.selectedText =
        this.config["showType"] === "email" ? eid + "@accenture.com" : eid;
      this.modelValueChangeEvent.emit(this.selectedText);
    }
  }

  estimateChooseOver(e, estimateSpendTooltip) {
    const TooltipEle = estimateSpendTooltip;
    if (estimateSpendTooltip) {
      if (
        e.clientX < TooltipEle.nativeElement.getBoundingClientRect().left ||
        e.clientX >
          TooltipEle.nativeElement.getBoundingClientRect().left +
            TooltipEle.nativeElement.offsetWidth -
            5 ||
        e.clientY < TooltipEle.nativeElement.getBoundingClientRect().top ||
        e.clientY >
          TooltipEle.nativeElement.getBoundingClientRect().top +
            TooltipEle.nativeElement.offsetHeight -
            5
      ) {
        this.showTooltip = false;
      }
    } else {
      this.showTooltip = false;
    }
  }

  @HostListener("document:click", ["$event"])
  documentClick(event) {
    if (this.inputPanel) {
      if (this.inputPanel.nativeElement.contains(event.target)) {
        this.isFocus = true;
      } else {
        this.isFocus = false;
      }

      if (this.dropPanel) {
        if (
          this.inputPanel.nativeElement.contains(event.target) ||
          this.dropPanel.nativeElement.contains(event.target)
        ) {
          return;
        } else {
          this.isShowPopup = false;
        }
      } else if (this.inputPanel.nativeElement.contains(event.target)) {
        this.isShowPopup = true;
      } else {
        this.isShowPopup = false;
      }
    }
  }

  controlBlur() {
    if (
      this.selectedText &&
      !(
        this.selectedText.split("@")[0] === this.selectedValue &&
        this.selectedText.split("@")[1] === "accenture.com"
      )
    ) {
      this.blurEvent.emit(true);
    }
  }
}
