import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { FormControl } from "@angular/forms";
import * as moment from "moment";
import { MAT_DATE_FORMATS } from "@angular/material/core";
export const DATE_FORMAT = {
  parse: {
    dateInput: "DD/MM/YYYY",
  },
  display: {
    dateInput: "DD/MM/YYYY",
    monthYearLabel: "MMM YYYY",
    // dateA11yLabel: 'LL',
    // monthYearA11yLabel: 'MMMM YYYY',
    // popupHeaderDateLabel: 'ddd, DD MMM'
  },
};
@Component({
  selector: "app-datepicker",
  templateUrl: "./datepicker.component.html",
  styleUrls: ["./datepicker.component.sass"],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: DATE_FORMAT }],
})
export class DatepickerComponent implements OnInit {
  @Input() bindingValue: any;
  @Input() disabled = false;
  @Input() setDate: string;
  @Output() bindingValueChange;

  constructor() {
    this.bindingValueChange = new EventEmitter();
  }

  ngOnInit() {}

  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    const tmpDate: any = moment(event.value).utc().toDate();
    // this.contentChange.emit(event.value)
    this.bindingValueChange.emit(tmpDate);
  }
}
