import { Component, OnInit } from "@angular/core";
import { FileUploader, FileItem, FileUploaderOptions } from "ng2-file-upload";
import { BuyerService } from "src/app/services/buyer.service";
import {
  DialogService,
  DialogType,
  ButtonCommands,
} from "src/app/services/dialog.service";
import { SupplierHiperosLogMessage } from "src/app/interfaces/supplierHiperosLogMessage";
import { LanguageService } from "../../../../dynamic-components/utils/language.service";
import { environment } from "src/environments/environment";
import { Router } from "@angular/router";

@Component({
  selector: "app-hiperos",
  templateUrl: "./hiperos.component.html",
  styleUrls: ["./hiperos.component.sass"],
})
export class HiperosComponent implements OnInit {
  uploader: FileUploader;
  uploading = false;
  uploadMessage: string;
  fileName: string;
  showProgress = false;
  retryItems = [];
  resending = false;
  showAddForm = false;
  isValidated = false;
  hiperosLog: SupplierHiperosLogMessage = {};
  redClass = false;

  itemsPerPage = 5
  currentPage = 1
  searchResult
  supplierCode: ""
  httpClient: any


  constructor(private buyerService: BuyerService,
    private dialogService: DialogService,
    private languageService: LanguageService,
    private router: Router,
  ) {
    this.uploader = new FileUploader({
      allowedMimeType: [
        'application',
        'text/csv',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ],
      allowedFileType: [
        'xls',
        'text/csv',
        'application'
      ],
      maxFileSize: 10485760,
      queueLimit: 1,
      autoUpload: false
    } as FileUploaderOptions);
  }

  async ngOnInit() {
    await this.searchRetryItems()

    this.uploader.onAfterAddingAll = async (newFiles: FileItem[]) => {
      try {
        this.redClass = false;
        this.uploader.clearQueue();
        console.log('after adding files')
        this.showProgress = true;
        this.uploadMessage = "uploading...";
        this.fileName = newFiles[0]._file.name;
        const result: any = await this.buyerService.importRiskAssessment(
          newFiles
        );
        if (result.data.error.message) {
          this.uploadMessage = result.data.error.message;
          return;
        }
        this.uploadMessage = `${result.data.rowCount} records have been successfully imported`
        await this.searchRetryItems()
        this.uploading = false;
        this.currentPage = 1
      } catch (e) {
        // console.log(e);
        this.redClass = true;
        this.uploadMessage = `Upload failed, the file you tried to upload failed format validation. (${e})`;
      }
    }
    this.uploader.onBuildItemForm = (a, b) => {
      console.log('onBuildItemForm')
      console.log(a)
    }
    this.uploader.onAfterAddingFile = (a) => {
      console.log('onAfterAddingFile')
      console.log(a)
    }
    this.uploader.onWhenAddingFileFailed = (a, b, c) => {
      console.log('onWhenAddingFileFailed')
      console.log(a)
      console.log(b)
      console.log(c)
    }
    this.uploader.onBeforeUploadItem = (a) => {
      this.uploading = true;
      console.log('onBeforeUploadItem')
      console.log(a)
    }
    this.uploader.onProgressItem = (a, b) => {
      console.log('onProgressItem')
      console.log(a)
      console.log(b)
    }
    this.uploader.onErrorItem = (a, b, c, d) => {
      console.log('onErrorItem')
      console.log(a)
      console.log(b)
      console.log(c)
      console.log(d)
    }
    this.uploader.onSuccessItem = (a, b, c, d) => {
      console.log('onSuccessItem')
      console.log(a)
      console.log(b)
      console.log(c)
      console.log(d)
    }
    this.uploader.onCompleteItem = (a, b) => {
      console.log('complete')
    }
  }

  backToDashboard() {
    this.router.navigate([`buyer`]);
  }

  async resendRiskAssessment(retryItem: any) {
    if (this.resending) {
      return;
    }
    this.resending = true;
    this.buyerService
      .resendRiskAssessment(retryItem.SupplierCode)
      .then((result: any) => {
        if (result.data) {
          if (result.data.error) {
            this.dialogService.dialog(
              result.data.error.message,
              ButtonCommands.Ok
            );
          } else {
            Object.assign(retryItem, result.data);
            retryItem.LastRetryTimeMessage = this.formatDate(
              new Date(retryItem.LastRetryTime)
            );
          }
        }
        this.resending = false;

        setTimeout(() => {
          const hiperosdiv = document.getElementsByClassName("my-pagination")[0];
          if (hiperosdiv) {
            const hiperoscontrol = hiperosdiv.childNodes[0];
            if (hiperoscontrol) {
              const hiperostemplate = hiperoscontrol.childNodes[0];
              if (hiperostemplate) {
                const hiperosul = hiperostemplate.childNodes[0];
                if (hiperosul) {
                  for (let i = 0; i < hiperosul.childNodes.length; i++) {
                    let hiperosli = hiperosul.childNodes[i];
                    if (hiperosli.nodeName == 'LI') {
                      (hiperosli as any).role = "group";
                    }
                  }
                }
              }
            }
          }
        });
      })
      .catch((error) => {
        this.resending = false;
        this.dialogService.dialog(error, ButtonCommands.Ok);
      });
  }

  pageChange(event) {
    this.currentPage = event;
  }

  async searchRetryItems() {
    const retryItemsResp: any = await this.buyerService.listRetryItems();
    retryItemsResp.data.map((item: any) => {
      item.LastRetryTimeMessage = this.formatDate(new Date(item.LastRetryTime));
    });
    this.retryItems = retryItemsResp.data;
  }

  formatDate(LastRetryTime: Date) {
    const cd = new Date(LastRetryTime);
    const day = cd.getDate() > 9 ? cd.getDate() : "0" + cd.getDate();
    const year = cd.getFullYear();
    const month =
      cd.getMonth() >= 9 ? cd.getMonth() + 1 : "0" + (cd.getMonth() + 1);
    const hour = cd.getHours() > 9 ? cd.getHours() : "0" + cd.getHours();
    const minute =
      cd.getMinutes() > 9 ? cd.getMinutes() : "0" + cd.getMinutes();
    const seconds =
      cd.getSeconds() > 9 ? cd.getSeconds() : "0" + cd.getSeconds();
    return (
      day + "-" + month + "-" + year + " " + hour + ":" + minute + ":" + seconds
    );
  }

  validate() {
    this.isValidated = true;
    if (!this.hiperosLog.SupplierCode || !this.hiperosLog.SupplierName) {
      return false;
    }
    return true;
  }

  addLog() {
    if (!this.showAddForm) {
      this.showAddForm = true;
    } else {
      return;
    }
  }

  async submit() {
    if (!this.validate()) {
      return;
    }
    const result: any = await this.buyerService.addRiskAssessmentLog(
      this.hiperosLog
    );
    if (result && result.isSuccess) {
      if (environment.languageSwitch && environment.role == "supplier") {
        await this.dialogService.dialog(
          this.languageService.getValue(
            "static.system.components.hiperos.Successfully"
          ),
          ButtonCommands.Close,
          DialogType.success,
          this.languageService.getValue(
            "static.system.components.alert.Success"
          )
        );
      } else {
        await this.dialogService.dialog(
          "You have added successfully.",
          ButtonCommands.Close,
          DialogType.success,
          "Success"
        );
      }
      this.showAddForm = false;
      this.searchRetryItems();
    } else {
      if (environment.languageSwitch && environment.role == "supplier") {
        await this.dialogService.dialog(
          this.languageService.getValue(
            "static.system.components.hiperos.Failed"
          ),
          ButtonCommands.Close,
          DialogType.warning,
          this.languageService.getValue(
            "static.system.components.invitationStatus.fail"
          )
        );
      } else {
        await this.dialogService.dialog(
          "Log added failed, please try again.",
          ButtonCommands.Close,
          DialogType.warning,
          "Fail"
        );
      }
    }
  }

  public async search() {
    this.searchResult = null
    // console.log(this.supplierCode)
    if (!this.supplierCode) {
      await this.dialogService.dialog('The search value cannot be empty', ButtonCommands.Ok)
      return
    }
    const retryResults: any = await this.buyerService.getRiskAssessmentBysupplierCode(this.supplierCode)
    if (!retryResults.data) {
      await this.dialogService.dialog('No data', ButtonCommands.Ok)
      return
    }
    retryResults.data.LastRetryTimeMessage = this.formatDate(new Date(retryResults.data.LastRetryTime))
    retryResults.data.SupplierResponse = JSON.parse(retryResults.data.SupplierResponse).statusMessage
    retryResults.data.RelationshipResponse = JSON.parse(retryResults.data.RelationshipResponse).statusMessage
    this.searchResult = retryResults.data
    // console.log(this.searchResult)
  }

}
