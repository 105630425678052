// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.upload-container {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  background: linear-gradient(to right, #048C8A, #006CAF);
}

.upload-table {
  background: #ffffff;
  border-radius: 30px 0px 30px 0px;
  padding: 30px 30px 60px 30px;
  width: 100%;
  line-height: 3rem;
  font-weight: bold;
}

table {
  width: 100%;
}
table tr {
  border-bottom: 1px solid darkgray;
  width: 100%;
}
table tr td {
  width: 20%;
}
table tr td:nth-child(2) {
  width: 30%;
}
table tr td:nth-child(3) {
  width: 30%;
}
table tr:nth-child(1) {
  border-bottom: none;
}

.page-navigation {
  font-size: 12px;
  font-weight: initial;
  margin-left: -12px;
  margin-bottom: 14px;
  cursor: pointer;
}

.link-mare-panel {
  position: absolute;
  height: auto;
  background: #fff;
  color: #000000;
  border-radius: 5px;
  padding: 5px 0;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.12), 0 3px 6px rgba(0, 0, 0, 0.24);
  outline: 10;
  z-index: 10000;
  margin-left: -170px;
}

ul.link-mare-item {
  width: 100%;
  list-style: none;
  padding-left: 20px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
