import { Component, OnInit } from "@angular/core";
import { DashBoardService } from "../../../services/dashboard.service";
import { OnBoardingService } from "../../../services/onboarding.service";

@Component({
  selector: "app-history-header",
  templateUrl: "./history-header.component.html",
  styleUrls: ["./history-header.component.sass"],
})
export class HistoryHeaderComponent implements OnInit {
  isDisable = false;
  title = "";
  isFromTSM = false;
  TsmId = "";
  isHideDshbdHelp = false;
  constructor(
    private dashBoardService: DashBoardService,
    private onBoardingService: OnBoardingService
  ) {}

  get supplierCode() {
    return this.dashBoardService.supplierCode;
  }

  ngOnInit() {
    this.title = "Supplier Transactions";
    this.isFromTSM = this.onBoardingService.isFromTsm();
    this.TsmId = this.onBoardingService.TsmId();
    if (this.dashBoardService.isSearchInTsm) {
      this.isFromTSM = true;
      // this.TsmId = this.dashBoardService.tsmKey1
      // hide the help and dashboard icon at nav-side
      this.isHideDshbdHelp = true;
    }
  }

  cancelEvent(event) {
    this.isDisable = false;
  }

  backTo() {
    this.isDisable = true;
  }
}
