import { Component, AfterViewInit, Inject, ElementRef } from "@angular/core";
import { AppConfigService } from "../core/services/app-config.service";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { DOCUMENT } from "@angular/common";
import { environment } from "../../environments/environment";
@Component({
  selector: "notificationframework-modal",
  template: `
    <div
      *ngIf="enable"
      id="acn-modal"
      [attr.data-widgetid]="component['WIDGET_ID']"
      [attr.data-appid]="config['APP_ID']"
    ></div>
    <link *ngIf="enable" rel="stylesheet" [href]="CSS_SCRIPT" />
  `,
  styles: [],
})
export class ModalComponent implements AfterViewInit {
  CSS_SCRIPT: SafeResourceUrl;
  config;
  component: Record<string, string>;
  enable;
  constructor(
    configService: AppConfigService,
    private sanitizer: DomSanitizer,
    @Inject(DOCUMENT) private document: Document,
    private elementRef: ElementRef
  ) {
    this.config = (configService.config as Record<string, unknown>)[
      "notification"
    ] as Record<string, unknown>;
    this.enable = environment.providers !== "mock" && environment.notification_switch;
    this.component = this.config["modal"] as Record<string, string>;
    this.CSS_SCRIPT = sanitizer.bypassSecurityTrustResourceUrl(
      this.component["CSS_SCRIPT"] as string
    );
  }

  ngAfterViewInit(): void {
    if (this.enable) {
      const nfs = this.document.createElement("script");
      nfs.type = "text/javascript";
      nfs.src = this.component["SRC_SCRIPT"] as string;
      this.elementRef.nativeElement.appendChild(nfs);
    }
  }
}
