// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.file-upload {
  border: dashed 1px rgba(31, 31, 53, 0.3);
  height: 4rem;
}

.nv-file-over {
  border: dashed 1px red;
}

.uploader {
  border-radius: 0.3rem;
  display: -webkit-box;
  -webkit-box-orient: horizontal;
  -webkit-box-pack: center;
  -webkit-box-align: center;
  cursor: pointer;
}

.file-img {
  width: 20px;
  height: 25px;
  float: left;
}

.progress {
  width: 8%;
  margin: auto 0;
  overflow: hidden;
  border-radius: 4px;
  text-align: -webkit-center;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}

.progress-bar {
  float: left;
  width: 0;
  height: 100%;
  font-size: 12px;
  color: #fff;
  text-align: center;
  background-color: #337ab7;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  transition: width 10s ease;
}

.file-message-con {
  text-align: -webkit-center;
  color: #4e4e87;
}

.file-message {
  padding-top: 5px;
  padding-left: 10px;
}

.material-icons {
  color: #4e4e87;
}

.link-mare-panel {
  position: absolute;
  height: auto;
  width: 350px;
  background: #fff;
  color: #000;
  border-radius: 5px;
  padding: 5px 0;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.12), 0 3px 6px rgba(0, 0, 0, 0.24);
  outline: 10;
  z-index: 9999;
}

ul.link-mare-item {
  width: 100%;
  list-style: none;
  padding-left: 20px;
}

ul.link-mare-item li {
  float: left;
  width: 100%;
  height: 30px;
  line-height: 30px;
  color: #666;
  cursor: pointer;
}

ul.link-mare-item li i {
  font-size: 1.2rem;
  margin-right: 7px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
