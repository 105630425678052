import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
  ElementRef,
} from "@angular/core";
import { CommonService } from "../../../../services/common.service";
import { BuyerChangeRequestItem } from "../../../../interfaces/buyer";
import { PaymentTermComponent } from "./payment-term/payment-term.component";
import {
  DialogService,
  DialogType,
  ButtonItem,
  ButtonCommands,
} from "../../../../services/dialog.service";
import { resolve } from "q";
import { CompanyComponent } from "./company/company.component";
import { BuyerChangeRequestDTO } from "../../../../interfaces/changeRequest";
import { OnBoardingService } from "../../services/onboarding.service";
import { LoadingUrlMapping, SpendCategoryGroup } from "src/app/interfaces/mapping";
import { environment } from "src/environments/environment";
import { LoadingService } from "src/app/services/loading.service";
import { DictionaryService } from "src/app/services/dictionary.service";
import { AmendSpendCommodityCategoryGroupComponent } from "./amend-spend-commodity-category-group/amend-spend-commodity-category-group.component";
import { AuthService } from "src/app/services/auth/auth.service";

@Component({
  selector: "app-change-request-dialog",
  templateUrl: "./change-request-dialog.component.html",
  styleUrls: ["./change-request-dialog.component.sass"],
})
export class ChangeRequestDialogComponent implements OnInit {
  @Input() isShowDialog: boolean;
  @Output() hiddenCRDialog: EventEmitter<boolean>;
  @Output() checkCRResult: EventEmitter<boolean>;
  @ViewChild("childComponentPaymentTerm")
  childComponentPaymentTerm: PaymentTermComponent;
  @ViewChild("childComponentCompany")
  childComponentCompany: CompanyComponent;
  @ViewChild("acceptScroll") acceptScroll: ElementRef;
  @ViewChild("childComponentAmendSpendCommodityCategoryGroup")
  childComponentAmendSpendCommodityCategoryGroup: AmendSpendCommodityCategoryGroupComponent;
  rand =Math.round((Math.random()*10000)).toString();
  changeRequestItems: any = [];
  currentSelect = "";
  changeRequestKeys: any;
  isValidated: boolean;

  isSubmitDisabled = false;

  OK: ButtonItem = {
    icon: "check",
    label: "OK",
    action: () => {
      resolve("ok");
    },
  };
  Close: ButtonItem = {
    label: "Close",
    action: () => {
      resolve("close");
    },
  };

  constructor(
    private commonService: CommonService,
    private dictionaryService: DictionaryService,
    private dialogService: DialogService,
    private loadingService: LoadingService,
    private onboardingService: OnBoardingService,
    private authService: AuthService
  ) {
    this.hiddenCRDialog = new EventEmitter();
    this.checkCRResult = new EventEmitter();
  }

  async ngOnInit() {
    this.isValidated = false;
    const dics = await this.dictionaryService.getDictionary(
      "Change Request Items"
    );
    if (dics) {
      this.changeRequestItems = dics;
    }
    if (
      this.authService.passport.buyer.RoleCode !== "S_CM" &&
      this.authService.passport.buyer.RoleCode !== "buyerAdmin" &&
      this.authService.passport.buyer.RoleCode !== "systemAdmin"
    ) {
      this.changeRequestItems = this.changeRequestItems.filter(
        (p) =>
          p.Code !== BuyerChangeRequestItem.AmendSpendCommodityCategoryGroup
      );
    }
    this.changeRequestKeys = BuyerChangeRequestItem;
  }

  validate(): boolean {
    this.isValidated = true;
    if (this.currentSelect === "") {
      return false;
    }
    return true;
  }

  closeDialog() {
    this.isValidated = false;
    this.isShowDialog = false;
    this.hiddenCRDialog.emit(this.isShowDialog);
    this.currentSelect = "";
  }

  async submit() {
    let isSuccess = false;
    let isValidate = true;
    this.isSubmitDisabled = true;
    // Exclusive Check
    if (!this.validate()) {
      this.isSubmitDisabled = false;
      return false;
    }
    const hasOpenedCR = await this.isRequestChangeOpen();
    if (hasOpenedCR) {
      this.isSubmitDisabled = false;
      this.closeDialog();
      this.dialogService.confirm(
        DialogType.warning,
        "There is one change request of this supplier still ongoing and you will not be allowed to submit the change request until the outstanding one completed.",
        [this.Close],
        "Failed"
      );
      return;
    }
    switch (this.currentSelect) {
      case BuyerChangeRequestItem.PaymentTerm:
        await this.childComponentPaymentTerm.submit((validate, success) => {
          isValidate = validate;
          isSuccess = success;
        });
        break;
      case BuyerChangeRequestItem.Company:
        ({ isValidate, isSuccess } = await this.childComponentCompany.submit());
        break;
      case BuyerChangeRequestItem.AmendSpendCommodityCategoryGroup:
        ({ isValidate, isSuccess } =
          await this.childComponentAmendSpendCommodityCategoryGroup.submit());
        break;
    }

    if (!isValidate) {
      this.isSubmitDisabled = false;
      return;
    }
    this.checkCRResult.emit(isSuccess);

    // const isMigrate = !this.onboardingService.supplierModel.Mapping.IsMigrated;
    // if (this.currentSelect == BuyerChangeRequestItem.AmendSpendCommodityCategoryGroup) {
    //   if (this.childComponentAmendSpendCommodityCategoryGroup.dynamicForm.values().SpendCategoryGroup == '5002' || this.childComponentAmendSpendCommodityCategoryGroup.dynamicForm.values().SpendCategoryGroup == '5021') {
    //     if (this.childComponentAmendSpendCommodityCategoryGroup.dynamicForm.values().IndependentContractor == 'N') {
    //       SpendCategoryGroup['5002'] = true
    //       SpendCategoryGroup['5021'] = true
    //     }else {
    //       SpendCategoryGroup['5002'] = false
    //       SpendCategoryGroup['5021'] = false
    //     }
    //   }
    // }
    // if (this.currentSelect == BuyerChangeRequestItem.AmendSpendCommodityCategoryGroup && SpendCategoryGroup[this.childComponentAmendSpendCommodityCategoryGroup.dynamicForm.values().SpendCategoryGroup] && isMigrate) {
    //   return;
    // }else 
    if(isSuccess) {
      this.closeDialog();
      this.dialogService.confirm(
        DialogType.success,
        "Your change request has been successfully submitted.",
        [this.OK],
        "Please Note"
      );
    } else {
      this.closeDialog();
      this.dialogService.confirm(
        DialogType.warning,
        "Submit failed,please try again later.",
        [this.Close],
        "Failed"
      );
    }
    this.isSubmitDisabled = false;
  }

  async isRequestChangeOpen() {
    let hasOpenedCR = false;
    const requestorChangeRequestList: any =
      await this.onboardingService.loadChangeRequestList();
    if (requestorChangeRequestList && requestorChangeRequestList.length !== 0) {
      requestorChangeRequestList.some((item: BuyerChangeRequestDTO) => {
        if (item.openFlag === true) {
          hasOpenedCR = true;
          return;
        }
      });
    }
    return hasOpenedCR;
  }
}
