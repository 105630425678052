import {
  BuyerACLPermissionService,
  BuyerItemPermission,
} from "./buyerACLPermission.service";
import { Injectable } from "@angular/core";
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot } from "@angular/router";
import { BuyerService } from "src/app/services/buyer.service";
import { OnBoardingService } from "./onboarding.service";
import { AuthService } from "../../../services/auth/auth.service";
import { RoleName } from "src/app/interfaces/buyer";

@Injectable()
export class PagePermissionResolver {
  constructor(
    private buyerService: BuyerService,
    private router: Router,
    private onBoardingService: OnBoardingService,
    private authService: AuthService,
    private buyerACLPermissionService: BuyerACLPermissionService
  ) {}

  async resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<any> {
    const supplierCode = route.paramMap.get("supplierCode");
    let roleCode: string = this.authService.passport.buyer.RoleCode;
    let itemsPermission: any;
    this.buyerACLPermissionService.pagePermissions = [];
    this.buyerACLPermissionService.buttonPermissions = [];
    if (!supplierCode) {
      itemsPermission = await this.buyerService.queryBuyerPagesPermission(
        roleCode
      );
    } else if (supplierCode === "0") {
      roleCode = "requestor";
      itemsPermission = await this.buyerService.queryBuyerPagesPermission(
        roleCode
      );
    } else {
      if (roleCode == "buyerAdmin" || roleCode == "systemAdmin") {
        itemsPermission = await this.buyerService.queryBuyerPagesPermission(
          roleCode
        );
      } else if (roleCode == "S_CM") {
        itemsPermission = await this.buyerService.queryBuyerPagesPermission(
          roleCode
        );
      } else {
        this.onBoardingService.supplierCode = supplierCode;
        await this.onBoardingService.loadSupplierRequestForm();
        const requestorUserEmail =
          this.onBoardingService.supplierModel.Mapping.requestor.createdBy;
        const currentUserEmail = this.authService.passport.buyer.Email;
        if (requestorUserEmail === currentUserEmail) {
          roleCode = "requestor";
          itemsPermission = await this.buyerService.queryBuyerPagesPermission(
            roleCode
          );
        } else {
          if (this.authService.passport.buyer.RoleCode === RoleName.Requestor) {
            roleCode = RoleName.OtherRequestor;
          }
          itemsPermission = await this.buyerService.queryBuyerPagesPermission(
            roleCode
          );
        }
      }
    }
    itemsPermission.data.forEach((item: BuyerItemPermission) => {
      if (item.Enabled) {
        if (item.ItemType === "page") {
          this.buyerACLPermissionService.pagePermissions.push(item.ItemName);
        }
        if (item.ItemType === "button") {
          this.buyerACLPermissionService.buttonPermissions.push(item.ItemName);
        }
      }
    });
    this.authService.ACL_Completed.emit(true);
    return [
      this.buyerACLPermissionService.pagePermissions,
      this.buyerACLPermissionService.buttonPermissions,
    ];
  }
}
