// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-top-bar {
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: rgba(255, 255, 255, 0.8);
}
.header-top-bar .logo {
  font-size: 1.85rem;
  color: #333;
}
.header-top-bar .logo img {
  height: 2.5rem;
  margin-right: 20px;
}
.header-top-bar .menu-icon {
  color: #000;
  text-align: right;
}

.skip-link {
  position: absolute;
  top: -10px;
}

a#skip-to-link {
  color: #0066cc !important;
}

.skip-link:focus {
  top: 18px !important;
  width: auto;
  height: auto;
  left: 45% !important;
  color: black;
  font-weight: 600;
}

.skip-link:hover {
  top: 18px !important;
  width: auto;
  left: 45% !important;
  height: auto;
  color: black;
  font-weight: 600;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
