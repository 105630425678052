// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.peoplepicker-drop-panel {
  height: 250px;
  border-left: solid 0.9px #ddd;
  border-bottom: solid 0.9px #ddd;
  border-right: solid 0.9px #ddd;
  padding: 6px;
  overflow-y: auto;
  position: absolute;
  background-color: #fff;
  z-index: 9999;
  width: 100%;
}

.peoplepicker-drop-option {
  cursor: pointer;
  margin-top: 1.5rem;
}
.peoplepicker-drop-option :first-child {
  margin-top: 0;
}

.peoplepicker-drop-option-span {
  display: block;
  padding: 0.5rem 0px 0px 0.5rem;
}

.loading {
  margin-top: 7px;
  display: inline-block;
  animation: rotate 2s linear infinite;
  -webkit-animation: rotate 2s linear infinite;
  -moz-animation: rotate 2s linear infinite;
  -ms-animation: rotate 2s linear infinite;
  -o-animation: rotate 2s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}
.people-div {
  border: solid 1px #414187;
  border-radius: 0.25rem;
  background: #f6f5fa;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
