// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-menu {
  position: relative;
}

.menuPanel {
  position: absolute;
  z-index: 1000;
  font-size: 0.7em;
  right: 0px;
  top: 35px;
  width: 124px;
  cursor: default;
}

.menuContent {
  background: #fff;
  color: #333;
  text-align: left;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.list-group-item {
  display: block;
  font-size: 1rem;
  padding-left: 15px;
  padding-right: 12px;
  padding-bottom: 12px;
  padding-top: 12px;
  border: none;
  font-size: 16px;
  font-weight: bold;
}
.list-group-item:hover {
  background-color: #eee;
  border-color: #eee;
  cursor: pointer;
}
.list-group-item .header {
  font-size: 1.2rem;
  font-weight: 700;
}

.language-box {
  width: 124px;
  border: 1px #a9a9a9 solid;
  border-radius: 5px;
  margin-left: 2.1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.language-box .select-language {
  width: 100px;
  height: 24px;
  color: #000000;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}
.language-box .down-icon {
  width: 24px;
}

.icon-arrow-rotate {
  transform: rotate(180deg);
  transition: transform 0.3s;
}

.icon-arrow-rotate-back {
  transform: rotate(0deg);
  transition: transform 0.3s;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
