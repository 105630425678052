import * as _ from "lodash";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  Validators,
} from "@angular/forms";
import { environment } from "../../../../../environments/environment";
import { NzMessageService } from 'ng-zorro-antd/message'
import { DictionaryService } from "../../services";

@Component({
  templateUrl: "./company.template.html",
  styleUrls: ["./company.style.sass"],
})
export class CompanyComponent implements OnInit {
  public geographicUnitDictionary: any[] = [];
  public companyCountryMappingDictionary: any[] = [];
  public searchGeographicUnitOptions: any[] = [];
  public searchCountryOptions: any[] = [];
  public searchCompanyOptions: any[] = [];
  public searchCompanyForm: UntypedFormGroup;
  public companies: any[] = [];
  public total = 0;
  public pageNumber = 1;
  public pageSize = 10;
  public loading = true;
  public deletedCompanyId = 0;
  public companyModalVisible = false;
  public companyForm: UntypedFormGroup;
  public modalGeographicUnitOptions: any[] = [];
  public modalCountryOptions: any[] = [];
  public company: any | undefined;
  public saveCompanyLoading = false;
  public saveCompanyDisabled = false;

  constructor(
    private readonly httpClient: HttpClient,
    private readonly message: NzMessageService,
    private readonly dictionaryService: DictionaryService
  ) {}

  private getSearchCompanyFormParams() {
    const { geographicUnitCode, countryCode, companyCodes } =
      this.searchCompanyForm.value;
    let params = new HttpParams();
    if (geographicUnitCode) {
      params = params.set("geographicUnitCode", geographicUnitCode);
    }
    if (countryCode) {
      params = params.set("countryCode", countryCode);
    }
    if (companyCodes) {
      params = params.set("companyCodes", companyCodes.join("||"));
    }
    params = params.set("pageNumber", _.toString(this.pageNumber));
    params = params.set("pageSize", _.toString(this.pageSize));
    // console.log("get companies and count params", params);
    return params;
  }

  private getCompanyFormParams() {
    const params = this.companyForm.getRawValue();
    return params;
  }

  private searchCompanyFormBuilder() {
    this.searchCompanyForm = new UntypedFormGroup({
      geographicUnitCode: new UntypedFormControl(""),
      countryCode: new UntypedFormControl(""),
      companyCodes: new UntypedFormControl(null),
    });
    const geographicUnitControl =
      this.searchCompanyForm.get("geographicUnitCode");
    const countryControl = this.searchCompanyForm.get("countryCode");
    const companyControl = this.searchCompanyForm.get("companyCodes");
    geographicUnitControl.valueChanges.subscribe((geographicUnitCode) => {
      // console.log("geographic unit code is ", geographicUnitCode);
      this.handleSearchCountries(
        geographicUnitCode ? [geographicUnitCode] : []
      );
      countryControl.patchValue("");
      this.handleSearchCompanies(
        geographicUnitCode ? _.map(this.searchCountryOptions, "Code") : []
      );
      companyControl.patchValue(null);
    });
    countryControl.valueChanges.subscribe((countryCode) => {
      // console.log("country code is ", countryCode);
      this.handleSearchCompanies(countryCode ? [countryCode] : []);
      companyControl.patchValue(null);
    });
  }
  private companyPattern =  /[A-Za-z]/;

  private companyFormBuilder() {
    const companyCodeFormatValidator = (
      control: AbstractControl
    ): ValidationErrors | null => {
      const companyCode = _.toString(control.value);
      if (
        companyCode.length === 4 &&
        _.every(companyCode.substring(0,2).split(""), (text) => _.includes("0123456789", text)) &&
        _.every(companyCode.substring(2,4).split(""), (text) => _.includes("0123456789", text) || this.companyPattern.test(text))
      ) {
        return null;
      }
      return { formatted: true };
    };
    const companyNameFormatValidator = (
      control: AbstractControl
    ): ValidationErrors | null => {
      const companyName = _.toString(control.value);
      if (!_.includes(companyName, ",")) {
        return null;
      }
      return { formatted: true };
    };
    this.companyForm = new UntypedFormGroup({
      geographicUnitCode: new UntypedFormControl(null, [Validators.required]),
      countryCode: new UntypedFormControl(null, [Validators.required]),
      companyCode: new UntypedFormControl(null, [
        Validators.required,
        companyCodeFormatValidator,
      ]),
      companyName: new UntypedFormControl(null, [
        Validators.required,
        companyNameFormatValidator,
      ]),
      localAPInvoiceEmail: new UntypedFormControl(null, [
        Validators.required,
        Validators.email,
      ]),
      localAPSupportEmail: new UntypedFormControl(null, [
        Validators.required,
        Validators.email,
      ]),
    });
    const geographicUnitControl = this.companyForm.get("geographicUnitCode");
    const countryControl = this.companyForm.get("countryCode");
    geographicUnitControl.valueChanges.subscribe((geographicUnitCode) => {
      // console.log("geographic unit code is ", geographicUnitCode);
      this.handleModalCountries([geographicUnitCode]);
      countryControl.patchValue("");
    });
    this.companyForm.statusChanges.subscribe((status) => {
      this.saveCompanyDisabled = status !== "VALID";
    });
  }

  private handleSearchGeographicUnits() {
    const geographicUnitOptions = this.geographicUnitDictionary;
    this.searchGeographicUnitOptions = geographicUnitOptions;
    // console.log("search geographic unit options", geographicUnitOptions);
  }

  private handleSearchCountries(geographicUnitCodes: string[] = []) {
    const geographicUnitDictionary = this.geographicUnitDictionary;
    const countryOptions = _.chain(geographicUnitDictionary)
      .filter((geographicUnit) =>
        geographicUnitCodes.length
          ? _.includes(geographicUnitCodes, geographicUnit.Code)
          : true
      )
      .map("Items")
      .compact()
      .flatMap()
      .value();
    this.searchCountryOptions = countryOptions;
    // console.log("search country options", countryOptions);
  }

  private handleSearchCompanies(countryCodes: string[] = []) {
    const companyDictionary = this.companyCountryMappingDictionary;
    const companyOptions = _.chain(companyDictionary)
      .filter((company) =>
        countryCodes.length ? _.includes(countryCodes, company.Code) : true
      )
      .map("Items")
      .compact()
      .flatMap()
      .sortBy("Code")
      .value();
    this.searchCompanyOptions = companyOptions;
    // console.log("search company options", companyOptions);
  }

  private handleModalGeographicUnits() {
    const geographicUnitOptions = this.geographicUnitDictionary;
    this.modalGeographicUnitOptions = geographicUnitOptions;
    // console.log("modal geographic unit options", geographicUnitOptions);
  }

  private handleModalCountries(geographicUnitCodes: string[] = []) {
    const geographicUnitDictionary = this.geographicUnitDictionary;
    const countryOptions = _.chain(geographicUnitDictionary)
      .filter((geographicUnit) =>
        _.includes(geographicUnitCodes, geographicUnit.Code)
      )
      .map("Items")
      .compact()
      .flatMap()
      .value();
    this.modalCountryOptions = countryOptions;
    // console.log("modal country options", countryOptions);
  }

  private handleOptions() {
    this.handleSearchGeographicUnits();
    this.handleSearchCountries();
    this.handleSearchCompanies();
    this.handleModalGeographicUnits();
    this.handleModalCountries();
  }

  private async getDictionary() {
    const result = await this.httpClient
      .get<any>(`${environment.gateway}/dropdown/dictionary`)
      .toPromise();
    // console.log("get dictionary result", result);
    if (result.isSuccess) {
      const dictionary = result.data;
      this.geographicUnitDictionary =
        this.dictionaryService.getGeographicUnits(dictionary);
      // console.log("geographic unit dictionary", this.geographicUnitDictionary);
      this.companyCountryMappingDictionary =
        this.dictionaryService.getCompanyCountryMappings(dictionary);
      // console.log(
      //   "company country mapping dictionary",
      //   this.companyCountryMappingDictionary
      // );
      this.handleOptions();
    }
  }

  private async createCompany() {
    const params = this.getCompanyFormParams();
    params.companyCode = params.companyCode.toUpperCase()
    const result = await this.httpClient
      .post<any>(`${environment.gateway}/system/companies`, params)
      .toPromise();
    return result;
  }

  private async updateCompany() {
    const params = this.getCompanyFormParams();
    const result = await this.httpClient
      .put<any>(
        `${environment.gateway}/system/companies/${this.company.id}`,
        params
      )
      .toPromise();
    return result;
  }

  public async ngOnInit() {
    this.searchCompanyFormBuilder();
    this.companyFormBuilder();
    await this.getDictionary();
    await this.getCompaniesAndCount();
  }

  public reset() {
    this.searchCompanyForm.reset({
      geographicUnitCode: "",
      countryCode: "",
    });
    this.getCompaniesAndCount(1);
  }

  public search() {
    // console.log("search form", this.searchCompanyForm);
    this.getCompaniesAndCount(1);
  }

  public async getCompaniesAndCount(
    pageNumber = this.pageNumber,
    pageSize = this.pageSize
  ) {
    this.loading = true;
    this.pageNumber = pageNumber;
    this.pageSize = pageSize;
    const params = this.getSearchCompanyFormParams();
    const result = await this.httpClient
      .get<any>(`${environment.gateway}/system/companies/andCount`, { params })
      .toPromise();
    this.loading = false;
    // console.log("get companies and count result", result);
    if (result.isSuccess) {
      this.companies = result.data.companies;
      this.total = result.data.count;
    }
  }

  public getGeographicUnitName(geographicUnitCode: string) {
    const geographicUnit = _.find(this.geographicUnitDictionary, {
      Code: geographicUnitCode,
    });
    return geographicUnit ? geographicUnit.Text : "";
  }

  public getCountryName(countryCode: string) {
    const country = _.chain(this.geographicUnitDictionary)
      .map("Items")
      .compact()
      .flatMap()
      .find({ Code: countryCode })
      .value();
    return country ? country.Text : "";
  }

  public openCompanyModal(data?: any) {
    this.company = data;
    let form: any = {
      geographicUnitCode: { value: "", disabled: false },
      countryCode: { value: "", disabled: false },
      companyCode: { value: "", disabled: false },
      standardPaymentTermCode: "",
    };
    if (data) {
      form = {
        geographicUnitCode: { value: data.geographicUnitCode, disabled: true },
        countryCode: { value: data.countryCode, disabled: true },
        companyCode: { value: data.companyCode, disabled: true },
        companyName: data.companyName,
        localAPInvoiceEmail: data.localAPInvoiceEmail,
        localAPSupportEmail: data.localAPSupportEmail,
      };
      // console.log("data", data);
    }
    this.companyForm.reset(form);
    this.companyModalVisible = true;
  }

  public closeCompanyModal() {
    this.companyModalVisible = false;
    this.company = undefined;
  }

  public async saveCompany() {
    try {
      this.saveCompanyLoading = true;
      const result = this.company
        ? await this.updateCompany()
        : await this.createCompany();
      if (result.isSuccess && result.data.succeed) {
        this.message.success('save company succeed')
        this.saveCompanyLoading = false;
        this.companyModalVisible = false;
        this.company = undefined;
        this.loading = true;
        await this.getDictionary();
        await this.getCompaniesAndCount(1);
      } else {
        this.message.error(result.data.message || 'save company failed')
        this.saveCompanyLoading = false;
      }
    } catch (error) {
      this.message.error('save company failed')
      this.saveCompanyLoading = false;
    }
  }

  public async deleteCompany(companyId: number, companyCode: string) {
    try {
      this.deletedCompanyId = companyId;
      const result = await this.httpClient
        .delete<any>(`${environment.gateway}/system/companies/one`, {
          params: { companyCode },
        })
        .toPromise();
      if (result.isSuccess && result.data.succeed) {
        this.message.success('delete company succeed')
        await this.getDictionary();
        await this.getCompaniesAndCount(1);
        this.deletedCompanyId = 0;
      } else {
        this.message.error(result.data.message || 'delete company failed')
        this.deletedCompanyId = 0;
      }
    } catch (error) {
      this.message.error('delete company failed')
      this.deletedCompanyId = 0;
    }
  }
}
