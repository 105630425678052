import { Injectable } from "@angular/core";

@Injectable()
export class LoadingService {
  isDisable: boolean;
  isShowAndHide: boolean;
  url: string;
  constructor() {
    this.isDisable = false;
    this.isShowAndHide = false;
  }

  openLoading(url: string) {
    this.url = url;
    this.isDisable = true;
  }

  closeLoading() {
    this.isDisable = false;
  }

  showLoading() {
    this.isShowAndHide = true;
  }

  hideLoading(){
    this.isShowAndHide = false;
  }
}
