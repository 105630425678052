import {
  Component,
  OnInit,
  Input,
  OnChanges,
  OnDestroy,
  AfterViewInit,
  SimpleChanges,
  ChangeDetectorRef,
} from "@angular/core";
import { DynamicComponentService } from "src/app/dynamic-components/services/dynamic-component.service";
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  ValidatorFn,
  AsyncValidatorFn,
  UntypedFormControl,
  UntypedFormArray,
  AbstractControl,
} from "@angular/forms";
import { Subscription } from "rxjs";
import {
  ComponentConfig,
  EventListener,
  EventListenerAction,
} from "../../ComponentConfig";
import { DynamicContext } from "../../interfaces/DynamicContext";
import { Filters } from "../../filters/Filters";
import { Expressions } from "../../expressions/Expressions";
import { Hooks } from "../../hooks/hooks";
import { LanguageService } from "../../utils/language.service";
import { environment } from "src/environments/environment";
import { cloneDeep } from "src/app/utils";

@Component({
  selector: "app-dynamic-form",
  template: `
    <div class="dynamic-row">
      <ng-container
        *ngFor="let config of configs"
        appDynamicComponent
        [model]="model"
        [formGroup]="formGroup"
        [context]="context"
        [config]="config"
      >
      </ng-container>
    </div>
  `,
  styleUrls: ["./dynamic-form.component.sass"],
})
export class DynamicFormComponent
  implements OnInit, OnChanges, OnDestroy, AfterViewInit
{
  public static originalRawValue: any;
  @Input() configs: ComponentConfig[];
  @Input() formGroup: UntypedFormGroup;
  @Input() model: any;
  @Input() context: DynamicContext = new DynamicContext();
  @Input() disabled = false;

  private loaded = false;
  public autoScrollToError = true;

  private lazyLoadSubscription = new Map();
  private lazyLoadSubscriptionForDialog = new Map();
  private lazyLoadGlobalSubscription: any[] = [];
  private lazyLoadGlobalSubscriptionDialog: any[] = [];

  private callBackList: any[] = [];

  private changeModelPrefix = "change:model.";
  private changeContextPrefix = "change:context.";
  private modelSubscriptions: Subscription[] = [];
  private modelSubscriptionsForDialog: Subscription[] = [];
  private globalSubscription: Subscription;
  private configMap = new Map<string, ComponentConfig>();

  constructor(
    public formBuilder: UntypedFormBuilder,
    public service: DynamicComponentService,
    public changeDetector: ChangeDetectorRef,
    public languageService: LanguageService
  ) {}

  handleSubmit(event: any) {}

  ngOnChanges(changes: SimpleChanges) {
    this.languageService.setCurrentLanguage(localStorage.getItem("language"));
    if (
      !changes.configs ||
      !changes.configs.currentValue ||
      changes.configs.currentValue.length === 0
    ) {
      return;
    }
    this.loaded = false;
    this.initContext();
    this.removeFilterFromJson();
    this.initForm();
    this.updateFormValues();
    this.executeCallBack();
    this.bindingLazyLoadListeners();
    this.changeDetector.detectChanges();
    this.bindingLazyLoadListenersForDialog();
    this.loaded = true;
  }

  filterFieldset(config: ComponentConfig, index: number, obj: any) {
    if (this.service.isContainerComponent(config.type)) {
      config.fieldset.forEach((def, i) => {
        if (!Filters.doFilters(def, this.context, this.formGroup)) {
          delete obj.fieldset[i];
        } else if (def.fieldset) {
          this.filterFieldset(def, i, obj.fieldset[i]);
        }
        // exist fieldset and not filter== true
      });
      obj.fieldset = obj.fieldset.filter((item) => item !== null);
    } else {
      if (!Filters.doFilters(config, this.context, this.formGroup)) {
        delete this.configs[index];
      }
    }
  }

  removeFilterFromJson() {
    this.configs.forEach((def, index) => {
      if (
        !Filters.doFilters(this.configs[index], this.context, this.formGroup)
      ) {
        delete this.configs[index];
      } else if (def.fieldset) {
        this.filterFieldset(def, index, this.configs[index]);
      }
    });
    this.configs = this.configs.filter((item) => item !== null);
  }

  initContext() {
    this.context.service = this.service;
    if (this.model) {
      this.model = cloneDeep(this.model);
    }
    this.context.setModel(this.model);
  }

  ngAfterViewInit() {}

  ngOnInit() {}

  initForm() {
    if (!this.formGroup) {
      this.formGroup = this.createFormGroup();
    }
    this.addFormControls();
    this.bindingListeners(this.configs);
  }

  updateFormValues() {
    if (!this.formGroup){
      return
    }
    const keys = Object.keys(this.formGroup.controls);
    keys.forEach((k: string) => {
      if (this.model && this.model.hasOwnProperty(k)) {
        this.formGroup
          .get(k)
          .patchValue(this.model[k], { onlySelf: true, emitEvent: true });
      } else if (this.configMap.get(k)) {
        const controlConfig = this.configMap.get(k);
        if (controlConfig.defaultValue) {
          this.formGroup.get(k).patchValue(controlConfig.defaultValue, {
            onlySelf: true,
            emitEvent: true,
          });
        }
      }
      if (this.configMap.get(k)) {
        Hooks.executeHook(
          this.configMap.get(k),
          this.context,
          this.formGroup,
          Hooks.TRIGGER_AfterFormControlInit
        );
      }
    });
    if (this.formGroup.parent) {
      DynamicFormComponent.originalRawValue = cloneDeep(
        this.formGroup.parent.parent.getRawValue()
      );
    } else {
      DynamicFormComponent.originalRawValue = cloneDeep(
        this.formGroup.getRawValue()
      );
    }

    if (this.disabled) {
      this.formGroup.disable();
    }
  }

  removeFormTouch() {
    const keys = Object.keys(this.formGroup.controls);
    keys.forEach((k: string) => {
      this.formGroup.get(k).markAsTouched();

      //this.formGroup.get(k).markAsDirty()
    });
  }

  bindingListeners(configs: Array<ComponentConfig>) {
    configs.forEach((config: ComponentConfig) => {
      this.bindingListener(config);
    });
  }

  bindingLazyLoadListeners() {
    this.lazyLoadSubscription.forEach((callback, k) => {
      const formControl = this.formGroup.get(k);
      if (!formControl) {
        return;
      }
      const sub = formControl.valueChanges.subscribe((v) => {
        callback(v);
      });
      this.modelSubscriptions.push(sub);
    });
    this.lazyLoadSubscription.clear();

    if (this.lazyLoadGlobalSubscription.length > 0) {
      this.globalSubscription = this.formGroup.valueChanges.subscribe(() => {
        this.lazyLoadGlobalSubscription.forEach((callback) => {
          callback(this.values());
        });
        // this.changeDetector.detectChanges()
      });
    }
  }

  executeCallBack() {
    if (this.callBackList.length > 0) {
      this.callBackList.forEach((callback) => {
        callback();
      });
    }
  }

  bindingListener(config: ComponentConfig) {
    if (this.service.isContainerComponent(config.type)) {
      this.bindingListeners(config.fieldset);
    }
    if (!config.eventListeners || config.eventListeners.length === 0) {
      return;
    }
    config.eventListeners.forEach((item: EventListener) => {
      if (item.event.startsWith(this.changeModelPrefix)) {
        this.bindingModelChangeListener(config, item);
      } else if (item.event.startsWith(this.changeContextPrefix)) {
        this.bindingContextChangeListener(config, item);
      }
    });
  }

  bindingModelChangeListener(config: ComponentConfig, item: EventListener) {
    let targetFieldName = item.event.substring(this.changeModelPrefix.length);
    let formGroup;
    if (targetFieldName.startsWith("parent")) {
      formGroup = this.formGroup.parent;
      formGroup = this.getFormGroup(targetFieldName, formGroup).formGroup;
      targetFieldName = this.getFormGroup(
        targetFieldName,
        formGroup
      ).targetFieldName;
    } else {
      formGroup = this.formGroup;
    }
    if (!formGroup.get(targetFieldName)) {
      return;
    }
    const subscription: Subscription = formGroup
      .get(targetFieldName)
      .valueChanges.subscribe((v) => {
        item.actions.forEach((action: EventListenerAction) => {
          Expressions.executeExpression(
            v,
            action.name,
            action.params,
            config,
            this.context,
            this.formGroup
          );
        });
        if (this.loaded) {
          this.changeDetector.detectChanges();
        }
      });
    this.modelSubscriptions.push(subscription);
  }

  getFormGroup(targetFieldName: string, formGroup: any) {
    targetFieldName = targetFieldName.substring("parent.".length);
    if (targetFieldName.startsWith("parent")) {
      formGroup = formGroup.parent;
      this.getFormGroup(targetFieldName, formGroup);
      targetFieldName = this.getFormGroup(
        targetFieldName,
        formGroup
      ).targetFieldName;
    }
    return { formGroup: formGroup, targetFieldName: targetFieldName };
  }

  bindingContextChangeListener(config: ComponentConfig, item: EventListener) {
    const targetFieldName = item.event.substring(
      this.changeContextPrefix.length
    );
    const contextField = this.context.get(targetFieldName);
    if (!contextField) {
      return;
    }
    contextField.subscribeToValueChange((v) => {
      item.actions.forEach((action: EventListenerAction) => {
        Expressions.executeExpression(
          v,
          action.name,
          action.params,
          config,
          this.context,
          this.formGroup
        );
      });
    });
  }

  createFormGroup(): UntypedFormGroup {
    const group = this.formBuilder.group({});
    return group;
  }

  addFormControls() {
    this.configs.forEach((def) => this.addFormControl(def, this.formGroup));
  }

  addFormControl(config: ComponentConfig, group: UntypedFormGroup) {
    if (this.service.isContainerComponent(config.type)) {
      config.fieldset.forEach((def) => this.addFormControl(def, group));
      return;
    }

    // Get current language value
    if (environment.languageSwitch) {
      config = this.languageService.getCurrentLanguageValue(config);
    }

    // run hooks
    Hooks.executeHook(
      config,
      this.context,
      this.formGroup,
      Hooks.TRIGGER_BeforeFormControlInit
    );
    if (!config.name) {
      return;
    }
    this.configMap.set(config.name, config);
    // init form control
    const control = new UntypedFormControl("", {
      updateOn: config.validateOn || "change",
    });
    if (!config.hide) {
      control.setValidators(this.createValidators(config));
      control.setAsyncValidators(this.createAsyncValidators(config));
    }
    if (config.datatype === "object") {
      const parent = new UntypedFormGroup({});
      config.fieldset.forEach((configFile: ComponentConfig) => {
        const controlName = new UntypedFormControl("", {
          updateOn: configFile.validateOn || "change",
        });
        if (!config.hide) {
          controlName.setValidators(this.createValidators(configFile));
          controlName.setAsyncValidators(
            this.createAsyncValidators(configFile)
          );
        }
        controlName["cid"] = config.name || config.id;
        parent.addControl(configFile.name, controlName);
      });
      group.addControl(config.name, parent);
    } else if (config.datatype === "array") {
      const array = new UntypedFormArray([]);
      group.addControl(config.name, array);
    } else {
      group.addControl(config.name, control);
    }
    group.get(config.name)["cid"] = config.name || config.id;
  }

  createValidators(config: ComponentConfig): ValidatorFn | AsyncValidatorFn {
    return this.service.validatorFactory.createValidators(config, this.context);
  }

  createAsyncValidators(config: ComponentConfig): AsyncValidatorFn {
    return this.service.validatorFactory.createAsyncValidators(
      config,
      this.context
    );
  }

  ngOnDestroy(): void {
    // saveState
    this.deBindingListeners();
    if (this.context) {
      this.context.cleanUp();
    }
  }

  subscribeToModelChange(
    fieldName: string,
    callback: (value: any) => void
  ): void {
    this.lazyLoadSubscription.set(fieldName, callback);
  }


  subscribeToModelChangeFordialog(
    fieldName: string,
    callback: (value: any) => void
  ): void {
    this.lazyLoadSubscriptionForDialog.set(fieldName, callback);
  }

  subscribeToFormChange(callback: (value: any) => void): void {
    this.lazyLoadGlobalSubscription.push(callback);
  }

  executeAfterFormGroupInit(callback: (value: any) => void): void {
    this.callBackList.push(callback);
  }

  deBindingListeners() {
    if (this.globalSubscription) {
      this.globalSubscription.unsubscribe();
    }
    if (this.modelSubscriptions && this.modelSubscriptions.length > 0) {
      this.modelSubscriptions.forEach((s) => s.unsubscribe);
    }
  }

  hasValueChanged() {
    return (
      JSON.stringify(this.originalValues()) !== JSON.stringify(this.values())
    );
  }

  originalValues() {
    return DynamicFormComponent.originalRawValue;
  }

  values() {
    return this.formGroup.getRawValue();
  }

  formGroupValidate(config: ComponentConfig, formGroup: UntypedFormGroup) {
    if (config.datatype) {
      if (config.datatype == "array") {
        const array = formGroup.get(config.name) as UntypedFormArray;
        if (this.formArrayValidate(config, array) === false) {
          return false;
        }
      } else {
        const group = formGroup.get(config.name) as UntypedFormGroup;
        for (const conf of config.fieldset) {
          if (this.formGroupValidate(conf, group) === false) {
            return false;
          }
        }
      }
    }
    if (formGroup.get(config.name)) {
      const error = formGroup.get(config.name).errors;
      if (error) {
        const key = Object.keys(error)[0];
        if (error[key] === true) {
          return false;
        }
      } else {
        return true;
      }
    }
  }

  formArrayValidate(config: any, array: any) {
    for (const control of array.controls) {
      const formGroup = control as UntypedFormGroup;
      if (config.fieldset) {
        for (const conf of config.fieldset) {
          if (conf.fieldset) {
            if (conf.fieldset.length === 1) {
              if (
                this.formGroupValidate(conf.fieldset[0], formGroup) === false
              ) {
                return false;
              }
            } else {
              if (this.formArrayValidate(conf, array) === false) {
                return false;
              }
            }
          } else if (this.formGroupValidate(conf, formGroup) === false) {
            return false;
          }
        }
      }
    }
  }

  valide() {
    if (!this.validateForm()) {
      if (this.autoScrollToError) {
        this.scrollToErrorField();
      }
      return false;
    }
    return true;
  }

  validateForm() {
    if (this.formGroup.pending) {
      return false;
    }
    const arr = Array.from(this.configMap.values());
    for (const item of arr) {
      if (this.formGroupValidate(item, this.formGroup) === false) {
        return false;
      }
    }
    return true;
  }

  scrollToErrorField() {
    const keys = Object.keys(this.formGroup.controls);
    for (const k of keys) {
      const control = this.formGroup.get(k);
      const errorResult = { errorControlId: "", deep: 0 };
      this.loopFormControl(control, errorResult);
      if (errorResult.errorControlId) {
        const el = document.querySelectorAll(
          `[id^='${errorResult.errorControlId}']`
        );
        if (el && el[errorResult.deep]) {
          el[errorResult.deep].scrollIntoView();
        }
        return;
      }
    }
  }

  loopFormControl(
    formControl: AbstractControl,
    errorResult: { errorControlId: string; deep: number }
  ) {
    if (formControl instanceof UntypedFormArray) {
      for (const control of formControl.controls) {
        this.loopFormControl(control, errorResult);
        if (errorResult.errorControlId) {
          return;
        }
      }
      errorResult.deep++;
    } else if (formControl instanceof UntypedFormGroup) {
      const keys = Object.keys(formControl.controls);
      for (const k of keys) {
        const control = formControl.get(k);
        this.loopFormControl(control, errorResult);
        if (errorResult.errorControlId) {
          return;
        }
      }
      errorResult.deep++;
    }
    if (!formControl.valid) {
      const hasError =
        formControl.errors &&
        Object.values(formControl.errors).find((v) => v === true);
      if (!hasError) {
        return;
      }
      errorResult.errorControlId = formControl["cid"];
      return;
    }
  }

  bindingLazyLoadListenersForDialog() {
    this.lazyLoadSubscriptionForDialog.forEach((callback, k) => {
      const formControl = this.formGroup.get(k);
      if (!formControl) {
        return;
      }
      const sub = formControl.valueChanges.subscribe((v) => {
        callback(v);
      });
      this.modelSubscriptionsForDialog.push(sub);
    });
    this.lazyLoadSubscriptionForDialog.clear();

    if (this.lazyLoadGlobalSubscriptionDialog.length > 0) {
      this.globalSubscription = this.formGroup.valueChanges.subscribe(() => {
        this.lazyLoadGlobalSubscriptionDialog.forEach((callback) => {
          callback(this.values());
        });
        // this.changeDetector.detectChanges()
      });
    }
  }
}
