import { Injectable } from "@angular/core";
import { EventEmitter } from "@angular/core";
import { Subject } from "rxjs";
import { ChangeRequestDetailStatus } from "src/app/interfaces/changeRequest";
import { Task } from "../interfaces/workflow";
import { LanguageService } from "../dynamic-components/utils/language.service";
import { environment } from "src/environments/environment";

export enum DialogType {
  question,
  warning,
  alert,
  success,
  sstReject = "AP SST Reject",
  sstTerminate = "AP SST Terminated",
  sstApprove = "AP SST Review Approved",
  qcReject = "AP QC Reject",
  qcTerminate = "AP QC Terminated",
  qcApprove = "AP QC Review Approved",
  replace = "Replace Email",
  sendInvitation = "Send Invitation",
  terminateSupplier = "Terminate Supplier",
}

export enum CRDialogType {
  REQUESTOR_CHANGE_REQUEST = "Requestor Change Request",
  SSTApproveChangeRequest = "SST Approve Change Request",
  SSTCloseChangeRequest = "SST Close Change Request",
  QCApproveChangeRequest = "QC Approve Change Request",
  QCCloseChangeRequest = "QC Close Change Request",
  QCRejectChangeRequest = "QCT Reject Change Request",
}

export enum ButtonCommand {
  yes,
  no,
  ok,
  cancel,
  close,
  redo,
  proceed,
}

export enum ButtonCommands {
  YesNo,
  Ok,
  Close,
  ProceedCancel,
  CancelClose,
  GotItCancel,
}

export interface ButtonItem {
  icon?: string;
  label?: string;
  action?: Function;
  command?: ButtonCommand;
  iswhite?: boolean;
  clickEvent?: EventEmitter<any>;
}

export interface Confirmation {
  dialogType: DialogType;
  icon?: string;
  header?: string;
  message?: string;
  key?: string;
  options?: any;
  buttons?: ButtonItem[];
  task?: Task;
}

export interface CRConfirmation {
  dialogType: ChangeRequestDetailStatus;
  message?: string;
  options?: any;
  task?: Task;
}

@Injectable()
export class ConfirmService {
  requireConfirmationSource = new Subject<Confirmation>();
  requireConfirmation$ = this.requireConfirmationSource.asObservable();

  confirm(confirmation: Confirmation) {
    this.requireConfirmationSource.next(confirmation);
    return this;
  }

  sendFlagSource = new Subject<any>();
  sendFlag$ = this.sendFlagSource.asObservable();
  coupaFlag = true;

  send(){
    this.sendFlagSource.next(this.coupaFlag);
    return this;
  }

  sendAuditSource = new Subject<any>();
  sendAudit$ = this.sendAuditSource.asObservable();

  sendAudit(crAudit) {
    this.sendAuditSource.next(crAudit);
    return this;
  }
}

@Injectable()
export class APReivewService {
  requireReviewSource = new Subject<Confirmation>();
  requireReview$ = this.requireReviewSource.asObservable();

  confirm(confirmation: Confirmation) {
    this.requireReviewSource.next(confirmation);
    return this;
  }
}
@Injectable()
export class ChangeRequestAPReviewService {
  changeRequestrequireReviewSource = new Subject<CRConfirmation>();
  changeRequestRequireReview$ =
    this.changeRequestrequireReviewSource.asObservable();

  confirm(confirmation: CRConfirmation) {
    this.changeRequestrequireReviewSource.next(confirmation);
    return this;
  }
}

@Injectable()
export class SendInvitationService {
  requireInvitationSource = new Subject<Confirmation>();
  requireInvitation$ = this.requireInvitationSource.asObservable();

  confirm(confirmation: Confirmation) {
    this.requireInvitationSource.next(confirmation);
    return this;
  }
}

@Injectable()
export class DialogService {
  constructor(
    private confirmationService: ConfirmService,
    private apReviewService: APReivewService,
    private changeRequestAPReviewService: ChangeRequestAPReviewService,
    private languageService: LanguageService,
    private sendInvitationService: SendInvitationService
  ) { }

  async saveConfirm() {
    const dialogType = DialogType.warning;
    const languageFlag =
      environment.languageSwitch && environment.role == "supplier";

    return new Promise<string>((resolve, reject) => {
      const cancel: ButtonItem = {
        label: languageFlag
          ? this.languageService.getValue("static.service.dialog.Cancel")
          : "Cancel",
        iswhite: true,
        action: () => {
          resolve("cancel");
        },
      };

      const discard: ButtonItem = {
        label: languageFlag
          ? this.languageService.getValue("static.service.dialog.Discard")
          : "Discard",
        iswhite: true,
        action: () => {
          resolve("discard");
        },
      };

      const save: ButtonItem = {
        label: languageFlag
          ? this.languageService.getValue("static.service.dialog.Save")
          : "Save",
        action: () => {
          resolve("save");
        },
      };
      return this.confirmationService.confirm({
        dialogType,
        message: languageFlag
          ? this.languageService.getValue(
            "static.service.dialog.DiscardOrCancel"
          )
          : "You have modified the form. You can save your changes, discard your changes, or cancel to continue editing.",
        header: languageFlag
          ? this.languageService.getValue("static.service.dialog.Confirm")
          : "Confirm",
        options: {},
        buttons: [cancel, discard, save],
      });
    });
  }
  async deleteSupplierWarning() {
    const dialogType = DialogType.warning;
    const languageFlag =
      environment.languageSwitch && environment.role == "supplier";

    return new Promise<string>((resolve, reject) => {
      const cancel: ButtonItem = {
        label: languageFlag
          ? this.languageService.getValue("static.service.dialog.Cancel")
          : "Cancel",
        iswhite: true,
        action: () => {
          resolve("cancel");
        },
      };

      const Yes: ButtonItem = {
        label: languageFlag
          ? this.languageService.getValue("static.service.dialog.Yes")
          : "Yes",
        iswhite: true,
        action: () => {
          resolve("Yes");
        },
      };
      return this.confirmationService.confirm({
        dialogType,
        message: languageFlag
          ? this.languageService.getValue("static.service.dialog.SureToProceed")
          : "Please note that part of supplier information would be deleted. Are you sure you want to proceed?",
        header: languageFlag
          ? this.languageService.getValue("static.service.dialog.Confirm")
          : "Confirm",
        options: {},
        buttons: [cancel, Yes],
      });
    });
  }
  async saveConfirmLastPage() {
    const dialogType = DialogType.warning;
    const languageFlag =
      environment.languageSwitch && environment.role == "supplier";

    return new Promise<string>((resolve, reject) => {
      const cancel: ButtonItem = {
        label: languageFlag
          ? this.languageService.getValue("static.service.dialog.Cancel")
          : "Cancel",
        iswhite: true,
        action: () => {
          resolve("cancel");
        },
      };

      const discard: ButtonItem = {
        label: languageFlag
          ? this.languageService.getValue("static.service.dialog.Discard")
          : "Discard",
        iswhite: true,
        action: () => {
          resolve("discard");
        },
      };
      return this.confirmationService.confirm({
        dialogType,
        message: languageFlag
          ? this.languageService.getValue(
            "static.service.dialog.DiscardOrCancel"
          )
          : "You have modified the form. You can save your changes, discard your changes, or cancel to continue editing.",
        header: languageFlag
          ? this.languageService.getValue("static.service.dialog.Confirm")
          : "Confirm",
        options: {},
        buttons: [cancel, discard],
      });
    });
  }

  async confirm(
    dialogType: DialogType,
    message: string,
    buttons: ButtonItem[],
    header = environment.languageSwitch && environment.role == "supplier"
      ? this.languageService.getValue("static.service.dialog.Confirm")
      : "Confirm",
    options = {}
  ): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.confirmationService.confirm({
        dialogType,
        message,
        header,
        options,
        buttons,
      });
    });
  }

  async fileUploadFialedDialog(message: string, options = {}): Promise<any> {
    const languageFlag =
      environment.languageSwitch && environment.role == "supplier";
    return new Promise<any>((resolve, reject) => {
      this.confirmationService.confirm({
        dialogType: DialogType.warning,
        message,
        header: languageFlag
          ? this.languageService.getValue("static.service.dialog.uploadFail")
          : "FILE UPLOADING FAILED",
        options,
        buttons: [
          {
            icon: "close",
            label: languageFlag
              ? this.languageService.getValue("static.service.dialog.Close")
              : "Close",
            action: () => {
              resolve(ButtonCommand.close);
            },
          },
          {
            icon: "autorenew",
            label: languageFlag
              ? this.languageService.getValue("static.service.dialog.ReUpload")
              : "Re-upload",
            action: () => {
              resolve(ButtonCommand.redo);
            },
          },
        ],
      });
    });
  }

  async dialog(
    message: string,
    command: ButtonCommands,
    dialogType = DialogType.alert,
    header = environment.languageSwitch && environment.role == "supplier"
      ? this.languageService.getValue("static.service.dialog.Confirm")
      : "Confirm",
    options = {}
  ): Promise<any> {
    const languageFlag =
      environment.languageSwitch && environment.role == "supplier";
    return new Promise<any>((resolve, reject) => {
      let btns = [];
      switch (command) {
        case ButtonCommands.YesNo:
          btns = [
            {
              iswhite: true,
              icon: "close",
              label: "No",
              action: () => {
                resolve(ButtonCommand.no);
              },
            },
            {
              icon: "check",
              label: languageFlag
                ? this.languageService.getValue("static.service.dialog.Yes")
                : "Yes",
              action: () => {
                resolve(ButtonCommand.yes);
              },
            },
          ];
          break;
        case ButtonCommands.ProceedCancel:
          btns = [
            {
              iswhite: true,
              icon: "close",
              label: languageFlag
                ? this.languageService.getValue("static.service.dialog.Cancel")
                : "Cancel",
              action: () => {
                resolve(ButtonCommand.no);
              },
            },
            {
              icon: "check",
              label: languageFlag
                ? this.languageService.getValue("static.service.dialog.Proceed")
                : "Proceed",
              action: () => {
                resolve(ButtonCommand.yes);
              },
            },
          ];
          break;
        case ButtonCommands.Ok:
          const language = localStorage.getItem("language") || 'en';
          let label = 'Ok'
          if (language === 'cn') {
            label = '好的'
          }
          if (language === 'jp') {
            label = 'OK'
          }
          btns = [
            {
              icon: "check",
              label,
              action: () => {
                resolve(ButtonCommand.ok);
              },
            },
          ];
          break;
        case ButtonCommands.Close:
          btns = [
            {
              iswhite: true,
              icon: "close",
              label: languageFlag
                ? this.languageService.getValue("static.service.dialog.Close")
                : "Close",
              action: () => {
                resolve(ButtonCommand.close);
              },
            },
          ];
          break;
        case ButtonCommands.CancelClose:
          btns = [
            {
              iswhite: true,
              icon: "close",
              label: languageFlag
                ? this.languageService.getValue("static.service.dialog.Cancel")
                : "Cancel",
              action: () => {
                resolve(ButtonCommand.cancel);
              },
            },
            {
              iswhite: true,
              icon: "close",
              label: languageFlag
                ? this.languageService.getValue("static.service.dialog.Close")
                : "Close",
              action: () => {
                resolve(ButtonCommand.close);
              },
            },
          ];
          break;
        case ButtonCommands.GotItCancel:
          btns = [
            {
              iswhite: true,
              icon: "close",
              label: languageFlag
                ? this.languageService.getValue("static.service.dialog.Cancel")
                : "Got It!",
              action: () => {
                resolve(ButtonCommand.cancel);
              },
            },
            {
              iswhite: true,
              icon: "close",
              label: languageFlag
                ? this.languageService.getValue("static.service.dialog.Close")
                : "Cancel My Request",
              action: () => {
                resolve(ButtonCommand.close);
              },
            },
          ];
          break;
      }

      this.confirmationService.confirm({
        dialogType: dialogType,
        message,
        header,
        options,
        buttons: btns,
      });
    });
  }

  async dialogNoButton(
    message: string,
    dialogType = DialogType.alert,
    header = environment.languageSwitch && environment.role == "supplier"
      ? this.languageService.getValue("static.service.dialog.Confirm")
      : "Confirm",
    options = {}
  ): Promise<any> {
    const languageFlag =
      environment.languageSwitch && environment.role == "supplier";
    return new Promise<any>((resolve, reject) => {
      const btns = [];

      this.confirmationService.confirm({
        dialogType: dialogType,
        message,
        header,
        options,
        buttons: btns,
      });
    });
  }

  async apSstReject(
    task: Task,
    message: string,
    options = { title: "Rejection Input" }
  ): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.apReviewService.confirm({
        dialogType: DialogType.sstReject,
        message,
        options,
        task,
      });
    });
  }

  async apSstTerminate(
    task: Task,
    message: string,
    options = { title: "Termination Input" }
  ): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.apReviewService.confirm({
        dialogType: DialogType.sstTerminate,
        message,
        options,
        task,
      });
    });
  }

  async apSstApprove(
    task: Task,
    message: string,
    options = { title: "Approvement Input" }
  ): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.apReviewService.confirm({
        dialogType: DialogType.sstApprove,
        message,
        options,
        task,
      });
    });
  }

  async apQcReject(
    task: Task,
    message: string,
    options = { title: "Rejection Input" }
  ): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.apReviewService.confirm({
        dialogType: DialogType.qcReject,
        message,
        options,
        task,
      });
    });
  }

  async apQcTerminate(
    task: Task,
    message: string,
    options = { title: "Termination Input" }
  ): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.apReviewService.confirm({
        dialogType: DialogType.qcTerminate,
        message,
        options,
        task,
      });
    });
  }

  async apQcApprove(
    task: Task,
    message: string,
    options = { title: "Approvement Input" }
  ): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.apReviewService.confirm({
        dialogType: DialogType.qcApprove,
        message,
        options,
        task,
      });
    });
  }

  async replaceEmail(
    message: string,
    options = { title: "Send Invitation" }
  ): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.sendInvitationService.confirm({
        dialogType: DialogType.replace,
        message,
        options,
      });
    });
  }

  async sendInvitation(
    message: string,
    options = { title: "Send Invitation" }
  ): Promise<any> {
    console.log("message-------->", message);
    return new Promise<any>((resolve, reject) => {
      this.sendInvitationService.confirm({
        dialogType: DialogType.sendInvitation,
        message,
        options,
      });
    });
  }

  async apSSTApproveChangeRequest(
    task: Task,
    message: string,
    options = { title: "Change Request" }
  ): Promise<any> {
    console.log("message-------->", message);
    return new Promise<any>((resolve, reject) => {
      this.changeRequestAPReviewService.confirm({
        dialogType: ChangeRequestDetailStatus.APPROVED_BY_AP_SST,
        message,
        options,
        task,
      });
    });
  }

  async apSSTCloseChangeRequest(
    task: Task,
    message: string,
    options = { title: "Close Change Request" }
  ): Promise<any> {
    console.log("message-------->", message);
    return new Promise<any>((resolve, reject) => {
      this.changeRequestAPReviewService.confirm({
        dialogType: ChangeRequestDetailStatus.CLOSED_BY_AP_SST,
        message,
        options,
        task,
      });
    });
  }

  async apQCApproveChangeRequest(
    task: Task,
    message: string,
    options = { title: "Change Request" }
  ): Promise<any> {
    console.log("message-------->", message);
    return new Promise<any>((resolve, reject) => {
      this.changeRequestAPReviewService.confirm({
        dialogType: ChangeRequestDetailStatus.APPROVED_BY_AP_QC,
        message,
        options,
        task,
      });
    });
  }

  async apQCCloseChangeRequest(
    task: Task,
    message: string,
    options = { title: "Close Change Request" }
  ): Promise<any> {
    console.log("message-------->", message);
    return new Promise<any>((resolve, reject) => {
      this.changeRequestAPReviewService.confirm({
        dialogType: ChangeRequestDetailStatus.CLOSED_BY_AP_QC,
        message,
        options,
        task,
      });
    });
  }

  async apQCRejectChangeRequest(
    task: Task,
    message: string,
    options = { title: "Reject Change Request" }
  ): Promise<any> {
    console.log("message-------->", message);
    return new Promise<any>((resolve, reject) => {
      this.changeRequestAPReviewService.confirm({
        dialogType: ChangeRequestDetailStatus.REJECT_BY_AP_QC,
        message,
        options,
        task,
      });
    });
  }

  async terminateSupplier(
    message: string,
    options = {
      title:
        environment.languageSwitch && environment.role == "supplier"
          ? this.languageService.getValue("static.service.dialog.Termination")
          : "Termination Input",
    }
  ): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.apReviewService.confirm({
        dialogType: DialogType.terminateSupplier,
        message,
        options,
      });
    });
  }

  async InvitationStatusChange() {
    const dialogType = DialogType.warning;

    return new Promise<string>((resolve, reject) => {
      const cancel: ButtonItem = {
        label: "OK",
        iswhite: true,
        action: () => {
          resolve("cancel");
        },
      };
      const languageFlag =
        environment.languageSwitch && environment.role == "supplier";
      return this.confirmationService.confirm({
        dialogType,
        message: languageFlag
          ? this.languageService.getValue(
            "static.service.dialog.CannotSetStatus"
          )
          : "Please note this supplier has been terminate and therefore you cannot set their status back to inactive.",
        header: languageFlag
          ? this.languageService.getValue(
            "static.supplier.components.general.please_note"
          )
          : "Please Note",
        options: {},
        buttons: [cancel],
      });
    });
  }
}
