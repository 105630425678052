import { Component, OnInit, OnDestroy } from "@angular/core";
import { SupplierUser } from "src/app/interfaces/supplier";
import { ManagementService } from "../../../services/management.service";
import {
  ButtonCommands,
  DialogType,
  DialogService,
} from "src/app/services/dialog.service";
import { Router, ActivatedRoute } from "@angular/router";
import { SupplierService } from "src/app/services/supplier.service";
import { AuthService } from "src/app/services/auth/auth.service";
import { trim } from "src/app/utils";
import { ThemePalette } from "@angular/material/core";
import { async } from "q";
import { environment } from "src/environments/environment";
import { LoadingUrlMapping } from "src/app/interfaces/mapping";
import { LoadingService } from "src/app/services/loading.service";
import { LanguageService } from "../../../../../dynamic-components/utils/language.service";

enum Status {
  new = "new",
  accept = "accept",
}
enum SupplierEnabled {
  active = "Active",
  inactive = "Inactive",
}
@Component({
  selector: "app-user-edit",
  templateUrl: "./user-edit.component.html",
  styleUrls: ["./user-edit.component.sass"],
})
export class UserEditComponent implements OnInit, OnDestroy {
  mode: string;
  mail: string;
  users: Array<SupplierUser> = [];
  adminUsers: Array<SupplierUser> = [];
  activeUsers: Array<any> = [];
  roles = [];
  color: ThemePalette = "primary";
  currentUser: SupplierUser;
  hasError = false;
  checkingDuplicateUser = false;
  currentRole;
  Status = Status;
  isValidated = true;
  isOneAdmin: boolean;
  emailIsInBlacklist = false;
  emailFormatValidated = true;
  emailPattern = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,})$/;

  supplierEnabled = SupplierEnabled;
  private get isCreate(): boolean {
    return !this.mode;
  }

  private get isView() {
    return this.mode === "view";
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private managementService: ManagementService,
    private dialogService: DialogService,
    private auth: AuthService,
    private supplierService: SupplierService,
    private loadingService: LoadingService,
    private languageService: LanguageService
  ) {}

  async ngOnInit() {
    // this.isValidated = false
    this.mode = this.route.snapshot.paramMap.get("mode");
    this.mail = this.route.snapshot.paramMap.get("mail");
    const supplierCode = this.auth.passport.supplier.Mapping.supplierCode;
    if (this.isCreate) {
      this.managementService.title = this.languageService.getValue(
        "static.supplier.user-edit.createUserDetails"
      );
    } else if (this.isView) {
      this.managementService.title = this.languageService.getValue(
        "static.supplier.user-edit.viewUserDetails"
      );
    } else {
      this.managementService.title = this.languageService.getValue(
        "static.supplier.user-edit.editUserDetails"
      );
    }
    this.managementService.changeToEditMode();

    const roleData = await this.supplierService.querySupplierRoles();
    if (roleData.isSuccess) {
      this.roles = roleData.data;
    }

    this.supplierService.supplierUsers(supplierCode).then((data: any) => {
      this.users = data.data;
      this.currentUser = this.isCreate
        ? {
            SupplierCode: this.auth.passport.supplier.Mapping.supplierCode,
            Status: SupplierEnabled.active,
          }
        : this.users.find((u) => u.Email === this.mail);
      if (this.isCreate) {
        this.currentUser.Enabled = true;
      }
      this.activeUsers = this.users.filter((item) => item.Enabled === true);
      this.adminUsers = this.users.filter(
        (item) => item.RoleCode === "supplierAdmin" && item.Enabled === true
      );
      if (this.adminUsers.length === 1) {
        if (this.currentUser === this.adminUsers[0]) {
          this.isOneAdmin = true;
        } else {
          this.isOneAdmin = false;
        }
      } else {
        this.isOneAdmin = false;
      }
    });
  }

  ngOnDestroy(): void {
    this.managementService.changeToViewMode();
  }

  async checkDuplicateUser() {
    if (this.checkingDuplicateUser) {
      return;
    }
    this.checkingDuplicateUser = true;
    const resp: any = await this.supplierService.userExists(
      this.auth.passport.supplier.Mapping.supplierCode,
      this.currentUser.Email
    );
    this.checkingDuplicateUser = false;
    return resp.data;

    // .then((resp: any) => {
    //   console.log('resp.data-------->', resp.data)
    //   this.saveable = resp.data
    //   this.hasError = !resp.data
    // })
  }

  async create() {
    await this.validate();
    this.currentUser.Name = this.currentUser.FirstName.concat(
      " ",
      this.currentUser.LastName
    );
    if (!this.isValidated) {
      return;
    }
    this.currentUser.PrivacyStatus = Status.new;
    const saveUrl = `${environment.gateway}${LoadingUrlMapping.supplierAddUser}`;
    this.loadingService.openLoading(saveUrl);
    const isSendEmail = true; // send invitation email
    if (this.isValidated) {
      this.supplierService
        .createUser(this.currentUser, isSendEmail, false)
        .then((result: any) => {
          this.loadingService.closeLoading();
          if (result.isSuccess) {
            if (result.data === "morethanFivesuppliers") {
              this.dialogService
                .dialog(
                  this.languageService.getValue(
                    "static.supplier.user-edit.deactivateUser"
                  ),
                  ButtonCommands.Close,
                  DialogType.alert,
                  this.languageService.getValue(
                    "static.supplier.user-edit.pleaseNote"
                  )
                )
                .then((d) => {
                  this.router.navigate([
                    `/buyer/supplierManagement/users/${this.route.snapshot.paramMap.get(
                      "supplierCode"
                    )}`,
                  ]);
                });
              return;
            }
            this.dialogService
              .dialog(
                this.languageService.getValue(
                  "static.supplier.user-edit.createSuccessfully"
                ),
                ButtonCommands.Ok,
                DialogType.success
              )
              .then((d) => {
                this.router.navigate([`/supplier/management/users`]);
              });
          } else {
            if (result.error.errorType === "wrong invitation url") {
              // eslint-disable-next-line max-len
              this.dialogService
                .dialog(
                  this.languageService.getValue(
                    "static.supplier.user-edit.individualEmail"
                  ),
                  ButtonCommands.Close,
                  DialogType.warning,
                  this.languageService.getValue(
                    "static.supplier.user-edit.warning"
                  )
                )
                .then((d) => {});
            }
          }
        });
    }
  }

  async save() {
    if (this.isCreate) {
      await this.create();
      return;
    }
    await this.validate();
    if (!this.isValidated) {
      return;
    }
    const currentuser = this.currentUser as any;
    const activeAndCurrentUser = this.activeUsers.filter(
      (item) => item.ID === currentuser.ID
    );
    if (
      this.activeUsers.length >= 20 &&
      this.currentUser.Enabled &&
      activeAndCurrentUser.length <= 0
    ) {
      this.dialogService.dialog(
        this.languageService.getValue(
          "static.supplier.user-edit.deactivateUser"
        ),
        ButtonCommands.Close,
        DialogType.alert,
        this.languageService.getValue("static.supplier.user-edit.pleaseNote")
      );
      return;
    }
    this.currentUser.Name = this.currentUser.FirstName.concat(
      " ",
      this.currentUser.LastName
    );
    this.supplierService
      .modifyUser(
        this.currentUser,
        this.auth.passport.supplierMember.Email,
        this.auth.passport.supplierMember.Name
      )
      .then((result: any) => {
        if (result.data === "morethanFivesuppliers") {
          this.dialogService
            .dialog(
              this.languageService.getValue(
                "static.supplier.user-edit.deactivateUser"
              ),
              ButtonCommands.Close,
              DialogType.alert,
              this.languageService.getValue(
                "static.supplier.user-edit.pleaseNote"
              )
            )
            .then((d) => {
              this.router.navigate([
                `/buyer/supplierManagement/users/${this.route.snapshot.paramMap.get(
                  "supplierCode"
                )}`,
              ]);
            });
          return;
        }
        if (result.data === "lessthanOneSupplierAdminUser") {
          this.dialogService
            .dialog(
              this.languageService.getValue(
                "static.supplier.user-edit.requiredProfile"
              ),
              ButtonCommands.Close,
              DialogType.alert,
              this.languageService.getValue(
                "static.supplier.user-edit.pleaseNote"
              )
            )
            .then((d) => {
              this.router.navigate([`/supplier/management/users`]);
            });
          return;
        }
        this.dialogService
          .dialog(
            this.languageService.getValue(
              "static.supplier.user-edit.updateSuccessfully"
            ),
            ButtonCommands.Ok,
            DialogType.success
          )
          .then((d) => {
            this.router.navigate([`/supplier/management/users`]);
          });
      });
  }

  cancel() {
    this.managementService.searchChanged.emit("");
    this.router.navigate([`/supplier/management/users`]);
  }
  roleName(roleCode): string {
    const role: any = this.roles.find((r: any) => r.RoleCode === roleCode);
    if (role) {
      if (role.RoleName == "Administrator") {
        return this.languageService.getValue(
          "static.supplier.user.Administrator"
        );
      } else if (role.RoleName == "Normal User") {
        return this.languageService.getValue(
          "static.supplier.user.Normal_User"
        );
      }
    } else {
      return "";
    }
  }
  emailPatternValid() {
    this.emailIsInBlacklist = false;
    this.emailFormatValidated = this.emailPattern.test(this.currentUser.Email);
  }

  async validate() {
    this.isValidated = true;
    if (this.currentUser.FirstName && this.currentUser.LastName) {
      this.currentUser.FirstName = this.currentUser.FirstName.trim();
      this.currentUser.LastName = this.currentUser.LastName.trim();
    }
    if (
      !this.currentUser.RoleCode ||
      !this.currentUser.FirstName ||
      !this.currentUser.LastName ||
      !this.emailFormatValidated ||
      !this.currentUser.Email
    ) {
      this.emailIsInBlacklist = false;
      this.isValidated = false;
      return;
    }
    if (this.currentUser.Email) {
      const past = await this.checkDuplicateUser();
      if (past !== undefined) {
        if (past === "inBlacklist") {
          this.emailIsInBlacklist = true;
          this.isValidated = false;
          this.hasError = false;
        } else {
          this.emailIsInBlacklist = false;
          if (this.isCreate) {
            this.isValidated = past;
            this.hasError = !past;
          }
        }
      } else {
        this.isValidated = false;
      }
    }
  }
}
