import { MultipleDropdownComponent } from "./../../../../shared/components/multiple-dropdown/multiple-dropdown.component";
import { Component, OnInit, ViewChild } from "@angular/core";
import { BuyerService } from "src/app/services/buyer.service";
import { DashBoardService } from "../../services/dashboard.service";
import { SearchTermDS } from "src/app/interfaces/buyer";
import { AuthService } from "src/app/services/auth/auth.service";
import { privacyStatementState } from "src/app/interfaces/mapping";
import { ActivatedRoute } from "@angular/router";
import { OnBoardingService } from "../../services/onboarding.service";

type SearchTermType = { [key: string]: any };

export interface SearchTerm extends SearchTermType {
  supplierName?: string;
  companyCode?: Array<string>;
  SAPID?: string;
  category?: Array<string>;
  BRN?: string;
  createBuyer?: string;
  status?: string;
  detailStatus?: Array<string>;
  isMigrated?: number;
  isOnHold?: number;
  isInvited?: number;
  InvitedStatus?: string;
}

export interface SearchDashBoard {
  key1?: string;
  key2?: string;
}

enum DropdownTermType {
  CompanyCode = "companyCode",
  Category = "category",
  DetailStatus = "detailStatus",
}

@Component({
  selector: "app-dashboard-search",
  templateUrl: "./dashboard-search.component.html",
  styleUrls: ["./dashboard-search.component.sass"],
})
export class DashboardSearchComponent implements OnInit {
  @ViewChild("multipleDropDown") multipleDropDown: MultipleDropdownComponent;
  isEmptyChoice: boolean;
  suppliers: any = [];
  searchTermDS: SearchTermDS = {};

  currentStatus: "all" | "pending" | "onboarded" = "all";
  dropdownTermType = DropdownTermType;

  privacyStatement = privacyStatementState;

  isRequestor = false;
  isMigratedAndInactive = false;
  isMigratedAndInvitedSent = false;
  isOnHold = false;
  showWarning = false;
  isSearch = false;

  total = 0;

  query: {
    searchDashBoard: SearchDashBoard;
    currentPage: number;
    itemsPerPage: number;
    orderType: string;
    needInit: boolean;
  } = {
      searchDashBoard: {},
      currentPage: 1,
      itemsPerPage: 9,
      orderType: "DESC",
      needInit: false,
    };

  constructor(
    private buyerService: BuyerService,
    private route: ActivatedRoute,
    private auth: AuthService,
    private dashBoardService: DashBoardService,
    private onboardingService: OnBoardingService
  ) {
    this.onboardingService.profileStage = "search";
  }

  async ngOnInit() {
    this.dashBoardService.isDashBoard = true;
    this.dashBoardService.isSearchInTsm = true;
    this.dashBoardService.isTsmEnterOnboarding = false;
    this.loadData();
  }

  // the basic search
  loadData() {
    this.route.params.subscribe((data) => {
      this.query.searchDashBoard.key1 = data.key1;
      this.query.searchDashBoard.key2 = data.key2;
      this.dashBoardService.tsmKey1 = data.key1;
      this.dashBoardService.tsmKey2 = data.key2;
    });

    this.buyerService.supplierSearchTsm(this.query).then((data: any) => {
      this.suppliers = data.data.results;
      this.total = data.data.total;

      setTimeout(() => {
        const dashsdiv = document.getElementsByClassName("my-pagination")[0];
        if (dashsdiv) {
          const dashscontrol = dashsdiv.childNodes[0];
          if (dashscontrol) {
            const dashstemplate = dashscontrol.childNodes[0];
            if (dashstemplate) {
              const dashsul = dashstemplate.childNodes[0];
              if (dashsul) {
                for (let i = 0; i < dashsul.childNodes.length; i++) {
                  let dashsli = dashsul.childNodes[i];
                  if (dashsli.nodeName == 'LI') {
                    (dashsli as any).role = "group";
                  }
                }
              }
            }
          }
        }
      });
    });
  }

  accept() {
    this.privacyStatement.isDisplayAccept = false;
    this.auth.passport.buyer.Status = "understand";
    this.buyerService.updateUserStatus(this.auth.passport.buyer.Email);
  }
}
