import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  HostListener,
  ComponentFactoryResolver,
  AfterViewInit,
} from "@angular/core";
import { MultipleDropdownComponent } from "../../../../../shared/components/multiple-dropdown/multiple-dropdown.component";
import { CommonService } from "src/app/services/common.service";
import {
  DialogService,
  ButtonCommands,
  DialogType,
  ButtonCommand,
  ConfirmService,
} from "src/app/services/dialog.service";
import { BuyerService } from "src/app/services/buyer.service";
import { OnBoardingService } from "../../../services/onboarding.service";
import { ActivatedRoute, Router } from "@angular/router";
import { RiskAssessmentInfo } from "src/app/interfaces/requestForm";
import { cloneDeep, trim } from "src/app/utils";
import { OnBoardMessage } from "src/app/interfaces/buyer";
import {
  SpendCategoryGroup,
  SupplierPendingStatus,
  StatusType,
  SupplierStatus,
  LoadingUrlMapping,
} from "src/app/interfaces/mapping";
import VMController from "src/app/interfaces/vm";
import { CanComponentDeactivate } from "src/app/can-deactivate.guard";
import { AP_ReviewStatus } from "src/app/interfaces/mapping";
import { LoadingService } from "src/app/services/loading.service";
import { environment } from "src/environments/environment";
import {
  SetupProcess,
  ProcessNames,
  AllTaskKey,
  Task,
} from "src/app/interfaces/workflow";
import { ComponentConfig } from "src/app/dynamic-components/ComponentConfig";
import { MetadataService } from "src/app/services/metadata.service";
import { DynamicContext } from "src/app/dynamic-components/interfaces/DynamicContext";
import { DynamicFormComponent } from "src/app/dynamic-components/components/dynamic-form/dynamic-form.component";
import { DictionaryService } from "src/app/services/dictionary.service";
import { BuyerCommonService } from "../../../services/buyerCommonService";
import { AuthService } from "src/app/services/auth/auth.service";
import { BaseComponent } from "src/app/dynamic-components/components/base/base.component";
import { AuditChangeRequestDetail, ChangeRequestType, initAuditChangeRequestDetail, initChangeRequestMapping, RiskAssessmentInfoCRData } from "src/app/interfaces/changeRequest";
import moment from "moment";
import { BuyerChangeRequestService, } from "../../../services/buyerChangeRequest.service";
import { Subscription } from "rxjs";

@Component({
  selector: "app-risk-assessmentCR",
  templateUrl: "./risk-assessmentCR.component.html",
  styleUrls: ["./risk-assessmentCR.component.sass"],
})
export class RiskAssessmentComponentCR  implements OnInit, AfterViewInit,CanComponentDeactivate {
  // extends BaseComponent
  subscription: Subscription;
  AP_ReviewStatus = AP_ReviewStatus;
  SupplierPendingStatus = SupplierPendingStatus;
  // // detailStatus: string  // old status verify
  // // apReviewStatus: string  // old status verify

  showAlertPanelO = false;
  showAlertPanel1 = false;
  env = environment.env;
  activeDes = "";
  getConfigsInfo: ComponentConfig[];
  context: DynamicContext;
  comments: string;
  @ViewChild("dynamicForm") dynamicForm: DynamicFormComponent;
  vm: VMController<RiskAssessmentInfo> = new VMController();
  get riskAssessmentInfo(): RiskAssessmentInfo {
    return this.onBoardingService.supplierModel.RequestForm.RiskAssessmentInfo;
  }


  onboardInfo: any;

  // vm: VMController<RiskAssessmentInfo> = new VMController();
  isResubmitDisabled = true;
  isInformationSecurityShow = false;
  isReTriggerCoupa: boolean = true;

  private auditCR = initAuditChangeRequestDetail();
  constructor(
    private router: Router,
    private onBoardingService: OnBoardingService,
    private commonService: CommonService,
    private dialogService: DialogService,
    private buyerService: BuyerService,
    private metadataService: MetadataService,
    private dictionaryService: DictionaryService,
    private loadingService: LoadingService,
    private authService: AuthService,
    private buyerCommonService: BuyerCommonService,
    private confirmService: ConfirmService,
    private buyerChangeRequest: BuyerChangeRequestService,
    private route: ActivatedRoute,
  ) {
    this.subscription = confirmService.sendAudit$.subscribe((audit) =>
    {
      this.auditCR = audit;
    });
  }

  async ngOnInit() {
    this.loadingService.closeLoading();
    this.context = new DynamicContext();
    await this.buyerCommonService.SetContextValue(this.context);
    this.context.mode = this.onBoardingService.mode;
    this.context.set(
      "parentModel",
      cloneDeep(this.onBoardingService.supplierModel)
    );
    this.confirmService.send();

    this.dynamicForm.subscribeToFormChange(() => {
      setTimeout(() => {
        this.isReTriggerCoupa =
          JSON.stringify(this.dynamicForm.originalValues()) ===
          JSON.stringify(this.dynamicForm.values());
      });
    });
  }

  async submitCR() {
    this.context.changeSubmitState();
    this.dynamicForm.removeFormTouch();
    if (!this.validate()) {
      return false;
    }
    const RAInfoCR = new RiskAssessmentInfoCRData();

    if (
      this.onBoardingService.supplierModel.RequestForm.RiskAssessmentInfo.BI_BDA_1 !== this.dynamicForm.values().BI_BDA_1
    ) {
      RAInfoCR.BI_BDA_1 = {
        oldValue:
          this.onBoardingService.supplierModel.RequestForm.RiskAssessmentInfo.BI_BDA_1,
        newValue:
          this.dynamicForm.values().BI_BDA_1,
      };
    } else {
      RAInfoCR.BI_BDA_1 = {
        oldValue:
          this.onBoardingService.supplierModel.RequestForm.RiskAssessmentInfo.BI_BDA_1,
      };
    }

    if (
      this.onBoardingService.supplierModel.RequestForm.RiskAssessmentInfo.BI_BDA_1a !== this.dynamicForm.values().BI_BDA_1a
    ) {
      RAInfoCR.BI_BDA_1a = {
        oldValue:
          this.onBoardingService.supplierModel.RequestForm.RiskAssessmentInfo.BI_BDA_1a,
        newValue:
          this.dynamicForm.values().BI_BDA_1a,
      };
    } else {
      RAInfoCR.BI_BDA_1a = {
        oldValue:
          this.onBoardingService.supplierModel.RequestForm.RiskAssessmentInfo.BI_BDA_1a,
      };
    }

    if (
      this.onBoardingService.supplierModel.RequestForm.RiskAssessmentInfo.BI_BDA_1b !== this.dynamicForm.values().BI_BDA_1b
    ) {
      RAInfoCR.BI_BDA_1b = {
        oldValue:
          this.onBoardingService.supplierModel.RequestForm.RiskAssessmentInfo.BI_BDA_1b,
        newValue:
          this.dynamicForm.values().BI_BDA_1b,
      };
    } else {
      RAInfoCR.BI_BDA_1b = {
        oldValue:
          this.onBoardingService.supplierModel.RequestForm.RiskAssessmentInfo.BI_BDA_1b,
      };
    }

    if (
      this.onBoardingService.supplierModel.RequestForm.RiskAssessmentInfo.BI_BDA_1bStartDate !== this.dynamicForm.values().BI_BDA_1bStartDate
    ) {
      RAInfoCR.BI_BDA_1bStartDate = {
        oldValue:
          this.onBoardingService.supplierModel.RequestForm.RiskAssessmentInfo.BI_BDA_1bStartDate,
        newValue:
          this.dynamicForm.values().BI_BDA_1bStartDate,
      };
    } else {
      RAInfoCR.BI_BDA_1bStartDate = {
        oldValue:
          this.onBoardingService.supplierModel.RequestForm.RiskAssessmentInfo.BI_BDA_1bStartDate,
      };
    }

    if (
      this.onBoardingService.supplierModel.RequestForm.RiskAssessmentInfo.BI_BDA_1bEndDate !== this.dynamicForm.values().BI_BDA_1bEndDate
    ) {
      RAInfoCR.BI_BDA_1bEndDate = {
        oldValue:
          this.onBoardingService.supplierModel.RequestForm.RiskAssessmentInfo.BI_BDA_1bEndDate,
        newValue:
          this.dynamicForm.values().BI_BDA_1bEndDate,
      };
    } else {
      RAInfoCR.BI_BDA_1bEndDate = {
        oldValue:
          this.onBoardingService.supplierModel.RequestForm.RiskAssessmentInfo.BI_BDA_1bEndDate,
      };
    }

    if (
      this.onBoardingService.supplierModel.RequestForm.RiskAssessmentInfo.BI_BDA_1c !== this.dynamicForm.values().BI_BDA_1c
    ) {
      RAInfoCR.BI_BDA_1c = {
        oldValue:
          this.onBoardingService.supplierModel.RequestForm.RiskAssessmentInfo.BI_BDA_1c,
        newValue:
          this.dynamicForm.values().BI_BDA_1c,
      };
    } else {
      RAInfoCR.BI_BDA_1c = {
        oldValue:
          this.onBoardingService.supplierModel.RequestForm.RiskAssessmentInfo.BI_BDA_1c,
      };
    }

    if (
      this.onBoardingService.supplierModel.RequestForm.RiskAssessmentInfo.BI_BDA_2 !== this.dynamicForm.values().BI_BDA_2
    ) {
      RAInfoCR.BI_BDA_2 = {
        oldValue:
          this.onBoardingService.supplierModel.RequestForm.RiskAssessmentInfo.BI_BDA_2,
        newValue:
          this.dynamicForm.values().BI_BDA_2,
      };
    } else {
      RAInfoCR.BI_BDA_2 = {
        oldValue:
          this.onBoardingService.supplierModel.RequestForm.RiskAssessmentInfo.BI_BDA_2,
      };
    }

    if (
      this.dynamicForm.values().InformationSecurity
    ) {
      RAInfoCR.InformationSecurity = {
        newValue:
          this.dynamicForm.values().InformationSecurity,
      };
    }

    if (
      this.onBoardingService.supplierModel.RequestForm.RiskAssessmentInfo.InformationSecurity_1 !== this.dynamicForm.values().InformationSecurity_1
    ) {
      RAInfoCR.InformationSecurity_1 = {
        oldValue:
          this.onBoardingService.supplierModel.RequestForm.RiskAssessmentInfo.InformationSecurity_1,
        newValue:
          this.dynamicForm.values().InformationSecurity_1,
      };
    } else {
      RAInfoCR.InformationSecurity_1 = {
        oldValue:
          this.onBoardingService.supplierModel.RequestForm.RiskAssessmentInfo.InformationSecurity_1,
      };
    }

    if (
      this.onBoardingService.supplierModel.RequestForm.RiskAssessmentInfo.InformationSecurity_1Yes !== this.dynamicForm.values().InformationSecurity_1Yes
    ) {
      RAInfoCR.InformationSecurity_1Yes = {
        oldValue:
          this.onBoardingService.supplierModel.RequestForm.RiskAssessmentInfo.InformationSecurity_1Yes,
        newValue:
          this.dynamicForm.values().InformationSecurity_1Yes,
      };
    } else {
      RAInfoCR.InformationSecurity_1Yes = {
        oldValue:
          this.onBoardingService.supplierModel.RequestForm.RiskAssessmentInfo.InformationSecurity_1Yes,
      };
    }

    if (
      this.dynamicForm.values().InformationSecurity_1a_new
    ) {
      RAInfoCR.InformationSecurity_1a_new = {
        newValue:
          this.dynamicForm.values().InformationSecurity_1a_new,
      };
    }

    if (
      this.dynamicForm.values().InformationSecurity_1a_no
    ) {
      RAInfoCR.InformationSecurity_1a_no = {
        newValue:
          this.dynamicForm.values().InformationSecurity_1a_no,
      };
    }
    if (
      this.dynamicForm.values().InformationSecurity_1b_new
    ) {
      RAInfoCR.InformationSecurity_1b_new = {
        newValue:
          this.dynamicForm.values().InformationSecurity_1b_new,
      };
    }

    if (
      this.dynamicForm.values().InformationSecurity_1c_new
    ) {
      RAInfoCR.InformationSecurity_1c_new = {
        newValue:
          this.dynamicForm.values().InformationSecurity_1c_new,
      };
    }

    if (
      this.dynamicForm.values().InformationSecurity_1ca_2
    ) {
      RAInfoCR.InformationSecurity_1ca_2 = {
        newValue:
          this.dynamicForm.values().InformationSecurity_1ca_2,
      };
    }

    if (
      this.dynamicForm.values().InformationSecurity_1ca_2_other
    ) {
      RAInfoCR.InformationSecurity_1ca_2_other = {
        newValue:
          this.dynamicForm.values().InformationSecurity_1ca_2_other,
      };
    }

    if (
      this.dynamicForm.values().InformationSecurity_1cb_new
    ) {
      RAInfoCR.InformationSecurity_1cb_new = {
        newValue:
          this.dynamicForm.values().InformationSecurity_1cb_new,
      };
    }

    if (
      this.dynamicForm.values().InformationSecurity_1cb_other
    ) {
      RAInfoCR.InformationSecurity_1cb_other = {
        newValue:
          this.dynamicForm.values().InformationSecurity_1cb_other,
      };
    }

    if (
      this.dynamicForm.values().InformationSecurity_1c_1
    ) {
      RAInfoCR.InformationSecurity_1c_1 = {
        newValue:
          this.dynamicForm.values().InformationSecurity_1c_1,
      };
    }

    if (
      this.dynamicForm.values().InformationSecurity_1c_2
    ) {
      RAInfoCR.InformationSecurity_1c_2 = {
        newValue:
          this.dynamicForm.values().InformationSecurity_1c_2,
      };
    }

    if (
      this.dynamicForm.values().InformationSecurity_1c_2_other
    ) {
      RAInfoCR.InformationSecurity_1c_2_other = {
        newValue:
          this.dynamicForm.values().InformationSecurity_1c_2_other,
      };
    }

    if (
      this.dynamicForm.values().InformationSecurity_1cg_new
    ) {
      RAInfoCR.InformationSecurity_1cg_new = {
        newValue:
          this.dynamicForm.values().InformationSecurity_1cg_new,
      };
    }

    if (
      this.dynamicForm.values().InformationSecurity_1cf_new
    ) {
      RAInfoCR.InformationSecurity_1cf_new = {
        newValue:
          this.dynamicForm.values().InformationSecurity_1cf_new,
      };
    }

    if (
      this.dynamicForm.values().InformationSecurity_1ce_1
    ) {
      RAInfoCR.InformationSecurity_1ce_1 = {
        newValue:
          this.dynamicForm.values().InformationSecurity_1ce_1,
      };
    }
    if (
      this.dynamicForm.values().InformationSecurity_1d_1
    ) {
      RAInfoCR.InformationSecurity_1d_1 = {
        newValue:
          this.dynamicForm.values().InformationSecurity_1d_1,
      };
    }

    if (
      this.dynamicForm.values().InformationSecurity_1e_new
    ) {
      RAInfoCR.InformationSecurity_1e_new = {
        newValue:
          this.dynamicForm.values().InformationSecurity_1e_new,
      };
    }

    if (
      this.dynamicForm.values().InformationSecurity_1eYes
    ) {
      RAInfoCR.InformationSecurity_1eYes = {
        newValue:
          this.dynamicForm.values().InformationSecurity_1eYes,
      };
    }

    if (
      this.dynamicForm.values().InformationSecurity_1cd_new
    ) {
      RAInfoCR.InformationSecurity_1cd_new = {
        newValue:
          this.dynamicForm.values().InformationSecurity_1cd_new,
      };
    }

    if (
      this.dynamicForm.values().InformationSecurity_1cd_5
    ) {
      RAInfoCR.InformationSecurity_1cd_5 = {
        newValue:
          this.dynamicForm.values().InformationSecurity_1cd_5,
      };
    }
    if (
      this.dynamicForm.values().InformationSecurity_1cd_5_other
    ) {
      RAInfoCR.InformationSecurity_1cd_5_other = {
        newValue:
          this.dynamicForm.values().InformationSecurity_1cd_5_other,
      };
    }

    if (
      this.dynamicForm.values().InformationSecurity_1cd_2
    ) {
      RAInfoCR.InformationSecurity_1cd_2 = {
        newValue:
          this.dynamicForm.values().InformationSecurity_1cd_2,
      };
    }

    if (
      this.dynamicForm.values().InformationSecurity_1cd_3
    ) {
      RAInfoCR.InformationSecurity_1cd_3 = {
        newValue:
          this.dynamicForm.values().InformationSecurity_1cd_3,
      };
    }

    if (
      this.dynamicForm.values().InformationSecurity_1cd_3_1
    ) {
      RAInfoCR.InformationSecurity_1cd_3_1 = {
        newValue:
          this.dynamicForm.values().InformationSecurity_1cd_3_1,
      };
    }
    if (
      this.dynamicForm.values().InformationSecurity_1cd_4
    ) {
      RAInfoCR.InformationSecurity_1cd_4 = {
        newValue:
          this.dynamicForm.values().InformationSecurity_1cd_4,
      };
    }

    if (
      this.dynamicForm.values().InformationSecurity_1cd_4_YES
    ) {
      RAInfoCR.InformationSecurity_1cd_4_YES = {
        newValue:
          this.dynamicForm.values().InformationSecurity_1cd_4_YES,
      };
    }

    if (
      this.dynamicForm.values().InformationSecurity_1cd_4_NO
    ) {
      RAInfoCR.InformationSecurity_1cd_4_NO = {
        newValue:
          this.dynamicForm.values().InformationSecurity_1cd_4_NO,
      };
    }

    if (
      this.dynamicForm.values().InformationSecurity_1f_1
    ) {
      RAInfoCR.InformationSecurity_1f_1 = {
        newValue:
          this.dynamicForm.values().InformationSecurity_1f_1,
      };
    }

    if (
      this.onBoardingService.supplierModel.RequestForm.RiskAssessmentInfo.InformationSecurity_1i !== this.dynamicForm.values().InformationSecurity_1i
    ) {
      RAInfoCR.InformationSecurity_1i = {
        oldValue:
          this.onBoardingService.supplierModel.RequestForm.RiskAssessmentInfo.InformationSecurity_1i,
        newValue:
          this.dynamicForm.values().InformationSecurity_1i,
      };
    } else {
      RAInfoCR.InformationSecurity_1i = {
        oldValue:
          this.onBoardingService.supplierModel.RequestForm.RiskAssessmentInfo.InformationSecurity_1i,
      };
    }

    if (
      this.onBoardingService.supplierModel.RequestForm.RiskAssessmentInfo.InformationSecurity_1j !== this.dynamicForm.values().InformationSecurity_1j
    ) {
      RAInfoCR.InformationSecurity_1j = {
        oldValue:
          this.onBoardingService.supplierModel.RequestForm.RiskAssessmentInfo.InformationSecurity_1j,
        newValue:
          this.dynamicForm.values().InformationSecurity_1j,
      };
    } else {
      RAInfoCR.InformationSecurity_1j = {
        oldValue:
          this.onBoardingService.supplierModel.RequestForm.RiskAssessmentInfo.InformationSecurity_1j,
      };
    }

    if (
      this.dynamicForm.values().InformationSecurity_4
    ) {
      RAInfoCR.InformationSecurity_4 = {
        newValue:
          this.dynamicForm.values().InformationSecurity_4,
      };
    }

    if (
      this.dynamicForm.values().InformationSecurity_4a
    ) {
      RAInfoCR.InformationSecurity_4a = {
        newValue:
          this.dynamicForm.values().InformationSecurity_4a,
      };
    }

    if (
      this.dynamicForm.values().InformationSecurity_5
    ) {
      RAInfoCR.InformationSecurity_5 = {
        newValue:
          this.dynamicForm.values().InformationSecurity_5,
      };
    }

    if (
      this.dynamicForm.values().InformationSecurity_6
    ) {
      RAInfoCR.InformationSecurity_6 = {
        newValue:
          this.dynamicForm.values().InformationSecurity_6,
      };
    }

    if (
      this.dynamicForm.values().InformationSecurity_1g_1
    ) {
      RAInfoCR.InformationSecurity_1g_1 = {
        newValue:
          this.dynamicForm.values().InformationSecurity_1g_1,
      };
    }
    if (
      this.dynamicForm.values().InformationSecurity_1h_1
    ) {
      RAInfoCR.InformationSecurity_1h_1 = {
        newValue:
          this.dynamicForm.values().InformationSecurity_1h_1,
      };
    }

    if (
      this.onBoardingService.supplierModel.RequestForm.RiskAssessmentInfo.InformationSecurity_2 !== this.dynamicForm.values().InformationSecurity_2
    ) {
      RAInfoCR.InformationSecurity_2 = {
        oldValue:
          this.onBoardingService.supplierModel.RequestForm.RiskAssessmentInfo.InformationSecurity_2,
        newValue:
          this.dynamicForm.values().InformationSecurity_2,
      };
    } else {
      RAInfoCR.InformationSecurity_2 = {
        oldValue:
          this.onBoardingService.supplierModel.RequestForm.RiskAssessmentInfo.InformationSecurity_2,
      };
    }

    if (
      this.onBoardingService.supplierModel.RequestForm.RiskAssessmentInfo.InformationSecurity_2a !== this.dynamicForm.values().InformationSecurity_2a
    ) {
      RAInfoCR.InformationSecurity_2a = {
        oldValue:
          this.onBoardingService.supplierModel.RequestForm.RiskAssessmentInfo.InformationSecurity_2a,
        newValue:
          this.dynamicForm.values().InformationSecurity_2a,
      };
    } else {
      RAInfoCR.InformationSecurity_2a = {
        oldValue:
          this.onBoardingService.supplierModel.RequestForm.RiskAssessmentInfo.InformationSecurity_2a,
      };
    }

    if (
      this.onBoardingService.supplierModel.RequestForm.RiskAssessmentInfo.InformationSecurity_3 !== this.dynamicForm.values().InformationSecurity_3
    ) {
      RAInfoCR.InformationSecurity_3 = {
        oldValue:
          this.onBoardingService.supplierModel.RequestForm.RiskAssessmentInfo.InformationSecurity_3,
        newValue:
          this.dynamicForm.values().InformationSecurity_3,
      };
    } else {
      RAInfoCR.InformationSecurity_3 = {
        oldValue:
          this.onBoardingService.supplierModel.RequestForm.RiskAssessmentInfo.InformationSecurity_3,
      };
    }

    if (
      this.dynamicForm.values().InformationSecurity_12
    ) {
      RAInfoCR.InformationSecurity_12 = {
        newValue:
          this.dynamicForm.values().InformationSecurity_12,
      };
    }

    if (
      this.onBoardingService.supplierModel.RequestForm.RiskAssessmentInfo.InformationSecurity_3a !== this.dynamicForm.values().InformationSecurity_3a
    ) {
      RAInfoCR.InformationSecurity_3a = {
        oldValue:
          this.onBoardingService.supplierModel.RequestForm.RiskAssessmentInfo.InformationSecurity_3a,
        newValue:
          this.dynamicForm.values().InformationSecurity_3a,
      };
    } else {
      RAInfoCR.InformationSecurity_3a = {
        oldValue:
          this.onBoardingService.supplierModel.RequestForm.RiskAssessmentInfo.InformationSecurity_3a,
      };
    }

    this.auditCR.isChangeRACR = true;
    this.auditCR.RiskAssessmentInfoCRData.push(RAInfoCR);

    const supplier = this.onBoardingService.supplierModel;
    const request = initChangeRequestMapping(supplier.Mapping.supplierCode);
    request.supplierCode = supplier.Mapping.supplierCode;
    request.requestType =
      ChangeRequestType.UPDATE_AmendSpendCommodityCategoryGroup;

    let requestValueNew = this.dynamicForm.values();
    requestValueNew.SpendCommodityGroup = this.auditCR.addAmendSpendCommodityCategoryGroup[0].SpendCommodityGroup.newValue
      ? this.auditCR.addAmendSpendCommodityCategoryGroup[0].SpendCommodityGroup.newValue
      : this.auditCR.addAmendSpendCommodityCategoryGroup[0].SpendCommodityGroup.oldValue;
    requestValueNew.SpendCategoryGroup = this.auditCR.addAmendSpendCommodityCategoryGroup[0].SpendCategoryGroup.newValue
      ? this.auditCR.addAmendSpendCommodityCategoryGroup[0].SpendCategoryGroup.newValue
      : this.auditCR.addAmendSpendCommodityCategoryGroup[0].SpendCategoryGroup.oldValue;

    if(this.auditCR.addAmendSpendCommodityCategoryGroup[0].IndependentContractor.newValue){
      requestValueNew.IndependentContractor = this.auditCR.addAmendSpendCommodityCategoryGroup[0].IndependentContractor.newValue;
    }else{
      if(this.auditCR.addAmendSpendCommodityCategoryGroup[0].IndependentContractor.newValue === '5002' || this.auditCR.addAmendSpendCommodityCategoryGroup[0].IndependentContractor.newValue === '5021'){
        requestValueNew.IndependentContractor = this.auditCR.addAmendSpendCommodityCategoryGroup[0].IndependentContractor.oldValue;
      }else{
        requestValueNew.IndependentContractor = ''
      }
    }
    
    request.requestValue = JSON.stringify(requestValueNew);
    request.createdOn = moment.utc().toString();
    request.createdBy = this.authService.passport.profile.name;
    request.supplierModel = supplier;
    request.audit = [this.auditCR];
    request.openFlag = true;

    const saveUrl = `${environment.gateway}${LoadingUrlMapping.addChangeRequest}`;
    this.loadingService.openLoading(saveUrl);

    await this.buyerChangeRequest.submitRequestor(request);
    await this.router.navigate([
      `buyer/onboarding/process/${this.onBoardingService.supplierCode}`,
    ], {replaceUrl: true})
    return;
  }

  async submitCloseCR() {
    this.context.changeSubmitState();
    this.dynamicForm.removeFormTouch();
    if (!this.validate()) {
      return false;
    }

    this.auditCR.isChangeRACR = false;

    const supplier = this.onBoardingService.supplierModel;
    const request = initChangeRequestMapping(supplier.Mapping.supplierCode);
    request.supplierCode = supplier.Mapping.supplierCode;
    request.requestType =
      ChangeRequestType.UPDATE_AmendSpendCommodityCategoryGroup;

      let requestValueNew = this.dynamicForm.values();
      requestValueNew.SpendCommodityGroup = this.auditCR.addAmendSpendCommodityCategoryGroup[0].SpendCommodityGroup.newValue
        ? this.auditCR.addAmendSpendCommodityCategoryGroup[0].SpendCommodityGroup.newValue
        : this.auditCR.addAmendSpendCommodityCategoryGroup[0].SpendCommodityGroup.oldValue;
      requestValueNew.SpendCategoryGroup = this.auditCR.addAmendSpendCommodityCategoryGroup[0].SpendCategoryGroup.newValue
        ? this.auditCR.addAmendSpendCommodityCategoryGroup[0].SpendCategoryGroup.newValue
        : this.auditCR.addAmendSpendCommodityCategoryGroup[0].SpendCategoryGroup.oldValue;
      
      if(this.auditCR.addAmendSpendCommodityCategoryGroup[0].IndependentContractor.newValue){
        requestValueNew.IndependentContractor = this.auditCR.addAmendSpendCommodityCategoryGroup[0].IndependentContractor.newValue;
      }else{
        if(this.auditCR.addAmendSpendCommodityCategoryGroup[0].IndependentContractor.newValue === '5002' || this.auditCR.addAmendSpendCommodityCategoryGroup[0].IndependentContractor.newValue === '5021'){
          requestValueNew.IndependentContractor = this.auditCR.addAmendSpendCommodityCategoryGroup[0].IndependentContractor.oldValue;
        }else{
          requestValueNew.IndependentContractor = ''
        }
      }

    request.requestValue = JSON.stringify(requestValueNew);
    request.createdOn = moment.utc().toString();
    request.createdBy = this.authService.passport.profile.name;
    request.supplierModel = supplier;
    request.audit = [this.auditCR];
    request.openFlag = true;

    const saveUrl = `${environment.gateway}${LoadingUrlMapping.addChangeRequest}`;
    this.loadingService.openLoading(saveUrl);

    await this.buyerChangeRequest.submitRequestor(request);
    await this.router.navigate([
      `buyer/onboarding/process/${this.onBoardingService.supplierCode}`,
    ], {replaceUrl: true})
    return;
  }

  async cancel(){
    await this.router.navigate([
      `buyer/supplier/${this.onBoardingService.supplierCode}`,
    ], {replaceUrl: true})
    return;
  }

  ngAfterViewInit(){
    this.getConfigsInfo = [
      {
        id: 'InformationSecurity',
        label: 'Information Security & Data Protection & Intellectual Property',
        type: 'collapse',
        width: '100%',
        hide: false,
        templateOptions: {
          deep: '1',
        },
        fieldset: [
          {
            name: 'InformationSecurity_1',
            label: 'Have you already initiated an Information Security/Data Protection review/assessment?',
            type: 'dropdown',
            width: '100%',
            required: true,
            errorMessage: {
              'required': 'Please select'
            },
            templateOptions: {
              boxWidth: { width: '50%' },
            },
            dataSource: {
              name: 'dictionary',
              params: 'RiskAssessment.dp_InformationSecurity_1',
              loadOnInit: true
            }
          },
          {
            name: 'InformationSecurity_1Yes',
            width: '100%',
            templateOptions: {
              boxWidth: { width: '50%' },
            },
            hide: true,
            type: 'input',
            errorMessage: {
              'required': 'Please Input',
              'RelationshipIDExists': 'Record does not exist'
            },
            required: true,
            validateOn: 'blur',
            label: 'If Yes enter your IS/Data Protection Relationship ID:',
            tooltip: 'IS/Data Protection Relationship IDs can be found in the email confirmation received from Hiperos (this usually take a format similar to R12345)',
            validators: [
              {
                name: 'RelationshipIDExists'
              }
            ],
            eventListeners: [
              {
                'event': 'change:model.InformationSecurity_1',
                'actions': [
                  {
                    'name': 'setVisible',
                    'params': '$value==11'
                  }
                ]
              }
            ]
          },
          {
            name: 'InformationSecurity_1a_new',
            type: 'dropdown',
            width: '100%',
            required: true,
            hide: true,
            templateOptions: {
              boxWidth: { width: '50%' },
            },
            label: 'Will the supplier receive, access, use, host, store, process, collect, record, organise, transfer, disclose, adapt, combine, erase or make available Accenture data and/or client data?',
            subLabel: '(Access refers to the supplier’s ability to retrieve or receive Accenture or Client Data and move, process, manipulate or store/host such data.)',
            tooltip: 'Accenture data/Client data includes also Personal Data (see the definition in Question 3.0 below) of, included but not limited to, employees/contractors/personnel of Accenture (and its affiliates), clients of Accenture (and its affiliates), third-party users of websites/applications and job applicants.',
            errorMessage: {
              'required': 'Please select'
            },
            dataSource: {
              name: 'dictionary',
              params: 'RiskAssessment.dp_InformationSecurity_1a_new',
              loadOnInit: true
            },
            eventListeners: [
              {
                'event': 'change:model.InformationSecurity_1',
                'actions': [
                  {
                    'name': 'setVisible',
                    'params': '$value==10'
                  }
                ]
              }
            ]
          },
          {
            name: 'InformationSecurity_1a_no',
            type: 'input',
            width: '100%',
            required: true,
            hide: true,
            label: 'Please explain',
            templateOptions: {
              boxWidth: { width: '50%' },
            },
            errorMessage: {
              'required': 'Please input'
            },
            eventListeners: [
              {
                'event': 'change:model.InformationSecurity_1a_new',
                'actions': [
                  {
                    'name': 'setVisible',
                    'params': '$value==10'
                  }
                ]
              }
            ]
          },
          {
            name: 'InformationSecurity_1b_new',
            type: 'dropdown',
            hide: true,
            width: '100%',
            required: true,
            templateOptions: {
              boxWidth: { width: '50%' },
            },
            label: 'Will the Supplier provide software/hardware that will be installed within an Accenture environment/facility for Accenture\'s purposes, processing Accenture Personal Data?',
            errorMessage: {
              'required': 'Please select'
            },
            dataSource: {
              name: 'dictionary',
              params: 'RiskAssessment.dp_InformationSecurity_1b',
              loadOnInit: true
            },
            eventListeners: [
              {
                'event': 'change:model.InformationSecurity_1a_new',
                'actions': [
                  {
                    'name': 'setVisible',
                    'params': '$value==10'
                  }
                ]
              }

            ]
          },
          {
            name: 'InformationSecurity_1c_new',
            type: 'dropdown',
            label: 'Will the Supplier receive or have access to Personal Data (excluding Business Contact Information)?',
            tooltip: `<ul class="link-mare-item">
                        <li>This includes Personal Data of, included but not limited to, employees/contractors/personnel of Accenture (and its affiliates), clients of Accenture (and its affiliates), third-party users of websites/applications and job applicants, that may be shared by Accenture/Client or received directly from the data subjects in question (e.g. Accenture employees).</li>
                        <li>"Business Contact Information" in the context of a supplier relationship means the contact information for the people involved in the contracting and purchasing process. This exclusion is to allow for minimal exchange of contact information that will be needed while entering into agreements with suppliers. This typically includes contact information for members of the Procurement, Legal and Business teams involved in contracting and subsequent Supplier Relationship Management processes.</li>
                      </ul>
            `,
            width: '100%',
            required: true,
            hide: true,
            errorMessage: {
              'required': 'Please select'
            },
            templateOptions: {
              boxWidth: { width: '50%' },
            },
            dataSource: {
              name: 'dictionary',
              params: 'RiskAssessment.dp_InformationSecurity_1c',
              loadOnInit: true
            },
            eventListeners: [
              {
                'event': 'change:model.InformationSecurity_1a_new',
                'actions': [
                  {
                    'name': 'setVisible',
                    'params': '$value==11'
                  }
                ]
              }
            ]
          },
          {
            name: 'InformationSecurity_1ca_2',
            label: 'What types of Personal Data? (Select all that apply)',
            type: 'multiple',
            width: '100%',
            hide: true,
            required: true,
            tooltip: `<ul class="link-mare-item">
                        <li>Health data may include any of the following:</li>
                        <li>Medical Conditions</li>
                        <li>Medical Treatment</li>
                        <li>Payment for Medical Services</li>
                        <li>Medical Insurance Data</li>
                        <li>US Protected Health Information covered under HIPAA</li>
                      </ul>
                     `,
            errorMessage: {
              'required': 'Please select'
            },
            dataSource: {
              name: 'dictionary',
              params: 'RiskAssessment.dp_InformationSecurity_1ca_2',
              loadOnInit: true
            },
            templateOptions: {
              boxWidth: { width: '50%' },
              exclusiveKey: [
              ]
            },
            eventListeners: [
              {
                'event': 'change:model.InformationSecurity_1a_new',
                'actions': [
                  {
                    'name': 'setVisible',
                    'params': '$value == 11 && $formGroup.controls["InformationSecurity_1c_new"].value != 10'
                  }
                ]
              },
              {
                'event': 'change:model.InformationSecurity_1c_new',
                'actions': [
                  {
                    'name': 'setVisible',
                    'params': '$formGroup.controls["InformationSecurity_1a_new"].value == 11 && $value != 10'
                  }
                ]
              }
            ]
          },
          {
            name: 'InformationSecurity_1ca_2_other',
            type: 'input',
            width: '100%',
            required: true,
            hide: true,
            label: 'Please explain',
            templateOptions: {
              boxWidth: { width: '50%' },
            },
            errorMessage: {
              'required': 'Please input'
            },
            eventListeners: [
              {
                'event': 'change:model.InformationSecurity_1ca_2',
                'actions': [
                  {
                    'name': 'setVisibleForExplain',
                  }
                ]
              },
              {
                'event': 'change:model.InformationSecurity_1c_new',
                'actions': [
                  {
                    'name': 'setVisibleForInformationSecurity_1ca_2',
                  }
                ]
              }
            ]
          },
          {
            width: '100%',
            name: 'InformationSecurity_1cb_new',
            label: 'Who does the Personal Data relate to? (Select all that apply)',
            type: 'multiple',
            hide: true,
            required: true,
            errorMessage: {
              'required': 'Please select'
            },
            dataSource: {
              name: 'dictionary',
              params: 'RiskAssessment.dp_InformationSecurity_1cb_new',
              loadOnInit: true
            },
            templateOptions: {
              boxWidth: { width: '50%' },
              exclusiveKey: 'Not Sure (Please note this may delay the process)'
            },
            eventListeners: [
              {
                'event': 'change:model.InformationSecurity_1c_new',
                'actions': [
                  {
                    'name': 'setVisible',
                    'params': '$value==11'
                  }
                ]
              }
            ]
          },
          {
            name: 'InformationSecurity_1cb_other',
            type: 'input',
            width: '100%',
            required: true,
            hide: true,
            label: 'Please explain',
            templateOptions: {
              boxWidth: { width: '50%' },
            },
            errorMessage: {
              'required': 'Please input'
            },
            eventListeners: [
              {
                'event': 'change:model.InformationSecurity_1cb_new',
                'actions': [
                  {
                    'name': 'setVisibleForExplain',
                  }
                ]
              }
            ]
          },
          {
            name: 'InformationSecurity_1c_1',
            type: 'multiple',
            label: 'Will the Supplier conduct any of the following processing activities? (Select all that apply)',
            width: '100%',
            required: true,
            hide: true,
            errorMessage: {
              'required': 'Please select'
            },
            templateOptions: {
              boxWidth: { width: '50%' },
              exclusiveKey: '18'
            },
            dataSource: {
              name: 'dictionary',
              params: 'RiskAssessment.dp_InformationSecurity_1c_1',
              loadOnInit: true
            },
            eventListeners: [
              {
                'event': 'change:model.InformationSecurity_1c_new',
                'actions': [
                  {
                    'name': 'setVisible',
                    'params': '$value==11'
                  }
                ]
              }
            ]
          },
          {
            name: 'InformationSecurity_1c_2',
            type: 'multiple',
            label: 'Will the Supplier use, or process, or disclose or make available to a third-party, Personal Data for any purpose other than to provide the products/services? (Select all that apply)',
            width: '100%',
            required: true,
            hide: true,
            errorMessage: {
              'required': 'Please select'
            },
            templateOptions: {
              boxWidth: { width: '50%' },
            },
            dataSource: {
              name: 'dictionary',
              params: 'RiskAssessment.dp_InformationSecurity_1c_2',
              loadOnInit: true
            },
            eventListeners: [
              {
                'event': 'change:model.InformationSecurity_1c_new',
                'actions': [
                  {
                    'name': 'setVisible',
                    'params': '$value==11'
                  }
                ]
              }
            ]
          },
          {
            name: 'InformationSecurity_1c_2_other',
            type: 'input',
            width: '100%',
            required: true,
            hide: true,
            label: 'Please explain',
            templateOptions: {
              boxWidth: { width: '50%' },
            },
            errorMessage: {
              'required': 'Please input'
            },
            eventListeners: [
              {
                'event': 'change:model.InformationSecurity_1c_2',
                'actions': [
                  {
                    'name': 'setVisibleForExplain',
                  }
                ]
              }
            ]
          },
          // {
          //   width: '100%',
          //   name: 'InformationSecurity_1cc',
          //   label: 'c) Will Supplier have access to any data for people in these groups?',
          //   type: 'multiple',
          //   required: true,
          //   errorMessage: {
          //     'required': 'Please select'
          //   },
          //   hide: true,
          //   dataSource: {
          //     'name': 'dictionary',
          //     'params': 'RiskAssessment.dp_InformationSecurity_1cc'
          //   },
          //   templateOptions: {
          //     boxWidth: { width: '50%' },
          //     exclusiveKey: 'No'
          //   },
          //   eventListeners: [
          //     {
          //       'event': 'change:model.InformationSecurity_1c_new',
          //       'actions': [
          //         {
          //           'name': 'setVisible',
          //           'params': '$value==11'
          //         }
          //       ]
          //     },

          //   ]
          // },
          {
            width: '100%',
            templateOptions: {
              boxWidth: { width: '50%' },
            },
            name: 'InformationSecurity_1cg_new',
            label: 'How many individuals does the Personal Data relate to? (Select one)',
            type: 'dropdown',
            required: true,
            hide: true,
            errorMessage: {
              'required': 'Please select'
            },
            dataSource: {
              name: 'dictionary',
              params: 'RiskAssessment.dp_InformationSecurity_1cg_new',
              loadOnInit: true
            },
            eventListeners: [
              {
                'event': 'change:model.InformationSecurity_1c_new',
                'actions': [
                  {
                    'name': 'setVisible',
                    'params': '$value==11'
                  }
                ]
              }

            ]
          },
          {
            width: '100%',
            templateOptions: {
              boxWidth: { width: '50%' },
            },
            name: 'InformationSecurity_1cf_new',
            label: 'Is EEA, UK, Switzerland Personal Data  that is not anonymized, transferred to or accessed from Countries outside the EEA, UK, Switzerland which are not on the list of <a target="_blank"  href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/adequacy-decisions_en">Adequate Countries</a> published by the EU Commission? ',
            tooltip: 'Transfer means allowing personal data to be available outside EEA, UK, Switzerland. It also includes if personal data is accessed or accessible from outside EEA, UK, Switzerland. Examples include making data available in a shared file or portal, sharing data via a secure link providing technical support.<br><br> Anonymised data means information which does not relate to an identified or identifiable natural person or to Personal Data rendered anonymous in such a manner that the individual is not or no longer identifiable. Anonymisation is the process of removing personal identifiers, both direct and indirect, that may lead to an individual being identified. <br><br> “EEA, UK, Switzerland Personal Data” means Personal Data subject to European Union privacy rules (i.e., the GDPR), belonging to data subjects located in EU countries, Iceland, Liechtenstein and Norway, which can be transferred to or made accessible from a third country or an international organization outside of the EEA, UK, Switzerland subject to adequate safeguards."',
            type: 'dropdown',
            hide: true,
            required: true,
            errorMessage: {
              'required': 'Please select'
            },
            dataSource: {
              name: 'dictionary',
              params: 'RiskAssessment.dp_InformationSecurity_1cf_new',
              loadOnInit: true
            },
            eventListeners: [
              {
                'event': 'change:model.InformationSecurity_1c_new',
                'actions': [
                  {
                    'name': 'setVisible',
                    'params': '$value==11'
                  }
                ]
              }
            ]
          },
          {
            width: '100%',
            templateOptions: {
              boxWidth: { width: '50%' },
            },
            name: 'InformationSecurity_1ce_1',
            label: 'Please select the location(s) of the individuals whose Personal Data is processed/accessed/making available or transfer',
            type: 'multiple',
            hide: true,
            required: true,
            errorMessage: {
              'required': 'Please select'
            },
            eventListeners: [
              {
                'event': 'change:model.InformationSecurity_1c_new',
                'actions': [
                  {
                    'name': 'setVisible',
                    'params': '$value==11'
                  }
                ]
              }
            ],
            dataSource: {
              name: 'dictionary',
              params: 'RiskAssessment.InformationSecurity_1ce_1',
              loadOnInit: true
            },
          },
          {

            type: 'multiple',
            name: 'InformationSecurity_1d_1',
            label: 'Select the data classification of all non-Personal Data that the Supplier will have access to. (Select all that apply)',
            width: '100%',
            required: true,
            hide: true,
            errorMessage: {
              'required': 'Please select'
            },
            dataSource: {
              name: 'dictionary',
              params: 'RiskAssessment.dp_InformationSecurity_1d_1',
              loadOnInit: true
            },
            templateOptions: {
              boxWidth: { width: '50%' },
              exclusiveKey: 'None of the above'
            },
            eventListeners: [
              {
                'event': 'change:model.InformationSecurity_1a_new',
                'actions': [
                  {
                    'name': 'setVisible',
                    'params': '$value==11'
                  }
                ]
              }
            ]
          },
          {
            width: '100%',
            templateOptions: {
              boxWidth: { width: '50%' },
            },
            type: 'dropdown',
            required: true,
            hide: true,
            name: 'InformationSecurity_1e_new',
            label: 'Will the Supplier provide, or give access to, any data in any form to Accenture (and its affiliates), Accenture’s Clients or Accenture’s partners?',
            subLabel: '(e.g. datasets, data feeds, licensed or purchased data, data scraped from websites, data collected directly from consumers/users, data from social media platforms, data from any public or other sources.)',
            errorMessage: {
              'required': 'Please select'
            },
            dataSource: {
              name: 'dictionary',
              params: 'RiskAssessment.dp_InformationSecurity_1e',
              loadOnInit: true
            },
            eventListeners: [
              {
                'event': 'change:model.InformationSecurity_1a_new',
                'actions': [
                  {
                    'name': 'setVisible',
                    'params': '$value==10||$value==11'
                  }
                ]
              }
            ]
          },
          {
            width: '100%',
            templateOptions: {
              boxWidth: { width: '50%' },
            },
            type: 'input',
            hide: true,
            name: 'InformationSecurity_1eYes',
            label: 'For what purpose(s) will the data provided by the Supplier be used? (please provide any relevant information that explains the applicable purpose(s) and use case(s))',
            required: true,
            errorMessage: {
              'required': 'Please Input'
            },
            eventListeners: [
              {
                'event': 'change:model.InformationSecurity_1e_new',
                'actions': [
                  {
                    'name': 'setVisible',
                    'params': '$value==11'
                  }
                ]
              }
            ]
          },
          {
            width: '100%',
            templateOptions: {
              boxWidth: { width: '50%' },
            },
            name: 'InformationSecurity_1cd_new',
            label: 'Will the Supplier provide Personal Data (excluding Business Contact Information)?',
            type: 'dropdown',
            tooltip: `<ul class="link-mare-item">
                        <li>"Personal Data" means any information relating to, identifying, describing, or capable of being associated with or reasonably linked (directly or indirectly) to, a natural person or household, and any other information regulated by data privacy laws. If you are not sure, please view examples of types of personal data in “What types of Personal Data?</li>
                        <li>"Business Contact Information" means any Personal Data that is used for the purpose of communicating, or facilitating communication, with an individual in relation to their employment, business or profession, such as their name, position name/title, work address, work phone number, work fax number or work e-mail</li>
                      </ul>
            `,
            required: true,
            hide: true,
            errorMessage: {
              'required': 'Please select'
            },
            dataSource: {
              name: 'dictionary',
              params: 'RiskAssessment.dp_InformationSecurity_1cd',
              loadOnInit: true
            },
            eventListeners: [
              {
                'event': 'change:model.InformationSecurity_1e_new',
                'actions': [
                  {
                    'name': 'setVisible',
                    'params': '$value==11'
                  }
                ]
              }
            ]
          },
          {
            width: '100%',
            templateOptions: {
              boxWidth: { width: '50%' },
            },
            name: 'InformationSecurity_1cd_5',
            label: 'What types of Personal Data will the Supplier provide? (Select all that apply)',
            type: 'multiple',
            tooltip: `<ul class="link-mare-item">
                        <li>Please note that Personal Data provided by Supplier should not be the same Personal Data that Accenture may provide to Supplier as per your selection under question “What types of Personal Data?" (if applicable)</li>
                      </ul>
            `,
            required: true,
            hide: true,
            errorMessage: {
              'required': 'Please select'
            },
            //                 filters: [{                  
            //                       name: 'expression',
            //                       params: '!context.getValue("parentModel").RequestForm.RiskAssessmentInfo.InformationSecurity_1cd_new'
            //                     }
            //                 ],
            dataSource: {
              name: 'dictionary',
              params: 'RiskAssessment.dp_InformationSecurity_1cd_5',
              loadOnInit: true
            },
            eventListeners: [
              {
                'event': 'change:model.InformationSecurity_1cd_new',
                'actions': [
                  {
                    'name': 'setVisible',
                    'params': '$value==11'
                  }
                ]
              }
            ]
          },
          {
            name: 'InformationSecurity_1cd_5_other',
            type: 'input',
            width: '100%',
            required: true,
            hide: true,
            label: 'Please explain',
            templateOptions: {
              boxWidth: { width: '50%' },
            },
            errorMessage: {
              'required': 'Please input'
            },
            eventListeners: [
              {
                'event': 'change:model.InformationSecurity_1cd_5',
                'actions': [
                  {
                    'name': 'setVisibleForExplain',
                  }
                ]
              },
            ]
          },
          {
            width: '100%',
            templateOptions: {
              boxWidth: { width: '50%' },
            },
            type: 'input',
            hide: true,
            name: 'InformationSecurity_1cd_2',
            required: true,
            errorMessage: {
              'required': 'Please Input'
            },
            label: 'How will the Supplier provide the Personal Data to Accenture and/or Accenture’s Clients?',
            subLabel: '(e.g. via a platform, tool, dataset, data feed, social media platform, social listening service)',
            eventListeners: [
              {
                'event': 'change:model.InformationSecurity_1cd_new',
                'actions': [
                  {
                    'name': 'setVisible',
                    'params': '$value==11'
                  }
                ]
              }
            ]
          },
          {
            width: '100%',
            templateOptions: {
              boxWidth: { width: '50%' },
            },
            name: 'InformationSecurity_1cd_3',
            label: 'Will Accenture share the Personal Data with Accenture’s Clients?',
            type: 'dropdown',
            required: true,
            hide: true,
            errorMessage: {
              'required': 'Please select'
            },
            dataSource: {
              name: 'dictionary',
              params: 'RiskAssessment.dp_InformationSecurity_1cd_3',
              loadOnInit: true
            },
            eventListeners: [
              {
                'event': 'change:model.InformationSecurity_1cd_new',
                'actions': [
                  {
                    'name': 'setVisible',
                    'params': '$value==11'
                  }
                ]
              }
            ]
          },
          {
            width: '100%',
            templateOptions: {
              boxWidth: { width: '50%' },
            },
            type: 'input',
            required: true,
            hide: true,
            errorMessage: {
              'required': 'Please input'
            },
            name: 'InformationSecurity_1cd_3_1',
            label: 'Please explain',
            eventListeners: [
              {
                'event': 'change:model.InformationSecurity_1cd_3',
                'actions': [
                  {
                    'name': 'setVisible',
                    'params': '$value==11'
                  }
                ]
              }
            ]
          },
          {
            width: '100%',
            templateOptions: {
              boxWidth: { width: '50%' },
            },
            name: 'InformationSecurity_1cd_4',
            label: 'Will Accenture anonymise/deidentify/aggregate/pseudonymise the Personal Data provided by the Supplier?',
            type: 'dropdown',
            required: true,
            hide: true,
            errorMessage: {
              'required': 'Please select'
            },
            dataSource: {
              name: 'dictionary',
              params: 'RiskAssessment.dp_InformationSecurity_1cd_4',
              loadOnInit: true
            },
            eventListeners: [
              {
                'event': 'change:model.InformationSecurity_1cd_new',
                'actions': [
                  {
                    'name': 'setVisible',
                    'params': '$value==11'
                  }
                ]
              }
            ]
          },
          {
            width: '100%',
            templateOptions: {
              boxWidth: { width: '50%' },
            },
            type: 'input',
            required: true,
            hide: true,
            errorMessage: {
              'required': 'Please input'
            },
            name: 'InformationSecurity_1cd_4_YES',
            label: 'Please explain',
            eventListeners: [
              {
                'event': 'change:model.InformationSecurity_1cd_4',
                'actions': [
                  {
                    'name': 'setVisible',
                    'params': '$value==11'
                  }
                ]
              }
            ]
          },
          {
            width: '100%',
            templateOptions: {
              boxWidth: { width: '50%' },
            },
            type: 'input',
            hide: true,
            name: 'InformationSecurity_1cd_4_NO',
            label: 'Please explain',
            required: true,
            eventListeners: [
              {
                'event': 'change:model.InformationSecurity_1cd_4',
                'actions': [
                  {
                    'name': 'setVisible',
                    'params': '$value==10'
                  }
                ]
              }
            ]
          },
          {
            width: '100%',
            templateOptions: {
              boxWidth: { width: '50%' },
            },
            type: 'dropdown',
            required: true,
            hide: true,
            name: 'InformationSecurity_1f_1',
            label: 'Will the Supplier have the ability to use, store, process or transfer Accenture and/or Client data outside of Accenture and/or Client’s environment/facility?',
            subLabel: '(e.g. within Supplier’s offices or hosted/cloud environment)',
            errorMessage: {
              'required': 'Please select'
            },
            dataSource: {
              name: 'dictionary',
              params: 'RiskAssessment.dp_InformationSecurity_1f',
              loadOnInit: true
            },
            eventListeners: [
              {
                'event': 'change:model.InformationSecurity_1a_new',
                'actions': [
                  {
                    'name': 'setVisible',
                    'params': '$value==11'
                  }
                ]
              }
            ]
          },
          {
            width: '100%',
            templateOptions: {
              boxWidth: { width: '50%' },
            },
            name: 'InformationSecurity_1i',
            required: true,
            hide: true,
            type: 'input',
            errorMessage: {
              'required': 'Please Input'
            },
            label: 'Supplier\'s Business Website (type N/A if not applicable):',
            eventListeners: [
              {
                'event': 'change:model.InformationSecurity_1',
                'actions': [
                  {
                    'name': 'setVisible',
                    'params': '$value==10'
                  }
                ]
              }
            ]
          },
          {
            name: 'InformationSecurity_1j',
            type: 'searchableDropdown',
            required: true,
            hide: true,
            label: 'Supplier Registered Country/Location',
            width: '100%',
            errorMessage: {
              'required': 'Please select'
            },
            templateOptions: {
              boxWidth: { width: '50%' },
              panelWidth: '46%'
            },
            dataSource: {
              'name': 'sortedDictionary',
              'params': 'BankCountryForTransactionAndBuyer',
              loadOnInit: true
            },
            eventListeners: [
              {
                'event': 'change:model.InformationSecurity_1',
                'actions': [
                  {
                    'name': 'setVisible',
                    'params': '$value==10'
                  }
                ]
              }
            ]
          },
          {
            name: 'InformationSecurity_4',
            label: 'Will the product/services being purchased going to be used to support Client delivery in this particular case?',
            type: 'dropdown',
            width: '100%',
            required: true,
            errorMessage: {
              'required': 'Please select'
            },
            templateOptions: {
              boxWidth: { width: '50%' },
            },
            dataSource: {
              name: 'dictionary',
              params: 'RiskAssessment.dp_InformationSecurity_4',
              loadOnInit: true
            },
            eventListeners: [
              {
                'event': 'change:model.InformationSecurity_1',
                'actions': [
                  {
                    'name': 'setVisible',
                    'params': '$value!=11'
                  }
                ]
              }
            ]
          },
          {
            width: '100%',
            name: 'InformationSecurity_4a',
            type: 'input',
            label: 'Please provide Client name(s) and corresponding Client services for delivery',
            hide: true,
            templateOptions: {
              boxWidth: { width: '50%' },
            },
            required: true,
            errorMessage: {
              'required': 'Please Input'
            },
            eventListeners: [
              {
                'event': 'change:model.InformationSecurity_4',
                'actions': [
                  {
                    'name': 'setVisible',
                    'params': '$value==11||$value==12'
                  }
                ]
              }
            ]
          },
          {
            name: 'InformationSecurity_5',
            label: 'Will the Supplier be responsible for the management of any of the following Services for infrastructure owned by Accenture and/or Client? Please ensure that you answer this correctly. Click on the help icon for additional details.(Select all that apply)',
            tooltip: `This is only applicable if the management of any of these Services directly falls under the scope/responsibility of the Supplier and the infrastructure in question is owned either by Accenture or Client.
                      This does not include scenarios where, Supplier’s personnel/resources may be involved in any of these Services, but the management of these Services directly falls under the scope/responsibility of Accenture or Client.`,
            type: 'multiple',
            width: '100%',
            required: true,
            errorMessage: {
              'required': 'Please select'
            },
            templateOptions: {
              boxWidth: { width: '50%' },
              exclusiveKey: 'None of the above',
              panelWidth: '50%'
            },
            dataSource: {
              name: 'dictionary',
              params: 'RiskAssessment.dp_InformationSecurity_5',
              loadOnInit: true
            },
            eventListeners: [
              {
                'event': 'change:model.InformationSecurity_1',
                'actions': [
                  {
                    'name': 'setVisible',
                    'params': '$value!=11'
                  }
                ]
              }
            ]
          },
          {
            name: 'InformationSecurity_6',
            label: 'Are the following scenarios applicable to the Supplier? (Select all that apply)',
            type: 'multiple',
            width: '100%',
            required: true,
            errorMessage: {
              'required': 'Please select'
            },
            templateOptions: {
              boxWidth: { width: '50%' },
              exclusiveKey: 'None of the above',
              panelWidth: '50%'
            },
            dataSource: {
              name: 'dictionary',
              params: 'RiskAssessment.dp_InformationSecurity_6',
              loadOnInit: true
            },
            eventListeners: [
              {
                'event': 'change:model.InformationSecurity_1',
                'actions': [
                  {
                    'name': 'setVisible',
                    'params': '$value!=11'
                  }
                ]
              }
            ]
          },
          {
            width: '100%',
            templateOptions: {
              boxWidth: { width: '50%' },
            },
            name: 'InformationSecurity_1g_1',
            type: 'dropdown',
            required: true,
            label: 'Will Accenture CIO support or manage the product/services being purchased?',
            errorMessage: {
              'required': 'Please select'
            },
            dataSource: {
              name: 'dictionary',
              params: 'RiskAssessment.dp_InformationSecurity_1g',
              loadOnInit: true
            },
            eventListeners: [
              {
                'event': 'change:model.InformationSecurity_1',
                'actions': [
                  {
                    'name': 'setVisible',
                    'params': '$value!=11'
                  }
                ]
              }
            ]
          },
          {
            hide: true,
            width: '100%',
            label: 'Your answers indicate that this supplier will not have access to any data, if this is not the case, please review and update your answers above. Else, answer the below question and make sure that the Service Description provided above clearly illustrates the “no data” nature of the scope to avoid further inquiries from IS Supplier Risk',
            type: 'textbox',
            eventListeners: [
              {
                'event': 'change:model.InformationSecurity_1a_new',
                'actions': [
                  {
                    'name': 'setVisible',
                    'params': '$value==10'
                  }
                ]
              }
            ]
          },
          // {
          //   width: '100%',
          //   templateOptions: {
          //     boxWidth: { width: '50%' },
          //   },
          //   name: 'InformationSecurity_1ga',
          //   label: '(a) if Yes, Who will provide support from CIO/ITS?',
          //   type: 'input',
          //   errorMessage: {
          //     'required': 'Please Input'
          //   },
          //   required: true,
          //   hide: true,
          //   eventListeners: [
          //     {
          //       'event': 'change:model.InformationSecurity_1g',
          //       'actions': [
          //         {
          //           'name': 'setVisible',
          //           'params': '$value==11'
          //         }
          //       ]
          //     }
          //   ]
          // },
          // {
          //   width: '100%',
          //   templateOptions: {
          //     boxWidth: { width: '50%' },
          //   },
          //   name: 'InformationSecurity_1gb',
          //   label: '(b) if Yes, what is the AIR ID Number and Classification?',
          //   required: true,
          //   hide: true,
          //   type: 'input',
          //   errorMessage: {
          //     'required': 'Please Input'
          //   },
          //   eventListeners: [
          //     {
          //       'event': 'change:model.InformationSecurity_1g',
          //       'actions': [
          //         {
          //           'name': 'setVisible',
          //           'params': '$value==11'
          //         }
          //       ]
          //     }
          //   ]
          // },
          {
            width: '50%',
            templateOptions: {
              boxWidth: { width: '100%' },
            },
            name: 'InformationSecurity_1h_1',
            required: true,
            type: 'peoplepicker',
            showType: 'email',
            Level: 'Accenture Leadership',
            label: 'Who in Accenture Leadership (e.g. Managing Director) is responsible for this Supplier?',
            errorMessage: {
              'required': 'Please Input'
            },
            eventListeners: [
              {
                'event': 'change:model.InformationSecurity_1',
                'actions': [
                  {
                    'name': 'setVisible',
                    'params': '$value!=11'
                  }
                ]
              }
            ]
          },
          {
            width: '100%',
            templateOptions: {
              boxWidth: { width: '50%' },
            },
            name: 'InformationSecurity_2',
            required: true,
            label: 'Will Supplier Intellectual Property be embedded in an Accenture/Avanade asset or Client deliverable?',
            type: 'dropdown',
            errorMessage: {
              'required': 'Please select'
            },
            dataSource: {
              name: 'dictionary',
              params: 'RiskAssessment.dp_InformationSecurity_2',
              loadOnInit: true
            },
            filters: [
              {
                name: 'expression',
                params: '!context.getValue("isAsen")'
              }
            ]
          },
          {
            width: '100%',
            templateOptions: {
              boxWidth: { width: '50%' },
            },
            label: '(a) If Yes, state asset/deliverable using Supplier IP:',
            type: 'input',
            errorMessage: {
              'required': 'Please Input'
            },
            required: true,
            hide: true,
            name: 'InformationSecurity_2a',
            filters: [
              {
                name: 'expression',
                params: '!context.getValue("isAsen")'
              }
            ],
            eventListeners: [
              {
                'event': 'change:model.InformationSecurity_2',
                'actions': [
                  {
                    'name': 'setVisible',
                    'params': '$value==11'
                  }
                ]
              }
            ]
          },
          {
            name: 'InformationSecurity_3',
            tooltip: 'NOTE/INFO - Ensure you are following corporate vendor IP policies <p> <a href="https://policies.accenture.com/policy/0051" target="_blank"> Use of Distributed Information </a> </p> <p [ngStyle]="margin: 10px"> <a [ngStyle]="{color: blue}" href="https://policies.accenture.com/policy/0059" target="_blank"> Acceptable Use </a> </p>',
            label: 'Will Supplier use or include information/software/tools subject to Intellectual Property Rights law (e.g. 3rd party proprietary knowledge, software, open source software, packaged software) into their product/service?',
            type: 'dropdown',
            width: '100%',
            required: true,
            errorMessage: {
              'required': 'Please select'
            },
            templateOptions: {
              boxWidth: { width: '50%' },
            },
            filters: [
              {
                name: 'expression',
                params: '!context.getValue("isAsen") '
              }
            ],
            dataSource: {
              name: 'dictionary',
              params: 'RiskAssessment.dp_InformationSecurity_3',
              loadOnInit: true
            }
          },
          {
            width: '100%',
            templateOptions: {
              boxWidth: { width: '50%' },
            },
            name: 'InformationSecurity_12',
            type: 'dropdown',
            required: true,
            label: 'Will Accenture Operations support or manage the product/services being purchased?',
            errorMessage: {
              'required': 'Please select'
            },
            dataSource: {
              name: 'dictionary',
              params: 'RiskAssessment.dp_InformationSecurity_12',
              loadOnInit: true
            },
            eventListeners: [
              {
                'event': 'change:model.InformationSecurity_1',
                'actions': [
                  {
                    'name': 'setVisible',
                    'params': '$value==10'
                  }
                ]
              }
            ]
          },
          {
            width: '100%',
            templateOptions: {
              boxWidth: { width: '50%' },
            },
            name: 'InformationSecurity_3a',
            type: 'input',
            hide: true,
            errorMessage: {
              'required': 'Please Input'
            },
            required: true,
            label: '(a) If Yes, provide evidence that Supplier has the license/right to use 3rd party software/tools.',
            eventListeners: [
              {
                'event': 'change:model.InformationSecurity_3',
                'actions': [
                  {
                    'name': 'setVisible',
                    'params': '$value==11'
                  }
                ]
              }
            ],
            filters: [
              {
                name: 'expression',
                params: '!context.getValue("isAsen")'
              }
            ]
          }


        ],
        // filters: [
        //   {
        //     name: 'SpendCategoryGroup',
        //     params: ''
        //   },
        // ],
      },
      {
        id: 'BI_BDA',
        label: 'BI/BDA',
        type: 'collapse',
        width: '100%',
        hide: true,
        templateOptions: {
          deep: '1',
        },
        fieldset: [
          {
            label: 'Per the Anti-corruption program as required by Policy 1327, would you consider this supplier to be a Business Development Agent ("BDA")',
            subLabel: '(Any independent third party representative, agent, consultant, contractor, subcontractor or other business partner (whether a company or individual) specifically engaged to support Accenture in a client-facing role in business development, sales or otherwise obtaining or retaining business);',
            name: 'BI_BDA_1',
            type: 'dropdown',
            templateOptions: {
              boxWidth: { width: '50%' },
              extraLabels: [
                {
                  label: 'a Business Intermediary ("BI")?',
                  subLabel: '(Any individual or entity that Accenture engages to perform activities that are expected to be client-facing, including business development agents, advisors, consultants, subcontractors, alliance partners, teaming partners, joint venture partners, sales representatives, and suppliers and/or any individual or entity acting or expected to act for or on behalf of Accenture/Avanade in securing a license, visa, permit or other form of authorization from or by intervening ina regulatory matter with a Public Official, government agency, or state-owned or controlled entity).<br>*(Any kind of interaction with a client is “client facing” under the policy (calls, project meetings, onsite installation, etc.), regardless of where the third party is located and you need to complete the BI process)'
                }
              ]
            },
            width: '100%',
            dataSource: {
              name: 'dictionary',
              params: 'RiskAssessment.dp_BI_BDA_1',
              loadOnInit: true
            },
            required: true,
            hide: true,
            errorMessage: {
              'required': 'Please select'
            },
            hooks: [
              { name: 'RequestorResubmitDisable', trigger: 'onComponentRender', params:'', condition:'' }
            ]
          },
          {
            name: 'BI_BDA_1a',
            hide: true,
            label: '(a) If BI or BDA above, please enter your BI/BDA Review Case ID',
            type: 'input',
            width: '100%',
            required: true,
            templateOptions: {
              boxWidth: { width: '50%' },
            },
            errorMessage: {
              'required': 'Please Input'
            },
            eventListeners: [
              // {
              //   'event': 'change:model.BI_BDA_1',
              //   'actions': [
              //     {
              //       'name': 'setVisible',
              //       'params': '$value==11||$value==12'
              //     }
              //   ]
              // }

            ]
          },
          {
            name: 'BI_BDA_1b',
            hide: true,
            label: 'If Supplier is providing services, what is the estimated Period of Performance?',
            subLabel: '(When do you anticipate the Supplier to Start and End work?)',
            type: 'input',
            errorMessage: {
              'required': 'Please Input'
            },
            width: '100%',
            required: true,
            templateOptions: {
              boxWidth: { width: '50%' },
            },
            eventListeners: [
              // {
              //   'event': 'change:model.BI_BDA_1',
              //   'actions': [
              //     {
              //       'name': 'setVisible',
              //       'params': '$value==11||$value==12'
              //     }
              //   ]
              // }
            ],
            filters: [
              {
                name: 'expression',
                params: 'context.getValue("parentModel").RequestForm.RiskAssessmentInfo.BI_BDA_1b&&!context.getValue("parentModel").RequestForm.RiskAssessmentInfo.BI_BDA_1bStartDate'
              }
            ]
          },
          {
            hide: true,
            label: 'If Supplier is providing services, what is the estimated Period of Performance?',
            subLabel: '(When do you anticipate the Supplier to Start and End work?)',
            type: 'textbox',
            customStyle: { 'min-height': '0px' },
            width: '100%',
            required: true,
            templateOptions: {
              boxWidth: { width: '50%' },
            },
            eventListeners: [
              // {
              //   'event': 'change:model.BI_BDA_1',
              //   'actions': [
              //     {
              //       'name': 'setVisible',
              //       'params': '$value==11||$value==12'
              //     }
              //   ]
              // }
            ],
            filters: [
              {
                name: 'expression',
                params: 'context.getValue("parentModel").RequestForm.RiskAssessmentInfo.BI_BDA_1bStartDate||!context.getValue("parentModel").RequestForm.RiskAssessmentInfo.BI_BDA_1b'
              }
            ]
          },
          {
            name: 'BI_BDA_1bStartDate',
            hide: true,
            label: 'Start Date',
            type: 'datepicker',
            dateformat: 'MM/DD/YYYY',
            errorMessage: {
              'required': 'Please Select'
            },
            width: '30%',
            required: true,
            templateOptions: {
              boxWidth: { width: '75%' },
            },
            eventListeners: [
              // {
              //   'event': 'change:model.BI_BDA_1',
              //   'actions': [
              //     {
              //       'name': 'setVisible',
              //       'params': '$value==11||$value==12'
              //     }
              //   ]
              // }
            ],
            filters: [
              {
                name: 'expression',
                params: 'context.getValue("parentModel").RequestForm.RiskAssessmentInfo.BI_BDA_1bStartDate||!context.getValue("parentModel").RequestForm.RiskAssessmentInfo.BI_BDA_1b'
              }
            ]
          },
          {
            name: 'BI_BDA_1bEndDate',
            hide: true,
            label: 'End Date',
            type: 'datepicker',
            dateformat: 'MM/DD/YYYY',
            errorMessage: {
              'required': 'Please Select'
            },
            width: '30%',
            required: true,
            templateOptions: {
              boxWidth: { width: '75%' },
            },
            eventListeners: [
              // {
              //   'event': 'change:model.BI_BDA_1',
              //   'actions': [
              //     {
              //       'name': 'setVisible',
              //       'params': '$value==11||$value==12'
              //     }
              //   ]
              // }
            ],
            filters: [
              {
                name: 'expression',
                params: 'context.getValue("parentModel").RequestForm.RiskAssessmentInfo.BI_BDA_1bEndDate||!context.getValue("parentModel").RequestForm.RiskAssessmentInfo.BI_BDA_1b'
              }
            ]
          },
          {
            name: 'BI_BDA_1c',
            hide: true,
            label: 'Will Supplier use sub-contractors to support delivery of services?',
            type: 'dropdown',
            width: '100%',
            required: true,
            errorMessage: {
              'required': 'Please select'
            },
            templateOptions: {
              boxWidth: { width: '50%' },
            },
            dataSource: {
              name: 'dictionary',
              params: 'RiskAssessment.dp_BI_BDA_1c',
              loadOnInit: true
            },
            eventListeners: [
              // {
              //   'event': 'change:model.BI_BDA_1',
              //   'actions': [
              //     {
              //       'name': 'setVisible',
              //       'params': '$value==11||$value==12'
              //     }
              //   ]
              // }
            ]
          },
          {
            name: 'BI_BDA_2',
            label: 'Is this supplier considered a Trade Association (TA") per (Policy 1463)?',
            subLabel: 'Trade associations are groups whose members typically are involved in a specific business or trade, promoting common interests or industry standards through activities such as best practices development, education and training. Examples of trade associations include the American Chemistry Council, China Chain Store and Franchise Association and the Mobile Entertainment Forum',
            tooltip: '<li>For Trade Associations - Business Sponsor must</li><li> obtain approval through the</li> <li><a style="color: #006caf" target="_blank" href="https://tradeassociation.accenture.com">Trade Association Tool</a></li>',
            type: 'dropdown',
            width: '100%',
            hide: true,
            required: true,
            errorMessage: {
              'required': 'Please select'
            },
            templateOptions: {
              boxWidth: { width: '50%' },
            },
            dataSource: {
              name: 'dictionary',
              params: 'RiskAssessment.dp_BI_BDA_2',
              loadOnInit: true
            }
          },
        ]
      },
    ];
  }

  async canDeactivate() {
    return true;
  }

  validate() {
    return this.dynamicForm.valide();
  }
}

